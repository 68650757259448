import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { RequestIntegrationDialogUiState } from '@cp/web/app/marketplace/protocol/MarketplaceStates';

@Injectable({
  providedIn: 'root'
})
export class RequestIntegrationDialogUiService extends BaseUiStateService<RequestIntegrationDialogUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'requestIntegrationDialog'], stateService);
  }
}
