import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { assertTruthy } from '@cp/common/utils/Assert';

export interface DisplayProgress {
  progressBars: number;
  totalProgress: number;
  fileNames: string;
}

@Component({
  selector: 'cp-upload-progress-bar',
  templateUrl: './upload-progress-bar.component.html',
  styleUrls: ['./upload-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadProgressBarComponent implements OnChanges, OnInit {
  @Input()
  numBars = 8;

  @Input()
  displayProgress!: DisplayProgress;

  getBarsArray(displayProgress: DisplayProgress): boolean[] {
    const barArray: boolean[] = [];
    for (let i = 0; i < this.numBars; i++) {
      barArray.push(i < displayProgress.progressBars);
    }
    return barArray;
  }

  ngOnChanges() {
    assertTruthy(this.displayProgress);
  }

  ngOnInit() {
    assertTruthy(this.displayProgress);
  }
}
