<mat-dialog-content class="mfa_update_dialog">
  <div class="wave_container"></div>
  <div class="content">
    <div class="checkmark_container">
      <mat-icon class="icon" svgIcon="mfa_totp_authenticator"></mat-icon>
    </div>

    <div class="text_container">
      <div class="title">Action Required: MFA Updates</div>
      <div class="description">Due to recent improvements to our security systems, your multi-factor authentication settings needs updating.</div>
    </div>

    <div class="buttons">
      <button (click)="showMfaPopup()" class="primary_button button"
              mat-flat-button>
        Update multi-factor authentication
      </button>
      <button (click)="cancel()" class="secondary_action button" mat-stroked-button type="button">
        Cancel
      </button>
    </div>
  </div>
</mat-dialog-content>
