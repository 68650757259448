import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@cp/web/app/account/account.service';

@Component({
  selector: 'cp-authenticator-app-set-up-dialog',
  templateUrl: './authenticator-app-set-up-dialog.component.html',
  styleUrls: ['./authenticator-app-set-up-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthenticatorAppSetUpDialogComponent implements OnInit {
  code = '';
  qrCodeString = '';
  token = '';
  errorMessage = '';
  isVerifyingCode = false;
  isVerificationButtonDisabled = true;

  constructor(
    public dialogRef: MatDialogRef<AuthenticatorAppSetUpDialogComponent>,
    private readonly accountService: AccountService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private readonly data: { title: string; action: string }
  ) {}

  async ngOnInit(): Promise<void> {
    await this.generateQrCode();
  }

  async generateQrCode(): Promise<void> {
    const { secret, qrCodeString } = await this.accountService.getTotpAuthenticatorAppSetupData();
    this.code = secret;
    this.qrCodeString = qrCodeString;
    this.cdr.markForCheck();
  }

  async verifyToken() {
    try {
      this.isVerifyingCode = true;
      await this.accountService.verifyTotpTokenAndEnableMfa(this.token);
      this.dialogRef.close();
    } catch (e) {
      this.errorMessage = 'Incorrect code entered';
      this.isVerifyingCode = false;
      this.cdr.markForCheck();
    }
  }

  updateToken(token: string): void {
    this.errorMessage = '';
    this.token = token;
    this.isVerificationButtonDisabled = token.length < 6;
    this.cdr.markForCheck();
  }

  static show(dialog: MatDialog): void {
    dialog.open(AuthenticatorAppSetUpDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      height: 'calc(min(770px, 90vh))',
      restoreFocus: false,
      panelClass: 'modal',
      data: {
        title: 'Set up authenticator app',
        action: 'Enable Authenticator App',
        buttonLayout: 'VERTICAL'
      }
    });
  }
}
