import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { InstanceBackup } from '@cp/common/protocol/Backup';
import { isDefined } from '@cp/common/protocol/Common';
import { convertArrayToRecord } from '@cp/common/utils/MiscUtils';
import { InstanceBackupsState } from '@cp/web/app/instances/protocol/InstanceStates';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstanceBackupStateService extends BaseUiStateService<InstanceBackupsState> {
  constructor(stateService: StateService) {
    super(['instanceBackups'], stateService);
  }

  setInstanceBackups(instanceId: string, instanceBackups: Array<InstanceBackup>): void {
    const backupByIdRecord = convertArrayToRecord(instanceBackups);
    this.stateService.setInPath([...this.STATE_PATH, 'backups', instanceId], backupByIdRecord);
  }

  setInstanceBackup(instanceBackup: InstanceBackup): void {
    this.stateService.setInPath(
      [...this.STATE_PATH, 'backups', instanceBackup.instanceId, instanceBackup.id],
      instanceBackup
    );
  }

  getInstanceBackups(instanceId: string): Record<string, InstanceBackup> {
    return (
      this.stateService.getStateInPath<Record<string, InstanceBackup>>([...this.STATE_PATH, 'backups', instanceId]) ||
      {}
    );
  }

  clearBackups(): void {
    this.stateService.deletePath([...this.STATE_PATH, 'backups']);
  }

  deleteBackup(instanceId: string, backupId: string): void {
    this.stateService.deletePath([...this.STATE_PATH, 'backups', instanceId, backupId]);
  }

  observeInstanceBackups(instanceId: string): Observable<Record<string, InstanceBackup>> {
    return this.stateService
      .observePath<Record<string, InstanceBackup>>([...this.STATE_PATH, 'backups', instanceId])
      .pipe(filter(isDefined));
  }

  observeInstancesBackups(): Observable<Array<InstanceBackup> | undefined> {
    return this.stateService
      .observePath<Record<string, Record<string, InstanceBackup>>>([...this.STATE_PATH, 'backups'])
      .pipe(
        map((instancesBackups) =>
          instancesBackups ? Object.values(instancesBackups).flatMap((b) => Object.values(b)) : undefined
        )
      );
  }
}
