import { Injectable } from '@angular/core';
import { InstanceFeatureId, OrganizationFeatureId } from '@cp/common/protocol/features';
import { UserFeatureId } from '@cp/common/protocol/features/UserFeatures';
import { checkSetsEquality } from '@cp/common/utils/MiscUtils';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { BehaviorSubject, distinctUntilChanged, map, Observable, of, switchMap } from 'rxjs';

/** Provides common functionality & support for Features in UI module. */
@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  private readonly userFeatureFlagsSubject = new BehaviorSubject(new Set<UserFeatureId>());

  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceStateService: InstanceStateService
  ) {
    accountStateService.observeUserDetails().subscribe((userDetails) => {
      const newEnabledFeatures: Set<UserFeatureId> = new Set(userDetails?.features || []);
      const currentEnabledFeatures = this.userFeatureFlagsSubject.getValue();
      if (!checkSetsEquality(currentEnabledFeatures, newEnabledFeatures)) {
        this.userFeatureFlagsSubject.next(newEnabledFeatures);
      }
    });
  }

  get userFeatureFlags(): Observable<Set<UserFeatureId>> {
    return this.userFeatureFlagsSubject;
  }

  hasUserFlag(flag: UserFeatureId): boolean {
    return this.userFeatureFlagsSubject.getValue().has(flag);
  }

  /** Returns an observable that emits on every 'featureId' change for the current user. */
  observeUserFeature(featureId: UserFeatureId): Observable<boolean> {
    return this.userFeatureFlagsSubject.pipe(
      map((featureIds) => featureIds.has(featureId)),
      distinctUntilChanged()
    );
  }

  /** Returns an observable that emits on every 'featureId' change in the current organization. */
  observeCurrentOrganizationFeature(featureId: OrganizationFeatureId): Observable<boolean> {
    return this.organizationStateService.observeCurrentOrganizationId().pipe(
      switchMap((organizationId) =>
        organizationId ? this.observeOrganizationFeature(organizationId, featureId) : of(false)
      ),
      distinctUntilChanged()
    );
  }

  /** Returns an observable that emits on every 'featureId' change for the specified organization. */
  observeOrganizationFeature(organizationId: string, featureId: OrganizationFeatureId): Observable<boolean> {
    return this.organizationStateService.observeOrganization(organizationId).pipe(
      map((organization) => !!organization && organization.features.includes(featureId)),
      distinctUntilChanged()
    );
  }

  /** Returns an observable that emits on every 'featureId' change for the specified instance. */
  observeInstanceFeature(instanceId: string, featureId: InstanceFeatureId): Observable<boolean> {
    return this.instanceStateService.observeInstance(instanceId).pipe(
      map((instance) => !!instance && instance.features.includes(featureId)),
      distinctUntilChanged()
    );
  }
}
