import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RestService } from '@cp/common-services/rest.service';
import { isDefined } from '@cp/common/protocol/Common';
import { DRIFT_API_PATH, GetDriftIdentityJwtRequest, GetDriftIdentityJwtResponse } from '@cp/common/protocol/Drift';
import { assertTruthy } from '@cp/common/utils/Assert';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import {
  getExpirableValueFromLocalStorage,
  storeExpirableValueInLocalStorage
} from '@cp/web/app/common/utils/LocalStorageUtils';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { combineLatest, take } from 'rxjs';
import { filter } from 'rxjs/operators';

interface driftStartInteractionInput {
  interactionId: number;
}

interface DriftAPI {
  reset: () => void;
  identify: (id: string, properties: {}, authProps: {}) => void;
  page: () => void;
  unload: () => void;
  load: (id: string) => void;
  show: () => void;
  api: {
    widget: {
      hide: () => void;
    };
    startInteraction: (input: driftStartInteractionInput) => void;
  };
}

const INTERACTION_MAPPING: Record<string, number> = {
  services: 358266,
  service: 358266,
  integrations: 350481,
  admin: 350657,
  usage: 350668,
  billing: 350666,
  members: 350613,
  activity: 350615,
  learn: 350742,
  support: 350753
};

@Injectable({
  providedIn: 'root'
})
export class DriftService {
  private driftEnabled = false;

  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly restService: RestService,
    private router: Router
  ) {
    const orgIdObs = this.organizationStateService.observeCurrentOrganizationId().pipe(filter(isDefined), take(1));
    combineLatest([this.accountStateService.observeUserDetails(), orgIdObs]).subscribe(async ([userDetails, orgId]) => {
      if (userDetails) {
        console.debug('A new user logged in');
        console.debug({ userDetails });
        console.debug({ drift: this.drift });
        this.driftEnabled = true;
        this.drift.load('bhbc5tszm6xi');
        const nameParts = userDetails.name.split(' ');
        assertTruthy(nameParts.length > 0);
        console.debug('Before identify');
        console.debug(`organizationId: ${orgId}`);
        let jwtDetails = getExpirableValueFromLocalStorage('driftIdentityJwt') as GetDriftIdentityJwtResponse;
        if (!jwtDetails) {
          jwtDetails = await this.getUserJwt(userDetails.userId);
          const expirationMillis = jwtDetails.expiresIn * 1000;
          storeExpirableValueInLocalStorage('driftIdentityJwt', jwtDetails, expirationMillis - Date.now());
        }
        this.drift.identify(
          userDetails.userId,
          {
            externalId: userDetails.userId,
            endUser: true,
            email: userDetails.email,
            firstName: nameParts[0],
            lastName: nameParts.slice(1).join(' '),
            organizationId: orgId
          },
          { userJwt: jwtDetails.userJwt }
        );
        console.debug('After identify');
      } else if (this.driftEnabled) {
        // User is unauthenticated - clear user cache.
        console.log('User logs out');
        this.driftEnabled = false;
        // TODO: Adding this to make tests pass, since it seems to be a race condition and its affecting tests.
        // We need to check why every now and then we are getting this.drift.unload is not a function
        if (this.drift && typeof this.drift.unload === 'function') {
          this.drift.unload();
        }
      }
    });

    this.router.events.subscribe((evt: unknown) => {
      if (evt instanceof NavigationStart && !router.navigated) {
        // Hide drift only on page refresh.
        if (this.drift.api?.widget && typeof this.drift.api.widget.hide === 'function') {
          this.drift.api.widget.hide();
        } else {
          const service = this;
          (window as any).drift.on('ready', () => {
            service.drift.api.widget.hide();
          });
        }
      }
    });
  }

  get drift(): DriftAPI {
    const drift = (window as any).drift;
    assertTruthy(drift && !!drift.reset && !!drift.page && !!drift.identify, 'drift must be defined on window');
    return drift;
  }

  get currentLocation(): string {
    const routeParts = window.location.pathname.split('/');
    for (const part of routeParts) {
      if (INTERACTION_MAPPING[part]) {
        return part;
      }
    }
    return '';
  }

  isChatAvailableOnThisPage(): boolean {
    return Object.keys(INTERACTION_MAPPING).includes(this.currentLocation);
  }

  openChat(): void {
    assertTruthy(this.isChatAvailableOnThisPage());
    if (this.drift.api) {
      this.drift.api.startInteraction({ interactionId: INTERACTION_MAPPING[this.currentLocation] });
    } else {
      const service = this;
      (window as any).drift.on('ready', () => {
        service.drift.api.startInteraction({ interactionId: INTERACTION_MAPPING[service.currentLocation] });
      });
    }
  }

  private async getUserJwt(userId: string): Promise<GetDriftIdentityJwtResponse> {
    const getDriftIdentityJwtRequest: GetDriftIdentityJwtRequest = {
      rpcAction: 'getDriftIdentityJwt',
      userId
    };
    return await this.restService.post(DRIFT_API_PATH, getDriftIdentityJwtRequest);
  }
}
