<mat-dialog-content *ngIf="updatePersonalInfoUiStateObs | async as uiState" class="update_personal_info_dialog">
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title dialogTitle="Update Personal Info"></cp-dialog-title>
  <form (ngSubmit)="onSubmit()" [formGroup]="updatePersonalInfoForm" class="form">
    <div>
      <div class="seed_form_element">
        <label>Name</label>
        <input formControlName="name" maxlength="50" name="name" class="fs-exclude" spellCheck="false">
        <div *ngIf="!updatePersonalInfoForm.controls['name'].valid && updatePersonalInfoForm.controls['name'].touched"
             class="seed_error">
          Please enter your full name
        </div>
      </div>
      <div class="seed_form_element">
        <label>Email</label>
        <div class="email_field fs-exclude">{{userDetails.email}}</div>
        <div class="disclaimer">Editing email address is unavailable at this time</div>
      </div>
    </div>

    <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>
    <div class="vertical_buttons">
      <button [disabled]="updatePersonalInfoForm.invalid || uiState.updatePersonalInfoButtonDisabled"
              class="main_action" color="primary" mat-flat-button
              type="submit">
        Update Personal Info
      </button>
      <button [mat-dialog-close]="false" class="secondary_action" mat-flat-button type="button">Cancel</button>
    </div>
  </form>
</mat-dialog-content>
