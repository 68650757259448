<div *ngIf="showNoAccessMessageOnEmptyList && ipAccessList.length === 0"
     class="no_access_notice"
     data-cy="no-traffic-notice"
>No traffic is currently able to access this service.
</div>

<table class="access_list_table" data-cy="ip-access-list-table">
  <thead>
  <tr>
    <th class="ip_row_header">IP address</th>
    <th class="description_header">Description</th>
    <th class="select_row_header" *ngIf="selectedSources">Select</th>
    <th class="actions_row_header" *ngIf="isActionRowVisible">Actions</th>
  </tr>
  </thead>
  <tbody>

  <ng-container *ngIf="ipAccessList.length === 0">
    <tr>
      <td colspan="2"><p class='empty_table'>No addresses specified</p></td>
    </tr>
  </ng-container>

  <ng-container *ngIf="ipAccessList.length > 0">
    <tr *ngFor="let entry of ipAccessList; trackBy: trackBySource" [attr.data-cy]="'row-' + entry.source">
      <td class="ip_td fs-exclude" data-cy="ip">
        {{entry.source}}
      </td>
      <td class="description_td fs-exclude"
          data-cy="description"
          matTooltip="{{entry.description}}"
          matTooltipClass="full_length_tooltip"
          showTooltipIfTruncated
      >
        {{entry.description}} <span class="current_ip_text_hint" *ngIf="isCurrentIp(entry)">(current IP)</span>
      </td>
      <td class="select_td" *ngIf="this.selectedSources" data-cy="select">
        <mat-checkbox [checked]="selectedSources.includes(entry.source)"
                      (change)="onSelectedEntryCheckboxChanged(entry)"
                      color="primary"
                      data-cy="select-checkbox"
        ></mat-checkbox>
      </td>
      <td class="actions_td" *ngIf="isActionRowVisible" data-cy="actions">
        <mat-icon *ngIf="isEditButtonVisible" (click)="editButtonClick.emit(entry)" svgIcon="pencil"
                  data-cy="edit-icon" matTooltip="Edit entry"></mat-icon>
        <mat-icon
          [galaxyClick]="buildFullyQualifiedEvent('whitelistEntryDelete')"
          *ngIf="isActionRowVisible" (click)="deleteButtonClick.emit(entry)" svgIcon="glyph_trash_bin"
          class="trash_icon"
          data-cy="delete-icon" matTooltip="Delete entry"></mat-icon>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>
