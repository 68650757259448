import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { AccountService } from '@cp/web/app/account/account.service';
import { trackByValue } from '@cp/web/app/common/utils/AngularUtils';
import { shuffleArray } from '@cp/web/app/common/utils/ArrayUtils';
import { getNextCounterValue } from '@cp/web/app/common/utils/BrowserUtils';

interface MultiChoiceOption {
  value: string;
  isChecked: boolean;
  otherValue?: string;
}

interface MultiChoiceQuestion {
  formFieldName: string;
  question: string;
  options: Array<MultiChoiceOption>;
  otherValue?: string;
}

@Component({
  templateUrl: './entry-questionnaire-dialog.component.html',
  styleUrls: ['./entry-questionnaire-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntryQuestionnaireDialogComponent {
  static isOpened = false;
  readonly questions: Array<MultiChoiceQuestion> = [
    {
      formFieldName: 'sigInReason',
      question: 'What’s your main reason for signing up?',
      options: [
        ...shuffleArray([
          { value: 'Starting a new project', isChecked: false },
          { value: 'Migrating from self managed to ClickHouse Cloud', isChecked: false },
          { value: 'Learning ClickHouse', isChecked: false }
        ]),
        { value: 'Other', isChecked: false } // Making sure Other is always last option.
      ]
    },
    {
      formFieldName: 'ClickHouseReason',
      question: 'What will you use ClickHouse for?',
      options: [
        ...shuffleArray([
          { value: 'Real-time Dashboards', isChecked: false },
          { value: 'Business Intelligence', isChecked: false },
          { value: 'Logging and Metrics', isChecked: false },
          { value: 'Real-time Analytics', isChecked: false },
          { value: 'Data Warehouse Speed Layer', isChecked: false },
          { value: 'ML and Data Science', isChecked: false }
        ]),
        { value: 'Other', isChecked: false } // Making sure Other is always last option.
      ]
    }
  ];

  readonly otherInputIdPrefix = `entry_questionnaire_${getNextCounterValue()}_other_input_`;
  errorMessage?: string;
  trackByValue = trackByValue;

  constructor(
    public readonly dialogRef: MatDialogRef<EntryQuestionnaireDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly accountService: AccountService
  ) {}

  static show(dialog: MatDialog): void {
    if (this.isOpened) {
      // Make sure we open the dialog only once.
      return;
    }
    dialog.open(EntryQuestionnaireDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      restoreFocus: false,
      panelClass: 'modal',
      disableClose: true,
      autoFocus: false
    });
    this.isOpened = true;
  }

  /**
   * The submit button is disabled if none of the checkboxes of a question are checked
   * or if only the Other option is checked but no value is provided.
   */
  isSubmitButtonDisabled(): boolean {
    return this.questions.some((q) => q.options.every((op) => !op.isChecked));
  }

  onSubmit(): void {
    const questions: Array<{ question: string; answer: string }> = [];
    for (const multiQuestion of this.questions) {
      const { question, options } = multiQuestion;
      const otherOption = options[options.length - 1];
      if (otherOption.isChecked && !otherOption.otherValue) {
        this.errorMessage = 'Other field is empty';
        return;
      }
      const answer = options
        .filter((op) => op.isChecked)
        .map((op) => {
          return op.value === 'Other' ? op.otherValue : op.value;
        })
        .join(', ');
      questions.push({ question, answer });
    }
    this.accountService.addUserEntryQuestionnaire({ questions }).then(() => {
      console.debug(`Submitted entry questionnaire: ${questions.map((q) => `${q.question} ${q.answer}`).join('; ')}`);
      EntryQuestionnaireDialogComponent.isOpened = false;
      this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['entry-questionnaire'] }).then();
      this.dialogRef.close();
    });
  }

  onCheckBoxClick(questionIndex: number, answer: string): void {
    assertTruthy(questionIndex >= 0 && questionIndex < this.questions.length);
    const option = this.questions[questionIndex].options.find((op) => op.value === answer);
    assertTruthy(
      option,
      `Couldn't find a option (${answer}) for the question: ${this.questions[questionIndex].question}`
    );
    option.isChecked = !option.isChecked;
    if (answer === 'Other' && option.isChecked) {
      const input = truthy(
        document.getElementById(`${this.otherInputIdPrefix}${questionIndex}`),
        `Can't find 'Other' text input`
      );
      input.focus();
    }
  }
}
