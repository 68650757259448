<mat-dialog-content *ngIf="uiStateObs | async as uiState" data-cy="change-instance-name-dialog">

  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title dialogTitle="Service Name"></cp-dialog-title>
  <form (ngSubmit)="onSubmit()" [formGroup]="changeInstanceNameForm" class="form">
    <div class="seed_form_element">
      <label>Name</label>
      <input spellcheck="false" data-cy="instance-name-input" formControlName="name" name="name" placeholder="">
      <div *ngIf="!changeInstanceNameForm.controls['name'].valid && changeInstanceNameForm.controls['name'].touched"
           class="seed_error">
        Please enter a new service name
      </div>
    </div>

    <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>

    <div class="buttons">
      <button [disabled]="changeInstanceNameForm.invalid || uiState.buttonDisabled"
              class="submit_button"
              color="primary"
              data-cy="update-button"
              mat-flat-button
              type="submit">
        Update
      </button>
      <button [mat-dialog-close]="false" class="close_button" mat-button>Cancel</button>
    </div>
  </form>
</mat-dialog-content>
