import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { isDefined } from '@cp/common/protocol/Common';
import { InstanceMysqlSettings, Instance, InstanceState } from '@cp/common/protocol/Instance';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { InstancesState } from '@cp/web/app/instances/protocol/InstanceStates';
import { distinctUntilChanged, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * An instance with an 'apiState' different from the 'state'. Used locally by InstanceStateService.
 * The reason for an extra 'apiState' to exist is that some operations in CP WEB module to improve UI robustness
 * update local 'instance.state' field before receiving actual API response: Example 'run'->'stopping' transition.
 *
 * In this case if the API call fails the method needs to restore the last and the most actual instance state
 * known by WEB: the state returned by the last successful API call/WS update.
 */
interface InstanceWithApiState extends Instance {
  apiState: InstanceState | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class InstanceStateService extends BaseUiStateService<InstancesState> {
  constructor(stateService: StateService) {
    super(['instances'], stateService);
  }

  /** Adds/updates single instance details. */
  setInstance(instance: Instance): void {
    const instanceWithApiState: InstanceWithApiState = { ...instance, apiState: instance.state };
    this.stateService.setInPath([...this.STATE_PATH, 'instances', instance.id], instanceWithApiState);
  }

  /** Clears all current instance records stored in 'state' and sets new instances from the array. */
  setInstances(instances: Record<string, Instance>): void {
    const instancesWithApiState: Record<string, InstanceWithApiState> = {};
    for (const key in instances) {
      const instance = instances[key];
      instancesWithApiState[key] = { ...instance, apiState: instance.state };
    }
    this.setStateKey('instances', instancesWithApiState);
  }

  deleteInstance(instanceId: string): void {
    this.stateService.runInBatch(() => {
      this.stateService.deletePath([...this.STATE_PATH, 'instances', instanceId]);
      this.stateService.deletePath([...this.STATE_PATH, 'passwords', instanceId]);
    });
  }

  /**
   * Updates local (stored inside the CP WEB module) instance state.
   * The update might be a real data from CP API module (source = 'api') or a optimistic (speculated)
   * state update set before a request to CP API module in order to improve UI responsiveness.
   */
  setInstanceState(instanceId: string, state: InstanceState, source: 'api' | 'optimistic' = 'api'): void {
    const instance = this.getInstance(instanceId);
    if (instance) {
      if (source === 'api') {
        this.stateService.setInPath([...this.STATE_PATH, 'instances', instance.id, 'apiState'], instance.state);
      } else {
        // Only 'progress' state can be set in 'optimistic' mode.
        // These state should not allow further state changes until confirmed by API.
        assertTruthy(state === 'starting' || state === 'stopping' || state === 'terminating');
      }
      this.stateService.setInPath([...this.STATE_PATH, 'instances', instanceId, 'state'], state);
    }
  }

  observeInstances(): Observable<Record<string, Instance>> {
    return this.stateService
      .observePath<Record<string, Instance>>([...this.STATE_PATH, 'instances'])
      .pipe(filter(isDefined));
  }

  listInstances(): Record<string, Instance> {
    return this.stateService.getStateInPath<Record<string, Instance>>([...this.STATE_PATH, 'instances']) || {};
  }

  clearInstances(): void {
    this.stateService.deletePath([...this.STATE_PATH, 'instances']);
    this.stateService.deletePath([...this.STATE_PATH, 'passwords']);
  }

  setInstancePassword(instanceId: string, password: string): void {
    this.stateService.setInPath([...this.STATE_PATH, 'passwords', instanceId], password);
  }

  setInstanceMysqlSettings(instanceId: string, settings: InstanceMysqlSettings): void {
    this.stateService.setInPath([...this.STATE_PATH, 'mysqlSettings', instanceId], settings);
  }

  observeInstancePassword(instanceId: string): Observable<string | undefined> {
    return this.stateService.observePath<string | undefined>([...this.STATE_PATH, 'passwords', instanceId]);
  }

  observeInstance(instanceId: string): Observable<Instance | undefined> {
    return this.stateService
      .observePath<Instance | undefined>([...this.STATE_PATH, 'instances', instanceId])
      .pipe(distinctUntilChanged());
  }

  observeInstanceMysqlSettings(instanceId: string): Observable<InstanceMysqlSettings | undefined> {
    return this.stateService
      .observePath<InstanceMysqlSettings | undefined>([...this.STATE_PATH, 'mysqlSettings', instanceId])
      .pipe(distinctUntilChanged());
  }

  getInstance(instanceId: string): Instance | undefined {
    return this.stateService.getStateInPath<Instance | undefined>([...this.STATE_PATH, 'instances', instanceId]);
  }

  getInstanceOrFail(instanceId: string): Instance {
    return <Instance>truthy(this.getInstance(instanceId), () => 'Instance not found: ' + instanceId);
  }

  getMysqlSettings(instanceId: string): InstanceMysqlSettings | undefined {
    return this.stateService.getStateInPath<InstanceMysqlSettings | undefined>([
      ...this.STATE_PATH,
      'mysqlSettings',
      instanceId
    ]);
  }

  restoreInstanceStateFromApiState(instanceId: string): void {
    const { apiState } = this.getInstanceOrFail(instanceId) as InstanceWithApiState;
    if (apiState) {
      this.setInstanceState(instanceId, apiState);
    }
  }
}
