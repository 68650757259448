<ng-container *ngIf="myRoleObs | async as myRole">
  <div class="container">
    <div class="text">
      By enabling
      <a target="_blank" href="https://clickhouse.com/docs/en/interfaces/mysql"
        >ClickHouse MySQL Interface</a
      >, your service will expose the network port 3306 for the MySQL wire
      protocol.
    </div>
    <div class="toggle">
      <mat-slide-toggle
        color="primary"
        [disabled]="myRole !== 'ADMIN'"
        [checked]="mysqlSettings.enabled"
        (change)="toggleMysqlEnabled($event)"
        data-cy="mysql-protocol-checkbox"
      ></mat-slide-toggle>
      <label class="toggle-label">Enable MySQL Interface</label>
    </div>
    <form
      *ngIf="mysqlPasswordForm && enteringPassword"
      (ngSubmit)="submitMysqlPassword()"
      [formGroup]="mysqlPasswordForm"
      class="form"
    >
      <div class="row">
        <div class="seed_form_element">
          <label>Default user password</label>
          <input
            type="password"
            formControlName="password"
            data-cy="mysql-password-input"
          />
        </div>
        <button
          [disabled]="mysqlPasswordForm.invalid"
          class="password-button"
          color="primary"
          mat-flat-button
          type="submit"
          data-cy="mysql-password-button"
        >
          Enable MySQL
        </button>
      </div>
    </form>
    <div *ngIf="error" class="seed_error" data-cy="mysql-protocol-error">
      Error toggling MySQL Interface: {{ error }}
    </div>
    <cp-code-snippet
      *ngIf="mysqlSettings.enabled && !enteringPassword"
      class="fs-exclude"
      [copyTransformer]="removeNewlinesAndBackslashesTransformer"
      [text]="text"
    ></cp-code-snippet>
  </div>
</ng-container>
