import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GalaxyEventType } from '@cp/common/protocol/Galaxy';
import {
  buildAllowListCurrentIpDescription,
  IpAccessListEntry,
  MAX_IP_ACCESS_LIST_DESCRIPTION_LENGTH
} from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isIpAccessSource } from '@cp/common/utils/ValidationUtils';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { FullyQualifiedEvent, FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';
import { ipAccessListSourceValidator } from '@cp/web/app/instances/ip-access-list/ip-access-list-common';

@Component({
  selector: 'cp-add-ip-access-list-entry-form',
  templateUrl: './add-ip-access-list-entry-form.component.html',
  styleUrls: ['./add-ip-access-list-entry-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddIpAccessListEntryFormComponent implements OnInit {
  addEntryFormGroup: FormGroup;

  @Input() sourceInputLabelText = 'Add entries';

  @Input() saveButtonLabel = 'Add entry';

  @Input() isCancelButtonVisible = false;

  @Input() isAddCurrentIpButtonVisible = true;

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  @Output() entryAdded = new EventEmitter<IpAccessListEntry>();

  @Output() cancelClicked = new EventEmitter<void>();

  @ViewChild('descriptionInput', { static: true }) descriptionInput!: ElementRef;

  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly formBuilder: FormBuilder
  ) {
    this.addEntryFormGroup = this.formBuilder.group({
      value: ['', [Validators.required, ipAccessListSourceValidator]],
      description: ['', Validators.maxLength(MAX_IP_ACCESS_LIST_DESCRIPTION_LENGTH)]
    });
  }

  ngOnInit(): void {
    assertTruthy(this.eventPrefix);
  }

  get currentIP(): string {
    const ipAccessListSource = this.accountStateService.getUserDetailsOrFail().ipAddress || '';
    // Not all 'ipAddress' forms are valid for use with IP filtering.
    // For example IP6 are not (not confirmed as safe by DP).
    return isIpAccessSource(ipAccessListSource) ? ipAccessListSource : '';
  }

  addCurrentIP(): void {
    const user = this.accountStateService.getUserDetailsOrFail();
    this.addEntryFormGroup.controls['value'].setValue(this.currentIP);
    this.addEntryFormGroup.controls['description'].setValue(buildAllowListCurrentIpDescription(user.name));
    this.descriptionInput.nativeElement.focus();
  }

  addEntry(): void {
    const valueControl = this.addEntryFormGroup.controls['value'];
    const descriptionControl = this.addEntryFormGroup.controls['description'];
    this.entryAdded.emit({ source: valueControl.value, description: descriptionControl.value });
    valueControl.setValue('');
    descriptionControl.setValue('');
  }

  handleCancelButtonClick(): void {
    this.cancelClicked.emit();
  }

  buildFullyQualifiedEvent(eventName: GalaxyEventType): FullyQualifiedEvent {
    return `${this.eventPrefix}.${eventName}`;
  }
}
