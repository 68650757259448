<div class="org_settings" data-cy="org-settings">
  <div class="side_padding">
    <div class="inner_page_nav">
      <div class="inner_page_title">Admin</div>
    </div>

    <div class="inner_page_body">
      <table>
        <tr>
          <td class="sidebar_column">
            <cp-internal-sidebar
              title="Organization"
              titleIcon="building"
              [menuButtons]="internalSidebarMenuButtons"
            ></cp-internal-sidebar>
          </td>
          <td>
            <div class="content_wrapper" [ngSwitch]="getActiveTab()">
              <cp-admin-general *ngSwitchCase="'GENERAL'" data-cy="admin-general"></cp-admin-general>
              <cp-admin-billing *ngSwitchCase="'BILLING'" data-cy="admin-billing"></cp-admin-billing>
              <cp-admin-usage *ngSwitchCase="'USAGE'" data-cy="admin-usage"></cp-admin-usage>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
