import { MILLIS_PER_DAY } from '@cp/common/utils/DateTimeUtils';

/**
 * Since the meter ends the day at midnight UTC (8PM EST, 5PM PST), trials might feel like they end prematurely in some cases.
 * We'll give an extra day and show one day less in the countdown.
 */
export const TRIAL_DURATION_IN_DAYS = 31;

/** How long after an organization becomes IN_NONPAYMENT_GRACE_PERIOD should we wait before stopping their services. */
export const STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS = 15;
export const STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_MILLIS =
  STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS * MILLIS_PER_DAY;

/** How long after an organization becomes IN_NONPAYMENT_GRACE_PERIOD should we wait before decommissioning them. */
export const DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS = 30;
export const DECOMMISSION_DQ_ORGS_THRESHOLD_IN_MILLIS = DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS * MILLIS_PER_DAY;
