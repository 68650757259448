<cp-add-ip-access-list-entry-form *ngIf="isFormVisible"
                                  [eventPrefix]="eventPrefix"
                                  [saveButtonLabel]="isFormOrTableMode? 'Submit' : 'Add entry'"
                                  [isCancelButtonVisible]="isFormOrTableMode"
                                  [isAddCurrentIpButtonVisible]="!isFormOrTableMode"
                                  (entryAdded)="addEntry($event)"
                                  (cancelClicked)="handleCancelClickInForm()"
></cp-add-ip-access-list-entry-form>

<div class="inputs_and_table_separator" *ngIf="isFormVisible && isTableVisible"></div>

<cp-ip-access-list-table-view *ngIf="isTableVisible"
                              [eventPrefix]="eventPrefix"
                              [showNoAccessMessageOnEmptyList]="false"
                              [ipAccessList]="ipAccessList"
                              [actionButtons]="['DELETE']"
                              (deleteButtonClick)="deleteEntry($event)"
></cp-ip-access-list-table-view>

<div class="actions_row" *ngIf="isFormOrTableMode && isTableVisible">

  <div class="status_label">
    <div *ngIf="statusMessage" class="icon_and_label_pair">
      <mat-icon svgIcon="check_icon" class="status_label_icon"></mat-icon>
      {{statusMessage}}
    </div>
  </div>

  <button
    mat-flat-button
    (click)="handleAddEntryButtonClick()"
    color="primary"
    class="add_entry_button"
    data-cy="table-and-form-add-entry-button"
  >Add entry
  </button>
</div>


