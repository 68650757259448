import { Injectable } from '@angular/core';
import { WebSocketService } from '@cp/common-services/web-socket.service';
import { InstanceBackupUpdatePayload, ListInstanceBackupsResponse, PostBackupAction } from '@cp/common/protocol/Backup';
import { WithInstanceId } from '@cp/common/utils/ProtocolUtils';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { InstanceBackupApiService } from '@cp/web/app/instances/instance-backups/instance-backup-api.service';
import { InstanceBackupStateService } from '@cp/web/app/instances/instance-backups/instance-backup-state.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { merge, NEVER, switchMap, tap } from 'rxjs';

export interface CreateInstanceBackupInput extends WithInstanceId {
  postBackupAction: PostBackupAction;
}

@Injectable({
  providedIn: 'root'
})
export class InstanceBackupService {
  constructor(
    private readonly instanceBackupStateService: InstanceBackupStateService,
    private readonly instanceBackupApiService: InstanceBackupApiService,
    private readonly instanceStateService: InstanceStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly webSocketService: WebSocketService,
    private readonly accountStateService: AccountStateService
  ) {
    this.accountStateService
      .observeUserId()
      .pipe(
        switchMap((userId) => {
          if (!userId) {
            return NEVER;
          }
          return this.organizationStateService.observeCurrentOrganizationId().pipe(
            /** Using switchMap so if the currentOrganizationId will change, RxJs will automatically unsubscribe from the
             *  previous subscriptions and will create new subscriptions. **/
            switchMap((currentOrganizationId) => {
              this.instanceBackupStateService.clearBackups();
              if (!currentOrganizationId) {
                return NEVER;
              }
              return merge(this.listenToNonAdminNotifications());
            })
          );
        })
      )
      .subscribe();
  }

  async createInstanceBackup(instanceId: string): Promise<void> {
    await this.instanceBackupApiService.createInstanceBackup(instanceId);
  }

  async listInstanceBackups(instanceId: string): Promise<ListInstanceBackupsResponse> {
    return this.instanceBackupApiService.listInstanceBackups(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId
    );
  }

  async refreshInstanceBackups(instanceId: string): Promise<void> {
    const response = await this.listInstanceBackups(instanceId);
    this.instanceBackupStateService.setInstanceBackups(instanceId, []);
    for (const backup of response.backups) {
      this.instanceBackupStateService.setInstanceBackup(backup);
    }
  }

  private listenToNonAdminNotifications() {
    return this.listenToBackupUpdate();
  }

  private listenToBackupUpdate() {
    return this.webSocketService
      .observeNotification<InstanceBackupUpdatePayload>({
        type: 'ORG_INSTANCE_BACKUP_UPDATE',
        objId: this.organizationStateService.getCurrentOrgIdOrFail()
      })
      .pipe(
        tap(({ payload }) => {
          const { backupsByInstanceId } = payload;
          for (const instanceId in backupsByInstanceId) {
            const backups = backupsByInstanceId[instanceId];
            this.instanceBackupStateService.setInstanceBackups(instanceId, backups);
          }
        })
      );
  }
}
