<mat-dialog-content *ngIf="createOrganizationUiStateObs | async as uiState"
                    class="create_organization_dialog"
                    data-cy="create-organization-dialog"
>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title dialogTitle="Create Organization"></cp-dialog-title>
  <form (ngSubmit)="onSubmit()" [formGroup]="createOrganizationForm" class="form">
    <div class="seed_form_element">
      <label>Organization name</label>
      <input data-cy="organization-name-input" formControlName="name" name="organizationName"
             type="text" spellcheck="false"/>
      <div *ngIf="!createOrganizationForm.controls['name'].valid && createOrganizationForm.controls['name'].touched"
           class="seed_error">
        Invalid organization named entered
      </div>
    </div>

    <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>

    <div class="vertical_buttons">
      <button [disabled]="createOrganizationForm.invalid || uiState.createOrganizationButtonDisabled" class="button"
              color="primary" data-cy="create-button" mat-flat-button
              type="submit">
        Create Organization
      </button>
      <button (click)="dialogRef.close()" class="close_button" mat-stroked-button type="button">Cancel</button>
    </div>
  </form>
</mat-dialog-content>
