<mat-dialog-content class="nonpayment_dialog" data-cy="nonpayment-dialog">
  <div class="dialog_container" *ngIf="myRoleObs | async as myRole">
    <div class="backgrounds">
      <div class="background-layer1"></div>
      <div class="background-layer2"></div>
    </div>
    <div class="clock_icon_container">
      <mat-icon class="icon" svgIcon="error_icon_yellow"></mat-icon>
    </div>
    <div class="title" data-cy="nonpayment-title">Oops! Your payment didn’t go through.</div>
    <div class="text" data-cy="nonpayment-text">
      We are unable to process payment for your latest invoice. Please
      <span *ngIf="myRole === 'ADMIN'">
        update your payment details
      </span>
      <span *ngIf="myRole === 'DEVELOPER'">
        contact your system admin
      </span>
      to continue using ClickHouse Cloud.
      <br>
      <br>
      <div class="paragraph_small">Services will be automatically suspended
        after {{STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS}} days of nonpayment.
        Services and data will be permanently deleted after {{DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS}} days.
        If you need more time to resolve payment issues or think you’re receiving this message in error, please contact
        our <a href="mailto:sales@clickhouse.com">sales team</a>.
      </div>
    </div>

    <div class="buttons">
      <button *ngIf="myRole === 'ADMIN'"
              (click)="goToBilling()" class="main_button"
              color="primary"
              data-cy="update-payment-button"
              mat-flat-button
      >Update payment details
      </button>
      <button (click)="goToSupport()" class="main_button"
              color="{{myRole === 'ADMIN' ? 'secondary' : 'primary'}}"
              data-cy="contact-support"
              mat-flat-button
      >Contact support
      </button>
    </div>
  </div>
</mat-dialog-content>
