import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@cp/web/environments/environment';

export interface CredentialDetails {
  user: string;
  password: string;
}

@Component({
  selector: 'cp-credentials-container',
  templateUrl: './credentials-container.component.html',
  styleUrls: ['./credentials-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CredentialsContainerComponent implements OnChanges {
  @Input()
  password?: string;

  @Input()
  username?: string;

  @Output()
  credentialsChanged = new EventEmitter<CredentialDetails | undefined>();

  credentialsForm: FormGroup;

  constructor(private readonly formBuilder: FormBuilder) {
    this.credentialsForm = this.formBuilder.group({
      user: ['', [Validators.required]],
      password: ['', environment.stage === 'local' ? null : [Validators.required]]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['password']) {
      this.credentialsForm.controls['password'].setValue(this.password);
    }
    if (changes['username']) {
      this.credentialsForm.controls['user'].setValue(this.username);
    }
    this.onCredentialsChange();
  }

  onCredentialsChange(): void {
    const credentials: CredentialDetails | undefined = this.credentialsForm.valid
      ? this.credentialsForm.value
      : undefined;
    this.credentialsChanged.emit(credentials);
  }
}
