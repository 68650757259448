import { IpAccessListEntry } from '@cp/common/protocol/Instance';
import { MILLIS_PER_DAY, MILLIS_PER_HOUR, MILLIS_PER_MINUTE } from '@cp/common/utils/DateTimeUtils';
import { RpcRequest, WithInstanceId, WithOrganizationId } from '@cp/common/utils/ProtocolUtils';

/** URL path for backup handler: /api/backup. */
export const BACKUP_API_PATH = 'backup';

/** Failed backup will be restarted every 20 minutes. */
export const FAILED_BACKUP_RESTART_INTERVAL_MILLIS = 20 * MILLIS_PER_MINUTE;

/** Failed backup will not be restarted anymore after 3 hours or retries. */
export const FAILED_BACKUP_RESTART_GIVE_UP_MILLIS = 3 * MILLIS_PER_HOUR;

/** How often backups are created by cron job. */
export const INSTANCE_BACKUP_INTERVAL_MILLIS = MILLIS_PER_DAY;

/** Maximum successful backups in the chain. Once reached, a new full backup is created. */
export const INSTANCE_BACKUP_MAX_INCREMENTAL_CHAIN_LENGTH = 7;

/** Set of all RPC actions for 'backup' handler. */
export type BackupRpcAction = 'create' | 'delete' | 'list' | 'restore';

export type BackupRpcRequest<T extends BackupRpcAction> = RpcRequest<T>;

export type InstanceBackupUpdatePayload = {
  /**
   * Type of the update: all available backups (COMPLETE) or only updated ones (PARTIAL or undefined).
   * Deprecated: should be removed after 1 release interval.
   */
  updateType: 'COMPLETE';

  /** List of updated backups. Deprecated: should be removed after 1 release interval. */
  backups: Array<InstanceBackup>;

  backupsByInstanceId: Record<string, Array<InstanceBackup>>;
};

export interface CreateInstanceBackupRequest extends WithOrganizationId, WithInstanceId, BackupRpcRequest<'create'> {}

export interface CreateInstanceBackupResponse {
  backupId: string;
}

export type ListInstanceBackupsRequest = WithOrganizationId & WithInstanceId & BackupRpcRequest<'list'>;

export interface ListInstanceBackupsResponse {
  /** Deprecated. Remove after 2 weeks release cycle. */
  backupsIntervalInMilliseconds: number;
  backups: Array<InstanceBackup>;
}

/** Using org & instance ids so Middy can verify this instance is part of the organization */
export interface DeleteInstanceBackupRequest extends WithOrganizationId, WithInstanceId, BackupRpcRequest<'delete'> {
  backupId: string;
}

export interface RestoreInstanceBackupRequest extends WithOrganizationId, WithInstanceId, BackupRpcRequest<'restore'> {
  backupId: string;
  instanceName: string;
  /** Hashed password. Uses makeSha256HexString(). Must be compatible with DP. */
  passwordHash: string;
  /** SHA1 hashed password. Uses makeDoubleSha1(). Must be compatible with DP. */
  doubleSha1Password: string;
}

/** Response when instance is restored from backup. */
export interface RestoreInstanceBackupResponse {
  /** New (restored) instance id. */
  instanceId: string;
  /**
   * Initial state of the IP access list for the instance after backup.
   * Inherited from the restored instance state.
   */
  ipAccessList: Array<IpAccessListEntry>;
}

/** Data-plane status of the backup. */
export const INSTANCE_BACKUP_STATUS_TYPE = ['done', 'error', 'in_progress'] as const;
export type InstanceBackupStatus = (typeof INSTANCE_BACKUP_STATUS_TYPE)[number];

export type PostBackupAction = 'INSTANCE_UPGRADE';

export interface InstanceBackup {
  id: string;
  instanceId: string;
  status: InstanceBackupStatus;
  createdAt: number;
  finished?: number;
  postBackupAction?: PostBackupAction;
}

/** Asserts that backup is finished and has a finish date. */
export function isBackupFinished(backup: InstanceBackup): backup is InstanceBackup & { finished: number } {
  return backup.finished !== undefined;
}
