import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportCaseDetails } from '@cp/common/protocol/Support';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isSupportCaseReply } from '@cp/common/utils/ValidationUtils';
import { SupportService } from '@cp/web/app/support/support.service';

@Component({
  selector: 'cp-case-reply',
  templateUrl: './case-reply.component.html',
  styleUrls: ['./case-reply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseReplyComponent implements OnInit, OnChanges {
  @Input()
  caseDetails!: SupportCaseDetails;

  @Output()
  replySuccessful = new EventEmitter<void>();

  replyInProgress = false;
  uploadInProgress = false;
  replyText?: string;
  private storageIds: Array<string> = [];

  constructor(
    private readonly supportService: SupportService,
    private readonly snackBar: MatSnackBar,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    assertTruthy(this.caseDetails);
  }

  ngOnChanges(): void {
    assertTruthy(this.caseDetails);
  }

  get isValidSupportCaseReply(): boolean {
    return isSupportCaseReply(this.replyText);
  }

  async submitReply() {
    this.replyInProgress = true;
    try {
      await this.supportService.reportCaseEvent(
        this.caseDetails.id,
        'reply',
        this.replyText,
        'WAITING_ON_CLICKHOUSE',
        this.storageIds
      );
      this.replyText = '';
      this.snackBar.open('Reply submitted', 'Dismiss', { duration: 5000 });
      this.replySuccessful.emit();
    } catch (e) {
      console.error(e);
      this.snackBar.open('Unable to reply to case, please try again later', 'Dismiss', { duration: 5000 });
    } finally {
      this.replyInProgress = false;
      this.cd.markForCheck();
    }
  }

  handleUploadStarted() {
    this.uploadInProgress = true;
  }

  handleUploadFinished(storageIds: Array<string>) {
    this.uploadInProgress = false;
    this.storageIds = storageIds;
  }
}
