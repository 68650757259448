<div *ngIf="instanceObs | async as instance" class="first_time_set_ip_access_list">
  <cp-dialog-title dialogTitle="Where would you like to connect from?"></cp-dialog-title>

  <div class="under_title_notice">
    To ensure an extra layer of security for your service, we recommend only allowing traffic from IP addresses that
    you know and trust.
  </div>
  <div class="text_tabs_block">
    <div class="text_tabs_column">
      <div
        [galaxyClick]="['auth.onboarding2.accessOptionSelect', {selectedOption: 'whitelist'}]"
        (click)="onSpecificLocationOptionClicked()"
        [ngClass]="activeTab === 'SPECIFIC_LOCATION' ? 'active_tab_block' : ''"
        class="text_tab_button"
        data-cy="specific-location-button"
      >
        <div class="text_tab_button_title">
          Specific locations
          <div *ngIf="activeTab === 'SPECIFIC_LOCATION'" class="check_icon_container">
            <mat-icon class="icon" svgIcon="check_icon"></mat-icon>
          </div>
        </div>
        <div class="text_tab_button_description">Control where your service can be accessed from</div>
      </div>
    </div>
    <div class="text_tabs_column">
      <div
        [galaxyClick]="['auth.onboarding2.accessOptionSelect', {selectedOption: 'anywhere'}]"
        (click)="onAnywhereOptionClicked()"
        [ngClass]="activeTab === 'ANYWHERE' ? 'active_tab_block' : ''"
        class="text_tab_button"
        data-cy="anywhere-button"
      >
        <div class="text_tab_button_title">
          Anywhere
          <div *ngIf="activeTab === 'ANYWHERE'" class="check_icon_container">
            <mat-icon class="icon" svgIcon="check_icon"></mat-icon>
          </div>
        </div>
        <div class="text_tab_button_description">Allow service access to anyone with your credentials</div>
      </div>
      <div *ngIf="activeTab === 'ANYWHERE'" class="anywhere_warning">
        We do not recommend allowing <br> public access to your service
      </div>
    </div>
  </div>

  <div *ngIf="activeTab === 'SPECIFIC_LOCATION'" class="specific_location_block" data-cy="specific-location-block">
    <cp-add-ip-access-list-form-and-table
      eventPrefix="auth.onboarding2"
      (ipAccessListChange)="onIpAccessListChange($event)"
      [ipAccessList]="ipAccessList"
      [mode]="'FORM_OR_TABLE'"
    ></cp-add-ip-access-list-form-and-table>
  </div>
</div>


