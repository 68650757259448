import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  getIntegrationReadinessLevelText,
  getIntegrationSupportLevelText,
  Integration,
  ReadinessLevel
} from '@cp/common/protocol/Integration';

import { assertTruthy } from '@cp/common/utils/Assert';
import { SegmentService } from '@cp/web/app/common/services/segment.service';

@Component({
  selector: 'cp-integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationCardComponent implements OnInit, OnChanges {
  @Input() integration!: Integration;

  constructor(
    private readonly router: Router,
    private readonly segmentService: SegmentService
  ) {}

  ngOnInit(): void {
    assertTruthy(this.integration, 'Integration card must have an integration data attached to it');
  }

  ngOnChanges(): void {
    assertTruthy(this.integration, 'Integration card must have an integration data attached to it');
  }

  get supportClassName(): string {
    const sl = this.integration.supportLevel;
    return sl === 'CORE'
      ? 'core_support_marker'
      : sl === 'PARTNER'
      ? 'partner_support_marker'
      : 'community_support_marker';
  }

  get supportLevel(): string {
    return getIntegrationSupportLevelText(this.integration.supportLevel);
  }

  getReadinessText(readinessLevel: ReadinessLevel): string {
    return getIntegrationReadinessLevelText(readinessLevel);
  }

  onViewIntegrationClick(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'integrationSelect',
      category: 'integrationList',
      properties: {
        integration_name: this.integration.slug
      },
      view: 'integrationPage',
      component: 'integrationCard'
    });
    this.router.navigate([`integrations/${this.integration.slug}`]).then();
  }
}
