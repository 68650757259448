import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FontsResolverService implements Resolve<void> {
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    // Load the fonts. Note: The 10px is just a dummy because there is no way to not provide the size.
    await Promise.all([
      document.fonts.load('10px "Inter"'),
      document.fonts.load('10px "Material Icons"'),
      document.fonts.load('10px "Hind Siliguri"')
    ]);
  }
}
