<ng-container *ngIf="currentOrganizationObs | async as currentOrganization">
  <ng-container *ngIf="otherOrganizationsObs | async as otherOrganizations">
    <ng-container *ngIf="userDetailsObs | async as userDetails">
      <ng-container *ngIf="invitationsObs | async as invitations">
        <header class="side_padding" [ngClass]="headerClasses" data-cy="header">
          <div *ngIf="enabledUserFeaturesFlags.length > 0 && isFFBannerEnabled"
               class="features_flags_container">
            <div *ngFor="let flag of enabledUserFeaturesFlags"
                 class="experiment_flag_badge"
                 [attr.title]="'Enabled experiment: '+ flag"
            >
              {{flag}}
            </div>
          </div>
          <div *ngIf="backRouteData" class="back_route_button" data-cy="back-route-button" (click)="navigateBack()">
            <mat-icon class="icon back_icon svg_fill" svgIcon="chevron_left"></mat-icon>
            <div class="back_route_button_text">{{backRouteData.backText}}</div>
          </div>
          <button [matMenuTriggerFor]="organizationDropdown" class="organization_dropdown_button"
                  [disabled]="otherOrganizations.length === 0 && !canCreateOrganizations(userDetails)"
                  data-cy="organization-name-drop-down-button"
                  mat-button>
            <span class='org_name fs-exclude'>{{currentOrganization.name}}</span>
            <mat-icon
              *ngIf="otherOrganizations.length || canCreateOrganizations(userDetails)"
              class="org_icon"
              svgIcon="building"></mat-icon>
            <mat-icon
              *ngIf="otherOrganizations.length || canCreateOrganizations(userDetails)"
              class="icon menu_drop_down_icon"
              svgIcon="down_arrow"></mat-icon>
          </button>
          <div class="vert_divider"></div>
          <div class="mid_icons">
            <mat-icon (click)="showHelpFlyout()" class="icon svg_fill" svgIcon="help"></mat-icon>
            <div *ngIf="invitations.length" class="notifications">
              <mat-icon
                (click)="showInvitationsPopup()"
                class="icon svg_fill"
                data-cy="notification-icon"
                svgIcon="bell">
                <div class="notification-bubble">
                  <div data-cy="notification-count">{{invitations.length}}</div>
                </div>
              </mat-icon>
            </div>
          </div>

          <div class="vert_divider"></div>
          <cp-avatar [fixedFontSize]="10" [matMenuTriggerFor]="profileMenu" [name]="userDetails.name"
                     class="avatar"></cp-avatar>
        </header>

        <mat-menu #organizationDropdown="matMenu" class="organization_dropdown" xPosition="before">
          <button (click)="switchOrganization(organization)"
                  *ngFor="let organization of otherOrganizations; trackBy: trackById"
                  data-cy="organization-menu-button"
                  mat-menu-item
                  class="fs-exclude"
          >{{organization.name}}</button>
          <mat-divider *ngIf="otherOrganizations.length"></mat-divider>
          <button (click)="showCreateOrganizationDialog()"
                  *ngIf="canCreateOrganizations(userDetails)"
                  data-cy="create-organization-menu-button"
                  mat-menu-item>
            <mat-icon class="icon svg_fill">add</mat-icon>
            Create Organization
          </button>
        </mat-menu>

        <mat-menu #profileMenu="matMenu" class="profile_menu" xPosition="before">
          <div class="profile_menu_top">
            <div class="signed_in_as">Signed in as</div>
            <div class="user_name fs-exclude">{{userDetails.name}}</div>
          </div>
          <div class="menu_buttons">
            <button class="menu_button" data-cy="profile-menu-item" mat-menu-item routerLink="/profile">Profile</button>
            <button (click)="signOut()" class="menu_button" data-cy="sign-out-menu-item" mat-menu-item>Sign out</button>
          </div>

        </mat-menu>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
