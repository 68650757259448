import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { TrialServiceLimitUiState } from '@cp/web/app/instances/protocol/InstanceStates';

@Injectable({
  providedIn: 'root'
})
export class TrialServiceLimitUiService extends BaseUiStateService<TrialServiceLimitUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'trialServiceLimit'], stateService);
  }
}
