<div *ngIf="!hasUserData && !['terminating', 'failed', 'provisioning'].includes(instance.state)" class="instance_no_data_message" data-cy="instance-no-data-message">
  <mat-icon class="icon" svgIcon="info_circle"></mat-icon>
  <div class="notification_text">
    This service has no data. To create tables and run queries, visit the
    <a class="notification_link" [href]="getWebConsoleUri(instance)" target="_blank">SQL console.</a>
  </div>
</div>

<div class="summary_container">
  <div class="state_line">
    <cp-instance-state [state]="instance.state" kind="ICON_AND_LABEL"></cp-instance-state>
    <cw-badge *ngIf="isMaintenanceInProgress" type="info" data-cy="maintenance-in-progress-badge">
      Maintenance in progress
    </cw-badge>
    <cp-instance-no-access-prompt [instanceId]="instance.id"></cp-instance-no-access-prompt>
  </div>

  <div class="separator_line"></div>
  <div *ngIf="!isDedicated" class="stats_columns">
    <div class="column">
      <div class="title">Location</div>
      <div class="cell location_cell">
        <div class="location_label">{{instanceRegionName}}</div>
      </div>
    </div>
    <div class="column">
      <div class="title">Version</div>
      <div class="cell">ClickHouse {{instance.clickhouseVersion}}</div>
    </div>
    <div class="column">
      <div class="title">Last successful backup</div>
      <div class="cell">{{(lastBackupDateObs | async)}}</div>
    </div>
    <div class="column">
      <div class="title">Created</div>
      <div class="cell">{{instance.creationDate | date}}</div>
    </div>
  </div>
  <div *ngIf="isDedicated" class="stats_columns">
    <div class="column">
      <div class="title">Location</div>
      <div class="cell location_cell">
        <div class="location_label">{{instanceRegionName}}</div>
      </div>
    </div>
    <div class="column">
      <div class="title">Last successful backup</div>
      <div class="cell">{{lastBackupDateObs | async}}</div>
    </div>
    <div class="column">
      <div class="title">Created</div>
      <div class="cell">{{instance.creationDate | date}}</div>
    </div>
  </div>
  <div *ngIf="isDedicated" class="stats_columns">
    <div class="column">
      <div class="title">Service Type</div>
      <div class="cell location_cell">
        <div class="location_label">{{instanceTierDetails.tier}}</div>
      </div>
    </div>
    <div class="column">
      <div class="title">Service Size</div>
      <div class="cell" *ngIf="scalingInfoObs | async as scalingInfo; else undefinedMaxMemory" >
        <ng-container *ngVar="instanceSizeDetails(scalingInfo.maxMemoryGb) as details">
          {{details.numCpus}} vCPU, {{details.nodeSize}} GiB RAM <span style="color: #888888">x {{details.numReplicas}} nodes</span>
        </ng-container>
      </div>
      <ng-template #undefinedMaxMemory>
        <div class="cell">Cannot get instance sizing details</div>
      </ng-template>
    </div>
    <div class="column">
      <div class="title">Version</div>
      <div class="cell">{{instance.clickhouseVersion}}</div>
    </div>
  </div>


</div>
