<ng-container *ngIf="caseDetailsObs | async as details">
  <mat-dialog-content>
    <div class="layout">
      <div class="title_container">
        <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
        <cp-dialog-title>
          <div class="dialog_title">Case quick view: <span class="case_id">{{details.caseNumber}}</span></div>
        </cp-dialog-title>
      </div>
      <div #caseDetailsContainer class="case_details_container">
        <div class="subject">{{details.subject}}</div>
        <div class="description_container">
          <span #supportDescriptionText class="description" [class.show-less]="descriptionExpanded === false">
            {{details.description}}
          </span>
          <span (click)="descriptionExpanded = !descriptionExpanded"
                *ngIf="
      supportDescriptionText.offsetHeight < supportDescriptionText.scrollHeight ||
      (descriptionExpanded === true && supportDescriptionText.offsetHeight > 40)"
                class=" expand_or_collapse">{{descriptionExpanded ? 'Collapse' : 'Read more'}}</span>
        </div>

        <div class="date">Sent {{details.lastModifiedDate | date: 'medium'}}</div>
        <cp-case-timeline (replyClicked)="scrollToReply()" [caseDetails]="details"
                          [showReplyButton]="true"></cp-case-timeline>
        <div class="separator"></div>
        <cp-case-reply [caseDetails]="details"></cp-case-reply>
      </div>
      <div class="buttons_container">
        <button (click)="scrollToReply()" class="action_button" mat-flat-button>Quick reply</button>
        <button (click)="dialogRef.close()" class="action_button" mat-flat-button>Close window</button>
        <button (click)="viewFullCaseDetails(details.id)" class="action_button" mat-flat-button>View full case</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-container>
