<ng-template #defaultTemplate let-item="item">
  {{item.label}}
</ng-template>
<div #seedSelect>
  <div class="seed_select"
       data-cy="seed-select"
       *ngIf="selectedOptionIndex >= 0 || label !== undefined"
       (click)="handleClick()"
       [ngClass]="{active: optionsMenuOpen, disabled: disabled}" [matMenuTriggerFor]="menu">
    <div class="seed_select_icon"></div>
    <div class="selected_option" data-cy="selected-option">
      <ng-template [ngTemplateOutletContext]="{item: selectedLabel, isSelected: true}"
                   [ngTemplateOutlet]="template || defaultTemplate"></ng-template>
    </div>
  </div>
  <mat-menu #menu="matMenu" class="sui-mat-menu">
    <div class="seed_select_options" [style.width.px]="seedSelect.offsetWidth" [attr.data-cy]="dataCy">
      <div *ngFor="let option of options; let i = index;trackBy: trackByLabel; let isLast = last;"
           class="seed_select_option"
           mat-menu-item
           [disabled]="option.disabled"
           [attr.data-cy]="option.dataCy ?? null"
           [attr.data-cy-value]="option.dataCyValue ?? null"
           [ngClass]="{selected: i === selectedOptionIndex, bottom_divider: withDivider && !isLast}"
           (click)="onUserSelect(i)">
        <ng-template [ngTemplateOutletContext]="{item: option, isSelected: false}"
                     [ngTemplateOutlet]="template || defaultTemplate"></ng-template>
      </div>
    </div>
  </mat-menu>
</div>
