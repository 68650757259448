import { Injectable } from '@angular/core';
import { isDefined } from '@cp/common/protocol/Common';
import { InstanceMemorySizeOptions, InstanceTier } from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import { getInstanceMemorySizeOptions } from '@cp/common/utils/InstanceUtils';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';

import { combineLatest, filter, map, Observable, switchMap } from 'rxjs';
import { InstanceStateService } from './instance-state.service';
import { InstanceService } from './instance.service';

@Injectable({
  providedIn: 'root'
})
export class InstanceAutoScalingService {
  constructor(
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly featuresService: FeaturesService
  ) {}

  observeMemorySizeOptionsForExistingInstance(instanceId: string): Observable<InstanceMemorySizeOptions> {
    const instanceObs = this.instanceStateService.observeInstance(instanceId).pipe(filter(isDefined));

    const isPayingOrgObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeBillingStatus(id)),
      map((billingStatus) => isPayingStatus(billingStatus))
    );

    const ftCustomAutoscaleValueObs = this.featuresService.observeInstanceFeature(
      instanceId,
      'FT_CUSTOM_AUTOSCALE_VALUES'
    );

    const limitsObs = this.instanceStateService.observeInstance(instanceId).pipe(
      filter(isDefined),
      switchMap((instance) => this.instanceService.getDpAutoScalingLimits(instance.regionId))
    );

    return combineLatest([isPayingOrgObs, ftCustomAutoscaleValueObs, instanceObs, limitsObs]).pipe(
      map(([isPaying, isCustomValuesFeatureEnabled, instance, limits]): InstanceMemorySizeOptions => {
        return getInstanceMemorySizeOptions(
          instance.regionId,
          instance.instanceTier,
          isCustomValuesFeatureEnabled,
          isPaying,
          instance.customAutoscaleValues,
          limits
        );
      })
    );
  }

  observeMemorySizeOptionsForNewInstance(
    regionId: RegionId,
    instanceTier: InstanceTier
  ): Observable<InstanceMemorySizeOptions> {
    const isPayingObs = this.organizationStateService.observeCurrentOrganization().pipe(
      filter(isDefined),
      switchMap((org) => this.organizationStateService.observeBillingStatus(org.id)),
      map((billingStatus) => isPayingStatus(billingStatus))
    );

    const limitsObs = this.instanceService.getDpAutoScalingLimits(regionId);

    return combineLatest([isPayingObs, limitsObs]).pipe(
      map(([isPaying, limits]) => {
        const customValuesFeatureEnabled = false;
        const customAutoscaleValues = new Array<number>();

        return getInstanceMemorySizeOptions(
          regionId,
          instanceTier,
          customValuesFeatureEnabled,
          isPaying,
          customAutoscaleValues,
          limits
        );
      })
    );
  }
}
