import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  checkPasswordContainLowercaseChars,
  checkPasswordContainNumbers,
  checkPasswordContainSpecialChars,
  checkPasswordContainUppercaseChars,
  isValidPasswordLength
} from '@cp/common/utils/ValidationUtils';

@Component({
  selector: 'cp-auth-password-requirements',
  templateUrl: './auth-password-requirements.component.html',
  styleUrls: ['./auth-password-requirements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthPasswordRequirementsComponent {
  @Input()
  password?: string;

  readonly requirements = [
    { title: 'at least 12 characters', isValid: () => isValidPasswordLength(this.password) },
    { title: '1 uppercase letter', isValid: () => checkPasswordContainUppercaseChars(this.password) },
    { title: '1 lowercase letter', isValid: () => checkPasswordContainLowercaseChars(this.password) },
    { title: '1 number', isValid: () => checkPasswordContainNumbers(this.password) },
    { title: '1 special character', isValid: () => checkPasswordContainSpecialChars(this.password) }
  ];

  constructor() {}
}
