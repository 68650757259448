import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { encodeHTML } from '@cp/web/app/common/utils/StringUtils';
import copy from 'copy-to-clipboard';

@Component({
  selector: 'cp-code-snippet',
  templateUrl: './code-snippet.component.html',
  styleUrls: ['./code-snippet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeSnippetComponent {
  @Input()
  text!: string;

  @Input()
  params: Record<string, any> = {};

  @Input()
  copyTransformer?: (str: string) => string;

  constructor(private readonly snackBar: MatSnackBar) {}

  getParsedText(wrapParamWithHtmlTag = false): string {
    let text = this.text;
    for (const entry of Object.entries(this.params)) {
      const paramName = entry[0];
      const paramValue = entry[1];
      const pattern = this.getPattern(paramName);
      text = text.replace(
        pattern,
        wrapParamWithHtmlTag ? `<span class='param'>${encodeHTML(paramValue)}</span>` : paramValue
      );
    }
    return text;
  }

  copyToClipboard(text: string): void {
    this.snackBar.open('Copied to clipboard', 'Dismiss', { duration: 5000 });
    copy(this.copyTransformer ? this.copyTransformer(text) : text);
  }

  private getPattern(paramName: string): RegExp {
    return new RegExp(`\\$\\$\\$${paramName}`, 'g');
  }
}
