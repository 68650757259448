import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address, OrganizationDetails } from '@cp/common/protocol/Billing';
import { isDefined } from '@cp/common/protocol/Common';
import { CompanySize, Organization } from '@cp/common/protocol/Organization';
import { TaxIdDatumType, TaxStatusType } from '@cp/common/protocol/Stripe';
import { assertTruthy } from '@cp/common/utils/Assert';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import {
  AdminBillingState,
  AdminBillingStateService
} from '@cp/web/app/admin/admin-billing/admin-billing-state.service';
import { AdminBillingService } from '@cp/web/app/admin/admin-billing/admin-billing.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { Observable, switchMap, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

export type DialogStage = 'ADDRESS' | 'COMPANY' | 'CREDIT_CARD';

export interface BillingConversionUiState {
  buttonDisabled: boolean;
  errorMessage?: string;
  addressFormInput?: AddressChange;
  companyDetailsFormInput?: CompanyDetailsChange;
}

export interface AddressChange {
  billingAddress: Address;
  bothAddressesSame: boolean;
  shippingAddress?: Address;
}

export interface CompanyDetailsChange {
  organizationIsABusiness?: boolean;
  companyName: string;
  taxId?: string;
  taxIdType?: TaxIdDatumType;
  taxStatus?: TaxStatusType;
  websiteUrl: string;
  companySize?: CompanySize;
}

@Component({
  selector: 'cp-billing-conversion-dialog',
  templateUrl: './billing-conversion-dialog.component.html',
  styleUrls: ['./billing-conversion-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Parent component for the address, company details and credit card update modals.
 * Manages the transitions between the different steps.
 * See designs: https://www.figma.com/file/i00FDyul4FXB8thLi0Dqfl/Beta?node-id=4239%3A28195
 */
export class BillingConversionDialogComponent extends OnDestroyComponent {
  dialogStage: DialogStage = 'ADDRESS';

  private organizationId?: string;
  private organizationObs: Observable<Organization>;
  private adminBillingStateObs: Observable<AdminBillingState>;
  uiState: BillingConversionUiState;

  constructor(
    public dialogRef: MatDialogRef<BillingConversionDialogComponent>,
    private readonly dialog: MatDialog,
    private readonly adminBillingService: AdminBillingService,
    private readonly adminBillingStateService: AdminBillingStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly organizationService: OrganizationService,
    private readonly snackBar: MatSnackBar,
    private focusMonitor: FocusMonitor,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    dialogRef.disableClose = true;

    this.organizationObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeOrganization(id)),
      tap((org) => {
        this.organizationId = org.id;
      })
    );
    this.organizationId = this.organizationStateService.getCurrentOrgId();

    this.adminBillingStateObs = adminBillingStateService.observeState();

    const currentMetadata = this.adminBillingStateService.getMetadata();
    const currentAddress = adminBillingStateService.getAddress();
    const currentFirmographics = organizationStateService.getCurrentOrgOrFail().firmographics;

    // Init the uiState based on the state services.
    const billingAddress = currentAddress?.billingAddress;
    const shippingAddress = currentAddress?.shippingAddress;
    this.uiState = {
      buttonDisabled: false,

      addressFormInput: {
        billingAddress: {
          line1: billingAddress?.line1 ?? '',
          line2: billingAddress?.line2,
          city: billingAddress?.city ?? '',
          state: billingAddress?.state,
          country: billingAddress?.country ?? '',
          postalCode: billingAddress?.postalCode ?? ''
        },
        shippingAddress: {
          line1: shippingAddress?.line1 ?? '',
          line2: shippingAddress?.line2,
          city: shippingAddress?.city ?? '',
          state: shippingAddress?.state,
          country: shippingAddress?.country ?? '',
          postalCode: shippingAddress?.postalCode ?? ''
        },
        bothAddressesSame: currentAddress?.bothAddressesSame ?? true
      },

      companyDetailsFormInput: {
        organizationIsABusiness: currentMetadata?.organizationIsABusiness,
        companyName: currentAddress?.companyName ?? '',
        taxId: currentMetadata?.taxId,
        taxIdType: currentMetadata?.taxIdType,
        taxStatus: currentMetadata?.taxStatus,
        websiteUrl: currentFirmographics.websiteUrl ?? '',
        companySize: currentFirmographics.companySize
      }
    };
  }

  async onAddressChange($event: AddressChange): Promise<void> {
    // Save the new address in the local state
    this.uiState.addressFormInput = $event;
    this.uiState.buttonDisabled = true;
    this.uiState.errorMessage = undefined;

    try {
      /** Even though companyName is not edited on the address form, it should be sent to API to prevent possibly
       * replacing the company name with the org's name when the user had previously entered a company name.
       */
      const organizationDetails: OrganizationDetails = {
        billingAddress: $event.billingAddress,
        shippingAddress: $event.shippingAddress,
        bothAddressesSame: $event.bothAddressesSame,
        companyName:
          this.uiState.companyDetailsFormInput?.companyName === ''
            ? undefined
            : this.uiState.companyDetailsFormInput?.companyName
      };

      assertTruthy(this.organizationId);
      await this.adminBillingService.updateOrganizationBillingDetails(this.organizationId, organizationDetails, true);

      this.dialogStage = 'COMPANY';
    } catch (e) {
      const serverErrorMessage = getServerErrorMessage(e);
      handleUpdateOrganizationBillingDetailsError(
        this.uiState,
        'Unable to save address information',
        serverErrorMessage,
        e
      );
    } finally {
      this.uiState = { ...this.uiState, buttonDisabled: false };
      this.cdr.markForCheck();
    }
  }

  async onCompanyDetailsBack($event: CompanyDetailsChange): Promise<void> {
    this.uiState.companyDetailsFormInput = $event;
    this.dialogStage = 'ADDRESS';
    this.uiState.buttonDisabled = false;
    this.uiState.errorMessage = undefined;

    assertTruthy($event.organizationIsABusiness !== undefined, 'organizationIsABusiness must be defined');
  }

  async onCompanyDetailsChange($event: CompanyDetailsChange): Promise<void> {
    try {
      // Save the new company details in the local state
      this.uiState.companyDetailsFormInput = $event;
      this.uiState.buttonDisabled = true;
      this.uiState.errorMessage = undefined;

      assertTruthy($event.organizationIsABusiness !== undefined, 'organizationIsABusiness must be defined');

      const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();

      assertTruthy(this.uiState.addressFormInput);

      const organizationDetails = $event.organizationIsABusiness
        ? {
            billingAddress: this.uiState.addressFormInput.billingAddress,
            bothAddressesSame: this.uiState.addressFormInput.bothAddressesSame,
            organizationIsABusiness: $event.organizationIsABusiness,
            shippingAddress: this.uiState.addressFormInput.shippingAddress,
            companyName: $event.companyName,
            taxId: $event.taxId,
            taxIdType: $event.taxIdType,
            taxStatus: $event.taxStatus,
            websiteUrl: $event.websiteUrl,
            companySize: $event.companySize
          }
        : {
            billingAddress: this.uiState.addressFormInput.billingAddress,
            bothAddressesSame: this.uiState.addressFormInput.bothAddressesSame,
            organizationIsABusiness: this.uiState.companyDetailsFormInput.organizationIsABusiness,
            shippingAddress: this.uiState.addressFormInput.shippingAddress,
            websiteUrl: this.uiState.companyDetailsFormInput.websiteUrl,
            companySize: this.uiState.companyDetailsFormInput.companySize
          };

      await this.adminBillingService.updateOrganizationBillingDetails(organizationId, organizationDetails);

      this.dialogStage = 'CREDIT_CARD';
    } catch (e) {
      const serverErrorMessage = getServerErrorMessage(e);
      handleUpdateOrganizationBillingDetailsError(
        this.uiState,
        'Unable to save address and company information',
        serverErrorMessage,
        e
      );
    } finally {
      this.uiState = { ...this.uiState, buttonDisabled: false };
      this.cdr.markForCheck();
    }
  }

  onCreditCardBack() {
    this.dialogStage = 'COMPANY';
    this.cdr.markForCheck();
  }

  async onCreditCardSaved() {
    this.snackBar.open('Payment information saved', 'Dismiss', { duration: 5000 });
    this.dialogRef.close();
  }

  static show(dialog: MatDialog): void {
    dialog.open(BillingConversionDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal_no_padding'
    });
  }
}

export function handleUpdateOrganizationBillingDetailsError(
  uiState: BillingConversionUiState,
  defaultErrorMessage: string,
  serverErrorMessage: string | undefined,
  e: unknown
): void {
  switch (serverErrorMessage) {
    case 'INVALID_ADDRESS':
    case 'BILLING_ADDRESS_IS_REQUIRED':
      uiState.errorMessage = 'An address is required';
      return;
    case 'INVALID_COMPANY_NAME':
      uiState.errorMessage = 'Company name is required';
      return;
    case 'INVALID_WEBSITE_URL':
      uiState.errorMessage = 'Website URL is required';
      return;
    case 'INVALID_COMPANY_SIZE':
      uiState.errorMessage = 'Company size is required';
      return;
    case 'ORGANIZATION_IS_A_BUSINESS_IS_REQUIRED':
      uiState.errorMessage = 'Please specify if this is a business or individual';
      return;
    case 'INVALID_TAX_ID':
      uiState.errorMessage = 'Please specify a proper tax id for your location';
      return;
    default:
      console.error(defaultErrorMessage, e);
      uiState.errorMessage = defaultErrorMessage;
  }
}
