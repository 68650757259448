<mat-dialog-content class="case_submitted_dialog">
  <div class="wave_container"></div>
  <div class="content">
    <div class="checkmark_container">
      <mat-icon class="icon" svgIcon="check_icon"></mat-icon>
    </div>

    <div class="text_container">
      <div class="title">Your case has been created</div>
      <div class="description">You will receive email updates as we work with you to fix this issue.</div>
    </div>

    <div class="buttons">
      <button (click)="dialogRef.close()" [routerLink]="'/support/'+caseId" class="primary_button button"
              mat-flat-button>
        View case
      </button>
      <button (click)="dialogRef.close()" class="secondary_action button" mat-stroked-button type="button">Close
      </button>
    </div>

  </div>
</mat-dialog-content>
