<ng-container *ngIf="myOrgUserObs | async as myOrgUser">
  <ng-container *ngIf="myOrgUser.role as myRole">
    <div class="mou">
      <div *ngIf="usersObs | async as users">
        <div class="inner_page_nav">
          <div class="description">Users that have access to the ClickHouse Cloud console.</div>
          <button (click)="showInvitesDialog()"
                  *ngIf="myRole === 'ADMIN' && (orgRestrictionsObs | async)?.canInviteMembers"
                  class="invite_button" color="primary"
                  data-cy="invite-button" mat-flat-button>
            <mat-icon class="icon svg_fill" svgIcon="plus_icon"></mat-icon>
            Invite members
          </button>
        </div>

        <table [dataSource]="users" class="users_table" data-cy="user-table" mat-table>
          <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>Name</th>
            <td *matCellDef="let element; typedBy: users" class="name_cell fs-exclude" data-cy="name-cell" mat-cell>
              <cp-avatar [fixedFontSize]="12"
                         [name]="element.name"
                         [template]="element.type === 'USER' ? 'ORANGE' : 'WHITE'"
                         class="avatar"></cp-avatar>
              {{element.name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th *matHeaderCellDef mat-header-cell>Email</th>
            <td *matCellDef="let element; typedBy: users" class="email_cell fs-exclude" data-cy="email-cell" mat-cell>
              {{element.email}}
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th *matHeaderCellDef mat-header-cell>Role</th>
            <td *matCellDef="let element; typedBy: users" [attr.data-cy-role]="element.role" data-cy="role-cell"
                mat-cell>{{element.role | titlecase}}</td>
          </ng-container>

          <ng-container matColumnDef="provider">
            <th *matHeaderCellDef mat-header-cell>Provider</th>
            <td *matCellDef="let element; typedBy: users" class="provider_cell fs-exclude" data-cy="provider-cell"
                mat-cell>
              <div class="element_with_icon">
                <mat-icon *ngIf="isGoogleUser(element.identityProviders)" [matTooltip]="'Signed in with Google'"
                          class="icon" svgIcon="google_logo"></mat-icon>
                <mat-icon *ngIf="isCognitoUser(element.identityProviders)" [matTooltip]="'Signed in with email'"
                          class="icon" svgIcon="email_icon"></mat-icon>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="mfa-status">
            <th *matHeaderCellDef mat-header-cell [matTooltip]="'Multi-factor authentication status'">MFA status</th>
            <td *matCellDef="let element; typedBy: users" [attr.data-cy-role]="element.mfaPreferredMethod"
                data-cy="role-cell"
                mat-cell>{{getUserMfaDisplayStatus(element.mfaPreferredMethod, element.identityProviders)}}</td>
          </ng-container>

          <ng-container matColumnDef="comment">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element; typedBy: users" data-cy="comment-cell" mat-cell>{{element.comment}}
              <span *ngIf="element.resend" data-cy="resend-link"><a
                (click)="resendInvitation(element.name, element.role)">Resend</a></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element; typedBy: users" mat-cell>
              <button *ngIf="myRole === 'ADMIN' && element.id !== myOrgUser.userId" [matMenuTriggerData]="{element}"
                      [matMenuTriggerFor]="element.type === 'USER' ? usersMenu : invitationsMenu"
                      class="menu_button"
                      data-cy="menu-button"
                      mat-button>
                <mat-icon class="icon svg_fill">more_vert</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" [attr.data-cy-user]="row.name"
              data-cy='user-table-row' mat-row></tr>
        </table>
      </div>
    </div>

    <mat-menu #usersMenu="matMenu" [attr.data-cy]="'users-menu'" class="users_menu" xPosition="before">
      <ng-template let-element="element" matMenuContent>
        <button (click)="changeUserRole(element.id, 'DEVELOPER')" *ngIf="element.role === 'ADMIN'"
                data-cy="change-role-to-developer-button"
                mat-menu-item
        >Change role to Developer
        </button>
        <button (click)="changeUserRole(element.id, 'ADMIN')" *ngIf="element.role === 'DEVELOPER'"
                data-cy="change-role-to-admin-button"
                mat-menu-item
        >Change role to Admin
        </button>
        <button (click)="removeUser(element.id)" class="delete" data-cy="delete-button" mat-menu-item>Remove</button>
      </ng-template>
    </mat-menu>

    <mat-menu #invitationsMenu="matMenu" class="invitations_menu" xPosition="before">
      <ng-template let-element="element" matMenuContent>
        <button (click)="deleteInvitation(element.id)" class="delete" data-cy="delete-button" mat-menu-item>Delete
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</ng-container>


