import { Inject, Injectable } from '@angular/core';
import { WebSocketService } from '@cp/common-services/web-socket.service';
import {
  CaseDetailsUpdatePayload,
  CreateSupportCaseResponse,
  SupportCase,
  SupportCaseDetails,
  SupportCaseEventType,
  SupportCasePriority,
  SupportCaseStatus
} from '@cp/common/protocol/Support';
import { RECAPTCHA_PROVIDER_TOKEN, RecaptchaProviderFn } from '@cp/web/app/common/utils/RecaptchaUtils';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { SupportApiService } from '@cp/web/app/support/support-api.service';
import { SupportCasesStateService } from '@cp/web/app/support/support-cases-state.service';
import { merge, NEVER, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(
    private readonly supportApiService: SupportApiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly supportCasesStateService: SupportCasesStateService,
    private readonly webSocketService: WebSocketService,
    @Inject(RECAPTCHA_PROVIDER_TOKEN) private readonly recaptchaProviderFn: RecaptchaProviderFn
  ) {
    this.organizationStateService
      .observeCurrentOrganizationId()
      .pipe(
        switchMap((orgId) => {
          if (orgId === undefined) {
            this.supportCasesStateService.clearSupportCases();
            return NEVER;
          }
          this.listCases(orgId).then((supportCases) => {
            if (orgId === this.organizationStateService.getCurrentOrgId()) {
              this.supportCasesStateService.setSupportCases(supportCases);
            }
          });
          return merge(
            this.supportCasesStateService.observeCurrentSupportCaseId().pipe(
              tap(async (currentCaseId) => {
                if (!currentCaseId) return;
                const details = await this.getCaseDetails(orgId, currentCaseId);
                if (details && orgId === this.organizationStateService.getCurrentOrgId()) {
                  this.supportCasesStateService.setSupportCase(details);
                }
              })
            ),
            this.listenToCaseUpdates(orgId)
          );
        })
      )
      .subscribe();
  }

  async reportCaseEvent(
    caseId: string,
    type: SupportCaseEventType,
    reply: string | undefined,
    status: SupportCaseStatus,
    attachmentStorageIds?: Array<string>
  ) {
    return await this.supportApiService.reportCaseEvent(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      caseId,
      type,
      reply,
      status,
      attachmentStorageIds
    );
  }

  async createCase(
    priority: SupportCasePriority,
    subject: string,
    description: string,
    additionalEmails: string[],
    serviceId?: string,
    serviceName?: string,
    attachmentStorageIds?: string[]
  ): Promise<CreateSupportCaseResponse> {
    const recaptchaToken = await this.recaptchaProviderFn();
    return await this.supportApiService.createCase(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      priority,
      subject,
      description,
      additionalEmails,
      recaptchaToken,
      serviceId,
      serviceName,
      attachmentStorageIds
    );
  }

  async prepareSupportAttachmentUpload(fileName: string, mimeType?: string) {
    return await this.supportApiService.prepareSupportAttachmentUpload(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      fileName,
      mimeType
    );
  }

  private async listCases(organizationId: string): Promise<Array<SupportCase>> {
    return await this.supportApiService.listCases(organizationId);
  }

  private async getCaseDetails(organizationId: string, caseId: string): Promise<SupportCaseDetails> {
    return await this.supportApiService.getCaseDetails(organizationId, caseId);
  }

  private listenToCaseUpdates(organizationId: string) {
    return this.webSocketService
      .observeNotification<CaseDetailsUpdatePayload>({
        type: 'CASE_DETAILS_UPDATE',
        objId: organizationId
      })
      .pipe(
        tap(({ payload }) => {
          this.supportCasesStateService.setSupportCase(payload.details);
        })
      );
  }
}
