import { ChangeDetectionStrategy, Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OrganizationBillingStatus, OrganizationRole } from '@cp/common/protocol/Organization';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { TrialServiceLimitUiService } from '@cp/web/app/instances/trial-service-limit-dialog/trial-service-limit-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable, takeUntil } from 'rxjs';

export type TrialServiceLimitDialogContentTemplateRef = TemplateRef<{ role: OrganizationRole }>;

@Component({
  selector: 'cp-trial-service-limit-dialog',
  templateUrl: './trial-service-limit-dialog.component.html',
  styleUrls: ['./trial-service-limit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrialServiceLimitDialogComponent extends OnDestroyComponent {
  readonly myRoleObs: Observable<OrganizationRole>;
  readonly customContentTemplate?: TrialServiceLimitDialogContentTemplateRef;

  constructor(
    public readonly dialogRef: MatDialogRef<TrialServiceLimitDialogComponent>,
    private readonly organizationStateService: OrganizationStateService,
    private readonly trialServiceLimitUiService: TrialServiceLimitUiService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { customContentTemplate?: TrialServiceLimitDialogContentTemplateRef },
    private readonly router: Router
  ) {
    super();
    this.trialServiceLimitUiService.setPartialState({ isInTrial: true });
    this.trialServiceLimitUiService
      .observeState()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((state) => {
        if (!state.isInTrial) {
          this.dialogRef.close();
        }
      });
    this.customContentTemplate = this.data?.customContentTemplate;
    this.myRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
  }

  async goToBilling(): Promise<void> {
    await this.router.navigateByUrl('/organization/admin/billing');
    this.dialogRef.close();
  }

  static show(dialog: MatDialog, customContentTemplate?: TrialServiceLimitDialogContentTemplateRef): void {
    dialog.open(TrialServiceLimitDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      maxHeight: '520px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: { customContentTemplate }
    });
  }

  /** Show trial-service-limit dialog or present start service restriction message if the user not in trial/grace-period. */
  static showStartServiceRestricted(
    dialog: MatDialog,
    snackBar: MatSnackBar,
    orgBillingStatus: OrganizationBillingStatus,
    customContentTemplate?: TrialServiceLimitDialogContentTemplateRef
  ): void {
    if (orgBillingStatus === 'IN_TRIAL_GRACE_PERIOD') {
      // Show trial service limit dialog.
      TrialServiceLimitDialogComponent.show(dialog, customContentTemplate);
    } else {
      snackBar.open(`Can't start the service at the moment. Please contact support for help.`, 'Dismiss', {
        duration: 10000
      });
    }
  }
}
