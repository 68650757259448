import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cp-disable-mfa-confirmation-dialog-content',
  templateUrl: './disable-mfa-confirmation-dialog-content.component.html',
  styleUrls: ['./disable-mfa-confirmation-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisableMfaConfirmationDialogContentComponent {
  @Output() cancel = new EventEmitter();
  @Output() primaryAction = new EventEmitter();
  @Input() isLoading = false;
  @Input() errorMessage = '';

  onCancel(): void {
    this.cancel.emit();
  }

  onPrimaryAction(): void {
    this.primaryAction.emit();
  }
}
