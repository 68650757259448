<div class="case_timeline_container">
  <div class="header_container">
    <div class="header">Case timeline</div>
    <button (click)="replyClicked.emit()"
            *ngIf="showReplyButton"
            class="reply_button secondary_action"
            mat-stroked-button>
      Reply
    </button>
  </div>
  <div class="events_container">
    <div *ngFor="let event of caseEvents" class="event">
      <div class="left">
        <div class="icon_container">
          <mat-icon [svgIcon]="eventIcons[event.type]" class="icon"></mat-icon>
        </div>
      </div>
      <div class="right">
        <div [ngClass]="{customer_event: !event.isClickHouse && event.type === 'reply'}" [ngSwitch]="event.type"
             class="event_details_container">
          <div *ngSwitchCase="'reply'" class="reply_event">
            <div class="reply_header">
              <div *ngIf="event.isClickHouse" class="full_name">
                {{event.fullName}}
                <div class="clickhouse">ClickHouse</div>
              </div>
              <div *ngIf="!event.isClickHouse" class="email">{{event.fullName}}</div>
              <div class="event_time">{{event.timestamp | approximateTime: 'MMM d, yy, h:mm a'}}</div>
            </div>
            <div class="reply" #supportCaseDescriptionText [class.show-less]="descriptionExpanded === false"
                 [innerHTML]="event.reply | linkify"></div>
            <span (click)="descriptionExpanded = !descriptionExpanded"
                  *ngIf="
      supportCaseDescriptionText.offsetHeight < supportCaseDescriptionText.scrollHeight ||
      (descriptionExpanded === true && supportCaseDescriptionText.offsetHeight > 40)"
                  class="expand_or_collapse">{{descriptionExpanded ? 'Collapse' : 'Read more'}}</span>
          </div>
          <div *ngSwitchCase="'status'" class="status_event">
            <div class="status_text">Case is {{event.status === 'NEW' ? 'opened' : 'closed'}}</div>
            <div class="event_time">{{event.timestamp | approximateTime: 'MMM d, yy, h:mm a'}}</div>
          </div>
          <div *ngSwitchCase="'attachment'" class="attachment_event">
            <ng-container *ngIf="event.attachment">
              <div class="status_text">{{event.email}} uploaded <span
                class="fileName">{{event.attachment.fileName}} </span>
                <a [href]="event.attachment.downloadUrl" target="_blank">download</a></div>
              <div class="event_time">{{event.timestamp | approximateTime: 'MMM d, yy, h:mm a'}}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
