<ng-container *ngIf="instanceObs | async as instance">
  <mat-dialog-content data-cy="sql-connect-dialog" class="sql_connect_dialog">
    <div class="dialog_title seed_h4" data-cy="add-data-dialog-title">Connect your SQL client</div>
    <div class="close_icon_wrapper">
      <cp-dialog-close-button [dialogRef]="dialogRef"
                           [width]="14"
                           [height]="16"
                           [fontSize]="16"
                           [top]="10"
      ></cp-dialog-close-button>
    </div>
    <div
      class="play_ui_container"
      *ngVar="!['running', 'idle', 'awaking'].includes(instance.state) as isDisabled"
    >
      <mat-icon [svgIcon]="isDisabled ? 'console_icon' : 'console_gradient'" class="play_ui_icon"></mat-icon>
      <div class="play_ui_content">
        <div class="paragraph_bold_regular">ClickHouse Play UI</div>
        <div class="paragraph_medium_regular">
          Run all of your ClickHouse commands right here in the native ClickHouse SQL client.
        </div>
        <a
          (click)="reportSqlConsoleClicked()" [disabled]="isDisabled"
          [href]="getWebConsoleUri(instance)"
          class="button play_ui_button"
          data-cy="connect-to-sql-button"
          [matTooltip]="isDisabled ? disabledButtonTooltip : ''"
          mat-stroked-button
          target="_blank"
        >
          Run client
        </a>
      </div>
    </div>

    <div class="hairline"></div>

    <div class="sql-client-title">SQL client integrations</div>
    <div class="paragraph_medium_regular">We love to integrate with popular SQL clients that support ClickHouse.</div>
    <div class="console_buttons" *ngIf="sqlIntegrationsObs | async as sqlIntegrations">
      <a *ngFor="let integration of sqlIntegrations"
         class="sql_client_button"
         [href]="'integrations/' + integration.slug"
         target="_blank"
      >
        <img class="sql_client_logo" [src]="integration.logo.url" [alt]="integration.name + ' logo'">
        <span>{{integration.name}}</span>
      </a>
    </div>
    <div class="request_integration_text">
      Don’t see your favorite integration? <a class="link" (click)="goToSqlClients()">View more.</a>
    </div>
    <div class="close_button_wrapper">
      <button (click)="dialogRef.close()" mat-flat-button type="button" class="close_button" data-cy="close-button">
        Close
      </button>
    </div>
  </mat-dialog-content>
</ng-container>
