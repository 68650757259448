<div class="main_layout" data-cy="admin-usage-component">

  <div *ngIf="reportState === 'LOADING'" class="loading_spinner_block" data-cy="report-loading">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="reportState === 'ERROR'" class="report_not_available_warning" data-cy="report-error">
    Report is not available. Please try later.
  </div>

  <div *ngIf="hasOrganizationUsageReport">
    <div class="status_row">
      <div class="status_item" data-cy="billing-this-cycle">
        <h5>Usage this billing cycle</h5>
        <p>
          <ng-container *ngIf="report.netBillableAmount !== report.grossBillableAmount">
            <span class="crossed_out fs-exclude">{{report.grossBillableAmount | currency : report.currency}}</span>
            &nbsp;
          </ng-container>
          <span class="fs-exclude">{{report.netBillableAmount | currency : report.currency}}</span>
          <mat-icon
            *ngIf="report.netBillableAmount !== report.grossBillableAmount && (billingStatusIsTrialRelatedObs | async) === false"
            svgIcon="info_circle"
            class="price_info_icon"
            matTooltipClass="column_info_icon_tooltip"
            [matTooltip]="'Please see the billing page for a complete breakdown of credits.'"
          ></mat-icon>

        </p>
      </div>

      <ng-container *ngIf="(billingStatusIsTrialRelatedObs | async) === true">
        <ng-container *ngIf="trialCommitmentStateObs | async as trialCommitment">
          <div class="status_item">
            <h5>Credits used</h5>
            <p class="fs-exclude">{{trialCommitment.amountSpent}} <span>/ {{trialCommitment.totalAmount}}</span></p>
          </div>

          <div class="status_item">
            <h5>Credits remaining</h5>
            <p class="fs-exclude">{{trialCommitment.amountRemaining}}</p>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="spacer_line"></div>
    <div class="controls_container">
      <div class="inner_page_section_title">Usage metrics</div>
      <div class="controls_block">
        <seed-select [options]="chartTypeOptions"
                     (selectionChange)="onChartTypeChange($event)"
                     [value]="input.chartMetric"
                     class="select"
                     data-cy="chart-metric-select"
        ></seed-select>

        <seed-select [options]="periodOptions"
                     (selectionChange)="onPeriodChange($event)"
                     [value]="report.billDate"
                     class="select"
                     data-cy="period-select"
        ></seed-select>
      </div>
    </div>

    <div class="chart_container" *ngIf="hasNonEmptyChartData" data-cy="usage-chart">
      <cp-admin-usage-chart [chartData]="chartData"></cp-admin-usage-chart>
    </div>
    <div class="chart_loading_spinner_block" *ngIf="chartState === 'LOADING'" data-cy="usage-chart-loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="empty_chart_container" *ngIf="chartState === 'ERROR'" data-cy="usage-chart-error">
      <div class="first_line">These metrics cannot be shown at this time</div>
      <div class="second_line">Please try again in a few minutes</div>
    </div>

    <div class="report_table_container" *ngIf="reportTableTotalRow" data-cy="report-table">
      <table [dataSource]="reportTableInstanceRows" mat-table class="report_table">
        <tr mat-header-row *matHeaderRowDef="reportTopLineTableColumnIds"></tr>
        <tr mat-header-row *matHeaderRowDef="reportTableColumnIds"></tr>
        <tr mat-row *matRowDef="let row; columns: reportTableColumnIds;" data-cy="report-row"></tr>
        <tr mat-footer-row *matFooterRowDef="reportTableColumnIds" class="total_row"></tr>

        <!-- Cell templates: th for metric names. -->
        <ng-container *ngFor="let column of topLineMetricColumns" [matColumnDef]="column.columnId">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" class="top_line">
            {{column.headerName}}
          </th>
        </ng-container>

        <!-- Cell templates: th & td for metric values. -->
        <ng-container *ngFor="let columnId of metricColumnIds" [matColumnDef]="columnId">
          <ng-container *ngVar="getColumnDescriptor(columnId) as column">
            <th mat-header-cell *matHeaderCellDef
                [ngClass]="{last_column_of_metric: checkIfLastMetricColumn(column.viewType)}">
              {{column.headerName}}
              <mat-icon *ngIf="column.tooltip" svgIcon="info_circle" class="column_info_icon"
                        matTooltipClass="column_info_icon_tooltip"
                        [matTooltip]="column.tooltip"
              ></mat-icon>
            </th>
            <td mat-cell *matCellDef="let instanceRow; typedBy: reportTableInstanceRows" class="fs-exclude"
                [ngClass]="{last_column_of_metric: checkIfLastMetricColumn(column.viewType)}">
              <mat-icon *ngIf="instanceRow[columnId] === 'LOADING'" svgIcon="loader"></mat-icon>
              {{instanceRow[columnId] !== 'LOADING' ? instanceRow[columnId] : ''}}</td>
            <td mat-footer-cell class="usage_footer_cell"
                [ngClass]="{last_column_of_metric: checkIfLastMetricColumn(column.viewType)}"
                *matFooterCellDef>
              <mat-icon *ngIf="reportTableTotalRow[columnId] === 'LOADING'" svgIcon="loader"></mat-icon>
              {{checkIfLastMetricColumn(column.viewType) && reportTableTotalRow[columnId] !== 'LOADING' ? reportTableTotalRow[columnId] : ''}}
            </td>
          </ng-container>
        </ng-container>

        <!-- Service name cell has a custom structure. -->
        <ng-container matColumnDef="serviceNameTopLine" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="top_line">
            Service
          </th>
        </ng-container>
        <!-- Service name cell has a custom structure. -->
        <ng-container matColumnDef="serviceName" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef class="hidden">
          </th>
          <td mat-cell *matCellDef="let instanceRow; typedBy: reportTableInstanceRows" class="row_header">
            <div [ngStyle]="{'background-color':instanceRow.color}" class="colored_square"></div>
            {{instanceRow['serviceName']}}
            <mat-icon [svgIcon]="instanceRow['cloudProvider']"
                      class="icon"
                      data-cy="cloud-provider-icon"></mat-icon>
          </td>
          <td mat-footer-cell *matFooterCellDef class="row_header usage_footer_cell">
            <div class="colored_square colored_square_total"></div>
            Total
          </td>
        </ng-container>
      </table>
      <p class="pricing-link">For more details visit our <a href="https://clickhouse.com/pricing" target="_blank">pricing
        page</a></p>

    </div>
  </div>

</div>
