import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DirectGrantSummary, RoleGrantSummary, UserPermissionsSummary } from '@cp/common/utils/DbUserPermissions';

interface DisplayGrantSummary {
  accessType: string;
  columnName?: string;
  objectName: string;
}

@Component({
  selector: 'cp-db-user-details',
  templateUrl: './db-user-details.component.html',
  styleUrls: ['./db-user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DbUserDetailsComponent {
  @Input()
  userPermissions!: UserPermissionsSummary;

  @Input()
  instanceName!: string;

  @Output()
  flyoutClose = new EventEmitter<void>();

  selectedUserDirectGrants(): Array<DirectGrantSummary> {
    return this.userPermissions?.directGrants ?? [];
  }

  selectedUserRoles(): Array<RoleGrantSummary> {
    return this.userPermissions?.roleGrants ?? [];
  }

  grantSummaries(grants: Array<DirectGrantSummary>): Array<DisplayGrantSummary> {
    return grants.map((grant) => {
      const objectName = `${grant.database ?? '*'}.${grant.table ?? '*'}`;

      return {
        accessType: grant.accessType,
        columnName: grant.column ?? undefined,
        objectName
      };
    });
  }

  close(): void {
    this.flyoutClose.emit();
  }

  trackByGrant(index: number, grant: DisplayGrantSummary): string {
    return JSON.stringify([grant.accessType, grant.columnName, grant.objectName]);
  }
}
