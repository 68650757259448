import { Injectable } from '@angular/core';
import {
  EMPTY_SIGN_IN_METADATA_ID,
  MarketingParams,
  SignInMetadata,
  SignInOauthParams
} from '@cp/common/protocol/SignInMetadata';
import { TackleSubscriptionToken } from '@cp/common/protocol/Tackle';
import { firstValueFrom, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignInMetadataService {
  /** Assigned when CP stores or receives metadataId (customOAuthState) from Cognito callback. */
  private id = EMPTY_SIGN_IN_METADATA_ID;

  /** Pending metadata that must be passed through 'sign-in' or 'sign-up' process. */
  private value: SignInMetadata = {};

  /** When set to true the sign-in metadata was processed (after the sign-in/sign-up). */
  private processedFlagObs = new ReplaySubject<boolean>(1);
  private processedFlag = false;

  setTackleToken(tackleToken: TackleSubscriptionToken): void {
    console.debug('SignInMetadataService::setTackleToken', tackleToken);
    this.value.tackleToken = tackleToken;
  }

  get metadata(): SignInMetadata {
    return this.value;
  }

  get tackleToken(): TackleSubscriptionToken | undefined {
    return this.value.tackleToken;
  }

  setMetadataId(id: string): void {
    console.debug('SignInMetadataService::setMetadataId', id);
    this.id = id;
  }

  get metadataId(): string {
    return this.id;
  }

  setOauthParams(params: SignInOauthParams): void {
    console.debug('SignInMetadataService::setOauthParams', params);
    this.value.oauthParams = params;
  }

  get oauthParams(): SignInOauthParams | undefined {
    return this.value.oauthParams;
  }

  markSignInMetadataAsProcessed(): void {
    console.debug('SignInMetadataService::markSignInMetadataAsProcessed');
    this.processedFlag = true;
    this.processedFlagObs.next(true);
  }

  isProcessed(): boolean {
    return this.processedFlag;
  }

  async waitUntilSignInMetadataIsProcessed(): Promise<void> {
    console.debug('SignInMetadataService::waitUntilSignInMetadataIsProcessed');
    await firstValueFrom(this.processedFlagObs);
    console.debug('SignInMetadataService::waitUntilSignInMetadataIsProcessed DONE');
  }

  setUrl(urlParam?: string) {
    const url = urlParam || window.location.href;
    console.debug('SignInMetadataService::setUrl', url);
    this.value.url = url;
  }

  get url(): string | undefined {
    return this.value.url;
  }

  setMarketingParams(params: MarketingParams) {
    console.debug('SignInMetadataService::setMarketingParams', params);
    this.value.marketingParams = params;
  }

  get marketingParams(): MarketingParams | undefined {
    return this.value.marketingParams;
  }

  setIdpConnection(idpConnection: string) {
    console.debug('SignInMetadataService::setIdpConnection', idpConnection);
    this.value.idpConnection = idpConnection;
  }

  get idpConnection(): string | undefined {
    return this.value.idpConnection;
  }
}
