import {
  ActionType,
  BaseAction,
  isBatchAction,
  isPathAction,
  PathAction,
  SetPathAction
} from '@cp/common-services/state/actions';

export type StateLeafNode = string | boolean | number | null | undefined | object | Array<StateLeafNode> | State;
export type State = { [key: string]: StateLeafNode };

export function apply(state: State, action: BaseAction): State {
  return applyInternal(state, action);
}

export function isStateObject(state: StateLeafNode): state is State {
  return typeof state === 'object';
}

function applyInternal(state: State, action: BaseAction): State {
  let newState = state;
  if (isPathAction(action)) {
    newState = resolvePathReducer(state, action, []);
  } else if (isBatchAction(action)) {
    newState = action.payload.reduce((reducedState, actionInBatch) => {
      return applyInternal(reducedState, actionInBatch);
    }, state);
  } else {
    throw new Error(`Invalid action type: ${action.type}`);
  }
  return newState;
}

function resolvePathReducer(state: State, action: PathAction, absolutePath: string[] = []): State {
  switch (action.type) {
    case ActionType.DELETE_PATH:
      return deleteInPath(state, action.path);
    case ActionType.SET_PATH: {
      const setPathAction = action as SetPathAction;
      return replaceInPath(state, action.path, absolutePath, setPathAction.payload);
    }
    case ActionType.BATCH_ACTION:
      throw new Error('Unsupported');
  }
}

function deleteInPath(state: State, path: string[]): State {
  if (!path.length) throw new Error('can not delete an empty path');

  const key = path[0];
  if (path.length === 1) return removeProperty(state, key);

  const newPath = path.slice(1);
  const valueOnKey = getProperty(state, key);
  if (!valueOnKey) return state;

  if (!isStateObject(valueOnKey)) {
    throw new Error('Cannot delete from a non object state.');
  }

  const newValueOnKey = deleteInPath(valueOnKey, newPath);
  if (newValueOnKey === valueOnKey) return state;
  return setProperty(state, key, newValueOnKey);
}

function replaceInPath(state: State, relativePath: string[], absolutePath: string[], newValue: StateLeafNode): State {
  return replaceInPathInternal(state, relativePath, absolutePath, newValue) as State;
}

function replaceInPathInternal(
  state: State,
  relativePath: string[],
  absolutePath: string[],
  newValue: StateLeafNode
): State {
  if (!relativePath.length) {
    if (isStateObject(newValue)) {
      return newValue;
    }
    throw new Error('Relative path cannot be of length 0 when the new value is not an object.');
  }

  const key: string = relativePath[0];
  const currentPath = [...absolutePath, key];

  if (relativePath.length === 1) {
    return setProperty(state, key, newValue);
  }

  const newPath = relativePath.slice(1);
  const valueOnKey = getProperty(state, key);
  if (valueOnKey === undefined || !isStateObject(valueOnKey)) {
    state = setProperty(state, key, {});
    return replaceInPathInternal(state, relativePath, absolutePath, newValue);
  }
  const newValueOnKey = replaceInPathInternal(valueOnKey, newPath, currentPath, newValue);
  return setProperty(state, key, newValueOnKey);
}

function setProperty(state: State, key: string, value: StateLeafNode): {} {
  state = { ...state };
  state[key] = value;
  return state;
}

function isArrayType(value: StateLeafNode): value is StateLeafNode[] {
  return value instanceof Array;
}

function getProperty(state: StateLeafNode, property: string): StateLeafNode {
  if (isArrayType(state)) {
    return state[convertToNumber(property)];
  } else {
    return (state as State)[property];
  }
}

function removeProperty(state: State, key: string): State {
  if (state instanceof Array) {
    throw new Error('Cannot remove an item from an array');
  }
  const copyState = { ...state };
  delete copyState[key];
  return copyState;
}

function convertToNumber(value: string): number {
  const valueAsNumber = Number(value);
  if (isNaN(valueAsNumber)) {
    throw new Error(`The value is not a number: Actual: ${value}`);
  }
  return Math.floor(valueAsNumber);
}
