import { Injectable } from '@angular/core';
import { environment } from '@cp/web/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {
  loadGoogleTagScript() {
    const stage = environment.stage;
    const accountId = stage === 'production' ? 'GTM-TL8H72K' : 'GTM-P52RCTZ';
    const headNode = document.createElement('script');
    headNode.id = 'googleTagManagerHead';
    headNode.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${accountId}');`;
    headNode.type = 'text/javascript';
    headNode.async = true;
    document.getElementsByTagName('head')[0].appendChild(headNode);

    const bodyNoscript = document.createElement('noscript');
    bodyNoscript.id = 'googleTagManagerBody';
    const bodyNode = document.createElement('iframe');
    bodyNode.src = `https://www.googletagmanager.com/ns.html?id=${accountId}`;
    bodyNode.height = '0';
    bodyNode.width = '0';
    bodyNode.style.display = 'none';
    bodyNode.style.visibility = 'hidden';
    bodyNoscript.appendChild(bodyNode);
    document.getElementsByTagName('body')[0].appendChild(bodyNoscript);
  }

  unloadGoogleTagScript() {
    document.getElementById('googleTagManagerHead')?.remove();
    document.getElementById('googleTagManagerBody')?.remove();
  }
}
