import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cp-case-submitted-dialog',
  templateUrl: './case-submitted-dialog.component.html',
  styleUrls: ['./case-submitted-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseSubmittedDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CaseSubmittedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly caseId: string
  ) {}
}
