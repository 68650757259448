import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestServiceError } from '@cp/common-services/rest.service';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import {
  ApiKeysService,
  CreateOrganizationOpenapiKeyOptions
} from '@cp/web/app/organizations/api-keys/api-keys-service';
import { ApiKeyFormData } from '@cp/web/app/organizations/api-keys/edit-api-key-form/edit-api-key-form.component';

interface GeneratedKeyDetails {
  keyId: string;
  keySecret: string;
}

export interface CreateApiKeyDialogComponentData {
  /** Current organization. */
  organizationId: string;
  /** Role of the current user in the organization. */
  role: OrganizationRole;
}

@Component({
  selector: 'cp-create-api-key-dialog',
  templateUrl: './create-api-key-dialog.component.html',
  styleUrls: ['./create-api-key-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateApiKeyDialogComponent extends OnDestroyComponent {
  generatedKeyDetails?: GeneratedKeyDetails;

  isCreateKeyNetworkCallInFlight = false;

  constructor(
    readonly dialogRef: MatDialogRef<CreateApiKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: CreateApiKeyDialogComponentData,
    private readonly apiKeyService: ApiKeysService,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  async createApiKey(formData: ApiKeyFormData): Promise<void> {
    try {
      this.isCreateKeyNetworkCallInFlight = true;
      const createOptions: CreateOrganizationOpenapiKeyOptions = {
        entityId: this.data.organizationId,
        name: formData.name,
        roles: [formData.role],
        expirationDate: formData.expirationDate
      };
      const response = await this.apiKeyService.createOrganizationApiKey(createOptions);
      this.generatedKeyDetails = { keyId: response.key, keySecret: response.secret };
    } catch (e) {
      const message = (e as RestServiceError)?.error?.message || '';
      const displayMessage = message.includes('exceed')
        ? 'The organization reached maximum API key count'
        : 'Internal error: Failed to create key.';
      this.snackBar.open(displayMessage, 'Dismiss', { duration: 5000 });
    } finally {
      this.isCreateKeyNetworkCallInFlight = false;
      this.cdr.markForCheck();
    }
  }

  static show(dialog: MatDialog, data: CreateApiKeyDialogComponentData): void {
    dialog.open(CreateApiKeyDialogComponent, {
      width: '100%',
      maxWidth: '542px',
      minHeight: '516px',
      restoreFocus: false,
      panelClass: 'modal_no_padding',
      data
    });
  }
}
