<div class="password_legend_container">
  <div class="chupchik_wrapper">
    <div class="chupchik1"></div>
    <div class="chupchik2"></div>
  </div>

  <div class="title">Password requirements:</div>
  <div class="legend">
    <div *ngFor="let requirement of requirements" [ngClass]="{validated: requirement.isValid()}">
      <mat-icon class="bullet" svgIcon="check_circle"></mat-icon>
      {{requirement.title}}
    </div>
  </div>
</div>
