<mat-dialog-content *ngIf="uiStateObs | async as uiState" class="reset_instance_password_dialog"
                    data-cy="reset-instance-password-dialog">
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title dialogTitle="Reset service user password"></cp-dialog-title>

  <div class="paragraph_text">
    You're resetting the password for the <span class="user">{{username}}</span> user. Make sure to update the settings
    of any clients that use these credentials.
  </div>
  <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"
                     class="error_message"></cw-system-message>

  <div class="buttons">
    <button (click)="resetInstancePassword()" [disabled]="uiState.resetPasswordButtonDisabled" class="button"
            class="submit_button"
            color="primary"
            data-cy="reset-password-button"
            mat-flat-button>
      <mat-icon *ngIf="uiState.resetPasswordButtonDisabled" class="button_loader_icon icon svg_stroke"
                svgIcon="loader"></mat-icon>
      Reset Password
    </button>
    <button (click)="dialogRef.close()"
            class="close_button"
            data-cy="close-button"
            mat-flat-button
            type="button"
    >Cancel
    </button>
  </div>
</mat-dialog-content>
