import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GalaxyEventType } from '@cp/common/protocol/Galaxy';
import { IpAccessListEntry } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { FullyQualifiedEvent, FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';

/**
 * Externally managed IP access list table. A dumb, view-only component.
 * See IpAccessListTableComponent as an example of the controller.
 */
@Component({
  selector: 'cp-ip-access-list-table-view',
  templateUrl: './ip-access-list-table-view.component.html',
  styleUrls: ['./ip-access-list-table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpAccessListTableViewComponent implements OnInit, OnChanges {
  @Input()
  ipAccessList: Array<IpAccessListEntry> = [];

  list: Array<IpAccessListEntry> = [];

  @Input()
  showNoAccessMessageOnEmptyList = true;

  @Input()
  actionButtons: Array<'EDIT' | 'DELETE'> = [];

  /** When defined (even if empty), enables selection checkboxes and checks items with the matched source in the list. */
  @Input()
  selectedSources?: string[];

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  /** Emits event every time user clicks edit entry button. */
  @Output()
  editButtonClick = new EventEmitter<IpAccessListEntry>();

  /** Emits event every time user clicks delete entry button. */
  @Output()
  deleteButtonClick = new EventEmitter<IpAccessListEntry>();

  /** Emits when checkbox for the item is clicked. */
  @Output()
  selectCheckboxChange = new EventEmitter<IpAccessListEntry>();

  constructor(private readonly accountStateService: AccountStateService) {}

  ngOnInit(): void {
    assertTruthy(this.ipAccessList);
    assertTruthy(this.eventPrefix);
  }

  ngOnChanges(): void {
    assertTruthy(this.ipAccessList);
  }

  get isEditButtonVisible(): boolean {
    return this.actionButtons.includes('EDIT');
  }

  get isDeleteButtonVisible(): boolean {
    return this.actionButtons.includes('DELETE');
  }

  get isActionRowVisible(): boolean {
    return this.isEditButtonVisible || this.isDeleteButtonVisible;
  }

  onSelectedEntryCheckboxChanged(entry: IpAccessListEntry): void {
    this.selectCheckboxChange.emit(entry);
  }

  isCurrentIp(entry: IpAccessListEntry): boolean {
    const currentIp = this.accountStateService.getUserDetailsOrFail().ipAddress;
    return entry.source === currentIp;
  }

  trackBySource(index: number, entry: IpAccessListEntry): string {
    return entry.source;
  }

  buildFullyQualifiedEvent(eventName: GalaxyEventType): FullyQualifiedEvent {
    return `${this.eventPrefix}.${eventName}`;
  }
}
