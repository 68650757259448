import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '@cp/web/app/auth/auth.service';

/** Guard for routes that can only be accessed by non-authenticated users. */
@Injectable({ providedIn: 'root' })
export class NonAuthenticatedUsersOnlyGuardService implements CanActivate, CanActivateChild {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  private async isLoggedIn(): Promise<boolean> {
    try {
      const accessToken = await this.authService.getAccessToken();
      return !!accessToken;
    } catch (error) {
      console.error('Error in NonAuthenticatedOnlyGuardService', error);
      return false;
    }
  }

  async canActivate(): Promise<boolean> {
    const isLoggedIn = await this.isLoggedIn();
    if (!isLoggedIn) {
      return true;
    }
    await this.router.navigate(['/'], { replaceUrl: true });
    return false;
  }

  canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
