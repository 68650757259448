import { isStrapiImage, StrapiImage } from '@cp/common/protocol/Strapi';

export type IntegrationCategory =
  /** Langauge client drivers like Go, Java, Python. */
  | 'LANGUAGE_CLIENT'
  /** Tools for consuming and transferring data, like Kafka and Airbyte. */
  | 'DATA_INGESTION'
  /** Tools for data visualization and BI tools, like Grafana and dbt. */
  | 'DATA_VISUALIZATION'
  /** SQL Console tools, like ArcType and DBeaver. */
  | 'SQL_CLIENT';

/** List of all integration categories to be displayed in the Marketplace page. */
export const ALL_INTEGRATION_CATEGORIES: ReadonlyArray<IntegrationCategory> = [
  'DATA_INGESTION',
  'DATA_VISUALIZATION',
  'SQL_CLIENT',
  'LANGUAGE_CLIENT'
];

export type IntegrationSupportLevel = 'CORE' | 'PARTNER' | 'COMMUNITY';
export type LicenseLevel = 'apache2' | 'mit' | 'mpl2' | 'agpl' | 'agpl2' | 'agpl3';
export type ReadinessLevel = 'experimental' | 'alpha' | 'beta' | 'new';

export interface Integration {
  name: string;
  /** Url to documentation page in CH website. */
  docsLink: string;
  /**
   * Short description of the integration (1 or 2 sentences).
   * This should be short enough to be displayed inside the integration card.
   */
  shortDescription: string;
  category: IntegrationCategory;
  /** Local path (from assets dir) to the logo image. */
  logo: StrapiImage;
  supportLevel: IntegrationSupportLevel;
  summary?: string;
  slug: string;
  version?: string;
  license?: LicenseLevel;
  readiness?: ReadinessLevel;
  about?: string;
  changelog?: string;
  website?: string;
}

/** A request for integration sent to ClickHouse by users. */
export interface SendRequestIntegration {
  text: string;
  recaptchaToken: string;
}

/** Return Integration support level as string. */
export function getIntegrationSupportLevelText(level: IntegrationSupportLevel): string {
  return level.charAt(0) + level.substring(1).toLowerCase();
}

/** Return Integration readiness level as string. */
export function getIntegrationReadinessLevelText(level: ReadinessLevel): string {
  return level.charAt(0).toUpperCase() + level.substring(1).toLowerCase();
}

/** Return Integration description. */
export function getIntegrationDescription(level: IntegrationSupportLevel): string {
  switch (level) {
    case 'COMMUNITY':
      return 'Built or maintained and supported by community members. No direct support is available besides the public Github repositories and community Slack channels.';

    case 'CORE':
      return 'Built or maintained by ClickHouse, supported by ClickHouse and live in the ClickHouse GitHub organization.';

    case 'PARTNER':
      return 'Built or maintained, and supported by, third-party software vendors.';

    default:
      throw new Error(`Unsupported integration level: ${level}`);
  }
}

/** Return Integration license level as string. */
export function getIntegrationLicenseLevelText(level: LicenseLevel): string {
  switch (level) {
    case 'apache2':
      return 'Apache 2.0';

    case 'mit':
      return 'MIT';

    case 'mpl2':
      return 'MPL 2.0';

    case 'agpl':
      return 'AGPL';

    case 'agpl2':
      return 'AGPL 2.0';

    case 'agpl3':
      return 'AGPL 3.0';

    default:
      throw new Error(`Unsupported license level: ${level}`);
  }
}

export function isIntegration(obj: unknown): obj is Integration {
  const integration = obj as Integration;
  return !!(
    integration.name &&
    integration.supportLevel &&
    integration.category &&
    integration.slug &&
    integration.docsLink &&
    integration.shortDescription &&
    integration.logo &&
    isStrapiImage(integration.logo)
  );
}
