import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { State } from '@cp/common-services/state/action.applier';
import { StateService } from '@cp/common-services/state/state.service';
import { TimePeriod } from '@cp/common/protocol/Metrics';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface InstanceMetricsDashboardUiState extends State {
  timePeriod: TimePeriod;
}

@Injectable({
  providedIn: 'root'
})
export class InstanceMetricsDashboardUiService extends BaseUiStateService<InstanceMetricsDashboardUiState> {
  private static readonly PATH = ['metrics-dashboard', 'instance'];

  constructor(stateService: StateService) {
    super(InstanceMetricsDashboardUiService.PATH, stateService);
  }

  observeDashboardState(instanceId: string): Observable<InstanceMetricsDashboardUiState> {
    return this.stateService
      .observePath<InstanceMetricsDashboardUiState>([...this.STATE_PATH, instanceId])
      .pipe(map((state) => (state === undefined ? { timePeriod: 'LAST_HOUR' } : state)));
  }

  /** Sets current state for instance dashboard. */
  setDashboardState(instanceId: string, state: Partial<InstanceMetricsDashboardUiState>): void {
    this.stateService.setInPath([...this.STATE_PATH, instanceId], state);
  }

  observeTimePeriod(instanceId: string): Observable<TimePeriod> {
    return this.observeDashboardState(instanceId).pipe(map((uiState) => uiState.timePeriod));
  }

  /** Sets current time period for instance dashboard. */
  setTimePeriod(instanceId: string, timePeriod: TimePeriod): void {
    this.setDashboardState(instanceId, { timePeriod });
  }
}
