import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { isDefined } from '@cp/common/protocol/Common';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { combineLatestWith, filter, map, startWith, takeUntil } from 'rxjs';
import { getUsersPageDbUsersTabPath, getUsersPageOrgUsersTabPath } from '../../app-routing-utils';
import {
  checkIfPathBelongsToPage,
  installOrganizationIdPageParameterHandler,
  PageComponentWithOrganizationId
} from '@cp/web/app/organizations/current-organization.helper';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { AccountStateService } from '@cp/web/app/account/account-state.service';

const userTabs = ['organization', 'database'] as const;
type UserTab = (typeof userTabs)[number];

@Component({
  selector: 'cp-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersPageComponent extends OnDestroyComponent implements OnInit, PageComponentWithOrganizationId {
  activeTab: UserTab = 'organization';
  dbUsersEnabled = this.accountStateService.observeUserDetails().pipe(
    map((details) => {
      return details && details.features.includes('FT_DB_USER_PAGE');
    })
  );

  private organizationId?: string;

  constructor(
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly organizationStateService: OrganizationStateService,
    private readonly accountStateService: AccountStateService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    installOrganizationIdPageParameterHandler(this);
  }

  ngOnInit(): void {
    const routerUrlObs = this.router.events.pipe(
      takeUntil(this.onDestroy),
      filter((e): e is RouterEvent => e instanceof RouterEvent),
      map((e) => e.url),
      startWith(this.router.url)
    );

    this.organizationStateService
      .observeCurrentOrganizationId()
      .pipe(takeUntil(this.onDestroy), filter(isDefined), combineLatestWith(routerUrlObs))
      .subscribe(([organizationId, routerUrl]) => {
        this.organizationId = organizationId;

        // Set activeTab based on current URL
        const canonicalUrl = this.buildCanonicalPagePathWithOrganizationId(organizationId, routerUrl);
        const urlTab = userTabs.find(
          (tab) => this.organizationId && this.getTabUrl(organizationId, tab) === canonicalUrl
        );
        this.activeTab = urlTab || 'organization';

        this.cdr.markForCheck();
      });
  }

  getTabUrl(organizationId: string, tab: UserTab): string {
    switch (tab) {
      case 'organization':
        return getUsersPageOrgUsersTabPath(organizationId);
      case 'database':
        return getUsersPageDbUsersTabPath(organizationId);
    }
  }

  getSelectedTabIndex(): number {
    return userTabs.indexOf(this.activeTab);
  }

  onTabChange(event: MatTabChangeEvent) {
    const selectedTab = userTabs[event.index];
    if (selectedTab && this.organizationId) {
      const url = this.getTabUrl(this.organizationId, selectedTab);
      this.router.navigate([url], { replaceUrl: true });
    }
  }

  /** Returns true if the given path part or the URL is handled by the current page component. */
  static isPagePath(path: string): boolean {
    return ['members', 'db-users'].some((urlFragment) =>
      checkIfPathBelongsToPage(path, 'organizations', urlFragment, `/organization/${urlFragment}`)
    );
  }

  buildCanonicalPagePathWithOrganizationId = (id: string, currentRouteUrl: string): string => {
    const isDbUserRoute = currentRouteUrl.endsWith('/db-users');
    const tab = isDbUserRoute ? 'database' : 'organization';
    return this.getTabUrl(id, tab);
  };
}
