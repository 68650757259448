import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isEmail, isInstanceName, isPassword, isSimpleName } from '@cp/common/utils/ValidationUtils';

export async function validateConfirmPassword(formControl: FormControl) {
  const formGroup: FormGroup | FormArray | null = formControl.parent;
  assertTruthy(formGroup instanceof FormGroup);

  const password = formGroup.controls['password']?.value;
  const confirmPassword = formControl.value ?? null;
  return confirmPassword != null && confirmPassword === password ? null : { valid: false };
}

export async function validateMoreThanOneWord(formControl: FormControl) {
  if (!formControl.value) {
    return { valid: false };
  }
  return formControl.value.trim().includes(' ') ? null : { valid: false };
}

export async function validateFormPassword(formControl: FormControl) {
  return isPassword(formControl.value) ? null : { valid: false };
}

export function validateFormEmail(formControl: FormControl): ValidationErrors | null {
  return isEmail(formControl.value) ? null : { valid: false };
}

type MinLengthTrimmedValidatorReturnType = {
  minLength: {
    actualLength: number;
    requiredLength: number;
  };
};

export function minLengthTrimmedValidator(
  minLength: number,
  optional = false
): (formControl: FormControl) => MinLengthTrimmedValidatorReturnType | null {
  return (formControl: FormControl) => {
    const value = (formControl.value || '').trim();
    if (optional && value === '') {
      return null;
    }
    return value.length >= minLength
      ? null
      : {
          minLength: {
            actualLength: value.length,
            requiredLength: minLength
          }
        };
  };
}

type MaxLengthTrimmedValidatorReturnType = {
  maxLength: {
    actualLength: number;
    requiredLength: number;
  };
};

export function maxLengthTrimmedValidator(
  maxLength: number,
  optional = false
): (formControl: FormControl) => MaxLengthTrimmedValidatorReturnType | null {
  return (formControl: FormControl) => {
    const value = (formControl.value || '').trim();
    if (optional && value === '') {
      return null;
    }

    return value.length <= maxLength
      ? null
      : {
          maxLength: {
            actualLength: value.length,
            requiredLength: maxLength
          }
        };
  };
}

export function organizationNameValidator(optional = false) {
  return simpleNameValidator(optional);
}

export function firstLastNameLengthValidator() {
  const MAX_SALESFORCE_NAME_LENGTH = 40;

  return (formControl: FormControl) => {
    // Make sure both first and last names separated by space are within salesforce length limit of 40.
    const nameWordsArray = (formControl.value || '').trim().split(' ');
    for (const word of nameWordsArray) {
      if (word.length > MAX_SALESFORCE_NAME_LENGTH) {
        return { nameTooLong: { value: word } };
      }
    }
    return null;
  };
}

export function userNameValidator(optional = false) {
  return simpleNameValidator(optional);
}

export function simpleNameValidator(optional = false) {
  return (formControl: FormControl) => {
    const value = (formControl.value || '').trim();
    return (optional && value === '') || isSimpleName(value) ? null : { nameInvalid: { value: formControl.value } };
  };
}

export function instanceNameValidator() {
  return (formControl: FormControl) => {
    const value = (formControl.value || '').trim();
    return isInstanceName(value) ? null : { valid: false };
  };
}
