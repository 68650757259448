import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toTimeByTimezone } from '@cp/common/utils/DateTimeUtils';
import { trackByTitle } from '@cp/web/app/common/utils/AngularUtils';
import { from, Observable } from 'rxjs';
import { Courses, WorkshopEventType } from './learn-page.protocol';
import { LearnPageService } from './learn-page.service';

interface Link {
  icon: string;
  title: string;
  description: string;
  url: string;
}

@Component({
  selector: 'cp-learn-page',
  templateUrl: './learn-page.component.html',
  styleUrls: ['./learn-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LearnPageComponent {
  upcomingEvents$ = new Observable<Array<WorkshopEventType>>();
  readonly links: Link[] = [
    {
      icon: 'glyph_code',
      title: 'Getting started',
      description: 'Everything you need for a successful start.',
      url: 'https://clickhouse.com/docs/en/cloud-quick-start'
    },
    {
      icon: 'glyph_ingest',
      title: 'Ingesting data',
      description: 'Loading data into your Cloud service.',
      url: 'https://clickhouse.com/docs/en/integrations/data-ingestion/'
    },
    {
      icon: 'glyph_integration',
      title: 'Integrating with ClickHouse',
      description: 'Connecting to third party apps and services.',
      url: 'https://clickhouse.com/docs/en/integrations/'
    },
    {
      icon: 'glyph_chart',
      title: 'Visualizing data',
      description: 'Connect to your favorite BI tools.',
      url: 'https://clickhouse.com/docs/en/integrations/data-visualization/'
    },
    {
      icon: 'glyph_connection',
      title: 'Connecting to ClickHouse',
      description: 'Sending queries to your Cloud service.',
      url: 'https://clickhouse.com/docs/en/integrations/connect-a-client/'
    },
    {
      icon: 'glyph_secure',
      title: 'Securing your services',
      description: 'Passwords, access lists, VPCs, and more.',
      url: 'https://clickhouse.com/docs/en/manage/security/'
    }
  ];
  readonly featured: Link[] = [
    {
      icon: 'glyph_gift',
      title: 'Cloud Changelog',
      description: 'See what’s new in the latest Cloud release.',
      url: 'https://clickhouse.com/docs/en/whats-new/cloud/'
    },
    {
      icon: 'logo_kafka',
      title: 'Integrating Kafka',
      description: 'Inserting Kafka based data into ClickHouse.',
      url: 'https://clickhouse.com/docs/en/integrations/kafka/'
    },
    {
      icon: 'glyph_code',
      title: 'Working with JSON',
      description: 'Exploring options with JSON and ClickHouse.',
      url: 'https://clickhouse.com/docs/en/guides/developer/working-with-json/'
    },
    {
      icon: 'logo_grafana',
      title: 'Connecting Grafana to ClickHouse',
      description: 'Beautiful visualizations in Grafana.',
      url: 'https://clickhouse.com/docs/en/connect-a-ui/grafana-and-clickhouse/'
    }
  ];
  readonly academyLinks: Array<Courses> = [
    {
      title: 'Learning Path: ClickHouse Cloud Onboarding',
      imgUrl:
        'https://learn.clickhouse.com/files/4367138/Cloud_Onboarding(2).png?lmsauth=f403ddba8a38c0af2f27f12a5f3e4ce7f747301a',
      time: '1.5 hrs',
      lessonCount: 5,
      url: 'https://learn.clickhouse.com/visitor_catalog_class/show/913421/'
    },
    {
      title: '101 - Getting Started',
      imgUrl:
        'https://learn.clickhouse.com/files/4367138/Getting_started(2).png?lmsauth=48d7c3416dba64c3387b991cb91fab86d3e7d054',
      time: '20 mins',
      lessonCount: 1,
      url: 'https://learn.clickhouse.com/visitor_catalog_class/show/913420/'
    },
    {
      title: '104 - Data Ingestion',
      imgUrl:
        'https://learn.clickhouse.com/files/4367138/Data_ingestion.png?lmsauth=9590efff112686c143131424be482717002b2659',
      time: '20 mins',
      lessonCount: 1,
      url: 'https://learn.clickhouse.com/visitor_catalog_class/show/912833/'
    }
  ];

  readonly trackByTitle = trackByTitle;

  constructor(private readonly learnPageService: LearnPageService) {
    this.upcomingEvents$ = from(this.learnPageService.loadUpcomingEvents()).pipe();
  }

  getTimeByTimezone(dateTime: Date, timeZone?: string): string {
    return toTimeByTimezone(dateTime, timeZone);
  }
}
