import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivationEnd, ActivationStart, Router } from '@angular/router';
import { BackRouteData, isBackRouteData } from '@cp/common-services/state/routes';
import { trackById } from '@cp/common-services/trackById';
import { GetUserDetailsResponse } from '@cp/common/protocol/Account';
import { getCssClassForUserFeatureFlag, UserFeatureId } from '@cp/common/protocol/features';
import { Organization } from '@cp/common/protocol/Organization';
import { isInternalUserEmail } from '@cp/common/utils/TestUsers';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { OrganizationInvitationsDialogComponent } from '@cp/web/app/account/organization-invitations-dialog/organization-invitations-dialog.component';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { isTestRun } from '@cp/web/app/common/utils/AngularUtils';
import { HelpFlyOutUiService } from '@cp/web/app/help/help-fly-out/help-fly-out-ui.service';
import { CreateOrganizationDialogComponent } from '@cp/web/app/organizations/create-organization/create-organization-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { filter, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  readonly trackById = trackById;
  readonly userDetailsObs = this.accountStateService.observeUserDetails();
  readonly currentOrganizationObs = this.organizationStateService.observeCurrentOrganization();
  readonly otherOrganizationsObs = this.currentOrganizationObs.pipe(
    switchMap((currentOrganization) => {
      return this.organizationStateService.observeOrganizations().pipe(
        map((organizationsMap) => {
          return Object.values(organizationsMap)
            .filter((organization) => organization.id !== currentOrganization.id)
            .sort((a, b) => b.createdAt - a.createdAt);
        })
      );
    })
  );
  readonly invitationsObs = this.accountStateService.observeOrgInvitations();

  /** Custom header class. Used only in custom 'user-features' mode. */
  headerClasses: string[] = [];
  enabledUserFeaturesFlags: Array<UserFeatureId> = [];

  backRouteData?: BackRouteData;

  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly dialog: MatDialog,
    private readonly accountStateService: AccountStateService,
    private readonly accountService: AccountService,
    private readonly helpFlyOutUiService: HelpFlyOutUiService,
    readonly router: Router,
    featuresService: FeaturesService,
    cdr: ChangeDetectorRef
  ) {
    featuresService.userFeatureFlags.subscribe((flags) => {
      this.enabledUserFeaturesFlags = [...flags];
      this.headerClasses =
        flags.size > 0 && this.isFFBannerEnabled
          ? ['experiment_mode_on', ...[...flags].map(getCssClassForUserFeatureFlag)]
          : [];
      cdr.markForCheck();
    });

    // Extract backRouteDetails from route activation snapshot if provided.
    router.events
      .pipe(
        filter(
          (event) =>
            event instanceof ActivationStart ||
            (event instanceof ActivationEnd && isBackRouteData(event.snapshot?.data))
        )
      )
      .subscribe((event) => {
        if (event instanceof ActivationStart) {
          this.backRouteData = undefined;
        }
        if (event instanceof ActivationEnd) {
          this.backRouteData = event.snapshot?.data as BackRouteData;
        }
        cdr.markForCheck();
      });
  }

  get isFFBannerEnabled(): boolean {
    const email = this.accountStateService.getUserDetailsOrFail().email;
    return isInternalUserEmail(email) || isTestRun();
  }

  switchOrganization(organization: Organization): void {
    this.organizationStateService.switchOrganization(organization.id);
  }

  async signOut(): Promise<void> {
    await this.accountService.signOut();
  }

  showCreateOrganizationDialog(): void {
    this.dialog.open(CreateOrganizationDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }

  showHelpFlyout(): void {
    this.helpFlyOutUiService.showFlyOut().then();
  }

  navigateBack(): void {
    if (!this.backRouteData) {
      return;
    }
    this.router.navigateByUrl(this.backRouteData.backPath).then();
  }

  canCreateOrganizations(userDetails: GetUserDetailsResponse): boolean {
    return userDetails.restrictions.canCreateOrganizations;
  }

  showInvitationsPopup(): void {
    this.dialog.open(OrganizationInvitationsDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'popup_modal',
      position: {
        top: -44 + 'px',
        right: 40 + 'px'
      }
    });
  }
}
