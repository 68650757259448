<mat-dialog-content class="restore_instance_backup_dialog" data-cy="restore-instance-backup-dialog">
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title dialogTitle="Restore this backup"></cp-dialog-title>
  <div class="text">
    The backup taken at {{backup.createdAt | date: 'medium' }} will be restored into a new service located
    in {{region.name}} ({{region.displayId}}).
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="dialogForm" class="form">
    <div class="seed_form_element">
      <label>Service name</label>
      <input formControlName="name" name="serviceName" type="text" spellcheck="false">
      <div *ngIf="!dialogForm.controls['name'].valid && dialogForm.controls['name'].touched"
           class="seed_error">
        Invalid service name entered
      </div>
    </div>

    <cw-system-message *ngIf="errorMessage" [message]="errorMessage"></cw-system-message>

    <div class="buttons">
      <button (click)="dialogRef.close()" class="close_button" mat-stroked-button type="button">Close</button>
      <button [disabled]="dialogForm.invalid || isRestoreButtonDisabled"
              class="button" color="primary" mat-flat-button
              data-cy="restore-this-backup-button"
              type="submit"
      >
        Restore this backup
      </button>
    </div>
  </form>
</mat-dialog-content>
