import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Instance, InstanceEndpoint, InstanceEndpointProtocol, InstanceState } from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { getConsoleDisabledTooltip, getConsoleUrl } from '@cp/web/app/common/utils/WebConsoleUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import copy from 'copy-to-clipboard';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-connect-to-instance-dialog',
  templateUrl: './connect-to-instance-dialog.component.html',
  styleUrls: ['./connect-to-instance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe]
})
export class ConnectToInstanceDialogComponent {
  readonly instanceObs: Observable<Instance | undefined>;
  readonly passwordObs: Observable<string | undefined>;
  readonly myOrgRoleObs: Observable<OrganizationRole>;
  instanceId: string;

  constructor(
    private readonly snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConnectToInstanceDialogComponent>,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceService: InstanceService,
    private readonly titlecasePipe: TitleCasePipe,
    @Inject(MAT_DIALOG_DATA) private readonly data: { instanceId: string },
    private readonly segmentService: SegmentService,
    private readonly instanceUiService: InstanceUiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly featuresService: FeaturesService,
    private readonly cdr: ChangeDetectorRef
  ) {
    const { instanceId } = data;
    this.instanceId = instanceId;
    this.instanceObs = this.instanceStateService.observeInstance(instanceId);
    this.passwordObs = this.instanceStateService.observeInstancePassword(instanceId);
    this.myOrgRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
  }

  getEndpointProtocols(endpoints: Record<InstanceEndpointProtocol, InstanceEndpoint>) {
    return Object.keys(endpoints).sort((a, b) => (a > b ? 1 : -1)) as Array<InstanceEndpointProtocol>;
  }

  copyToClipboard(data: string): void {
    copy(data);
    this.snackBar.open('Copied connection details to clipboard', 'Dismiss', { duration: 5000 });
  }

  getEndpointProtocolName(endpointProtocol: InstanceEndpointProtocol): string {
    switch (endpointProtocol) {
      case 'https':
        return 'HTTPS';
      case 'nativesecure':
        return 'Native';
      default:
        return this.titlecasePipe.transform(endpointProtocol);
    }
  }

  getEndpointDetails(endpoint: InstanceEndpoint, dbUsername: string, dbPassword: string | undefined): string {
    return `username = ${dbUsername}
      password = ${dbPassword ? dbPassword : '<password>'}
      hostname = ${endpoint.hostname}
      port     = ${endpoint.port}`
      .split('\n')
      .map((line) => line.trim())
      .join('\n');
  }

  protocolClicked(protocol: string): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'choose protocol',
      category: 'cloud ui service',
      properties: { protocol },
      view: 'servicePage',
      component: 'modal'
    });
  }

  getWebConsoleUri(instance: Instance): string {
    return getConsoleUrl(instance);
  }

  /*showPlayUi(instance: Instance): void {
    const httpsEndpoint = instance.endpoints.https;
    const url = `https://${httpsEndpoint?.hostname}:${httpsEndpoint?.port}/play?user=${instance.dbUsername}&theme=dark`;
    this.segmentService.trackGaEvent('click', 'sql console', 'cloud ui service');
    this.instanceUiService.showPlayUiDialog(url);
  }*/

  showInstanceResetPasswordDialog(instance: Instance): void {
    this.instanceUiService.showInstanceResetPasswordDialog(instance.id, instance.dbUsername);
  }

  reportSqlConsoleClicked(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'sql console',
      category: 'cloud ui service',
      component: 'button',
      view: 'serviceList'
    });
  }

  getDisabledTooltip(state: InstanceState) {
    return getConsoleDisabledTooltip(state);
  }

  isEnabled(instance: Instance): boolean {
    return (
      ['running', 'idle', 'awaking'].includes(instance.state) && instance.activeMaintenanceKind !== 'fullMaintenance'
    );
  }
}
