import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getCognitoErrorCode, parseCognitoErrorString } from '@cp/common/utils/MiscUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { SignInUiState } from '@cp/web/app/account/protocol/AccountStates';
import { SignInStorageService } from '@cp/web/app/account/sign-in/sign-in-storage.service';
import { SignInUiService } from '@cp/web/app/account/sign-in/sign-in-ui.service';
import { MFA_VERIFY_TOTP_URI } from '@cp/web/app/app-routing-utils';
import { AuthService } from '@cp/web/app/auth/auth.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import { validateFormEmail } from '@cp/web/app/common/utils/FormValidationUtils';
import { buildRecaptchaMutationObserver } from '@cp/web/app/common/utils/RecaptchaUtils';
import { ScriptsService } from '@cp/web/app/scripts/scripts.service';
import { environment } from '@cp/web/environments/environment';
import * as Sentry from '@sentry/angular';
import { firstValueFrom, Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'cp-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  readonly signInForm: FormGroup;
  readonly signInUiStateObs: Observable<SignInUiState>;
  readonly queryParams: Params;
  private recaptchaObserver: MutationObserver;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly scriptsService: ScriptsService,
    private readonly accountStateService: AccountStateService,
    private readonly uiService: SignInUiService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly signInStorageService: SignInStorageService,
    private readonly signInMetadataService: SignInMetadataService
  ) {
    super();
    if (this.activatedRoute.snapshot.queryParams['dest'] === 'matrixlms') {
      // In case of matrix lms sign-in, redirect to matrix lms login page (provided by okta).
      window.location.href = environment.matrixLmsLoginEndpoint;
    }
    this.signInStorageService.clear();
    this.queryParams = activatedRoute.snapshot.queryParams;
    this.signInUiStateObs = uiService.observeState();
    uiService.setPartialState({ signInButtonDisabled: false, errorMessage: undefined, successMessage: undefined });
    this.signInForm = this.formBuilder.group({
      email: ['', [Validators.required, validateFormEmail]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    authService
      .observeAuthErrors()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((error: string | undefined) => {
        const parsedError = parseCognitoErrorString(error ? error.replace(/\+/g, ' ').trim() : undefined);
        this.handleResponseError(parsedError);
      });

    if (!this.accountService.hasOauth2FlowParamsInUrl()) {
      authService.subscribeRedirectToHomePageIfAlreadyAuthenticated(this.onDestroy);
    }
    this.recaptchaObserver = buildRecaptchaMutationObserver();
  }

  ngOnInit(): void {
    try {
      this.scriptsService.loadGoogleTagScript();
      this.recaptchaObserver.observe(document.body, {
        attributes: false,
        childList: true,
        characterData: false
      });
    } catch (error) {
      console.log(error);
    }
  }

  override ngOnDestroy(): void {
    try {
      this.scriptsService.unloadGoogleTagScript();
      this.recaptchaObserver.disconnect();
    } catch (error) {
      console.log(error);
    }
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  async signInWithGoogle() {
    const sentryTransaction = Sentry.startTransaction({ name: 'signInWithGoogle' });
    const sentrySpan = sentryTransaction.startChild({ op: 'signInWithGoogle' });
    try {
      this.uiService.setPartialState({
        signInButtonDisabled: true,
        errorMessage: undefined,
        successMessage: undefined
      });
      this.signInStorageService.setSignInInProgress();
      await this.accountService.signInWithGoogle();

      sentrySpan.setData('status', 'ok');
    } catch (e) {
      sentrySpan.setData('status', 'error');
      console.error('Google sign in error', e);
    } finally {
      this.uiService.setPartialState({
        signInButtonDisabled: false,
        errorMessage: undefined,
        successMessage: undefined
      });
      sentrySpan.finish();
      sentryTransaction.finish();
    }
  }

  async onSubmit(): Promise<void> {
    if (this.signInForm.invalid) {
      return;
    }
    const sentryTransaction = Sentry.startTransaction({ name: 'signInWithCredentials' });
    try {
      const span = sentryTransaction.startChild({ op: 'signInWithCredentials' });
      this.uiService.setPartialState({
        signInButtonDisabled: true,
        errorMessage: undefined,
        successMessage: undefined
      });
      const { email, password } = this.signInForm.value;
      this.signInStorageService.setSignInInProgress();
      try {
        const response = await this.accountService.signInWithCredentials(email, password);

        if (response.isAwaitingTotpVerification) {
          this.router.navigate([MFA_VERIFY_TOTP_URI], { queryParams: this.activatedRoute.snapshot.queryParams }).then();
          return;
        }

        span.setData('status', 'ok');
      } catch (e: any) {
        span.setData('status', 'error');
        console.error('sign in error', e);
        const cognitoCode = getCognitoErrorCode(e);
        span.setData('cognitoCode', cognitoCode);
        this.handleResponseError(cognitoCode);
        return;
      } finally {
        span.finish();
      }

      if (!this.accountService.hasOauth2FlowParamsInUrl()) {
        const sentrySpan = sentryTransaction.startChild({ op: 'waitForAuth' });
        await firstValueFrom(this.accountStateService.waitForAuthenticatedState());
        sentrySpan.finish();
        await this.router.navigateByUrl('/');
      }
    } finally {
      sentryTransaction.finish();
    }
  }

  private handleResponseError(cognitoCode: string | undefined) {
    if (cognitoCode?.includes('Already found an entry for username')) {
      // See https://bobbyhadz.com/blog/aws-amplify-already-found-entry - known Cognito bug
      this.signInWithGoogle().then();
      return;
    }
    let errorMessage: string;
    console.error(cognitoCode || 'Undefined cognito error code');
    switch (cognitoCode) {
      case 'UserNotConfirmedException': {
        const email = this.signInForm.value.email;
        this.uiService.setPartialState({
          signInButtonDisabled: false,
          successMessage: `We've sent an email to <b class="fs-exclude">${email}</b>. Please follow the instructions to verify your account.`
        });
        return;
      }
      case 'INVITATION_REQUIRED':
        errorMessage = `ClickHouse Cloud is not open to the public at this time. To get early access to our serverless hosted offering, join the <a href="https://clickhouse.com/cloud/" target="_blank">wait list</a> today.`;
        break;
      default:
        errorMessage = 'Wrong email or password entered';
        break;
    }
    this.uiService.setPartialState({ signInButtonDisabled: false, errorMessage: errorMessage });
  }

  /** Returns Tackle token marketplace or undefined if there's no tackle token. */
  get tackleMarketplace(): string | undefined {
    return this.signInMetadataService.metadata.tackleToken?.marketplace;
  }
}
