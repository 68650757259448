import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isDefined } from '@cp/common/protocol/Common';
import { getAllowAnywhereIpAccessListEntry, isAllowAnywhere } from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { keepUniqueElements } from '@cp/common/utils/MiscUtils';
import { downloadText } from '@cp/cp-common-web/DownloadUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { AddIpAccessListEntryDialogComponent } from '@cp/web/app/instances/ip-access-list/add-ip-access-list-entry-dialog.component';
import {
  getImportIpAccessListFromFileDialogConfig,
  ImportIpAccessListFromFileDialogComponent
} from '@cp/web/app/instances/ip-access-list/import-ip-access-list-from-file-dialog.component';
import {
  getImportIpAccessListFromInstanceDialogConfig,
  ImportIpAccessListFromInstanceDialogComponent
} from '@cp/web/app/instances/ip-access-list/import-ip-access-list-from-instance-dialog.component';
import {
  AccessListJsonFile,
  AccessListJsonFileEntry,
  convertAccessListJsonFileToString
} from '@cp/web/app/instances/ip-access-list/ip-access-list-common';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { distinctUntilChanged, Observable, ReplaySubject, switchMap, take, takeUntil } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'cp-instance-security',
  templateUrl: './instance-security.component.html',
  styleUrls: ['./instance-security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceSecurityComponent extends OnDestroyComponent implements OnInit, OnChanges {
  @Input()
  instanceId!: string;

  readonly userOrgRoleObs: Observable<OrganizationRole>;

  readonly isAllowAnywhereObs: Observable<boolean>;

  private instanceIdSubject = new ReplaySubject<string>(1);

  /** Set to 'true' while network request to update ip-access-list is in-flight. */
  isPendingUpdate = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly instanceUiService: InstanceUiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.userOrgRoleObs = organizationStateService.observeCurrentOrganizationRole();
    this.isAllowAnywhereObs = this.instanceIdSubject.pipe(
      distinctUntilChanged(),
      switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId)),
      filter(isDefined),
      map((instance) => isAllowAnywhere(instance.ipAccessList))
    );
  }

  ngOnInit(): void {
    assertTruthy(this.instanceId);
  }

  ngOnChanges(): void {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);
  }

  showAddIpAccessEntryDialog(): void {
    AddIpAccessListEntryDialogComponent.show(this.dialog, this.instanceId);
  }

  showImportFromAnotherInstanceDialog(): void {
    const config: MatDialogConfig<string> = {
      ...getImportIpAccessListFromInstanceDialogConfig(),
      data: this.instanceId
    };
    this.dialog.open(ImportIpAccessListFromInstanceDialogComponent, config);
  }

  showImportIpAccessListDialog(): void {
    const config: MatDialogConfig<string> = {
      ...getImportIpAccessListFromFileDialogConfig(),
      data: this.instanceId
    };
    this.dialog.open(ImportIpAccessListFromFileDialogComponent, config);
  }

  exportIpAccessList(): void {
    const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
    const jsonFile: AccessListJsonFile = {
      addresses: instance.ipAccessList.map<AccessListJsonFileEntry>((e) => ({
        address: e.source,
        description: e.description
      }))
    };
    const jsonFileContent = convertAccessListJsonFileToString(jsonFile);
    downloadText(jsonFileContent, `ip-access-list-${this.instanceId}.json`);
  }

  async switchToSpecificLocations(): Promise<void> {
    AddIpAccessListEntryDialogComponent.show(this.dialog, this.instanceId, 'SWITCH_FROM_ANYWHERE_TO_SPECIFIC_IPS');
  }

  async switchToAllowAnywhere(): Promise<void> {
    this.instanceUiService
      .showConfirmAccessFromAnywhereDialog()
      .afterClosed()
      .pipe(take(1), takeUntil(this.onDestroy), filter(Boolean))
      .subscribe(async () => {
        try {
          this.isPendingUpdate = true;
          this.cdr.markForCheck();
          const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
          // Keep existing IP access list entries, but add 'allowAnywhere'.
          const newIpAccessList = keepUniqueElements(
            [...instance.ipAccessList, getAllowAnywhereIpAccessListEntry()],
            (e1, e2) => e1.source === e2.source
          );
          await this.instanceService.updateIpAccessList(this.instanceId, newIpAccessList);
          this.snackBar.open('Your IP access list has been updated', 'Dismiss', { duration: 5000 });
        } catch (e) {
          console.error(e);
          this.snackBar.open('Failed to update access list', 'Dismiss', { duration: 5000 });
        } finally {
          this.isPendingUpdate = false;
          this.cdr.markForCheck();
        }
      });
  }
}
