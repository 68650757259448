<div class="flyout_content">
  <header class="header" *ngIf="userPermissions">
    <div class="names">
      <div class="user_name">
        {{ userPermissions.username }}
      </div>
      <div class="service_name">
        {{ instanceName }}
      </div>
    </div>
    <button class="close" (click)="close()" galaxyClick="dbUsersPage.userDetailsFlyout.closeButtonSelect">
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </header>
  
  <div class="roles_title">Database roles</div>
  
  <div *ngFor="let role of selectedUserRoles()">
    <div class="permission_category">{{ role.roleName }}</div>
    <div class="permissions_summary">
      <div *ngFor="let grant of grantSummaries(role.grants)">
        <span class="keyword">{{ grant.accessType }} </span>
        <span *ngIf="grant.columnName">({{ grant.columnName }}) </span>
        <span class="keyword">ON</span>
        <span> ({{ grant.objectName }})</span>
      </div>
    </div>
  </div>
  <div *ngIf="selectedUserDirectGrants().length > 0">
    <div class="permission_category">Privileges assigned individually</div>
    <div class="permissions_summary">
      <div *ngFor="let grant of grantSummaries(selectedUserDirectGrants()); trackBy: trackByGrant">
        <span class="keyword">{{ grant.accessType }} </span>
        <span *ngIf="grant.columnName">({{ grant.columnName }}) </span>
        <span class="keyword">ON</span>
        <span> ({{ grant.objectName }})</span>
      </div>
    </div>
  </div>
</div>
