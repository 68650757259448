import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import { UserRestrictions } from '@cp/common/protocol/Account';
import { BACKUP_API_PATH, CreateInstanceBackupRequest } from '@cp/common/protocol/Backup';
import { GetClientSecretResponse } from '@cp/common/protocol/Billing';
import { OrganizationRestrictions } from '@cp/common/protocol/Organization';
import { RunWithRetryPayload } from '@cp/common/protocol/Retry';
import {
  SysadminSendPayloadsRequest,
  SysadminUpdateCpUserRestrictionsRequest,
  SysadminUpdateOrganizationRestrictionsRequest
} from '@cp/common/protocol/Sysadmin';
import { AccountApiService } from '@cp/web/app/account/account-api.service';
import { BillingApiService } from '@cp/web/app/admin/billing-api.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private readonly accountApiService: AccountApiService,
    private readonly restService: RestService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly billingApiService: BillingApiService
  ) {}

  // noinspection JSUnusedGlobalSymbols
  async getClientSecret(): Promise<GetClientSecretResponse> {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    return this.billingApiService.getClientSecret(organizationId);
  }

  // noinspection JSUnusedGlobalSymbols
  async sendPayloads(payloads: Array<RunWithRetryPayload>): Promise<void> {
    const request: SysadminSendPayloadsRequest = {
      payloads,
      rpcAction: 'sysadminSendPayloads'
    };
    await this.restService.post('admin', request);
  }

  // noinspection JSUnusedGlobalSymbols
  async updateUserRestrictions(userId: string, restrictions: Partial<UserRestrictions>): Promise<void> {
    const request: SysadminUpdateCpUserRestrictionsRequest = {
      userId,
      restrictions,
      rpcAction: 'sysadminUpdateCpUserRestrictions'
    };
    await this.restService.post('admin', request);
  }

  // noinspection JSUnusedGlobalSymbols
  async updateOrganizationRestrictions(
    organizationId: string,
    restrictions: Partial<OrganizationRestrictions>
  ): Promise<void> {
    const request: SysadminUpdateOrganizationRestrictionsRequest = {
      organizationId,
      restrictions,
      rpcAction: 'sysadminUpdateOrganizationRestrictions'
    };
    await this.restService.post('admin', request);
  }

  // noinspection JSUnusedGlobalSymbols
  async createInstanceBackup(instanceId: string): Promise<void> {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    const request: CreateInstanceBackupRequest = { rpcAction: 'create', organizationId, instanceId };
    try {
      await this.restService.post(BACKUP_API_PATH, request);
    } catch (e) {
      console.error('createInstanceBackup', e);
    }
  }
}
