import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type UserMfaInputComponentData = {
  title: string;
  description: string;
  fieldDescription?: string;
  helpText: string;
  inputValue: string;
  primaryActionLabel: string;
  secondaryActionLabel?: string;
  errorMessage?: string;
  isVerifying: boolean;
  isPrimaryActionDisabled: boolean;
};

function getDefaultUserMfaInputData(): UserMfaInputComponentData {
  return {
    title: '',
    description: '',
    fieldDescription: '',
    helpText: '',
    inputValue: '',
    primaryActionLabel: '',
    secondaryActionLabel: '',
    errorMessage: '',
    isVerifying: false,
    isPrimaryActionDisabled: true
  };
}

@Component({
  selector: 'cp-user-mfa-input-page',
  templateUrl: './user-mfa-input-page.component.html',
  styleUrls: ['./user-mfa-input-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMfaInputPageComponent {
  @Input() data = getDefaultUserMfaInputData();
  @Output() inputUpdate = new EventEmitter<string>();
  @Output() primaryAction = new EventEmitter<string>();
  @Output() secondaryAction = new EventEmitter<void>();

  onPrimaryAction(value: string): void {
    this.primaryAction.emit(value);
  }

  onSecondaryAction(): void {
    this.secondaryAction.emit();
  }

  onChange(value: string): void {
    this.inputUpdate.emit(value);
  }
}
