import { Injectable } from '@angular/core';
import { WebSocketService } from '@cp/common-services/web-socket.service';

import {
  AcceptOrganizationInvitationResponse,
  Organization,
  OrganizationActivity,
  OrganizationInvitationDetails,
  OrganizationRole,
  OrganizationUpdatePayload
} from '@cp/common/protocol/Organization';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { ApiKeysStateService } from '@cp/web/app/organizations/api-keys/api-keys-state-service';
import { OrganizationApiService } from '@cp/web/app/organizations/organization-api.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { from, NEVER, Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(
    private readonly organizationApiService: OrganizationApiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly apiKeysStateService: ApiKeysStateService,
    private readonly webSocketService: WebSocketService,
    private readonly accountStateService: AccountStateService,
    private readonly segmentService: SegmentService
  ) {}

  async refreshOrganizations(): Promise<void> {
    const organizations = await this.organizationApiService.listOrganizations();
    this.organizationStateService.setOrganizations(organizations);
    if (Object.values(organizations).length > 0) {
      this.assignCurrentOrganizationId();
    }
  }

  async createOrganization(name: string): Promise<Organization> {
    const organization = await this.organizationApiService.createOrganization(name);
    this.segmentService.trackGaEvent({
      event: 'modify organization',
      label: 'create organization',
      category: 'cloud ui',
      properties: { name },
      component: 'organizationModal',
      view: 'admin'
    });
    return organization;
  }

  async deleteInvitation(organizationId: string, email: string) {
    await this.organizationApiService.deleteInvitation(organizationId, email);
  }

  async changeRole(organizationId: string, userId: string, role: OrganizationRole) {
    await this.organizationApiService.changeRole(organizationId, userId, role);
  }

  async removeUser(organizationId: string, userId: string) {
    await this.organizationApiService.removeUser(organizationId, userId);
  }

  async invite(organizationId: string, emails: Array<string>, roles: Array<OrganizationRole>) {
    await this.organizationApiService.invite(organizationId, [...emails], [...roles]);
    for (let i = 0; i < emails.length; i++) {
      this.segmentService.trackGaEvent({
        event: 'invite member',
        label: 'invite member',
        category: 'cloud ui',
        properties: {
          email: emails[i],
          role: roles[i]
        },
        component: 'organizationModal',
        view: 'admin'
      });
    }
  }

  async resendInvite(organizationId: string, email: string, role: OrganizationRole) {
    await this.organizationApiService.resendInvite(organizationId, email, role);
    this.segmentService.trackGaEvent({
      event: 'invite member',
      label: 'invite member',
      category: 'cloud ui',
      properties: {
        email: email,
        role: role
      },
      component: 'summary',
      view: 'membersPage'
    });
  }

  async changeOrganizationName(organizationId: string, name: string) {
    await this.organizationApiService.changeOrganizationName(organizationId, name);
    this.segmentService.trackGaEvent({
      event: 'modify organization',
      label: 'rename organization',
      category: 'cloud ui org setting',
      view: 'admin',
      component: 'modal'
    });
  }

  async leaveOrganization(organizationId: string) {
    await this.organizationApiService.leaveOrganization(organizationId);
  }

  async deleteOrganization(organizationId: string) {
    await this.organizationApiService.deleteOrganization(organizationId);
    this.segmentService.trackGaEvent({
      event: 'modify organization',
      label: 'delete organization',
      category: 'cloud ui org setting',
      view: 'admin',
      component: 'modal'
    });
  }

  async acceptInvitation(orgInviteKey: string): Promise<AcceptOrganizationInvitationResponse> {
    const result = await this.organizationApiService.acceptInvitation(orgInviteKey);
    if (result.errorCode !== 'ok') {
      return result;
    }

    this.accountStateService.setOrgInviteKey(undefined);
    this.segmentService.trackGaEvent({
      event: 'click email',
      label: 'accept invitation',
      category: 'cloud ui',
      component: 'summary',
      view: 'acceptInvitationsPage'
    });
    await this.refreshOrganizations();
    return result;
  }

  async declineInvitation(invitationKey: string): Promise<void> {
    await this.organizationApiService.declineInvitation(invitationKey);
  }

  observeActivities(): Observable<Array<OrganizationActivity>> {
    return this.organizationStateService.observeCurrentOrganizationId().pipe(
      switchMap((orgId) => {
        if (!orgId) return NEVER;
        return from(this.organizationApiService.listActivities(orgId));
      })
    );
  }

  listenToOrgUpdates() {
    return this.webSocketService
      .observeNotification<OrganizationUpdatePayload>({
        type: 'ORG_UPDATE',
        objId: this.accountStateService.getUserIdOrFail()
      })
      .pipe(
        tap(({ payload }) => {
          if (payload.updateType === 'UNLINK') {
            this.organizationStateService.deleteOrganization(payload.organizationId);
            this.apiKeysStateService.cleanOrganizationData(payload.organizationId);
            if (payload.organizationId === this.organizationStateService.getCurrentOrgId()) {
              this.assignCurrentOrganizationId();
            }
          } else if (payload.updateType === 'COMPLETE') {
            this.organizationStateService.setOrganizations(payload.organizations);
            const currentOrganizationId = this.organizationStateService.getCurrentOrgId();
            if (payload.organizations.some((o) => o.id === currentOrganizationId)) {
              this.assignCurrentOrganizationId();
            }
          } else {
            // Partial update.
            for (const organization of payload.organizations) {
              this.organizationStateService.setOrganization(organization);
              /** If this is the first sign in, the first partial update is when we get the first org. */
              if (!this.organizationStateService.getCurrentOrgId()) {
                this.assignCurrentOrganizationId();
              }
            }
          }
        })
      );
  }

  assignCurrentOrganizationId(): void {
    const organizations = this.organizationStateService.getOrganizations();
    let currentOrganizationId = localStorage.getItem('currentOrganizationId');
    if (!currentOrganizationId || !organizations[currentOrganizationId]) {
      currentOrganizationId = Object.values(organizations).sort((a, b) => b.createdAt - a.createdAt)[0].id;
    }
    this.organizationStateService.switchOrganization(currentOrganizationId);
  }

  isCurrentUserAdmin(): boolean {
    return (
      this.organizationStateService.getCurrentOrgOrFail().users[this.accountStateService.getUserIdOrFail()].role ===
      'ADMIN'
    );
  }

  async createDefaultOrgForUser(): Promise<void> {
    await this.organizationApiService.createDefaultOrganizationForUser();
    await this.refreshOrganizations();
    await this.organizationStateService.getCurrentOrgOrFail();
  }

  async markViewedInvitations(invitations: OrganizationInvitationDetails[]): Promise<void> {
    const expiredInvitationKeys = invitations
      .filter((invite) => invite.isExpired)
      .map((invite) => invite.invitationKey);
    if (expiredInvitationKeys.length) {
      await this.organizationApiService.markViewedInvitations(expiredInvitationKeys);
    }
  }
}
