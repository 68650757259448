<div class="add_credit_card_content">
  <div class="scrollable_wrapper padded_content">
    <div class="seed_form_element">
      <label *ngIf="uiState.stripeReady">Credit Card</label>
      <div
        galaxyClick="billing.modal.cardNumberInputFocus"
        galaxyBlur="billing.modal.cardNumberInputBlur">
        <div id="cardNumberElement" [ngClass]="{ready: uiState.stripeReady}"></div>
      </div>
      <div *ngIf="uiState.stripeCardNumberErrorMessage"
           class="seed_error">
        {{uiState.stripeCardNumberErrorMessage}}
      </div>
    </div>

    <div class="split_row">
      <div class="seed_form_element">
        <label *ngIf="uiState.stripeReady">Expiration date</label>
        <div
          galaxyClick="billing.modal.cardExpiryInputFocus"
          galaxyBlur="billing.modal.cardExpiryInputBlur">
          <div id="cardExpiryElement"></div>
        </div>
        <div class="seed_error"
             *ngIf="uiState.stripeCardExpiryErrorMessage">
          {{uiState.stripeCardExpiryErrorMessage}}
        </div>
      </div>

      <div class="seed_form_element">
        <label *ngIf="uiState.stripeReady">Security code</label>
        <div
          galaxyClick="billing.modal.cardCvcInputFocus"
          galaxyBlur="billing.modal.cardCvcInputBlur">
          <div id="cardCvcElement"></div>
        </div>
        <div class="seed_error"
             *ngIf="uiState.stripeCardCvcErrorMessage">
          {{uiState.stripeCardCvcErrorMessage}}
        </div>
      </div>
    </div>


    <div *ngIf="!uiState.stripeReady" class="loading_wrapper">
      <mat-spinner *ngIf="!uiState.stripeReady" class="loading"></mat-spinner>
    </div>
  </div>

  <div class="vertical_buttons padded_content">
    <button
      (click)="onSubmit()"
      [disabled]="!uiState.stripeComplete || uiState.buttonDisabled"
      class="main_action"
      color="primary" data-cy="save-billing-button" mat-flat-button
    >Save billing information
    </button>
    <button
      galaxyClick="billing.modal.backButtonSelect"
      data-cy="save-billing-back-button"
      (click)="handleBackButton()"
      class="secondary_action" mat-stroked-button>Back</button>
    <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>
  </div>
</div>
