<div *ngIf="kind === 'BADGE'" [class]="state" class="badge">
  <mat-icon [svgIcon]="svgIcon" class="icon"></mat-icon>
  <div class="text" data-cy="service-state-text">{{stateText}}</div>
</div>

<div *ngIf="kind === 'ICON_AND_LABEL'" [ngClass]="additionalClass ?? ''" class="icon_and_label">
  <div [class]="state" class="icon_rect">
    <mat-icon [svgIcon]="svgIcon" class="icon"></mat-icon>
  </div>
  <div class="text" data-cy="service-state-text">{{stateText}}</div>
  <div class="icon_rect info_icon_rect" data-cy="idle-tooltip-icon"
       *ngIf="state === 'idle'"
       [matTooltip]="'Your service will resume running when\ndata is actively being ingested or queried'"
       matTooltipPosition="right"
  >
    <mat-icon class="info_icon" svgIcon="info_circle"></mat-icon>
  </div>
</div>

