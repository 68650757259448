import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Instance } from '@cp/common/protocol/Instance';
import { SupportCaseDetails } from '@cp/common/protocol/Support';
import { truthy } from '@cp/common/utils/Assert';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { SupportCasesStateService } from '@cp/web/app/support/support-cases-state.service';
import { supportCaseStatusDisplay } from '@cp/web/app/support/support-protocol';
import { SupportUiService } from '@cp/web/app/support/support-ui.service';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cp-support-case-page',
  templateUrl: './support-case-page.component.html',
  styleUrls: ['./support-case-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportCasePageComponent {
  caseDetailsObs?: Observable<SupportCaseDetails>;
  instanceObs?: Observable<Instance | undefined>;
  descriptionExpanded = false;
  supportCaseStatusDisplay = supportCaseStatusDisplay;
  private caseIdSubject = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly supportCasesStateService: SupportCasesStateService,
    private readonly supportUiService: SupportUiService,
    private readonly instanceStateService: InstanceStateService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      const caseId = params['caseId'];
      this.caseIdSubject.next(caseId);
      this.supportCasesStateService.setCurrentCaseId(caseId);
    });

    this.caseDetailsObs = this.caseIdSubject.pipe(
      filter(Boolean),
      switchMap((caseId) => {
        return this.supportCasesStateService
          .observeSupportCase(caseId)
          .pipe(filter((details) => details.events?.length > 0));
      })
    );

    this.instanceObs = this.caseDetailsObs.pipe(
      switchMap((caseDetails) => {
        if (!caseDetails.serviceId) return of(undefined);
        return this.instanceStateService.observeInstance(caseDetails.serviceId);
      })
    );
  }

  getNumberOfLines(description: string): number {
    return description.split(/\n/).length;
  }

  showCreateSupportCaseDialog(): void {
    this.supportUiService.showCreateCaseDialog();
  }

  showReplyDialog(): void {
    this.supportUiService.showCaseReplyDialog(truthy(this.caseIdSubject.value));
  }
}
