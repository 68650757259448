/** List of all user features registered in the system. */
export const USER_FEATURES = [
  /** Flag name used to check the user features flag system itself.  When enabled, the app header color is changed. */
  'FT_USER_SELF_TEST',
  /** Allows user to select experimental regions for new CH instances. See Region.isExperimental flag. */
  'NEW_DP_REGIONS',
  /** Enables a user to claim a trial again and again (ignores UserRestrictions.canClaimTrial). */
  'UNLIMITED_TRIALS',
  /** Enables "ResidentMemoryMetricReport" on instance dashboard. */
  'SHOW_RESIDENT_MEMORY_USAGE',
  /** Enables database user API and UI */
  'FT_DB_USER_PAGE',
  /** Enables users to upgrade their instances*/
  'FT_UPGRADE_INSTANCE',
  /** Enables users to use GPT for query completion*/
  'FT_GPT_QUERY_COMPLETION',
  /** Enables access to Auth0 demo. */
  'FT_USER_AUTH0',
  /** Call real customer key validation lambda in dev for CMEK (used for testing) */
  'FT_CALL_CMEK_LAMBDA',
  /** Enables new metric clusters for all user requests for GCP instances. */
  'FT_USER_USE_GCP_METRIC_CLUSTER',
  /** Enable the running queries menu in sql console */
  'FT_USER_SHOW_RUNNING_QUERIES',
  /** The metrics requests from the user won't use cache. */
  'FT_USER_BYPASS_METRICS_CACHE',
  /** Enables SQL-Console metadata with new cache flow */
  'FT_USER_SQL_CONSOLE_METADATA_WITH_CACHE',
  /** Enable access to the unified console */
  'FT_USER_UNIFIED_CONSOLE',
  /** Enable access to the unified console's new org details page */
  'FT_USER_UNIFIED_CONSOLE_ORGANIZATION_DETAILS_PAGE',
  /** Enable access to the unified console's new billing page */
  'FT_USER_UNIFIED_CONSOLE_BILLING_PAGE',
  /** Enable access to the unified console's new usage page */
  'FT_USER_UNIFIED_CONSOLE_USAGE_PAGE',
  /** Enable wake service confirmation modal in sql console */
  'FT_USER_CONFIRM_SERVICE_WAKE',
  /** Moving idling controls to create service experience */
  'FT_IDLE_IN_CREATE_SERVICE',
  /** Enable the new dataloading feature */
  'FT_USER_CHUNKING_DATA_LOADS'
] as const;

export type UserFeatureId = (typeof USER_FEATURES)[number];

const ALL_USER_FEATURE_FLAG_SET: ReadonlySet<UserFeatureId> = new Set<UserFeatureId>([...USER_FEATURES]);

export function isUserFeatureId(value: unknown): value is UserFeatureId {
  return ALL_USER_FEATURE_FLAG_SET.has(value as UserFeatureId);
}

/**
 * Returns CSS class name for the given user feature flag.
 * Used by E2E tests to check if the feature is enabled.
 */
export function getCssClassForUserFeatureFlag(flag: string): string {
  return `user_feature_${flag}`;
}
