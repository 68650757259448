import { Inject, Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import {
  InstanceMetricRequest,
  InstanceMetricResponse,
  MetricArgs,
  MetricReport,
  SendMetricsFeedbackRequest
} from '@cp/common/protocol/Metrics';
import { truthy } from '@cp/common/utils/Assert';
import { RECAPTCHA_PROVIDER_TOKEN, RecaptchaProviderFn } from '@cp/web/app/common/utils/RecaptchaUtils';
import { InstanceMetricsStateService } from '@cp/web/app/metrics/instance-metrics-state-service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  constructor(
    private readonly restService: RestService,
    private readonly instanceMetricsStateService: InstanceMetricsStateService,
    private readonly organizationStateService: OrganizationStateService,
    @Inject(RECAPTCHA_PROVIDER_TOKEN) private readonly recaptchaProviderFn: RecaptchaProviderFn
  ) {
    this.organizationStateService.observeCurrentOrganizationId().subscribe(() => {
      this.instanceMetricsStateService.clear();
    });
  }

  observeInstanceMetric<R extends MetricReport>(instanceId: string, query: MetricArgs): Observable<R | undefined> {
    return this.instanceMetricsStateService
      .observeInstanceMetric(instanceId, query)
      .pipe(map((state) => state?.report as R | undefined));
  }

  observeInstanceMetricError(instanceId: string, query: MetricArgs): Observable<string | undefined> {
    return this.instanceMetricsStateService.observeInstanceMetricError(instanceId, query);
  }

  async refreshInstanceMetric(instanceId: string, query: MetricArgs): Promise<void> {
    const request: InstanceMetricRequest = {
      organizationId: this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId,
      batch: [query]
    };
    try {
      const response = await this.restService.post<InstanceMetricResponse>('metrics/queryMetrics', request);
      const report = truthy(response.batch[0]);
      if (typeof report === 'string') {
        // CP API returned an error message instead of the report.
        this.instanceMetricsStateService.setInstanceMetricError(instanceId, query, report);
      } else {
        this.instanceMetricsStateService.setInstanceMetric(instanceId, { query, report });
      }
    } catch (e: unknown) {
      // Keep the old value in case of error. Otherwise, register an error state.
      const currentMetricState = this.instanceMetricsStateService.getInstanceMetricSnapshot(instanceId, query);
      if (!currentMetricState) {
        this.instanceMetricsStateService.setInstanceMetricError(instanceId, query, 'Failed to fetch data');
      }
    }
  }

  public async sendMetricsFeedback(feedbackText: string): Promise<void> {
    const recaptchaToken = await this.recaptchaProviderFn();
    const request: SendMetricsFeedbackRequest = { text: feedbackText, recaptchaToken };
    await this.restService.post('metrics/sendMetricsFeedback', request);
  }
}
