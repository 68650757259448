<div class="permissions" *ngIf="instance">
  <table class="permissions_table">
    <thead>
      <tr class="header_row">
        <th colspan="3">
          <div class="instance_header">
            {{instance.name}}
            <div
              [class]="statusBadgeClass()"
              [matTooltip]="instanceStatusTooltip(instance)"
              matTooltipPosition="right"
              matTooltipClass="user_management_instance_status_tooltip">

              {{formatStatus()}}

              <mat-icon
                *ngIf="instance.state === 'idle'"
                svgIcon="info_circle"
                class="info_icon">
              </mat-icon>
            </div>
          </div>
        </th>
      </tr>
      <tbody>
        <tr *ngIf="(permissionsResult.status === 'unloaded' || permissionsResult.status === 'error') && canLoadUsers(instance.state)" class="info_row">
          <td colspan="3">
            <button (click)="loadUsersForInstance()" class="fetchButton">
              Show users
            </button>
            <span class="load_error" *ngIf="permissionsResult.status === 'error'">
              Error loading users
            </span>
          </td>
        </tr>

        <tr *ngIf="!canLoadUsers(instance.state)" class="info_row">
          <td colspan="3">
            Cannot retrieve users from {{permissionsResult.status}} services.
          </td>
        </tr>

        <tr *ngIf="permissionsResult.status === 'loading'" class="info_row">
          <td colspan="3">
            <div class="loading_message">
              <mat-spinner class="spinner" [diameter]="30">
              </mat-spinner>

              <span *ngIf="instance.state === 'idle' || instance.state === 'awaking'">
                Waking up your service...
              </span>
            </div>
          </td>
        </tr>

        <tr *ngFor="let user of users(); trackBy: trackByUsername">
          <td>
            <a (click)="selectUser(user.username)">
              {{user.username}}
            </a>
          </td>

          <td>
            <span class="permission" *ngFor="let permission of userPermissions(user); trackBy: trackByIdentity">
              {{permission}}
            </span>
            <span class="permission" *ngIf="hasDirectPermissions(user)">
              Directly assigned permissions
            </span>
          </td>

          <td>
            <div *ngIf="user.username === this.instance.dbUsername" (click)="$event.stopPropagation()">
              <button
                [matMenuTriggerFor]="defaultUserMenu"
                mat-button>
                <mat-icon class="icon svg_fill">more_vert</mat-icon>
              </button>
              <!--<mat-icon fontIcon="more_vert" class="more_icon"></mat-icon>-->
            </div>
          </td>
        </tr>
      </tbody>
  </table>

  <mat-menu #defaultUserMenu>
    <div
      mat-menu-item
      (click)="resetInstancePassword()">
      Reset password
    </div>
  </mat-menu>
</div>

