<div class="sign_up_container auth_page_container"
     *ngIf="signUpUiStateObs | async as uiState"
     [ngClass]="{auth_page_container_with_aws: tackleMarketplace, verify_email_container: uiState.signUpComplete }"
     data-cy="sign-up-component">
  <div class="auth_page_section">
    <div class="container">
      <ng-container *ngVar="regionBlockedObs | async as blocked">
        <ng-container *ngIf="blocked && !isBlockedRegionIgnored">
          <cw-auth-logo type="full" color="primary-dark" class="region-blocked"></cw-auth-logo>
          <form class="create_instance_container">
            <cp-region-block-message [showContinueButton]="true"
                                     (continueClick)="onContinueClicked()"></cp-region-block-message>
          </form>
        </ng-container>
        <ng-container *ngIf="!blocked || isBlockedRegionIgnored">
          <ng-container *ngIf="!uiState.signUpComplete">
            <div class="sign_up_section">
              <div class="sign_up_left_container">
                <div class="logo_line" [ngClass]="{logo_line_with_marketplace: !!tackleMarketplace}">
                  <cw-auth-logo type="full" color="primary-dark"
                                [ngClass]="{noMargin: !!tackleMarketplace}"></cw-auth-logo>
                  <ng-container *ngIf="tackleMarketplace">
                    <div class="">+</div>
                    <div
                      [ngClass]="{aws_marketplace_logo: tackleMarketplace === 'aws', gcp_marketplace_logo: tackleMarketplace === 'gcp'}"
                      data-cy="marketplace-logo"
                    ></div>
                  </ng-container>
                </div>

                <div *ngIf="tackleMarketplace" class="marketplace_alert" data-cy="marketplace-alert">
                  <div class="marketplace_alert_info_icon">
                    <mat-icon svgIcon="i_letter" class="market_place_alert_icon"></mat-icon>
                  </div>
                  <div class="marketplace_alert_container">
                    <b class="marketplace_alert_title">Important</b>
                    <p> Do not close this browser window until you sign in or register successfully.</p>
                  </div>
                </div>
                <div *ngIf="tackleMarketplace" class="marketplace_alert"
                     data-cy="marketplace-alert">
                  <div class="marketplace_alert_info_icon">
                    <mat-icon svgIcon="i_letter" class="market_place_alert_icon"></mat-icon>
                  </div>
                  <div class="marketplace_alert_container">
                    <b class="marketplace_alert_title">Existing ClockHouse Cloud user?</b>
                    <p>
                      <a routerLink="/signIn" [queryParams]="queryParams">Sign in</a> to create a new ClickHouse Cloud
                      organization that is connected with your {{tackleMarketplace === 'aws' ? 'AWS' : 'GCP'}}
                      Marketplace billing (applies to initial sign in only).
                    </p>
                  </div>
                </div>
                <div *ngIf="tackleMarketplace" class="sign_up_faq">
                  If you have questions, visit our
                  <a href="https://clickhouse.com/docs/en/faq/marketplace"
                     target="_blank"> {{tackleMarketplace === 'aws' ? 'AWS' : 'GCP'}} Marketplace FAQ</a>.
                </div>
                <div class="check-list-container" *ngIf="!tackleMarketplace">
                  <div class="check-title">
                    Your FREE 30 day trial includes
                  </div>
                  <div class="check-list">
                    <mat-icon svgIcon="check_icon" class=""></mat-icon>
                    $300 credits
                  </div>
                  <div class="check-list">
                    <mat-icon svgIcon="check_icon" class=""></mat-icon>
                    Fully managed service on AWS or GCP
                  </div>
                  <div class="check-list">
                    <mat-icon svgIcon="check_icon" class=""></mat-icon>
                    Exclusive SQL console
                  </div>
                  <div class="check-list">
                    <mat-icon svgIcon="check_icon" class=""></mat-icon>
                    SOC 2 Type II Certification
                  </div>
                </div>
              </div>
              <div class="sign_up_right_container">
                <form (ngSubmit)="onSubmit()" [formGroup]="signUpForm" class="form">
                  <div class="auth_intro">
                    <div class="auth_title" data-cy="auth-title">{{authTitle}}</div>
                  </div>
                  <div class="sign_up_sign_in_link_wrapper">
                    Already have an account? <a
                    galaxyClick="auth.register.loginLinkSelect"
                    [queryParams]="queryParams"
                    routerLink="/signIn">
                    Sign in
                  </a>
                  </div>

                  <div *ngIf="!tackleMarketplace" class="social_buttons">
                    <button
                      galaxyClick="auth.register.googleAuthSelect"
                      class="button social_button google_button"
                      (click)="initiateGoogleSignIn()"
                      mat-flat-button
                      type="button"
                      data-cy="sign-up-with-google-button"
                    >
                      <mat-icon class="icon" svgIcon="google_logo"></mat-icon>
                      Sign up with Google
                    </button>

                    <div class="social_divider">
                      <div class="divider"></div>
                      <div class="or">OR</div>
                    </div>
                  </div>

                  <div class="seed_form_element" [attr.data-error]="name?.invalid && name?.touched">
                    <label class="seed_label">Name</label>
                    <input
                      spellcheck="false"
                      galaxyClick="auth.register.nameInputFocus"
                      galaxyBlur="auth.register.nameInputBlur"
                      autocomplete="name"
                      class="seed_input"
                      data-cy="name-input"
                      formControlName="name"
                      maxlength="50"
                      name="name"
                      type="text">
                    <div *ngIf="name?.invalid && name?.touched" class="seed_error">
                      <div *ngIf="name?.errors?.['nameInvalid']">
                        Please enter your full name
                      </div>
                      <div *ngIf="name?.errors?.['nameTooLong']">
                        First/Last Name must be less than 40 characters long
                      </div>
                    </div>
                  </div>

                  <div class="seed_form_element"
                       [attr.data-error]="!signUpForm.controls['email'].valid && signUpForm.controls['email'].touched">
                    <label class="seed_label">Email</label>
                    <input
                      galaxyClick="auth.register.emailInputFocus"
                      galaxyBlur="auth.register.emailInputBlur"
                      autocomplete="username"
                      class="seed_input"
                      data-cy="email-input"
                      formControlName="email"
                      name="email"
                      type="email">
                    <div *ngIf="!signUpForm.controls['email'].valid && signUpForm.controls['email'].touched"
                         class="seed_error">
                      Invalid e-mail address
                    </div>
                  </div>

                  <div class="seed_form_element"
                       [attr.data-error]="!signUpForm.controls['password'].valid && signUpForm.controls['password'].touched">
                    <label class="seed_label">Password</label>
                    <cp-auth-password-requirements
                      *ngIf="showPasswordRequirements"
                      [password]="signUpForm.controls['password'].value">
                    </cp-auth-password-requirements>
                    <input #passwordInput
                           galaxyClick="auth.register.passwordInputFocus"
                           galaxyBlur="auth.register.passwordInputBlur"
                           (blur)="showPasswordRequirements = false"
                           (focus)="showPasswordRequirements = true"
                           [type]="hidePassword ? 'password' : 'text'"
                           autocomplete="new-password"
                           class="seed_input"
                           data-cy="password-input"
                           formControlName="password"
                           name="password"/>
                    <button
                      [galaxyClick]="hidePassword ? 'auth.register.passwordReveal' : 'auth.register.passwordHide'"
                      (click)="$event.preventDefault(); hidePassword = !hidePassword; passwordInput.focus()"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hidePassword"
                      class="seed_input_icon" mat-icon-button matSuffix>
                      <mat-icon [svgIcon]="hidePassword ? 'show_password_icon' : 'hide_password_icon'"
                                class="password_reveal_icon"></mat-icon>
                    </button>
                    <div
                      *ngIf="!signUpForm.controls['password'].valid && signUpForm.controls['password'].touched"
                      class="seed_error">
                      Password does not meet all <a (click)="passwordInput.focus()" class="error_link">requirements</a>
                    </div>
                  </div>

                  <cw-system-message
                    *ngIf="uiState.errorMessage"
                    [attr.data-cy-server-message]="uiState.serverErrorMessage || null"
                    [message]="uiState.errorMessage"
                    data-cy="system-message"></cw-system-message>

                  <cp-sign-up-checkbox [formGroup]="signUpForm"></cp-sign-up-checkbox>

                  <div class="form-element">
                    <button
                      galaxyClick="auth.register.registerButtonSubmit"
                      [disabled]="isSubmitDisabled || uiState.signUpButtonDisabled"
                      class="button clickui-primary-button"
                      data-cy="sign-up-button"
                      mat-flat-button
                      type="submit">
                      <div class="relative">
                        <mat-icon *ngIf="uiState.signUpButtonDisabled"
                                  class="button_loader_icon_absolute icon svg_stroke"
                                  svgIcon="loader"></mat-icon>
                        Sign Up
                      </div>
                    </button>
                  </div>
                </form>

                <div class="recaptcha-error-container"></div>
                <div *ngIf="!tackleMarketplace" class="bottom_links marketplace-btn-container">
                  <div class="">Sign up through the Marketplace to unify billing</div>
                  <div class="button-container">
                    <a [href]="awsMarketplaceProdPageUrl">
                      <button class="button clickui-secondary-button" mat-flat-button>
                        <img src="/assets/images/common/logo_aws_marketplace.svg" height="21"/>
                      </button>
                    </a>
                    <a href="https://console.cloud.google.com/marketplace/product/clickhouse-public/clickhouse-cloud">
                      <button class="button clickui-secondary-button" mat-flat-button>
                        <img src="/assets/images/common/google_cloud_logo.svg" height="17"/>
                      </button>
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="uiState.signUpComplete">
            <cp-verification-email-message
              [userId]="userId"
              [email]="signUpForm.controls['email'].value"></cp-verification-email-message>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>
