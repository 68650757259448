import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

/** CSS class of the button. See buttons.scss. */
export type ConfirmationDialogButtonStyle = 'btn_normal' | 'btn_danger_light' | 'btn_danger';

/** Layout of the buttons. */
export type ConfirmationDialogButtonLayout =
  /** All buttons are placed in the same single row, stacked horizontally. */
  | 'HORIZONTAL'
  /** Every button is placed into a separate row, stacked vertically. */
  | 'VERTICAL';

export interface ConfirmationDialogData {
  title: string;
  text: string;
  action: string;
  /** Default value: 'btn_normal'. */
  buttonStyle?: ConfirmationDialogButtonStyle;
  /** Default value: 'HORIZONTAL'. */
  buttonLayout?: ConfirmationDialogButtonLayout;
}

@Component({
  selector: 'cp-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
  title: string;
  text: string;
  action: string;
  buttonStyle: ConfirmationDialogButtonStyle;
  buttonLayout: ConfirmationDialogButtonLayout;

  constructor(
    public readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: ConfirmationDialogData
  ) {
    this.title = data.title;
    this.text = data.text;
    this.action = data.action;
    this.buttonStyle = data.buttonStyle ?? 'btn_normal';
    this.buttonLayout = data.buttonLayout ?? 'HORIZONTAL';
  }

  static show(dialog: MatDialog, data: ConfirmationDialogData): MatDialogRef<ConfirmationDialogComponent> {
    return dialog.open(ConfirmationDialogComponent, {
      maxWidth: '517px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data
    });
  }
}
