<div class="org_activity side_padding">
  <div class="inner_page_nav">
    <div class="inner_page_title" data-cy="page-title">Learn</div>
  </div>

  <div class="content_wrapper">
    <div class="video_panel">
      <div class="video_details">
        <h5 class="learn_section_header">Watch our video introduction to getting up and running with ClickHouse
          Cloud.</h5>
        <p>This three minute video shows how to load data into your service, run your first queries, and understand your
          metrics.</p>
      </div>
      <div class="video_embed">
        <iframe src="https://player.vimeo.com/video/751409158?h=54cfbce3b9" width="360" height="202" frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"></iframe>
      </div>
    </div>

    <h4 class="learn_section_header">ClickHouse 101</h4>

    <div class="docs_column">
      <ng-container *ngFor="let link of links; trackBy: trackByTitle">
        <a class="docs_panel" [href]="link.url" target="_blank">
          <div class="docs_icon">
            <mat-icon class="icon" svgIcon="{{link.icon}}"></mat-icon>
          </div>
          <div class="docs_details">
            <div class="docs_title">{{link.title}}</div>
            <div class="paragraph_small">{{link.description}} <span>Read more</span></div>
          </div>
        </a>
      </ng-container>
    </div>
    <h4 class="learn_section_header">
      ClickHouse Academy
      <a href='https://learn.clickhouse.com/visitor_class_catalog' target="_blank">
        View All
        <mat-icon>arrow_forward</mat-icon>
      </a>
    </h4>

    <div class="grid_three">
      <ng-container *ngFor="let link of academyLinks; trackBy: trackByTitle">
        <a class="learn_panel" [href]="link.url" target="_blank">
          <div class="learn_img">
            <mat-icon>videocam</mat-icon>
            <img class="thumbnail" [src]="link.imgUrl" [alt]="link.title + ' logo'">
          </div>
          <div class="learn_details">
            <div class="paragraph_bold_regular">{{link.title}}</div>
            <div class="paragraph_normal_small">{{link.time}} • {{link.lessonCount}} courses</div>
          </div>
        </a>
      </ng-container>
    </div>
    <ng-container *ngIf="upcomingEvents$ | async as events">
      <h4 class="learn_section_header">
        Upcoming Workshops
        <a href="https://clickhouse.com/company/news-events" target="_blank">
          View All
          <mat-icon>arrow_forward</mat-icon>
        </a>
      </h4>

      <div class="grid_three">
        <ng-container *ngFor="let event of events; trackBy: trackByTitle">
          <div class="events_panel">
            <div class="paragraph_bold_regular">{{event.title}}</div>
            <div class="events_description">
              <div class="workshop_desc paragraph_normal_small">
                <span *ngIf="event.duration">{{event.duration}} • </span>
                {{event.date.toDateString()}}
              </div>
              <div class="workshop_timezones paragraph_normal_small">
                {{getTimeByTimezone(event.date, 'US/Pacific')}} PST (San Francisco)
                <br/>
                {{getTimeByTimezone(event.date, 'Europe/Amsterdam')}} CET (Amsterdam)
                <br/>
                {{getTimeByTimezone(event.date)}}
              </div>
            </div>
            <a href="{{'https://clickhouse.com/events/' + event.slug}}" target="_blank">
              <button
                class="register_event_button"
                data-cy="register_event_button"
                mat-stroked-button>
                Register Now
              </button>
            </a>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <h4 class="learn_section_header">Featured documents</h4>

    <div class="docs_column">
      <ng-container *ngFor="let feature of featured; trackBy: trackByTitle">
        <a class="docs_panel" [href]="feature.url" target="_blank">
          <div class="docs_icon">
            <mat-icon class="feature_icon" svgIcon="{{feature.icon}}"></mat-icon>
          </div>
          <div class="docs_details">
            <div class="docs_title">{{feature.title}}</div>
            <div class="paragraph_small">{{feature.description}} <span>Read more</span></div>
          </div>
        </a>
      </ng-container>
    </div>

    <p class="docs_link_text">Visit our <a href="https://clickhouse.com/docs/en/home/" target="_blank">docs</a> for even
      more guidance on working with ClickHouse and Clickhouse Cloud.</p>
  </div>
</div>
