<div *ngIf="!isDismissed()" [ngClass]="messageType" class="system_message" data-cy="system-message">
  <div *ngIf="svgIcon && showIcon" class="icon_wrapper">
    <mat-icon [svgIcon]="svgIcon" class="icon"></mat-icon>
  </div>
  <div class="messageWrapper">
    <div [innerHTML]="message" class="message" data-cy="system-message-text"></div>
    <div class="message" data-cy="system-message-text">
      <ng-content></ng-content>
    </div>
  </div>

  <button (click)="dismissMessage()" *ngIf="isDismissible()" class="dismiss_button" mat-icon-button>
    <mat-icon class="icon" svgIcon="close_icon"></mat-icon>
  </button>
</div>
