import { COGNITO_MODE_QUERY_PARAM } from '@cp/common/utils/HttpUtils';
import { environment } from '@cp/web/environments/environment';

export const APP_INIT_URL_PARAMS = new URLSearchParams(window.location.search);

export const AUTH0_ENABLED_ENVIRONMENTS = ['local', 'dev', 'staging', 'production'];

/**
 * isAuth0Mode is enabled only if there is an 'auth0' search param (like https://url.com?auth0)
 * and it is not a production environment (to avoid security issues).
 */
export const isAuth0Mode =
  // All environments use Auth0 by default. To switch to Cognito use "?cognito".
  (AUTH0_ENABLED_ENVIRONMENTS.includes(environment.stage) && !APP_INIT_URL_PARAMS.has(COGNITO_MODE_QUERY_PARAM)) ||
  // Cypress tests in Auth0 mode.
  window.Cypress?.env()['AUTH0_MODE'];
