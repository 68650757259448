import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isFutureOpenapiKeyExpirationDate, OpenapiKey } from '@cp/common/protocol/OpenapiKey';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { UpdateOpenapiKeyOptions } from '@cp/web/app/organizations/api-keys/api-keys-api.service';
import { ApiKeysService } from '@cp/web/app/organizations/api-keys/api-keys-service';
import { ApiKeyFormData } from '@cp/web/app/organizations/api-keys/edit-api-key-form/edit-api-key-form.component';

export interface EditApiKeyDialogComponentData {
  key: OpenapiKey;
  /** Role of the current user in the organization. */
  role: OrganizationRole;
}

@Component({
  selector: 'cp-edit-api-key-dialog',
  templateUrl: './edit-api-key-dialog.component.html',
  styleUrls: ['./edit-api-key-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditApiKeyDialogComponent {
  public readonly form: FormGroup = new FormGroup({});

  isUpdateKeyNetworkCallInFlight = false;

  constructor(
    readonly dialogRef: MatDialogRef<EditApiKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: EditApiKeyDialogComponentData,
    private readonly apiKeyService: ApiKeysService
  ) {}

  async updateApiKey(formData: ApiKeyFormData): Promise<void> {
    // Do not include expiration date for expired keys into the update.
    // Reason: CP-API does not accept dates in the past.
    const includeExpirationKey = isFutureOpenapiKeyExpirationDate(formData.expirationDate);
    try {
      this.isUpdateKeyNetworkCallInFlight = true;
      const update: UpdateOpenapiKeyOptions = {
        keyId: this.data.key.id,
        name: formData.name,
        expirationDate: includeExpirationKey ? formData.expirationDate : undefined,
        roles: [formData.role]
      };
      await this.apiKeyService.updateOrganizationApiKey(update);
    } finally {
      this.isUpdateKeyNetworkCallInFlight = false;
      this.dialogRef.close();
    }
  }

  static show(dialog: MatDialog, data: EditApiKeyDialogComponentData): void {
    dialog.open(EditApiKeyDialogComponent, {
      width: '100%',
      maxWidth: '542px',
      restoreFocus: false,
      panelClass: 'modal',
      data
    });
  }
}
