import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cp-ip-access-list-info-icon',
  templateUrl: './ip-access-list-info-icon.component.html',
  styleUrls: ['./ip-access-list-info-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class IpAccessListInfoIconComponent {}
