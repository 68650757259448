import {
  MILLIS_PER_DAY,
  MILLIS_PER_HOUR,
  MILLIS_PER_MINUTE,
  MILLIS_PER_SECOND,
  MILLIS_PER_WEEK
} from '@cp/common/utils/DateTimeUtils';
import { WithBatch } from '@cp/common/utils/ProtocolUtils';
import { ErrorResponseMixin, isString } from '@cp/common/utils/ValidationUtils';

/***
 * Metrics model.
 * See https://docs.google.com/document/d/1djhIETDxTAlqLGRxYbh-yf8SXtSkC0bZDcAaRT3kIsU/edit
 */
export interface InstanceMetricRequest<QueryArgs extends CommonMetricArgs = CommonMetricArgs>
  extends WithBatch<QueryArgs> {
  organizationId: string;
  instanceId: string;
  /**
   * A flag to bypass metrics cache. Can only be used by @clickhouse.com users
   * or by users with FT_USER_BYPASS_METRICS_CACHE flag.
   */
  bypassCache?: boolean;
}

/** Metric response contains individual reports per each metric or an error message (captured event id in Sentry). */
export type InstanceMetricResponse = Partial<ErrorResponseMixin> & WithBatch<MetricReport | string>;

/**
 * The time period for the metrics report.
 * - LAST_15_MINUTES: last 15 minutes.
 * - LAST_HOUR: last 60 minutes.
 * - LAST_DAY: last 24 hours.
 * - LAST_WEEK: last 7 days.
 * - LAST_MONTH: last 30 days.
 * - LAST_YEAR: last 52 weeks.
 */
export const METRICS_TIME_PERIODS = [
  'LAST_15_MINUTES',
  'LAST_HOUR',
  'LAST_DAY',
  'LAST_WEEK',
  'LAST_MONTH',
  'LAST_YEAR'
] as const;
export type TimePeriod = (typeof METRICS_TIME_PERIODS)[number];

/** Set of supported metric report types. */
export type MetricType =
  /**
   * Memory used by ClickHouse cgroup.
   * See 'allocated_memory' DP metric in DataPlaneMetricsApi.ts.
   */
  | 'ALLOCATED_MEMORY'

  /** 'Insert' query count per period. */
  | 'INSERT_QPS'

  /** Read bytes per period. */
  | 'READ_THROUGHPUT'

  /** Real memory used by ClickHouse ( the same as top RES/RSS). */
  | 'RESIDENT_MEMORY_USAGE'

  /** Number of rows read/written per reported period. */
  | 'ROW_COUNT'

  /** Database storage usage. */
  | 'S3_STORAGE_USAGE'

  /** 'Select' query count per period. */
  | 'SELECT_QPS'

  /** Count of INSERT/SELECT statements executed per period. */
  | 'SQL_STATEMENTS_PER_TYPE'

  /** Count of successful/failed queries per period. */
  | 'SUCCESSFUL_QUERIES'

  /** A report used for billing only. Not available via public metric query api. */
  | 'USAGE_BILLING'

  /** Write bytes per period. */
  | 'WRITE_THROUGHPUT';

/**
 * Supported types of metrics queries.
 * Using 'Args' instead of 'Query' suffix to differentiate from 'Query' in the metrics name (like QueryLatency).
 */
export type MetricArgs =
  | AllocatedMemoryMetricArgs
  | InsertQpsMetricArgs
  | ResidentMemoryUsageMetricArgs
  | ReadThroughputMetricArgs
  | RowCountMetricArgs
  | S3StorageUsageMetricArgs
  | SelectQpsMetricArgs
  | SqlStatementsPerTypeMetricArgs
  | SuccessfulQueriesMetricArgs
  | WriteThroughputMetricArgs;

/** Supported types of metrics query results. */
export type MetricReport =
  | AllocatedMemoryMetricReport
  | InsertQpsMetricReport
  | ResidentMemoryUsageMetricReport
  | ReadThroughputMetricReport
  | RowCountMetricReport
  | S3StorageUsageMetricReport
  | SelectQpsMetricReport
  | SqlStatementsPerTypeMetricReport
  | SuccessfulQueriesMetricReport
  | WriteThroughputMetricReport;

/** Common part of all metric args. */
export interface CommonMetricArgs<T extends MetricType = MetricType> {
  /** Type of the metrics report. */
  type: T;
  /** Requested time period of the report. */
  timePeriod: TimePeriod;
  /** End date millis for the time period. If not Date.now() is used. */
  endDate?: number;
}

/**
 * A single point in the metrics chart.
 * The first element is a timestamp (in milliseconds), the second element is a point value.
 */
export type ChartDataPoint = [number, number];
export type ChartDataSeries = Array<ChartDataPoint>;

/** Common properties of all metrics reports. */
export interface CommonMetricReport<T extends MetricType = MetricType> {
  /** Type of the report. Same as request.type. */
  type: T;

  /** Reported time period. */
  timePeriod: TimePeriod;

  /**
   * Charts in the report.
   * May contain multiple data series.
   * See each report for details if the metrics report contains multiple series.
   */
  data: Array<Array<ChartDataPoint>>;

  /**
   * Execution details.
   * Can be a free format string that contains details meaningful to developers.
   * Used to provide developer-level debug info about the request execution on the client side.
   * Example of a flag: METRIC_REPORT_FLAG_CACHED if the report is from the cache.
   * Not parsed by the client side API (should be reworked to a stricter typed framework if used by API).
   */
  flags?: string[];
}

export const METRIC_REPORT_FLAG_CACHED = 'cached';

/**
 * The amount of data read from ClickHouse to clients in bytes.
 * https://github.com/ClickHouse/ClickHouse/blob/master/src/Common/ProfileEvents.cpp#L129
 *  ClickHouseProfileEvents_SelectedBytes.
 */
export type ReadThroughputMetricArgs = CommonMetricArgs<'READ_THROUGHPUT'>;

/** Response for the 'READ_THROUGHPUT' report. */
export interface ReadThroughputMetricReport extends CommonMetricReport<'READ_THROUGHPUT'> {
  summary: ReadThroughputMetricReportSummary;
}

export interface ReadThroughputMetricReportSummary {
  /** Total size in bytes read from CH during the reported period. */
  totalBytesRead: number;
  /** Average read size per second during the reported period. */
  bytesReadPerSecond: number;
}

/** Count of inserted and selected rows per requested period. */
export type RowCountMetricArgs = CommonMetricArgs<'ROW_COUNT'>;

/**
 * Response for the 'ROW_COUNT' report.
 * Contains 2 charts for count of selected (index=0) & inserted (index=1) rows per aggregated period.
 */
export interface RowCountMetricReport extends CommonMetricReport<'ROW_COUNT'> {
  summary: RowCountMetricReportSummary;
}

export interface RowCountMetricReportSummary {
  /** Total rows inserted per requested period. */
  insertedRows: number;
  /** Total rows selected per requested period. */
  selectedRows: number;
}

/** Count of queries (SELECT statements) that were executed every second. */
export type SelectQpsMetricArgs = CommonMetricArgs<'SELECT_QPS'>;

/** Response for the 'SELECT_QPS' report. */
export interface SelectQpsMetricReport extends CommonMetricReport<'SELECT_QPS'> {
  summary: SelectQpsMetricReportSummary;
}

/**
 * Usage report for a service.
 * This report will be sent to M3ter for billing purposes.
 * Does not extend 'CommonMetricReport' because the report has no charts and is never sent to users via public API.
 */
export interface UsageBillingReport {
  /**
   * Max amount of bytes (compressed) used by ClickHouse database production instance in the queried period multiplied by the amount of minutes in the queried period.
   * (Max amount of database bytes)*(minutes in the query period)
   *
   * Based on DataPlane disk_storage metric.
   * Populated when the instanceTier === 'Production'
   */
  storageByteMinutesTables: number;
  /**
   * Max amount of bytes (compressed) used by ClickHouse database development instance in the queried period multiplied by the amount of minutes in the queried period.
   * (Max amount of database bytes)*(minutes in the query period)
   *
   * Based on DataPlane disk_storage metric.
   * Populated when the instanceTier === 'Development'
   */
  devStorageByteMinutesTables: number;
  /**
   * Max amount of bytes (compressed) used by ClickHouse database backups in the queried period multiplied by the amount of minutes in the queried period.
   * (Max amount of backups bytes)*(minutes in the query period)
   *
   * Not yet implemented by DataPlane Metrics.
   */
  storageByteMinutesBackups: number;
  /**
   * Count of write operations performed on persistent storage in the queried/reported period.
   *
   * Based on DataPlane s3_write_requests metric.
   */
  storageWriteCount: number;
  /**
   * Count of read operations performed on persistent storage in the queried/reported period.
   *
   * Based on DataPlane s3_read_requests metric.
   */
  storageReadCount: number;
  /**
   * Average amount of RAM GB allocated to the ClickHouse database production instance in the queried period multiplied by the amount of minutes in the queried period.
   * (Average amount of RAM GB allocated)*(minutes in the query period)
   *
   * Based on DataPlane compute_memory_requests metric.
   * Populated when the instanceTier === 'Production'
   */
  computeUnitGBMinutes: number;
  /**
   * Average amount of RAM GB allocated to the ClickHouse database dev instance in the queried period multiplied by the amount of minutes in the queried period.
   * (Average amount of RAM GB allocated)*(minutes in the query period)
   *
   * Based on DataPlane compute_memory_requests metric.
   * Populated when the instanceTier === 'Development'
   */
  devComputeUnitGBMinutes: number;
}

export interface SelectQpsMetricReportSummary {
  /** Count of total SELECT ops during the whole period reported. */
  totalSelects: number;
  /** Average QPS of SELECT ops in the reported interval. */
  selectQueriesPerSecond: number;
}

/**
 * The total amount of storage used by the service in bytes.
 * Note: the aggregation fn is AVG since we cannot sum the data (it will explode) and the right fn over time is avg.
 * Note: Could use either “container_fs_usage_bytes” or “kubelet_volume_stats_used_bytes”.
 */
export type S3StorageUsageMetricArgs = CommonMetricArgs<'S3_STORAGE_USAGE'>;

/** Response for the 'S3_STORAGE_USAGE' report. */
export interface S3StorageUsageMetricReport extends CommonMetricReport<'S3_STORAGE_USAGE'> {
  /** Summary. All values are at the last time moment of the report period. */
  summary: S3StorageUsageMetricReportSummary;
}

export interface S3StorageUsageMetricReportSummary {
  /** Total data stored in CH in bytes. */
  currentUsageInBytes: number;
  /** How much usage was changed since during the reported period. */
  deltaUsageInBytes: number;
}

export type AllocatedMemoryMetricArgs = CommonMetricArgs<'ALLOCATED_MEMORY'>;

export interface AllocatedMemoryMetricReport extends CommonMetricReport<'ALLOCATED_MEMORY'> {
  summary: AllocatedMemoryMetricReportSummary;
}

export interface AllocatedMemoryMetricReportSummary {
  currentMemoryInBytes: number;
  deltaMemoryInBytes: number;
}

/** Max amount of RAM used during the requested period.  */
export type ResidentMemoryUsageMetricArgs = CommonMetricArgs<'RESIDENT_MEMORY_USAGE'>;

/** Response for the 'RESIDENT_MEMORY_USAGE' report. */
export interface ResidentMemoryUsageMetricReport extends CommonMetricReport<'RESIDENT_MEMORY_USAGE'> {
  /** Summary. All values are at the last time moment of the report period. */
  summary: ResidentMemoryUsageMetricReportSummary;
}

export interface ResidentMemoryUsageMetricReportSummary {
  /** Total memory usage in bytes. */
  currentUsageInBytes: number;
  /** How much usage was changed since during the reported period. */
  deltaUsageInBytes: number;
}

/**
 * Count of operations per "type": SELECT, INSERT, UPDATE, DELETE.
 * Note: could be fetched from `system.query_log` but needs filtering and aggregating
 *  (two lines per query, “QueryFinished” contains total run-time)
 */
export type SqlStatementsPerTypeMetricArgs = CommonMetricArgs<'SQL_STATEMENTS_PER_TYPE'>;

/**
 * Response for the 'SQL_STATEMENTS_PER_TYPE' report.
 * Indexes in the data series array: 0:SELECT, 1:INSERT.
 */
export interface SqlStatementsPerTypeMetricReport extends CommonMetricReport<'SQL_STATEMENTS_PER_TYPE'> {
  summary: SqlStatementsPerTypeMetricReportSummary;
}

export interface SqlStatementsPerTypeMetricReportSummary {
  insertsPercentPerPeriod: number;
  selectsPercentPerPeriod: number;
}

/**
 * The number of queries that succeeded or failed.
 */
export type SuccessfulQueriesMetricArgs = CommonMetricArgs<'SUCCESSFUL_QUERIES'>;

/**
 * Response for the 'SUCCESSFUL_QUERIES' report.
 * The report contains 2 data series: successful & failed queries count.
 */
export interface SuccessfulQueriesMetricReport extends CommonMetricReport<'SUCCESSFUL_QUERIES'> {
  summary: SuccessfulQueriesMetricReportSummary;
}

export interface SuccessfulQueriesMetricReportSummary {
  /** Percent of successful queries during the period. */
  successRatePercent: number;
  /** Percent of failed queries during the period. */
  failRatePercent: number;
}

/** The amount of data written to ClickHouse from clients in bytes. */
export type WriteThroughputMetricArgs = CommonMetricArgs<'WRITE_THROUGHPUT'>;

/** Response for the 'WRITE_THROUGHPUT' report. */
export interface WriteThroughputMetricReport extends CommonMetricReport<'WRITE_THROUGHPUT'> {
  summary: WriteThroughputMetricReportSummary;
}

export interface WriteThroughputMetricReportSummary {
  /** Total write size per period. */
  totalBytesWritten: number;
  /** Average write size per second in bytes during the given period. */
  bytesWrittenPerSecond: number;
}

/**
 * Count of queries (UPDATE/INSERT statements) that were executed every minute.
 * A query may start before the minute and may finish after the minute.
 */
export type InsertQpsMetricArgs = CommonMetricArgs<'INSERT_QPS'>;

/** Response for the 'INSERT_QPS' report. */
export interface InsertQpsMetricReport extends CommonMetricReport<'INSERT_QPS'> {
  summary: InsertQpsMetricReportSummary;
}

export interface InsertQpsMetricReportSummary {
  /** Total INSERT operations count during the reported period. */
  totalInserts: number;
  /** Average INSERT operations per second during the reported period. */
  insertQueriesPerSecond: number;
}

/** Metrics feedback sent to ClickHouse by users. */
export interface SendMetricsFeedbackRequest {
  text: string;
  recaptchaToken: string;
}

/**
 * Returns aggregation period for the given time period.
 * For all sampling points within an aggregation period there is only 1 point in the result chart series.
 */
export function getAggregationPeriodMillis(timePeriod: TimePeriod): number {
  // Current values for each time period are copied from the default Grafana values for Prometheus dashboard.
  let stepInMillis: number | undefined;
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      stepInMillis = MILLIS_PER_MINUTE;
      break;
    case 'LAST_HOUR':
      // Grafana value: stepInMillis = 20 * MILLIS_PER_SECOND;
      stepInMillis = MILLIS_PER_MINUTE;
      break;
    case 'LAST_DAY':
      stepInMillis = 5 * MILLIS_PER_MINUTE;
      break;
    case 'LAST_WEEK':
      stepInMillis = MILLIS_PER_HOUR;
      break;
    case 'LAST_MONTH':
      stepInMillis = 3 * MILLIS_PER_HOUR;
      break;
    case 'LAST_YEAR':
      stepInMillis = MILLIS_PER_DAY;
      break;
  }
  if (!stepInMillis) {
    throw new Error(`Unsupported time period: ${timePeriod}`);
  }
  return stepInMillis;
}

/**
 * Returns aggregation period (in seconds) for the given time period.
 * Ideally, we would just extend getAggregationPeriodMillis to include a 'unit' param, but I don't want to break anything - Zach
 */
export function getAggregationPeriodSeconds(timePeriod: TimePeriod): number {
  return getAggregationPeriodMillis(timePeriod) / 1000;
}

/** Returns number of seconds in the given time period. */
export function getSecondsInTimePeriod(timePeriod: TimePeriod): number {
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      return (15 * MILLIS_PER_MINUTE) / MILLIS_PER_SECOND;
    case 'LAST_HOUR':
      return MILLIS_PER_HOUR / MILLIS_PER_SECOND;
    case 'LAST_DAY':
      return MILLIS_PER_DAY / MILLIS_PER_SECOND;
    case 'LAST_WEEK':
      return MILLIS_PER_WEEK / MILLIS_PER_SECOND;
    case 'LAST_MONTH':
      return (30 * MILLIS_PER_DAY) / MILLIS_PER_SECOND;
    case 'LAST_YEAR':
      return (52 * MILLIS_PER_WEEK) / MILLIS_PER_SECOND;
  }
  throw new Error(`Invalid time period: ${timePeriod}`);
}

/** Returns number of minutes in the given time period. */
export function getMinutesInTimePeriod(timePeriod: TimePeriod): number {
  return getSecondsInTimePeriod(timePeriod) / (MILLIS_PER_MINUTE / MILLIS_PER_SECOND);
}

export function isCommonMetricArg(value: unknown): value is CommonMetricArgs {
  if (typeof value !== 'object') return false;
  const arg = value as CommonMetricArgs;
  return isString(arg.type) && isString(arg.timePeriod) && (arg.timePeriod === undefined || isString(arg.timePeriod));
}
