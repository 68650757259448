import { assertTruthy } from '@cp/common/utils/Assert';

interface ExpirableLocalStorageValueContainer<T = unknown> {
  value: T;
  expireAt: number;
}

/**
 * Stores a value in the browser's local storage that can be looked up with 'getExpirableValueFromLocalStorage' during
 * 'expirationPeriodMillis' and won't be available after this time.
 */
export function storeExpirableValueInLocalStorage<T = unknown>(
  key: string,
  value: T,
  expirationPeriodMillis: number
): void {
  assertTruthy(expirationPeriodMillis > 0, `bad expirationPeriodMillis: ${expirationPeriodMillis}`);
  const item: ExpirableLocalStorageValueContainer<T> = {
    value: value,
    expireAt: Date.now() + expirationPeriodMillis
  };
  localStorage.setItem(key, JSON.stringify(item));
}

/** Picks a non expired value from the local storage. */
export function getExpirableValueFromLocalStorage<T = unknown>(key: string): T | undefined {
  try {
    const serializedItem = localStorage.getItem(key);
    if (!serializedItem) {
      return undefined;
    }
    const item = JSON.parse(serializedItem) as ExpirableLocalStorageValueContainer<T>;
    if (item.expireAt <= Date.now()) {
      localStorage.removeItem(key);
      return undefined;
    }
    return item.value;
  } catch (error) {
    console.error(`Failed to parse expirable value ${key}`, error);
    return undefined;
  }
}

/** Removes all keys from the localstorage that starts with the given prefix. */
export function removeLocalStorageKeysByKeyPrefix(keyPrefix: string): void {
  const lsKeysToCleanup: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i) || '';
    if (key.startsWith(keyPrefix)) {
      lsKeysToCleanup.push(key);
    }
  }
  for (const key of lsKeysToCleanup) {
    localStorage.removeItem(key);
  }
}
