import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationFeatureId, UserFeatureId } from '@cp/common/protocol/features';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'userFeatureEnabled'
})
export class UserFeatureEnabledPipe implements PipeTransform {
  constructor(private readonly features: FeaturesService) {}

  transform(featureId: UserFeatureId | undefined): Observable<boolean> {
    return featureId ? this.features.observeUserFeature(featureId) : of(false);
  }
}

@Pipe({
  name: 'organizationFeatureEnabled'
})
export class OrganizationFeatureEnabledPipe implements PipeTransform {
  constructor(private readonly features: FeaturesService) {}

  transform(featureId: OrganizationFeatureId | undefined): Observable<boolean> {
    return featureId ? this.features.observeCurrentOrganizationFeature(featureId) : of(false);
  }
}
