<mat-dialog-content class="billing_conversion_dialog" data-cy="change-billing-address-dialog">
  <div class="dialog_header">
    <div class="title">Billing information</div>
    <div class="organization_type_buttons_block">
      <div class="organization_type_buttons_row" *ngIf="dialogStage === 'CREDIT_CARD'">
        <div *ngIf="uiState.companyDetailsFormInput?.organizationIsABusiness" class="sub_title">Business</div>
        <div *ngIf="!uiState.companyDetailsFormInput?.organizationIsABusiness" class="sub_title">Individual</div>
      </div>
    </div>
   </div>

  <div class="progress_container_wrapper padded_content">
    <div class="progress_container">
      <div [ngClass]="dialogStage === 'ADDRESS' ? 'active': ''" class="progress_dot"></div>
      <div [ngClass]="dialogStage === 'COMPANY' ? 'active': ''" class="progress_dot"></div>
      <div [ngClass]="dialogStage === 'CREDIT_CARD' ? 'active': ''" class="progress_dot"></div>
    </div>
  </div>

  <ng-container [ngSwitch]="dialogStage">
    <cp-change-billing-address *ngSwitchCase="'ADDRESS'" [formInput]="uiState"
                               eventPrefix="billing.modal"
                               (addressChange)="onAddressChange($event)"></cp-change-billing-address>

    <cp-billing-company-details
      *ngSwitchCase="'COMPANY'"
      eventPrefix="billing.modal"
      [formInput]="uiState"
      (companyDetailsChange)="onCompanyDetailsChange($event)"
      (backButtonClick)="onCompanyDetailsBack($event)"></cp-billing-company-details>

    <cp-add-credit-card *ngSwitchCase="'CREDIT_CARD'" (backButtonClick)="onCreditCardBack()"
                        (creditCardSaved)="onCreditCardSaved()"></cp-add-credit-card>
  </ng-container>

</mat-dialog-content>
