import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'cp-iframe-with-content',
  templateUrl: './iframe-with-content.component.html',
  styleUrls: ['./iframe-with-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeWithContentComponent {
  readonly url: SafeUrl;

  constructor(
    public dialogRef: MatDialogRef<IframeWithContentComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { url: string },
    private sanitizer: DomSanitizer
  ) {
    this.url = sanitizer.bypassSecurityTrustResourceUrl(data.url);
  }
}
