<mat-dialog-content class="delete_instance_dialog_container"
                    data-cy="delete_instance_dialog_container">
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title dialogTitle="Delete service?"></cp-dialog-title>
  <div class="text_wrapper">
    <div class="text">Terminating <span class="bold">{{instance.name}}</span> will permanently delete all settings,
      data, and backups associated with this service. This action cannot be undone. To continue, please enter
      the
      name
      of the service.
    </div>
  </div>
  <input [(ngModel)]="deleteInput"
         spellcheck="false"
         (keyup.enter)="isReadyToSubmit() && confirmDeletion()"
         class="seed_input"
         data-cy="delete-instance-confirm-input"
         type="text"
  />
  <div class="buttons">
    <button (click)="confirmDeletion()"
            [disabled]="!isReadyToSubmit()"
            class="btn_danger submit-button"
            data-cy="confirm-delete-button"
            mat-flat-button>
      Delete service
    </button>
    <button (click)="dialogRef.close()"
            class="close_button"
            data-cy="close-button"
            mat-button
            type="button">
      Cancel
    </button>
  </div>
</mat-dialog-content>
