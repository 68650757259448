<mat-dialog-content *ngIf="uiStateObs | async as uiState" class="create_case_dialog">
  <div>
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
    <cp-dialog-title dialogTitle="Create new case"></cp-dialog-title>
  </div>
  <div class="form-container">
    <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="form">
      <div class="form-data-container">
        <div class="seed_form_element">
          <label>Priority</label>
          <seed-select [options]="priorityOptions" [value]="priorityOptions[0].value"
                       formControlName="priority"></seed-select>
        </div>

        <ng-container *ngIf="instanceListObs | async as instanceList">
          <div *ngIf="instanceList.length" class="seed_form_element">
            <label>Cloud service</label>
            <seed-select [options]="instanceList" formControlName="instance"></seed-select>
          </div>
        </ng-container>


        <div class="seed_form_element">
          <label>Subject</label>
          <input formControlName="subject" name="subject" placeholder="Enter a subject for your issue">
          <div *ngIf="subject && subject.invalid && subject.touched" class="seed_error">
            <div *ngIf="subject.errors?.['required']">
              Please enter a subject
            </div>
            <div *ngIf="subject.errors?.['maxlength']">
              The subject exceeds the maximum character limit of 255
            </div>
          </div>
        </div>

        <div class="seed_form_element">
          <label>Description</label>
          <textarea class="textarea"
                    formControlName="description"
                    maxlength="32000"
                    name="description"
                    placeholder="Enter a short description of your issue"
          >
          </textarea>
          <div *ngIf="description && description.invalid && description.touched" class="seed_error">
            <div *ngIf="description.errors?.['required']">
              Please enter a detailed description
            </div>
            <div *ngIf="description.errors?.['maxlength']">
              The description exceeds the maximum character limit of 32,000
            </div>
          </div>
        </div>

        <div class="seed_form_element">
          <label>Attachments</label>
          <cp-upload (uploadFinished)="handleUploadFinished($event)"
                     (uploadStarted)="handleUploadStarted()"
                     [uploadType]="'support'"></cp-upload>
        </div>

        <div class="seed_form_element">
          <label>Notify others</label>
          <div class="notify_others_container">
            <input class="additional_email_input input" formControlName="additionalEmail1" name="additionalEmail1"
                   placeholder="Email address">
            <input class="additional_email_input input" formControlName="additionalEmail2" name="additionalEmail2"
                   placeholder="Email address">
          </div>

        </div>

        <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>
      </div>
      <div class="buttons">
        <button
          [disabled]="formGroup.invalid || uiState.submitButtonDisabled || uiState.fileUploadInProgress"
          class="submit_button button"
          color="primary"
          mat-flat-button
          type="submit">
          Create new case
        </button>
        <button (click)="dialogRef.close()" class="close_button button" mat-button type="button">Cancel
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
