import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  isManuallyTriggeredAction,
  PendingUserAction,
  PendingUserActionType
} from '@cp/common/protocol/PendingUserActions';
import { assertTruthy } from '@cp/common/utils/Assert';
import { checkArraysEqualityWithComparator, checkObjectEqualityByShallowCompare } from '@cp/common/utils/MiscUtils';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { EntryQuestionnaireDialogComponent } from '@cp/web/app/account/entry-questionnaire-dialog/entry-questionnaire-dialog.component';
import * as Sentry from '@sentry/angular';
import { distinctUntilChanged } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MfaUpdateDialogComponent } from '@cp/web/app/account/mfa/mfa-update-dialog/mfa-update-dialog.component';

/** Service responsible to run pending user actions. */
@Injectable({
  providedIn: 'root'
})
export class PendingUserActionsService {
  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly accountService: AccountService,
    private readonly snackbar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {
    // Trivial implementation that processes pending actions by one.
    // Once the action is processed it is removed from the user details, that causes a new event.
    this.accountStateService
      .observeUserDetails()
      .pipe(
        filter((userDetails) => !!userDetails && userDetails.pendingActions.length > 0),
        distinctUntilChanged((p, n) =>
          checkArraysEqualityWithComparator(p?.pendingActions, n?.pendingActions, checkObjectEqualityByShallowCompare)
        )
      )
      .subscribe(async (userDetails) => {
        assertTruthy(userDetails);
        if (!userDetails.pendingActions) {
          return;
        }
        try {
          let actionIndex = 0;
          while (actionIndex < userDetails.pendingActions.length) {
            const action = userDetails.pendingActions[actionIndex];
            if (!isManuallyTriggeredAction(action.type)) {
              console.debug('Pending actions: ', userDetails.pendingActions);
              await this.triggerPendingAction(action);
              // Only clear actions if they are not marked as manually cleared.
              await this.accountService.updateUserDetails({ pendingActionTypesToRemove: [action.type] });
              return;
            }
            actionIndex++;
          }
        } catch (e) {
          Sentry.captureException(e);
          console.error('Failed to process pending user action', e);
        }
      });
  }

  getPendingAction(type: PendingUserActionType): PendingUserAction | undefined {
    const userDetails = this.accountStateService.getUserDetailsOrFail();
    return userDetails.pendingActions.find((action) => action.type === type);
  }

  /**
   * Trigger manually pending action by type name.
   * Return false if action can't be found.
   */
  async triggerPendingActionByType(type: PendingUserActionType): Promise<boolean> {
    const action = this.getPendingAction(type);
    if (!action) {
      return false;
    }
    return await this.triggerPendingAction(action);
  }

  private async triggerPendingAction(action: PendingUserAction): Promise<boolean> {
    try {
      switch (action.type) {
        case 'snackbar-message':
          assertTruthy(action.data, 'snackbar-message pending action must have data field');
          this.snackbar.open(action.data);
          break;
        case 'entry-questionnaire':
          EntryQuestionnaireDialogComponent.show(this.dialog);
          break;
        case 'tackle-subscription':
          await this.router.navigate(['/marketplaceOnboarding']);
          break;
        case 'mfa-update':
          MfaUpdateDialogComponent.show(this.dialog);
          break;
        case 'onboarding':
          await this.router.navigate(['/onboard']);
          break;
      }
      return true;
    } catch (e) {
      Sentry.captureException(e);
      console.error('Failed to process pending user action', e);
      return false;
    }
  }
}
