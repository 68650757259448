import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Instance } from '@cp/common/protocol/Instance';
import { REGION_BY_ID } from '@cp/common/protocol/Region';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { formatClickHouseVersion } from '@cp/common/utils/MiscUtils';
import { getInstancePagePath } from '@cp/web/app/app-routing-utils';
import { InstanceBackupService } from '@cp/web/app/instances/instance-backups/instance-backup.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'cp-upgrade-instance-card',
  templateUrl: './upgrade-instance-card.component.html',
  styleUrls: ['./upgrade-instance-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradeInstanceCardComponent implements OnInit, OnChanges {
  @Input()
  instance!: Instance;

  isLoadingObs = new Subject();
  readonly regionMap = REGION_BY_ID;
  readonly formatCHVersion = formatClickHouseVersion;
  readonly getUpgradeDisplayErrorMessage = this.instanceUiService.getUpgradeDisplayErrorMessage;

  constructor(
    private readonly instanceBackupService: InstanceBackupService,
    private readonly snackBar: MatSnackBar,
    private readonly instanceService: InstanceService,
    private readonly instanceUiService: InstanceUiService
  ) {}

  get detailsPagePath(): string {
    return getInstancePagePath(truthy(this.instance).id);
  }

  ngOnInit(): void {
    this.assertInputs();
  }

  ngOnChanges(): void {
    this.assertInputs();
  }

  private assertInputs(): void {
    assertTruthy(this.instance);
  }

  async onTryAgain() {
    try {
      await this.instanceService.upgradeInstance(this.instance.id);
    } catch (e) {
      this.instanceUiService.showSnackBar(
        `Error: we couldn't update your service ${this.instance.name}. Please try again later or if the error persists contact our support.`
      );
    }
  }

  async onCancel() {
    try {
      await this.instanceService.cancelInstanceUpgrade(this.instance.id);
    } catch (e) {
      this.snackBar.open(`Error while cancelling the service upgrade ${this.instance.name}`, 'Dismiss', {
        duration: 5000
      });
      console.error(e);
    }
  }
}
