import { Pipe, PipeTransform } from '@angular/core';
import { SECONDS_PER_DAY, SECONDS_PER_HOUR } from '@cp/common/utils/DateTimeUtils';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(milliseconds: number, shortType: boolean = true): string {
    return durationTransform(milliseconds, shortType, 'seconds');
  }
}

@Pipe({
  name: 'durationHours'
})
export class DurationHoursPipe implements PipeTransform {
  transform(milliseconds: number, shortType: boolean = true): string {
    return durationTransform(milliseconds, shortType, 'hours');
  }
}

@Pipe({
  name: 'durationMinutes'
})
export class DurationMinutesPipe implements PipeTransform {
  transform(milliseconds: number, shortType: boolean = true): string {
    return durationTransform(milliseconds, shortType, 'minutes');
  }
}

function pluralize(number: number): string {
  return number > 1 ? 's' : '';
}

function durationTransform(
  milliseconds: number,
  shortType: boolean,
  roundingUnits: 'hours' | 'minutes' | 'seconds'
): string {
  let temp = Math.floor(milliseconds / 1000);
  const results: string[] = [];
  const days = Math.floor((temp %= 31536000) / SECONDS_PER_DAY);
  if (days) {
    results.push(`${days} day${pluralize(days)}`);
  }
  const hours = Math.floor((temp %= 86400) / SECONDS_PER_HOUR);
  if (hours) {
    const units = shortType ? 'hr' : 'hour';
    results.push(`${hours} ${units}${pluralize(hours)}`);
  }
  if (roundingUnits === 'hours') {
    return results.join(', ');
  }
  const minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    const units = shortType ? 'min' : 'minute';
    results.push(`${minutes} ${units}${pluralize(minutes)}`);
  }
  if (roundingUnits === 'minutes') {
    return results.join(', ');
  }
  const seconds = temp % 60;
  const units = shortType ? 'sec' : 'second';
  results.push(`${seconds ? seconds : 1} ${units}${pluralize(seconds)}`);
  return results.join(', ');
}
