<ng-container *ngIf="instanceObs | async as instance">
  <mat-dialog-content class="first_time_set_ip_access_list_dialog" data-cy="first-time-set-ip-access-list-dialog">
    <div class="left">
      <cp-first-time-set-ip-access-list
        [instanceId]="input.instanceId"
        [initialIpAccessList]="input.ipAccessList"
        (ipAccessListChange)="handleIpAccessListChangeEvent($event)"
      ></cp-first-time-set-ip-access-list>
      <button (click)="next()"
              [disabled]="!selected || isSubmitInProgress"
              class="continue_button"
              color="primary"
              mat-flat-button
              data-cy="continue-button"
      >
        <mat-icon *ngIf="isSubmitInProgress" class="button_loader_icon icon svg_stroke" svgIcon="loader"></mat-icon>
        Continue
      </button>

    </div>

    <cp-instance-side-details [instance]="instance"
                              class="instance_side_details"></cp-instance-side-details>

  </mat-dialog-content>
</ng-container>
