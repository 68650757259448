import { DISABLE_AUTO_SIGN_IN_QUERY_PARAM } from '@cp/common/utils/HttpUtils';
import { APP_INIT_URL_PARAMS } from '@cp/web/app/auth0-utils';
import { parseTackleSubscriptionTokenFromUrlOnAppInit } from '@cp/web/app/common/services/tackle-subscription.service';

/**
 * Checks that the current auth provider can auto-sign user during application init.
 * Normally auto-sign-in is always enabled, but in some cases (like redirect from Tackle) there should be no auto-signin.
 */
export function isAutoSigninEnabledOnAppInit(): boolean {
  if (parseTackleSubscriptionTokenFromUrlOnAppInit() !== undefined) {
    // No auto-signin when there are Tackle tokens in the URL.
    // A user must explicitly select the correct account in this case.
    return false;
  }
  return !APP_INIT_URL_PARAMS.has(DISABLE_AUTO_SIGN_IN_QUERY_PARAM);
}
