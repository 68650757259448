import type { IdentityProvider, MfaMethod } from '@cp/common/protocol/Account';
import type { AuditRecordTp } from '@cp/common/protocol/Audit';
import type { CommitmentType, Currency } from '@cp/common/protocol/Billing';
import type { DeepReadonly } from '@cp/common/protocol/Common';
import type { OrganizationFeatureId } from '@cp/common/protocol/features';
import type { SupportCasePriority } from '@cp/common/protocol/Support';
import type { TackleMarketplace } from '@cp/common/protocol/Tackle';
import type { UpdateType } from '@cp/common/protocol/WebSocket';
import { CONFLICT_STATUS, FORBIDDEN_STATUS, registerStatusCodeByErrorToken } from '@cp/common/utils/HttpError';
import type { RpcRequest, WithErrorCode, WithOrganizationId } from '@cp/common/utils/ProtocolUtils';
import type { InstanceCloudProvider } from './Instance';
import type { RegionId } from './Region';

// WebSocket notification payloads

/** Payload of 'ORG_UPDATE' WS message. */
export type OrganizationUpdatePayload =
  | {
      /** List of all user organizations or the updated organization. */
      organizations: Array<Organization>;
      updateType?: UpdateType;
    }
  | {
      /**
       * Sent when user does not belong to the organization anymore:
       * the organization is deleted or a user is removed from the organization.
       */
      updateType: 'UNLINK';
      organizationId: string;
    };

export const ORGANIZATION_ROLES = ['ADMIN', 'DEVELOPER'] as const;

export type OrganizationRole = (typeof ORGANIZATION_ROLES)[number];

/** Refer to flows in billing tech design document https://docs.google.com/document/d/1MZzM-IHYWz0DMNjV3reEg8m42kyJarpU8i4aoFl5-fU/edit#heading=h.atelj2ywpydi */
export const ORGANIZATION_BILLING_STATUS_ARRAY = [
  /** Org was created by a user who isn't eligible to claim another trial. */
  'NO_TRIAL',

  /** Org exists and hasn't started trial yet. Applicable to support-only orgs, training orgs set up in advance, etc. */
  'PRE_TRIAL',

  /** Org is currently in free trial. */
  'IN_TRIAL',

  /** Org's trial has ended (expired or credits depleted). Org does not yet have a payment method. */
  'IN_TRIAL_GRACE_PERIOD',

  /** Org has previously converted. If previously PAID, the latest charge was declined. If previously PREPAID, prepaid credits have expired or depleted.*/
  'IN_NONPAYMENT_GRACE_PERIOD',

  /** Org negotiated a pre-commitment deal and provisioned a credit balance. */
  'PREPAID',

  /** Org has payment method and is invoiced on their monthiversary. */
  'PAID',

  /** Org did not convert during the grace period and was decommissioned. */
  'DECOMMISSIONED',

  /** The system has detected some anomaly, marking this user as requiring manual review. */
  'REVIEW_MANUALLY',

  /** Org is in the marketplace remorse period, they could cancel their subscription and get a full refund. */
  'IN_MP_REMORSE_PERIOD',

  /** Org's marketplace subscription was cancelled. */
  'IN_MP_GRACE_PERIOD',

  /** An employee account isn't billed. */
  'EMPLOYEE',

  /** A non-employee account that we decided to exclude from billing. See https://github.com/ClickHouse/control-plane/issues/1737 */
  'NO_BILLING'
] as const;

export type OrganizationBillingStatus = (typeof ORGANIZATION_BILLING_STATUS_ARRAY)[number];

export type OrganizationBillingStatusProperties = {
  /** A description of the billing status */
  description: string;
  /** The billing status can transition to paid */
  canConvertToPaid: boolean;
  /** If we detect a new trial credit, should we change from this status to IN_TRIAL */
  changeToInTrialIfHasTrialCredit: boolean;
  /** Allow to upgrade instances */
  canUpgradeInstances: boolean;
  /**
   * The billing status cannot be changed automatically,
   *  but requires manual intervention
   */
  requiresManualChange: boolean;
  /** Whether an invoice can be produced for a billing stauts */
  isInvoicingDisabled: boolean;
  /** The restrictions associated with the billing status */
  restrictions: Partial<OrganizationRestrictions>;
};

const ORGANIZATION_BILLING_STATUSES: Record<OrganizationBillingStatus, OrganizationBillingStatusProperties> = {
  NO_TRIAL: {
    description: 'No Trial',
    canConvertToPaid: true,
    changeToInTrialIfHasTrialCredit: true,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: true,
    restrictions: newStrictOrgRestrictions()
  },

  PRE_TRIAL: {
    description: 'Pre Trial',
    canConvertToPaid: true,
    changeToInTrialIfHasTrialCredit: true,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: true,
    restrictions: newPreTrialOrgRestrictions()
  },

  IN_TRIAL: {
    description: 'Trial',
    canConvertToPaid: true,
    changeToInTrialIfHasTrialCredit: true,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: true,
    restrictions: newTrialOrgRestrictions()
  },

  IN_TRIAL_GRACE_PERIOD: {
    description: 'Trial Grace Period',
    canConvertToPaid: true,
    changeToInTrialIfHasTrialCredit: true,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: true,
    restrictions: newStrictOrgRestrictions()
  },

  IN_NONPAYMENT_GRACE_PERIOD: {
    description: 'Nonpayment Grace Period',
    canConvertToPaid: false,
    changeToInTrialIfHasTrialCredit: false,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: false,
    restrictions: newStrictOrgRestrictions()
  },

  PREPAID: {
    description: 'Committed Prepaid',
    canConvertToPaid: false,
    changeToInTrialIfHasTrialCredit: false,
    canUpgradeInstances: true,
    requiresManualChange: false,
    isInvoicingDisabled: false,
    restrictions: newLaxOrgRestrictions()
  },

  PAID: {
    description: 'Paid',
    canConvertToPaid: false,
    changeToInTrialIfHasTrialCredit: false,
    canUpgradeInstances: true,
    requiresManualChange: false,
    isInvoicingDisabled: false,
    restrictions: newLaxOrgRestrictions()
  },

  DECOMMISSIONED: {
    description: 'Decommissioned',
    canConvertToPaid: true,
    changeToInTrialIfHasTrialCredit: true,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: true,
    restrictions: newStrictOrgRestrictions()
  },

  REVIEW_MANUALLY: {
    description: 'Review Manually',
    canConvertToPaid: true,
    changeToInTrialIfHasTrialCredit: false,
    canUpgradeInstances: true,
    requiresManualChange: false,
    isInvoicingDisabled: false,
    // When transitioning to REVIEW_MANUALLY, leave the current restrictions as they are.
    restrictions: keepExistingRestrictionsAsIs()
  },

  IN_MP_GRACE_PERIOD: {
    description: 'Marketplace Grace Period',
    canConvertToPaid: true,
    changeToInTrialIfHasTrialCredit: true,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: true,
    restrictions: newStrictOrgRestrictions()
  },

  IN_MP_REMORSE_PERIOD: {
    description: 'Marketplace Remorse Period',
    canConvertToPaid: false,
    changeToInTrialIfHasTrialCredit: false,
    canUpgradeInstances: false,
    requiresManualChange: false,
    isInvoicingDisabled: false,
    restrictions: newLaxOrgRestrictions()
  },

  EMPLOYEE: {
    description: 'Employee account',
    canConvertToPaid: false,
    changeToInTrialIfHasTrialCredit: false,
    canUpgradeInstances: true,
    requiresManualChange: true,
    isInvoicingDisabled: true,
    // When transitioning to EMPLOYEE, leave the current restrictions as they are.
    restrictions: keepExistingRestrictionsAsIs()
  },

  NO_BILLING: {
    description: 'No Billing',
    canConvertToPaid: false,
    changeToInTrialIfHasTrialCredit: false,
    canUpgradeInstances: true,
    requiresManualChange: true,
    isInvoicingDisabled: true,
    // When transitioning to NO_BILLING, leave the current restrictions as they are.
    restrictions: keepExistingRestrictionsAsIs()
  }
} as const;

export type BillingStatusDescription = (typeof ORGANIZATION_BILLING_STATUSES)[OrganizationBillingStatus]['description'];

type BillingStatusProperty = keyof (typeof ORGANIZATION_BILLING_STATUSES)[OrganizationBillingStatus];

export const ORGANIZATION_BILLING_STATUS_TO_DESCRIPTION_MAP: Record<
  OrganizationBillingStatus,
  BillingStatusDescription
> = (Object.keys(ORGANIZATION_BILLING_STATUSES) as Array<OrganizationBillingStatus>).reduce(
  (obj, el) => {
    obj[el] = ORGANIZATION_BILLING_STATUSES[el].description;
    return obj;
  },
  {} as Record<OrganizationBillingStatus, BillingStatusDescription>
);

function getBillingStatusesSetWithProperty(property: BillingStatusProperty): ReadonlySet<OrganizationBillingStatus> {
  return new Set<OrganizationBillingStatus>(
    (Object.keys(ORGANIZATION_BILLING_STATUSES) as Array<OrganizationBillingStatus>).filter(
      (key) => ORGANIZATION_BILLING_STATUSES[key][property]
    )
  );
}

function getBillingStatusToPropertyMap<K extends BillingStatusProperty>(
  property: K
): Record<OrganizationBillingStatus, (typeof ORGANIZATION_BILLING_STATUSES)[OrganizationBillingStatus][K]> {
  return (Object.keys(ORGANIZATION_BILLING_STATUSES) as Array<OrganizationBillingStatus>).reduce(
    (res, billingStatus) => {
      res[billingStatus] = ORGANIZATION_BILLING_STATUSES[billingStatus][property];
      return res;
    },
    {} as Record<OrganizationBillingStatus, (typeof ORGANIZATION_BILLING_STATUSES)[OrganizationBillingStatus][K]>
  );
}

/**
 * Statuses that react to adding a payment method by changing the org's status to PAID.
 */
export const ORG_BILLING_STATUS_THAT_CAN_CONVERT_TO_PAID_SET = getBillingStatusesSetWithProperty('canConvertToPaid');

/**
 * Statuses that react to adding a TRIAL credit by changing the org's status to IN_TRIAL.
 */
export const ORG_BILLING_STATUS_THAT_SWITCH_TO_IN_TRIAL_SET = getBillingStatusesSetWithProperty(
  'changeToInTrialIfHasTrialCredit'
);

/**
 * Statuses that can upgrade an instance.
 */
export const ORG_BILLING_STATUS_THAT_CAN_UPGRADE_INSTANCES = getBillingStatusesSetWithProperty('canUpgradeInstances');

export function canBillingStatusUpgradeInstance(billingStatus: unknown): billingStatus is OrganizationBillingStatus {
  return (
    typeof billingStatus === 'string' &&
    ORG_BILLING_STATUS_THAT_CAN_UPGRADE_INSTANCES.has(billingStatus as OrganizationBillingStatus)
  );
}

/**
 * These statuses can only be changed manually (once set, it can only be changed from sysadmin console).
 */
export const STICKY_ORG_BILLING_STATUS = getBillingStatusesSetWithProperty('requiresManualChange');
/**
 * Statuses that do not allow invoice creation.
 */
export const ORG_BILLING_STATUS_THAT_CANNOT_HAVE_INVOICES_SET =
  getBillingStatusesSetWithProperty('isInvoicingDisabled');

/** Size of the company that an admin . */
export const COMPANY_SIZE_ARRAY = [
  '0-10',
  '11-25',
  '26-50',
  '51-100',
  '101-250',
  '251-500',
  '501-1000',
  '1001-2500',
  '2501-5000',
  '5001-10000',
  '10000+'
] as const;

export type CompanySize = (typeof COMPANY_SIZE_ARRAY)[number];

/** Error codes for accept invitation. */
export type ErrorCode = 'email-mismatch' | 'expired' | 'ok';

export const ALL_COMPANY_SIZE_SET: ReadonlySet<CompanySize> = new Set<CompanySize>([...COMPANY_SIZE_ARRAY]);

/** Creates the most permissive restrictions. */
export function newLaxOrgRestrictions(): Partial<OrganizationRestrictions> {
  return {
    maxInstanceCount: 20,
    canCreateInstances: true,
    canStartInstances: true,
    canInviteMembers: true,
    maxCasePriority: 'SEV_1'
  };
}

/** Creates the least permissive restrictions. This org can't really "do" anything. */
function newStrictOrgRestrictions(): Partial<OrganizationRestrictions> {
  return {
    maxInstanceCount: 0,
    canCreateInstances: false,
    canStartInstances: false,
    canStartTrial: false
  };
}

function newTrialOrgRestrictions(): Partial<OrganizationRestrictions> {
  return {
    maxInstanceCount: 1,
    canCreateInstances: true,
    canStartInstances: true,
    canStartTrial: false
  };
}

function newPreTrialOrgRestrictions(): Partial<OrganizationRestrictions> {
  return {
    ...newTrialOrgRestrictions(),
    canStartTrial: true
  };
}

// billingStatuses that use this option do not change restrictions.
function keepExistingRestrictionsAsIs(): Partial<OrganizationRestrictions> {
  return {};
}

/** Mapping of org status to restrictions. */
export const ORG_RESTRICTIONS_BY_STATUS: DeepReadonly<
  Record<OrganizationBillingStatus, Partial<OrganizationRestrictions>>
> = getBillingStatusToPropertyMap('restrictions');

/** URL path for organization handler: /api/organization. */
export const ORGANIZATION_API_PATH = 'organization';

/** Set of all RPC actions for 'organization' handler. */
export type OrganizationRpcAction =
  | 'acceptInvitation'
  | 'changeUserRole'
  | 'create'
  | 'createDefault'
  | 'delete'
  | 'declineInvitation'
  | 'deleteInvitation'
  | 'invite'
  | 'leave'
  | 'list'
  | 'listActivities'
  | 'removeUser'
  | 'rename'
  | 'resendInvitation'
  | 'markViewedInvitations';

export type OrganizationRpcRequest<T extends OrganizationRpcAction> = RpcRequest<T>;

/**
 * Exposed example of default restrictions.
 * You should use not this directly in the web since GetUserDetails.restrictions should always be fully populated.
 */
export function getDefaultOrganizationRestrictions(): OrganizationRestrictions {
  return {
    maxInstanceCount: 20,
    canCreateInstances: true,
    canStartInstances: true,
    canInviteMembers: true,
    canStartTrial: false,
    maxCasePriority: 'SEV_3'
  };
}

export interface CreateOrganizationRequest extends OrganizationRpcRequest<'create'> {
  name: string;
}

/** Error token used to notify a client that organization with the same name already exists. */
export const ORG_WITH_SAME_NAME_ALREADY_EXISTS = 'ORG_WITH_SAME_NAME_ALREADY_EXISTS';
registerStatusCodeByErrorToken(ORG_WITH_SAME_NAME_ALREADY_EXISTS, CONFLICT_STATUS);

export const ORGANIZATION_LIMIT_REACHED = 'ORGANIZATION_LIMIT_REACHED';
registerStatusCodeByErrorToken(ORGANIZATION_LIMIT_REACHED, CONFLICT_STATUS);

export const CREATING_ORGANIZATIONS_DISABLED = 'CREATING_ORGANIZATIONS_DISABLED';
registerStatusCodeByErrorToken(CREATING_ORGANIZATIONS_DISABLED, FORBIDDEN_STATUS);

export interface ChangeOrganizationNameRequest extends OrganizationRpcRequest<'rename'>, WithOrganizationId {
  name: string;
}

export interface RemoveUserFromOrganizationRequest extends OrganizationRpcRequest<'removeUser'>, WithOrganizationId {
  userId: string;
}

/**
 * You cannot delete an organization if you have instances associated with it. All users are first removed from the
 * organization, and then we delete it completely from the DB.
 */
export type DeleteOrganizationRequest = OrganizationRpcRequest<'delete'> & WithOrganizationId;

/** Response error token for new members invitations in case if the org can't invite new members. */
export const ORG_CANT_INVITE_MEMBERS = 'ORG_CANT_INVITE_MEMBERS';
registerStatusCodeByErrorToken(ORG_CANT_INVITE_MEMBERS, CONFLICT_STATUS);

export interface InviteToOrganizationRequest extends OrganizationRpcRequest<'invite'>, WithOrganizationId {
  emails: Array<string>;
  roles: Array<OrganizationRole>;
}

export interface ResendInviteToOrganizationRequest
  extends OrganizationRpcRequest<'resendInvitation'>,
    WithOrganizationId {
  email: string;
  role: OrganizationRole;
}

export interface DeleteOrganizationInvitationRequest
  extends OrganizationRpcRequest<'deleteInvitation'>,
    WithOrganizationId {
  email: string;
}

export interface AcceptOrganizationInvitationRequest extends OrganizationRpcRequest<'acceptInvitation'> {
  inviteKey: string;
}

export interface DeclineOrganizationInvitationRequest extends OrganizationRpcRequest<'declineInvitation'> {
  inviteKey: string;
}

export interface MarkViewedInvitationsRequest extends OrganizationRpcRequest<'markViewedInvitations'> {
  inviteKeys: Array<string>;
}

export interface ChangeOrganizationUserRoleRequest
  extends OrganizationRpcRequest<'changeUserRole'>,
    WithOrganizationId {
  userId: string;
  role: OrganizationRole;
}

export type CreateDefaultOrganizationForUserRequest = OrganizationRpcRequest<'createDefault'>;

export type ListOrganizationsRequest = OrganizationRpcRequest<'list'>;
export type ListOrganizationsResponse = Record<string, Organization>;

export type LeaveOrganizationRequest = OrganizationRpcRequest<'leave'> & WithOrganizationId;

export interface AcceptOrganizationInvitationResponse extends WithOrganizationId, WithErrorCode {
  organization?: Organization;
}

export interface AddSamlUserToOrganizationResponse extends WithOrganizationId, WithErrorCode {
  organization?: Organization;
}

/**
 * Type of the payment type for the organization.
 * STRIPE - payment is made using credit card using Stripe service.
 * TACKLE - payment is made using Tackle service (Cloud subscription).
 */
export type OrganizationPaymentStateType = 'STRIPE' | 'TACKLE';

/**
 * Tackle subscription details.
 * See https://developers.tackle.io/docs/setup-your-self-hosted-registration-page.
 */
export interface OrganizationTackleSubscription {
  marketplace: TackleMarketplace;
  customerId: string;
  productId: string;
  /** The AWS account number 1234-1234-1234 will appear as 123412341234. */
  awsAccountId?: string;

  /** Time the subscription was made on CH side. Not the same as AWS-side subscription time. */
  clickhouseSubscriptionDate: Date;
  /** Time the subscription was cancelled CH side. Not the same as AWS-side non-renewal time (but should be close). */
  clickhouseCancellationDate?: Date;
}

export interface Organization {
  id: string;
  name: string;
  createdAt: number;
  updatedAt: number;
  users: Record<string, OrganizationUser>;
  invitations: Record<string, OrganizationInvitation>;
  billingStatus: OrganizationBillingStatus;
  /** Organization-level restrictions. Can override user roles. */
  restrictions: OrganizationRestrictions;
  paymentDetails: {
    currency: Currency;
    addressCaptured: boolean;
    paymentMethodCaptured: boolean;
  };
  paymentState: OrganizationPaymentStateType;
  tackleState?: OrganizationTackleSubscription;
  firmographics: Partial<OrganizationFirmographics>;
  cachedCommitmentState: Partial<Record<CommitmentType, OrganizationCommitment>>;
  features: Array<OrganizationFeatureId>;
  regionsWhiteList: string;
  gcpPrivatePreviewTermsAccepted?: boolean;
}

export interface OrganizationWithServices extends Organization {
  services?: Array<string>;
}

export interface OrganizationUserDetailsUpdate {
  name?: string;
  email?: string;
  mfaPreferredMethod?: MfaMethod;
  identityProviders?: Array<IdentityProvider>;
}

export interface OrganizationUser {
  joinedAt: number;
  userId: string;
  name: string;
  role: OrganizationRole;
  email: string;
  mfaPreferredMethod?: MfaMethod;
  identityProviders?: Array<IdentityProvider>;
}

export interface OrganizationInvitation {
  email: string;
  role: OrganizationRole;
  expirationDate: number;
  linkClicked: boolean;
  inviterEmail?: string;
}

export interface OrganizationInvitationDetails {
  id: string;
  invitationKey: string;
  isExpired: boolean;
  inviterEmail?: string;
  organizationName: string;
}

/**
 * These restrictions apply to the entire organization and can override user roles.
 * For example: An ADMIN on an org with canStartInstances = false will not be able to start any instances.
 */
export interface OrganizationRestrictions {
  /** How many instances can be created in total in this organization. */
  maxInstanceCount: number;
  /** Can new instances be created. */
  canCreateInstances: boolean;
  /** Can existing instances be started.  */
  canStartInstances: boolean;
  /** Can anyone be invited to this org. */
  canInviteMembers: boolean;
  /** Can this org start a trial. This can happen if the user who created the org has already claimed a trial on another org. */
  canStartTrial: boolean;
  /** What's the highest priority os a support case this org can create. */
  maxCasePriority: SupportCasePriority;
}

/** This object should only exist if the organization is a business. */
export interface OrganizationFirmographics {
  /** The organization's website. */
  websiteUrl: string;
  /** The organization's company name. */
  companySize: CompanySize;
}

/** Refer to documentation on OrganizationM3terBo. */
export interface OrganizationM3ter {
  accountId?: string;
  planAssigned: boolean;
  sfdcExternalMappingCreated: boolean;
}

/** Refer to documentation on OrganizationStripeCustomerBo. */
export interface OrganizationStripeCustomer {
  customerId: string;
  paymentMethodCaptured: boolean;
  addressCaptured: boolean;
}

export interface OrganizationCommitment {
  /** Id of the commitment. to differentiate between them. */
  id: string;
  /** The type of commitment last seen */
  commitmentType: CommitmentType;
  /** Total amount of credits allotted in this commitment. */
  totalAmount: number;
  /** Amount of credits already spent out of the total. */
  amountSpent: number;
  /** Amount of credits remaining. */
  amountRemaining: number;
  /** When this commitment started, should be a past date. Unix timestamp in milliseconds. */
  startDate: number;
  /** When will this commitment expire, could be a future or past date. Unix timestamp in milliseconds. */
  expirationDate: number;
  /**
   * The total amount of days for this commitment.
   * Use this instead of expirationDare - startDate as it compensates for the slightly longer trials we have instated.
   * See: https://github.com/ClickHouse/control-plane/issues/1076
   */
  totalDurationInDays: number;
  /**
   * The amount of days before this commitment expires.
   * Use this instead of expirationDare - now as it compensates for the slightly longer trials we have instated.
   * See: https://github.com/ClickHouse/control-plane/issues/1076
   */
  timeRemainingInDays: number;
  /** Usage percent, calculated on the server for consistency. */
  usagePercent: number;
}

// WebSocket notification payloads

// ORG_USER_NAME_CHANGED
export interface OrganizationUserNameChangedPayload {
  userId: string;
  newName: string;
}

export type OrganizationActivity = AuditRecordTp & {
  id: string;
  userId?: string;
  openapiKeySuffix?: string;
  email?: string;
  ipAddress?: string;
  timestamp: Date;
  instanceId?: string;
  instanceName?: string;
  /* Deprecated. Use actorDetails. */
  fullName?: string;
  /** Display level info about the actor. Can be a email, a username or a generic actor name for internal actors. */
  actorDetails: string;
  /** Info about the affected entity. See AuditRecord.entityDetails. */
  entityDetails?: string;
};

export type ListOrganizationActivitiesRequest = OrganizationRpcRequest<'listActivities'> & WithOrganizationId;

export interface ListOrganizationActivitiesResponse {
  activities: Array<OrganizationActivity>;
}

export interface OrganizationPrivateEndpoint {
  cloudProvider: InstanceCloudProvider;
  id: string;
  description: string;
  region?: RegionId;
}
