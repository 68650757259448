<mat-dialog-content class="request_integration_dialog" *ngIf="requestIntegrationDialogUiStateObs | async as uiState">
  <ng-container *ngIf="!uiState.requestSubmitted">
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
    <cp-dialog-title data-cy="request-integration-dialog-title" dialogTitle="Request new integration"></cp-dialog-title>
    <div class="text">
      Please indicate the integration that you’d like to see included and a brief summary of how you’d like to use it.
    </div>

    <div class="textarea_title">Request</div>

    <div class="seed_form_element">
        <textarea [(ngModel)]="requestText"
                  class="seed_input textarea"
                  data-cy="request-textarea"
                  placeholder="Integration name and description"
        ></textarea>
    </div>

    <div class="buttons">
      <button (click)="submitRequest()"
              [disabled]="isSubmitButtonDisabled() || uiState.sendIntegrationRequestButtonDisabled"
              class="submit-button"
              color="primary"
              data-cy="submit-request-integration-button"
              mat-flat-button
              type="submit"
      >Request integration
      </button>
      <button (click)="dialogRef.close()"
              class="close_button"
              data-cy="close-request-integration-dialog-button"
              mat-button
              type="button"
      >Cancel
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="uiState.requestSubmitted">
    <cp-dialog-submitted
      title="Thanks so much for your request!"
      subtitle="Our team will review your request for a new integration and will reach out if we need more information."
      [dialogRef]="dialogRef"
    ></cp-dialog-submitted>
  </ng-container>
</mat-dialog-content>
