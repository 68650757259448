<ng-container *ngIf="uiStateObs | async as uiState">
  <div *ngIf="!uiState.hidden" [ngClass]="{ active: uiState.active }" class="fly_out_and_backdrop_container">
    <div (click)="closeFlyout()" class="backdrop"></div>
    <div class="fly_out_content_wrapper">
      <div class="fly_out_content">
        <div class="title_and_close_button">
          <div class="title">Help</div>
          <mat-icon (click)="closeFlyout()" class="top_close_button icon svg_fill">close</mat-icon>
        </div>

<!--        TODO (https://github.com/ClickHouse/control-plane/issues/1379):: Put back after fetching upcoming events from teh CMS. -->
<!--        <section class="help_section">-->
<!--          <div class="section_title_wrapper">-->
<!--            <div class="section_icon_wrapper">-->
<!--              <mat-icon class="section_icon icon" svgIcon="help_calendar"></mat-icon>-->
<!--            </div>-->
<!--            <span class="title">Upcoming events</span>-->
<!--          </div>-->
<!--          <div class="section_content documentation_content">-->
<!--            <a class="link" href="https://clickhouse.com/company/events/v22-10-release-webinar?utm_source=help_menu&utm_medium=cloud_ui&utm_campaign=webinar_22_10_release" target="_blank">Oct 26 - 22.10 release webinar</a>-->
<!--            <a class="link" href="https://clickhouse.com/company/events/cloud-beta?utm_source=help_menu&utm_medium=cloud_ui&utm_campaign=webinar_cloud_beta" target="_blank">Oct 27 - Introducing ClickHouse Cloud webinar</a>-->
<!--            <a class="link" href="https://us06web.zoom.us/meeting/register/tZYsdOuppzMjE9GLu7bJJkBURUFwFfVtoOkn?utm_source=help_menu&utm_medium=cloud_ui&utm_campaign=office_hours" target="_blank">Nov 3 - ClickHouse Cloud Office Hours</a>-->
<!--          </div>-->
<!--        </section>       -->

        <section class="help_section">
          <div class="section_title_wrapper">
            <div class="section_icon_wrapper">
              <mat-icon class="section_icon icon svg_stroke" svgIcon="help_documentation"></mat-icon>
            </div>
            <span class="title">Documentation</span>
          </div>
          <div class="section_content documentation_content">
            <a class="link" href="https://clickhouse.com/docs/" target="_blank">ClickHouse documentation</a>
            <a class="link" href="https://clickhouse.com/docs/en/whats-new/cloud/" target="_blank">Changelog: What's new in Cloud</a>
            <a class="link" href="https://clickhouse.com/docs/en/connect-a-ui" target="_blank">Connect to a
              visualization tool</a>
            <a class="link" href="https://clickhouse.com/docs/en/guides" target="_blank">New ClickHouse user guides</a>
            <div class="disclaimer">
              Documentation linked above is focused on self-managed deployments of ClickHouse. Some functionality
              described may not yet be available in ClickHouse Cloud. If you have questions, please contact <a
              class="link" href="https://clickhouse.cloud/support" target="_blank">support</a>.
            </div>
          </div>
        </section>

        <section class="help_section">
          <div class="section_title_wrapper">
            <div class="section_icon_wrapper">
              <mat-icon class="section_icon icon svg_stroke" svgIcon="help_support"></mat-icon>
            </div>
            <span class="title">Support</span>
          </div>
          <div class="section_content support_content">
            <div class="text">
              Create a support case if you’ve been unable to find answers to your question in the
              <a href="https://clickhouse.com/docs/" target="_blank">documentation</a>.
            </div>

            <div class="buttons">
              <button (click)="showCreateSupportCaseDialog()" class="button" color="primary" mat-flat-button>
                Create case
              </button>
            </div>
            <cp-support-cases class="support_cases"></cp-support-cases>

          </div>
        </section>
      </div>
      <div class="fly_out_close_button_wrapper">
        <button (click)="closeFlyout()" class="button" color="primary" mat-flat-button>Close</button>
      </div>
    </div>
  </div>
</ng-container>
