import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cp-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogTitleComponent {
  @Input()
  dialogTitle?: string;
}
