<div *ngIf="supportCasesObs | async as supportCases" class="support_cases_container">
  <div class="header">
    <div class="open_cases_title">
      OPEN CASES ({{supportCases.length}})
    </div>
  </div>
  <div class="support_cases">
    <div *ngFor="let supportCase of supportCases; trackBy: trackById" class="support_case">
      <a (click)="openSupportCaseDetails(supportCase.id)" class="subject">{{supportCase.subject}}</a>
      <div class="attributes">
        <div class="attribute">
          <div class="name">Last updated</div>
          <div class="value">{{supportCase.lastModifiedDate | approximateTime: 'MMM d, yy, h:mm:ss a'}}</div>
        </div>
        <div *ngIf="supportCaseStatusDisplay[supportCase.status] as displayStatus" class="attribute">
          <div class="name">Status</div>
          <div class="value">
            <div [ngClass]="displayStatus.className" class="indicator"></div>
            {{displayStatus.text}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
