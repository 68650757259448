import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS,
  STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS
} from '@cp/common/environment/CommonEnvironment';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-nonpayment-dialog',
  templateUrl: './nonpayment-dialog.component.html',
  styleUrls: ['./nonpayment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonpaymentDialogComponent {
  readonly myRoleObs: Observable<OrganizationRole>;

  readonly STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS = STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS;
  readonly DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS = DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS;

  constructor(
    public readonly dialogRef: MatDialogRef<NonpaymentDialogComponent>,
    private readonly organizationStateService: OrganizationStateService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {
    this.myRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
  }

  async goToBilling(): Promise<void> {
    await this.router.navigateByUrl('/organization/admin/billing');
    this.dialogRef.close();
  }

  async goToSupport(): Promise<void> {
    await this.router.navigateByUrl('/support');
    this.dialogRef.close();
  }

  static show(dialog: MatDialog): void {
    dialog.open(NonpaymentDialogComponent, {
      width: '100%',
      maxWidth: '501px',
      minHeight: '463px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal_no_padding',
      disableClose: true
    });
  }
}
