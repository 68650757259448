import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { isDefined } from '@cp/common/protocol/Common';
import { Instance, InstanceState } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { InstancePermissionsState, UserPermissionsSummary } from '@cp/common/utils/DbUserPermissions';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { CredentialDetails } from '@cp/web/app/common/components/credentials-container/credentials-container.component';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { distinctUntilChanged, ReplaySubject, switchMap } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InstanceUiService } from '../../instances/instance-ui.service';
import { SelectedInstanceUser } from './manage-db-users.component';

type StatusBadgeClassType = 'success' | 'info' | 'warning' | 'danger';
type StatusBadgeClass = 'status_badge' | `status_badge_${StatusBadgeClassType}`;

@Component({
  selector: 'manage-instance-db-users',
  templateUrl: './manage-instance-db-users.component.html',
  styleUrls: ['./manage-instance-db-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageInstanceDbUsersComponent extends OnDestroyComponent implements OnInit, OnChanges {
  @Input()
  instanceId!: string;

  @Input()
  permissionsResult!: InstancePermissionsState;

  @Output()
  userSelected = new EventEmitter<SelectedInstanceUser>();

  @Output()
  loadUsers = new EventEmitter<string>();

  private instanceIdSubject = new ReplaySubject<string>(1);

  instance?: Instance;
  instancePassword = '';

  credentialsDetails?: CredentialDetails;
  usePasswordAuth = false;

  constructor(
    private readonly instanceUiService: InstanceUiService,
    private readonly instanceStateService: InstanceStateService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();

    this.instanceIdSubject
      .pipe(
        takeUntil(this.onDestroy),
        distinctUntilChanged(),
        switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId)),
        filter(isDefined)
      )
      .subscribe((instance) => {
        this.instance = instance;
        this.cdr.markForCheck();
      });

    this.instanceIdSubject
      .pipe(
        takeUntil(this.onDestroy),
        distinctUntilChanged(),
        switchMap((instanceId) => this.instanceStateService.observeInstancePassword(instanceId)),
        filter(isDefined)
      )
      .subscribe((password) => {
        this.instancePassword = password;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);
  }

  ngOnChanges(): void {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);
  }

  toggleUsePasswordAuth(event: MatSlideToggleChange) {
    this.usePasswordAuth = event.checked;
  }

  hasDirectPermissions(user: UserPermissionsSummary): boolean {
    return user.directGrants.length > 0;
  }

  userPermissions(user: UserPermissionsSummary): Array<string> {
    return user.roleGrants.map((grant) => grant.roleName);
  }

  selectUser(userName: string) {
    this.userSelected.emit({ userName, instanceId: this.instanceId });
  }

  users(): Array<UserPermissionsSummary> {
    if (this.permissionsResult.status === 'loaded') {
      return this.permissionsResult.users;
    } else {
      return [];
    }
  }

  statusBadgeClass(): Array<StatusBadgeClass> {
    const classes: Array<StatusBadgeClass> = ['status_badge'];

    if (this.instance) {
      switch (this.instance.state) {
        case 'running':
          classes.push('status_badge_success');
          break;
        case 'provisioning':
          classes.push('status_badge_warning');
          break;
        case 'stopped':
        case 'terminated':
        case 'degraded':
        case 'failed':
          classes.push('status_badge_danger');
          break;
        case 'idle':
        case 'awaking':
        default:
          classes.push('status_badge_info');
          break;
      }
    }

    return classes;
  }

  canLoadUsers(status: InstanceState): boolean {
    return ['running', 'idle', 'awaking'].includes(status);
  }

  formatStatus(): string {
    switch (this.instance?.state) {
      case 'running':
        return 'Running';
      case 'provisioning':
        return 'Provisioning';
      case 'stopped':
        return 'Stopped';
      case 'terminated':
        return 'Terminated';
      case 'degraded':
        return 'Degraded';
      case 'failed':
        return 'Failed';
      case 'idle':
        return 'Idle';
      case 'awaking':
        return 'Awaking';
      default:
        return this.instance?.state ?? '';
    }
  }

  loadUsersForInstance(): void {
    this.loadUsers.emit(this.instanceId);
  }

  resetInstancePassword(): void {
    if (this.instance) {
      this.instanceUiService.showInstanceResetPasswordDialog(this.instance.id, this.instance.dbUsername, true);
    }
  }

  trackByUsername(index: number, user: { username: string }): string {
    return user.username;
  }

  trackByIdentity(index: number, obj: string) {
    return obj;
  }

  instanceStatusTooltip(instance: Instance): string {
    if (instance.state === 'idle') {
      return 'We cannot retrieve the list of users because your service is idle. To wake up your service, click on Show Users';
    } else {
      return '';
    }
  }
}
