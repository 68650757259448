import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'cp-instance-provisioning-dialog',
  templateUrl: './instance-provisioning-dialog.component.html',
  styleUrls: ['./instance-provisioning-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceProvisioningDialogComponent extends OnDestroyComponent {
  instanceId: string;

  constructor(
    public dialogRef: MatDialogRef<InstanceProvisioningDialogComponent>,
    private readonly instanceStateService: InstanceStateService,
    @Inject(MAT_DIALOG_DATA) private readonly data: { instanceId: string }
  ) {
    super();
    const { instanceId } = data;
    this.instanceId = instanceId;
    this.instanceStateService
      .observeInstance(instanceId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((instance) => {
        if (instance && ['running', 'idle', 'awaking'].includes(instance.state)) {
          this.dialogRef.close();
        }
      });
  }

  static show(dialog: MatDialog, instanceId: string): void {
    dialog.open(InstanceProvisioningDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      minHeight: '358px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'provisioning_modal',
      data: { instanceId }
    });
  }
}
