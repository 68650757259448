<ng-container *ngIf="userOrgRoleObs | async as userOrgRole">
  <ng-container *ngVar="isAllowAnywhereObs | async as isAllowAnywhere">
    <div class="access_list_block" >
      <div class="access_list_info_card">
        <h4>IP Access List</h4>

        <div class="paragraph_text">
          To ensure an extra layer of security for your service, we recommend only allowing traffic from IP addresses that
          you know and trust.
        </div>
      </div>

      <div class="access_list_controls_block">
        <div class="allow_access_title">Allow access to this service from</div>

        <div class="access_list_kind_buttons_block" *ngIf="userOrgRole === 'ADMIN'">
          <div class="access_list_kind_buttons_row">
            <button (click)="switchToSpecificLocations()"
                    [galaxyClick]="['servicePage.security.accessOptionSelect', {value: 'specific'}]"
                    [attr.disabled]="isAllowAnywhere ? null : true"
                    mat-stroked-button
                    class="left_button"
                    [ngClass]="!isAllowAnywhere ? 'selected' : ''"
                    data-cy="specific-location-button"
                    >Specific locations
            </button>

            <button (click)="switchToAllowAnywhere()"
                    [galaxyClick]="['servicePage.security.accessOptionSelect', {value: 'anywhere'}]"
                    [attr.disabled]="isAllowAnywhere?true:null"
                    mat-stroked-button
                    class="right_button"
                    [ngClass]="isAllowAnywhere ? 'selected': ''"
                    data-cy="anywhere-button"
                    >Anywhere
            </button>
          </div>
          <div *ngIf="isPendingUpdate" class="pending_update">
            <div>
              <mat-icon svgIcon="health_loading" class="loading_icon"></mat-icon>
            </div>
            Updating…
          </div>
        </div>

        <div *ngIf="isAllowAnywhere && !isPendingUpdate" class="allow_anywhere_text" data-cy="allow-anywhere-text">
          IP Access is allowing incoming traffic from anywhere.
        </div>

      </div>
    </div>

    <div *ngIf="!isAllowAnywhere && !isPendingUpdate" class="specific_locations_block" data-cy="specific-location-block">
      <cp-ip-access-list-table [instanceId]="instanceId"></cp-ip-access-list-table>

      <div class="access_list_buttons_block">
        <button *ngIf="userOrgRole === 'ADMIN'"
                (click)="showAddIpAccessEntryDialog()"
                class="add_ip_access_list_entry_button"
                color="primary"
                mat-flat-button
                data-cy="add-ip-access-list-entry-button"
                >
          Add entry
        </button>

        <button [matMenuTriggerFor]="shareIpAccessListMenu"
                mat-flat-button
                class="share_ip_access_list_button"
                data-cy="share-ip-access-list-button"
                >
          <mat-icon svgIcon="share" class="share_icon"></mat-icon>
          Import
        </button>
        
        <mat-menu #shareIpAccessListMenu="matMenu" xPosition="before" data-cy="share-ip-access-list-dropdown">
          <div class="menu_buttons">

            <button *ngIf="userOrgRole === 'ADMIN'"
                    (click)="showImportFromAnotherInstanceDialog()"
                    mat-menu-item class="menu_button"
                    data-cy="import-from-another-instance-button"
                    >Import from another service
            </button>

            <button *ngIf="userOrgRole === 'ADMIN'"
                    (click)="showImportIpAccessListDialog()"
                    mat-menu-item class="menu_button"
                    data-cy="import-from-json-button"
                    >Import from JSON file
            </button>

            <mat-divider *ngIf="userOrgRole === 'ADMIN'"></mat-divider>

            <button (click)="exportIpAccessList()" mat-menu-item class="menu_button" data-cy="export-to-json-button">
              Export to JSON file
            </button>
          </div>
        </mat-menu>

      </div>
    </div>

  </ng-container>
</ng-container>
