import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { isDefined } from '@cp/common/protocol/Common';
import {
  InstanceMysqlSettings,
  Instance,
  InstanceEndpoint,
  InstanceEndpointProtocol
} from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { distinctUntilChanged, filter, map, Observable, ReplaySubject, switchMap, takeUntil, zip } from 'rxjs';
import { InstanceService } from '@cp/web/app/instances/instance.service';

@Component({
  selector: 'cp-connect-to-instance-dialog-content',
  templateUrl: './connect-to-instance-dialog-content.component.html',
  styleUrls: ['./connect-to-instance-dialog-content.component.scss'],
  providers: [TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectToInstanceDialogContentComponent extends OnDestroyComponent implements OnChanges, OnInit {
  @Input()
  instanceId!: string;

  @Input()
  password?: string;

  @Input()
  username?: string;

  readonly instanceObs!: Observable<Instance | undefined>;
  readonly endpointsObs!: Observable<Record<InstanceEndpointProtocol, InstanceEndpoint>>;
  readonly myOrgRoleObs: Observable<OrganizationRole>;
  private readonly instanceIdObs = new ReplaySubject<string>(1);
  readonly mysqlSettingsObs: Observable<InstanceMysqlSettings | undefined>;
  private mysqlEnabled: boolean = false;

  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceUiService: InstanceUiService,
    private readonly segmentService: SegmentService,
    private readonly titlecasePipe: TitleCasePipe,
    private readonly instanceService: InstanceService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.instanceObs = this.instanceIdObs.pipe(
      distinctUntilChanged(),
      filter(isDefined),
      switchMap((id) => {
        return this.instanceStateService.observeInstance(id);
      })
    );
    this.mysqlSettingsObs = this.instanceIdObs.pipe(
      distinctUntilChanged(),
      filter(isDefined),
      switchMap((id) => {
        return this.instanceStateService.observeInstanceMysqlSettings(id);
      })
    );
    this.endpointsObs = this.instanceObs.pipe(
      filter(isDefined),
      map((instance) => instance.endpoints),
      filter(isDefined)
    );
    this.myOrgRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
  }

  ngOnInit(): void {
    this.updateInstanceId();

    zip(this.instanceIdObs, this.mysqlSettingsObs, this.organizationStateService.observeCurrentOrganization())
      .pipe(
        filter(([, mysqlSettings, org]) => !mysqlSettings && org.features.includes('FT_ORG_MYSQL_PROTOCOL')),
        takeUntil(this.onDestroy)
      )
      .subscribe(([instanceId]) => {
        this.instanceService.fetchMysqlSettings(instanceId);
      });
    this.organizationStateService
      .observeCurrentOrganization()
      .pipe(
        map((organization) => organization.features.includes('FT_ORG_MYSQL_PROTOCOL')),
        takeUntil(this.onDestroy)
      )
      .subscribe((mysqlEnabled) => {
        this.mysqlEnabled = mysqlEnabled;
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['instanceId']) {
      this.updateInstanceId();
    }
  }

  updateInstanceId(): void {
    assertTruthy(this.instanceId);
    this.instanceIdObs.next(this.instanceId);
  }

  showInstanceResetPasswordDialog(instance: Instance): void {
    this.instanceUiService.showInstanceResetPasswordDialog(instance.id, instance.dbUsername);
  }

  protocolClicked(protocol: string): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'choose protocol',
      category: 'cloud ui service',
      properties: { protocol },
      view: 'servicePage',
      component: 'modal'
    });
  }

  getEndpointProtocolsInTabOrder(
    endpoints: Record<InstanceEndpointProtocol, InstanceEndpoint>
  ): Array<InstanceEndpointProtocol> {
    let protocols = Object.keys(endpoints).sort() as Array<InstanceEndpointProtocol>;
    // Make 'nativesecure' the first (and default).
    if (protocols.includes('nativesecure') && protocols[0] !== 'nativesecure') {
      protocols = protocols.filter((p) => p !== 'nativesecure');
      protocols.unshift('nativesecure');
    }
    if (this.mysqlEnabled) {
      protocols.push('mysql');
    }
    return protocols;
  }

  getEndpointProtocolName(endpointProtocol: InstanceEndpointProtocol): string {
    switch (endpointProtocol) {
      case 'https':
        return 'HTTPS';
      case 'nativesecure':
        return 'Native';
      case 'mysql':
        return 'MySQL';
      default:
        return this.titlecasePipe.transform(endpointProtocol);
    }
  }
}
