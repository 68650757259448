import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isDefined } from '@cp/common/protocol/Common';
import { Organization, OrganizationUser } from '@cp/common/protocol/Organization';
import { getPathWithNoQueryPartFromUrl } from '@cp/common/utils/MiscUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { InternalSidebarButton } from '@cp/web/app/common/components/internal-sidebar/internal-sidebar.component';
import {
  checkIfPathBelongsToPage,
  installOrganizationIdPageParameterHandler,
  PageComponentWithOrganizationId
} from '@cp/web/app/organizations/current-organization.helper';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminComponent extends OnDestroyComponent implements PageComponentWithOrganizationId {
  readonly organizationObs: Observable<Organization>;
  readonly numberOfAdminsObs: Observable<number>;
  readonly myOrgUserObs: Observable<OrganizationUser>;

  internalSidebarMenuButtons: Array<InternalSidebarButton> = [
    {
      id: 'GENERAL',
      name: 'General details',
      link: getAdminPathByTab('GENERAL')
    },
    {
      id: 'BILLING',
      name: 'Billing',
      link: getAdminPathByTab('BILLING'),
      minRole: 'ADMIN'
    },
    {
      id: 'USAGE',
      name: 'Usage',
      link: getAdminPathByTab('USAGE'),
      minRole: 'ADMIN'
    }
  ];

  constructor(
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly organizationStateService: OrganizationStateService
  ) {
    super();
    installOrganizationIdPageParameterHandler(this);
    this.organizationObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeOrganization(id))
    );
    this.numberOfAdminsObs = this.organizationObs.pipe(
      filter(isDefined),
      map((organization) => Object.values(organization.users).filter((u) => u.role === 'ADMIN').length)
    );

    this.myOrgUserObs = this.organizationStateService.observeCurrentOrganizationUser();
  }

  getActiveTab(): AdminTab {
    return getAdminTabByPath(this.router.url);
  }

  /** Returns true if the given path part or the URL is handled by the current page component. */
  static isPagePath(path: string): boolean {
    return checkIfPathBelongsToPage(path, 'organizations', 'admin', '/organization/admin');
  }

  buildCanonicalPagePathWithOrganizationId(id: string, currentRouterUrl: string): string {
    return AdminComponent.buildCanonicalPagePath(id, currentRouterUrl);
  }

  static buildCanonicalPagePath(id: string, currentRouterUrl: string): string {
    // Try to restore current active tab from the 'currentRouterUrl'.
    const tokens = currentRouterUrl.split('/');
    const tabTokenIndex =
      tokens[1] === 'organization' && tokens[2] === 'admin' && tokens.length === 4
        ? 3 // no-id case.
        : tokens[1] === 'organizations' && tokens[3] === 'admin' && tokens.length === 5
        ? 4 // with id case.
        : -1;
    switch (tokens[tabTokenIndex]) {
      case 'billing':
        return `/organizations/${id}/admin/billing`;
      case 'usage':
        return `/organizations/${id}/admin/usage`;
      default:
        break;
    }
    return `/organizations/${id}/admin`;
  }
}

/** Active tab on the admin page. */
export type AdminTab = 'GENERAL' | 'BILLING' | 'USAGE';

/** Returns active admin page tab by the Angular router URL. */
function getAdminTabByPath(routerUrl: string): AdminTab {
  const path = getPathWithNoQueryPartFromUrl(routerUrl);
  if (checkIfPathBelongsToPage(path, 'organizations', 'admin/billing', '/organization/admin/billing')) {
    return 'BILLING';
  } else if (checkIfPathBelongsToPage(path, 'organizations', 'admin/usage', '/organization/admin/usage')) {
    return 'USAGE';
  }
  return 'GENERAL';
}

/** Returns URL's path for the given admin tab. */
export function getAdminPathByTab(tab: AdminTab): string {
  switch (tab) {
    case 'BILLING':
      return '/organization/admin/billing';
    case 'USAGE':
      return '/organization/admin/usage';
  }
  return '/organization/admin';
}
