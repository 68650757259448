import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IpAccessListEntry } from '@cp/common/protocol/Instance';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';

@Component({
  templateUrl: './delete-ip-access-list-entry-dialog.component.html',
  styleUrls: ['./delete-ip-access-list-entry-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteIpAccessListEntryDialogComponent {
  /** True after 'Delete' button is clicked and before the component receives the response. */
  isSubmitInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DeleteIpAccessListEntryDialogInput,
    readonly dialogRef: MatDialogRef<unknown>,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly snackBar: MatSnackBar
  ) {}

  async delete(): Promise<void> {
    const { instanceId } = this.data;
    try {
      this.isSubmitInProgress = true;
      const instance = this.instanceStateService.getInstanceOrFail(instanceId);
      const newIpAccessList = instance.ipAccessList.filter((e) => e.source !== this.data.ipAccessListEntry.source);
      await this.instanceService.updateIpAccessList(instanceId, newIpAccessList);
      this.snackBar.open('Your IP address has been removed', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      this.snackBar.open('Failed to delete entry', 'Dismiss', { duration: 5000 });
    } finally {
      this.isSubmitInProgress = false;
    }
  }
}

export function getDeleteIpAccessListEntryDialogConfig(): Partial<MatDialogConfig<DeleteIpAccessListEntryDialogInput>> {
  return {
    width: '100%',
    maxWidth: '517px',
    autoFocus: true,
    restoreFocus: false,
    panelClass: 'modal'
  };
}

export interface DeleteIpAccessListEntryDialogInput {
  organizationId: string;
  instanceId: string;
  ipAccessListEntry: IpAccessListEntry;
}
