import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  AllocatedMemoryMetricReportSummary,
  InsertQpsMetricReportSummary,
  MetricArgs,
  MetricReport,
  MetricType,
  ReadThroughputMetricReportSummary,
  ResidentMemoryUsageMetricReportSummary,
  RowCountMetricReportSummary,
  S3StorageUsageMetricReportSummary,
  SelectQpsMetricReportSummary,
  SqlStatementsPerTypeMetricReportSummary,
  SuccessfulQueriesMetricReportSummary,
  TimePeriod,
  WriteThroughputMetricReportSummary
} from '@cp/common/protocol/Metrics';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { formatDataSize } from '@cp/web/app/common/pipes/data-size.pipe';
import { formatTimePeriod } from '@cp/web/app/common/pipes/time-period.pipe';

@Component({
  selector: 'cp-metric-summary',
  templateUrl: './metric-summary.component.html',
  styleUrls: ['./metric-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricSummaryComponent implements OnInit, OnChanges {
  /**
   * Metrics report with a summary.
   * When the summary is undefined the component shows a loading state.
   */
  @Input() summaryInput!: MetricSummaryInput;

  readonly naText = 'N/A';

  readonly loadingText = 'Loading...';

  get timePeriod(): TimePeriod {
    return this.query.timePeriod;
  }

  get query(): MetricArgs {
    return this.summaryInput.query;
  }

  get type(): MetricType {
    return this.summaryInput.query.type;
  }

  get report(): MetricReport | undefined {
    return this.summaryInput.report;
  }

  get isError(): boolean {
    return this.summaryInput.isError;
  }

  get isLoading(): boolean {
    return !this.report && !this.summaryInput.isError;
  }

  get s3StorageUsageReportSummary(): S3StorageUsageMetricReportSummary | undefined {
    assertTruthy(this.type === 'S3_STORAGE_USAGE');
    return this.reportSummary;
  }

  get allocatedMemoryUsageReportSummary(): AllocatedMemoryMetricReportSummary | undefined {
    assertTruthy(this.type === 'ALLOCATED_MEMORY');
    return this.reportSummary;
  }

  get residentMemoryUsageReportSummary(): ResidentMemoryUsageMetricReportSummary | undefined {
    assertTruthy(this.type === 'RESIDENT_MEMORY_USAGE');
    return this.reportSummary;
  }

  get successfulQueriesReportSummary(): SuccessfulQueriesMetricReportSummary | undefined {
    assertTruthy(this.type === 'SUCCESSFUL_QUERIES');
    return this.reportSummary;
  }

  get readThroughputMetricReportSummary(): ReadThroughputMetricReportSummary | undefined {
    assertTruthy(this.type === 'READ_THROUGHPUT');
    return this.reportSummary;
  }

  get rowCountMetricReportSummary(): RowCountMetricReportSummary | undefined {
    assertTruthy(this.type === 'ROW_COUNT');
    return this.reportSummary;
  }

  get selectQpsMetricReportSummary(): SelectQpsMetricReportSummary | undefined {
    assertTruthy(this.type === 'SELECT_QPS');
    return this.reportSummary;
  }

  get insertQpsMetricReportSummary(): InsertQpsMetricReportSummary | undefined {
    assertTruthy(this.type === 'INSERT_QPS');
    return this.reportSummary;
  }

  get writeThroughputMetricReportSummary(): WriteThroughputMetricReportSummary | undefined {
    assertTruthy(this.type === 'WRITE_THROUGHPUT');
    return this.reportSummary;
  }

  get sqlStatementsPerTypeMetricReportSummary(): SqlStatementsPerTypeMetricReportSummary | undefined {
    assertTruthy(this.type === 'SQL_STATEMENTS_PER_TYPE');
    return this.reportSummary;
  }

  /** See https://user-images.githubusercontent.com/305167/166716722-f6d05225-d7dd-4bb8-8adb-880fdb5e3d02.png. */
  get s3StorageUsageReportSummaryDeltaText(): string {
    const summary = truthy(this.s3StorageUsageReportSummary);
    return this.getSummaryBytesDeltaText(summary.deltaUsageInBytes);
  }

  get memoryUsageReportSummaryDeltaText(): string {
    const summary = truthy(this.residentMemoryUsageReportSummary);
    return this.getSummaryBytesDeltaText(summary.deltaUsageInBytes);
  }

  get allocatedMemoryReportSummaryDeltaText(): string {
    const summary = truthy(this.allocatedMemoryUsageReportSummary);
    return this.getSummaryBytesDeltaText(summary.deltaMemoryInBytes);
  }

  private getSummaryBytesDeltaText(deltaUsageInBytes: number): string {
    const deltaText =
      deltaUsageInBytes === 0
        ? 'No change'
        : (deltaUsageInBytes > 0 ? 'Increase of ' : 'Decrease of ') + formatDataSize(Math.abs(deltaUsageInBytes));
    const timeText = `in ${formatTimePeriod(this.timePeriod).toLowerCase()}`;
    return `${deltaText} ${timeText}`;
  }

  /** Returns report?.summary. */
  private get reportSummary(): any {
    return (this.report as any)?.summary;
  }

  ngOnInit(): void {
    this.checkRequiredInputs();
  }

  ngOnChanges(): void {
    this.checkRequiredInputs();
  }

  checkRequiredInputs(): void {
    assertTruthy(!this.report || this.query.type === this.report.type);
    // Check that report has a summary or there is no report at all (summary is loading).
    assertTruthy(!this.report || (this.report as any).summary);
  }
}

export interface MetricSummaryInput {
  query: MetricArgs;
  report: MetricReport | undefined;
  isError: boolean;
}
