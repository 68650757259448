import { Injectable } from '@angular/core';
import { UsageMetric } from '@cp/common/protocol/Billing';
import { AdminUsageApiService } from '@cp/web/app/admin/admin-usage/admin-usage-api.service';
import { AdminUsageStateService } from '@cp/web/app/admin/admin-usage/admin-usage-state.service';

@Injectable({
  providedIn: 'root'
})
export class AdminUsageService {
  constructor(
    private readonly adminUsageApiService: AdminUsageApiService,
    private readonly adminUsageStateService: AdminUsageStateService
  ) {}

  /** Refreshes current usage report, billing dates and chart. */
  async refreshAdminUsageReport(
    organizationId: string,
    billDate: string | undefined,
    chartMetric: UsageMetric
  ): Promise<boolean> {
    try {
      const { billDates, report, chart, callAgainSoon } = await this.adminUsageApiService.getAdminUsageReport(
        organizationId,
        billDate,
        chartMetric
      );
      this.adminUsageStateService.setOrganizationBillDates(organizationId, billDates);
      this.adminUsageStateService.setOrganizationUsageReport(organizationId, billDate || report.billDate, report);
      this.adminUsageStateService.setOrganizationUsagePeriodChart(
        organizationId,
        billDate || report.billDate,
        chartMetric,
        chart
      );
      return callAgainSoon;
    } catch (error) {
      console.error(error);
      if (billDate) {
        // Set errors in local state.
        // Do not overwrite valid dates with null state (error state) if we failed to fetch the report.
        this.adminUsageStateService.setOrganizationBillDates(organizationId, null, 'keep-non-null-value-if-present');
        this.adminUsageStateService.setOrganizationUsageReport(organizationId, billDate, null);
        this.adminUsageStateService.setOrganizationUsagePeriodChart(organizationId, billDate, chartMetric, null);
      } else {
        // Failed to fetch bill dates. Set 'null' (error) instead of 'undefined'.
        this.adminUsageStateService.setOrganizationBillDates(organizationId, null, 'keep-non-null-value-if-present');
      }
    }
    return false;
  }

  async refreshAdminUsageChart(organizationId: string, billDate: string, chartMetric: UsageMetric): Promise<void> {
    try {
      const { chart } = await this.adminUsageApiService.getAdminUsageChart(organizationId, billDate, chartMetric);
      this.adminUsageStateService.setOrganizationUsagePeriodChart(organizationId, billDate, chartMetric, chart);
    } catch (error) {
      this.adminUsageStateService.setOrganizationUsagePeriodChart(organizationId, billDate, chartMetric, null);
    }
  }
}
