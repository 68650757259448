import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import { isDefined } from '@cp/common/protocol/Common';
import {
  DUPLICATE_INSTANCE_NAME,
  getAllowAnywhereIpAccessList,
  Instance,
  INSTANCE_LIMIT_REACHED,
  INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED,
  InstanceCustomerManagedEncryptionConfig,
  InstanceTier,
  IpAccessListEntry
} from '@cp/common/protocol/Instance';
import { OrganizationBillingStatus } from '@cp/common/protocol/Organization';
import { PendingUserAction } from '@cp/common/protocol/PendingUserActions';
import { isAwsRegionId } from '@cp/common/protocol/Region';
import { truthy } from '@cp/common/utils/Assert';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { trace } from '@cp/web/app/common/services/galaxy.service';
import { PendingUserActionsService } from '@cp/web/app/common/services/pending-user-actions.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { CreateInstanceUiService } from '@cp/web/app/instances/create-instance/create-instance-ui.service';
import {
  checkInstanceTierAndRegionChanged,
  getAutoscalingConfig
} from '@cp/web/app/instances/create-instance/create-instance.component';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { CreateInstanceFormDetails, CreateInstanceUiState } from '@cp/web/app/instances/protocol/InstanceStates';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { environment } from '@cp/web/environments/environment';
import { distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

/** Enum representing the current stage of the onboarding. */
type OnboardingStage = 'CREATE_INSTANCE' | 'SECURITY' | 'SERVICE_DETAILS';
type SidebarState = 'CLOSED' | 'AUTO_SCALING' | 'CUSTOMER_MANAGED_ENCRYPTION';

@Component({
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  stage: OnboardingStage = 'CREATE_INSTANCE';

  // Create Instance
  public readonly createInstanceForm: FormGroup = new FormGroup({
    autoScalingDetails: new FormControl({ value: null, disabled: true }),
    customerManagedEncryption: new FormControl({ value: null, disabled: false })
  });
  readonly createInstanceUiStateObs: Observable<CreateInstanceUiState>;
  readonly accountName = this.accountStateService.observeUserDetails().pipe(
    filter(Boolean),
    map((userDetails) => userDetails.name)
  );
  readonly cmekEnabledObs = this.organizationStateService
    .observeCurrentOrganization()
    .pipe(map((organization) => organization.features.includes('FT_ORG_CUSTOMER_MANAGED_ENCRYPTION')));

  instanceId?: string;
  instanceObs?: Observable<Instance>;
  instancePasswordObs?: Observable<string>;

  // Ip Access list
  ipAccessList: Array<IpAccessListEntry> = [];
  ipListSelected = false;
  isIpListSubmitInProgress = false;
  anywhereIp = false;

  readonly regionBlockedObs: Observable<boolean | undefined>;

  private callBackEventHandle = () => {
    history.pushState(null, '', window.location.href);
  };

  sidebarState: SidebarState = 'CLOSED';
  billingStatusObs: Observable<OrganizationBillingStatus>;
  autoScalingLimits?: GetAutoScalingLimitsResponse;

  customerEncryptionValid = true;

  constructor(
    private readonly createInstanceUiService: CreateInstanceUiService,
    private readonly instanceService: InstanceService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly accountStateService: AccountStateService,
    private readonly instanceStateService: InstanceStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly segmentService: SegmentService,
    private readonly pendingActionsService: PendingUserActionsService
  ) {
    super();
    // Preventing back button in browser - back button would throw the user from the onboarding wizard - https://github.com/ClickHouse/control-plane/issues/2073
    history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', this.callBackEventHandle);
    this.createInstanceUiStateObs = createInstanceUiService.observeCreateInstanceUiState();
    this.regionBlockedObs = this.accountStateService
      .observeUserDetails()
      .pipe(
        filter(Boolean),
        map((userDetails) => userDetails.regionBlocked)
      )
      .pipe(distinctUntilChanged());
    createInstanceUiService.setPartialState({ createInstanceButtonDisabled: false, errorMessage: undefined });

    this.billingStatusObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((orgId) => this.organizationStateService.observeBillingStatus(orgId))
    );
  }

  ngOnInit() {
    trace('pageLoad', 'window', { namespace: 'app', event: 'pageLoad', stage: this.stage });

    this.createInstanceForm.valueChanges
      .pipe(
        takeUntil(this.onDestroy),
        filter((value) => isDefined(value.instanceDetails?.region?.id)),
        distinctUntilChanged(checkInstanceTierAndRegionChanged)
      )
      .subscribe(async (value) => {
        if (!INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(value.instanceDetails.tier)) {
          this.sidebarState = 'CLOSED';
        }
        if (this.customerManagedEncryptionOpen && !this.cmekAllowedInRegion) {
          this.sidebarState = 'CLOSED';
        }
        this.autoScalingLimits = await this.instanceService.getDpAutoScalingLimits(value.instanceDetails.region.id);

        this.cdr.markForCheck();
      });
  }

  setStage(stage: OnboardingStage): void {
    this.stage = stage;
    trace('pageLoad', 'window', { namespace: 'app', event: 'pageLoad', stage: this.stage });
  }

  getCustomerManagedEncryptionConfig(tier: InstanceTier): InstanceCustomerManagedEncryptionConfig | undefined {
    const customerManagedEncryptionConfig: InstanceCustomerManagedEncryptionConfig | undefined =
      this.createInstanceForm.value['customerManagedEncryption'] ?? undefined;

    if (
      !customerManagedEncryptionConfig?.keyArn ||
      !INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(tier) ||
      !this.cmekAllowedInRegion
    ) {
      return undefined;
    } else {
      return customerManagedEncryptionConfig;
    }
  }

  async onSubmitCreateInstance(): Promise<void> {
    if (this.stage !== 'CREATE_INSTANCE') {
      return;
    }
    if (this.createInstanceForm.invalid) {
      return;
    }

    this.createInstanceUiService.setPartialState({ createInstanceButtonDisabled: true, errorMessage: undefined });
    const autoScalingConfig = getAutoscalingConfig(
      this.createInstanceForm,
      this.accountStateService.getUserDetailsOrFail().features
    );
    const details = this.createInstanceForm.value.instanceDetails as CreateInstanceFormDetails;
    const customerManagedEncryptionConfig = this.getCustomerManagedEncryptionConfig(details.tier);

    try {
      this.instanceId = await this.instanceService.createInstance(
        details.name.trim(),
        details.region.id,
        this.organizationStateService.getCurrentOrgIdOrFail(),
        [],
        details.tier,
        'onboarding',
        details.gcpTermsChecked,
        autoScalingConfig,
        customerManagedEncryptionConfig
      );
      const pendingActionsToAdd: Array<PendingUserAction> = [];
      if (environment.stage !== 'dev') {
        pendingActionsToAdd.push({ type: 'entry-questionnaire' });
      }
      // Remove onboarding flow only after the user creates a service and add entry-questionnaire as pending action.
      this.accountService.updateUserDetails({ pendingActionsToAdd, pendingActionTypesToRemove: ['onboarding'] }).then();
      this.instanceObs = this.instanceStateService.observeInstance(this.instanceId).pipe(filter(Boolean));
      this.instancePasswordObs = this.instanceStateService
        .observeInstancePassword(this.instanceId)
        .pipe(map((password) => password || ''));
      this.setStage('SECURITY');
    } catch (e: any) {
      console.error(e);
      let errorMessage;
      const serverErrorMessage = getServerErrorMessage(e);
      switch (serverErrorMessage) {
        case DUPLICATE_INSTANCE_NAME:
          errorMessage = 'A service with this name already exists, please choose another';
          break;
        case INSTANCE_LIMIT_REACHED:
          errorMessage = `You've reached the maximum amount of services allowed per organization`;
          break;
        default:
          errorMessage = 'Error occurred when creating a service';
          break;
      }
      this.createInstanceUiService.setStateKey('errorMessage', errorMessage);
    } finally {
      this.createInstanceUiService.setStateKey('createInstanceButtonDisabled', false);
    }
  }

  onIpListChange(ipAccessListOrAnywhere: Array<IpAccessListEntry> | true): void {
    this.ipListSelected = true;
    this.ipAccessList = ipAccessListOrAnywhere === true ? [] : ipAccessListOrAnywhere;
    this.anywhereIp = ipAccessListOrAnywhere === true;
  }

  async onFinishIpList(): Promise<void> {
    const instanceId = truthy(this.instanceId);
    try {
      this.isIpListSubmitInProgress = true;
      const effectiveIpAccessList = this.anywhereIp ? getAllowAnywhereIpAccessList() : this.ipAccessList;
      await this.instanceService.updateIpAccessList(instanceId, effectiveIpAccessList);

      this.setStage('SERVICE_DETAILS');

      this.cdr.markForCheck();
      this.segmentService.trackGaEvent({
        event: 'click',
        label: 'Continue IP Filter',
        category: 'onboarding',
        view: 'onboarding',
        component: 'serviceCard'
      });
    } catch (e) {
      console.error(e);
      this.snackBar.open('Failed to update access list', 'Dismiss', { duration: 5000 });
    } finally {
      this.isIpListSubmitInProgress = false;
    }
  }

  async finish() {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: `I've copied my password`,
      category: 'onboarding',
      view: 'onboarding',
      component: 'serviceCard'
    });
    await this.pendingActionsService.triggerPendingActionByType('entry-questionnaire');
    await this.router.navigateByUrl(`/service/${this.instanceId}/connection`);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    window.removeEventListener('popstate', this.callBackEventHandle);
  }

  /**
   * Handles the click event of the "Continue Anyway" button
   * if the user is in a blocked region.
   */
  onContinueClicked(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'Continue Anyway (region block)',
      category: 'onboarding',
      view: 'onboarding',
      component: 'serviceCard'
    });
    this.router.navigateByUrl(`/services`);
  }

  toggleSidebar(state: SidebarState): void {
    if (this.sidebarState === state) {
      this.sidebarState = 'CLOSED';
    } else {
      this.sidebarState = state;
    }
  }

  get autoScalingSettingsOpen(): boolean {
    return this.sidebarState === 'AUTO_SCALING';
  }

  get customerManagedEncryptionOpen(): boolean {
    return this.sidebarState === 'CUSTOMER_MANAGED_ENCRYPTION';
  }

  get sidebarOpen(): boolean {
    return this.sidebarState !== 'CLOSED';
  }

  setCustomerEncryptionValid(isValid: boolean) {
    this.customerEncryptionValid = isValid;
    this.cdr.markForCheck();
  }

  get canSubmitCreateInstance(): boolean {
    return !this.createInstanceForm.invalid && this.customerEncryptionValid;
  }

  get cmekAllowedInRegion(): boolean {
    const regionId = this.createInstanceForm.value.instanceDetails.region?.id;
    return isAwsRegionId(regionId);
  }
}
