<ng-container *ngIf="userOrgRoleObs | async as userOrgRole">
  <ng-container *ngIf="instanceObs | async as instance">
    <cp-ip-access-list-table-view
      [ipAccessList]="ipAccessList"
      eventPrefix="servicePage.security"
      [actionButtons]="userOrgRole === 'ADMIN' ? ['EDIT', 'DELETE']: []"
      (editButtonClick)="showEditFilterDialog($event)"
      (deleteButtonClick)="showDeleteFilterDialog($event)"
    ></cp-ip-access-list-table-view>
  </ng-container>
</ng-container>
