<ng-container *ngIf="instanceObs | async as instance">
  <div *ngIf="instance && checkHasNoIpAccess(instance)" class="no_access_prompt" data-cy="no-access-prompt">
    <div class="no_access_icon_circle">
      <mat-icon svgIcon="info_icon" class="no_access_icon"></mat-icon>
    </div>
    <div>
      No IP access set -
      <a [routerLink]="getInstancePageSecurityTabPath(instance.id)"
         fragment="networking"
         data-cy="security-tab-link"
      >Update</a>
    </div>
  </div>
</ng-container>
