<form (ngSubmit)="onSubmit()" [formGroup]="addressForm" class="form" data-cy="change-billing-address-form">
  <div class="scrollable_wrapper padded_content">

    <div class="seed_form_element">
      <label>Billing address</label>
      <input
        [galaxyClick]="buildFullyQualifiedEvent('addressInputFocus')"
        [galaxyBlur]="buildFullyQualifiedEvent('addressInputBlur')"
        formControlName="billingLine1"
        name="billingLine1"
        placeholder="Address line 1"
        data-cy="billing-line-1"/>
      <input
        [galaxyClick]="buildFullyQualifiedEvent('address2InputFocus')"
        [galaxyBlur]="buildFullyQualifiedEvent('address2InputBlur')"
        formControlName="billingLine2"
        name="billingLine2"
        class="line2"
        placeholder="Address line 2 (optional)"
        data-cy="billing-line-2"/>
      <div class="seed_error" data-cy="billing-line-2-error"
           *ngIf="!addressForm.controls['billingLine1']?.valid && addressForm.controls['billingLine1']?.touched">
        Please enter a valid street address
      </div>
    </div>

    <div class="split_row">
      <div class="seed_form_element">
        <label>City</label>
        <input
          [galaxyClick]="buildFullyQualifiedEvent('cityInputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('cityInputBlur')"
          formControlName="billingCity"
          name="billingCity"
          placeholder=""
          data-cy="billing-city"/>
        <div *ngIf="!addressForm.controls['billingCity']?.valid && addressForm.controls['billingCity']?.touched"
             class="seed_error" data-cy="billing-city-error">
          Please enter a valid city
        </div>
      </div>

      <div class="seed_form_element">
        <label>ZIP or Postal code</label>
        <input
          spellcheck="false"
          [galaxyClick]="buildFullyQualifiedEvent('zipInputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('zipInputBlur')"
          formControlName="billingPostalCode"
          name="billingPostalCode"
          placeholder=""
          data-cy="billing-postal-code"/>
        <div
          *ngIf="!addressForm.controls['billingPostalCode']?.valid && addressForm.controls['billingPostalCode']?.touched"
          class="seed_error" data-cy="billing-postal-code-error">
          Please enter a valid ZIP or postal code
        </div>
      </div>
    </div>

    <div class="split_row">
      <div class="seed_form_element">
        <label>Country</label>
        <mat-form-field appearance="outline" class="country_select">
          <mat-select
            [galaxyClick]="buildFullyQualifiedEvent('countrySelectorFocus')"
            [galaxyBlur]="buildFullyQualifiedEvent('countrySelectorBlur')"
            [galaxyChange]="buildFullyQualifiedEvent('countrySelectorSelect')"
            formControlName="billingCountry"
            data-cy="billing-country-select"
            [typeaheadDebounceInterval]="500">
            <mat-option *ngFor="let country of countries"
                        [value]="country.value"
                        [attr.data-cy]="'billing-' + country.dataCy"
                        [attr.data-cy-value]="country.dataCyValue"
            >{{country.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="!addressForm.controls['billingCountry']?.valid && addressForm.controls['billingCountry']?.touched"
             class="seed_error" data-cy="billing-country-select-error"
        >
          Please enter a valid country
        </div>
      </div>

      <div class="seed_form_element">
        <label>State / County / Province</label>

        <mat-form-field *ngIf="getStates('billing').length > 0" appearance="outline" class="state_select">
          <mat-select formControlName="billingState" data-cy="billing-state-select" [typeaheadDebounceInterval]="500">
            <mat-option *ngFor="let state of getStates('billing')"
                        [value]="state.value"
                        [attr.data-cy]="'billing-' + state.dataCy"
                        [attr.data-cy-value]="state.dataCyValue"
            >{{state.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="getStates('billing').length === 0" appearance="outline" class="state_select"
                        data-cy="billing-state-field" disabled="true">
          <mat-select data-cy="billing-state-select" disabled="true" placeholder="N/A">
          </mat-select>
        </mat-form-field>

        <div *ngIf="!addressForm.controls['billingState']?.valid && addressForm.controls['billingState']?.touched"
             class="seed_error" ata-cy="billing-state-select-error">
          Please enter a valid State / County / Province
        </div>
      </div>
    </div>

    <mat-checkbox
      [galaxyClick]="buildFullyQualifiedEvent(shippingAddressSameAsBilling ? 'sameAddressUncheck' : 'sameAddressCheck')"
      (change)="toggleShippingAddressSameAsBilling()"
      [checked]="shippingAddressSameAsBilling"
      color="primary"
      class="both_addresses_same_checkbox"
      data-cy="shipping-address-same-as-billing">Shipping address same as billing address
    </mat-checkbox>

    <div *ngIf="!shippingAddressSameAsBilling" class="shippingAddress">
      <div class="seed_form_element">
        <label>Shipping address</label>
        <input
          [galaxyClick]="buildFullyQualifiedEvent('bizAddressInputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('bizAddressInputBlur')"
          data-cy="shipping-line-1"
          formControlName="shippingLine1"
          name="shippingLine1"
          placeholder="Address line 1">
        <input
          [galaxyClick]="buildFullyQualifiedEvent('bizAddress2InputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('bizAddress2InputBlur')"
          class="line2"
          data-cy="shipping-line-2"
          formControlName="shippingLine2"
          name="shippingLine2"
          placeholder="Address line 2 (optional)">
        <div *ngIf="!addressForm.controls['shippingLine1']?.valid && addressForm.controls['shippingLine1']?.touched"
             data-cy="shipping-line-2-error"
             class="seed_error">
          Please enter a valid street address
        </div>
      </div>

      <div class="split_row">
        <div class="seed_form_element">
          <label>City</label>
          <input
            [galaxyClick]="buildFullyQualifiedEvent('bizCityInputFocus')"
            [galaxyBlur]="buildFullyQualifiedEvent('bizCityInputBlur')"
            data-cy="shipping-city"
            formControlName="shippingCity"
            name="shippingCity"
            placeholder="">
          <div *ngIf="!addressForm.controls['shippingCity']?.valid && addressForm.controls['shippingCity']?.touched"
               class="seed_error" data-cy="shipping-city-error">
            Please enter a valid city
          </div>
        </div>

        <div class="seed_form_element">
          <label>ZIP or Postal code</label>
          <input
            spellcheck="false"
            [galaxyClick]="buildFullyQualifiedEvent('bizZipInputFocus')"
            [galaxyBlur]="buildFullyQualifiedEvent('bizZipInputBlur')"
            data-cy="shipping-postal-code"
            formControlName="shippingPostalCode"
            name="shippingPostalCode"
            placeholder="">
          <div
            *ngIf="!addressForm.controls['shippingPostalCode']?.valid && addressForm.controls['shippingPostalCode']?.touched"
            class="seed_error" data-cy="shipping-postal-code-error">
            Please enter a valid ZIP or postal code
          </div>
        </div>
      </div>

      <div class="split_row">

        <div class="seed_form_element">
          <label>Country</label>
          <mat-form-field appearance="outline" class="country_select" data-cy="shipping-country-field">
            <mat-select
              [galaxyClick]="buildFullyQualifiedEvent('bizCountrySelectorFocus')"
              [galaxyBlur]="buildFullyQualifiedEvent('bizCountrySelectorBlur')"
              [galaxyChange]="buildFullyQualifiedEvent('bizCountrySelectorSelect')"
              data-cy="shipping-country-select"
              formControlName="shippingCountry"
              [typeaheadDebounceInterval]="500">
              <mat-option *ngFor="let country of countries"
                          [attr.data-cy-value]="country.dataCyValue"
                          [attr.data-cy]="'shipping-' + country.dataCy"
                          [value]="country.value"
              >{{country.label}}</mat-option>
            </mat-select>
          </mat-form-field>

          <div
            *ngIf="!addressForm.controls['shippingCountry']?.valid && addressForm.controls['shippingCountry']?.touched"
            class="seed_error" data-cy="shipping-country-select-error">
            Please enter a valid country
          </div>
        </div>

        <div class="seed_form_element">
          <label>State / County / Province</label>


          <mat-form-field *ngIf="getStates('shipping').length > 0" appearance="outline" class="state_select"
                          data-cy="shipping-state-field">
            <mat-select data-cy="shipping-state-select" formControlName="shippingState" [typeaheadDebounceInterval]="500">
              <mat-option *ngFor="let state of getStates('shipping')"
                          [attr.data-cy-value]="state.dataCyValue"
                          [attr.data-cy]="'shipping-' + state.dataCy"
                          [value]="state.value"
              >{{state.label}}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field *ngIf="getStates('shipping').length === 0" appearance="outline" class="state_select"
                          data-cy="shipping-state-field" disabled="true">
            <mat-select data-cy="shipping-state-select" disabled="true" placeholder="N/A">
            </mat-select>
          </mat-form-field>

          <div *ngIf="!addressForm.controls['shippingState']?.valid && addressForm.controls['shippingState']?.touched"
               class="seed_error" data-cy="shipping-state-select-error">
            Please enter a valid State / County / Province
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="vertical_buttons padded_content">
    <button
      [galaxyClick]="buildFullyQualifiedEvent('companyDetailsButtonSelect')"
      [disabled]="addressForm.invalid || formInput?.buttonDisabled"
      class="main_action"
      color="primary" data-cy="company-or-payment-update-button" mat-flat-button
      type="submit"
    >{{customSubmitText || 'Company details ->'}}
    </button>
    <button
      *ngIf="isCancelable"
      galaxyClick="billing.modal.cancelButtonSelect"
      data-cy="company-or-payment-back-button"
      [mat-dialog-close]="false" class="secondary_action" mat-stroked-button>Cancel</button>
    <cw-system-message *ngIf="formInput?.errorMessage" [message]="formInput?.errorMessage"></cw-system-message>
  </div>
</form>
