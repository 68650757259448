<mat-dialog-content *ngIf="uiStateObs | async as uiState" [attr.data-cy]="'send-invites-dialog'"
                    class="send_invites_dialog">
  <ng-container *ngIf="!requestSubmitted">
    <div class="title_row">
      <div class="seed_h3">Invite members</div>
      <div class="close_icon_wrapper">
        <cp-dialog-close-button [dialogRef]="dialogRef"
                                [fontSize]="16"
                                [height]="16"
                                [width]="14"
        ></cp-dialog-close-button>
      </div>
    </div>
    <div class="subtitle">Members have access to the ClickHouse Cloud console where they can view or manage services,
      members, billing, usage, and API keys.
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="sendOrgInvitesForm" class="form">
      <div class="inputs_grid">
        <div class="column_title">Invite members</div>
        <div class="column_title">Role</div>
      </div>
      <div *ngFor="let ind of times(emailCount)" class="inputs_grid">
        <div class="seed_form_element">
          <input [attr.data-cy]="'email-input-' + ind"
                 class="seed_input invite_form_element"
                 formControlName="email_{{ind}}"
                 name="email"
                 placeholder="Email address"
                 type="email">
          <ng-container *ngIf="sendOrgInvitesForm.controls['email_' + ind] as emailControl">
            <div *ngIf="!emailControl.valid && emailControl.touched && emailControl.errors" class="seed_error" [attr.data-cy]="'email-control-error-' + ind">
              <p *ngIf="emailControl.errors['invalidEmail']">Invalid e-mail address</p>
              <p *ngIf="emailControl.errors['duplicateEmail']">Duplicated email</p>
              <p *ngIf="emailControl.errors['duplicatePendingEmail']">There's already pending invitation with that email</p>
              <p *ngIf="emailControl.errors['existingUser']">There's already an existing user with that email</p>
            </div>
          </ng-container>

        </div>
        <div class="seed_form_element">
          <seed-select [attr.data-cy]="'role-select-' + ind"
                       [options]="roles"
                       [template]="roleSelectOption"
                       [withDivider]="true"
                       label="Select role"
                       class="role_select invite_form_element"
                       formControlName="role_{{ind}}"
          >
          </seed-select>
        </div>
      </div>
      <button (click)="addNewEmailControl()" class="add_button" data-cy="add-new-email-button" mat-stroked-button
              type="button">
        + Add member
      </button>

      <div class="system_message_wrapper">
        <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"
                           data-cy="form-error"></cw-system-message>
      </div>

      <div class="invite_members_alert" data-cy="invite-members-alert">
        <div class="marketplace_alert_info_icon">
          <mat-icon svgIcon="info_circle" class="invite_members_alert_icon"></mat-icon>
        </div>
        <div class="invite_members_alert_container">
          <p>Admins and developers are not database users. To create database users and roles, please use the
            <a href="" target="_blank">SQL console</a>. To learn more, visit our docs on
            <a href="https://clickhouse.com/docs/en/cloud/users-and-roles" target="_blank">Users and Roles.</a></p>
        </div>
      </div>

      <div class="buttons">
        <button [disabled]="!formIsValid || uiState.sendInvitesButtonDisabled"
                class="invite_button dialog_button"
                color="primary"
                data-cy="invite-button"
                mat-flat-button
                type="submit"
        >Send invites
        </button>
        <button (click)="dialogRef.close()" class="close_button dialog_button" data-cy="cancel-button" mat-button
                type="button">
          Cancel
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="requestSubmitted">
    <cp-dialog-submitted
      [dialogRef]="dialogRef"
      subtitle="Your invite(s) have now been sent to the emails specified. To accept their invitation,
      the recipient will need to click on the link in their email and follow the instructions provided."
      title="Invites have been sent!"
    ></cp-dialog-submitted>
  </ng-container>
</mat-dialog-content>


<ng-template #roleSelectOption let-item="item" let-isSelected="isSelected">
  <div class="role_label" [ngClass]="{bold: !isSelected, grey: isSelected && !item.value}">{{item.label}}</div>
  <div class="role_description" *ngIf="item.label === 'Admin' && !isSelected">Full access.</div>
  <div class="role_description" *ngIf="item.label === 'Developer' && !isSelected">Read only. Can’t access the
    database,<br>
    activity logs, billing, and usage. Can<br>
    create API keys with the Developer role.<br>
  </div>
</ng-template>
