import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { InstanceBackup } from '@cp/common/protocol/Backup';
import { Region, REGION_BY_ID } from '@cp/common/protocol/Region';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { MAX_INSTANCE_NAME_LENGTH, MIN_INSTANCE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import {
  instanceNameValidator,
  maxLengthTrimmedValidator,
  minLengthTrimmedValidator
} from '@cp/web/app/common/utils/FormValidationUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { FirstTimeSetIpAccessListDialogComponent } from '@cp/web/app/instances/ip-access-list/first-time-set-ip-access-list-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface RestoreInstanceBackupDialogData {
  backup: InstanceBackup;
}

@Component({
  selector: 'cp-restore-instance-backup-dialog',
  templateUrl: './restore-instance-backup-dialog.component.html',
  styleUrls: ['./restore-instance-backup-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestoreInstanceBackupDialogComponent extends OnDestroyComponent {
  readonly dialogForm: FormGroup;
  readonly region: Region;
  isRestoreButtonDisabled = false;
  errorMessage?: string;

  constructor(
    public dialogRef: MatDialogRef<RestoreInstanceBackupDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceService: InstanceService,
    private readonly organizationStateService: OrganizationStateService,
    @Inject(MAT_DIALOG_DATA) private readonly data: RestoreInstanceBackupDialogData,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    this.organizationStateService
      .observeNewServiceRestriction(organizationId)
      .pipe(
        takeUntil(this.onDestroy),
        filter((canCreate) => !canCreate)
      )
      .subscribe(() => this.dialogRef.close());

    const instance = this.instanceStateService.getInstanceOrFail(this.backup.instanceId);
    this.region = REGION_BY_ID[instance.regionId];
    const restoreSuffix = ' (Restore)';
    const defaultName = instance.name.slice(0, MAX_INSTANCE_NAME_LENGTH - restoreSuffix.length) + restoreSuffix;
    this.dialogForm = this.formBuilder.group({
      name: [
        defaultName,
        [
          Validators.required,
          minLengthTrimmedValidator(MIN_INSTANCE_NAME_LENGTH),
          maxLengthTrimmedValidator(MAX_INSTANCE_NAME_LENGTH),
          instanceNameValidator()
        ]
      ]
    });
  }

  get backup(): InstanceBackup {
    return this.data.backup;
  }

  async onSubmit(): Promise<void> {
    if (this.dialogForm.invalid) {
      return;
    }

    this.isRestoreButtonDisabled = true;
    this.errorMessage = undefined;
    const details: { name: string } = this.dialogForm.value;
    try {
      const { instanceId, ipAccessList } = await this.instanceService.restoreInstanceBackup(
        this.backup.id,
        this.backup.instanceId,
        details.name
      );
      this.dialogRef.close();
      this.snackBar.open('Service backup is being restored', 'Dismiss', { duration: 5000 });
      FirstTimeSetIpAccessListDialogComponent.show(this.dialog, instanceId, ipAccessList);
    } catch (e) {
      const serverErrorMessage = getServerErrorMessage(e);
      if (serverErrorMessage === 'DUPLICATE_INSTANCE_NAME') {
        this.errorMessage = 'A service with this name already exists, please choose another';
        return;
      }
      if (serverErrorMessage === 'INSTANCE_LIMIT_REACHED') {
        this.errorMessage = `You've reached the maximum amount of services allowed per organization`;
        return;
      }
      this.errorMessage = 'Error occurred when restoring a backup, please try again later';
    } finally {
      this.isRestoreButtonDisabled = false;
      this.cdr.markForCheck();
    }
  }

  static show(dialog: MatDialog, data: RestoreInstanceBackupDialogData): void {
    dialog.open(RestoreInstanceBackupDialogComponent, {
      width: '100%',
      maxWidth: '700px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal',
      data
    });
  }
}
