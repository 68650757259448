import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Instance } from '@cp/common/protocol/Instance';
import { truthy } from '@cp/common/utils/Assert';
import { InstanceService } from '@cp/web/app/instances/instance.service';

@Component({
  selector: 'cp-delete-instance-dialog',
  templateUrl: './delete-instance-dialog.component.html',
  styleUrls: ['./delete-instance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteInstanceDialogComponent {
  readonly instance: Instance;
  deleteInput: string = '';
  disableButton = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteInstanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { instance: Instance },
    private readonly instanceService: InstanceService,
    private readonly snackBar: MatSnackBar
  ) {
    this.instance = truthy(data.instance);
  }

  async confirmDeletion(): Promise<void> {
    this.disableButton = true;
    try {
      await this.instanceService.deleteInstance(this.instance.id);
      this.dialogRef.close();
    } catch (e: any) {
      console.error('Unable to delete instance', e);
      this.snackBar.open('Unable to delete service, please try again later', 'Dismiss');
    } finally {
      this.disableButton = false;
    }
  }

  isReadyToSubmit(): boolean {
    return this.deleteInput.toUpperCase().trim() === this.instance.name.toUpperCase().trim() && !this.disableButton;
  }
}
