<ng-container *ngVar="tickObs | async">
  <ng-container *ngIf="userOrgRoleObs | async as userOrgRole">
    <div *ngIf="backupsObs | async as backups" class="instance_backups_container">
      <div class="info_cards">
        <div class="info_card">
          <div class="name">Last successful backup</div>
          <div *ngVar="getLastSuccessfulBackupTime(backups) as lastSuccessfulBackupTime" class="data">
            {{lastSuccessfulBackupTime ? (lastSuccessfulBackupTime | date: 'medium') : 'N/A'}}
          </div>
        </div>
        <div *ngVar="nextScheduledBackupTimeObs | async as nextScheduledBackupTime" class="info_card">
          <div class="name">Next scheduled backup</div>
          <div *ngIf="nextScheduledBackupTime <= Date.now()" class="data">Shortly</div>
          <div *ngIf="nextScheduledBackupTime > Date.now()" class="data">
            {{(nextScheduledBackupTime | approximateTime)}}
          </div>
        </div>
        <div class="info_card">
          <div class="name">Backup frequency</div>
          <div class="data">Daily</div>
        </div>
      </div>
      <div *ngIf="!backups.length" class="no_backups_container" data-cy="no-backups-card">
        <mat-icon class="icon" svgIcon="no_backups"></mat-icon>
        <h3>No backups</h3>
        <div class="text">There are currently no backups for this service.</div>
        <div class="text">The first backup will be taken shortly.</div>
      </div>
      <div *ngIf="backups.length" class="backup_history">
        <h3>Backup history</h3>
        <div class="backup_history_table" data-cy="backup-history-table">
          <div class="row header_row">
            <div class="cell">Snapshot</div>
            <div class="cell">Status</div>
            <div class="cell">Started</div>
            <div class="cell">Finished</div>
            <div class="cell">Duration</div>
            <div class="cell header_actions">Actions</div>
          </div>
          <div *ngFor="let backup of backups; trackBy: trackById" class="row" data-cy="backup-row">
            <div class="cell snapshot" data-cy="backup-id"
                 [id]="backupDivIdPrefix + backup.id"
                 [matTooltipDisabled]="isBackupIdVisible(backup.id)"
                 [matTooltip]="backup.id"
            >
              {{backup.id}}
            </div>
            <div class="cell status"
                 data-cy="backup-status"
                 [attr.data-cy-finished]="backup.status === 'in_progress'?0:1">
              <cw-health-icon [icon]="convertToHealthIconType(backup.status)" class='health_icon'></cw-health-icon>
            </div>
            <div class="cell started">
              {{ backup.createdAt | date: 'medium'}}
            </div>
            <div class="cell finished">
              {{ backup.finished ? (backup.finished | date: 'medium') : '-'}}
            </div>
            <div class="cell duration">
              {{ getDurationString(backup) }}{{backup.finished ? '' : '...'}}
            </div>
            <div class="cell actions">
              <button *ngIf="userOrgRole === 'ADMIN'"
                      [galaxyClick]="['servicePage.backups.backupRestoreButtonSelect', {snapshot: backup.id}]"
                      (click)="showRestoreInstanceBackupDialog(backup)"
                      [disabled]="backup.status !== 'done'"
                      mat-icon-button
                      data-cy="restore-backup-button"
                      title="Restore backup">
                <mat-icon class="icon" svgIcon="backup_restore"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #trialServiceLimitDialogContent let-role="role">
  A new service is required to restore from this backup.
  <br>
  <br>
  During the trial period, you can create and run one service. If you’d like to start using multiple services, please
  <ng-container *ngIf="role === 'DEVELOPER'">contact your admin to</ng-container>
  enter your payment details.
</ng-template>
