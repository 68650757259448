import { ChangeDetectionStrategy, Component } from '@angular/core';
import { trackById } from '@cp/common-services/trackById';
import { SupportCase } from '@cp/common/protocol/Support';
import { isEmpty } from '@cp/common/utils/ValidationUtils';
import { DriftService } from '@cp/web/app/chatbot/drift.service';
import { SupportCasesStateService } from '@cp/web/app/support/support-cases-state.service';
import { supportCaseStatusDisplay } from '@cp/web/app/support/support-protocol';
import { SupportUiService } from '@cp/web/app/support/support-ui.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type StatusToggle = 'all' | 'open' | 'closed';
type SortField = 'lastModifiedDate' | 'status' | 'subject' | 'caseNumber' | 'primaryClickHouseContactName';

interface SortFieldAndDirection {
  field: SortField;
  ascending: boolean;
}

interface HeaderField {
  label: string;
  sortField: SortField;
}

@Component({
  selector: 'cp-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportPageComponent {
  trackById = trackById;
  readonly sortSubject = new BehaviorSubject<SortFieldAndDirection>({
    field: 'lastModifiedDate',
    ascending: false
  });
  readonly headerFields: Array<HeaderField> = [
    { sortField: 'caseNumber', label: 'Case number' },
    { sortField: 'subject', label: 'Subject' },
    { sortField: 'status', label: 'Status' },
    { sortField: 'primaryClickHouseContactName', label: 'ClickHouse contact' },
    { sortField: 'lastModifiedDate', label: 'Last updated' }
  ];
  searchText?: string;
  supportCaseStatusDisplay = supportCaseStatusDisplay;
  readonly allSupportCasesObs = this.supportCasesStateService.observeSupportCases().pipe(
    map((supportCasesMap) => {
      return Object.values(supportCasesMap);
    })
  );
  private readonly statusToggleSubject = new BehaviorSubject<StatusToggle>('all');
  private readonly searchTextSubject = new BehaviorSubject<string>('');
  readonly supportCasesObs: Observable<Array<SupportCase>> = combineLatest([
    this.allSupportCasesObs,
    this.statusToggleSubject,
    this.searchTextSubject,
    this.sortSubject
  ]).pipe(
    map(([supportCases, statusToggle, searchText, sortAndDirection]) => {
      return this.filterSupportCasesByStatus(statusToggle, supportCases)
        .filter((supportCase) => {
          if (isEmpty(searchText)) {
            return true;
          }

          return (searchText as string)
            .toLowerCase()
            .split(' ')
            .every((keyword) => {
              return (
                supportCase.subject.toLowerCase().includes(keyword) ||
                supportCase.description.toLowerCase().includes(keyword) ||
                supportCase.caseNumber.toLowerCase().includes(keyword) ||
                supportCase.status.toLowerCase().includes(keyword) ||
                supportCase.primaryClickHouseContactName?.toLowerCase().includes(keyword)
              );
            });
        })
        .sort((a, b) => {
          const aValue = a[sortAndDirection.field] || '';
          const bValue = b[sortAndDirection.field] || '';
          if (aValue === bValue) {
            return 0;
          }
          if (sortAndDirection.ascending) {
            return aValue > bValue ? 1 : -1;
          } else {
            return bValue > aValue ? 1 : -1;
          }
        });
    })
  );

  constructor(
    private readonly driftService: DriftService,
    private readonly supportCasesStateService: SupportCasesStateService,
    private readonly supportUiService: SupportUiService
  ) {}

  searchInputChanged(): void {
    this.searchTextSubject.next(this.searchText || '');
  }

  showCreateSupportCaseDialog(): void {
    this.supportUiService.showCreateCaseDialog();
  }

  chatWithSupport(): void {
    this.driftService.openChat();
  }

  selectedStatusChanged(status: StatusToggle): void {
    this.statusToggleSubject.next(status);
  }

  getCountByStatus(status: StatusToggle, supportCases: Array<SupportCase>): number {
    return this.filterSupportCasesByStatus(status, supportCases).length;
  }

  setSort(field: SortField, currentSort: SortFieldAndDirection): void {
    this.sortSubject.next({ field, ascending: field === currentSort.field ? !currentSort.ascending : true });
  }

  private filterSupportCasesByStatus(status: StatusToggle, supportCases: Array<SupportCase>): Array<SupportCase> {
    return supportCases.filter((supportCase) => {
      switch (status) {
        case 'open':
          return supportCase.status !== 'CLOSED';
        case 'closed':
          return supportCase.status === 'CLOSED';
        case 'all':
        default:
          return true;
      }
    });
  }
}
