import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SupportCaseDetails, SupportCaseEvent, SupportCaseEventType } from '@cp/common/protocol/Support';
import { assertTruthy } from '@cp/common/utils/Assert';

@Component({
  selector: 'cp-case-timeline',
  templateUrl: './case-timeline.component.html',
  styleUrls: ['./case-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseTimelineComponent implements OnInit, OnChanges {
  descriptionExpanded = false;

  readonly eventIcons: Record<SupportCaseEventType, string> = {
    reply: 'case_reply',
    status: 'case_status',
    attachment: 'case_upload'
  };

  @Input()
  caseDetails!: SupportCaseDetails;

  caseEvents!: SupportCaseEvent[];

  @Input()
  showReplyButton = true;

  @Output()
  replyClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
    assertTruthy(this.caseDetails);
    this.populateCaseEvents();
  }

  ngOnChanges() {
    assertTruthy(this.caseDetails);
    this.populateCaseEvents();
  }

  private populateCaseEvents() {
    let lastEventSeen = false;
    this.caseEvents = (this.caseDetails.events || []).filter((event) => {
      if (lastEventSeen) return false;
      lastEventSeen = event.type === 'status' && event.status === 'NEW';
      return (
        ['reply', 'attachment'].includes(event.type) ||
        (event.type === 'status' && ['NEW', 'CLOSED'].includes(event.status))
      );
    });
  }
}
