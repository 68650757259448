<div class="dialog_submitted_container">
  <div class="backgrounds">
    <div class="background-layer1"></div>
    <div class="background-layer2"></div>
  </div>
  <div class="check_icon_container">
    <mat-icon class="icon" svgIcon="check_icon"></mat-icon>
  </div>
  <h4 data-cy="dialog-submitted-title">{{title}}</h4>
  <div data-cy="dialog-submitted-subtitle" class="text">{{subtitle}}</div>

  <div class="buttons">
    <button (click)="dialogRef.close()"
            class="close_button"
            data-cy="close-dialog-submitted-button" mat-button
            type="button"
    >Close
    </button>
  </div>
</div>
