import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Integration, isIntegration } from '@cp/common/protocol/Integration';
import { assertTruthy } from '@cp/common/utils/Assert';
import { StrapiService } from '@cp/web/app/common/services/strapi.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  constructor(
    private readonly strapiService: StrapiService,
    private readonly snackBar: MatSnackBar
  ) {}

  async loadIntegrations({
    integrationSlug = '',
    includeSummary = false
  }: { integrationSlug?: string; includeSummary?: boolean } = {}): Promise<Array<Integration>> {
    const fields: string[] = [
      'shortDescription',
      'name',
      'category',
      'supportLevel',
      'docsLink',
      'slug',
      'about',
      'changelog',
      'version',
      'license',
      'readiness',
      'website'
    ];

    if (includeSummary) {
      fields.push('summary');
    }

    try {
      const strapiObj = await this.strapiService.getStrapi().find('integrations', {
        populate: ['logo'],
        filters: integrationSlug ? { slug: { $eq: integrationSlug } } : {},
        fields
      });

      const integrations = this.strapiService.convertStrapiObject(strapiObj.data);
      assertTruthy(Array.isArray(integrations) && integrations.every((integration) => isIntegration(integration)));
      return integrations;
    } catch (error) {
      Sentry.captureException(error);
      this.snackBar.open("Can't load the integrations list.", 'Dismiss', { duration: 5000 });
      return [];
    }
  }
}
