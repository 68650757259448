import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isUserFeatureId } from '@cp/common/protocol/features';
import { AccountService } from '@cp/web/app/account/account.service';

/**
 * Top-level component for the protected area: only signed-in users can see this component and children.
 * See app-routing.module.ts.
 */
@Component({
  templateUrl: './protected-layout.component.html',
  styleUrls: ['./protected-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtectedLayoutComponent implements OnInit, OnDestroy {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly accountService: AccountService
  ) {
    // User features are supported only for signed-in users.
    const experimentToEnable = activatedRoute.snapshot.queryParamMap.get('enable-feature');
    if (experimentToEnable && isUserFeatureId(experimentToEnable)) {
      this.accountService.updateExperimentFlag(experimentToEnable, true).then();
    }
    const experimentToDisable = activatedRoute.snapshot.queryParamMap.get('disable-feature');
    if (experimentToDisable && isUserFeatureId(experimentToDisable)) {
      this.accountService.updateExperimentFlag(experimentToDisable, false).then();
    }
  }

  ngOnInit(): void {
    this.accountService.setIsInProtectedView(true);
  }

  ngOnDestroy(): void {
    this.accountService.setIsInProtectedView(false);
  }
}
