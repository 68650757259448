import { Pipe, PipeTransform } from '@angular/core';
import linkifyStr from 'linkify-string';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  /**
   * Parses text and converts URLs into hyperlinks.
   * This will keep any HTML tags, but will not render them except for anchors.
   * Example: `<h1>This is an example<h1> https://www.google.com -> <h1>This is an example</h1> <a href="https://www.google.com" target="_blank">https://www.google.com</a>`
   */
  transform(str: string): string {
    return linkifyStr(str, { target: '_blank' });
  }
}
