import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { isDefined } from '@cp/common/protocol/Common';
import { AccountService } from '@cp/web/app/account/account.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { firstValueFrom, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * This guard checks whether the user can create more instances, if not skip the onboard page and redirect to the
 * services page.
 */
@Injectable({
  providedIn: 'root'
})
export class OnboardGuardService implements CanActivate {
  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceStateService: InstanceStateService,
    private readonly accountService: AccountService,
    private readonly router: Router
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const orgRestrictions = await firstValueFrom(
      this.organizationStateService.observeCurrentOrganizationId().pipe(
        filter(isDefined),
        switchMap((id) => this.organizationStateService.observeRestrictions(id))
      )
    );
    if (!orgRestrictions.canCreateInstances || orgRestrictions.maxInstanceCount === 0) {
      this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['onboarding'] }).then();
      return this.router.parseUrl('/');
    }
    const instances = await firstValueFrom(this.instanceStateService.observeInstances());
    const instancesCount = Object.keys(instances).length;
    if (instancesCount > 0) {
      this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['onboarding'] }).then();
      return this.router.parseUrl('/');
    }
    return true;
  }
}
