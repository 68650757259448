<div *ngIf="filteredActivitiesObs | async as activities" class="org_activity">
  <div class="activity_header">
    <div class="left">
      <div class="activity_count">{{activities.length}} activities</div>
    </div>

    <div class="right">
      <div class="search_container">
        <div class="icon_wrapper">
          <mat-icon class="icon" svgIcon="search"></mat-icon>
        </div>
        <input (input)="searchInputChanged()" [(ngModel)]="searchText" class="seed_input search_input"
               placeholder="Search activities" type="text" spellcheck="false"/>
      </div>
      <button (click)="exportCsv(activities)" class="export_csv_button" mat-flat-button>
        <mat-icon class="icon" svgIcon="export_csv_icon"></mat-icon>
        Export to CSV
      </button>
    </div>
  </div>

  <table *ngIf="activities.length" class="activity_table">
    <thead>
    <tr *ngVar="sortSubject | async as sort" class="header_row row">
      <th (click)="setSort(field.sortField, sort)" *ngFor="let field of headerFields" class="cell">
        {{field.label}}
        <mat-icon *ngIf="field.sortField === sort?.field"
                  [ngClass]="{ descending: !sort.ascending}"
                  class="sort_icon"
                  svgIcon="sort_arrow"></mat-icon>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let activity of activities; trackBy: trackById" class="row">
      <td class="cell">{{activity.activity}}</td>
      <td class="cell fs-exclude">{{activity.actor}}</td>
      <td *ngIf="showPayload" class="cell fs-exclude">{{activity.payload}}</td>
      <td class="cell fs-exclude">{{isValidIP4Address(activity.ipAddress) ? activity.ipAddress : ''}}</td>
      <td class="cell">{{getDisplayDate(activity.time)}}</td>
    </tr>
    </tbody>
  </table>
</div>
