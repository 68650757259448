import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { assertTruthy } from '@cp/common/utils/Assert';

@Component({
  selector: 'seed-tab',
  templateUrl: './seed-tab.component.html',
  styleUrls: ['./seed-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SeedTabComponent)
    }
  ]
})
export class SeedTabComponent implements ControlValueAccessor, OnChanges, OnInit {
  onChange = (ignored: string): void => {}; // eslint-disable-line  @typescript-eslint/no-unused-vars
  onTouched = (ignored: string): void => {}; // eslint-disable-line  @typescript-eslint/no-unused-vars

  @Input()
  labels!: string[];

  @Input()
  template?: TemplateRef<{ item: string }>;

  @Input()
  contentTemplate?: TemplateRef<{ item: string }>;

  @Input()
  initialValue?: string;

  selectedOptionIndex = 0;

  @Output()
  selectionChange = new EventEmitter<string>();

  ngOnInit() {
    assertTruthy(this.labels?.length);
    if (this.initialValue) {
      const initialIndex = this.labels.indexOf(this.initialValue);
      assertTruthy(initialIndex >= 0);
      this.selectedOptionIndex = initialIndex;
    }
  }

  ngOnChanges(): void {
    assertTruthy(this.labels?.length);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: string) => void): void {
    this.onTouched = fn;
  }

  writeValue(): void {}

  onClick(optionIndex: number): void {
    // Toggle checkbox.
    this.selectedOptionIndex = optionIndex;
    this.onChange(this.labels[optionIndex]);
    this.selectionChange.emit(this.labels[optionIndex]);
  }
}
