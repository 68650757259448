import { Inject, Injectable } from '@angular/core';
import { WebSocketService } from '@cp/common-services/web-socket.service';
import { GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import { RestoreInstanceBackupResponse } from '@cp/common/protocol/Backup';
import { DbUsersResponse } from '@cp/common/protocol/DbUsers';
import {
  GetImportFileDownloadUrlResponse,
  GetInstanceAutoScalingResponse,
  GetInstanceIamPrincipalResponse,
  InstanceAutoscalingParams,
  InstanceCustomerManagedEncryptionConfig,
  InstanceDatabaseAccessMapping,
  InstanceMysqlSettings,
  InstanceTier,
  InstanceUpdatePayload,
  IpAccessListEntry,
  VerifyCustomerKeyConfigResponse
} from '@cp/common/protocol/Instance';
import { canBillingStatusUpgradeInstance } from '@cp/common/protocol/Organization';
import { RegionId } from '@cp/common/protocol/Region';
import { UploadSignatureDetails } from '@cp/common/protocol/Storage';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { formatImportFilename } from '@cp/common/utils/FormatUtils';
import { convertArrayToRecord } from '@cp/common/utils/MiscUtils';
import { generateClickHouseDbPassword } from '@cp/common/utils/PasswordUtils';
import { makeDoubleSha1HashBrowser, makeSha256Base64Browser } from '@cp/cp-common-web/utils/CryptoUtilsBrowser';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { SegmentCategory, SegmentService } from '@cp/web/app/common/services/segment.service';
import { INSTANCE_API_SERVICE_TOKEN, InstanceApiService } from '@cp/web/app/instances/instance-api.service';
import { InstanceBackupStateService } from '@cp/web/app/instances/instance-backups/instance-backup-state.service';
import { InstanceBackupService } from '@cp/web/app/instances/instance-backups/instance-backup.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { combineLatest, NEVER, Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {
  constructor(
    @Inject(INSTANCE_API_SERVICE_TOKEN) private readonly instanceApiService: InstanceApiService,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceUiService: InstanceUiService,
    private readonly accountService: AccountService,
    private readonly instanceBackupService: InstanceBackupService,
    private readonly instanceBackupStateService: InstanceBackupStateService,
    private readonly webSocketService: WebSocketService,
    private readonly accountStateService: AccountStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly organizationService: OrganizationService,
    private readonly featuresService: FeaturesService,
    private readonly segmentService: SegmentService
  ) {
    this.accountStateService
      .observeUserId()
      .pipe(
        switchMap((userId) => {
          if (!userId) {
            return NEVER;
          }
          return this.organizationStateService.observeCurrentOrganizationId().pipe(
            /** Using switchMap so if the currentOrganizationId will change, RxJs will automatically unsubscribe from the
             *  previous subscriptions and will create new subscriptions. **/
            switchMap((currentOrganizationId) => {
              this.instanceStateService.clearInstances();
              if (!currentOrganizationId) {
                return NEVER;
              }
              return this.listenToNotifications();
            })
          );
        })
      )
      .subscribe();
  }

  async refreshInstanceList(): Promise<void> {
    const currentOrganizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    const instances = await this.instanceApiService.listInstances(currentOrganizationId);
    this.instanceStateService.setInstances(convertArrayToRecord(instances));
  }

  async createInstance(
    name: string,
    regionId: RegionId,
    organizationId: string,
    ipAccessList: Array<IpAccessListEntry>,
    instanceTier: InstanceTier,
    segmentCategory?: SegmentCategory,
    gcpTermsChecked?: boolean,
    autoscalingParams: Partial<InstanceAutoscalingParams> = {},
    customerManagedEncryption?: InstanceCustomerManagedEncryptionConfig
  ): Promise<string> {
    const password = generateClickHouseDbPassword();
    const passwordHash = await makeSha256Base64Browser(password);
    const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
    const { instanceId } = await this.instanceApiService.createInstance({
      name,
      regionId,
      organizationId,
      ipAccessList,
      instanceTier,
      passwordHash,
      doubleSha1Password,
      gcpTermsChecked,
      autoscalingParams: autoscalingParams,
      customerManagedEncryption
    });

    this.instanceStateService.setInstancePassword(instanceId, password);

    this.segmentService.trackGaEvent({
      event: 'create new service',
      label: 'create new service',
      category: segmentCategory || 'cloud ui',
      properties: {
        name,
        region: regionId
      },
      view: 'serviceList',
      component: 'header'
    });
    return instanceId;
  }

  async stopInstance(instanceId: string): Promise<void> {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'stop',
      category: 'cloud ui service',
      view: 'serviceList',
      component: 'serviceCard'
    });
    this.instanceStateService.setInstanceState(instanceId, 'stopping', 'optimistic');
    try {
      await this.instanceApiService.stopInstance(instanceId, this.organizationStateService.getCurrentOrgIdOrFail());
    } catch (ignored) {
      console.error(ignored);
      await this.refreshInstanceListOrRestoreApiInstanceState(instanceId);
    }
  }

  async startInstance(instanceId: string): Promise<void> {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'start',
      category: 'cloud ui service',
      view: 'serviceList',
      component: 'serviceCard'
    });
    this.instanceStateService.setInstanceState(instanceId, 'starting', 'optimistic');
    try {
      await this.instanceApiService.startInstance(instanceId, this.organizationStateService.getCurrentOrgIdOrFail());
    } catch (ignored) {
      console.error(ignored);
      await this.refreshInstanceListOrRestoreApiInstanceState(instanceId);
    }
  }

  async deleteInstance(instanceId: string): Promise<void> {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'delete',
      category: 'cloud ui service',
      view: 'serviceList',
      component: 'serviceCard'
    });
    this.instanceStateService.setInstanceState(instanceId, 'terminating', 'optimistic');
    try {
      await this.instanceApiService.deleteInstance(instanceId, this.organizationStateService.getCurrentOrgIdOrFail());
    } catch (ignored) {
      console.error(ignored);
      await this.refreshInstanceListOrRestoreApiInstanceState(instanceId);
    }
  }

  observeCanUpgradeInstance(instanceId: string): Observable<boolean> {
    const instanceObs = this.instanceStateService.observeInstance(instanceId);
    const isUpgradeInstanceFeatureActiveObs = this.featuresService.observeUserFeature('FT_UPGRADE_INSTANCE');
    const orgObs = this.organizationStateService.observeCurrentOrganization();

    return combineLatest([instanceObs, isUpgradeInstanceFeatureActiveObs, orgObs]).pipe(
      map(
        ([instance, isUpgradeInstanceFeatureActive, organization]) =>
          instance?.instanceTier === 'Development' &&
          this.organizationService.isCurrentUserAdmin() &&
          isUpgradeInstanceFeatureActive &&
          canBillingStatusUpgradeInstance(organization.billingStatus)
      )
    );
  }

  async cancelInstanceUpgrade(instanceId: string): Promise<void> {
    await this.instanceApiService.cancelInstanceUpgrade(
      instanceId,
      this.organizationStateService.getCurrentOrgIdOrFail()
    );
  }

  async upgradeInstance(instanceId: string): Promise<void> {
    await this.instanceApiService.upgradeInstance(instanceId, this.organizationStateService.getCurrentOrgIdOrFail());
  }

  /** Refreshes 'hasUserData' flag and returns the latest flag value. */
  async refreshUserDataFlag(instanceId: string): Promise<boolean | undefined> {
    try {
      const { instance } = await this.instanceApiService.refreshUserDataFlag(
        this.organizationStateService.getCurrentOrgIdOrFail(),
        instanceId
      );
      this.instanceStateService.setInstance(instance);
      return instance.hasUserData;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  /** Sets 'hasUserData' instance flag to true and returns the latest flag value. */
  async markInstanceHasUserData(instanceId: string): Promise<boolean | undefined> {
    try {
      const { instance } = await this.instanceApiService.markInstanceHasUserData(
        this.organizationStateService.getCurrentOrgIdOrFail(),
        instanceId
      );
      this.instanceStateService.setInstance(instance);
      return instance.hasUserData;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  /** Calls 'refreshInstanceList' and if it fails restores the last instance state returned by API.*/
  private async refreshInstanceListOrRestoreApiInstanceState(instanceId: string): Promise<void> {
    try {
      await this.refreshInstanceList();
    } catch (ignored) {
      console.error(ignored);
      this.instanceStateService.restoreInstanceStateFromApiState(instanceId);
    }
  }

  async resetPassword(instanceId: string): Promise<void> {
    const password = generateClickHouseDbPassword();
    const passwordHash = await makeSha256Base64Browser(password);
    const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    await this.instanceApiService.resetPassword({ instanceId, organizationId, passwordHash, doubleSha1Password });
    this.instanceStateService.setInstancePassword(instanceId, password);
  }

  async changeInstanceName(instanceId: string, name: string): Promise<void> {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    await this.instanceApiService.changeInstanceName(instanceId, organizationId, name);
  }

  async restoreInstanceBackup(
    backupId: string,
    instanceId: string,
    instanceName: string
  ): Promise<RestoreInstanceBackupResponse> {
    const password = generateClickHouseDbPassword();
    const passwordHash = await makeSha256Base64Browser(password);
    const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    const response = await this.instanceApiService.restoreInstanceBackup({
      organizationId,
      instanceId,
      backupId,
      instanceName,
      passwordHash,
      doubleSha1Password
    });
    this.instanceStateService.setInstancePassword(response.instanceId, password);
    return response;
  }

  async updateIpAccessList(instanceId: string, ipAccessList: Array<IpAccessListEntry>): Promise<void> {
    return this.instanceApiService.updateIpAccessList(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId,
      ipAccessList
    );
  }

  async changeAutoScaling(
    instanceId: string,
    enableIdleScaling: boolean,
    idleTimeoutMinutes: number,
    minAutoScalingTotalMemory?: number,
    maxAutoScalingTotalMemory?: number
  ): Promise<void> {
    return this.instanceApiService.updateAutoScaling(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId,
      enableIdleScaling,
      idleTimeoutMinutes,
      minAutoScalingTotalMemory,
      maxAutoScalingTotalMemory
    );
  }

  async prepareImportFile(fileName: string, mimeType?: string): Promise<UploadSignatureDetails> {
    const cleanFileName = formatImportFilename(fileName);
    return await this.instanceApiService.prepareUploadSignatureDetails(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      cleanFileName,
      mimeType
    );
  }

  async getImportFileDownloadUrl(instanceId: string, storageId: string): Promise<GetImportFileDownloadUrlResponse> {
    return await this.instanceApiService.getImportFileDownloadUrl(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId,
      storageId
    );
  }

  private listenToNotifications() {
    return this.listenToInstanceChanged();
  }

  private listenToInstanceChanged() {
    return this.webSocketService
      .observeNotification<InstanceUpdatePayload>({
        type: 'ORG_INSTANCE_UPDATE',
        objId: this.organizationStateService.getCurrentOrgIdOrFail()
      })
      .pipe(
        tap((notification) => {
          const { instances, updateType } = truthy(notification.payload);
          if (updateType === 'COMPLETE') {
            this.instanceStateService.setInstances(convertArrayToRecord(instances));
          } else {
            for (const instance of instances) {
              if (instance.state === 'terminated') {
                this.instanceStateService.deleteInstance(instance.id);
              } else {
                this.instanceStateService.setInstance(instance);
              }
            }
          }
        })
      );
  }

  async getInstanceDbPermissions(instanceId: string): Promise<DbUsersResponse> {
    return this.instanceApiService.getInstanceDbPermissions(instanceId);
  }

  getDpAutoScalingLimits(regionId: RegionId): Promise<GetAutoScalingLimitsResponse> {
    return this.instanceApiService.getDpAutoScalingLimits(regionId);
  }

  getInstanceAutoscaling(instanceId: string): Promise<GetInstanceAutoScalingResponse> {
    return this.instanceApiService.getInstanceAutoscaling(instanceId);
  }

  verifyCustomerKeyConfig(config: InstanceCustomerManagedEncryptionConfig): Promise<VerifyCustomerKeyConfigResponse> {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    return this.instanceApiService.verifyCustomerKeyConfig(organizationId, config);
  }

  async fetchMysqlSettings(instanceId: string): Promise<InstanceMysqlSettings> {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    const mysqlSettings = await this.instanceApiService.getMysqlSettings(organizationId, instanceId);
    this.instanceStateService.setInstanceMysqlSettings(instanceId, mysqlSettings);
    return mysqlSettings;
  }

  async updateMysqlSettings(instanceId: string, enabled: boolean): Promise<void> {
    await this.instanceApiService.updateMysqlSettings(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId,
      enabled
    );
    const currentSettings = this.instanceStateService.getMysqlSettings(instanceId);
    assertTruthy(currentSettings);
    this.instanceStateService.setInstanceMysqlSettings(instanceId, {
      ...currentSettings,
      enabled
    });
  }

  async backfillMysqlPassword(instanceId: string, doubleSha1Password: string, sha256Password: string): Promise<void> {
    await this.instanceApiService.backfillMysqlPassword(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId,
      doubleSha1Password,
      sha256Password
    );
    const currentSettings = this.instanceStateService.getMysqlSettings(instanceId);
    assertTruthy(currentSettings);
    this.instanceStateService.setInstanceMysqlSettings(instanceId, {
      ...currentSettings,
      passwordSet: true
    });
  }

  async getInstanceIamPrincipal(instanceId: string): Promise<GetInstanceIamPrincipalResponse> {
    return this.instanceApiService.getInstanceIamPrincipal(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId
    );
  }

  async updateInstanceDbRoleMapping(
    instanceId: string,
    databaseRoleMappings: ReadonlyArray<InstanceDatabaseAccessMapping>
  ): Promise<void> {
    return this.instanceApiService.updateInstanceDbRoleMapping(
      this.organizationStateService.getCurrentOrgIdOrFail(),
      instanceId,
      databaseRoleMappings
    );
  }
}
