<form (ngSubmit)="addEntry()" [formGroup]="addEntryFormGroup" class="form">
  <div class="seed_form_element">
    <label>
      {{sourceInputLabelText}}
      <cp-ip-access-list-info-icon></cp-ip-access-list-info-icon>
    </label>
    <input
      spellcheck="false"
      [galaxyClick]="buildFullyQualifiedEvent('whitelistAddressInputFocus')"
      [galaxyBlur]="buildFullyQualifiedEvent('whitelistAddressInputBlur')"
      type="text" formControlName="value" placeholder="IP address or CIDR" data-cy="ip-input">
  </div>

  <div class="seed_form_element">
    <label>Description (optional)</label>
    <input
      spellcheck="false"
      [galaxyClick]="buildFullyQualifiedEvent('whitelistDescInputFocus')"
      [galaxyBlur]="buildFullyQualifiedEvent('whitelistDescInputBlur')"
      type="text" formControlName="description" data-cy="description-input" #descriptionInput>
  </div>

  <div class="buttons_row">
    <button
      *ngIf="isCancelButtonVisible"
      [galaxyClick]="buildFullyQualifiedEvent('whitelistCancelButtonSelect')"
      (click)="handleCancelButtonClick()"
      mat-flat-button
      class="button cancel_button"
      data-cy="cancel-button"
    >Cancel
    </button>

    <button
      *ngIf="currentIP !== '' && isAddCurrentIpButtonVisible"
      [galaxyClick]="buildFullyQualifiedEvent('whiteListCurrentIpButtonSelect')"
      (click)="addCurrentIP()"
      type="button"
      class="light_button"
      color="primary"
      mat-flat-button
      data-cy="add-current-ip-button"
    >Add my current IP
    </button>

    <button type="submit"
    [galaxyClick]="buildFullyQualifiedEvent('whiteListAddEntryButtonSelect')"
            [disabled]="addEntryFormGroup.invalid"
            color="primary"
            mat-flat-button
            data-cy="add-entry-button"
    >{{saveButtonLabel}}
    </button>
  </div>
</form>
