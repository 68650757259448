<div class="container">
  <header>
      Do you want to wake up all idle services?
    
    <mat-icon fontIcon="close" class="close_button" (click)="cancel()">
    </mat-icon>
  </header>

  <div class="question">
    To view all users from all services, you have to wake up your idle services which will incur additional costs. Do you want to proceed?
  </div>

  <button
    mat-button
    mat-flat-button
    color="primary"
    class="confirm_button"
    (click)="confirm()">
    Yes, wake all services
  </button>

  <button mat-button class="cancel_button"    (click)="cancel()">
    Cancel
  </button>
</div>
