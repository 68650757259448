import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject } from '@angular/core';

@Directive({
  selector: '[appHighlightJs]'
})
/** Adds highlight js styles to its target element. */
export class HighlightJsDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit(): void {
    if (this.document.defaultView) {
      (this.document.defaultView as any).hljs?.highlightElement(this.el.nativeElement);
    }
  }
}
