import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import {
  BILLING_API_PATH,
  UsageMetric,
  UsageReportChartResponse,
  UsageReportRequest,
  UsageReportResponse
} from '@cp/common/protocol/Billing';

@Injectable({
  providedIn: 'root'
})
export class AdminUsageApiService {
  constructor(private readonly restService: RestService) {}

  async getAdminUsageReport(
    organizationId: string,
    billDate: string | undefined,
    chartType: UsageMetric
  ): Promise<UsageReportResponse> {
    const request: UsageReportRequest = {
      rpcAction: 'getUsageReport',
      organizationId,
      billDate,
      chartMetric: chartType
    };
    return this.restService.post<UsageReportResponse>(BILLING_API_PATH, request);
  }

  async getAdminUsageChart(
    organizationId: string,
    billDate: string,
    chartType: UsageMetric
  ): Promise<UsageReportChartResponse> {
    // TODO: currently this method is implemented via 'getAdminUsageReport' and fetches more data than asked.
    const response = await this.getAdminUsageReport(organizationId, billDate, chartType);
    return { chart: response.chart };
  }
}
