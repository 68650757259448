import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GalaxyEventType } from '@cp/common/protocol/Galaxy';
import { ALL_COMPANY_SIZE_SET } from '@cp/common/protocol/Organization';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import {
  filterTaxIdTypesByCountry,
  isTaxStatusType,
  TaxIdDatumType,
  TaxIdInfoType,
  taxIdTypes,
  taxIdTypesWithNoTaxValues,
  TaxStatusType
} from '@cp/common/protocol/Stripe';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { isSimpleName } from '@cp/common/utils/ValidationUtils';
import {
  BillingConversionUiState,
  CompanyDetailsChange
} from '@cp/web/app/admin/billing-conversion-dialog/billing-conversion-dialog.component';
import { FullyQualifiedEvent, FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';

@Component({
  selector: 'cp-billing-company-details',
  templateUrl: './change-billing-company-details.component.html',
  styleUrls: ['./change-billing-company-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Form component containing company-related information (company name, taxId, firmographics).
 * This component doesn't save the input. Instead, it passes it back to its caller to handle that "external" logic.
 * See designs: https://www.figma.com/file/i00FDyul4FXB8thLi0Dqfl/Beta?node-id=4239%3A28288
 */
export class ChangeBillingCompanyDetailsComponent implements OnInit {
  companyDetailsForm: FormGroup;

  companySizeOptions: Array<SeedSelectOption> = Array.from(ALL_COMPANY_SIZE_SET).map((value) => ({
    label: `${value.replace('-', ' - ')} employees`,
    value,
    dataCy: 'company-size-option',
    dataCyValue: `company-size-option-${value}`
  }));

  organizationIsABusiness: boolean = true;
  taxIdTypeOptions: Array<SeedSelectOption> = [];

  get selectedTaxType(): TaxIdDatumType | TaxStatusType | undefined {
    return this.companyDetailsForm.get('taxIdType')?.value;
  }

  // No ngOnChanges() in this component because we don't expect the inputs to change after init.
  // We only use formInput in ngOnInit().
  @Input()
  formInput?: BillingConversionUiState;

  @Input()
  isCancelable = true;

  @Input()
  customSubmitText?: string;

  @Input()
  isTaxIdShown = true;

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  @Input()
  taxIdMessage?: string;

  @Output()
  companyDetailsChange = new EventEmitter<CompanyDetailsChange>();

  @Output()
  backButtonClick = new EventEmitter<CompanyDetailsChange>();

  constructor(private readonly formBuilder: FormBuilder) {
    // formInput is not available in the constructor. Creating this empty form in the meantime.
    this.companyDetailsForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    assertTruthy(this.eventPrefix);

    this.organizationIsABusiness = this.formInput?.companyDetailsFormInput?.organizationIsABusiness ?? true;

    const countryCode = truthy(
      this.formInput?.addressFormInput?.shippingAddress?.country ||
        this.formInput?.addressFormInput?.billingAddress?.country,
      'Missing country code'
    );
    this.taxIdTypeOptions = filterTaxIdTypesByCountry(countryCode).map((value: TaxIdInfoType) => ({
      label: `${value.description}`,
      value: `${value.taxId || value.taxStatus}`,
      dataCy: 'tax-id-type-option',
      dataCyValue: `tax-id-type-option-${value.taxId || value.taxStatus}`,
      placeHolder: `${value.example}`
    }));

    this.companyDetailsForm = this.formBuilder.group({});
    this.enableOrDisableOrgAsBusiness(this.organizationIsABusiness);
  }

  async onSubmit(): Promise<void> {
    if (this.organizationIsABusiness && this.companyDetailsForm.invalid) {
      return;
    }
    this.companyDetailsChange.emit({
      organizationIsABusiness: this.organizationIsABusiness,
      companyName: this.companyDetailsForm.value.companyName,
      taxId: this.companyDetailsForm.value.taxId,
      taxIdType: this.companyDetailsForm.value.taxIdType,
      taxStatus: this.stripeSupportsCountry() ? this.companyDetailsForm.value.taxIdType : 'unsupported',
      websiteUrl: this.companyDetailsForm.value.websiteUrl,
      companySize: this.companyDetailsForm.value.companySize
    });
  }

  handleBackButton() {
    this.backButtonClick.emit({
      organizationIsABusiness: this.organizationIsABusiness,
      // Sanitizing the values returned on back, since they might not be valid.
      companyName: isSimpleName(this.companyDetailsForm.value.companyName)
        ? this.companyDetailsForm.value.companyName
        : undefined,
      taxId: this.companyDetailsForm.value.taxId,
      taxIdType: this.companyDetailsForm.value.taxIdType,
      taxStatus: this.stripeSupportsCountry() ? this.companyDetailsForm.value.taxIdType : 'unsupported',
      // Sanitizing the values returned on back, since they might not be valid.
      websiteUrl: isSimpleName(this.companyDetailsForm.value.websiteUrl)
        ? this.companyDetailsForm.value.websiteUrl
        : undefined,
      companySize: this.companyDetailsForm.value.companySize
    });
  }

  enableOrDisableOrgAsBusiness(organizationIsABusiness: boolean) {
    this.organizationIsABusiness = organizationIsABusiness;

    if (this.organizationIsABusiness) {
      this.companyDetailsForm.addControl(
        'companyName',
        this.formBuilder.control(this.formInput?.companyDetailsFormInput?.companyName ?? '', [Validators.required])
      );
      this.companyDetailsForm.addControl(
        'taxId',
        this.formBuilder.control(this.formInput?.companyDetailsFormInput?.taxId ?? '', [])
      );
      this.companyDetailsForm.addControl(
        'websiteUrl',
        this.formBuilder.control(this.formInput?.companyDetailsFormInput?.websiteUrl ?? '', [Validators.required])
      );
      this.companyDetailsForm.addControl(
        'companySize',
        this.formBuilder.control(this.formInput?.companyDetailsFormInput?.companySize ?? '', [Validators.required])
      );
      this.companyDetailsForm.addControl(
        'taxIdType',
        this.formBuilder.control(
          this.formInput?.companyDetailsFormInput?.taxIdType ??
            this.formInput?.companyDetailsFormInput?.taxStatus ??
            '',
          []
        )
      );
      this.companyDetailsForm.addValidators([
        taxIdValidator('taxIdType', 'taxId', this.stripeSupportsCountry()),
        businessValidator('companyName', 'websiteUrl', 'companySize', this.organizationIsABusiness)
      ]);
    } else {
      this.companyDetailsForm.removeControl('companyName');
      this.companyDetailsForm.removeControl('taxId');
      this.companyDetailsForm.removeControl('websiteUrl');
      this.companyDetailsForm.removeControl('companySize');
      this.companyDetailsForm.removeControl('taxIdType');
    }
  }

  buildFullyQualifiedEvent(name: GalaxyEventType): FullyQualifiedEvent {
    return `${this.eventPrefix}.${name}`;
  }

  hasTaxId(): boolean {
    return this.selectedTaxType !== undefined && !isTaxStatusType(this.selectedTaxType);
  }

  taxIdExample(): string {
    const currentTaxTypes = taxIdTypes.filter((type) => type.taxId === this.selectedTaxType);
    return currentTaxTypes[0]?.example ? `e.g. ${currentTaxTypes[0].example}` : '';
  }

  stripeSupportsCountry(): boolean {
    return this.taxIdTypeOptions.length > taxIdTypesWithNoTaxValues.length;
  }

  taxIdInfoMessage(): string {
    if (!this.stripeSupportsCountry() && this.organizationIsABusiness) {
      return 'Please send an email with your Tax Id to ar@clickhouse.com';
    }

    if (this.selectedTaxType === 'exempt') {
      return 'Please send an email with proof of tax exemption to tax-exempt@clickhouse.com';
    }

    return '';
  }
}

function taxIdValidator(taxIdTypeKey: string, taxIdKey: string, stripeSupportsCountry: boolean) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const taxId = control.get(taxIdKey);
    const taxIdType = control.get(taxIdTypeKey);

    if (stripeSupportsCountry && taxIdType && !isTaxStatusType(taxIdType.value) && taxId && taxId.value === '') {
      return { required: true };
    }

    return null;
  };
}

function businessValidator(
  companyNameKey: string,
  websiteUrlKey: string,
  companySizeKey: string,
  organizationIsABusiness: boolean
) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!organizationIsABusiness) {
      return null;
    }

    const companyName = control.get(companyNameKey);
    const websiteUrl = control.get(websiteUrlKey);
    const companySize = control.get(companySizeKey);

    if (!companyName?.value || !websiteUrl?.value || !companySize?.value) {
      return { required: true };
    }

    return null;
  };
}
