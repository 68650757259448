<cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
<cp-dialog-title dialogTitle="Edit API Key"></cp-dialog-title>
<cp-edit-api-key-form
  mode="edit"
  [userRole]="data.role"
  [initialFormData]="{name: data.key.name, role: data.key.roles[0], expirationDate: data.key.expirationDate}"
  [isSubmitInProgress]="isUpdateKeyNetworkCallInFlight"
  (update)="updateApiKey($event)"
  (cancel)="dialogRef.close()"
></cp-edit-api-key-form>
