<mat-dialog-content data-cy="set-up-authenticator-app-dialog" class="set_up_authenticator_app_dialog">
  <div class="dialog_title seed_h4" data-cy="set-up-authenticator-app-title">Set up authenticator app</div>
  <div class="close_icon_wrapper">
    <cp-dialog-close-button [dialogRef]="dialogRef"
                            [width]="14"
                            [height]="16"
                            [fontSize]="16"
                            [top]="10"
    ></cp-dialog-close-button>
  </div>
  <div class="controls_div">
    <cp-authenticator-app-set-up-dialog-content [code]="code"
                                                [qrCode]="qrCodeString"
                                                [token]="token"
                                                (tokenConfirmation)="verifyToken()"
                                                (tokenUpdate)="updateToken($event)">

    </cp-authenticator-app-set-up-dialog-content>

    <div *ngIf="errorMessage" class="error_message_wrapper">
      <cw-system-message
        messageType="error"
        [message]="errorMessage">
      </cw-system-message>
    </div>

    <div class="vertical_buttons">
      <button class="main_action"
              [disabled]="isVerificationButtonDisabled"
              color="primary" data-cy="confirm-button" mat-flat-button
              (click)="verifyToken()">
        <mat-icon *ngIf="isVerifyingCode" class="code_verification_loading_icon"
                  svgIcon="loader"></mat-icon>
        Enable Authenticator app
      </button>
      <button (click)="dialogRef.close()" mat-button mat-flat-button type="button"
              class="cancel_button" data-cy="cancel-button">
        Cancel
      </button>
    </div>
  </div>
</mat-dialog-content>
