<mat-dialog-content class="trial_service_limit_dialog" data-cy="trial-service-limit-dialog">
  <div class="dialog_container" *ngIf="myRoleObs | async as myRole">
    <!-- TODO(https://github.com/ClickHouse/control-plane/issues/1260):: export background header to a separate component that accepts an icon as an input. -->
    <div class="backgrounds">
      <div class="background-layer1"></div>
      <div class="background-layer2"></div>
    </div>
    <div class="header_icon_wrapper">
      <div class="clock_icon_container">
        <mat-icon class="icon" svgIcon="bars"></mat-icon>
      </div>
    </div>
    <div class="title" data-cy="trial-service-limit-title">Trial service limit reached</div>
    <div class="text" data-cy="trial-service-limit-text">
      <ng-container
        *ngTemplateOutlet="customContentTemplate || defaultContentTemplate; context: {$implicit: myRole}"></ng-container>
    </div>

    <div class="buttons">
      <button *ngIf="myRole === 'ADMIN'"
              (click)="goToBilling()" class="payment_button"
              color="primary"
              data-cy="enter-payment-button"
              mat-flat-button
      >Enter payment details
      </button>
      <button (click)="dialogRef.close()"
              class="close_button"
              data-cy="close-service-limit-button" mat-button
              type="button"
      >Close
      </button>
    </div>
  </div>
</mat-dialog-content>

<ng-template #defaultContentTemplate let-role="role">
  During the trial period, you can create and run one service. If you’d like to start using multiple services, please
  <ng-container *ngIf="role === 'DEVELOPER'">contact your admin to</ng-container> enter your payment details.
</ng-template>
