<mat-dialog-content data-cy="org-invite-expired-dialog">
  <div class="content_center">
    <div class="title">Invitation expired!</div>
    <mat-icon svgIcon="clock" class="clock_icon"></mat-icon>
    <div class="text">The invitation link used has now expired.  To request a new link, please contact this organization's administrator.
    </div>
    <button (click)="onClose()"
            class="close_button"
            data-cy="close-invitation-expired-button" mat-button
            type="button"
    >Return to
      <ng-container *ngIf="isAuthenticated">site</ng-container>
      <ng-container *ngIf="!isAuthenticated">registration</ng-container>
    </button>
  </div>
</mat-dialog-content>
