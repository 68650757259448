import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import { isDefined } from '@cp/common/protocol/Common';
import {
  DUPLICATE_INSTANCE_NAME,
  INSTANCE_LIMIT_REACHED,
  INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED,
  InstanceCustomerManagedEncryptionConfig,
  InstanceTier
} from '@cp/common/protocol/Instance';
import { OrganizationBillingStatus } from '@cp/common/protocol/Organization';
import { isAwsRegionId } from '@cp/common/protocol/Region';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { CreateInstanceUiService } from '@cp/web/app/instances/create-instance/create-instance-ui.service';
import {
  checkInstanceTierAndRegionChanged,
  getAutoscalingConfig
} from '@cp/web/app/instances/create-instance/create-instance.component';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { FirstTimeSetIpAccessListDialogComponent } from '@cp/web/app/instances/ip-access-list/first-time-set-ip-access-list-dialog.component';
import { CreateInstanceFormDetails, CreateInstanceUiState } from '@cp/web/app/instances/protocol/InstanceStates';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable, switchMap, takeUntil } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

type SidebarState = 'CLOSED' | 'AUTO_SCALING' | 'CUSTOMER_MANAGED_ENCRYPTION';

@Component({
  templateUrl: './create-instance-dialog.component.html',
  styleUrls: ['./create-instance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateInstanceDialogComponent extends OnDestroyComponent {
  readonly form: FormGroup = new FormGroup({
    autoScalingDetails: new FormControl({ value: null, disabled: false }),
    customerManagedEncryption: new FormControl({ value: null, disabled: false })
  });
  readonly createInstanceUiStateObs: Observable<CreateInstanceUiState>;
  sidebarState: SidebarState = 'CLOSED';
  readonly cmekEnabled = this.organizationStateService
    .observeCurrentOrganization()
    .pipe(map((organization) => organization.features.includes('FT_ORG_CUSTOMER_MANAGED_ENCRYPTION')));
  advancedSettingsOpen = false;
  billingStatusObs: Observable<OrganizationBillingStatus>;
  autoScalingLimits?: GetAutoScalingLimitsResponse;
  customerEncryptionValid = true;

  constructor(
    public dialogRef: MatDialogRef<CreateInstanceDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly instanceService: InstanceService,
    private readonly createInstanceUiService: CreateInstanceUiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly accountStateService: AccountStateService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.billingStatusObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((orgId) => this.organizationStateService.observeBillingStatus(orgId))
    );

    this.createInstanceUiStateObs = createInstanceUiService.observeCreateInstanceUiState();
    createInstanceUiService.setPartialState({ createInstanceButtonDisabled: false, errorMessage: undefined });

    this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy),
        filter((value) => isDefined(value.instanceDetails?.region?.id)),
        distinctUntilChanged(checkInstanceTierAndRegionChanged)
      )
      .subscribe(async (value) => {
        if (!INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(value.instanceDetails.tier)) {
          this.sidebarState = 'CLOSED';
        }
        if (this.customerManagedEncryptionOpen && !this.cmekAllowedInRegion) {
          this.sidebarState = 'CLOSED';
        }
        this.autoScalingLimits = await this.instanceService.getDpAutoScalingLimits(value.instanceDetails.region.id);
        this.cdr.markForCheck();
      });
  }

  getCustomerManagedEncryptionConfig(tier: InstanceTier): InstanceCustomerManagedEncryptionConfig | undefined {
    const customerManagedEncryptionConfig: InstanceCustomerManagedEncryptionConfig | undefined =
      this.form.value['customerManagedEncryption'] ?? undefined;

    if (
      !customerManagedEncryptionConfig?.keyArn ||
      !INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(tier) ||
      !this.cmekAllowedInRegion
    ) {
      return undefined;
    } else {
      return customerManagedEncryptionConfig;
    }
  }

  async onSubmit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    this.createInstanceUiService.setPartialState({ createInstanceButtonDisabled: true, errorMessage: undefined });
    const autoScalingConfig = getAutoscalingConfig(this.form, this.accountStateService.getUserDetailsOrFail().features);
    const details = this.form.value.instanceDetails as CreateInstanceFormDetails;
    const customerManagedEncryptionConfig = this.getCustomerManagedEncryptionConfig(details.tier);

    try {
      const instanceId = await this.instanceService.createInstance(
        details.name.trim(),
        details.region.id,
        this.organizationStateService.getCurrentOrgIdOrFail(),
        [],
        details.tier,
        undefined,
        details.gcpTermsChecked,
        autoScalingConfig,
        customerManagedEncryptionConfig
      );
      this.snackBar.open('Service created', 'Dismiss', { duration: 5000 });
      FirstTimeSetIpAccessListDialogComponent.show(this.dialog, instanceId);
      this.dialogRef.close();
    } catch (e: any) {
      console.error(e);
      let errorMessage;
      const serverErrorMessage = getServerErrorMessage(e);
      switch (serverErrorMessage) {
        case DUPLICATE_INSTANCE_NAME:
          errorMessage = 'A service with this name already exists, please choose another';
          break;
        case INSTANCE_LIMIT_REACHED:
          errorMessage = `You've reached the maximum amount of services allowed per organization`;
          break;
        default:
          errorMessage = 'Error occurred when creating a service';
          break;
      }
      this.createInstanceUiService.setStateKey('errorMessage', errorMessage);
    } finally {
      this.createInstanceUiService.setStateKey('createInstanceButtonDisabled', false);
    }
  }

  toggleSidebar(state: SidebarState) {
    if (this.sidebarState === state) {
      this.sidebarState = 'CLOSED';
    } else {
      this.sidebarState = state;
    }
  }

  get autoScalingSettingsOpen(): boolean {
    return this.sidebarState === 'AUTO_SCALING';
  }

  get customerManagedEncryptionOpen(): boolean {
    return this.sidebarState === 'CUSTOMER_MANAGED_ENCRYPTION';
  }

  setCustomerEncryptionValid(isValid: boolean) {
    this.customerEncryptionValid = isValid;
    this.cdr.markForCheck();
  }

  get canSubmit(): boolean {
    return !this.form.invalid && this.customerEncryptionValid;
  }

  get cmekAllowedInRegion(): boolean {
    const regionId = this.form.value.instanceDetails.region?.id;
    return isAwsRegionId(regionId);
  }
}
