import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { isDefined } from '@cp/common/protocol/Common';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { isNotEmpty } from '@cp/common/utils/ValidationUtils';
import { AdminBillingService } from '@cp/web/app/admin/admin-billing/admin-billing.service';
import { BillingApiService } from '@cp/web/app/admin/billing-api.service';
import { ChangeBillingContactUiService } from '@cp/web/app/organizations/change-billing-contact-dialog/change-billing-contact-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { ChangeBillingContactUiState } from '@cp/web/app/organizations/protocol/OrganizationStates';
import { map, Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cp-change-billing-contact-dialog',
  templateUrl: './change-billing-contact-dialog.component.html',
  styleUrls: ['./change-billing-contact-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeBillingContactDialogComponent {
  changeBillingContactForm: FormGroup;
  uiStateObs: Observable<ChangeBillingContactUiState>;
  readonly billingContactOptionsObs: Observable<Array<SeedSelectOption>>;
  readonly initialContact: string;

  constructor(
    public dialogRef: MatDialogRef<ChangeBillingContactDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly uiStateService: ChangeBillingContactUiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly billingApiService: BillingApiService,
    private readonly adminBillingService: AdminBillingService,
    @Inject(MAT_DIALOG_DATA) private readonly data: { billingContact: string }
  ) {
    this.uiStateObs = uiStateService.observeState();
    this.initialContact = data.billingContact;
    this.uiStateService.setPartialState({ buttonDisabled: false });
    this.billingContactOptionsObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((organizationId) => this.organizationStateService.observeOrganizationUsers(organizationId)),
      map((users) => {
        const dataCy = 'billing-contact-option';
        const adminEmails = [...Object.values(users)]
          .filter((user) => user.role === 'ADMIN' && isNotEmpty(user.email))
          .map((user) => user.email);
        // Since old users don't have their email stored in org users we need to make sure the current email is in that
        // list of admin emails.
        return Array.from(new Set<string>([...adminEmails, this.initialContact])).map<SeedSelectOption>((email) => ({
          label: email,
          value: email,
          dataCyValue: email,
          dataCy
        }));
      })
    );

    this.changeBillingContactForm = this.formBuilder.group({
      contact: [data.billingContact, [Validators.required]]
    });
  }

  async onSubmit(): Promise<void> {
    if (this.changeBillingContactForm.invalid) {
      return;
    }
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    await this.billingApiService.updateBillingContact(organizationId, this.changeBillingContactForm.value.contact);
    await this.adminBillingService.refreshAdminBillingState(organizationId);
    this.dialogRef.close();
  }
}

export function getChangeBillingContactDialogConfig(billingContact: string): MatDialogConfig {
  return {
    width: '100%',
    maxWidth: '500px',
    autoFocus: true,
    restoreFocus: false,
    panelClass: 'modal',
    data: { billingContact }
  };
}
