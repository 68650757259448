import { ActivatedRouteSnapshot } from '@angular/router';
import { UsageMetric } from '@cp/common/protocol/Billing';
import { TimePeriod } from '@cp/common/protocol/Metrics';

/**
 * Returns path to the service (instance) details page.
 * Must be in sync with the 'Routes' map above.
 */
export function getInstancePagePath(instanceId: string): string {
  return `/service/${instanceId}`;
}

/** Name of the query parameter for 'TimePeriod'. */
export const SERVICE_DETAILS_PAGE_TIME_PERIOD_QUERY_PARAMETER_NAME = 'period';

export function getTimePeriodFromRouteSnapshot(snapshot: ActivatedRouteSnapshot): TimePeriod | undefined {
  const value = snapshot.queryParamMap.get(SERVICE_DETAILS_PAGE_TIME_PERIOD_QUERY_PARAMETER_NAME);
  return convertUrlParameterToTimePeriod(value);
}

/** Returns path shown in the browser when user selects 'summary' (default) tab on the instance page. */
export function getServiceDetailsPageSummaryTabPath(
  instanceId: string,
  metricsDashboardTimePeriod?: TimePeriod
): string {
  let summaryPath = getInstancePagePath(instanceId);
  const timePeriodAsUrlParameter = convertTimePeriodToUrlParameter(metricsDashboardTimePeriod);
  const timePeriodAsUrlParameterDefaultValue = '1h';
  if (timePeriodAsUrlParameter && timePeriodAsUrlParameter !== timePeriodAsUrlParameterDefaultValue) {
    summaryPath += `?${SERVICE_DETAILS_PAGE_TIME_PERIOD_QUERY_PARAMETER_NAME}=${timePeriodAsUrlParameter}`;
  }
  return summaryPath;
}

/** Returns path shown in the browser when user selects 'backup' tab on the instance page. */
export function getInstancePageBackupTabPath(instanceId: string): string {
  return `${getInstancePagePath(instanceId)}/backups`;
}

/** Returns path shown in the browser when user selects 'security' tab on the instance page. */
export function getInstancePageSettingsTabPath(instanceId: string): string {
  return `${getInstancePagePath(instanceId)}/settings`;
}

/** Returns path shown in the browser when user selects 'connection' tab on the instance page. */
export function getInstancePageConnectionTabPath(instanceId: string): string {
  return `${getInstancePagePath(instanceId)}/connection`;
}

export function getInstancePageNetworkSettingsPath(instanceId: string): string {
  return `${getInstancePageSettingsTabPath(instanceId)}#networking`;
}

/** Converts time period to URL query parameter value. */
export function convertTimePeriodToUrlParameter(timePeriod: TimePeriod | undefined): string | undefined {
  if (!timePeriod) {
    return undefined;
  }
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      return '15min';
    case 'LAST_HOUR':
      return '1h';
    case 'LAST_DAY':
      return '24h';
    case 'LAST_WEEK':
      return '1w';
    case 'LAST_MONTH':
      return '1m';
    case 'LAST_YEAR':
      return '1y';
  }
  return undefined;
}

/** Converts TimePeriod query URL parameter value to the API enum value. */
export function convertUrlParameterToTimePeriod(parameterValue: string | undefined | null): TimePeriod | undefined {
  if (parameterValue === undefined || parameterValue === null) {
    return undefined;
  }
  switch (parameterValue) {
    case '15min':
      return 'LAST_15_MINUTES';
    case '1h':
      return 'LAST_HOUR';
    case '24h':
      return 'LAST_DAY';
    case '1w':
      return 'LAST_WEEK';
    case '1m':
      return 'LAST_MONTH';
    case '1y':
      return 'LAST_YEAR';
  }
  return undefined;
}

export const ADMIN_USAGE_PAGE_METRIC_PARAM_NAME = 'metric';

const ADMIN_USAGE_METRIC_BY_QUERY_PARAM: Record<string, UsageMetric> = {
  storage: 'STORAGE_GB_MINUTES_TABLES',
  compute: 'COMPUTE_UNIT_MINUTES'
};

const ADMIN_USAGE_QUERY_PARAM_BY_METRIC: Record<UsageMetric, string> = Object.fromEntries(
  Object.entries(ADMIN_USAGE_METRIC_BY_QUERY_PARAM).map(([k, v]) => [v, k])
) as Record<UsageMetric, string>;

/** Extracts metric from URL params. */
export function getAdminUsageMetricFromUrl(snapshot: ActivatedRouteSnapshot): UsageMetric | undefined {
  const param = snapshot.queryParamMap.get(ADMIN_USAGE_PAGE_METRIC_PARAM_NAME) || '';
  return ADMIN_USAGE_METRIC_BY_QUERY_PARAM[param];
}

/** Converts metric to URL param. */
export function getAdminUsageMetricUrlParamValue(metric: UsageMetric): string {
  return ADMIN_USAGE_QUERY_PARAM_BY_METRIC[metric];
}

export const MFA_VERIFY_TOTP_URI = '/mfa/verify-totp';

/** Returns path shown in the browser when user selects 'Cloud users' tab on the user page. */
export function getUsersPageOrgUsersTabPath(orgId: string): string {
  return `/organizations/${orgId}/members`;
}

/** Returns path shown in the browser when user selects 'Database users' tab on the user page. */
export function getUsersPageDbUsersTabPath(orgId: string): string {
  return `/organizations/${orgId}/db-users`;
}
