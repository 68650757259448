<mat-dialog-content data-cy="import-ip-access-list-from-instance-dialog">
  <h4>Import from another service</h4>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <ng-container *ngIf="orgInstancesObs | async as orgInstances">
    <div *ngIf="orgInstances.length > 0">
      <div class="instance_selector_label">Service</div>

      <seed-select [options]="convertInstancesToSelectOptions(orgInstances)"
                   [value]="selectedInstanceIdSubject | async"
                   (selectionChange)="onSelectedInstanceChanged($event.value)"
      ></seed-select>

      <cp-ip-access-list-table-view *ngIf="selectedInstanceIpAccessListObs | async as selectedInstanceIpAccessList"
                                     eventPrefix="servicePage.security"
                                     [ipAccessList]="selectedInstanceIpAccessList"
                                     [selectedSources]="selectedIpAccessListEntrySources"
                                     (selectCheckboxChange)="toggleIpAccessListEntrySelection($event)"
                                     class="ip_access_list_table"
      ></cp-ip-access-list-table-view>
    </div>

    <div *ngIf="orgInstances.length === 0" class="no_other_instances_notice" data-cy="no-other-instances-notice">
      No services found
    </div>

    <div class="dialog_buttons_row">
      <button (click)="submit()" *ngIf="orgInstances.length > 0"
              [disabled]="selectedIpAccessListEntries.length === 0 || isSubmitInProgress"
              color="primary"
              mat-flat-button
              data-cy="submit-button"
      >
        <mat-icon *ngIf="isSubmitInProgress" class="button_loader_icon icon svg_stroke" svgIcon="loader"></mat-icon>
        Import {{nEntriesText}}
      </button>

      <a (click)="dialogRef.close()" class="cancel_button" data-cy="cancel-button">Cancel</a>
    </div>
  </ng-container>

</mat-dialog-content>
