<mat-dialog-content data-cy="edit-ip-access-list-entry-dialog">
  <h4>Edit entry</h4>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>

  <form (ngSubmit)="save()" [formGroup]="editEntryFormGroup" class="form">

    <div class="seed_form_element">
      <label>
        IP address or CIDR entries
        <cp-ip-access-list-info-icon></cp-ip-access-list-info-icon>
      </label>
      <input type="text" formControlName="value" data-cy="ip-input" spellcheck="false">
    </div>

    <div class="seed_form_element">
      <label>Description (optional)</label>
      <input type="text" formControlName="description" data-cy="description-input" spellcheck="false">
    </div>

    <div class="dialog_buttons_row">
      <button type="submit"
              [disabled]="!editEntryFormGroup.valid || !hasChanges() || isSubmitInProgress"
              color="primary"
              mat-flat-button
              data-cy="save-button"
      >
        <mat-icon *ngIf="isSubmitInProgress" class="button_loader_icon icon svg_stroke" svgIcon="loader"></mat-icon>
        Edit entry
      </button>

      <a (click)="dialogRef.close()" class="cancel_button" data-cy="cancel-button">Cancel</a>
    </div>

  </form>
</mat-dialog-content>
