import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BillPeriodDates,
  OrganizationUsagePeriodChart,
  OrganizationUsageReport,
  USAGE_METRIC_ARRAY,
  USAGE_METRIC_VIEW_TYPE_ARRAY,
  UsageMetric,
  UsageMetricValues,
  UsageMetricViewType
} from '@cp/common/protocol/Billing';
import { isDefined } from '@cp/common/protocol/Common';
import { InstanceCloudProvider } from '@cp/common/protocol/Instance';
import { Organization, OrganizationBillingStatus, OrganizationCommitment } from '@cp/common/protocol/Organization';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import {
  getMetricDetailedDescription,
  getShortUsageMetricName,
  getShortUsageMetricNameWithViewType
} from '@cp/common/utils/BillingUtils';
import { formatDateAndMonthAndDate } from '@cp/common/utils/FormatUtils';
import { checkArraysEqualityWithComparator, checkObjectEqualityByShallowCompare } from '@cp/common/utils/MiscUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import {
  AdminUsageChartComponentData,
  AdminUsageChartSeriesData
} from '@cp/web/app/admin/admin-usage-chart/admin-usage-chart.component';
import { AdminUsageStateService } from '@cp/web/app/admin/admin-usage/admin-usage-state.service';
import { AdminUsageService } from '@cp/web/app/admin/admin-usage/admin-usage.service';
import { AdminComponent, getAdminPathByTab } from '@cp/web/app/admin/admin.component';
import {
  ADMIN_USAGE_PAGE_METRIC_PARAM_NAME,
  getAdminUsageMetricFromUrl,
  getAdminUsageMetricUrlParamValue
} from '@cp/web/app/app-routing-utils';

import { formatDataSize } from '@cp/web/app/common/pipes/data-size.pipe';
import { getSeriesColorByIndex } from '@cp/web/app/common/utils/ChartUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { BehaviorSubject, distinctUntilChanged, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/** State of the component controls. Defines state all other internal components. */
interface AdminUsageComponentInput {
  organizationId?: string;
  /** Known date, undefined === default, null === error while resolving default. */
  billDate: string | undefined | null;
  chartMetric: UsageMetric;
}

/** Type safe column id in the table. */
type ReportTableTopLineColumnId = 'serviceNameTopLine' | UsageMetric;
type ReportTableColumnId = 'serviceName' | `${UsageMetric}/${UsageMetricViewType}`;

/**
 * For model for the material table in the report.
 * Contains name of the service and pre-formatted usage metric values by type.
 */
interface ReportTableRow extends Record<ReportTableColumnId, string> {
  /** Name of the service. */
  name: string;
  /** Color associated with the row. */
  color: string;
  /* Cloud provider of the service. */
  cloudProvider: InstanceCloudProvider;
}

/** Metric used by default by admin usage page. */
const DEFAULT_CHART_METRIC: UsageMetric = 'COMPUTE_UNIT_MINUTES';

@Component({
  selector: 'cp-admin-usage',
  templateUrl: './admin-usage.component.html',
  styleUrls: ['./admin-usage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminUsageComponent extends OnDestroyComponent {
  /** All input parameters that affect the final UI state: state of the controls elements, current organization, etc. */
  readonly inputObs: BehaviorSubject<AdminUsageComponentInput>;

  get input(): AdminUsageComponentInput {
    return this.inputObs.getValue();
  }

  /** Used to cancel scheduled report refreshes is the input has changed in between. */
  private cancelReportRefresh = false;

  /** Used to trigger the report refresh action. */
  private readonly reportRefreshSubject = new Subject<void>();

  /** Used to check what was changed since the last update to optimize 'refresh' logic. */
  private previousInput: AdminUsageComponentInput;

  /** Report data to render. */
  reportState: OrganizationUsageReport | 'LOADING' | 'ERROR' = 'LOADING';

  /** Chart data to render. */
  chartState: AdminUsageChartComponentData | 'LOADING' | 'ERROR' = 'LOADING';

  /** Rows in the report table. */
  reportTableInstanceRows: Array<ReportTableRow> = [];

  /** Total row data. Defined when there is a report. */
  reportTableTotalRow?: ReportTableRow;

  /**
   * Ids of columns in the report table.
   * A value for each cell in the column is stored in the row[id].
   */
  reportTableColumnIds: Array<ReportTableColumnId> = [];

  /**
   * Ids of top line columns in the report table.
   */
  readonly reportTopLineTableColumnIds = REPORT_TABLE_TOP_LINE_COLUMN_DESCRIPTORS.map<ReportTableTopLineColumnId>(
    (d) => d.columnId
  );

  /** Available metrics to show on the chart. */
  readonly chartTypeOptions: Array<SeedSelectOption<UsageMetric>> = REPORT_TABLE_METRIC_COLUMN_DESCRIPTORS.filter(
    (tableColumn) => tableColumn.isChartMetric
  ).map<SeedSelectOption>((tableColumn) => ({
    label: tableColumn.metricName,
    value: tableColumn.metric,
    dataCyValue: tableColumn.metric,
    dataCy: 'chart-metric-option'
  }));

  periodOptions: Array<SeedSelectOption<string>> = [];
  trialCommitmentStateObs: Observable<OrganizationCommitment>;
  billingStatusIsTrialRelatedObs: Observable<boolean>;
  usageDollarBreakdownFeatureEnabled = false;

  // Only show credit usage if the org is in one of these billing statuses.
  private readonly billingStatusesToShow: Set<OrganizationBillingStatus> = new Set([
    'IN_TRIAL',
    'IN_TRIAL_GRACE_PERIOD',
    'DECOMMISSIONED'
  ]);

  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceStateService: InstanceStateService,
    private readonly adminUsageService: AdminUsageService,
    private readonly adminStateUsageService: AdminUsageStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly currencyPipe: CurrencyPipe
  ) {
    super();

    this.inputObs = new BehaviorSubject<AdminUsageComponentInput>({
      organizationId: undefined,
      billDate: undefined,
      chartMetric: getAdminUsageMetricFromUrl(activatedRoute.snapshot) || DEFAULT_CHART_METRIC
    });
    this.previousInput = this.inputObs.getValue();

    this.organizationStateService
      .observeCurrentOrganizationId()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((organizationId) => {
        this.inputObs.next({ organizationId, billDate: undefined, chartMetric: this.input.chartMetric });
      });

    this.reportRefreshSubject.pipe(takeUntil(this.onDestroy)).subscribe(async () => {
      const { organizationId, billDate, chartMetric } = this.previousInput;
      if (organizationId === undefined) return; // Nothing to refresh.
      if (billDate === null) return; // Failed to fetch bill dates: do
      const callAgainSoon = await this.adminUsageService.refreshAdminUsageReport(organizationId, billDate, chartMetric);
      if (callAgainSoon) {
        this.cancelReportRefresh = false;
        setTimeout(() => {
          if (this.cancelReportRefresh) {
            return;
          }
          this.reportRefreshSubject.next();
        }, 5000);
      }
    });

    // A single place to update browser state (title, path) based on the current controls state.
    this.inputObs
      .pipe(distinctUntilChanged(checkObjectEqualityByShallowCompare), takeUntil(this.onDestroy))
      .subscribe((input) => this.updateBrowserState(input));

    // A single place to trigger data refresh on input parameters update.
    this.inputObs
      .pipe(distinctUntilChanged(checkObjectEqualityByShallowCompare), takeUntil(this.onDestroy))
      .subscribe((input) => {
        const { organizationId, billDate, chartMetric } = input;
        const prevInputState = this.previousInput;
        this.previousInput = input;
        if (organizationId === undefined) return; // Nothing to refresh.
        if (billDate === null) return; // Failed to fetch bill dates: do not refresh twice.
        if (organizationId !== prevInputState.organizationId) {
          this.reportRefreshSubject.next();
          return;
        }
        if (billDate != prevInputState.billDate) {
          // Do not 'refresh' the report twice when 'billDate' changes first time (from 'undefined').
          const newDateIsTheSameAsDefault = prevInputState.billDate === undefined;
          if (!newDateIsTheSameAsDefault) {
            this.reportRefreshSubject.next();
            return;
          }
        }
        if (chartMetric != prevInputState.chartMetric && billDate) {
          this.adminUsageService.refreshAdminUsageChart(organizationId, billDate, chartMetric).then();
        }
      });

    // Resubscribe to 'observeOrganizationBillDates' when organization changes and handle billing dates update.
    this.inputObs
      .pipe(
        distinctUntilChanged((p, n) => p.organizationId === n.organizationId),
        switchMap<AdminUsageComponentInput, Observable<Array<BillPeriodDates> | undefined | null>>((input) =>
          input.organizationId
            ? this.adminStateUsageService.observeOrganizationBillDates(input.organizationId)
            : of(undefined)
        ),
        distinctUntilChanged((p, n) => checkArraysEqualityWithComparator(p, n, checkObjectEqualityByShallowCompare)),
        takeUntil(this.onDestroy)
      )
      .subscribe((newBillDates) => {
        this.updatePeriodSelectorOptions(newBillDates);
        // If there is no current billDate or current billDate is not valid anymore -> update input.billDate.
        if (
          !this.input.billDate ||
          !newBillDates ||
          !newBillDates.some((dates) => dates.billDate === this.input.billDate)
        ) {
          const billDate = newBillDates ? newBillDates[0]?.billDate : newBillDates;
          this.inputObs.next({ ...this.input, billDate });
        }
        this.cdr.markForCheck();
      });

    // Resubscribe to 'observeOrganizationUsageReport' when organization or bill date changes and handle report updates.
    this.inputObs
      .pipe(
        distinctUntilChanged((p, n) => n.organizationId === p.organizationId && n.billDate === p.billDate),
        switchMap(
          ({ organizationId, billDate }) =>
            organizationId && billDate
              ? this.adminStateUsageService.observeOrganizationUsageReport(organizationId, billDate)
              : of(billDate === undefined ? undefined : null) // repeat the billDate state: undefined or null (error).
        ),
        takeUntil(this.onDestroy)
      )
      .subscribe((report) => {
        if (report) {
          this.cancelReportRefresh = true;
        }
        this.reportState = report === null ? 'ERROR' : report === undefined ? 'LOADING' : report;
        this.syncReportTableWithReportState();
        this.cdr.markForCheck();
      });

    // Resubscribe to 'observeOrganizationUsageReport' when organization id or bill date or chart metric  changes
    // and handle chart updates.
    this.inputObs
      .pipe(
        distinctUntilChanged(
          (p, n) =>
            n.organizationId === p.organizationId && n.billDate === p.billDate && n.chartMetric === p.chartMetric
        ),
        switchMap(
          ({ organizationId, billDate, chartMetric }) =>
            organizationId && billDate
              ? this.adminStateUsageService.observeOrganizationUsagePeriodChart(organizationId, billDate, chartMetric)
              : of(billDate === undefined ? undefined : null) // repeat the billDate state: undefined or null (error).
        ),
        takeUntil(this.onDestroy)
      )
      .subscribe((chart) => {
        this.chartState =
          chart === null ? 'ERROR' : chart === undefined ? 'LOADING' : this.prepareChartComponentData(chart);
        this.cdr.markForCheck();
      });

    this.trialCommitmentStateObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeTrialCommitmentState(id))
    );

    this.billingStatusIsTrialRelatedObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeBillingStatus(id)),
      map<OrganizationBillingStatus, boolean>((billingStatus) => this.billingStatusesToShow.has(billingStatus)),
      takeUntil(this.onDestroy)
    );

    organizationStateService
      .observeCurrentOrganization()
      .pipe(
        map<Organization, boolean>((o) => !o.features.includes('FT_GCP_ORG')),
        distinctUntilChanged((p, n) => n === p),
        takeUntil(this.onDestroy)
      )
      .subscribe((value) => {
        this.usageDollarBreakdownFeatureEnabled = value;
        this.reportTableColumnIds = REPORT_TABLE_COLUMN_DESCRIPTORS.filter(
          this.createColumIdsByFeatureFlagFilter(this.usageDollarBreakdownFeatureEnabled, true)
        ).map<ReportTableColumnId>((d) => d.columnId);
        this.cdr.markForCheck();
      });
  }

  get hasOrganizationUsageReport(): boolean {
    return typeof this.reportState === 'object';
  }

  get report(): OrganizationUsageReport {
    assertTruthy(this.hasOrganizationUsageReport);
    return this.reportState as OrganizationUsageReport;
  }

  onChartTypeChange({ value: chartMetric }: SeedSelectOption<UsageMetric>): void {
    if (chartMetric === this.input.chartMetric) {
      //  May happen during initial date update after report is fetched.
      //  TODO: seed-select should not report input values back.
      return;
    }
    this.inputObs.next({ ...this.input, chartMetric });
  }

  onPeriodChange({ value: billDate }: SeedSelectOption<string>): void {
    if (billDate === this.input.billDate) {
      //  May happen during initial date update after report is fetched.
      //  TODO: seed-select should not report input values back.
      return;
    }
    this.inputObs.next({ ...this.input, billDate });
  }

  /** Prepares chart component data from the report data: adds UI options like colors and display texts */
  private prepareChartComponentData(reportChartData: OrganizationUsagePeriodChart): AdminUsageChartComponentData {
    return {
      startDate: Date.parse(reportChartData.startDate),
      endDate: Date.parse(reportChartData.endDate),
      perInstanceData: Object.keys(reportChartData.perInstanceData).reduce(
        (map, instanceId, index) =>
          map.set(instanceId, {
            name: reportChartData.instanceNameById[instanceId] || instanceId,
            color: getSeriesColorByIndex(index), //TODO: indexes (and so colors) are not synchronized with the table!
            data: truthy(
              this.usageDollarBreakdownFeatureEnabled
                ? reportChartData.websitePerInstanceData[instanceId]
                : reportChartData.perInstanceData[instanceId]
            )
          }),
        new Map<string, AdminUsageChartSeriesData>()
      ),
      valueFormatter: (value: number): string =>
        formatDataSize(value, this.usageDollarBreakdownFeatureEnabled ? 'human-readable-number' : 'numeric-symbols')
    };
  }

  private updatePeriodSelectorOptions(billDates: Array<BillPeriodDates> | undefined | null): void {
    this.periodOptions = (billDates || []).map<SeedSelectOption>((billDates) => ({
      label: formatBillDateLabel(billDates),
      value: billDates.billDate,
      dataCyValue: billDates.billDate,
      dataCy: 'period-option'
    }));
  }

  /** Converts org report data into table column models with a pre-formatted values. */
  private createTableRow(
    name: string,
    color: string,
    record: UsageMetricValues,
    cloudProvider?: InstanceCloudProvider
  ): ReportTableRow {
    const row: ReportTableRow = { name, color, cloudProvider } as ReportTableRow;

    for (const column of REPORT_TABLE_COLUMN_DESCRIPTORS) {
      row[column.columnId] = this.generateTableRowContent(column, record, name);
    }
    return row;
  }

  private generateTableRowContent(
    column: ServiceColumnDescriptor | ReportTableColumnDescriptor,
    record: UsageMetricValues,
    name: string
  ): string {
    if (column.columnId === 'serviceName') {
      // Return column header.
      return name;
    }

    const thisRecord = record[column.metric];
    if (column.viewType === 'COST') {
      // This is a workaround for the bug described in https://github.com/ClickHouse/control-plane/issues/3217
      const cost = thisRecord.metricValue === 0 ? 0 : thisRecord.cost;
      if (cost != undefined) {
        // This record's cost is defined, return it nicely formatted, or return '$0.00'.
        return this.currencyPipe.transform(cost, 'USD') ?? '$0.00';
      }

      // Cost is not defined (the statement is still being processed), use 'LOADING' as a placeholder for now.
      return 'LOADING';
    }

    // Select value to display based on the feature flag.
    const valueToDisplay = this.usageDollarBreakdownFeatureEnabled
      ? record[column.metric].websiteUnitMetricValue
      : record[column.metric].metricValue;

    // Format the value selected.
    return formatMetricValue(column.viewType, valueToDisplay);
  }

  private syncReportTableWithReportState(): void {
    this.reportTableInstanceRows = [];
    this.reportTableTotalRow = undefined;
    if (typeof this.reportState !== 'object') {
      return;
    }

    const report: OrganizationUsageReport = this.reportState;
    // Helper to convert a usage report row into a pre-formatted table row.

    for (let i = 0; i < report.instanceReports.length; i++) {
      const instanceReport = report.instanceReports[i];
      const name = instanceReport.instanceName;
      const cloudProvider = instanceReport.instanceCloudProvider;
      const color = getSeriesColorByIndex(i);
      this.reportTableInstanceRows.push(this.createTableRow(name, color, instanceReport, cloudProvider));
    }
    // Color for the total row's square icon is hardcoded in the SCSS file.
    this.reportTableTotalRow = this.createTableRow('Total', 'inherit', report.totalUsageReport);
  }

  getColumnDescriptor(columnId: ReportTableColumnId): ReportTableColumnDescriptor {
    return truthy(REPORT_TABLE_COLUMN_DESCRIPTORS_MAP.get(columnId));
  }

  /** List of column ids related to metrics. */
  get metricColumnIds(): Array<ReportTableColumnId> {
    return REPORT_TABLE_COLUMN_DESCRIPTORS.filter(
      this.createColumIdsByFeatureFlagFilter(this.usageDollarBreakdownFeatureEnabled)
    ).map((d) => d.columnId);
  }

  private createColumIdsByFeatureFlagFilter(
    usageDollarBreakdownFeatureEnabled: boolean,
    allowServiceName = false
  ): (o: ServiceColumnDescriptor | ReportTableColumnDescriptor) => boolean {
    return (o) =>
      (allowServiceName && o.columnId === 'serviceName') ||
      (usageDollarBreakdownFeatureEnabled
        ? o.columnId.includes('UNITS') || o.columnId.includes('COST')
        : o.columnId.includes('WEBSITE') || o.columnId.includes('BILLING'));
  }

  /** List of column ids related to metrics. */
  get topLineMetricColumns(): Array<TopLineColumnDescriptor> {
    return REPORT_TABLE_TOP_LINE_COLUMN_DESCRIPTORS.filter((cd) => cd.columnId !== 'serviceNameTopLine');
  }

  /** Returns 'true' if there is a valid and non-empty chart data to display. */
  get hasNonEmptyChartData(): boolean {
    // Check chat chart data is available and  any of the data series is not empty.
    return (
      typeof this.chartState === 'object' &&
      [...this.chartState.perInstanceData.values()].some((dataSeries) => dataSeries.data.length > 0)
    );
  }

  get chartData(): AdminUsageChartComponentData {
    assertTruthy(this.hasNonEmptyChartData);
    return this.chartState as AdminUsageChartComponentData;
  }

  /** Updates browser state (url, title, etc...) based on the current controls state. */
  private updateBrowserState(state: AdminUsageComponentInput): void {
    const queryParams: string[] = [];
    this.cancelReportRefresh = true;
    if (state.chartMetric !== DEFAULT_CHART_METRIC) {
      queryParams.push(`${ADMIN_USAGE_PAGE_METRIC_PARAM_NAME}=${getAdminUsageMetricUrlParamValue(state.chartMetric)}`);
    }
    const organizationId = state.organizationId || this.organizationStateService.getCurrentOrgIdOrFail();
    let path = AdminComponent.buildCanonicalPagePath(organizationId, getAdminPathByTab('USAGE'));
    if (queryParams.length > 0) {
      path += `?${queryParams.join('&')}`;
    }
    window.history.replaceState(null, '', path);
  }

  checkIfLastMetricColumn(viewType: UsageMetricViewType) {
    return viewType === 'BILLING' || viewType === 'COST';
  }
}

/**
 * Formats bill dates to display text of select options.
 * Example: Oct 2 - Today, Mar 2 - Apr 2,
 */
function formatBillDateLabel({ startDate, endDate }: BillPeriodDates): string {
  const startMillis = Date.parse(startDate);
  const endMillis = Date.parse(endDate);
  const isCurrentPeriod = startMillis <= Date.now() && endMillis > Date.now();
  const startToken = formatDateAndMonthAndDate(startDate);
  const endToken = isCurrentPeriod ? 'Today' : formatDateAndMonthAndDate(endDate);
  return `${startToken} - ${endToken}`;
}

/** Static report table column details with no data included. */
interface TopLineColumnDescriptor {
  columnId: ReportTableTopLineColumnId;
  headerName: string;
}

/** Static report table column details with no data included. */
interface ServiceColumnDescriptor {
  columnId: 'serviceName';
  headerName: string;
}

/** Static report table column details with no data included. */
interface ReportTableColumnDescriptor {
  columnId: ReportTableColumnId;
  viewType: UsageMetricViewType;
  metric: UsageMetric;
  /** Text shown in the table header. */
  headerName: string;
  /** Text shown in the chart drop down. */
  metricName: string;
  /** Tooltip text. */
  tooltip?: string;
  /** If true, we can show a chart for this column. */
  isChartMetric?: boolean;
}

/** Creates new column descriptor for the metric. */
function newTopLineColumn(metric: UsageMetric): TopLineColumnDescriptor {
  return {
    columnId: metric,
    headerName: getShortUsageMetricName(metric)
  };
}

/** Creates new column descriptor for the metric. */
function newMetricColumn(metric: UsageMetric): Array<ReportTableColumnDescriptor> {
  return USAGE_METRIC_VIEW_TYPE_ARRAY.map<ReportTableColumnDescriptor>((viewType) => ({
    columnId: `${metric}/${viewType}`,
    metric,
    viewType,
    headerName: getShortUsageMetricNameWithViewType(metric, viewType),
    metricName: getShortUsageMetricName(metric),
    tooltip: getMetricDetailedDescription(metric)[viewType],
    isChartMetric: viewType === 'BILLING'
  }));
}

/** List of metric columns in the report table's top line. */
const REPORT_TABLE_TOP_LINE_COLUMN_DESCRIPTORS: ReadonlyArray<TopLineColumnDescriptor> = [
  { columnId: 'serviceNameTopLine', headerName: 'Service' },
  ...USAGE_METRIC_ARRAY.map(newTopLineColumn)
];

/** List of metric columns in the report table. */
const REPORT_TABLE_COLUMN_DESCRIPTORS: ReadonlyArray<ServiceColumnDescriptor | ReportTableColumnDescriptor> = [
  { columnId: 'serviceName', headerName: 'Service' },
  ...USAGE_METRIC_ARRAY.flatMap(newMetricColumn)
];

const REPORT_TABLE_METRIC_COLUMN_DESCRIPTORS: ReadonlyArray<ReportTableColumnDescriptor> =
  REPORT_TABLE_COLUMN_DESCRIPTORS.filter((cd) => cd.columnId !== 'serviceName') as Array<ReportTableColumnDescriptor>;

const REPORT_TABLE_COLUMN_DESCRIPTORS_MAP: ReadonlyMap<string, ReportTableColumnDescriptor> =
  USAGE_METRIC_ARRAY.flatMap(newMetricColumn).reduce(
    (map, d) => map.set(d.columnId, d),
    new Map<ReportTableColumnId, ReportTableColumnDescriptor>()
  );

/** Formats numeric metric value into string display value. */
function formatMetricValue(viewType: UsageMetricViewType, value: number): string {
  switch (viewType) {
    case 'WEBSITE':
      return formatDataSize(value, 'human-readable-number');
    case 'UNITS':
      return formatDataSize(value, 'human-readable-number');
    default:
      return formatDataSize(value, 'numeric-symbols');
  }
}
