import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AccountApiService } from '@cp/web/app/account/account-api.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';

@Injectable({
  providedIn: 'root'
})
export class OauthResolver implements Resolve<boolean> {
  constructor(
    private readonly signInMetadataService: SignInMetadataService,
    private readonly accountApiService: AccountApiService,
    private readonly accountService: AccountService
  ) {}

  resolve(): Promise<boolean> {
    return new Promise((res) => {
      this.accountService.isOauth2Flow().then((isOauth2Flow) => {
        /*
         * the purpose of this resolver is to stop the navigation
         * until the oauth redirect (if present) has happened.
         * Resolving either to true or false will make the navigation continue.
         *
         * If the oauth Params are defined,
         * it means that there is a redirect ongoing
         * and therefore we shouldn’t resolve
         * (otherwise the user will be redirected to control plane first
         * and then to the final route)*/
        if (!isOauth2Flow) {
          res(true);
        }
      });
    });
  }
}
