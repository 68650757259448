import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'cp-oauth-authorizer',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OauthAuthorizerComponent extends OnDestroyComponent implements OnInit {
  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly accountService: AccountService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.accountStateService
      .observeAuthenticated()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(async (isAuthenticated) => {
        if (!this.accountService.hasOauth2FlowParamsInUrl() || !isAuthenticated) {
          await this.router.navigate(['/signIn'], { queryParams });
        }
      });
  }
}
