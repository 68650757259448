<mat-dialog-content data-cy="import-ip-access-list-from-file-dialog">
  <h4>Import from JSON</h4>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <div class="info_block">
    Add and manage IP addresses at scale by uploading a JSON file.
  </div>

  <div class="download_block">
    <div class="block_title">Download</div>
    <div class="block_content">
      <button (click)="downloadTemplateFile()"
              mat-flat-button
              class="download_template_button"
              data-cy="download-template-button"
      >
        <mat-icon svgIcon="download" class="download_template_icon"></mat-icon>
        Template JSON file
      </button>
      <div class="right_light_text">
        View how to structure your JSON to upload multiple IP address.
      </div>
    </div>
  </div>

  <div class="upload_block">
    <div class="block_title">Upload</div>
    <div class="block_content">
      <div>
        <input type="file" #fileInput hidden (change)="handleFileInput($event)" data-cy="file-input">
        <button (click)="fileInput.click()" mat-flat-button
                class="upload_file_button"
                data-cy="upload-file-button">
          <mat-icon svgIcon="upload_cloud" class="upload_file_icon"></mat-icon>
          Completed JSON file
        </button>

      </div>
      <div class="right_light_text">
        Upload file after editing the Template JSON with the desired IP addresses
      </div>
    </div>
  </div>

  <div class="file_list_block" data-cy="file-list-block">
    <div *ngFor="let file of files; let fileIndex = index; trackBy: trackByName"
         class="json_file_block"
         data-cy="json-file"
         [attr.data-cy-index]="fileIndex"
    >
      <div data-cy="file-name">{{file.name}}</div>
      <a (click)="removeFileFromList(file)" class="json_file_delete_button" data-cy="delete-button">×</a>
    </div>
  </div>

  <cw-system-message *ngIf="errorMessage" class="error_message">{{errorMessage}}</cw-system-message>

  <div class="buttons">
    <button (click)="submitFiles()"
            [disabled]="isButtonSubmitDisabled"
            class="submit_button"
            color="primary"
            mat-flat-button
            data-cy="submit-button"
    >
      <mat-icon *ngIf="isSubmitInProgress" class="button_loader_icon icon svg_stroke" svgIcon="loader"></mat-icon>
      Submit new entries
    </button>

    <a (click)="dialogRef.close()" class="cancel_button" data-cy="cancel-button">Cancel</a>

  </div>
</mat-dialog-content>
