import { INSTANCE_BACKUP_MAX_INCREMENTAL_CHAIN_LENGTH } from '@cp/common/protocol/Backup';
import type { RangeSettings } from '@cp/common/protocol/features/Features';
import { INSTANCE_TIERS, MIN_AUTOSCALING_PRODUCTION_MEMORY } from '@cp/common/protocol/Instance';

/** List of all instance level registered in the system. */
export const INSTANCE_FEATURES = [
  /** Instance uses the new incremental backup logic. */
  'FT_INCREMENTAL_BACKUP',
  // Permanent FF that don't have a removal date.
  /** Flag to check the instance features flag system itself. */
  'FT_INSTANCE_SELF_TEST',
  /** Automatic backup is disabled for an instance with this feature. */
  'FT_NO_AUTO_BACKUP',
  /**
   * Flag to assign custom autoscale values in addition to the default autoscale values.
   * The custom values are stored as list of numbers in the Instance model under autoscaleCustomValues.
   */
  'FT_CUSTOM_AUTOSCALE_VALUES',
  /** Sets custom backup period per instance. The custom period is stored in the Instance model under backupPeriodHours optional field. */
  'FT_CUSTOM_BACKUP_PERIOD',
  /** Disable autoscaling configuration for an instance. */
  'FT_DISABLE_AUTOSCALING',
  /** Disable full backups for an instance. An instance with this FF will still have incremental backups every backup period. */
  'FT_DISABLE_FULL_BACKUP',
  /** Enables new metric clusters for GCP instances (no effect is applied to AWS instance). */
  'FT_INSTANCE_USE_GCP_METRIC_CLUSTER',
  /** Disables reporting of billing metrics to m3ter temporarily for this service. */
  'FT_INSTANCE_DISABLE_BILLING_METRICS_REPORTING',
  /** Enables users to use GPT for query completion and correction*/
  'FT_INSTANCE_USE_GPT',
  /** Sets custom incremental backup chain length per instance. The custom period is stored in the Instance model under backupPeriodHours optional field. */
  'FT_INSTANCE_CUSTOM_INCREMENTAL_BACKUP_CHAIN_LENGTH',
  /** This flags enables clickpipes to always use the same instance region as the clickpipes region if it's available. Enable this to use experimental regions*/
  'FT_USE_INSTANCE_REGION_FOR_CLICKPIPES',
  /** Instance has private endpoints configured through data plane.
   * This will block the user from editing private endpoints through control
   * plane. Once the private endpoints are migrated, this feature can be removed
   * from the instance
   */
  'FT_INSTANCE_LEGACY_PRIVATE_ENDPOINT',
  /** Sets a custom number of backups to retain. The value is stored in the Instance model under countOfBackupsToRetain optional field. */
  'FT_INSTANCE_CUSTOM_BACKUPS_TO_RETAIN'
] as const;

export type InstanceFeatureId = (typeof INSTANCE_FEATURES)[number];

export type InstanceFeatureIdWithDataPayload =
  | 'FT_CUSTOM_BACKUP_PERIOD'
  | 'FT_CUSTOM_AUTOSCALE_VALUES'
  | 'FT_INSTANCE_CUSTOM_INCREMENTAL_BACKUP_CHAIN_LENGTH'
  | 'FT_INSTANCE_CUSTOM_BACKUPS_TO_RETAIN';

const ALL_INSTANCE_FEATURE_FLAG_SET: ReadonlySet<InstanceFeatureId> = new Set<InstanceFeatureId>([
  ...INSTANCE_FEATURES
]);

export const INSTANCE_FEATURE_WITH_DATA_PAYLOAD_SETTINGS: Record<InstanceFeatureIdWithDataPayload, RangeSettings> = {
  FT_CUSTOM_BACKUP_PERIOD: {
    minValue: 1,
    maxValue: 1000,
    defaultValue: 72
  },
  FT_CUSTOM_AUTOSCALE_VALUES: {
    minValue: MIN_AUTOSCALING_PRODUCTION_MEMORY,
    maxValue: 1080,
    defaultValue: ''
  },
  FT_INSTANCE_CUSTOM_INCREMENTAL_BACKUP_CHAIN_LENGTH: {
    minValue: 1,
    maxValue: 20,
    defaultValue: INSTANCE_BACKUP_MAX_INCREMENTAL_CHAIN_LENGTH
  },
  FT_INSTANCE_CUSTOM_BACKUPS_TO_RETAIN: {
    minValue: 1,
    maxValue: 20,
    defaultValue: INSTANCE_TIERS['Production'].countOfBackupsToRetainInDb
  }
};

export function isInstanceFeatureId(value: unknown): value is InstanceFeatureId {
  return ALL_INSTANCE_FEATURE_FLAG_SET.has(value as InstanceFeatureId);
}

export function isInstanceFeatureIdWithDataPayload(value: unknown): value is InstanceFeatureIdWithDataPayload {
  return (value as InstanceFeatureIdWithDataPayload) in INSTANCE_FEATURE_WITH_DATA_PAYLOAD_SETTINGS;
}
