import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { InstanceEndpoint } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { removeNewlinesAndBackslashesTransformer } from '@cp/web/app/common/utils/CopyTransformers';

@Component({
  selector: 'cp-https-endpoint-details',
  templateUrl: './https-endpoint-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HttpsEndpointDetailsComponent implements OnInit, OnChanges {
  readonly removeNewlinesAndBackslashesTransformer = removeNewlinesAndBackslashesTransformer;

  @Input()
  instanceEndpoint!: InstanceEndpoint;

  @Input()
  username!: string;

  @Input()
  password?: string;

  text?: string;
  params: Record<string, any> = {};

  ngOnInit(): void {
    this.checkRequiredFields();
  }

  ngOnChanges(): void {
    this.checkRequiredFields();
    this.params = {
      replacePasswordHere: this.password ? this.password : '<password>'
    };
    this.text = `curl --user '${this.username}:$$$replacePasswordHere' \\
     --data-binary 'SELECT 1' \\
     https://${this.instanceEndpoint.hostname}:${this.instanceEndpoint.port}`;
  }

  private checkRequiredFields(): void {
    assertTruthy(this.instanceEndpoint);
    assertTruthy(this.username);
  }
}
