import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { trackById } from '@cp/common-services/trackById';
import { Instance } from '@cp/common/protocol/Instance';
import {
  InstancePermissionsState,
  userPermissionsSummary,
  UserPermissionsSummary
} from '@cp/common/utils/DbUserPermissions';
import { downloadCsv } from '@cp/cp-common-web/DownloadUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { getConsoleUrl } from '@cp/web/app/common/utils/WebConsoleUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import {
  checkIfPathBelongsToPage,
  PageComponentWithOrganizationId
} from '@cp/web/app/organizations/current-organization.helper';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { map, takeUntil } from 'rxjs/operators';
import { WakeIdleServicesDialogComponent } from './wake-idle-services-dialog/wake-idle-services-dialog.component';

export interface SelectedInstanceUser {
  instanceId: string;
  userName: string;
}

@Component({
  selector: 'manage-db-users',
  templateUrl: './manage-db-users.component.html',
  styleUrls: ['./manage-db-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageDbUsersComponent extends OnDestroyComponent implements PageComponentWithOrganizationId {
  trackById = trackById;

  readonly buildCanonicalPagePathWithOrganizationId = (id: string) => `organizations/${id}/db-users`;

  instances = new Array<Instance>();

  selectedUser?: SelectedInstanceUser | undefined;

  instancePermissions: Record<string, InstancePermissionsState | undefined> = {};

  userDetailsVisible = false;

  constructor(
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceService: InstanceService,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly organizationStateService: OrganizationStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog
  ) {
    super();

    this.instanceStateService
      .observeInstances()
      .pipe(
        takeUntil(this.onDestroy),
        map((instancesMap) => Object.values(instancesMap).sort((a, b) => b.creationDate - a.creationDate))
      )
      .subscribe((instances) => {
        this.instances = instances;
        this.loadUnloadedRunningInstances();
        this.cdr.markForCheck();
      });
  }

  loadUnloadedRunningInstances() {
    for (const instance of this.instances) {
      if (instance.state === 'running' && this.permissionsForInstance(instance.id).status === 'unloaded') {
        this.loadPermissions(instance.id);
      }
    }
  }

  loadAllLoadableInstances(): void {
    for (const instance of this.instances) {
      const permissions = this.permissionsForInstance(instance.id);
      const unloaded = ['unloaded', 'error'].includes(permissions.status);
      if ((instance.state === 'running' || instance.state === 'idle' || instance.state === 'awaking') && unloaded) {
        this.loadPermissions(instance.id);
      }
    }
  }

  anyUnloadedInstances(): boolean {
    return this.instances.some((instance) => {
      const loadable = instance.state === 'running' || instance.state === 'idle' || instance.state === 'awaking';
      const permissions = this.permissionsForInstance(instance.id);
      const unloaded = ['unloaded', 'error'].includes(permissions.status);
      return loadable && unloaded;
    });
  }

  showLoadAllDialog(): void {
    const dialogRef = this.dialog.open(WakeIdleServicesDialogComponent, {
      width: '100%',
      maxWidth: '512px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.loadAllLoadableInstances();
      }
    });
  }

  selectUser(user: SelectedInstanceUser | undefined) {
    if (!user) {
      this.userDetailsVisible = false;
    } else {
      this.userDetailsVisible = true;
      this.selectedUser = user;
    }
  }

  selectedUserPermissions(): UserPermissionsSummary | undefined {
    if (!this.selectedUser) {
      return undefined;
    }
    const { instanceId, userName } = this.selectedUser;
    const state = this.instancePermissions[instanceId];
    if (state?.status === 'loaded') {
      return state.users.find((user) => user.username === userName);
    } else {
      return undefined;
    }
  }

  permissionsForInstance(instanceId: string): InstancePermissionsState {
    const state = this.instancePermissions[instanceId];
    if (!state) {
      return { status: 'unloaded' };
    } else {
      return state;
    }
  }

  selectedUserInstanceName(): string | undefined {
    if (!this.selectedUser) {
      return undefined;
    }
    const { instanceId } = this.selectedUser;
    return this.instances.find((instance) => instance.id === instanceId)?.name;
  }

  /** Returns true if the given path part or the URL is handled by the current page component. */
  static isPagePath(path: string): boolean {
    return checkIfPathBelongsToPage(path, 'organizations', 'db-users', '/organization/db-users');
  }

  async loadPermissions(instanceId: string): Promise<void> {
    this.instancePermissions[instanceId] = {
      status: 'loading'
    };

    const apiResult = await this.instanceService.getInstanceDbPermissions(instanceId);
    if (apiResult.type === 'success') {
      this.instancePermissions[instanceId] = {
        status: 'loaded',
        users: apiResult.users.map((user) => userPermissionsSummary(apiResult.grants, apiResult.roleGrants, user.name))
      };
    } else {
      this.instancePermissions[instanceId] = {
        status: 'error',
        error: apiResult.error
      };
    }

    this.cdr.markForCheck();
  }

  exportCsv(): void {
    const rows = [['Service', 'User', 'Role', 'Access Type', 'Database', 'Table', 'Column']];

    for (const instance of this.instances) {
      const state = this.instancePermissions[instance.id];

      if (state?.status === 'loaded') {
        for (const user of state.users) {
          for (const role of user.roleGrants) {
            for (const grant of role.grants) {
              rows.push([
                instance.name,
                user.username,
                role.roleName,
                grant.accessType,
                grant.database ?? '',
                grant.table ?? '',
                grant.column ?? ''
              ]);
            }
          }

          for (const grant of user.directGrants) {
            rows.push([
              instance.name,
              user.username,
              '',
              grant.accessType,
              grant.database ?? '',
              grant.table ?? '',
              grant.column ?? ''
            ]);
          }
        }
      }
    }

    downloadCsv(rows, 'clickhouse-db-users.csv');
  }

  getSqlConsoleUri(): string {
    return getConsoleUrl();
  }
}
