import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountStateService } from '@cp/web/app/account/account-state.service';

@Component({
  selector: 'cp-org-invite-expired-dialog',
  templateUrl: './org-invite-expired-dialog.component.html',
  styleUrls: ['./org-invite-expired-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgInviteExpiredDialogComponent implements OnInit {
  isAuthenticated: boolean = false;

  constructor(
    public readonly dialogRef: MatDialogRef<OrgInviteExpiredDialogComponent>,
    private readonly accountStateService: AccountStateService,
    private readonly router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = await this.accountStateService.isAuthenticated();
  }

  onClose(): void {
    this.dialogRef.close();
    if (this.isAuthenticated) {
      this.router.navigateByUrl('/').then();
    } else {
      this.router.navigateByUrl('/signUp').then();
    }
  }

  static show(dialog: MatDialog): void {
    dialog.open(OrgInviteExpiredDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      minHeight: '269px',
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
      panelClass: 'modal'
    });
  }
}
