import { Injectable, Injector } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { MfaType, SignInWithCredentialsResponse } from '@cp/common-services/web-auth';
import { GetUserDetailsResponse } from '@cp/common/protocol/Account';
import { isDefined } from '@cp/common/protocol/Common';
import { OrganizationInvitationDetails } from '@cp/common/protocol/Organization';
import { WebSocketAccountStateInterface } from '@cp/common/protocol/WebSocket';
import { truthy } from '@cp/common/utils/Assert';
import { convertArrayToRecord } from '@cp/common/utils/MiscUtils';
import { AccountState } from '@cp/web/app/account/protocol/AccountStates';
import { AuthService } from '@cp/web/app/auth/auth.service';
import { GalaxyService } from '@cp/web/app/common/services/galaxy.service';
import { distinctUntilChanged, firstValueFrom, mapTo, Observable, race, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountStateService extends BaseUiStateService<AccountState> implements WebSocketAccountStateInterface {
  constructor(
    stateService: StateService,
    private readonly injector: Injector
  ) {
    super(['account'], stateService);
  }

  observeUserId(): Observable<string | undefined> {
    return this.stateService
      .observePath<string>([...this.STATE_PATH, 'userDetails', 'userId'])
      .pipe(distinctUntilChanged());
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const tokenIsValid = !!(await this.injector.get(AuthService).getAccessToken());
      const hasUserDetails = firstValueFrom(
        this.stateService
          .observePath<boolean>([...this.STATE_PATH, 'userDetails'])
          .pipe(filter(isDefined), map(Boolean))
      );
      return tokenIsValid && hasUserDetails;
    } catch (error) {
      return false;
    }
  }

  observeAuthenticated(): Observable<boolean> {
    return this.stateService
      .observePath<GetUserDetailsResponse | null>([...this.STATE_PATH, 'userDetails'])
      .pipe(filter(isDefined), map(Boolean));
  }

  observeIsAwaitingTotpVerification(): Observable<boolean> {
    return this.stateService
      .observePath<boolean>([...this.STATE_PATH, 'mfaSignInData', 'isAwaitingTotpVerification'])
      .pipe(map(Boolean));
  }

  setMfaSignInData(mfaData: SignInWithCredentialsResponse): void {
    this.stateService.setInPath([...this.STATE_PATH, 'mfaSignInData'], mfaData);
  }

  observeMfaPreferredMethod(): Observable<MfaType> {
    return this.stateService.observePath<MfaType>([...this.STATE_PATH, 'userDetails', 'mfaPreferredMethod']);
  }

  waitForAuthenticatedState(): Observable<boolean> {
    return race(this.observeAuthenticated().pipe(filter(Boolean)), timer(10000).pipe(mapTo(false)));
  }

  getIsAuthenticated(): boolean {
    return !!this.getStateForKey('userDetails');
  }

  updateUserDetails(update: Partial<GetUserDetailsResponse>): void {
    this.stateService.runInBatch(() => {
      const userDetails = this.getUserDetailsOrFail();
      this.setStateKey('userDetails', { ...userDetails, ...update });
    });
  }

  setUserDetails(userDetails: GetUserDetailsResponse | null): void {
    this.stateService.runInBatch(() => {
      if (!userDetails) {
        this.stateService.setInPath([], {});
      }
      this.setStateKey('userDetails', userDetails);
      this.injector.get(GalaxyService).trace('AccountStateService::setUserDetails', userDetails);
    });
  }

  observeUserDetails(): Observable<GetUserDetailsResponse | null> {
    return this.stateService
      .observePath<GetUserDetailsResponse | null>([...this.STATE_PATH, 'userDetails'])
      .pipe(filter(isDefined));
  }

  getUserDetailsOrFail(): GetUserDetailsResponse {
    return truthy(this.stateService.getStateInPath<GetUserDetailsResponse>([...this.STATE_PATH, 'userDetails']));
  }

  getUserIdOrFail(): string {
    return truthy(this.stateService.getStateInPath<string>([...this.STATE_PATH, 'userDetails', 'userId']));
  }

  getUserId(): string | undefined {
    return this.stateService.getStateInPath<string>([...this.STATE_PATH, 'userDetails', 'userId']);
  }

  setOrgInviteKey(orgInviteKey?: string): void {
    this.setStateKey('orgInviteKey', orgInviteKey);
  }

  getOrgInviteKey(): string | undefined {
    return this.getStateForKey('orgInviteKey');
  }

  setOrgInviteEmail(orgInviteEmail?: string): void {
    this.setStateKey('orgInviteEmail', orgInviteEmail);
  }

  getOrgInviteEmail(): string | undefined {
    return this.getStateForKey('orgInviteEmail');
  }

  setOrgInvitations(invitations: Array<OrganizationInvitationDetails>): void {
    const invitationsRecord = Array.isArray(invitations) ? convertArrayToRecord(invitations) : invitations;
    this.stateService.setInPath([...this.STATE_PATH, 'invitations'], invitationsRecord);
  }

  observeOrgInvitations(): Observable<Array<OrganizationInvitationDetails>> {
    return this.stateService
      .observePath<Record<string, OrganizationInvitationDetails>>([...this.STATE_PATH, 'invitations'])
      .pipe(
        filter(isDefined),
        distinctUntilChanged(),
        map((invitationMap) => {
          return Object.values(invitationMap).sort((a, b) => Number(b.isExpired) - Number(a.isExpired));
        })
      );
  }
}
