import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApproximateTimePipe } from '@cp/common-services/pipes/approximate-time.pipe';
import { InstanceBackup, isBackupFinished } from '@cp/common/protocol/Backup';
import { isDefined } from '@cp/common/protocol/Common';
import {
  DEDICATED_NUM_REPLICAS,
  GetInstanceAutoScalingResponse,
  Instance,
  INSTANCE_TIERS_THAT_ARE_DEDICATED
} from '@cp/common/protocol/Instance';
import { Region, REGION_BY_ID } from '@cp/common/protocol/Region';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { getConsoleUrl } from '@cp/web/app/common/utils/WebConsoleUtils';
import { InstanceBackupStateService } from '@cp/web/app/instances/instance-backups/instance-backup-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { distinctUntilChanged, Observable, ReplaySubject, switchMap } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { InstanceStateService } from '../instance-state.service';

interface TierDetails {
  tier: string;
  ratio: number;
}

interface InstanceSizeDetails {
  numCpus: number;
  nodeSize: number;
  numReplicas: number;
}

/**
 * Service summary widget on the Summary tab of the Service (Instance) page on top of the Metrics Dashboard.
 * https://www.figma.com/file/CZDTAPm1l3fqjN4EHPlwzt/Private-Preview.
 */
@Component({
  selector: 'cp-instance-page-summary',
  templateUrl: './instance-page-summary.component.html',
  styleUrls: ['./instance-page-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstancePageSummaryComponent extends OnDestroyComponent implements OnInit, OnChanges {
  @Input() instanceId!: string;
  @Input() hasUserData!: boolean;

  instance!: Instance;

  /** Observes last instance backup state. */
  readonly lastBackupDateObs: Observable<string>;
  readonly instanceObs: Observable<Instance>;
  readonly scalingInfoObs: Observable<GetInstanceAutoScalingResponse>;
  /** Last instance id from the @Input(). */
  private readonly instanceIdSubject = new ReplaySubject<string>(1);

  isDedicated = false;

  constructor(
    private readonly instanceBackupStateService: InstanceBackupStateService,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly approximateTimePipe: ApproximateTimePipe,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();

    this.lastBackupDateObs = this.instanceIdSubject.pipe(
      distinctUntilChanged(),
      switchMap((instanceId) =>
        instanceBackupStateService.observeInstanceBackups(instanceId).pipe(
          map((instanceBackupMap: Record<string, InstanceBackup>) => {
            const backupsSortedByDate = Object.values(instanceBackupMap)
              .filter(isBackupFinished)
              .sort((b1, b2) => b1.finished - b2.finished);
            const lastBackup = backupsSortedByDate[backupsSortedByDate.length - 1];
            if (!lastBackup) {
              return 'Not available';
            }
            const approximateTime = this.approximateTimePipe.transform(lastBackup.createdAt);
            return `${approximateTime}`;
          })
        )
      )
    );

    this.instanceObs = this.instanceIdSubject.pipe(
      filter(isDefined),
      distinctUntilChanged(),
      switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId).pipe(filter(isDefined))),
      takeUntil(this.onDestroy)
    );

    this.instanceObs.subscribe((instance) => {
      this.instance = instance;
      this.isDedicated = INSTANCE_TIERS_THAT_ARE_DEDICATED.has(this.instance.instanceTier);
      this.cdr.markForCheck();
    });

    this.scalingInfoObs = this.instanceIdSubject.pipe(
      filter(isDefined),
      distinctUntilChanged(),
      switchMap((instanceId) => this.instanceService.getInstanceAutoscaling(instanceId)),
      takeUntil(this.onDestroy)
    );
  }

  get instanceRegionName(): string {
    const region: Region = truthy(REGION_BY_ID[this.instance.regionId]);
    return `${region.name} (${region.displayId})`;
  }

  get instanceTierDetails(): TierDetails {
    switch (this.instance.instanceTier) {
      case 'Dedicated-Standard': {
        return { tier: 'Dedicated - Standard (1:4)', ratio: 4 };
      }
      case 'Dedicated-High-Mem': {
        return { tier: 'Dedicated - Memory Optimized (1:8)', ratio: 8 };
      }
      case 'Dedicated-High-Cpu': {
        return { tier: 'Dedicated - CPU Optimized (1:2)', ratio: 2 };
      }
      default: {
        return { tier: this.instance.instanceTier, ratio: 4 };
      }
    }
  }

  instanceSizeDetails(maxMem: number): InstanceSizeDetails {
    const ratio = this.instanceTierDetails.ratio;
    const numReplicas = DEDICATED_NUM_REPLICAS;
    const nodeSize = maxMem / numReplicas;
    const numCpus = nodeSize / ratio;
    return { numCpus, nodeSize, numReplicas };
  }

  ngOnInit(): void {
    this.validateInputs();
    this.instanceIdSubject.next(this.instanceId);
  }

  ngOnChanges(): void {
    this.validateInputs();
    this.instanceIdSubject.next(this.instanceId);
  }

  validateInputs(): void {
    assertTruthy(this.hasUserData !== undefined, `hasUserData must be provided.`);
    assertTruthy(this.instanceId, `instance id must be provided.`);
  }

  getWebConsoleUri(instance: Instance): string {
    return getConsoleUrl(instance);
  }

  get isMaintenanceInProgress(): boolean {
    return !!this.instance.activeMaintenanceKind;
  }
}
