<ng-container *ngIf="(billingStatusIsTrialObs | async) === true">
  <div *ngIf="{numDaysLeft: numDaysLeftObs | async } as wrapper" class="trial_countdown_wrapper">
    <mat-icon svgIcon="white_clock" class="clock_icon"></mat-icon>
    <div data-cy="sidebar-trial-expiration"
         class="trial_countdown_text">{{wrapper.numDaysLeft |  i18nPlural: numDaysLeftMessageMapping}}
    </div>
    <button
      galaxyClick="leftbar.footer.upgradeButtonSelect"
      (click)="showCreditCardDialog()"
      class="button upgrade_button"
      data-cy="upgrade-button"
      mat-flat-button
    >Upgrade
    </button>
  </div>
</ng-container>
