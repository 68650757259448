/** Returns list of cookies by name prefix. Key is the cookie name and value is a cookie value. */
export function getCookiesByNamePrefix(prefix: string): Map<string, string> {
  const cookies = document.cookie.split(';');
  const map = new Map<string, string>();
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name.startsWith(prefix)) {
      map.set(name, value);
    }
  }
  return map;
}

/** Removes all cookies by their name prefix. */
export function removeCookiesByNamePrefix(prefix: string): void {
  const keys = [...getCookiesByNamePrefix(prefix).keys()];
  for (const key of keys) {
    document.cookie = `${key}=; Max-Age=-99999999;`;
  }
}
