import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ChartDataPoint, MetricArgs } from '@cp/common/protocol/Metrics';
import { assertTruthy } from '@cp/common/utils/Assert';
import { DataSizePipe, DataSizePointFormat } from '@cp/web/app/common/pipes/data-size.pipe';
import { DEFAULT_COLOR_FOR_METRICS, getXAxisDateTimeOptions } from '@cp/web/app/common/utils/ChartUtils';
import * as Highcharts from 'highcharts';
import { GradientColorStopObject, SeriesAreaOptions, SeriesLineOptions } from 'highcharts';
import enableHighchartsAccessibility from 'highcharts/modules/accessibility';

enableHighchartsAccessibility(Highcharts);

/** Renders Highcharts instance for the given input. */
@Component({
  selector: 'cp-metric-chart',
  templateUrl: './metric-chart.component.html',
  styleUrls: ['./metric-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricChartComponent implements OnInit, OnChanges, AfterViewInit {
  /** Metrics related chart parameters. */
  @Input() metric!: MetricChartInput;

  /** Data to show on the chart. Empty array is processed like a loading state. */
  @Input() seriesData!: Array<Array<ChartDataPoint>>;

  @ViewChild('chart', { static: true }) chartElementRef?: ElementRef;

  /** Active Highcharts instance. Set in AfterViewInit. */
  private chart!: Highcharts.Chart;

  private readonly dataSizePipe = new DataSizePipe();

  get tooltipDateFormat(): string {
    // See https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat.
    switch (this.metric.query.timePeriod) {
      case 'LAST_HOUR':
      case 'LAST_DAY':
        return '%B %e, %l:%M %p'; // Up to minutes: April 24, 4:20 PM.
      case 'LAST_WEEK':
      case 'LAST_MONTH':
        return '%B %e, %l %p'; // Up to hours: April 24, 4 PM.
      case 'LAST_YEAR':
        return '%B %e, %Y'; // Up to days: April 24, 2022.
      default:
        assertTruthy(false);
    }
    return '%Y-%m-%d';
  }

  ngOnInit(): void {
    this.checkRequiredInputs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkRequiredInputs();
    const metricChange = changes['metric'];
    if (
      metricChange &&
      !metricChange.isFirstChange() &&
      this.metric.query.type !== metricChange.previousValue.query.type
    ) {
      throw new Error('Chart component does not support metric type update');
    }
    if (metricChange || changes['seriesData']) {
      this.updateChartSeries();
    }
  }

  ngAfterViewInit(): void {
    // Highcharts has a problem with flex and grid layouts and should be instantiated after the initial flex/grid layout.
    // On the subsequent resize calls there are no observed compatibility issues.
    setTimeout(() => this.createChartInstance(), 0);
  }

  private checkRequiredInputs(): void {
    assertTruthy(this.metric);
    assertTruthy(this.seriesData);
  }

  private createChartInstance(): void {
    assertTruthy(this.chartElementRef?.nativeElement);
    const options = this.metric.seriesOptions || [{}]; // Single series chart by default.
    const data = this.seriesData.length > 0 ? this.seriesData : options.map(() => []); // Empty data per each series by default.
    assertTruthy(data.length === options.length);

    const defaultSeriesColor = DEFAULT_COLOR_FOR_METRICS;
    const series: Array<SeriesAreaOptions | SeriesLineOptions> = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const seriesOptions: SeriesAreaOptions | SeriesLineOptions =
        option.type === 'line' ? { type: 'line' } : { type: 'area' };
      seriesOptions.color = option.color || defaultSeriesColor;
      seriesOptions.data = data[i];
      seriesOptions.lineWidth = 2;
      seriesOptions.clip = false; // Do not clip (half) thick series line when it goes out of the chart area (below 0 point).
      seriesOptions.marker = { enabled: false };
      seriesOptions.name = option.name || this.metric.title;
      if (seriesOptions.type === 'area' && option.color) {
        seriesOptions.fillColor = { stops: generateLinearGradientStops(option.color) };
      }
      series.push(seriesOptions);
    }

    const componentThis = this;
    const yAxisFormatter = this.metric.yAxisValueFormatter;
    const chartOptions: Highcharts.Options = {
      title: {
        text: this.metric.title
      },
      chart: {
        renderTo: this.chartElementRef.nativeElement,
        margin: [42, 50, 30, 70],
        animation: false
      },
      time: {
        useUTC: false // Use local time by default.
      },
      yAxis: {
        title: { text: null },
        labels: {
          format: this.metric.yAxisValueFormat,
          formatter: !yAxisFormatter
            ? undefined
            : function () {
                // Note 'this' here refers to the chart object.
                return yAxisFormatter(this.value);
              }
        }
      },
      xAxis: getXAxisDateTimeOptions(),
      plotOptions: {
        series: {
          animation: false
        },
        area: {
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: generateLinearGradientStops(defaultSeriesColor)
          }
        }
      },
      tooltip: {
        pointFormatter: function () {
          const dataSizeFormat = componentThis.metric.tooltipDataSizePointFormat || 'short-scale-letter';
          const suffix = componentThis.metric.tooltipValueSuffix || '';
          let seriesName = this.series.name;
          const detailsStartIndex = seriesName.indexOf('(');
          if (detailsStartIndex > 0) {
            seriesName = seriesName.substring(0, detailsStartIndex).trim();
          }
          const formattedValue = componentThis.dataSizePipe.transform(this.y || 0, dataSizeFormat) + suffix;
          return `<div class="chart_tooltip_series_line">${seriesName}: <span class="chart_tooltip_value">${formattedValue}</span></div>`;
        },
        xDateFormat: this.tooltipDateFormat,
        shared: true,
        useHTML: true,
        backgroundColor: 'var(--control-plane-bg-color-tooltip)',
        borderColor: 'var(--control-plane-bg-color-tooltip)',
        borderRadius: 6,
        hideDelay: 0,
        style: {
          color: 'var(--control-plane-text-color-tooltip)',
          fontWeight: '600',
          opacity: 0.7
        }
      },
      credits: { enabled: false },
      legend: { enabled: false },
      series
    };

    this.chart = Highcharts.chart(chartOptions);
    this.updateChartSeries();
  }

  private updateChartSeries(): void {
    if (!this.chart) {
      return;
    }
    if (this.seriesData.length === 0) {
      // Delete all existing series data in the chart.
      for (let i = 0; i < this.chart.series.length; i++) {
        this.chart.series[i].hide();
        this.chart.series[i].setData([]);
      }
      this.chart.showLoading('Loading...');
    } else {
      assertTruthy(this.chart.series.length === this.seriesData.length);
      for (let i = 0; i < this.seriesData.length; i++) {
        const numericPoints = this.seriesData[i].map((p) => [p[0], Number(p[1])]);
        // If we have only 1 point force Highcharts to show a marker for it.
        if (numericPoints.length === 1) {
          this.chart.series[i].setData([
            {
              x: numericPoints[0][0],
              y: numericPoints[0][1],
              marker: { enabled: true }
            }
          ]);
        } else {
          this.chart.series[i].setData(numericPoints);
        }
        this.chart.series[i].show();
      }
      this.chart.hideLoading();
    }
    this.chart.update({ tooltip: { xDateFormat: this.tooltipDateFormat } });
    this.chart.redraw();
  }
}

/** Chart type related parameters of the MetricsChartComponent. */
export interface MetricChartInput<MetricsQueryType extends MetricArgs = MetricArgs> {
  /** Title of the chart. */
  title: string;

  /** A short help about the metric. Shown as tooltip for the info icon. */
  helpTooltipText: string;

  /**
   * Highcharts point value format for values (in tooltips). See DataSizePipe.
   * If not provided 'short-scale-letter' is used.
   */
  tooltipDataSizePointFormat?: DataSizePointFormat;

  /** A suffix added to the tooltip value. Example '/s' to render values like: '100Mb/s'. */
  tooltipValueSuffix?: string;

  /** Highcharts format for Y axis values. See https://api.highcharts.com/highcharts/yAxis.labels.format.  */
  yAxisValueFormat?: string;

  /** Highcharts formatter for Y axis values. See https://api.highcharts.com/highcharts/yAxis.labels.formatter.  */
  yAxisValueFormatter?: (value: number | string) => string;

  /** Metrics query args. */
  query: MetricsQueryType;

  /** Series configuration. If empty a chart with 1 default series is used. */
  seriesOptions?: Array<Partial<MetricChartSeriesOptions>>;
}

/** Per data series configuration. */
export interface MetricChartSeriesOptions {
  /** Type of the chart. If not provided 'area' is used. */
  type?: 'area' | 'line';
  name: string;
  color?: string;
}

/** Generates area chart fill gradient stops based on the series (line) color. */
function generateLinearGradientStops(color: string): Array<GradientColorStopObject> {
  assertTruthy(color.startsWith('#') && color.length === 7);
  return [
    [-0.2302, `${color}90`],
    [-0.1782, `${color}33`],
    [1, `${color}00`]
  ] as Array<GradientColorStopObject>;
}
