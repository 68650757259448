import { GetAutoScalingLimitsResponse, GetMemorySizeOptionsInput } from '@cp/common/protocol/AutoScaling';
import { InstanceBackupStatus } from '@cp/common/protocol/Backup';
import { InstanceFeatureId } from '@cp/common/protocol/features';
import { getCustomFeatureSettings } from '@cp/common/protocol/features/Features';
import {
  defaultInstanceMemorySizes,
  INSTANCE_TIERS,
  InstanceMemorySizeOptions,
  InstanceTier,
  MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG,
  MAX_AUTOSCALING_RANGE_FOR_PAID_ORG_GCP
} from '@cp/common/protocol/Instance';
import { isGCPRegion, RegionId } from '@cp/common/protocol/Region';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import { isNumberInRange } from '@cp/common/utils/ValidationUtils';

export function getInstanceMemorySizeOptions(
  regionId: RegionId,
  instanceTier: InstanceTier,
  isCustomValuesFeatureEnabled: boolean,
  isPayingOrg: boolean,
  customAutoscaleValues: Array<number> = [],
  dpAutoScalingLimits: GetAutoScalingLimitsResponse
): InstanceMemorySizeOptions {
  let sizes = defaultInstanceMemorySizes;

  const isDevInstance = instanceTier === 'Development';
  const maxMemoryValue = isGCPRegion(regionId)
    ? MAX_AUTOSCALING_RANGE_FOR_PAID_ORG_GCP
    : MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG;

  if (isDevInstance) {
    sizes = [16, 48, 96, 192, maxMemoryValue];
  } else {
    sizes[sizes.length - 1] = maxMemoryValue;
  }

  // Make sure our sizes fit within the restrictions specified by data plane
  customAutoscaleValues = customAutoscaleValues.filter(
    (size) => size >= dpAutoScalingLimits.minMemoryGb && size <= dpAutoScalingLimits.maxMemoryGb
  );

  if (!isDevInstance) {
    sizes = sizes.filter((size) => size >= dpAutoScalingLimits.minMemoryGb && size <= dpAutoScalingLimits.maxMemoryGb);
  }

  // The max tick should be blocked for nonpaying production services.
  let enableMaxTick = isPayingOrg || isDevInstance;
  if (isCustomValuesFeatureEnabled && customAutoscaleValues.length > 0) {
    const valuesSet = new Set([...sizes, ...customAutoscaleValues]);

    sizes = new Array(...valuesSet).sort((n1, n2) => n1 - n2);
    // Enable the max tick even for nonpaying services in case it is provided as a custom autoscale value.

    enableMaxTick = enableMaxTick || customAutoscaleValues.includes(sizes[sizes.length - 1]);
  }

  const max = enableMaxTick ? sizes.length - 1 : sizes.length - 2;
  const selectableDomain = { min: 0, max };

  return { sizes, selectableDomain };
}

export function getMemorySizeOptionsForNewInstance({
  regionId,
  instanceTier,
  billingStatus,
  autoScalingLimits
}: GetMemorySizeOptionsInput): InstanceMemorySizeOptions {
  const isPayingOrg = isPayingStatus(billingStatus);

  const customValuesFeatureEnabled = false;
  const customAutoscaleValues = new Array<number>();

  return getInstanceMemorySizeOptions(
    regionId,
    instanceTier,
    customValuesFeatureEnabled,
    isPayingOrg,
    customAutoscaleValues,
    autoScalingLimits
  );
}

/** Selects UX/OpenAPI visible backups from the list of all backups. */
export function selectBackupsToShow<T extends { status: InstanceBackupStatus }>(
  allInstanceBackupsSortedDesc: Array<T>,
  features: Array<InstanceFeatureId>,
  instanceTier: InstanceTier,
  countOfBackupsToRetainInDb?: number
): Array<T> {
  const { minValue, maxValue } = getCustomFeatureSettings('FT_INSTANCE_CUSTOM_BACKUPS_TO_RETAIN');
  const nSuccessfulToShow =
    features.includes('FT_INSTANCE_CUSTOM_BACKUPS_TO_RETAIN') &&
    isNumberInRange(countOfBackupsToRetainInDb, minValue, maxValue)
      ? countOfBackupsToRetainInDb
      : INSTANCE_TIERS[instanceTier].countOfBackupsToShowInUi;

  // Show only 2 'failed' and up to N 'done' recent backups.
  const nFailedToShow = 2;
  const backupsToShow: Array<T> = [];
  let failedCounter = 0;
  let successfulCounter = 0;
  for (const backup of allInstanceBackupsSortedDesc) {
    if (backup.status === 'error') {
      if (failedCounter < nFailedToShow) {
        backupsToShow.push(backup);
        failedCounter++;
      }
    } else if (backup.status === 'done') {
      if (successfulCounter < nSuccessfulToShow) {
        backupsToShow.push(backup);
        successfulCounter++;
      }
    } else {
      backupsToShow.push(backup);
    }
  }
  return backupsToShow;
}
