<div class="container">
  <h4 class="seed_h4">Customer Managed Encryption Key (CMEK)</h4>
  <div class="section" [formGroup]="form">
    <div class="paragraph_normal_regular">
      Bring your own KMS key to encrypt your data.
    </div>

    <div class="key_arn_validation_title">
      <label for="cmek_aws_arn">AWS ARN</label>
      <ng-container *ngIf="(keyArnValidationStatus | async) as status">
        <ng-container *ngIf="status === 'PENDING'" class="key_arn_validation_status">
          <mat-spinner class="validation_icon pending" diameter="16"></mat-spinner>
          Validating...
        </ng-container>
        <ng-container *ngIf="status === 'INVALID'" class="key_arn_validation_status">
          <mat-icon class="validation_icon fail" svgIcon="invalid"></mat-icon>
          Invalid
        </ng-container>
        <ng-container *ngIf="status === 'VALID'" class="key_arn_validation_status">
          <mat-icon class="validation_icon success" svgIcon="small_check_in_circle"></mat-icon>
          Valid
        </ng-container>
      </ng-container>
    </div>
    
    <div class="seed_form_element">
      <input
        id="cmek_aws_arn"
        matInput
        formControlName="keyArn"
        placeholder="ARN for AWS KMS Key" />
    </div>
    
    <label for="cmek_aws_arn">AWS Assumed Role ARN (optional)</label>
    <div class="seed_form_element">
      <input
        id="cmek_aws_arn"
        matInput
        formControlName="assumedRoleArn"
        placeholder="ARN for ClickHouse IAM"
        />
    </div>
  </div>
</div>
