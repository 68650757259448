import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { USER_NOT_FOUND } from '@cp/common/protocol/Account';
import { AccountService } from '@cp/web/app/account/account.service';
import { ForgotPasswordUiService } from '@cp/web/app/account/forgot-password/forgot-password-ui.service';
import { ForgotPasswordUiState } from '@cp/web/app/account/protocol/AccountStates';
import { validateFormEmail } from '@cp/web/app/common/utils/FormValidationUtils';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent {
  readonly forgotPasswordForm: FormGroup;
  readonly forgotPasswordUiStateObs: Observable<ForgotPasswordUiState>;
  readonly queryParams: Params;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly accountService: AccountService,
    readonly forgotPasswordUiService: ForgotPasswordUiService,
    private readonly snackBar: MatSnackBar,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.queryParams = activatedRoute.snapshot.queryParams;
    this.forgotPasswordUiStateObs = forgotPasswordUiService.observeState();

    forgotPasswordUiService.setPartialState({
      sendForgotPasswordButtonDisabled: false,
      errorMessage: undefined,
      forgotPasswordEmailSent: false
    });

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, validateFormEmail]]
    });
  }

  async onSubmit(): Promise<void> {
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.forgotPasswordUiService.setPartialState({
      sendForgotPasswordButtonDisabled: true,
      errorMessage: undefined,
      forgotPasswordEmailSent: false
    });
    const details = this.forgotPasswordForm.value;

    try {
      await this.accountService.sendForgotPasswordEmail(details.email, this.queryParams);
      this.forgotPasswordUiService.setStateKey('forgotPasswordEmailSent', true);
    } catch (e: unknown) {
      console.error(e);
      const cognitoCode = e instanceof HttpErrorResponse ? e.error?.message || e.message : 'unknown';
      let errorMessage: string;
      switch (cognitoCode) {
        case USER_NOT_FOUND:
          errorMessage = '';
          break;
        default:
          errorMessage = 'Unable to reset password, please try again later';
          break;
      }
      if (errorMessage.length > 0) {
        this.forgotPasswordUiService.setStateKey('errorMessage', errorMessage);
      } else {
        this.forgotPasswordUiService.setStateKey('forgotPasswordEmailSent', true);
      }
    } finally {
      this.forgotPasswordUiService.setStateKey('sendForgotPasswordButtonDisabled', false);
    }
  }

  async resendPasswordLink(): Promise<void> {
    const details = this.forgotPasswordForm.value;

    try {
      await this.accountService.sendForgotPasswordEmail(details.email, this.queryParams);
      this.snackBar.open(`Resend password link to ${details.email} successfully.`, 'Dismiss');
    } catch (e: unknown) {
      console.error(e);
      const cognitoCode = e instanceof HttpErrorResponse ? e.error?.message || e.message : 'unknown';
      let errorMessage: string;
      switch (cognitoCode) {
        case USER_NOT_FOUND:
          errorMessage = '';
          break;
        default:
          errorMessage = 'Unable to reset password, please try again later';
          break;
      }
      if (errorMessage.length > 0) {
        this.snackBar.open(`Failed to resend password link to ${details.email}. ${errorMessage}`, 'Dismiss');
      } else {
        this.snackBar.open(`Resend password link to ${details.email} successfully.`, 'Dismiss');
      }
    }
  }
}
