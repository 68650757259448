import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { ChartDataSeries } from '@cp/common/protocol/Metrics';
import { assertTruthy } from '@cp/common/utils/Assert';
import { getXAxisDateTimeOptions } from '@cp/web/app/common/utils/ChartUtils';
import * as Highcharts from 'highcharts';
import { AxisLabelsFormatterContextObject, SeriesColumnOptions } from 'highcharts';

@Component({
  selector: 'cp-admin-usage-chart',
  templateUrl: './admin-usage-chart.component.html',
  styleUrls: ['./admin-usage-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminUsageChartComponent implements OnInit, OnChanges, AfterViewInit {
  /** Metrics related chart parameters. */
  @Input() chartData!: AdminUsageChartComponentData;

  @ViewChild('chart', { static: true }) chartElementRef?: ElementRef;

  /** Active Highcharts instance. Set in AfterViewInit. */
  private chart?: Highcharts.Chart;

  ngOnInit(): void {
    this.checkRequiredInputs();
  }

  ngOnChanges(): void {
    this.checkRequiredInputs();
    if (this.chart) {
      // If this is not the initial set up update the chart. Otherwise, wait for 'ngAfterViewInit'.
      this.recreateChartData();
    }
  }

  ngAfterViewInit(): void {
    this.recreateChartData();
  }

  /** Destroys currently shown charts and creates a new chart. */
  private checkRequiredInputs(): void {
    assertTruthy(this.chartData);
  }

  /** Deletes the existing chart and creates a new chart to show 'chartData'. */
  private recreateChartData(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
    assertTruthy(this.chartElementRef?.nativeElement);
    const { perInstanceData } = this.chartData;
    const seriesDataList = [...perInstanceData.values()];
    const series: Array<SeriesColumnOptions> = [];
    for (const seriesData of seriesDataList) {
      const seriesOptions: SeriesColumnOptions = { type: 'column' };
      seriesOptions.name = seriesData.name;
      seriesOptions.data = seriesData.data;
      seriesOptions.color = seriesData.color;
      seriesOptions.stacking = 'normal';
      series.push(seriesOptions);
    }
    const chartOptions: Highcharts.Options = {
      chart: {
        renderTo: this.chartElementRef.nativeElement,
        // top (to avoid cropping of y-axis text), right (no margin), bottom (space for x-axis text), left (space for y-axis text).
        margin: [10, 0, 30, 80],
        animation: false,
        numberFormatter: this.chartData.valueFormatter
      },
      //TODO: time: {useUTC: false},  // TODO: billing should not depend on the current user TZ. Need to confirm.
      yAxis: {
        title: { text: null },
        labels: {
          formatter: ({ pos }: AxisLabelsFormatterContextObject) => this.chartData.valueFormatter(pos)
        }
      },
      xAxis: { ...getXAxisDateTimeOptions(), min: this.chartData.startDate, max: this.chartData.endDate },
      plotOptions: {
        column: {
          maxPointWidth: 50,
          animation: false
        }
      },
      tooltip: {
        shared: true
      },
      title: { text: undefined }, // 'undefined' disables the title.
      credits: { enabled: false },
      legend: { enabled: false },
      series
    };
    this.chart = Highcharts.chart(chartOptions);
    this.chart.redraw();
  }
}

/** Input of the admin chart component. */
export interface AdminUsageChartComponentData {
  startDate: number;
  endDate: number;
  perInstanceData: Map<string, AdminUsageChartSeriesData>;
  /** Point value formatter on the chart: tooltip, y-axis. */
  valueFormatter: (value: number) => string;
}

/** Single chart series data. */
export interface AdminUsageChartSeriesData {
  name: string;
  color: string;
  data: ChartDataSeries;
  /** Values converted to units that conform to the website's pricing page. */
  websiteUnitData?: ChartDataSeries;
}
