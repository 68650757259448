<div class="post_submission_text" data-cy="verify-email-address-notice">
  <div class="form_container" data-cy="check-your-email-card">
    <div class="auth_intro">
      <div class="auth_title">Check your email</div>
    </div>
    <mat-icon class="verify_email" svgIcon="verify_email"></mat-icon>
    <div class="text">
      <ng-container *ngIf="email; else withoutMail">
        We’ve sent an email validation link to <br/>
        <span class="email fs-exclude" data-cy="email">{{email}}</span>.
      </ng-container>
      <ng-template #withoutMail>
        We've sent you an email.
        Please follow the instructions to verify your account
      </ng-template>
    </div>
    <div *ngIf="!customMessage" class="info-sub-text">
      Click on the link to complete your signup. The validation link will expire in 24 hours.
    </div>
    <ng-container *ngIf="customMessage">{{customMessage}}</ng-container>

    <div class="buttons button-container buttons-col">
      <a *ngIf="userId && email" [routerLink]="'/resendConfirmEmail/'+userId">
        <button class="button clickui-primary-button" mat-flat-button>
          Resend confirmation link
        </button>
      </a>
      <a routerLink="/signIn">
        <button class="button clickui-secondary-button" mat-flat-button>
          Back to sign in
        </button>
      </a>
    </div>
  </div>
</div>
