<div class="auth_page_container">
  <div class="container">
    <cw-auth-logo type="full" color="primary-dark"></cw-auth-logo>
    <div class="form_container">
      <div class="auth_intro">
        <div class="auth_title" data-test="title">{{data.title}}</div>
      </div>
      <div class="intro_text description" data-test="description">{{data.description}}</div>

      <div class="input_element_wrapper">
        <label data-test="field-description">{{data.fieldDescription}}</label>
        <input class="seed_input input_element" data-test="user-input"
               spellcheck="false"
               #userInput
               maxlength="6"
               (input)="onChange(userInput.value)"
               (keyup.enter)="onPrimaryAction(userInput.value)"
               [value]="data.inputValue"/>
      </div>

      <div *ngIf="data.errorMessage" class="error_message_wrapper"
           data-test="error-message">
        <cw-system-message
          messageType="error"
          [message]="data.errorMessage">
        </cw-system-message>
      </div>
      <div class="actions_wrapper">
        <div class="form_element">
          <button class="button clickui-primary-button"
                  disabled="{{data.isPrimaryActionDisabled}}"
                  data-test="primary-action-button"
                  mat-flat-button
                  (click)="onPrimaryAction(userInput.value)">
            <div class="relative">
              <mat-icon *ngIf="data.isVerifying" class="button_loader_icon_absolute icon svg_stroke"
                        data-test="primary-action-loading-indicator"
                        svgIcon="loader"></mat-icon>
              {{data.primaryActionLabel}}
            </div>
          </button>
        </div>
        <div *ngIf="data.secondaryActionLabel" class="form_element">
          <button class="button clickui-secondary-button"
                  data-test="secondary-action-button" mat-flat-button
                  (click)="onSecondaryAction()">
            <div class="relative">
              {{data.secondaryActionLabel}}
            </div>
          </button>
        </div>
      </div>
      <div class="bottom_links">
        <p class="help_text" data-test="help-text">
          {{data.helpText}}</p><span>&nbsp;</span><a href="mailto:support@clickhouse.com">Contact support</a>
      </div>
    </div>
  </div>
</div>
