import { BillingRpcRequest, OrganizationDetails } from '@cp/common/protocol/Billing';
import { Organization, OrganizationBillingStatus } from '@cp/common/protocol/Organization';

/** Type of the tackle marketplace. Only supported marketplaces are listed. */
const TACKLE_MARKETPLACE_TYPE = ['aws', 'gcp'];
export type TackleMarketplace = (typeof TACKLE_MARKETPLACE_TYPE)[number];

/** Subscription token sent by Tackle to CP: */
export interface TackleSubscriptionToken {
  marketplace: TackleMarketplace;
  customerId: string;
  productId: string;
  /** Defined (but is still optional) only for 'aws' marketplace. */
  awsAccountId?: string;
}

/**
 * Request used to create a new Tackle subscription.
 * Company details are stored to all interested parties (Stripe, MongoDB, Workato...).
 */
export interface TackleSubscriptionRequest extends BillingRpcRequest<'handleTackleSubscription'> {
  token: TackleSubscriptionToken;
  organizationDetails: OrganizationDetails;
}

export interface TackleSubscriptionResponse {
  /** Organization with assigned subscription. */
  organization: Organization;
}

/** Tackle webhook event type. See https://developers.tackle.io/reference/sample-payloads. */
export type TackleWebhookEventType =
  | 'order_created'
  | 'order_modified'
  | 'order_cancelled'
  | 'private_offer_invitation_opened'
  | 'private_offer_created'
  | 'private_offer_viewed'
  | 'private_offer_purchase_instructions_sent'
  | 'private_offer_accepted';

// noinspection SpellCheckingInspection
/**
 * Tackle-provided product event details. Input of the tackle product webhook.
 * See https://developers.tackle.io/reference/payload-variables
 * and https://developers.tackle.io/reference/private-offer-payloads.
 */
export interface TackleProductWebhookRequest {
  event_type: TackleWebhookEventType;
  productid: string;
  customerid: string;
  marketplace: TackleMarketplace;
}

/** See https://developers.tackle.io/reference/v1-create-registration. */
export interface TackleAwsEntitlement {
  /** Example: "example_dimension_name". */
  dimension: string;
  /** Example: "example_awsdimension_apiname_1". */
  skuid: string;
  /** Example: "2019-04-24T12:23:03.048000+00:00". */
  expiration: string;
  /** Example: 5. */
  value: number;
}

/** https://cloud.google.com/marketplace/docs/partners/commerce-procurement-api/reference/rest/v1/providers.entitlements. */
export type GcpProviderEntitlement =
  | 'ENTITLEMENT_STATE_UNSPECIFIED'
  | 'ENTITLEMENT_ACTIVATION_REQUESTED'
  | 'ENTITLEMENT_ACTIVE'
  | 'ENTITLEMENT_PENDING_CANCELLATION'
  | 'ENTITLEMENT_CANCELLED'
  | 'ENTITLEMENT_PENDING_PLAN_CHANGE'
  | 'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL'
  | 'ENTITLEMENT_SUSPENDED';

/**
 * See https://developers.tackle.io/reference/v1-create-registration and webhooks (may add more fields).
 */
export interface TackleGcpEntitlement {
  /* Example: "providers/tackle-public/entitlements/416bacaa-94b6-4701-911bf945aab9",*/
  name: string;
  /* Example: "providers/tackle-public/accounts/E-ABC2-D530-E2FG-H2Q2". */
  account: string;
  /* Example: "2019-10-25T21:38:20.865Z". */
  createTime: string;
  /* Example: "2019-10-25T21:38:20.865Z". */
  updateTime?: string;
  /* Example: "tackle-public". */
  provider?: string;
  /* Example: "tackle-on-gcp". */
  product: string;
  /* Example: "mp1-platform-3-P1Y". */
  plan?: string;
  /* Example: "ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL". */
  state: GcpProviderEntitlement;
  /* Example: "mp1-starter-P1Y". */
  newPendingPlan?: string;
  /* Example: "mp1_sass". */
  quoteExternalName?: string;
  /* Example: "mp1-marketplace". */
  productExternalName?: string;
  /** Example: "project_number:1234567890". */
  usageReportingId?: string;
}

export interface TackleProductWebhookGcpOrderModifiedRequest extends TackleProductWebhookRequest {
  event_type: 'order_modified';
  marketplace: 'gcp';
  entitlements: Array<TackleGcpEntitlement>;
}

export function isTackleMarketplace(v: unknown): v is TackleMarketplace {
  return TACKLE_MARKETPLACE_TYPE.includes(v as string);
}

/**
 * List of billing statuses safe to resume Tackle subscription on 'order_modified' request.
 * With any other status the 'order_modified' organization will be updated to have 'MANUAL_REVIEW' billing status.
 */
export const BILLING_STATUS_GOOD_TO_RESUME_TACKLE_SUBSCRIPTION: Array<OrganizationBillingStatus> = [
  'IN_MP_GRACE_PERIOD',
  'IN_MP_REMORSE_PERIOD',
  'DECOMMISSIONED'
];
