import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getIntegrationDescription,
  getIntegrationLicenseLevelText,
  getIntegrationReadinessLevelText,
  getIntegrationSupportLevelText,
  Integration,
  IntegrationSupportLevel,
  LicenseLevel,
  ReadinessLevel
} from '@cp/common/protocol/Integration';
import { assertTruthy } from '@cp/common/utils/Assert';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { IntegrationsService } from '@cp/web/app/marketplace/integrations.service';
import { from, map, Observable } from 'rxjs';

type TabType = 'how_to_connect' | 'changelog' | 'about';

@Component({
  selector: 'cp-integration-summary',
  templateUrl: './integration-summary.component.html',
  styleUrls: ['./integration-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationSummaryComponent implements OnInit {
  integrationData = new Observable<Integration>();
  selectedTab: TabType = 'how_to_connect';

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly integrationService: IntegrationsService,
    private readonly segmentService: SegmentService
  ) {}

  async ngOnInit(): Promise<void> {
    const integrationNameParam = this.route.snapshot.paramMap.get('integrationName');
    if (!integrationNameParam) {
      console.warn(`Couldn't find integrationName in the query param map - navigate back to integrations page.`);
      await this.router.navigate(['integrations']);
      return;
    }

    this.integrationData = from(
      this.integrationService.loadIntegrations({
        integrationSlug: integrationNameParam,
        includeSummary: true
      })
    ).pipe(
      map((integrations) => {
        assertTruthy(
          integrations.length === 1,
          `Found more than one integration with the slug - ${integrationNameParam}`
        );
        return integrations[0];
      })
    );
  }

  switchTab(selectedProvider: TabType): void {
    const eventName =
      selectedProvider === 'how_to_connect'
        ? 'howToConnectSelect'
        : selectedProvider === 'changelog'
        ? 'changelogSelect'
        : 'aboutSelect';

    this.segmentService.trackGaEvent({
      event: 'click',
      label: eventName,
      category: 'integrationPage',
      view: 'integrationPage',
      component: 'tab'
    });
    this.selectedTab = selectedProvider;
  }

  onFullDocumentationClicked() {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'fullDocumentationSelect',
      category: 'integrationPage',
      view: 'integrationPage',
      component: 'tab'
    });
  }

  getSupportLevelText(supportLevel: IntegrationSupportLevel): string {
    return getIntegrationSupportLevelText(supportLevel);
  }

  getReadinessText(readinessLevel: ReadinessLevel): string {
    return getIntegrationReadinessLevelText(readinessLevel);
  }

  getSupportLevelDescription(supportLevel: IntegrationSupportLevel): string {
    return getIntegrationDescription(supportLevel);
  }

  getLicenseLevelText(licenseLevel: LicenseLevel): string {
    return getIntegrationLicenseLevelText(licenseLevel);
  }
}
