import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  isFutureOpenapiKeyExpirationDate,
  OPENAPI_KEY_NAME_MAX_LENGTH,
  OPENAPI_KEY_NAME_MIN_LENGTH,
  OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS
} from '@cp/common/protocol/OpenapiKey';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_DAY, MILLIS_PER_WEEK } from '@cp/common/utils/DateTimeUtils';
import {
  apiKeyOrgPermissionsTooltipText,
  formatOrgRoleAsPermissionName
} from '@cp/web/app/organizations/api-keys/api-key-ui-utils';

export interface ApiKeyFormData {
  name: string;
  role: OrganizationRole;
  expirationDate: number;
}

type ExpirationPeriodLabel = 'Never' | '1 week' | '2 weeks' | '1 month' | '6 months' | '1 year' | 'Custom';

/** Ordered list of options as shown in the select. */
const API_KEY_EXPIRATION_OPTIONS: Record<ExpirationPeriodLabel, number> = {
  Never: -1, // Custom handling.
  '1 week': MILLIS_PER_WEEK,
  '2 weeks': 2 * MILLIS_PER_WEEK,
  '1 month': 30 * MILLIS_PER_DAY,
  '6 months': 6 * 30 * MILLIS_PER_DAY,
  '1 year': 356 * MILLIS_PER_DAY,
  Custom: -1 // Custom handling.
};

interface FormValueModel {
  name: string;
  role: OrganizationRole;
  expirationPeriodType: ExpirationPeriodLabel;
  expirationDateValue: string;
}

@Component({
  selector: 'cp-edit-api-key-form',
  templateUrl: './edit-api-key-form.component.html',
  styleUrls: ['./edit-api-key-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditApiKeyFormComponent implements OnInit, OnChanges {
  @Input() mode!: 'create' | 'edit';
  @Input() userRole!: OrganizationRole;

  /** When submit is in progress the submit form button is disabled. */
  @Input() isSubmitInProgress = false;

  @Input() initialFormData: ApiKeyFormData = { name: '', role: 'DEVELOPER', expirationDate: 0 };

  @Output() update = new EventEmitter<ApiKeyFormData>();
  @Output() cancel = new EventEmitter<void>();

  form!: FormGroup;

  readonly timePeriodOptions = Object.entries(API_KEY_EXPIRATION_OPTIONS).map<SeedSelectOption<ExpirationPeriodLabel>>(
    ([label]) => ({
      label,
      value: label as ExpirationPeriodLabel,
      dataCyValue: label,
      dataCy: 'time-period-option'
    })
  );

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    assertTruthy(this.mode && this.userRole);
    const { name, role, expirationDate } = this.initialFormData;
    const isCustomExpirationDate = expirationDate > 0 && expirationDate != OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS;
    this.form = this.formBuilder.group({
      name: [
        name,
        [
          Validators.required,
          Validators.minLength(OPENAPI_KEY_NAME_MIN_LENGTH),
          Validators.maxLength(OPENAPI_KEY_NAME_MAX_LENGTH)
        ]
      ],
      role: [role, [Validators.required]],
      expirationPeriodType: [isCustomExpirationDate ? 'Custom' : 'Never', [Validators.required]],
      expirationDateValue: [isCustomExpirationDate ? formatExpirationDate(this.initialFormData.expirationDate) : '']
    });
    this.form.addValidators([() => this.validateExpirationPeriodFormData()]);
    this.form.valueChanges.subscribe(() => this.cdr.markForCheck());
  }

  ngOnChanges(): void {
    assertTruthy(this.mode && this.userRole);
  }

  get currentExpirationPeriodType(): ExpirationPeriodLabel {
    return (this.form.value as FormValueModel).expirationPeriodType;
  }

  private validateExpirationPeriodFormData(): ValidationErrors | null {
    if (!this.form) return null; // Form is not initialized yet.
    if (this.currentExpirationPeriodType !== 'Custom') return null;
    const formValue = this.form.value as FormValueModel;
    const expirationDate = getExpirationDateFromExpirationPeriod(
      formValue.expirationPeriodType,
      formValue.expirationDateValue
    );
    let isInvalidDate = isNaN(expirationDate);
    if (!isInvalidDate && !isFutureOpenapiKeyExpirationDate(expirationDate)) {
      // If date is not changed -> allow date in the past. This way user can change other fields (like name).
      isInvalidDate =
        formatExpirationDate(expirationDate) !== formatExpirationDate(this.initialFormData.expirationDate);
    }
    return isInvalidDate ? { expirationDateValue: 'Invalid value' } : null;
  }

  async onSubmit(): Promise<void> {
    if (this.form.invalid) return;
    this.update.emit(this.currentFormData);
  }

  get currentFormData(): ApiKeyFormData {
    const formValue = this.form.value as FormValueModel;

    // The visual date is a rounded timestamp. Avoid changing the default value unless user changes the date
    // to more than 1 minute.
    const roundedExpirationDate = getExpirationDateFromExpirationPeriod(
      formValue.expirationPeriodType,
      formValue.expirationDateValue
    );
    const expirationDate =
      formatExpirationDate(roundedExpirationDate) === formatExpirationDate(this.initialFormData.expirationDate)
        ? this.initialFormData.expirationDate
        : roundedExpirationDate;

    return {
      name: formValue.name,
      role: formValue.role,
      expirationDate
    };
  }

  get hasChanges(): boolean {
    const currentData = this.currentFormData;
    return (
      currentData.name !== this.initialFormData.name ||
      currentData.role !== this.initialFormData.role ||
      formatExpirationDate(currentData.expirationDate) !== formatExpirationDate(this.initialFormData.expirationDate)
    );
  }

  readonly formatOrgRole = formatOrgRoleAsPermissionName;
  readonly apiKeyOrgPermissionsTooltipText = apiKeyOrgPermissionsTooltipText;
}

function getExpirationDateFromExpirationPeriod(label: ExpirationPeriodLabel, value: string): number {
  if (label === 'Never') return OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS;
  if (label !== 'Custom') {
    // Round up to minutes.
    const date = new Date(Date.now() + API_KEY_EXPIRATION_OPTIONS[label]);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  }
  // Support only 2 types of inputs: 1) YYYY-MM-DD 2) YYYY-MM-DD HH:mm.
  // Both values are in UTC.
  // Convert date to ISO format before parsing (example: '2023-02-25T20:06:08.096Z').
  // Any failure with parse will result to NaN.
  if (value.includes(' ')) return Date.parse(value.replace(' ', 'T') + ':00.000Z');
  return Date.parse(value + 'T00:00.000Z');
}

function formatExpirationDate(timestamp: number): string {
  if (timestamp === OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS) return '';
  return new Date(timestamp).toISOString().substring(0, 16).replace('T', ' '); // Trim up to minutes.
}
