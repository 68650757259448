<ng-container *ngIf="billingStatusStateObs | async as billingStatus">
  <ng-container *ngIf="commitmentStateObs | async as commitment">
    <ng-container *ngIf="myRoleObs | async as myRole">
      <ng-container *ngIf="(billingStatusIsTrialRelatedObs | async) === true">
        <cw-system-message *ngIf="showBillingAlertType(billingStatus, commitment)"
                           [dismissalKey]="getDismissalKey(billingStatus, commitment)"
                           messageType="warning">
          <div [ngSwitch]="getBillingAlertType(billingStatus, commitment)">

            <div *ngSwitchCase="'CREDIT_NEAR_DEPLETION'" data-cy="billing-alert-message">
              <div>Credit limit alert</div>
              <div>{{commitment.amountSpent}} of the {{commitment.totalAmount}} trial credits have been used
                ({{commitment.usagePercent | number :'1.0-2'}}%).
                To continue using ClickHouse Cloud once your credit total has been met, please
                <span *ngIf="myRole !== 'ADMIN'">contact your system admin.</span>
                <span *ngIf="myRole === 'ADMIN'">enter your <a
                  (click)="showCreditCardDialog()">payment details</a>.</span>
              </div>
            </div>

            <div *ngSwitchCase="'CREDIT_NEAR_EXPIRATION'" data-cy="billing-alert-message">
              {{commitment.timeRemainingInDays | i18nPlural : remainingDaysMessageMapping}}
              {{commitment.totalDurationInDays| i18nPlural : totalDaysMessageMapping}}
              To continue using ClickHouse Cloud, please
              <span *ngIf="myRole !== 'ADMIN'">contact your system admin.</span>
              <span *ngIf="myRole === 'ADMIN'">enter your <a
                (click)="showCreditCardDialog()">payment details</a>.</span>
            </div>

            <div *ngSwitchCase="'CREDIT_DEPLETED'" data-cy="billing-alert-message">
              {{commitment.amountSpent}} of the {{commitment.totalAmount}} trial credits have been used
              ({{commitment.usagePercent | number :'1.0-2'}}%).
              To continue using ClickHouse Cloud, please
              <span *ngIf="myRole !== 'ADMIN'">contact your system admin.</span>
              <span *ngIf="myRole === 'ADMIN'">enter your <a
                (click)="showCreditCardDialog()">payment details</a>.</span>
            </div>

            <div *ngSwitchCase="'CREDIT_EXPIRED'" data-cy="billing-alert-message">
              Your {{commitment.totalDurationInDays}} day free trial has now expired. To continue using
              ClickHouse Cloud, please
              <span *ngIf="myRole !== 'ADMIN'">contact your system admin.</span>
              <span *ngIf="myRole === 'ADMIN'">enter your <a
                (click)="showCreditCardDialog()">payment details</a>.</span>
            </div>

          </div>
        </cw-system-message>

      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
