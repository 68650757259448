import { Injectable, InjectionToken } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import { GetAutoScalingLimitsRequest, GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import {
  BACKUP_API_PATH,
  RestoreInstanceBackupRequest,
  RestoreInstanceBackupResponse
} from '@cp/common/protocol/Backup';
import { DbUsersPasswordlessRequest, DbUsersResponse } from '@cp/common/protocol/DbUsers';
import {
  CancelInstanceUpgradeRequest,
  ChangeInstanceNameRequest,
  ChangeIpAccessListRequest,
  CreateInstanceRequest,
  CreateInstanceResponse,
  DeleteInstanceRequest,
  InstanceMysqlSettings,
  GetImportFileDownloadUrlRequest,
  GetImportFileDownloadUrlResponse,
  GetInstanceAutoScalingRequest,
  GetInstanceAutoScalingResponse,
  GetMysqlSettingsRequest,
  Instance,
  INSTANCE_API_PATH,
  InstanceAutoScalingRequest,
  IpAccessListEntry,
  ListInstancesRequest,
  ListInstancesResponse,
  MarkInstanceHasUserDataRequest,
  MarkInstanceHasUserDataResponse,
  PrepareUploadSignatureDetailsRequest,
  RefreshUserDataFlagRequest,
  RefreshUserDataFlagResponse,
  ResetInstancePasswordRequest,
  ResetInstancePasswordResponse,
  StartInstanceRequest,
  StopInstanceRequest,
  UpgradeInstanceRequest,
  InstanceCustomerManagedEncryptionConfig,
  VerifyCustomerKeyConfigRequest,
  VerifyCustomerKeyConfigResponse,
  UpdateMysqlSettingsRequest,
  BackfillMysqlPasswordRequest,
  GetInstanceIamPrincipalResponse,
  GetInstanceIamPrincipalRequest,
  InstanceDatabaseAccessMapping,
  UpdateInstanceDbRoleMappingRequest
} from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { UploadSignatureDetails } from '@cp/common/protocol/Storage';

/** CP API for instance (service) management. */
export interface InstanceApiService {
  /** Returns list of instances sorted by creation date. */
  listInstances(organizationId: string): Promise<Array<Instance>>;

  createInstance(request: Omit<CreateInstanceRequest, 'rpcAction'>): Promise<CreateInstanceResponse>;

  upgradeInstance(instanceId: string, organizationId: string): Promise<void>;

  cancelInstanceUpgrade(instanceId: string, organizationId: string): Promise<void>;

  stopInstance(instanceId: string, organizationId: string): Promise<void>;

  startInstance(instanceId: string, organizationId: string): Promise<void>;

  deleteInstance(instanceId: string, organizationId: string): Promise<void>;

  resetPassword(request: Omit<ResetInstancePasswordRequest, 'rpcAction'>): Promise<ResetInstancePasswordResponse>;

  changeInstanceName(instanceId: string, organizationId: string, name: string): Promise<void>;

  restoreInstanceBackup(
    request: Omit<RestoreInstanceBackupRequest, 'rpcAction'>
  ): Promise<RestoreInstanceBackupResponse>;

  /** Updates a complete IP access list for an instance. */
  updateIpAccessList(
    currentOrgIdOrFail: string,
    instanceId: string,
    ipAccessList: Array<IpAccessListEntry>
  ): Promise<void>;

  updateAutoScaling(
    organizationId: string,
    instanceId: string,
    enableIdleScaling: boolean,
    idleTimeoutMinutes: number,
    minAutoScalingTotalMemory?: number,
    maxAutoScalingTotalMemory?: number
  ): Promise<void>;

  prepareUploadSignatureDetails(
    organizationId: string,
    fileName: string,
    mimeType?: string
  ): Promise<UploadSignatureDetails>;

  getImportFileDownloadUrl(
    organizationId: string,
    instanceId: string,
    storageId: string
  ): Promise<GetImportFileDownloadUrlResponse>;

  refreshUserDataFlag(organizationId: string, instanceId: string): Promise<RefreshUserDataFlagResponse>;

  /** Sets instance.hasUserData flag true.*/
  markInstanceHasUserData(organizationId: string, instanceId: string): Promise<MarkInstanceHasUserDataResponse>;

  getInstanceDbPermissions(instanceId: string): Promise<DbUsersResponse>;

  /** Get data plane auto scaling limits for region */
  getDpAutoScalingLimits(regionId: RegionId): Promise<GetAutoScalingLimitsResponse>;

  /** Get data plane auto scaling information for instance */
  getInstanceAutoscaling(instanceId: string): Promise<GetInstanceAutoScalingResponse>;

  /** Validate CMEK key */
  verifyCustomerKeyConfig(
    organizationId: string,
    config: InstanceCustomerManagedEncryptionConfig
  ): Promise<VerifyCustomerKeyConfigResponse>;

  getMysqlSettings(organizationId: string, instanceId: string): Promise<InstanceMysqlSettings>;

  updateMysqlSettings(organizationId: string, instanceId: string, enabled: boolean): Promise<void>;

  backfillMysqlPassword(
    organizationId: string,
    instanceId: string,
    doubleSha1Password: string,
    sha256Password: string
  ): Promise<void>;

  getInstanceIamPrincipal(organizationId: string, instanceId: string): Promise<GetInstanceIamPrincipalResponse>;

  updateInstanceDbRoleMapping(
    organizationId: string,
    instanceId: string,
    databaseRoleMappings: ReadonlyArray<InstanceDatabaseAccessMapping>
  ): Promise<void>;
}

export const INSTANCE_API_SERVICE_TOKEN = new InjectionToken<InstanceApiService>('instanceApiService');

/** Default implementation of the InstanceApiService. */
@Injectable()
export class DefaultInstanceApiService implements InstanceApiService {
  constructor(private readonly restService: RestService) {}

  async listInstances(organizationId: string): Promise<Array<Instance>> {
    const request: ListInstancesRequest = { rpcAction: 'list', organizationId };
    const response = await this.restService.post<ListInstancesResponse>(INSTANCE_API_PATH, request);
    return response.instances;
  }

  async createInstance({
    name,
    regionId,
    organizationId,
    passwordHash,
    doubleSha1Password,
    ipAccessList,
    instanceTier,
    gcpTermsChecked,
    autoscalingParams,
    customerManagedEncryption
  }: Omit<CreateInstanceRequest, 'rpcAction'>): Promise<CreateInstanceResponse> {
    const request: CreateInstanceRequest = {
      rpcAction: 'create',
      name,
      regionId,
      organizationId,
      ipAccessList,
      instanceTier,
      passwordHash,
      doubleSha1Password,
      gcpTermsChecked,
      autoscalingParams,
      customerManagedEncryption
    };
    return await this.restService.post<CreateInstanceResponse>(INSTANCE_API_PATH, request);
  }

  async upgradeInstance(instanceId: string, organizationId: string): Promise<void> {
    const request: UpgradeInstanceRequest = { rpcAction: 'upgrade', instanceId, organizationId };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async cancelInstanceUpgrade(instanceId: string, organizationId: string): Promise<void> {
    const request: CancelInstanceUpgradeRequest = { rpcAction: 'cancelUpgrade', instanceId, organizationId };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async stopInstance(instanceId: string, organizationId: string): Promise<void> {
    const request: StopInstanceRequest = { rpcAction: 'stop', instanceId, organizationId };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async startInstance(instanceId: string, organizationId: string): Promise<void> {
    const request: StartInstanceRequest = { rpcAction: 'start', instanceId, organizationId };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async deleteInstance(instanceId: string, organizationId: string): Promise<void> {
    const request: DeleteInstanceRequest = { rpcAction: 'delete', instanceId, organizationId };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async resetPassword(
    request: Omit<ResetInstancePasswordRequest, 'rpcAction'>
  ): Promise<ResetInstancePasswordResponse> {
    const rpcRequest: ResetInstancePasswordRequest = { rpcAction: 'resetPassword', ...request };
    return await this.restService.post<ResetInstancePasswordResponse>(INSTANCE_API_PATH, rpcRequest);
  }

  async changeInstanceName(instanceId: string, organizationId: string, name: string): Promise<void> {
    const request: ChangeInstanceNameRequest = { rpcAction: 'rename', instanceId, organizationId, name };
    return await this.restService.post(INSTANCE_API_PATH, request);
  }

  async restoreInstanceBackup({
    organizationId,
    instanceId,
    backupId,
    instanceName,
    passwordHash,
    doubleSha1Password
  }: Omit<RestoreInstanceBackupRequest, 'rpcAction'>): Promise<RestoreInstanceBackupResponse> {
    const request: RestoreInstanceBackupRequest = {
      rpcAction: 'restore',
      instanceName,
      backupId,
      organizationId,
      instanceId,
      passwordHash,
      doubleSha1Password
    };
    return await this.restService.post<RestoreInstanceBackupResponse>(BACKUP_API_PATH, request);
  }

  async updateIpAccessList(
    organizationId: string,
    instanceId: string,
    ipAccessList: Array<IpAccessListEntry>
  ): Promise<void> {
    const request: ChangeIpAccessListRequest = {
      rpcAction: 'updateIpAccessList',
      organizationId,
      instanceId,
      ipAccessList
    };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async updateAutoScaling(
    organizationId: string,
    instanceId: string,
    enableIdleScaling: boolean,
    idleTimeoutMinutes: number,
    minAutoScalingTotalMemory?: number,
    maxAutoScalingTotalMemory?: number
  ): Promise<void> {
    const request: InstanceAutoScalingRequest = {
      rpcAction: 'updateAutoScaling',
      organizationId,
      instanceId,
      minAutoScalingTotalMemory,
      maxAutoScalingTotalMemory,
      enableIdleScaling,
      idleTimeoutMinutes
    };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async prepareUploadSignatureDetails(
    organizationId: string,
    fileName: string,
    mimeType?: string
  ): Promise<UploadSignatureDetails> {
    const request: PrepareUploadSignatureDetailsRequest = {
      rpcAction: 'prepareUploadSignatureDetails',
      organizationId,
      fileName,
      mimeType
    };
    return await this.restService.post<UploadSignatureDetails>(INSTANCE_API_PATH, request);
  }

  async getImportFileDownloadUrl(
    organizationId: string,
    instanceId: string,
    storageId: string
  ): Promise<GetImportFileDownloadUrlResponse> {
    const request: GetImportFileDownloadUrlRequest = {
      rpcAction: 'getImportFileDownloadUrl',
      organizationId,
      instanceId,
      storageId
    };
    return await this.restService.post<GetImportFileDownloadUrlResponse>(INSTANCE_API_PATH, request);
  }

  async refreshUserDataFlag(organizationId: string, instanceId: string): Promise<RefreshUserDataFlagResponse> {
    const request: RefreshUserDataFlagRequest = { rpcAction: 'refreshUserDataFlag', organizationId, instanceId };
    return await this.restService.post<RefreshUserDataFlagResponse>(INSTANCE_API_PATH, request);
  }

  async markInstanceHasUserData(organizationId: string, instanceId: string): Promise<MarkInstanceHasUserDataResponse> {
    const request: MarkInstanceHasUserDataRequest = {
      rpcAction: 'markInstanceHasUserData',
      organizationId,
      instanceId
    };
    return await this.restService.post<MarkInstanceHasUserDataResponse>(INSTANCE_API_PATH, request);
  }

  async getInstanceDbPermissions(instanceId: string): Promise<DbUsersResponse> {
    const request: DbUsersPasswordlessRequest = {
      rpcAction: 'getUsersPasswordless',
      instanceId
    };
    return await this.restService.post<DbUsersResponse>('dbUsers', request);
  }

  getDpAutoScalingLimits(regionId: RegionId): Promise<GetAutoScalingLimitsResponse> {
    const request: GetAutoScalingLimitsRequest = {
      rpcAction: 'getLimits',
      regionId
    };

    return this.restService.post<GetAutoScalingLimitsResponse>('autoScaling', request);
  }

  getInstanceAutoscaling(instanceId: string): Promise<GetInstanceAutoScalingResponse> {
    const request: GetInstanceAutoScalingRequest = {
      rpcAction: 'getAutoScaling',
      instanceId
    };

    return this.restService.post<GetInstanceAutoScalingResponse>('instance', request);
  }

  async verifyCustomerKeyConfig(
    organizationId: string,
    config: InstanceCustomerManagedEncryptionConfig
  ): Promise<VerifyCustomerKeyConfigResponse> {
    const request: VerifyCustomerKeyConfigRequest = {
      rpcAction: 'verifyCustomerKeyConfig',
      organizationId,
      customerManagedEncryption: config
    };

    return this.restService.post<VerifyCustomerKeyConfigResponse>('instance', request);
  }

  async getMysqlSettings(organizationId: string, instanceId: string): Promise<InstanceMysqlSettings> {
    const request: GetMysqlSettingsRequest = {
      rpcAction: 'getMysqlSettings',
      organizationId,
      instanceId
    };
    return this.restService.post(INSTANCE_API_PATH, request);
  }

  async updateMysqlSettings(organizationId: string, instanceId: string, enabled: boolean): Promise<void> {
    const request: UpdateMysqlSettingsRequest = {
      rpcAction: 'updateMysqlSettings',
      organizationId,
      instanceId,
      enabled
    };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async backfillMysqlPassword(
    organizationId: string,
    instanceId: string,
    doubleSha1Password: string,
    sha256Password: string
  ): Promise<void> {
    const request: BackfillMysqlPasswordRequest = {
      rpcAction: 'backfillMysqlPassword',
      organizationId,
      instanceId,
      doubleSha1Password,
      sha256Password
    };
    await this.restService.post(INSTANCE_API_PATH, request);
  }

  async getInstanceIamPrincipal(organizationId: string, instanceId: string): Promise<GetInstanceIamPrincipalResponse> {
    const request: GetInstanceIamPrincipalRequest = {
      rpcAction: 'getInstanceIamPrincipal',
      instanceId,
      organizationId
    };
    return await this.restService.post(INSTANCE_API_PATH, request);
  }

  async updateInstanceDbRoleMapping(
    organizationId: string,
    instanceId: string,
    defaultDatabaseRoleMappings: ReadonlyArray<InstanceDatabaseAccessMapping>
  ): Promise<void> {
    const request: UpdateInstanceDbRoleMappingRequest = {
      rpcAction: 'updateInstanceDbRoleMapping',
      organizationId,
      instanceId,
      defaultDatabaseRoleMappings
    };
    await this.restService.post(INSTANCE_API_PATH, request);
  }
}
