import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import {
  AuthorizationParams as Auth0AuthorizationParams,
  AuthService as Auth0Service,
  User as Auth0User
} from '@auth0/auth0-angular';
import { RestService } from '@cp/common-services/rest.service';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isInternalUserEmail } from '@cp/common/utils/TestUsers';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { environment } from '@cp/web/environments/environment';
import copy from 'copy-to-clipboard';
import { catchError, distinctUntilChanged, NEVER, Observable, switchMap, take, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';

/** Which method to use for login: 'loginWithRedirect' or 'loginWithPopup'. */
const AUTH0_LOGIN_METHODS = ['redirect', 'popup'] as const;
type Auth0LoginMethod = (typeof AUTH0_LOGIN_METHODS)[number];

/**
 * - `'page'`: displays the UI with a full page view.
 * - `'popup'`: displays the UI with a popup window.
 * - `'touch'`: displays the UI in a way that leverages a touch interface.
 * - `'wap'`: displays the UI with a "feature phone" type interface.
 */
const AUTH0_LOGIN_DISPLAY_MODES = ['page', 'popup', 'touch', 'wap'] as const;
type Auth0LoginDisplayMode = (typeof AUTH0_LOGIN_DISPLAY_MODES)[number];

/**
 * - `'none'`: do not prompt user for login or consent on reauthentication.
 * - `'login'`: prompt user for reauthentication.
 * - `'consent'`: prompt user for consent before processing request.
 * - `'select_account'`: prompt user to select an account.
 */
const AUTH0_LOGIN_PROMPTS = ['none', 'login', 'consent', 'select_account'] as const;
type Auth0LoginPrompt = (typeof AUTH0_LOGIN_PROMPTS)[number];

/**
 * Provides a hint to Auth0 as to what flow should be displayed.
 * The default behavior is to show a login page, but you can override
 * this by passing 'signup' to show the signup page instead.
 *
 * This only affects the New Universal Login Experience.
 */
const AUTH0_SCREEN_HINTS = ['signup', 'login'] as const;
type Auth0ScreenHint = (typeof AUTH0_SCREEN_HINTS)[number];

interface Auth0MfaDevice {
  id: string;
  authenticator_type: string;
  active: boolean;
}

interface Auth0MfaAssociateResponse {
  authenticator_type: string;
  secret: string;
  barcode_uri: string;
  recovery_codes: string[];
}

interface NewMfaDeviceInfo {
  qrcode: string;
}

const AUTH0_HANDLER_PATH = `auth0`;

@Component({
  selector: 'auth0-demo',
  templateUrl: './auth0-demo.component.html',
  styleUrls: ['./auth0-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Auth0DemoComponent extends OnDestroyComponent {
  readonly isDemoEnabled$: Observable<boolean>;
  readonly user$: Observable<Auth0User | 'loading' | 'signed-out'>;

  readonly AUTH0_LOGIN_METHODS = AUTH0_LOGIN_METHODS;
  loginMethod: Auth0LoginMethod = 'redirect';

  readonly AUTH0_LOGIN_DISPLAY_MODES = AUTH0_LOGIN_DISPLAY_MODES;
  loginDisplayMode: Auth0LoginDisplayMode = 'page';

  readonly AUTH0_LOGIN_PROMPTS = AUTH0_LOGIN_PROMPTS;
  loginPrompt: Auth0LoginPrompt = 'login';

  readonly AUTH0_SCREEN_HINTS = AUTH0_SCREEN_HINTS;
  screenHint: Auth0ScreenHint = 'login';

  apiCallResult = 'unknown';
  auth0User: Auth0User | null | undefined;
  accessToken?: string;
  parsedAccessToken?: string;

  mfaDevices: Array<Auth0MfaDevice> = [];
  mfaStatusMessage = 'not fetched';

  newMfaDeviceInfo?: NewMfaDeviceInfo;
  otpCode?: string;

  constructor(
    accountStateService: AccountStateService,
    private readonly auth0: Auth0Service,
    private readonly httpClient: HttpClient,
    private readonly cdr: ChangeDetectorRef,
    private readonly restService: RestService
  ) {
    super();
    this.isDemoEnabled$ = accountStateService.observeUserDetails().pipe(
      map((u) =>
        Boolean(
          environment.stage === 'local' ||
            u?.features?.includes('FT_USER_AUTH0') ||
            isInternalUserEmail(u?.email) ||
            u?.email === 'e2e+a1@clickhouse.testinator.com'
        )
      ),
      distinctUntilChanged()
    );
    this.user$ = auth0.user$.pipe(
      tap((u) => {
        console.log('Got Auth0 user', u);
        this.apiCallResult = 'unknown';
        this.auth0User = u;
        this.accessToken = undefined;
        this.parsedAccessToken = undefined;
        if (u) {
          // 'getAccessTokenSilently' is not a real observable, but a promise wrapped as an observable,
          // so we have to call & await it every time before use.
          this.auth0
            .getAccessTokenSilently()
            .pipe(takeUntil(this.onDestroy))
            .subscribe((accessToken) => {
              this.accessToken = accessToken;
              this.parsedAccessToken = parseJwt(accessToken);
              this.refreshMfaDevices();
              this.cdr.markForCheck();
            });
        }
      }),
      map((u) => (u === undefined ? 'loading' : u === null ? 'signed-out' : u)),
      distinctUntilChanged()
    );
  }

  copyAccessToken(): void {
    copy(this.accessToken || '');
  }

  signIn(): void {
    const authorizationParams: Auth0AuthorizationParams = {
      display: this.loginDisplayMode,
      prompt: this.loginPrompt,
      screen_hint: this.screenHint,
      redirect_uri: `${window.location.origin}/auth0`,
      scope: 'openid profile email read:authenticators remove:authenticators enroll'
    };
    if (this.loginMethod === 'redirect') {
      this.auth0.loginWithRedirect({ authorizationParams, appState: { target: '/auth0' } });
    } else {
      this.auth0.loginWithPopup({ authorizationParams });
    }
  }

  signOut(): void {
    this.auth0.logout({ openUrl: false });
  }

  async runApiCall(): Promise<void> {
    this.apiCallResult = 'running';
    try {
      this.apiCallResult = await this.restService.post<string>(AUTH0_HANDLER_PATH, { rpcAction: 'test' }, true);
    } catch (e) {
      console.log('runApiCall: Error calling API', e);
      this.apiCallResult = `Error: ${(e as Error)?.message}`;
    } finally {
      this.cdr.markForCheck();
    }
  }

  triggerAppReloadByBackend(): void {
    this.restService.post<string>(AUTH0_HANDLER_PATH, { rpcAction: 'testWebAppReload' }, true).then();
  }

  /**
   *  To enroll an MFA device, we have to use a dedicated audience:  https://{domain}/mfa/.
   *  The enrollment won't work with any other audience, even with 'enroll' scope & consent from a user.
   */
  private getMfaEnrollmentAccessToken(): Observable<string | undefined> {
    // Since the audience is not the same used during user sign-in/sign-up, we have to show a consent screen to a user
    // in order to get the access token. The consent screen will be shown only once.
    return this.auth0
      .getAccessTokenWithPopup({
        authorizationParams: {
          scope: 'enroll',
          audience: `https://${environment.auth0ClientOptions.domain}/mfa/`
        }
      })
      .pipe(take(1));
  }

  refreshMfaDevices(): void {
    console.log('Fetching list of MFA devices');
    this.mfaDevices = [];
    this.mfaStatusMessage = 'getting access token';
    this.cdr.markForCheck();
    this.auth0
      .getAccessTokenSilently()
      .pipe(
        tap((accessToken) => {
          console.log('Using access token:', accessToken);
          this.mfaStatusMessage = 'got access token, fetching mfa details';
          this.cdr.markForCheck();
        }),
        switchMap((accessToken) =>
          this.httpClient.get<Array<Auth0MfaDevice>>(
            // The method below requires 'read:authenticators' scope in the access token.
            `https://${environment.auth0ClientOptions.domain}/mfa/authenticators`,
            { headers: { Authorization: `Bearer ${accessToken}` } }
          )
        ),
        take(1),
        takeUntil(this.onDestroy),
        catchError((error) => {
          console.error('Error fetching mfa listing', error);
          this.mfaStatusMessage = `error: ${
            (error as Error)?.message || (error as HttpErrorResponse)?.error?.error || error
          }`;
          this.cdr.markForCheck();
          return NEVER;
        })
      )
      .subscribe((mfaDevices) => {
        console.log('refreshMfaDevices response: ', mfaDevices);
        this.mfaStatusMessage = `Found ${mfaDevices.length} device${mfaDevices.length === 1 ? '' : 's'}`;
        this.mfaDevices = mfaDevices;
        this.cdr.markForCheck();
      });
  }

  addNewMfaDevice(): void {
    this.otpCode = undefined;
    console.log('Adding a new MFA device');
    this.auth0
      .getAccessTokenSilently()
      .pipe(
        tap((mfaAccessToken) => console.log('Using access token:', mfaAccessToken)),
        switchMap((mfaAccessToken) =>
          this.httpClient.post<Auth0MfaAssociateResponse>(
            // The method below requires 'enroll' scope in the access token.
            `https://${environment.auth0ClientOptions.domain}/mfa/associate`,
            { authenticator_types: ['otp'] },
            { headers: { Authorization: `Bearer ${mfaAccessToken}` } }
          )
        ),
        take(1),
        takeUntil(this.onDestroy),
        catchError((error) => {
          console.error('Error associating mfa device', error);
          return NEVER;
        })
      )
      .subscribe((response) => {
        console.log('Got MFA associate response', response);
        if (response.barcode_uri) {
          this.newMfaDeviceInfo = { qrcode: response.barcode_uri };
        } else {
          window.alert('Failed to add a new OTP device');
        }
        this.refreshMfaDevices();
      });
  }

  async toggleMfa(isEnabled: boolean): Promise<void> {
    try {
      this.apiCallResult = await this.restService.post<string>(
        AUTH0_HANDLER_PATH,
        { rpcAction: 'toggleMfa', isEnabled },
        true
      );
    } catch (e) {
      console.log('runApiCall: Error calling API', e);
      this.apiCallResult = `Error: ${(e as Error)?.message}`;
    } finally {
      this.cdr.markForCheck();
    }
  }

  deleteMfaDevice(mfaDevice: Auth0MfaDevice): void {
    console.log('Deleting MFA', mfaDevice);
    const deviceId = mfaDevice.id;
    this.hideMfaVerification();
    this.auth0
      .getAccessTokenSilently()
      .pipe(
        tap((accessToken) => console.log('Using access token:', accessToken)),
        switchMap((accessToken) =>
          this.httpClient.delete(
            // The method below requires 'remove:authenticators' scope in the access token.
            `https://${environment.auth0ClientOptions.domain}/mfa/authenticators/${deviceId}`,
            { headers: { Authorization: `Bearer ${accessToken}` } }
          )
        ),
        take(1),
        takeUntil(this.onDestroy),
        catchError((error) => {
          console.error('Error deleting mfa device', error);
          return NEVER;
        })
      )
      .subscribe(() => this.refreshMfaDevices());
  }

  verifyOtpCode(): void {
    assertTruthy(this.otpCode);
    console.log('Verify OTP code and enroll MFA device', this.otpCode);
    const otpCode = this.otpCode;
    // noinspection HttpUrlsUsage
    this.getMfaEnrollmentAccessToken()
      .pipe(
        tap((mfaAccessToken) => console.log('Got mfa access token:', mfaAccessToken)),
        switchMap((mfaAccessToken) =>
          this.httpClient.post<{ access_token?: string }>(
            `https://${environment.auth0ClientOptions.domain}/oauth/token`,
            {
              grant_type: 'http://auth0.com/oauth/grant-type/mfa-otp',
              client_id: environment.auth0ClientOptions.clientId,
              mfa_token: mfaAccessToken,
              otp: otpCode
            },
            { headers: { Authorization: `Bearer ${mfaAccessToken}` } }
          )
        ),
        take(1),
        takeUntil(this.onDestroy),
        catchError((error) => {
          console.error('Error enrolling mfa device', error);
          return NEVER;
        })
      )
      .subscribe((response) => {
        console.log('MFA device enrolling response', response);
        if (response.access_token) {
          window.alert('MFA device is enrolled');
          this.hideMfaVerification();
          this.refreshMfaDevices();
        } else {
          window.alert('Error enrolling MFA device, see logs.');
        }
      });
  }

  hideMfaVerification(): void {
    this.newMfaDeviceInfo = undefined;
    this.otpCode = undefined;
    this.cdr.markForCheck();
  }

  readonly stringify = JSON.stringify;
}

function parseJwt(token: string): string {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  );
  const object = JSON.parse(jsonPayload);
  return JSON.stringify(object, undefined, 4);
}
