import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

/**
 *  Extension for [matCellDef] that projects 'typedBy' type into the element and make it compile type verifiable.
 *  Default Angular [matCellDef] uses 'any' for the table element type.
 *
 *  Usage: <td *matCellDef="let element; typedBy: elementsDataSource">
 */
@Directive({
  selector: '[matCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatCellDef }]
})
export class TypeSafeMatCellDef<T> extends MatCellDef {
  @Input() matCellDefTypedBy!: Array<T> | Observable<Array<T>> | MatTableDataSource<T>;

  static ngTemplateContextGuard<T>(dir: TypeSafeMatCellDef<T>, ctx: unknown): ctx is { $implicit: T; index: number } {
    return true;
  }
}
