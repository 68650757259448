<ng-container>
  <div class="instance_card_wrapper" data-cy="upgrade-instance-card">
    <div
      class="instance_card"
      [galaxyClick]="['serviceList.serviceCard.serviceSelect', {serviceId: instance.id}]"
      [ngClass]="{ disabled: instance.state === 'failed'}"
      *ngIf="regionMap[instance.regionId] as region"
      [routerLink]="instance.state !== 'failed' ? detailsPagePath : undefined">
      <div class="card_header">
        <div class="left">
          <div [title]="instance.name" class="name" data-cy="service-name">{{instance.name}} (Production)</div>
          <div class="version">
            {{formatCHVersion(instance.clickhouseVersion)}}
          </div>
        </div>
        <div class="right">
          <button
            class="menu_button"
            data-cy="service-menu-button"
            mat-button>
            <mat-icon class="icon svg_fill">more_vert</mat-icon>
          </button>
        </div>
      </div>
      <div class="card_body" [ngClass]="{development: instance.instanceTier === 'Development'}">
        <div class="metadata_container">
          <div class="metadata_row state_row">
            <cp-instance-state [additionalClass]="'normal_weight'" [kind]="'ICON_AND_LABEL'"
                               [state]="instance.upgradeStatus === 'error' ? 'upgrading-error' : 'upgrading'"></cp-instance-state>
            <cp-instance-no-access-prompt [instanceId]="instance.id"></cp-instance-no-access-prompt>
          </div>
          <div class="metadata_row">
            <mat-icon [svgIcon]="region.cloudProvider" class="icon" data-cy="cloud-provider-icon"></mat-icon>
            <span class="region" data-cy="region">{{region.name}} ({{region.displayId}})</span>
          </div>
        </div>
        <cp-connect-console-button
          [instance]="instance"
          eventPrefix="serviceList.serviceCard"
          color="white">
        </cp-connect-console-button>
      </div>
    </div>
    <div class="actions_wall_wrapper">
      <div class="actions_wall_container">
        <div class="upgrade_status_container">
          <div class="actions"
               *ngIf="instance.upgradeStatus === 'error'">
            <button
              class="secondary_button"
              mat-flat-button
              data-cy="upgrade-instance-card-error-upgrade-cancel-button"
              (click)="onCancel()"
            >
              Cancel
            </button>
            <button
              color="primary"
              mat-flat-button
              data-cy="upgrade-instance-card-error-upgrade-try-again-button"
              (click)="onTryAgain()">
              Try again
            </button>
          </div>
          <div
            *ngIf="instance.upgradeStatus === 'in_progress'">
            <button
              class="secondary_button"
              data-cy="upgrade-instance-card-cancel-upgrade-button"
              mat-flat-button
              (click)="onCancel()">
              Cancel Upgrade
            </button>
          </div>

          <div *ngIf="instance.upgradeErrorMessage" class="upgrade_error">
            <p data-cy="instance-upgrade-error">{{getUpgradeDisplayErrorMessage(instance.upgradeErrorMessage)}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
