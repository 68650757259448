import { simplifyJson } from 'logpipes';

export const SENSITIVE_DATA_PLACEHOLDER = '****';

/** Supported log category names. */
export const ALL_LOG_CATEGORY_NAMES = ['log', 'error', 'warn', 'info', 'debug'] as const;
export type LogCategoryName = (typeof ALL_LOG_CATEGORY_NAMES)[number];

/** Lowercase versions of sensitive substrings. */
const sensitiveTokens = ['password'];

export function isSensitivePropertyKey(propertyName: string): boolean {
  return sensitiveTokens.includes(propertyName.toLowerCase());
}

/** Known & allowed tokens that match 'sensitiveTokens'. Case sensitive. */
const whitelistedTokenRegExps: Array<RegExp> = [
  /**
   *  Web socket event type.
   * "Example: {"type":"ORG_INSTANCE_PASSWORD_CHANGED","objId":"3bcb0e42-4ffe-486b-9b1e-4a63901d3e54"}.
   */
  'ORG_INSTANCE_PASSWORD_CHANGED'
].map((t) => new RegExp(t, 'g'));

/** Replaces field value with a non-sensitive value if needed. */
export function replaceSensitiveFieldValue<ValueType = unknown>(name: string, value: ValueType): ValueType | string {
  if (isSensitivePropertyKey(name)) {
    return SENSITIVE_DATA_PLACEHOLDER;
  }
  if (typeof value !== 'string') {
    return value;
  }
  const whitelistedValue = whitelistedTokenRegExps
    .reduce((v: string, r: RegExp) => v.replace(r, '*'), value)
    .toLowerCase();
  return sensitiveTokens.some((t) => whitelistedValue.includes(t)) ? SENSITIVE_DATA_PLACEHOLDER : value;
}

/** Stringify the object, safely. */
export function safeStringify(input: unknown): string {
  return JSON.stringify(simplifyJson(input));
}
