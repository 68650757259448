import { Injectable } from '@angular/core';
import {
  ControlPlaneAnalyticsComponent,
  ControlPlaneAnalyticsNamespace,
  GalaxyProperties
} from '@cp/common/protocol/Galaxy';
import { isTestRun } from '@cp/web/app/common/utils/AngularUtils';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { environment } from '@cp/web/environments/environment';
import { GalaxyService, GalaxyServiceData } from './galaxy.service';

// See reference in: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
export interface SegmentAnalytics {
  _writeKey: string;
  load: (key: string) => void;
  page: (
    category?: string,
    name?: string,
    properties?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) => void;
  track: (
    event?: string,
    properties?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) => void;
  identify: (
    userId?: string,
    traits?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) => void;
}

export type SegmentCategory =
  | 'cloud ui'
  | 'cloud ui service'
  | 'cloud ui org setting'
  | 'onboarding'
  | 'integrationList'
  | 'integrationPage';
export type SegmentEventType =
  | 'sign up'
  | 'sign in'
  | 'modify organization'
  | 'create new service'
  | 'click'
  | 'done'
  | 'invite member'
  | 'update profile'
  | 'upload file'
  | 'click email'
  | 'onboarding'
  | 'load data';

export type AnalyticsWebEvent = {
  event: SegmentEventType;
  label: string;
  category: SegmentCategory;
  properties?: GalaxyProperties;
  reportToServer?: boolean;
  identifyOnServer?: boolean;
  component: ControlPlaneAnalyticsComponent;
  view: ControlPlaneAnalyticsNamespace;
  userId?: string;
};

export type AnalyticsServerEvent = {
  event: SegmentEventType;
  properties: GalaxyProperties;
  view: ControlPlaneAnalyticsNamespace;
  component: ControlPlaneAnalyticsComponent;
  identify: boolean;
  reportExternally: boolean;
  userId?: string;
};

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly galaxyService: GalaxyService
  ) {
    const analytics: SegmentAnalytics = this.getAnalytics();
    analytics._writeKey = environment.segmentKey;
    analytics.load(environment.segmentKey);
  }

  reportPageView(): void {
    if (!this.isReportingEnabled()) return;

    try {
      this.getAnalytics().page();
    } catch (error) {
      console.error('Could not send page load event to segment', error);
    }
  }

  identify(email: string, name: string, userId?: string): void {
    if (!this.isReportingEnabled()) return;

    try {
      this.getAnalytics().identify(userId, { email, name });
    } catch (error) {
      console.error('Could not identify user', error);
    }
  }

  async reportSegmentEventToServer({
    event,
    view,
    component,
    properties,
    identify,
    reportExternally,
    userId
  }: AnalyticsServerEvent): Promise<void> {
    if (!this.isGalaxyReportingEnabled()) return;

    const request: GalaxyServiceData = {
      event: `${view}.${component}.${event}`,
      properties,
      identify,
      reportExternally,
      interaction: 'trigger',
      userId
    };

    await this.galaxyService.track(request);
  }

  trackGaEvent({
    event,
    label,
    category,
    properties,
    view,
    component,
    reportToServer = true,
    identifyOnServer = false,
    userId
  }: AnalyticsWebEvent): void {
    if (!this.isReportingEnabled() && !this.isGalaxyReportingEnabled()) return;

    if (!properties) {
      properties = {};
    }
    properties = {
      ...properties,
      label,
      category,
      orgId: this.organizationStateService.getCurrentOrgId()
    };

    this.trackEvent(event, properties);

    if (reportToServer) {
      this.reportSegmentEventToServer({
        event,
        view,
        component,
        properties,
        identify: identifyOnServer,
        reportExternally: true,
        userId
      }).then();
    }
  }

  // Note: must use this method rather than save window.analytics in this class because it uses a factory.
  getAnalytics(): SegmentAnalytics {
    return (window as any).analytics;
  }

  private trackEvent(event: SegmentEventType, properties: Record<string, any>): void {
    if (!this.isReportingEnabled()) return;

    try {
      this.getAnalytics().track(event, properties);
    } catch (error) {
      console.log('Could not send event to segment', event, properties);
    }
  }

  private isReportingEnabled(): boolean {
    return environment.stage !== 'local';
  }

  private isGalaxyReportingEnabled(): boolean {
    return environment.stage !== 'local' || isTestRun();
  }
}
