import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import copy from 'copy-to-clipboard';

@Component({
  selector: 'cp-highlighted-code-snippet',
  templateUrl: './highlighted-code-snippet.component.html',
  styleUrls: ['./highlighted-code-snippet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlightedCodeSnippetComponent {
  @ViewChild('contentWrapper') content!: ElementRef;

  /**
   * Optional snippet language for syntax highlighting.
   * If not provided (empty string) highlight js will automatically detect the namespace to be use.
   */
  @Input() highlightLanguage = '';

  @Input()
  copyTransformer?: (str: string) => string;

  constructor(private readonly snackBar: MatSnackBar) {}

  copyToClipboard(text: string): void {
    this.snackBar.open('Copied to clipboard', 'Dismiss', { duration: 5000 });
    copy(this.copyTransformer ? this.copyTransformer(text) : text);
  }

  get text(): string {
    return this.content?.nativeElement.innerText || '';
  }
}
