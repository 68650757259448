import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Range } from '@cp/common/types/MathTypes';
import { assertTruthy } from '@cp/common/utils/Assert';

interface TickLayout {
  /** Tick x percentage relative to the slider width. */
  xPercentage: number;

  /** Tick index (starts from 0). */
  index: number;
}

/** Change to the min or max value of the slider */
export interface SeedSliderChange {
  xPercentage: number;
  index: number;
}

@Component({
  selector: 'seed-slider',
  templateUrl: './seed-slider.component.html',
  styleUrls: ['./seed-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeedSliderComponent implements OnChanges {
  @ViewChild('slider') slider?: ElementRef<HTMLDivElement>;

  onPointerDown(event: PointerEvent): void {
    assertTruthy(this.slider);
    this.slider.nativeElement.setPointerCapture(event.pointerId);
  }

  onPointerMove(event: PointerEvent): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (!this.clickedHandle || event.movementX === 0) return;
    if (this.clickedHandle === 'both') {
      // Both of the handles are the same place, and we couldn't decide which one was clicked.
      // Decide on the clicked handle based on the mouse dir.
      this.clickedHandle = event.movementX > 0 ? 'right' : 'left';
    }
    const isLeftHandle = this.clickedHandle === 'left';
    const prevPercentage = isLeftHandle ? this.leftPercentage : this.rightPercentage;
    const xPercentage = ((event.movementX + (prevPercentage / 100) * this.sliderWidth) / this.sliderWidth) * 100;
    if (isLeftHandle) {
      this.updateMinHandle(xPercentage);
    } else {
      this.updateMaxHandle(xPercentage);
    }
  }

  finalizeHandleMovement(event?: PointerEvent): void {
    const leftMoved = this.cachedLeftPercentage !== undefined && this.leftPercentage !== this.cachedLeftPercentage;
    const rightMoved = this.cachedRightPercentage !== undefined && this.rightPercentage !== this.cachedRightPercentage;

    if (event) {
      assertTruthy(this.slider);
      this.slider.nativeElement.releasePointerCapture(event.pointerId);
    }
    this.clickedHandle = undefined;
    if (this.sticky) {
      if (leftMoved) {
        this.leftPercentage = this.findClosestTickPos(this.leftPercentage);
      }
      if (rightMoved) {
        this.rightPercentage = this.findClosestTickPos(this.rightPercentage);
      }
    }

    if (leftMoved) {
      this.minChange.emit({
        xPercentage: this.leftPercentage,
        index: Math.round((this.leftPercentage / 100) * (this.numTicks - 1))
      });
    }

    if (rightMoved) {
      this.maxChange.emit({
        xPercentage: this.rightPercentage,
        index: Math.round((this.rightPercentage / 100) * (this.numTicks - 1))
      });
    }

    this.cachedLeftPercentage = undefined;
    this.cachedRightPercentage = undefined;
  }

  /** The width of the slider bar in pixels. */
  @Input()
  sliderWidth = 0;

  /** The number of slider ticks including min and max. */
  @Input()
  numTicks = 2;

  /** Optional tick label template. If not provided, the index of the tick will be presented as the label. */
  @Input()
  tickLabelTemplate?: TemplateRef<{ index: number }>;

  /** Indicates whether the handles are sticky (attached to the closest tick on mouse release). */
  @Input()
  sticky = true;

  /** Set the index value of the left (minxo) handle. The index must be between 0 to numTicks - 1*/
  @Input()
  set minIndex(minIndex: number) {
    assertTruthy(minIndex >= 0 && minIndex < this.numTicks, `minIndex must be in the range [0, numTick-1]`);
    this.minPercentage = this.convertIndexToPercentage(minIndex);
  }

  /** Set the percentage value of the left (min) handle. */
  @Input()
  set minPercentage(minPercentage: number) {
    this.leftPercentage = minPercentage;
  }

  leftPercentage = 0;

  /** Set the index value of the right (max) handle. The index must be between 0 to numTicks - 1*/
  @Input()
  set maxIndex(maxIndex: number) {
    assertTruthy(maxIndex >= 0 && maxIndex < this.numTicks, `maxIndex must be in the range [0, numTick-1]`);
    this.maxPercentage = this.convertIndexToPercentage(maxIndex);
  }

  /** Set the percentage value of the right (max) handle. */
  @Input()
  set maxPercentage(maxPercentage: number) {
    this.rightPercentage = maxPercentage;
  }

  rightPercentage = 100;

  /**
   * The values domain of the handles. The handles can't exceed this domain, and any tick outside of this domain
   * will be greyed-out.
   * The default domain is the whole range of the slider.
   */
  @Input()
  handlesDomain: Range = { min: 0, max: this.numTicks };

  @Input()
  disabled = false;

  @Output()
  minChange = new EventEmitter<SeedSliderChange>();

  @Output()
  maxChange = new EventEmitter<SeedSliderChange>();

  clickedHandle?: 'left' | 'right' | 'both';
  ticksLayouts: Array<TickLayout> = [];

  /**
   * Left and right cached percentages. These are used to cache the position of the handles before they move in order
   * to track if they changed when the user released the mouse.
   */
  private cachedLeftPercentage?: number;
  private cachedRightPercentage?: number;

  onHandleClick(handle: 'left' | 'right'): void {
    if (this.disabled) {
      return;
    }
    this.cachedLeftPercentage = this.leftPercentage;
    this.cachedRightPercentage = this.rightPercentage;
    this.clickedHandle = handle;
    if (Math.abs(this.leftPercentage - this.rightPercentage) < 1) {
      // The handles are on top of each other - we'll decide which handle is clicked based on the mouse move direction.
      this.clickedHandle = 'both';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['handlesDomain'] ||
      changes['minIndex'] ||
      changes['minPercentage'] ||
      changes['maxIndex'] ||
      changes['maxPercentage']
    ) {
      this.updateHandles();
    }
    this.computeTicksLayout();
  }

  computeTicksLayout(): void {
    const step = this.sliderWidth / (this.numTicks - 1);
    // Reset ticks array.
    this.ticksLayouts = [];
    let currentX = 0;
    // Compute all the ticks between and max.
    for (let i = 0; i < this.numTicks - 1; i++) {
      this.ticksLayouts.push({
        xPercentage: (currentX / this.sliderWidth) * 100,
        index: i
      });
      currentX += step;
    }
    // Make sure last tick is at the end.
    this.ticksLayouts.push({
      xPercentage: 100,
      index: this.numTicks - 1
    });
  }

  private findClosestTickPos(xPercentage: number): number {
    if (this.ticksLayouts.length === 0) return xPercentage;
    let closest = 0;
    for (const tick of this.ticksLayouts) {
      if (Math.abs(tick.xPercentage - xPercentage) < Math.abs(closest - xPercentage)) {
        closest = tick.xPercentage;
      }
    }
    return closest;
  }

  convertIndexToPercentage(index: number): number {
    return Math.round((index / (this.numTicks - 1)) * 100);
  }

  isTickOutsideOfDomain(tick: TickLayout): boolean {
    return tick.index < this.handlesDomain.min || tick.index > this.handlesDomain.max;
  }

  private updateMinHandle(xPercentage: number): void {
    // Prevent the left handle from passing the right handle.
    const maxRestriction = this.convertIndexToPercentage(this.handlesDomain.max);
    const minRestriction = this.convertIndexToPercentage(this.handlesDomain.min);
    this.leftPercentage = Math.max(Math.min(xPercentage, this.rightPercentage, maxRestriction), minRestriction);
  }

  private updateMaxHandle(xPercentage: number): void {
    // Prevent the right handle from passing the left handle.
    const maxRestriction = this.convertIndexToPercentage(this.handlesDomain.max);
    const minRestriction = this.convertIndexToPercentage(this.handlesDomain.min);
    this.rightPercentage = Math.min(Math.max(xPercentage, this.leftPercentage, minRestriction), maxRestriction);
  }

  private updateHandles(): void {
    this.updateMinHandle(this.leftPercentage);
    this.updateMaxHandle(this.rightPercentage);
    this.finalizeHandleMovement();
  }

  get leftPercentageStyle(): number {
    return this.disabled
      ? 0
      : this.leftPercentage === this.rightPercentage
      ? this.leftPercentage - 1
      : this.leftPercentage;
  }

  get rightPercentageStyle(): number {
    return this.disabled
      ? 0
      : this.leftPercentage === this.rightPercentage
      ? this.rightPercentage + 1
      : this.rightPercentage;
  }
}
