import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportCaseDetails } from '@cp/common/protocol/Support';
import { SupportCasesStateService } from '@cp/web/app/support/support-cases-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-case-reply-dialog',
  templateUrl: './case-reply-dialog.component.html',
  styleUrls: ['./case-reply-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseReplyDialogComponent {
  readonly caseDetailsObs: Observable<SupportCaseDetails>;

  constructor(
    public dialogRef: MatDialogRef<CaseReplyDialogComponent>,
    private readonly supportCasesStateService: SupportCasesStateService,
    private readonly snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private readonly caseId: string
  ) {
    this.caseDetailsObs = supportCasesStateService.observeSupportCase(caseId);
  }

  replySuccessful() {
    this.dialogRef.close();
    /*
      TODO - https://github.com/ClickHouse/control-plane/issues/5698
    */
    window.location.reload();
    this.snackBar.open('Reply submitted', 'Dismiss', { duration: 5000 });
  }
}
