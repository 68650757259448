import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { SeedCheckboxComponent } from './forms/seed-checkbox/seed-checkbox.component';
import { SeedSelectComponent } from './forms/seed-select/seed-select.component';
import { SeedSliderComponent } from './forms/seed-slider/seed-slider.component';
import { SeedTabComponent } from './forms/seed-tab/seed-tab.component';
import { SeedInputDirective } from './seed-input.directive';

@NgModule({
  declarations: [SeedInputDirective, SeedSelectComponent, SeedSliderComponent, SeedCheckboxComponent, SeedTabComponent],
  imports: [CommonModule, MatMenuModule],
  exports: [SeedSelectComponent, SeedSliderComponent, SeedCheckboxComponent, SeedTabComponent]
})
export class SeedUiModule {}
