<div *ngIf="relevantContent | async as content">
  <p class="content_container_title" *ngIf="content.length">You might be interested in</p>
  <a class="content_container"
    galaxyClick="integrationPage.relevantContent.relevantContentSelected"
    target="_blank" href={{blogPost.url}}
    (click)="onLinkClicked(blogPost)" *ngFor="let blogPost of content">
    <div class="title side_padding">{{blogPost.title}}</div>
    <div class="separator_line"></div>
    <div class="details side_padding">{{blogPost.shortDescription}}</div>
    <div class="footer side_padding">
      Read More <mat-icon>arrow_right_alt</mat-icon>
    </div>
  </a>
</div>

