import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { BehaviorSubject, take, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'cw-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent extends OnDestroyComponent {
  /** Sets how fast the indicator will be shown. Read only once on component creation. */
  @Input() showDelayMillis = 500;

  readonly isVisible$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    if (this.showDelayMillis >= 0) {
      timer(this.showDelayMillis)
        .pipe(take(1), takeUntil(this.onDestroy))
        .subscribe(() => this.isVisible$.next(true));
    } else {
      this.isVisible$.next(true);
    }
  }
}
