import { Injectable } from '@angular/core';
import {
  Address,
  ConfirmUpdatedPaymentMethodResponse,
  GetClientSecretResponse,
  OrganizationDetails
} from '@cp/common/protocol/Billing';
import { isDefined } from '@cp/common/protocol/Common';
import {
  AdminBillingState,
  AdminBillingStateAddress,
  AdminBillingStateMetadata,
  AdminBillingStateService
} from '@cp/web/app/admin/admin-billing/admin-billing-state.service';
import { BillingApiService } from '@cp/web/app/admin/billing-api.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminBillingService {
  constructor(
    private readonly billingApiService: BillingApiService,
    private readonly adminBillingStateService: AdminBillingStateService,
    private readonly organizationStateService: OrganizationStateService
  ) {
    organizationStateService
      .observeCurrentOrganizationId()
      .pipe(filter(isDefined))
      .subscribe(async (id) => {
        await this.refreshAdminBillingState(id);
      });
  }

  async refreshAdminBillingState(organizationId: string): Promise<void> {
    this.adminBillingStateService.clear();
    await this.getOrganizationBillingDetails(organizationId);
  }

  async updateOrganizationBillingDetails(
    organizationId: string,
    details: OrganizationDetails,
    updateAddressOnly?: boolean
  ): Promise<void> {
    await this.billingApiService.updateOrganizationBillingDetails(organizationId, details);
    if (updateAddressOnly) {
      this.updateAddressAdminBillingServiceState(details);
      return;
    }
    this.updateAdminBillingServiceState(details);
  }

  updateAddressAdminBillingServiceState(details: OrganizationDetails): void {
    const address: AdminBillingStateAddress = {
      billingAddress: details.billingAddress,
      bothAddressesSame:
        details.bothAddressesSame ??
        this.areAddressesTheSame(details.billingAddress, details.shippingAddress, !details.organizationIsABusiness),
      shippingAddress: details.shippingAddress,
      companyName: details.companyName
    };
    const billingState: Partial<AdminBillingState> = {
      address
    };

    this.adminBillingStateService.setLastUpdated();
    this.adminBillingStateService.setPartialState(billingState);
  }

  /** Updates local admin billing service state. */
  updateAdminBillingServiceState(details: OrganizationDetails): void {
    const address: AdminBillingStateAddress = {
      billingAddress: details.billingAddress,
      bothAddressesSame:
        details.bothAddressesSame ??
        this.areAddressesTheSame(details.billingAddress, details.shippingAddress, !details.organizationIsABusiness),
      shippingAddress: details.shippingAddress,
      companyName: details.companyName
    };
    const metadata: AdminBillingStateMetadata = {
      organizationIsABusiness: details.organizationIsABusiness,
      taxId: details.taxId,
      taxIdType: details.taxIdType,
      taxStatus: details.taxStatus
    };
    const billingState: Partial<AdminBillingState> = {
      address,
      metadata
    };

    this.adminBillingStateService.setLastUpdated();
    this.adminBillingStateService.setPartialState(billingState);
  }

  async getClientSecret(organizationId: string): Promise<GetClientSecretResponse> {
    return await this.billingApiService.getClientSecret(organizationId);
  }

  async getUsageStatementCSV(organizationId: string, billId: string): Promise<string> {
    const response = await this.billingApiService.getUsageStatement(organizationId, billId);
    return response.report;
  }

  async confirmUpdatedPaymentMethod(
    organizationId: string,
    paymentMethodUpdateId: string
  ): Promise<ConfirmUpdatedPaymentMethodResponse> {
    return await this.billingApiService.confirmUpdatedPaymentMethod(organizationId, paymentMethodUpdateId);
  }

  async getOrganizationBillingDetails(organizationId: string): Promise<void> {
    const response = await this.billingApiService.getOrganizationBillingDetails(organizationId);
    const {
      billingAddress,
      organizationIsABusiness,
      shippingAddress,
      companyName,
      taxId,
      taxIdType,
      taxStatus,
      paymentMethod,
      invoices,
      billUsageStatements,
      creditBalances,
      nextInvoiceDate,
      billingContact,
      canUpdatePaymentMethod
    } = response;

    // This is a hack to populate the billing state with something in case the org has no details.
    const address: AdminBillingStateAddress = {
      billingAddress: billingAddress || {},
      bothAddressesSame: this.areAddressesTheSame(billingAddress, shippingAddress, !organizationIsABusiness),
      shippingAddress: shippingAddress || {},
      companyName: companyName
    };
    const metadata: AdminBillingStateMetadata = {
      organizationIsABusiness: organizationIsABusiness,
      taxId,
      taxIdType,
      taxStatus
    };
    const billingState: AdminBillingState = {
      address,
      paymentMethod,
      metadata,
      invoices,
      billUsageStatements,
      creditBalances,
      nextInvoiceDate,
      billingContact,
      canUpdatePaymentMethod
    };
    this.adminBillingStateService.setLastUpdated();
    this.adminBillingStateService.setPartialState(billingState);
  }

  /**
   * Compares the two addresses provided using the following rules:
   * If both are empty, they are the same.
   * If all fields are the same (same value or both empty), they are the same.
   * If ignoreEmptyAddress2 is true and address2 is empty, they are the same.
   */
  private areAddressesTheSame(address1?: Partial<Address>, address2?: Partial<Address>, ignoreEmptyAddress2 = false) {
    if (address1 === address2) {
      return true;
    }

    if (ignoreEmptyAddress2 && !address2) {
      return true;
    }

    return (
      address1?.line1 === address2?.line1 &&
      address1?.line2 === address2?.line2 &&
      address1?.city === address2?.city &&
      address1?.state === address2?.state &&
      address1?.country === address2?.country &&
      address1?.postalCode === address2?.postalCode
    );
  }

  async requestAdditionalCredits(feedbackText: string): Promise<void> {
    await this.billingApiService.requestAdditionalCredits(feedbackText);
  }
}
