import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ORG_WITH_SAME_NAME_ALREADY_EXISTS } from '@cp/common/protocol/Organization';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { MAX_SIMPLE_NAME_LENGTH, MIN_SIMPLE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import {
  maxLengthTrimmedValidator,
  minLengthTrimmedValidator,
  organizationNameValidator
} from '@cp/web/app/common/utils/FormValidationUtils';
import { ChangeOrganizationNameUiService } from '@cp/web/app/organizations/change-organization-name-dialog/change-organization-name-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { ChangeOrganizationNameUiState } from '@cp/web/app/organizations/protocol/OrganizationStates';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-change-organization-name-dialog',
  templateUrl: './change-organization-name-dialog.component.html',
  styleUrls: ['./change-organization-name-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeOrganizationNameDialogComponent {
  changeOrganizationNameForm: FormGroup;
  uiStateObs: Observable<ChangeOrganizationNameUiState>;

  constructor(
    public dialogRef: MatDialogRef<ChangeOrganizationNameDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly uiStateService: ChangeOrganizationNameUiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly organizationService: OrganizationService,
    private readonly snackBar: MatSnackBar
  ) {
    this.uiStateObs = uiStateService.observeState();
    this.uiStateService.setPartialState({ buttonDisabled: false, errorMessage: undefined });
    const organization = organizationStateService.getCurrentOrgOrFail();
    this.changeOrganizationNameForm = this.formBuilder.group({
      name: [
        organization.name,
        [
          Validators.required,
          maxLengthTrimmedValidator(MAX_SIMPLE_NAME_LENGTH),
          minLengthTrimmedValidator(MIN_SIMPLE_NAME_LENGTH),
          organizationNameValidator()
        ]
      ]
    });
  }

  async onSubmit(): Promise<void> {
    if (this.changeOrganizationNameForm.invalid) {
      return;
    }
    this.uiStateService.setPartialState({ buttonDisabled: true, errorMessage: undefined });
    const name = this.changeOrganizationNameForm.value.name;
    try {
      const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
      await this.organizationService.changeOrganizationName(organizationId, name);
      this.snackBar.open('Organization Name Changed', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e) {
      const serverErrorMessage = getServerErrorMessage(e);
      if (serverErrorMessage === ORG_WITH_SAME_NAME_ALREADY_EXISTS) {
        this.uiStateService.setStateKey(
          'errorMessage',
          'Organization with same name already exists, please choose another'
        );
        return;
      }
      console.error(e);
      this.uiStateService.setStateKey('errorMessage', 'Unable to change organization name');
    } finally {
      this.uiStateService.setStateKey('buttonDisabled', false);
    }
  }
}

export function getChangeOrganizationNameDialogConfig(): MatDialogConfig {
  return {
    width: '100%',
    maxWidth: '517px',
    autoFocus: true,
    restoreFocus: false,
    panelClass: 'modal'
  };
}
