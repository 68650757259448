import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetUserDetailsResponse } from '@cp/common/protocol/Account';
import { assertTruthy } from '@cp/common/utils/Assert';
import { MAX_SIMPLE_NAME_LENGTH, MIN_SIMPLE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { UpdatePersonalInfoUiService } from '@cp/web/app/account/profile/update-personal-info/update-personal-info-ui.service';
import { UpdatePersonalInfoUiState } from '@cp/web/app/account/protocol/AccountStates';
import {
  maxLengthTrimmedValidator,
  minLengthTrimmedValidator,
  userNameValidator
} from '@cp/web/app/common/utils/FormValidationUtils';
import { Observable, takeUntil } from 'rxjs';

@Component({
  templateUrl: './update-personal-info.component.html',
  styleUrls: ['./update-personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatePersonalInfoComponent extends OnDestroyComponent {
  readonly updatePersonalInfoForm: FormGroup;
  updatePersonalInfoUiStateObs: Observable<UpdatePersonalInfoUiState>;
  readonly userDetails: GetUserDetailsResponse;

  constructor(
    public dialogRef: MatDialogRef<UpdatePersonalInfoComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly updatePersonalInfoUiService: UpdatePersonalInfoUiService,
    private readonly accountStateService: AccountStateService,
    private readonly accountService: AccountService,
    private readonly snackBar: MatSnackBar
  ) {
    super();
    this.updatePersonalInfoUiStateObs = updatePersonalInfoUiService.observeState().pipe(takeUntil(this.onDestroy));

    this.updatePersonalInfoUiService.setPartialState({
      updatePersonalInfoButtonDisabled: false,
      errorMessage: undefined
    });

    this.userDetails = this.accountStateService.getUserDetailsOrFail();
    this.updatePersonalInfoForm = this.formBuilder.group({
      name: [
        this.userDetails.name,
        [
          Validators.required,
          maxLengthTrimmedValidator(MAX_SIMPLE_NAME_LENGTH),
          minLengthTrimmedValidator(MIN_SIMPLE_NAME_LENGTH),
          userNameValidator()
        ]
      ]
    });
  }

  async onSubmit(): Promise<void> {
    assertTruthy(this.updatePersonalInfoForm);
    if (this.updatePersonalInfoForm.invalid) {
      return;
    }
    this.updatePersonalInfoUiService.setPartialState({
      updatePersonalInfoButtonDisabled: true,
      errorMessage: undefined
    });
    const details = this.updatePersonalInfoForm.value;
    try {
      await this.accountService.updateUserDetails({ name: details.name });
      this.snackBar.open('Personal Info Updated', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e: any) {
      console.error(e);
      this.updatePersonalInfoUiService.setStateKey('errorMessage', 'Unable to update personal info');
    } finally {
      this.updatePersonalInfoUiService.setStateKey('updatePersonalInfoButtonDisabled', false);
    }
  }
}
