import { Injectable } from '@angular/core';
import { StateService } from '@cp/common-services/state/state.service';
import { ResetInstancePasswordUiState } from '@cp/web/app/instances/protocol/InstanceStates';
import { distinctUntilChanged, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetInstancePasswordUiService {
  private readonly STATE_PATH = ['ui', 'resetInstancePassword'];

  constructor(private readonly stateService: StateService) {}

  observeResetInstancePasswordUiState(): Observable<ResetInstancePasswordUiState> {
    return this.stateService.observePath<ResetInstancePasswordUiState>(this.STATE_PATH).pipe(distinctUntilChanged());
  }

  setStateKey<K extends keyof ResetInstancePasswordUiState & string>(
    key: K,
    value: ResetInstancePasswordUiState[K]
  ): void {
    this.stateService.setKeyInPath<ResetInstancePasswordUiState>(this.STATE_PATH, key, value, true);
  }

  setPartialState(partialState: Partial<ResetInstancePasswordUiState>): void {
    this.stateService.setPartial<ResetInstancePasswordUiState>(this.STATE_PATH, partialState, true);
  }
}
