<ng-container *ngIf="allSupportCasesObs | async as allSupportCases">
  <div *ngIf="supportCasesObs | async as supportCases" class="support_page">
    <div class="side_padding">
      <div *ngIf="allSupportCases.length" class="nav">
        <div class="left">
          <div class="title">Support</div>
        </div>

        <div class="right">
          <div class="search_container">
            <div class="icon_wrapper">
              <mat-icon class="icon" svgIcon="search"></mat-icon>
            </div>
            <input (input)="searchInputChanged()" [(ngModel)]="searchText" class="seed_input search_input"
                   placeholder="Search support cases" type="text" spellcheck="false"/>
          </div>

          <button (click)="showCreateSupportCaseDialog()" class="button support_button" color="primary"
                  mat-flat-button>
            <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
            New case
          </button>
          <button (click)="chatWithSupport()" class="button chat_button" color="secondary" mat-stroked-button>
            <mat-icon class="icon" svgIcon="chat_stroked_icon"></mat-icon>
            Chat with us
          </button>
        </div>
      </div>

      <mat-button-toggle-group (change)="selectedStatusChanged($event.value)" *ngIf="allSupportCases.length"
                               class="status_toggle"
                               name="caseStatus"
                               value="all">
        <mat-button-toggle class="toggle" value="all">View
          all {{getCountByStatus('all', allSupportCases)}}</mat-button-toggle>
        <mat-button-toggle class="toggle" value="open">
          Open {{getCountByStatus('open', allSupportCases)}}</mat-button-toggle>
        <mat-button-toggle class="toggle" value="closed">
          Closed {{getCountByStatus('closed', allSupportCases)}}</mat-button-toggle>
      </mat-button-toggle-group>

      <div *ngIf="!supportCases.length" class="no_support_cases">
        <mat-icon class="inbox_icon" svgIcon="inbox"></mat-icon>
        <div class="title">No support cases</div>
        <div class="description">If you are experiencing issues and were unable to find answers in our
          <a class="link" href="https://clickhouse.com/docs" target="_blank">documentation</a>, open a case and our
          support staff will be happy to help.
        </div>

        <div class="support_button_group">
          <button (click)="showCreateSupportCaseDialog()" class="button support_button" color="primary"
                  mat-flat-button>
            <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
            New case
          </button>
          <button (click)="chatWithSupport()" class="button chat_button" color="secondary" mat-stroked-button>
            <mat-icon class="icon" svgIcon="chat_stroked_icon"></mat-icon>
            Chat with us
          </button>
        </div>
      </div>

      <div *ngIf="supportCases.length" class="support_cases">
        <div *ngIf="sortSubject | async as sort" class="header_row row">
          <div (click)="setSort(field.sortField, sort)" *ngFor="let field of headerFields"
               class="cell">
            {{field.label}}
            <mat-icon *ngIf="sort.field === field.sortField" [ngClass]="{ descending: !sort.ascending}"
                      class="sort_icon"
                      svgIcon="sort_arrow"></mat-icon>
          </div>
        </div>
        <div *ngFor="let supportCase of supportCases; trackBy: trackById" class="row">
          <div class="cell">{{supportCase.caseNumber}}</div>
          <div class="cell"><a [routerLink]="'/support/'+supportCase.id" class="link">{{supportCase.subject}}</a></div>
          <div class="cell">{{supportCaseStatusDisplay[supportCase.status].text}}</div>
          <div class="cell">{{supportCase.primaryClickHouseContactName}}</div>
          <div class="cell">{{supportCase.lastModifiedDate | approximateTime: 'medium'}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
