import { Injectable } from '@angular/core';
import { StateService } from '@cp/common-services/state/state.service';
import { CreateInstanceUiState } from '@cp/web/app/instances/protocol/InstanceStates';
import { distinctUntilChanged, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateInstanceUiService {
  private readonly STATE_PATH = ['ui', 'createInstance'];

  constructor(private readonly stateService: StateService) {}

  observeCreateInstanceUiState(): Observable<CreateInstanceUiState> {
    return this.stateService.observePath<CreateInstanceUiState>(this.STATE_PATH).pipe(distinctUntilChanged());
  }

  setStateKey<K extends keyof CreateInstanceUiState & string>(key: K, value: CreateInstanceUiState[K]): void {
    this.stateService.setKeyInPath<CreateInstanceUiState>(this.STATE_PATH, key, value, true);
  }

  setPartialState(partialState: Partial<CreateInstanceUiState>): void {
    this.stateService.setPartial<CreateInstanceUiState>(this.STATE_PATH, partialState, true);
  }
}
