import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'cw-auth-logo',
  templateUrl: './auth-logo.component.html',
  styleUrls: ['./auth-logo.component.scss'],
  imports: [CommonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthLogoComponent {
  @Input()
  color?: 'white' | 'primary' | 'black' | 'primary-light' | 'primary-dark';
  @Input()
  type?: 'full' | 'short';
  @Input()
  allowRedirection?: 'true' | 'false';
}
