import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Prevent authenticated users to visit the
 * /signin and /signup page.
 *
 * If an authenticated user tries to sign-in/sign-up with a different email
 * the sign-in/sign-up will silently fail, therefore, with this guard,
 * we prevent the user from reaching that state.
 */

@Injectable({
  providedIn: 'root'
})
export class SignInSignUpPageIfAuthenticatedGuard implements CanActivate {
  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.accountStateService
      .observeAuthenticated()
      .pipe(map((isAuthenticated) => (isAuthenticated ? this.router.parseUrl('/') : true)));
  }
}
