import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { convertMarkdown } from '@cp/web/app/common/utils/MarkdownUtils';

@Pipe({
  name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {
  constructor(protected sanitized: DomSanitizer) {}

  transform(value: string, addCopyButton?: boolean): SafeHtml {
    if (addCopyButton) {
      return this.sanitized.bypassSecurityTrustHtml(convertMarkdown(value, true));
    }
    return this.sanitized.bypassSecurityTrustHtml(convertMarkdown(value));
  }
}
