<div class="reply_container">
  <div class="seed_form_element">
    <label>Reply</label>
    <textarea
      maxlength="32000"
      [(ngModel)]="replyText"
      class="textarea seed_input"
      data-cy="case-reply-textarea"
      name="replyText">
    </textarea>
    <div *ngIf="replyText && !isValidSupportCaseReply" class="seed_error">
        The reply exceeds the maximum character limit of 32,000
    </div>
  </div>
  <div class="seed_form_element">
    <label>Attachments</label>
    <cp-upload (uploadFinished)="handleUploadFinished($event)"
            (uploadStarted)="handleUploadStarted()"
            [uploadType]="'support'"></cp-upload>
  </div>
  <button (click)="submitReply()"
          [disabled]="replyInProgress || uploadInProgress || !isValidSupportCaseReply"
          class="button"
          color="primary"
          mat-flat-button>
    <mat-icon *ngIf="replyInProgress" class="button_loader_icon icon svg_stroke"
              svgIcon="loader"></mat-icon>
    Reply
  </button>
</div>
