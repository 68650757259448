import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { SignInStorageService } from '@cp/web/app/account/sign-in/sign-in-storage.service';
import { AuthService } from '@cp/web/app/auth/auth.service';
import { UserMfaInputComponentData } from '@cp/web/app/common/components/user-mfa-input-page/user-mfa-input-page.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'cp-verify-totp',
  templateUrl: './verify-totp.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyTotpComponent {
  data: UserMfaInputComponentData = {
    title: 'Multi-factor authentication',
    description: 'Open you authentication app and enter the code for ClickHouse',
    fieldDescription: '6-digit code',
    inputValue: '',
    primaryActionLabel: 'Verify code',
    secondaryActionLabel: 'Cancel',
    helpText: "Can't access your authenticator app?",
    errorMessage: '',
    isVerifying: false,
    isPrimaryActionDisabled: true
  };

  constructor(
    private readonly accountService: AccountService,
    private readonly accountStateService: AccountStateService,
    private readonly authService: AuthService,
    private readonly signInStorageService: SignInStorageService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router
  ) {}

  async onSubmit() {
    this.signInStorageService.setSignInInProgress();
    try {
      this.updateData({ isVerifying: true });
      console.debug(`Confirming MFA sign in: ${this.data.inputValue}`);
      await this.accountService.confirmTotpSignIn(this.data.inputValue);
    } catch (e) {
      console.debug('Failed to confirm MFA sign in', e);
      this.updateData({ errorMessage: 'Incorrect code entered', isVerifying: false });
      this.cdr.markForCheck();
      return;
    }

    if (!this.accountService.hasOauth2FlowParamsInUrl()) {
      await firstValueFrom(this.accountStateService.waitForAuthenticatedState());
      await this.router.navigateByUrl('/');
    }
  }

  async onInputChange(token: string) {
    this.updateData({ inputValue: token, isPrimaryActionDisabled: token.length !== 6, errorMessage: '' });
  }

  onSecondaryAction() {
    this.router.navigate(['/signIn']).then();
  }

  private updateData(update: Partial<UserMfaInputComponentData>) {
    this.data = { ...this.data, ...update };
  }
}
