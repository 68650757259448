<div class="time_period_select_group">
  <div
    title="Every point in the chart is computed as an average value over the 'Aggregation period'.">
    <div class="aggregation_period_label">Aggregation period</div>
    <div class="aggregation_period_value" data-cy="aggregation-period-text">{{timePeriod|aggregationPeriod}}</div>
  </div>
  <div class="time_period_label">Time period</div>
  <mat-form-field appearance="outline" class="time_period_select" data-cy="time-period-select">
    <mat-select
      galaxyClick="servicePage.summary.timePeriodSelectorOpen"
      galaxyBlur="servicePage.summary.timePeriodSelectorBlur"
      galaxyChange="servicePage.summary.timePeriodSelectorSelect"
      (selectionChange)="onTimePeriodChanged($event.value)" [value]="timePeriod">
      <mat-option *ngFor="let period of availableTimePeriods"
                  [attr.data-cy-value]="period"
                  [attr.data-cy]="'time-period-option'"
                  [value]="period"
      >{{period | timePeriod}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="charts_grid" data-cy="metric-charts-grid">
  <cp-metric-chart-with-summary
    *ngFor="let metrics of metricsList; trackBy:trackByMetricType" [instanceId]="instanceId"
    [metric]="metrics"
    class="charts_grid_card">
  </cp-metric-chart-with-summary>

  <div class="charts_grid_card feedback_card">
    <cp-metric-chart-with-summary
      *ngIf="metricsList?.[0] as metric"
      [instanceId]="instanceId"
      [metric]="metric"
      class="feedback_card_chart_bg">
    </cp-metric-chart-with-summary>

    <div class="feedback_card_content">
      <div class="feedback_title">Can’t find what you’re looking for?</div>
      <div class="feedback_text">Checkout our advanced dashboard to view CPU Usage, queries per second, running queries,
        reads from disk and many others.
      </div>
      <div class="feedback_buttons_row">
        <button class="advanced_dashboard_button"
                galaxyClick="servicePage.summary.advancedDashboardLinkSelect"
                mat-flat-button color="primary">
          <a [href]="dashboardUrl"
             target="_blank"
             class="advanced_dashboard_link">
            Advanced dashboard
          </a>
        </button>
        <button class="request_metric_button"
                galaxyClick="servicePage.summary.newMetricButtonSelect"
                (click)="openMetricsFeedbackDialog()"
                data-cy="share-metric-feedback-button" mat-stroked-button>
          Request new metric
        </button>
      </div>
    </div>
  </div>

</div>
