import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InstanceMaintenanceWindow } from '@cp/common/protocol/Instance';

export interface MaintenanceWindowInfoMetadata extends InstanceMaintenanceWindow {
  instanceName: string;
}

@Component({
  templateUrl: './maintenance-window-info-dialog.component.html',
  styleUrls: ['./maintenance-window-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenanceWindowInfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MaintenanceWindowInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: { maintenanceWindows: Array<MaintenanceWindowInfoMetadata> }
  ) {}

  static show(dialog: MatDialog, maintenanceWindows: Array<MaintenanceWindowInfoMetadata>): void {
    dialog.open(MaintenanceWindowInfoDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'provisioning_modal',
      data: { maintenanceWindows }
    });
  }
}
