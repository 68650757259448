import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { assertTruthy } from '@cp/common/utils/Assert';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { PendingUserActionsService } from '@cp/web/app/common/services/pending-user-actions.service';
import { isTackleSubscriptionToken } from '@cp/web/app/common/services/tackle-subscription.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { firstValueFrom } from 'rxjs';

/**
 * This guard redirects to the service page if there are no tackle pending actions or if the organization role
 * is DEVELOPER.
 */
@Injectable({
  providedIn: 'root'
})
export class TackleFirmographicsGuardService implements CanActivate {
  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly accountService: AccountService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly pendingActionsService: PendingUserActionsService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    // Checks if the user has tackle pending actions and redirects to root if there are none
    const isAuthenticated = await this.accountStateService.isAuthenticated();
    const tacklePendingAction = isAuthenticated && this.pendingActionsService.getPendingAction('tackle-subscription');
    if (!tacklePendingAction) {
      return this.router.parseUrl('/');
    }

    try {
      assertTruthy(
        tacklePendingAction.data,
        `Can't activate Tackle firmographics - Tackle pending action must have data field`
      );
      const tackleToken = JSON.parse(tacklePendingAction.data);
      if (!isTackleSubscriptionToken(tackleToken)) {
        // User used a bad tackle token.
        await this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['tackle-subscription'] });
        return this.router.parseUrl('/');
      }
      const myOrgs = await firstValueFrom(this.organizationStateService.observeOrganizations());
      if (
        Object.values(myOrgs).some(
          (org) =>
            org.paymentState === 'TACKLE' &&
            org.tackleState?.customerId === tackleToken.customerId &&
            org.tackleState?.productId === tackleToken.productId &&
            !org.tackleState?.clickhouseCancellationDate
        )
      ) {
        // User already processed this AWS subscription - clear pending action and proceed silently to services page.
        await this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['tackle-subscription'] });
        return this.router.parseUrl('/');
      }
    } catch (e) {
      this.snackBar.open(
        'Encountered an error processing your AWS Marketplace subscription - please contact support.',
        'Dismiss',
        { duration: 5000 }
      );
      await this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['tackle-subscription'] });
      return this.router.parseUrl('/');
    }
    return true;
  }
}
