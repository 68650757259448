import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { assertTruthy } from '@cp/common/utils/Assert';
import { downloadText } from '@cp/cp-common-web/DownloadUtils';
import { FullyQualifiedEvent, GalaxyService } from '@cp/web/app/common/services/galaxy.service';
import copy from 'copy-to-clipboard';

@Component({
  selector: 'cp-username-and-password',
  templateUrl: './username-and-password.component.html',
  styleUrls: ['./username-and-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsernameAndPasswordComponent implements OnInit, OnChanges {
  showPassword: boolean = false;

  @Input() username!: string;

  @Input() password!: string;

  @Input() sideBarColor = '';

  @Input() onUserNameCopyEvent?: FullyQualifiedEvent;

  @Input() onPasswordCopyEvent?: FullyQualifiedEvent;

  @Input() onDownLoadCredentialsEvent?: FullyQualifiedEvent;

  @Input() usernameLabel = 'Username';

  @Input() passwordLabel = 'Password';

  @Input() credentialsFileName = 'clickhouse_credentials.txt';

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly galaxyService: GalaxyService
  ) {}

  ngOnInit(): void {
    assertTruthy(this.username);
    assertTruthy(this.password);
  }

  ngOnChanges(): void {
    assertTruthy(this.username);
    assertTruthy(this.password);
  }

  copyUserNameToClipboard(text: string) {
    if (this.onUserNameCopyEvent) {
      this.galaxyService.track({ event: this.onUserNameCopyEvent, interaction: 'click' }).then();
    }
    this.copyToClipboard(text);
  }

  copyPasswordToClipboard(text: string) {
    if (this.onPasswordCopyEvent) {
      this.galaxyService.track({ event: this.onPasswordCopyEvent, interaction: 'click' }).then();
    }
    this.copyToClipboard(text);
  }

  copyToClipboard(text: string): void {
    this.snackBar.open('Copied to clipboard', 'Dismiss', { duration: 5000 });
    copy(text);
  }

  downloadCredentials(): void {
    if (this.onDownLoadCredentialsEvent) {
      this.galaxyService.track({ event: this.onDownLoadCredentialsEvent, interaction: 'click' }).then();
    }
    const text = `${this.usernameLabel}: ${this.username}\n${this.passwordLabel}: ${this.password}`;
    downloadText(text, this.credentialsFileName);
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }
}
