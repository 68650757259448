import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { State } from '@cp/common-services/state/action.applier';
import { StateService } from '@cp/common-services/state/state.service';
import { MetricArgs, MetricReport, TimePeriod } from '@cp/common/protocol/Metrics';
import { distinctUntilChanged, Observable } from 'rxjs';

interface InstanceMetricState extends State {
  query: MetricArgs;
  report: MetricReport;
}

@Injectable({
  providedIn: 'root'
})
export class InstanceMetricsStateService extends BaseUiStateService<InstanceMetricState> {
  constructor(stateService: StateService) {
    super(['metrics', 'instance'], stateService);
  }

  /** Sets current metric data for instance/time-period. Resets error flag. */
  setInstanceMetric(instanceId: string, state: InstanceMetricState): void {
    const queryKey = buildKeyFromQuery(state.query);
    const queryErrorKey = buildErrorKeyFromQuery(state.query);
    this.stateService.setInPath([...this.STATE_PATH, instanceId, ...queryKey], state);
    this.stateService.deletePath([...this.STATE_PATH, instanceId, ...queryErrorKey]);
  }

  /** Sets error state for the metric instance. Does not reset the last reported data. */
  setInstanceMetricError(instanceId: string, query: MetricArgs, errorMessage: string): void {
    const queryErrorKey = buildErrorKeyFromQuery(query);
    this.stateService.setInPath([...this.STATE_PATH, instanceId, ...queryErrorKey], errorMessage);
  }

  observeInstanceMetric(instanceId: string, query: MetricArgs): Observable<InstanceMetricState | undefined> {
    const queryKey = buildKeyFromQuery(query);
    return this.stateService.observePath<InstanceMetricState>([...this.STATE_PATH, instanceId, ...queryKey]);
  }

  observeInstanceMetricError(instanceId: string, query: MetricArgs): Observable<string | undefined> {
    const queryErrorKey = buildErrorKeyFromQuery(query);
    return this.stateService
      .observePath<string | undefined>([...this.STATE_PATH, instanceId, ...queryErrorKey])
      .pipe(distinctUntilChanged());
  }

  /** Returns current state of the metric: may be undefined (never successfully fetched). */
  getInstanceMetricSnapshot(instanceId: string, query: MetricArgs): InstanceMetricState | undefined {
    const queryKey = buildKeyFromQuery(query);
    return this.stateService.getStateInPath<InstanceMetricState>([...this.STATE_PATH, instanceId, ...queryKey]);
  }

  clear(): void {
    this.stateService.deletePath([...this.STATE_PATH]);
  }

  clearInstanceMetricsForTimePeriod(instanceId: string, timePeriod: TimePeriod): void {
    this.stateService.deletePath([...this.STATE_PATH, instanceId, timePeriod]);
  }
}

/** Builds serializable unique query key per query type and query time period. */
function buildKeyFromQuery(query: MetricArgs): string[] {
  return [query.timePeriod, query.type];
}

/** Builds serializable unique query key per query type and query time period. */
function buildErrorKeyFromQuery(query: MetricArgs): string[] {
  return [query.timePeriod, query.type, 'error'];
}
