<div class="integration-summary-container side_padding" *ngIf="integrationData | async as integration">
  <div class="inner_page_nav">
    <div class="inner_page_title" data-cy="page-title">Integrations</div>
  </div>
  <div class="logo_container">
    <div class="logo_wrapper">
      <div class="logo" [style.background-image]="'url(' + integration.logo.url + ')'"></div>
    </div>
    <div class="summary_container">
      <h2 class="summary_title">
        {{integration.name}}
      </h2>
      <span *ngIf="integration.readiness" class="readiness">
        {{getReadinessText(integration.readiness)}}
      </span>
      <p class="summary_description">{{integration.shortDescription}}</p>
    </div>
    <div class="summary_details">
      <p *ngIf="integration.website" class="integration_detail__value">
        <a class="integration_website_link link" [href]="integration.website" target="_blank">
          <p class="integration_website_text">Website</p><mat-icon class="launch_icon">launch</mat-icon>
        </a>
      </p>
      <p class="integration_detail__value">
        {{getSupportLevelText(integration.supportLevel)}}
      </p>
      <p *ngIf="integration.version" class="integration_detail__value">
        {{integration.version}}
      </p>
      <p *ngIf="integration.license" class="integration_detail__value">
        {{getLicenseLevelText(integration.license)}}
      </p>
    </div>
  </div>
  <div class="inner_page_body">
    <div class="integration_summary_body">
      <div class="integration-summary__selector">
        <p
          class="integration-summary__tab"
          galaxyClick="integrationPage.summary.howToConnectSelect"
          [ngClass]="{selected: selectedTab === 'how_to_connect'}"
          (click)="switchTab('how_to_connect')"
        >
          How to connect
        </p>
        <p
          *ngIf="integration.changelog"
          class="integration-summary__tab"
          [ngClass]="{selected: selectedTab === 'changelog'}"
          galaxyClick="integrationPage.summary.changelogSelect"
          (click)="switchTab('changelog')"
        >
          Changelog
        </p>
        <p
          *ngIf="integration.about"
          class="integration-summary__tab"
          [ngClass]="{selected: selectedTab === 'about'}"
          galaxyClick="integrationPage.summary.aboutSelect"
          (click)="switchTab('about')"
        >
          About
        </p>
        <a
          class="integration-summary__tab link"
          galaxyClick="integrationPage.summary.fullDocumentationSelect"
          [href]="integration.docsLink"
          target="_blank"
          (click)="onFullDocumentationClicked()">
          <p class="full_doc_text">Full documentation</p><mat-icon class="launch_icon">launch</mat-icon>
        </a>
      </div>
      <div [ngSwitch]="selectedTab">
        <div *ngSwitchCase="'changelog'">
          <div *ngIf="integration.changelog"
            [innerHTML]="integration.changelog | markdown:true"
            class="integration_summary_content changelog">
          </div>
        </div>
        <div *ngSwitchCase="'about'">
          <div *ngIf="integration.about" [innerHTML]="integration.about | markdown:true" class="integration_summary_content"></div>
        </div>
        <div *ngSwitchDefault>
          <div *ngIf="integration.summary" [innerHTML]="integration.summary | markdown:true" class="integration_summary_content summary"></div>
          <a [href]="integration.docsLink"
            target="_blank"
            class="go_to_doc_button"
            data-cy="go-to-doc-button"
            mat-flat-button
          >
            Read the full documentation
          </a>
        </div>
      </div>
    </div>
    <div class="integration-summary-sidebar">
      <p class="sidebar__title">Details</p>
      <div class="integration_details">
        <div *ngIf="integration.website" class="integration_detail">
          <div class="integration_detail__title">Provider</div>
          <p class="integration_detail__value">
            <a class="integration_website_link link" [href]="integration.website" target="_blank">
              <p class="integration_website_text">Website</p><mat-icon class="launch_icon">launch</mat-icon>
            </a>
          </p>
        </div>
        <div class="integration_detail">
          <div class="integration_detail__title">
            Support level
            <mat-icon
            svgIcon="info_circle"
            class="info_icon"
            matTooltipClass="column_info_icon_tooltip"
            [matTooltip]="getSupportLevelDescription(integration.supportLevel)"
          ></mat-icon>
          </div>
          <p class="integration_detail__value">
            {{getSupportLevelText(integration.supportLevel)}}
          </p>
        </div>
        <div *ngIf="integration.version" class="integration_detail">
          <div class="integration_detail__title">Version</div>
          <p class="integration_detail__value">
            {{integration.version}}
          </p>
        </div>
        <div *ngIf="integration.license" class="integration_detail">
          <div class="integration_detail__title">License</div>
          <p class="integration_detail__value">
            {{getLicenseLevelText(integration.license)}}
          </p>
        </div>
      </div>
      <cp-relevant-content></cp-relevant-content>
    </div>
  </div>
</div>
