import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'approximateTime',
  pure: true
})
export class ApproximateTimePipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe) {}

  transform(timestamp: number | string, format?: string): any {
    if (typeof timestamp === 'string') {
      timestamp = new Date(timestamp).getTime();
    }
    if (!timestamp) {
      return undefined;
    }
    let seconds = Math.floor((Date.now() - new Date(timestamp).getTime()) / 1000);
    const isInFuture = seconds < 0;
    seconds = Math.abs(seconds);
    if (seconds < 10) {
      return isInFuture ? 'In a few seconds' : 'Few seconds ago';
    }

    const intervals: Record<string, number> = {
      hour: 3600,
      minute: 60,
      second: 1
    };

    const dayInSeconds = 86400;
    if (seconds > dayInSeconds) {
      return this.datePipe.transform(timestamp, format || 'medium');
    }

    let counter;
    for (const i in intervals) {
      counter = Math.floor(seconds / intervals[i]);
      if (counter > 0) {
        const pluralSuffix = counter === 1 ? '' : 's';
        return isInFuture ? `in ${counter} ${i}${pluralSuffix}` : `${counter} ${i}${pluralSuffix} ago`;
      }
    }
  }
}
