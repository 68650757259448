<table mat-table [dataSource]="keys" class="keys_table" data-cy="keys-table">
  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef>API key</th>
    <td mat-cell *matCellDef="let key; typedBy: keys" class="id_cell" data-cy="key-id-cell">
      <div class="key_id fs-exclude">{{key.name}}</div>
      <div class="muted_details_text fs-exclude">*********{{key.keySuffix}}</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="permissions">
    <th mat-header-cell *matHeaderCellDef>
      Organization permissions
      <mat-icon svgIcon="info_circle"
                class="permissions_info_icon"
                [matTooltip]="apiKeyOrgPermissionsTooltipText"
      ></mat-icon>
    </th>
    <td mat-cell *matCellDef="let key; typedBy: keys" class="roles_cell" data-cy="key-roles-cell">
      {{key.roles.map(formatOrgRole).join(', ')}}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let key; typedBy: keys" class="status_cell" data-cy="status-cell">
      <div class="status"><span class="badge" [ngClass]="getStatusBadgeClass(key)">{{formatStatus(key)}}</span></div>
      <div class="muted_details_text fs-exclude">{{formatLastUsedDate(key)}}</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="expiration">
    <th mat-header-cell *matHeaderCellDef>Expires</th>
    <td mat-cell *matCellDef="let key; typedBy: keys" class="expiration_cell fs-exclude" data-cy="expiration-cell">
      <span [ngClass]="isExpired(key) ? 'text_error' : null">{{formatExpirationDate(key)}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="creation">
    <th mat-header-cell *matHeaderCellDef>Created by</th>
    <td mat-cell *matCellDef="let key; typedBy: keys" class="creation_cell" data-cy="creation-cell">
      <div class="fs-exclude">{{key.createdByDetails}}</div>
      <div class="muted_details_text">{{formatCreationDate(key)}}</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let key; typedBy: keys" class="actions_cell" data-cy="actions-cell">
      <button [matMenuTriggerData]="{key}"
              [matMenuTriggerFor]="keyActionsMenu"
              class="menu_button"
              data-cy="key-actions-menu-button"
              mat-button>
        <mat-icon class="icon svg_fill">more_vert</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let key; columns: displayedColumns;"
      [attr.data-cy-key]="key.keyId"
      data-cy='keys-table-row'
  ></tr>
</table>

<mat-menu #keyActionsMenu="matMenu" data-cy="key-actions-menu" class="actions_menu" xPosition="before">
  <ng-template let-key="key" matMenuContent>
    <button mat-menu-item
            (click)="editKey.emit(key)"
            [disabled]="!canEdit(key)"
            class="menu_item"
            data-cy="edit-key"
    >Edit
    </button>

    <button mat-menu-item
            (click)="toggleKey.emit(key)"
            [disabled]="!canToggle(key)"
            class="menu_item"
            data-cy="toggle-key-menu-item"
    >{{key.state === 'enabled' ? 'Disable API key' : 'Enable API key'}}
    </button>

    <button mat-menu-item
            (click)="deleteKey.emit(key)"
            [disabled]="!canDelete(key)"
            class="text_danger menu_item"
            data-cy="delete-key-menu-item"
    >Delete API key
    </button>
  </ng-template>
</mat-menu>
