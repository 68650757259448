<mat-dialog-content class="request_credits_dialog">
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title data-cy="request-credits-dialog-title" dialogTitle="Request more credits"></cp-dialog-title>
  <div class="text">
    Please indicate how many credits you’d like to request and our sales team will contact you shortly.
  </div>
  <div class="textarea_title">Credit request</div>

  <div class="seed_form_element">
        <textarea [(ngModel)]="requestCreditsText"
                  class="seed_input textarea"
                  data-cy="request-credits-textarea"
                  galaxyBlur="billing.modal.creditsRequestInputBlur"
                  galaxyClick="billing.modal.creditsRequestInputFocus"
                  placeholder="Tell us more about your use case and how you might use your additional credits"
        ></textarea>
  </div>


  <div class="buttons">
    <button
      (click)="submitForm()"
      [disabled]="!isValidCreditsDescription || creditsRequestButtonDisabled"
      class="submit-button"
      color="primary"
      data-cy="submit-request-credits-button"
      galaxyClick="billing.modal.creditsRequestButtonSelect"
      mat-flat-button
      type="submit"
    >Request credits
    </button>
    <button
      (click)="dialogRef.close()"
      class="close_button"
      data-cy="close-request-credits-dialog-button"
      galaxyClick="billing.modal.closeButtonSelect" mat-button
      type="button"
    >Close
    </button>
  </div>
</mat-dialog-content>
