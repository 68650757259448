import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { UserFeatureId } from '@cp/common/protocol/features';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { trackByName } from '@cp/web/app/common/utils/AngularUtils';
import { checkIfPathBelongsToPage } from '@cp/web/app/organizations/current-organization.helper';
import { takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cp-internal-sidebar',
  templateUrl: './internal-sidebar.component.html',
  styleUrls: ['./internal-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalSidebarComponent extends OnDestroyComponent {
  @Input()
  title?: string;

  @Input()
  titleIcon?: string;

  @Input()
  menuButtons: Array<InternalSidebarButton> = [];

  readonly trackByName = trackByName;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        this.cd.markForCheck();
      });
  }

  isMenuButtonActive(menuButton: InternalSidebarButton): boolean {
    const url = this.router.url;
    const linkTokens = menuButton.link.split('/');
    const prefix = `${linkTokens[1]}s`; // We use plural form for pages with id: organization->organizations.
    let suffix = linkTokens[2];
    for (let i = 3; linkTokens[i]; i++) {
      suffix += `/${linkTokens[i]}`;
    }
    return checkIfPathBelongsToPage(url, prefix, suffix, menuButton.link);
  }
}

export interface InternalSidebarButton {
  id: string;
  name: string;
  icon?: string;
  link: string;
  experiment?: UserFeatureId;
  minRole?: OrganizationRole;
}
