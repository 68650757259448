import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsInteraction } from '@cp/common/protocol/Galaxy';
import {
  GalaxyService,
  GalaxyTrackerData,
  GalaxyTrackerEventObj,
  getTracker
} from '@cp/web/app/common/services/galaxy.service';

@Directive({
  selector: '[galaxyBlur]'
})
export class GalaxyTrackBlurDirective {
  @Input('galaxyBlur') data!: GalaxyTrackerData;

  private track!: (interaction: AnalyticsInteraction, data: GalaxyTrackerEventObj) => Promise<void>;

  constructor(private readonly galaxyService: GalaxyService) {
    this.track = getTracker(this.galaxyService);
  }

  getEvent() {
    return typeof this.data === 'string' ? this.data : this.data[0];
  }

  getProperties() {
    return (typeof this.data === 'string' ? {} : this.data[1]) || {};
  }

  getDataObj() {
    return {
      event: this.getEvent(),
      properties: this.getProperties()
    };
  }

  @HostListener('blur')
  onClick() {
    if (!this.isContextMenuClosed()) {
      this.track('click', this.getDataObj());
    }
  }

  isContextMenuClosed(): boolean {
    return this.getEvent().endsWith('ContextMenuBlur');
  }

  @HostListener('menuClosed')
  onClose() {
    if (this.isContextMenuClosed()) {
      this.track('click', this.getDataObj());
    }
  }
}
