import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetUserDetailsResponse } from '@cp/common/protocol/Account';
import { isDefined } from '@cp/common/protocol/Common';
import { Organization, OrganizationRole, OrganizationUser } from '@cp/common/protocol/Organization';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { ConfirmationDialogComponent } from '@cp/web/app/common/components/confirmation-dialog/confirmation-dialog.component';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import {
  ChangeOrganizationNameDialogComponent,
  getChangeOrganizationNameDialogConfig
} from '@cp/web/app/organizations/change-organization-name-dialog/change-organization-name-dialog.component';
import {
  CreateOrganizationDialogComponent,
  getCreateOrganizationDialogConfig
} from '@cp/web/app/organizations/create-organization/create-organization-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { Observable, switchMap, take } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'cp-admin-general',
  templateUrl: './admin-general.component.html',
  styleUrls: ['./admin-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminGeneralComponent {
  readonly userObs: Observable<GetUserDetailsResponse | null>;
  readonly organizationObs: Observable<Organization>;
  readonly numberOfAdminsObs: Observable<number>;
  readonly myOrgUserObs: Observable<OrganizationUser>;
  hideOrgId = true;

  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly organizationService: OrganizationService,
    private readonly instanceStateService: InstanceStateService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    this.userObs = this.accountStateService.observeUserDetails();
    this.organizationObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeOrganization(id))
    );
    this.numberOfAdminsObs = this.organizationObs.pipe(
      filter(isDefined),
      map((organization) => Object.values(organization.users).filter((u) => u.role === 'ADMIN').length)
    );
    this.myOrgUserObs = this.organizationStateService.observeCurrentOrganizationUser();
  }

  confirmLeaveOrganization(): void {
    const organization = this.organizationStateService.getCurrentOrgOrFail();
    const orgName = organization.name;
    const dialogRef = ConfirmationDialogComponent.show(this.dialog, {
      title: `Leave ${orgName}`,
      text: `Are you sure you want to leave '${orgName}'?`,
      action: `Leave`
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (shouldLeave) => {
        if (shouldLeave) {
          await this.organizationService.leaveOrganization(organization.id);
          this.snackBar.open(`Left organization '${organization.name}'`, 'Dismiss', { duration: 5000 });
        }
      });
  }

  confirmDeleteOrganization(): void {
    const numberOfMyOrganizations = Object.keys(this.organizationStateService.getOrganizations() || {}).length;
    if (numberOfMyOrganizations === 1) {
      this.snackBar.open(`Please create a new organization before deleting your only organization`, 'Dismiss', {
        duration: 5000
      });
      return;
    }
    const numberOfInstances = Object.keys(this.instanceStateService.listInstances()).length;
    if (numberOfInstances > 0) {
      this.snackBar.open(`Please terminate all services before deleting an organization`, 'Dismiss', {
        duration: 5000
      });
      return;
    }

    const organization = this.organizationStateService.getCurrentOrgOrFail();
    const orgName = organization.name;
    const dialogRef = ConfirmationDialogComponent.show(this.dialog, {
      title: `Delete ${orgName}`,
      text: `Are you sure you want to delete '${orgName}'?`,
      action: `Delete`
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (shouldDelete) => {
        if (shouldDelete) {
          await this.organizationService.deleteOrganization(organization.id);
          this.snackBar.open(`Deleted '${organization.name}'`, 'Dismiss', { duration: 5000 });
        }
      });
  }

  showChangeOrganizationNameDialog(): void {
    this.dialog.open(ChangeOrganizationNameDialogComponent, getChangeOrganizationNameDialogConfig());
  }

  showCreateOrganizationDialog(): void {
    this.dialog.open(CreateOrganizationDialogComponent, getCreateOrganizationDialogConfig());
  }

  getOrgId(hideOrgId: boolean, organizationId: string): string {
    return hideOrgId ? `${organizationId.slice(0, -7).replace(/./g, '*')}${organizationId.slice(-7)}` : organizationId;
  }

  checkIfActionsButtonVisible(numberOfAdmins: number, role: OrganizationRole): boolean {
    return (
      this.checkIfDeleteOrganizationButtonVisible(role) ||
      this.checkIfLeaveOrganizationButtonVisible(numberOfAdmins, role)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkIfDeleteOrganizationButtonVisible(role: OrganizationRole): boolean {
    // Temporarily disabling this button
    return false;
    //TODO: Rethink this condition because the user has to be an admin to see this page.
    // return role === 'ADMIN';
  }

  checkIfLeaveOrganizationButtonVisible(numberOfAdmins: number, role: OrganizationRole) {
    //TODO: Rethink this condition because the user has to be an admin to see this page.
    return numberOfAdmins > 1 || role !== 'ADMIN';
  }
}
