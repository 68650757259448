<ng-container *ngIf="myOrgRoleObs | async as myOrgRole">
  <div class="instance_card_wrapper">
    <div
      class="instance_card"
      [galaxyClick]="['serviceList.serviceCard.serviceSelect', {serviceId: instance.id}]"
      [ngClass]="{ disabled: instance.state === 'failed'}"
      *ngIf="regionMap[instance.regionId] as region"
      [routerLink]="instance.state !== 'failed' ? detailsPagePath : undefined">
      <div class="card_header">
        <div class="left">
          <div [title]="instance.name" class="name" data-cy="service-name">{{instance.name}}</div>
          <div class="version">
            {{formatCHVersion(instance.clickhouseVersion)}}
          </div>
          <div *ngVar="!isDedicated ? instance.instanceTier : 'Dedicated' as tierTag" data-cy="dev-instance-chip"
               class="dev_instance_chip">
            {{tierTag}}
          </div>
        </div>
        <div (click)="$event.stopPropagation()" class="right">
          <span
            *ngIf="instance.customerManagedEncryption"
            class="customer_key_badge"
            [matTooltip]="customerKeyTooltip"
            matTooltipPosition="above">
            <mat-icon class="customer_key_icon" svgIcon="customer_key"></mat-icon>
          </span>
          <button
            [galaxyClick]="['serviceList.serviceCard.serviceContextMenuOpen', {serviceId: instance.id}]"
            [galaxyBlur]="['serviceList.serviceCard.serviceContextMenuBlur', {serviceId: instance.id}]"
            [matMenuTriggerFor]="instanceMenu"
            class="menu_button"
            data-cy="service-menu-button"
            mat-button>
            <mat-icon class="icon svg_fill">more_vert</mat-icon>
          </button>
        </div>
      </div>
      <div class="card_body" [ngClass]="{development: instance.instanceTier === 'Development'}">
        <div class="metadata_container">
          <div class="metadata_row state_row">
            <cp-instance-state [additionalClass]="'normal_weight'" [kind]="'ICON_AND_LABEL'"
                               [state]="instance.state"></cp-instance-state>
            <div *ngIf="!checkHasNoIpAccess(instance)">
              <cw-badge *ngIf="!isMaintenanceInProgress && isMaintenanceScheduled" type="info"
                        data-cy="maintenance-scheduled-badge">
                Maintenance scheduled
              </cw-badge>
              <cw-badge *ngIf="isMaintenanceInProgress" type="info" data-cy="maintenance-in-progress-badge">
                Maintenance in progress
              </cw-badge>
            </div>
            <cp-instance-no-access-prompt [instanceId]="instance.id"></cp-instance-no-access-prompt>
          </div>
          <div *ngIf="checkHasNoIpAccess(instance)">
            <cw-badge *ngIf="!isMaintenanceInProgress && isMaintenanceScheduled" type="info"
                      data-cy="maintenance-scheduled-badge">
              Maintenance scheduled
            </cw-badge>
            <cw-badge *ngIf="isMaintenanceInProgress" type="info" data-cy="maintenance-in-progress-badge">
              Maintenance in progress
            </cw-badge>
          </div>
          <div class="metadata_row" *ngVar="region.cloudProvider as cloudProvider">
            <mat-icon [svgIcon]="cloudProvider"
                      class="icon"
                      data-cy="cloud-provider-icon"></mat-icon>
            <span class="region" data-cy="region">{{region.name}} ({{region.displayId}})</span>
          </div>
        </div>
        <cp-connect-console-button
          *ngIf="instance.activeMaintenanceKind !== 'fullMaintenance'"
          [instance]="instance"
          eventPrefix="serviceList.serviceCard"
          color="white">
        </cp-connect-console-button>
      </div>

      <mat-menu #instanceMenu="matMenu" class="instance_menu" xPosition="before">
        <div class="menu_buttons">
          <ng-container *ngVar="(myOrgRole === 'ADMIN') as isAdmin">
            <a [routerLink]="detailsPagePath"
               [galaxyClick]="['serviceList.serviceCard.serviceContextMenuServiceOpen', {serviceId: instance.id}]"
               class="menu_button" mat-menu-item
               *ngIf="instance.state !== 'failed'">Details</a>

            <!-- Show a separator only if there are state actions below. -->
            <mat-divider *ngIf="!['terminating', 'failed'].includes(instance.state) && isAdmin"></mat-divider>

            <button
              (click)="startInstance()"
              [galaxyClick]="['serviceList.serviceCard.serviceContextMenuServiceStart', {serviceId: instance.id}]"
              *ngIf="STATES_VALID_TO_START.includes(instance.state) && isAdmin"
              class="menu_button" data-cy="menu-item-start"
              mat-menu-item
            >Start
            </button>

            <button
              (click)="confirmStopInstance(instance)"
              [galaxyClick]="['serviceList.serviceCard.serviceContextMenuServiceStop', {serviceId: instance.id}]"
              *ngIf="STATES_VALID_TO_STOP.includes(instance.state) && isAdmin"
              class="menu_button" data-cy="menu-item-stop"
              mat-menu-item
            >Stop
            </button>

            <div
              *ngIf="instance.instanceTier === 'Development' && myOrgRole === 'ADMIN'"
              [matTooltipDisabled]="(isInstanceUpgradeDisabledObs | async)"
              [matTooltip]="'Please contact support to enable this feature'"
              [matTooltipPosition]="'left'">
              <button
                (click)="onUpgrade()"
                [galaxyClick]="['serviceList.serviceCard.serviceContextMenuServiceUpgrade', {serviceId: instance.id}]"
                [disabled]="(isInstanceUpgradeDisabledObs | async) === false"
                class="menu_button"
                data-cy="menu-item-upgrade"
                mat-menu-item
              >Upgrade
              </button>
            </div>

            <button
              (click)="showDeleteInstanceDialog()"
              [galaxyClick]="['serviceList.serviceCard.serviceContextMenuServiceDelete', {serviceId: instance.id}]"
              *ngIf="STATES_VALID_TO_TERMINATE.includes(instance.state) && isAdmin" class="delete menu_button"
              data-cy="menu-item-delete"
              mat-menu-item
            >Delete
            </button>

          </ng-container>
        </div>
      </mat-menu>
    </div>
    <div *ngIf="shouldResetPassword" class="actions_wall_wrapper">
      <div class="actions_wall_container">
        <button
          *ngIf="STATES_VALID_FOR_PASSWORD_RESET.includes(instance.state)"
          color="primary"
          mat-flat-button
          data-cy="instance-card-password-setup-button"
          (click)="onPasswordSetup()"
        >
          Setup Password
        </button>
      </div>
    </div>
  </div>
</ng-container>
