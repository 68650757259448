export function capitalize(str: string): string {
  const split = str.trim().split(' ');
  const resultArray = [];
  for (const word of split) {
    resultArray.push(word[0].toUpperCase() + word.slice(1).toLowerCase());
  }
  return resultArray.join(' ');
}

export function encodeHTML(str: string): string {
  return str.replace(/([\u00A0-\u9999<>&])(.|$)/g, function (full, char, next) {
    if (char !== '&' || next !== '#') {
      if (/[\u00A0-\u9999<>&]/.test(next)) next = '&#' + next.charCodeAt(0) + ';';

      return '&#' + char.charCodeAt(0) + ';' + next;
    }

    return full;
  });
}
