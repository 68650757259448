/** Tracks by string/numeric 'userId' property helper for Angular *ngFor. */
export function trackByUserId(index: number, item: { userId: string | number }): string {
  return `${item.userId}`;
}

/** Tracks by string 'name' property helper for Angular *ngFor. */
export function trackByName(index: number, item: { name: string }): string {
  return `${item.name}`;
}

/** Tracks by string 'title' property helper for Angular *ngFor. */
export function trackByTitle(index: number, item: { title: string }): string {
  return `${item.title}`;
}

/** Tracks by string 'title' property helper for Angular *ngFor. */
export function trackByValue(index: number, item: { value: string }): string {
  return item.value;
}

/** Tracks by string/numeric 'type' property helper for Angular *ngFor. */
export function trackByType(index: number, item: { type: string | number }): string {
  return `${item.type}`;
}

/** Tracks by string 'instanceId' property helper for Angular *ngFor. */
export function trackByInstanceId(index: number, item: { instanceId: string }): string {
  return item.instanceId;
}

/** CSS object abstraction TypeScript. */
export type CssStyle = Record<string, string | number>;

/** See nextUniqueCounterValue(). */
let counterValue = 0;

/**
 * Returns Angular application level unique numeric counter value.
 * May be used for DOM element ids generation with a custom prefix/suffix.
 */
export function nextUniqueCounterValue(): number {
  counterValue++;
  return counterValue;
}

/** Returns true if the app currently runs as a part of the E2E test suite. */
export function isTestRun(): boolean {
  return Boolean((window as any).Cypress);
}

/** Creates an iterator of serial numbers from 0 to max. */
export function times(max: number): { [Symbol.iterator]: () => Generator<number> } {
  return {
    [Symbol.iterator]: function* () {
      for (let i = 0; i < max; i++) {
        yield i;
      }
    }
  };
}
