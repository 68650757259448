import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import {
  CommonOptionalRequestProps,
  WithBatch,
  WithInstanceId,
  WithOrganizationId
} from '@cp/common/utils/ProtocolUtils';
import { Observable, timer } from 'rxjs';
import { map, mergeMap, share } from 'rxjs/operators';

/**
 * Merges multiple requests with the same batch metadata into a single request.
 * Only requests with an Array.isArray(body.batch) === true can be merged into batches.
 *
 * The requests are merged by a group key = url + orgId + instanceId + 'Authorization' header:
 * merged requests have the same values for these fields.
 *
 * The 'response.body' of the batch request must also be WithBatchSupport (has a 'batch' array).
 */
@Injectable()
export class BatchRequestOptimizerInterceptor implements HttpInterceptor {
  readonly pendingBatchRequests = new Map<string, JoinedHttpBatchRequest>();

  /** Time in milliseconds the batch request will wait for new requests to merge before the real run. */
  batchWaitTimeInMillis = 10;

  intercept(req: HttpRequest<CommonOptionalRequestProps>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!isBatchRequest(req)) {
      return next.handle(req);
    }
    const newBatchRequest = req as HttpBatchRequest;
    const { organizationId, instanceId } = req.body;
    const batchKey = `${req.url}/${organizationId || '-'}/${instanceId || '-'}/${
      req.headers.get('Authorization') || '-'
    }`;
    const pendingBatchRequest = this.pendingBatchRequests.get(batchKey);
    return !pendingBatchRequest
      ? this.startBatchRequest(batchKey, newBatchRequest, next)
      : this.addToBatchRequest(newBatchRequest, pendingBatchRequest);
  }

  private startBatchRequest(
    batchKey: string,
    req: HttpBatchRequest,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const response = timer(this.batchWaitTimeInMillis).pipe(
      mergeMap(() => {
        // Delete from the pending list right before the real run.
        this.pendingBatchRequests.delete(batchKey);
        // Execute the request.
        return next.handle(req);
      }),
      share()
    );
    const batchRequest = req as JoinedHttpBatchRequest;
    batchRequest.batchKey = batchKey;
    batchRequest.originalRequestBatchSizes = [batchRequest.body.batch.length];
    batchRequest.originalRequests = [req];
    batchRequest.sharedBatchResponse = response;

    this.pendingBatchRequests.set(batchKey, batchRequest);
    return this.selectResultsFromBatchResponse(req, batchRequest);
  }

  private addToBatchRequest(
    req: HttpBatchRequest,
    batchRequest: JoinedHttpBatchRequest
  ): Observable<HttpEvent<unknown>> {
    batchRequest.originalRequests.push(req);
    batchRequest.body.batch.push(...req.body.batch);
    batchRequest.originalRequestBatchSizes.push(req.body.batch.length);
    return this.selectResultsFromBatchResponse(req, batchRequest);
  }

  private selectResultsFromBatchResponse(
    originalRequest: HttpBatchRequest,
    batchRequest: JoinedHttpBatchRequest
  ): Observable<HttpEvent<unknown>> {
    return batchRequest.sharedBatchResponse.pipe(
      map((event) => {
        if (!(event instanceof HttpResponse)) {
          return event;
        }
        // Validate response first.
        const batchResponseData = truthy(event.body as WithBatch).batch;
        assertTruthy(Array.isArray(batchResponseData));
        const requestIndex = batchRequest.originalRequests.indexOf(originalRequest);
        assertTruthy(requestIndex >= 0);

        let responseDataIndexFrom = 0;
        for (let i = 0; i < requestIndex; i++) {
          responseDataIndexFrom += batchRequest.originalRequestBatchSizes[i];
        }
        const originalResponseBatchSize = batchRequest.originalRequestBatchSizes[requestIndex];
        assertTruthy(
          responseDataIndexFrom >= 0 && responseDataIndexFrom + originalResponseBatchSize <= batchResponseData.length
        );

        const originalRequestResponseBody: WithBatch = { batch: [] };
        for (let i = responseDataIndexFrom; i < responseDataIndexFrom + originalResponseBatchSize; i++) {
          originalRequestResponseBody.batch.push(batchResponseData[i]);
        }
        return event.clone({ body: originalRequestResponseBody });
      })
    );
  }
}

/** An HTTP request with a mandatory 'body' field. */
type HttpRequestWithBody<T> = HttpRequest<T> & { body: T };

/**
 * Model of the batch request.
 * Optional 'organizationId' and 'instanceId' are included into the batch group key.
 */
type HttpBatchRequest = HttpRequestWithBody<WithBatch & Partial<WithOrganizationId> & Partial<WithInstanceId>>;

/** The original batch request adjusted to hold all merged batch requests. */
interface JoinedHttpBatchRequest extends HttpBatchRequest {
  batchKey: string;
  /** Original requests. */
  originalRequests: Array<HttpRequest<WithBatch>>;
  originalRequestBatchSizes: number[];

  /** Response observable. The 'response' contains result for all multiplexed requests in 'batch' field. */
  sharedBatchResponse: Observable<HttpEvent<WithBatch>>;
}

/** Returns true if the request supports batch mode (multiplexing mode). */
function isBatchRequest(request: HttpRequest<any>): request is HttpBatchRequest {
  return Array.isArray(request?.body?.batch);
}
