import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmail } from '@cp/common/utils/ValidationUtils';
import { AccountService } from '@cp/web/app/account/account.service';

@Component({
  templateUrl: './email-verification-error-auth0-callback.component.html',
  styleUrls: ['./email-verification-error-auth0-callback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVerificationErrorAuth0CallbackComponent {
  readonly email: string;
  readonly auth0Sub: string;
  message?: string;

  constructor(
    route: ActivatedRoute,
    router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly accountService: AccountService
  ) {
    this.email = route.snapshot.queryParams['email'] || '';
    this.auth0Sub = route.snapshot.queryParams['sub'] || '';
    if (!isEmail(this.email) || this.auth0Sub.length === 0) {
      // When used correctly, this page always has 'email' parameter.
      // When used incorrectly (invoked manually) -> ignore and redirect to the root.
      console.log('Invalid page parameters', route.snapshot.queryParams);
      router.navigate(['/']).then();
    }
  }

  async resendEmailConfirmationLink(): Promise<void> {
    this.message = undefined;
    try {
      await this.accountService.resendEmailConfirmation(this.auth0Sub);
    } catch (error) {
      // TODO(#4123): make message more detailed. There may be error rate-limit errors.
      this.message = 'Failed to send email confirmation link';
    } finally {
      this.cdr.markForCheck();
    }
  }
}
