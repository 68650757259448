import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import {
  AcceptOrganizationInvitationRequest,
  AcceptOrganizationInvitationResponse,
  ChangeOrganizationNameRequest,
  ChangeOrganizationUserRoleRequest,
  CreateDefaultOrganizationForUserRequest,
  CreateOrganizationRequest,
  DeclineOrganizationInvitationRequest,
  DeleteOrganizationInvitationRequest,
  DeleteOrganizationRequest,
  InviteToOrganizationRequest,
  LeaveOrganizationRequest,
  ListOrganizationActivitiesRequest,
  ListOrganizationActivitiesResponse,
  ListOrganizationsRequest,
  ListOrganizationsResponse,
  MarkViewedInvitationsRequest,
  Organization,
  ORGANIZATION_API_PATH,
  OrganizationActivity,
  OrganizationRole,
  RemoveUserFromOrganizationRequest,
  ResendInviteToOrganizationRequest
} from '@cp/common/protocol/Organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationApiService {
  constructor(private readonly restService: RestService) {}

  async listOrganizations(): Promise<ListOrganizationsResponse> {
    const request: ListOrganizationsRequest = { rpcAction: 'list' };
    return await this.restService.post<ListOrganizationsResponse>(ORGANIZATION_API_PATH, request);
  }

  async createOrganization(name: string): Promise<Organization> {
    const request: CreateOrganizationRequest = { rpcAction: 'create', name };
    return await this.restService.post<Organization>(ORGANIZATION_API_PATH, request);
  }

  async changeOrganizationName(organizationId: string, name: string): Promise<void> {
    const request: ChangeOrganizationNameRequest = { rpcAction: 'rename', organizationId, name };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async deleteOrganization(organizationId: string): Promise<void> {
    const request: DeleteOrganizationRequest = { rpcAction: 'delete', organizationId };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  // noinspection JSUnusedGlobalSymbols
  async leaveOrganization(organizationId: string): Promise<void> {
    const request: LeaveOrganizationRequest = { rpcAction: 'leave', organizationId };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async changeRole(organizationId: string, userId: string, role: OrganizationRole): Promise<void> {
    const request: ChangeOrganizationUserRoleRequest = { rpcAction: 'changeUserRole', organizationId, userId, role };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async acceptInvitation(inviteKey: string): Promise<AcceptOrganizationInvitationResponse> {
    const request: AcceptOrganizationInvitationRequest = { rpcAction: 'acceptInvitation', inviteKey };
    return await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async declineInvitation(inviteKey: string): Promise<void> {
    const request: DeclineOrganizationInvitationRequest = { rpcAction: 'declineInvitation', inviteKey };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async deleteInvitation(organizationId: string, email: string): Promise<void> {
    const requestBody: DeleteOrganizationInvitationRequest = { rpcAction: 'deleteInvitation', organizationId, email };
    await this.restService.post(ORGANIZATION_API_PATH, requestBody);
  }

  async removeUser(organizationId: string, userId: string): Promise<void> {
    const request: RemoveUserFromOrganizationRequest = { rpcAction: 'removeUser', organizationId, userId };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async invite(organizationId: string, emails: Array<string>, roles: Array<OrganizationRole>): Promise<void> {
    const request: InviteToOrganizationRequest = { rpcAction: 'invite', organizationId, emails, roles };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async resendInvite(organizationId: string, email: string, role: OrganizationRole): Promise<void> {
    const request: ResendInviteToOrganizationRequest = { rpcAction: 'resendInvitation', organizationId, email, role };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async listActivities(organizationId: string): Promise<Array<OrganizationActivity>> {
    const request: ListOrganizationActivitiesRequest = { rpcAction: 'listActivities', organizationId };
    return (await this.restService.post<ListOrganizationActivitiesResponse>(ORGANIZATION_API_PATH, request)).activities;
  }

  async createDefaultOrganizationForUser() {
    const request: CreateDefaultOrganizationForUserRequest = { rpcAction: 'createDefault' };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }

  async markViewedInvitations(inviteKeys: string[]) {
    const request: MarkViewedInvitationsRequest = { rpcAction: 'markViewedInvitations', inviteKeys };
    await this.restService.post(ORGANIZATION_API_PATH, request);
  }
}
