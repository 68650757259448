import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogPost, isBlogPost, VideoRecording } from '@cp/common/protocol/IntegrationRelevantContent';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { RelevantContentService } from '@cp/web/app/marketplace/relevant_content.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cp-relevant-content',
  templateUrl: './relevant-content.component.html',
  styleUrls: ['./relevant-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationRelevantContentComponent implements OnInit {
  relevantContent = new Observable<Array<BlogPost | VideoRecording>>();
  private readonly maxElements = 5;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly relevantContentService: RelevantContentService,
    private readonly segmentService: SegmentService
  ) {}

  async ngOnInit(): Promise<void> {
    const integrationNameParam = this.getIntegrationName();
    if (!integrationNameParam) {
      console.warn(`Couldn't find integrationName in the query param map - navigate back to integrations page.`);
      await this.router.navigate(['integrations']);
      return;
    }

    this.relevantContent = from(this.relevantContentService.load(integrationNameParam)).pipe(
      map((content) => {
        return content.slice(0, this.maxElements);
      })
    );
  }

  private getIntegrationName(): string | null {
    return this.route.snapshot.paramMap.get('integrationName');
  }

  onLinkClicked(content: BlogPost | VideoRecording) {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'relevantContentSelected',
      category: 'integrationPage',
      properties: {
        integration_name: this.getIntegrationName(),
        title: content.title,
        url: content.url,
        type: isBlogPost(content) ? 'blogpost' : 'video'
      },
      view: 'integrationPage',
      component: 'sidebar'
    });
  }
}
