/*** Date/time related utilities and constants. */

export const MILLIS_PER_SECOND = 1000;
export const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
export const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
export const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;
export const MILLIS_PER_WEEK = 7 * MILLIS_PER_DAY;

export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
export const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;
export const SECONDS_PER_WEEK = 7 * SECONDS_PER_DAY;
export const SECONDS_PER_MONTH = 30 * SECONDS_PER_DAY;
export const SECONDS_PER_YEAR = 365 * SECONDS_PER_DAY;

/** Average amount of hours per month. Used for pricing and billing purposes. */
export const HOURS_PER_MONTH = (365 * 24) / 12;

/**
 * Used to define a period that has a start and end time.
 * Can be unbounded on one (since ever OR until forever), both (all time since ever until forever) or neither side (from time A to B)
 */
export interface TimePeriod {
  startTime?: Date;
  endTime?: Date;
}

/**
 * Returns true if referencePoint is between periodStartTime and periodEndTime (inclusive).
 * periodStartTime and periodEndTime behave like doTimePeriodsOverlap's second time range parameters where null or undefined values denote infinity.
 */
export function doesTimeRangeIncludePoint(
  referencePoint: Date | string | number,
  periodStartTime: Date | string | number | undefined | null,
  periodEndTime: Date | string | number | undefined | null
): boolean {
  return doTimePeriodsOverlap(referencePoint, referencePoint, periodStartTime, periodEndTime, true);
}

/**
 * Compares two time periods and returns true if they overlap.
 * If a period is invalid (endTime before startTime) this method returns false.
 * The accepted formats for the timestamps are the same as for Date constructor:
 * - String should be full ISO date.
 * - Number should be unix timestamp in milliseconds
 *
 * @param rawStartTime1 Start time of the first period.
 * @param rawEndTime1 End time of the first period.
 * @param rawStartTime2 Start time of the second period. Null or undefined values denote infinity (period started at big bang).
 * @param rawEndTime2 End time of the second period. Null or undefined values denote infinity (period will end at universe heat death).
 * @param isInclusiveComparison if true, a single overlap point (for example endTime1 === startTime2) is enough to count as overlapping.
 */
export function doTimePeriodsOverlap(
  rawStartTime1: Date | string | number,
  rawEndTime1: Date | string | number,
  rawStartTime2: Date | string | number | undefined | null,
  rawEndTime2: Date | string | number | undefined | null,
  isInclusiveComparison = true
): boolean {
  const startTime1 = new Date(rawStartTime1).getTime();
  const endTime1 = new Date(rawEndTime1).getTime();

  const startTime2 = rawStartTime2 ? new Date(rawStartTime2).getTime() : undefined;
  const endTime2 = rawEndTime2 ? new Date(rawEndTime2).getTime() : undefined;

  if (startTime2 === undefined && endTime2 === undefined) {
    // period2 is unbounded on both ends so it includes all time ever. There's definite overlap.
    return true;
  }

  if (startTime1 > endTime1) {
    // period1 is invalid (starts after it ends).
    return false;
  }

  if (startTime2 === undefined && endTime2 !== undefined) {
    return isInclusiveComparison ? startTime1 <= endTime2 : startTime1 < endTime2;
  }

  if (startTime2 !== undefined && endTime2 === undefined) {
    return isInclusiveComparison ? endTime1 >= startTime2 : endTime1 > startTime2;
  }

  if (startTime2 && endTime2) {
    if (startTime2 > endTime2) {
      // period2 is invalid (starts after it ends).
      return false;
    }
    return isInclusiveComparison
      ? startTime1 <= endTime2 && endTime1 >= startTime2
      : startTime1 < endTime2 && endTime1 > startTime2;
  }

  return false;
}

/**
 * Returns the beginning of the day in UTC (midnight UTC).
 * Defaults to today if no date provided.
 */
export function getBeginningOfDayUtc(date = new Date()): Date {
  const modifiableDate = new Date(date.getTime());
  modifiableDate.setUTCHours(0, 0, 0, 0);
  return modifiableDate;
}

/** Converts full ISO date string (with time) to date only string: '2022-09-09T16:13:47.079Z'->'2022-09-09'. */
export function toISODateOnlyString(isoDateTimeStringOrDate: string | Date | number): string {
  const isoDateTimeString =
    typeof isoDateTimeStringOrDate === 'number'
      ? new Date(isoDateTimeStringOrDate).toISOString()
      : typeof isoDateTimeStringOrDate === 'object'
      ? isoDateTimeStringOrDate.toISOString()
      : isoDateTimeStringOrDate;
  return isoDateTimeString.substring(0, 10);
}

/** Converts date object to timeonly only string by timezone: '2022-09-09T16:13:47.079Z'->'2022-09-09'. */
export function toTimeByTimezone(
  dateTime: Date,
  timeZone?: string,
  timeZoneName:
    | 'short'
    | 'long'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric'
    | undefined = 'longGeneric'
): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit'
  };
  if (timeZone) {
    options.timeZone = timeZone;
  } else {
    options.timeZoneName = timeZoneName;
  }
  //Escaped character NARROW NO-BREAK SPACE can cause regex to fail when string is returned to cognito
  return dateTime.toLocaleTimeString('en-US', options).replace('\u202f', ' ');
}

export function toLocaleDate(milliseconds: string | number): string {
  return new Date(milliseconds).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

/** Formats date as 'YYYY-MM-DD HH:mm:ss'. */
export function formatDateInGalaxyStyle(timestamp: number | Date): string {
  const isoDate = (typeof timestamp === 'object' ? timestamp : new Date(timestamp)).toISOString();
  return isoDate.replace('T', ' ').substring(0, isoDate.length - 5);
}

/** Converts date object to detailed locale date string: '2023-02-21T16:13:47.079Z'->'Tuesday, February 21, 2023'. */
export function toDetailedLocaleDate(dateTime: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  return dateTime.toLocaleDateString('en-US', options);
}

/** Formats date range as 'Jul 10 to Jul 21, 2023' + suffix (optional). Limit to 30 chars. */
export function toDateRangeString(billStartDate: Date, billEndDate: Date, suffix: string = ''): string {
  if (billEndDate < billStartDate) {
    return 'invalid date range';
  }

  const billStartNoYear = billStartDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  return billStartNoYear + ' to ' + toLocaleDate(billEndDate.getTime()) + suffix;
}
