import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-trial-expired-dialog',
  templateUrl: './trial-expired-dialog.component.html',
  styleUrls: ['./trial-expired-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrialExpiredDialogComponent {
  readonly myRoleObs: Observable<OrganizationRole>;

  constructor(
    public readonly dialogRef: MatDialogRef<TrialExpiredDialogComponent>,
    private readonly organizationStateService: OrganizationStateService,
    private readonly router: Router
  ) {
    this.myRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
  }

  async goToBilling(): Promise<void> {
    await this.router.navigateByUrl('/organization/admin/billing');
    this.dialogRef.close();
  }

  static show(dialog: MatDialog): void {
    dialog.open(TrialExpiredDialogComponent, {
      width: '100%',
      maxWidth: '501px',
      minHeight: '463px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal_no_padding'
    });
  }
}
