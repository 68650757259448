<mat-sidenav-container class="nav_container">
  <mat-sidenav
    mode="over"
    position="end"
    [opened]="userDetailsVisible"
    disableClose
    class="flyout">
    <ng-container *ngIf="selectedUserPermissions(); let permissions">
      <ng-container *ngIf="selectedUserInstanceName(); let instanceName">
        <cp-db-user-details
          [userPermissions]="permissions"
          [instanceName]="instanceName"
          (flyoutClose)="selectUser(undefined)">
        </cp-db-user-details>
      </ng-container>
    </ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="introduction">
      <div class="description">
        Users with database access on services. All cloud admins have database
        admin access to all services. To add database users with custom roles,
        please use the
        <a
          galaxyClick="dbUsersPage.summary.sqlConsoleGetStartedButtonSelect"
          [href]="getSqlConsoleUri()"
          target="_blank"
          >
          SQL console.
        </a>
      </div>
      
      <button
        mat-button
        mat-stroked-button
        class="csv_button top_button"
        color="secondary"
        galaxyClick="dbUsersPage.summary.csvDownload"
        (click)="exportCsv()">
        Download CSV
      </button>
      
      <button
        mat-button
        mat-flat-button
        [disabled]="!anyUnloadedInstances()"
        class="top_button"
        color="primary"
        galaxyClick="dbUsersPage.summary.loadCredentials"
        (click)="showLoadAllDialog()">
        Show all users
      </button>
    </div>
    
    <div *ngFor="let instance of instances; trackBy: trackById">
      <manage-instance-db-users
        [instanceId]="instance.id"
        [permissionsResult]="permissionsForInstance(instance.id)"
        (userSelected)="selectUser($event)"
        (loadUsers)="loadPermissions($event)"
        >
      </manage-instance-db-users>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
