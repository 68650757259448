import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { assertTruthy } from '@cp/common/utils/Assert';

export type HealthIconType = 'healthy' | 'error' | 'warning' | 'loading';

@Component({
  standalone: true,
  selector: 'cw-health-icon',
  templateUrl: './health-icon.component.html',
  styleUrls: ['./health-icon.component.scss'],
  imports: [MatIconModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthIconComponent implements OnInit, OnChanges {
  @Input()
  icon!: HealthIconType;

  ngOnInit(): void {
    assertTruthy(this.icon);
  }

  ngOnChanges(): void {
    assertTruthy(this.icon);
  }
}
