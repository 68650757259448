<div class="metric_chart_with_summary">

  <div class="metrics_summary_with_info_block">
    <cp-metric-summary [summaryInput]="summaryInput"></cp-metric-summary>
    <div [matTooltip]="metric.helpTooltipText" class="info_block_corner" matTooltipPosition='right'>
      <mat-icon class="info_block_corner_icon" svgIcon="i_letter"></mat-icon>
    </div>
  </div>

  <cp-metric-chart *ngIf="!summaryInput.isError" [metric]="metric"
                [seriesData]="seriesData"
                class="metrics_chart"
  ></cp-metric-chart>

  <div *ngIf="summaryInput.isError" class="error_block">
    <div class="error_title">
      Something has gone wrong
    </div>
    <div class="error_text">
      The data has failed to load correctly.
      We have been made aware of this issue and are working to provide a fix.
    </div>
    <div>
      <button
        galaxyClick="servicePage.summary.metricCardRetryButtonSelect"
        (click)="refresh()" class="retry_button" color="primary" mat-flat-button>
        <mat-icon [svgIcon]="'health_loading'" class="retry_icon"></mat-icon>
        Retry
      </button>
    </div>
  </div>

</div>
