<div class="internal_sidebar_wrapper">
  <div class="internal_sidebar_title_wrapper">
    <mat-icon *ngIf="titleIcon" [svgIcon]="titleIcon" class="icon svg_fill"></mat-icon>
    <h4>{{title}}</h4>
  </div>
  <div class="internal_sidebar_menu" data-cy="internal-sidebar-menu">
    <ng-container *ngFor="let menuButton of menuButtons; trackBy: trackByName">
      <button mat-button
              *ngIf="(!menuButton.experiment || (menuButton.experiment | userFeatureEnabled | async))
        && (!menuButton.minRole || (menuButton.minRole | minRole | async))"
              [ngClass]="{active: isMenuButtonActive(menuButton)}"
              [routerLink]="menuButton.link"
              class="internal_sidebar_menu_item"
              data-cy="internal-sidebar-menu-item">
        <div class="icon_wrapper" *ngIf="menuButton.icon">
          <mat-icon [svgIcon]="menuButton.icon" class="icon svg_fill"></mat-icon>
        </div>
        <div class="internal_sidebar_menu_item_name" data-cy="internal-sidebar-menu-item-name">{{menuButton.name}}</div>
      </button>
    </ng-container>
  </div>
</div>
