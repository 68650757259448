import { formatCsvField } from '@cp/common/utils/FormatUtils';
import { saveAs } from 'file-saver';

export function downloadCsv(data: Array<string[]>, fileName: string) {
  const text = data.map((row) => row.map(formatCsvField).join(',')).join('\n');
  downloadText(text, fileName);
}

export function downloadText(text: string, fileName: string) {
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, fileName);
}
