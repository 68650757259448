<ng-container *ngIf="allowRedirection === 'false' else linkLogo">
  <span class="auth_logo">
    <mat-icon [class]="'logo' + ' fill-'+ (color ?? 'white')"
              [svgIcon]="type === 'full' ? 'logo_clickhouse' : 'logo'"></mat-icon>
  </span>
</ng-container>
<ng-template #linkLogo>
  <a class="auth_logo" href="https://clickhouse.com" target="_blank">
    <mat-icon [class]="'logo' + ' fill-'+ (color ?? 'white')"
              [svgIcon]="type === 'full' ? 'logo_clickhouse' : 'logo'"></mat-icon>
  </a>
</ng-template>
