<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="form-row">
    <div class="label">API Key name</div>
    <div class="name_input">
      <input class="seed_input" formControlName="name" data-cy="key-name" spellcheck="false">
    </div>
  </div>
  <div class="form-row">
    <div class="label">
      Organization permissions
      <mat-icon svgIcon="info_circle"
                class="permissions_info_icon"
                [matTooltip]="apiKeyOrgPermissionsTooltipText"
      ></mat-icon>
    </div>
    <mat-radio-group formControlName="role">
      <mat-radio-button value="DEVELOPER">{{formatOrgRole('DEVELOPER')}}</mat-radio-button>
      <mat-radio-button value="ADMIN" [disabled]="userRole !== 'ADMIN'" data-cy="admin-key-radio-button"
      >{{formatOrgRole('ADMIN')}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="form-row">
    <div class="label">Expires</div>
    <div class="period_select_block">
      <seed-select [options]="timePeriodOptions"
                   [value]="currentExpirationPeriodType"
                   formControlName="expirationPeriodType"
      ></seed-select>
      <input *ngIf="currentExpirationPeriodType === 'Custom'"
             class="seed_input"
             placeholder="YYYY-MM-DD HH:mm"
             formControlName="expirationDateValue"
      >
    </div>
  </div>
  <div class="notice" *ngIf="mode === 'create'">
    <mat-icon svgIcon="info_circle" class="info_icon"></mat-icon>
    <div>
      The API key only grants access to the cloud console and doesn't
      provide access to any of your ClickHouse databases.
    </div>
  </div>

  <div class="buttons">
    <button mat-flat-button
            [disabled]="form.invalid || !hasChanges || isSubmitInProgress"
            type="submit"
            class="button"
            color="primary"
            data-cy="key-submit-button"
    >{{mode === 'create' ? 'Generate API key' : 'Save changes'}}
    </button>
    <button mat-flat-button (click)="cancel.emit()" class="close_button" type="button">Cancel</button>
  </div>
</form>
