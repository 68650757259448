import { RpcRequest } from '@cp/common/utils/ProtocolUtils';

/** URL path for galaxy handler: /api/galaxy. */
export const GALAXY_API_PATH = 'galaxy';

/** Set of all RPC actions for 'galaxy' handler. */
export type GalaxyRpcAction = 'sendGalaxyEvent' | 'sendGalaxyUnauthenticatedEvent' | 'sendGalaxyForensicEvent';

export type GalaxyRpcRequest<T extends GalaxyRpcAction> = RpcRequest<T>;

export type ControlPlaneUnauthenticatedEventType =
  | 'sign_up'
  | 'emailInputFocus'
  | 'emailInputBlur'
  | 'googleAuthSelect'
  | 'nameInputFocus'
  | 'nameInputBlur'
  | 'loginButtonSubmit'
  | 'loginLinkSelect'
  | 'passwordInputFocus'
  | 'passwordInputBlur'
  | 'passwordResetLink'
  | 'registerLinkSelect'
  | 'orgInputFocus'
  | 'orgInputBlur'
  | 'tosAgreeCheck'
  | 'passwordReveal'
  | 'passwordHide'
  | 'tosLinkSelect'
  | 'privacyLinkSelect'
  | 'registerButtonSubmit'
  | 'resetButtonSelect'
  | 'click email'
  | 'questionnaireCheckboxCheck';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GalaxyProperties = Record<string, any>;
export type AnalyticsApplication = 'CONTROL_PLANE_API' | 'CONTROL_PLANE_WEB' | 'SQL_CONSOLE_WEB';

export type ForensicsNamespace = 'forensics' | 'logs';
/** All view values across all systems. */
// noinspection JSUnusedGlobalSymbols
export type AnalyticsNamespace =
  | SqlUiAnalyticsNamespace
  | ControlPlaneAnalyticsNamespace
  | ForensicsNamespace
  | 'audit';

/** View values that exist in SQL UI. */
export type SqlUiAnalyticsNamespace = 'app' | 'navbar' | 'sidebar' | 'tabbar' | 'tableView' | 'queryView';
/** View values that exist in CP WEB. */
export type ControlPlaneAnalyticsNamespace =
  | 'auth'
  | 'acceptInvitationsPage'
  | 'leftbar'
  | 'billing'
  | 'feedback'
  | 'serviceList'
  | 'servicePage'
  | 'userProfile'
  | 'signup'
  | 'onboarding'
  | 'integrationList'
  | 'integrationPage'
  | 'membersPage'
  | 'dbUsersPage'
  | 'tackle'
  | 'entryQuestionnaire'
  | 'admin'
  | 'addingMember';

// noinspection JSUnusedGlobalSymbols
export type AnalyticsComponent = SqlUiAnalyticsComponent | ControlPlaneAnalyticsComponent;
/** Component values that exist in SQL UI. */
export type SqlUiAnalyticsComponent =
  | 'window'
  | 'logo'
  | 'navButtons'
  | 'footer'
  | 'tableList'
  | 'queryList'
  | 'homeTab'
  | 'queryTab'
  | 'tableTab'
  | 'toolBar'
  | 'insertRow'
  | 'filterSection'
  | 'sortSection'
  | 'filterSortPane'
  | 'spreadsheet'
  | 'editor'
  | 'resultToolBar'
  | 'resultSpreadsheet'
  | 'resultSpreadsheetFooter'
  | 'chartConfig';

/** Component values that exist in CP WEB. */
export type ControlPlaneAnalyticsComponent =
  | 'window'
  | 'new'
  | 'button'
  | 'checkbox'
  | 'form'
  | 'login'
  | 'register'
  | 'passwordReset'
  | 'verifyEmail'
  | 'resendEmailModal'
  | 'organizationModal'
  | 'header'
  | 'nav'
  | 'footer'
  | 'modal'
  | 'serviceCard'
  | 'integrationCard'
  | 'summary'
  | 'metricRequest'
  | 'backups'
  | 'security'
  | 'sidebar'
  | 'tab'
  | 'relevantContent'
  | 'onboarding1'
  | 'onboarding2'
  | 'onboarding3'
  | 'firmographics'
  | 'postSignIn'
  | 'userDetailsFlyout';

export type SegmentEventType =
  | 'sign up'
  | 'sign in'
  | 'modify organization'
  | 'create new service'
  | 'click'
  | 'done'
  | 'invite member'
  | 'update profile'
  | 'upload file'
  | 'click email'
  | 'onboarding'
  | 'load data';

export type ControlPlaneAuthenticatedEventType =
  | 'load'
  | 'blur'
  | 'focus'
  | 'serviceSelect'
  | 'resendLinkSelect'
  | 'serviceNameBlur'
  | 'loginLinkSelect'
  | 'regionSelectorFocus'
  | 'regionSelectorBlur'
  | 'regionSelectorSelect'
  | 'gcpWaitlistButtonSelect'
  | 'azureWaitlistButtonSelect'
  | 'serviceNameInputFocus'
  | 'serviceNameInputBlur'
  | 'purposeSelect'
  | 'continueButtonSelect'
  | 'whitelistEntryDelete'
  | 'whitelistAddressInputFocus'
  | 'whitelistAddressInputBlur'
  | 'whitelistDescInputFocus'
  | 'whitelistDescInputBlur'
  | 'whitelistSubmitButtonSelect'
  | 'usernameCopy'
  | 'passwordCopy'
  | 'credentialDownload'
  | 'providerSelect'
  | 'logoClick'
  | 'servicesSectionFocus'
  | 'integrationsSectionFocus'
  | 'membersSectionFocus'
  | 'activitySectionFocus'
  | 'adminSectionFocus'
  | 'helpSectionFocus'
  | 'upgradeButtonSelect'
  | 'feedbackButtonSelect'
  | 'infoTypeSelect'
  | 'addressInputFocus'
  | 'addressInputBlur'
  | 'address2InputFocus'
  | 'address2InputBlur'
  | 'cityInputFocus'
  | 'cityInputBlur'
  | 'zipInputFocus'
  | 'zipInputBlur'
  | 'countrySelectorFocus'
  | 'countrySelectorBlur'
  | 'countrySelectorSelect'
  | 'sameAddressUncheck'
  | 'sameAddressCheck'
  | 'bizAddressInputFocus'
  | 'bizAddressInputBlur'
  | 'bizAddress2InputFocus'
  | 'bizAddress2InputBlur'
  | 'bizCityInputFocus'
  | 'bizCityInputBlur'
  | 'bizZipInputFocus'
  | 'bizZipInputBlur'
  | 'bizCountrySelectorFocus'
  | 'bizCountrySelectorBlur'
  | 'bizCountrySelectorSelect'
  | 'paymentDetailsButtonSelect'
  | 'companyDetailsButtonSelect'
  | 'bizNameInputFocus'
  | 'bizNameInputBlur'
  | 'bizSizeSelectorFocus'
  | 'bizSizeSelectorBlur'
  | 'bizSizeSelectorSelect'
  | 'bizTaxInputFocus'
  | 'bizTaxInputBlur'
  | 'bizSiteInputFocus'
  | 'bizSiteInputBlur'
  | 'cardNumberInputFocus'
  | 'cardNumberInputBlur'
  | 'cardExpiryInputFocus'
  | 'cardExpiryInputBlur'
  | 'cardCvcInputFocus'
  | 'cardCvcInputBlur'
  | 'backButtonSelect'
  | 'cancelButtonSelect'
  | 'scoreSelect'
  | 'feedbackInputFocus'
  | 'feedbackInputBlur'
  | 'feedbackShareButtonSelect'
  | 'closeButtonSelect'
  | 'serviceContextMenuOpen'
  | 'serviceContextMenuBlur'
  | 'serviceContextMenuServiceOpen'
  | 'serviceContextMenuServiceStart'
  | 'serviceContextMenuServiceStop'
  | 'serviceContextMenuServiceDelete'
  | 'serviceContextMenuServiceUpgrade'
  | 'connectContextMenuOpen'
  | 'connectContextMenuBlur'
  | 'connectContextMenuConsoleOpen'
  | 'connectContextMenuDetailsOpen'
  | 'newServiceButtonSelect'
  | 'actionsContextMenuOpen'
  | 'actionsContextMenuBlur'
  | 'actionsContextMenuDataLoad'
  | 'actionsContextMenuVisualize'
  | 'actionsContextMenuPasswordReset'
  | 'actionsContextMenuServiceRename'
  | 'actionsContextMenuScalingOpen'
  | 'actionsContextMenuServiceStart'
  | 'actionsContextMenuServiceStop'
  | 'actionsContextMenuServiceDelete'
  | 'navSummarySelect'
  | 'navBackupsSelect'
  | 'navSecuritySelect'
  | 'navConnectionSelect'
  | 'navPermissionsSelect'
  | 'loadDataGetStartedButtonSelect'
  | 'sqlConsoleGetStartedButtonSelect'
  | 'connectStringGetStartedButtonSelect'
  | 'inviteMembersGetStartedSelect'
  | 'watchVideoGetStartedSelect'
  | 'newMetricButtonSelect'
  | 'advancedDashboardLinkSelect'
  | 'timePeriodSelectorOpen'
  | 'timePeriodSelectorBlur'
  | 'timePeriodSelectorSelect'
  | 'metricCardRetryButtonSelect'
  | 'backupRestoreButtonSelect'
  | 'metricRequestButtonSelect'
  | 'metricRequestInputFocus'
  | 'metricRequestInputBlur'
  | 'accessOptionSelect'
  | 'whitelistCancelButtonSelect'
  | 'whiteListCurrentIpButtonSelect'
  | 'whiteListAddEntryButtonSelect'
  | 'integrationSelect'
  | 'howToConnectSelect'
  | 'changelogSelect'
  | 'aboutSelect'
  | 'fullDocumentationSelect'
  | 'relevantContentSelected'
  | 'apiKeysSectionFocus'
  | 'dbUsersSectionFocus'
  | 'csvDownload'
  | 'loadCredentials'
  | 'creditsRequestInputFocus'
  | 'creditsRequestInputBlur'
  | 'creditsRequestButtonSelect'
  | 'resendPassword';

export type ControlPlaneEventType = ControlPlaneAuthenticatedEventType | ControlPlaneUnauthenticatedEventType;
export type GalaxyEventType = SegmentEventType | ControlPlaneEventType;

export type AnalyticsInteraction = 'trigger' | 'click' | 'rightclick' | 'doubleclick' | 'shortcut' | 'keypress';

export interface SqlUiEventData {
  application: 'SQL_CONSOLE_WEB';
  timestamp?: number | string;
  namespace: SqlUiAnalyticsNamespace;
  component: SqlUiAnalyticsComponent;
  sessionId?: string;
  interaction: AnalyticsInteraction;
  properties: GalaxyProperties;
  event: string;
  userId?: string;
}

export interface UnauthenticatedSqlUiEventData extends SqlUiEventData {
  userId: string;
}

export interface ControlPlaneEventData {
  application: 'CONTROL_PLANE_WEB';
  timestamp?: number | string;
  namespace: ControlPlaneAnalyticsNamespace;
  component: ControlPlaneAnalyticsComponent;
  event: string;
  interaction: AnalyticsInteraction;
  properties: GalaxyProperties;
  identify?: boolean;
  reportExternally?: boolean;
}

export interface ControlPlaneUnauthenticatedEventData extends Omit<ControlPlaneEventData, 'event'> {
  event: ControlPlaneUnauthenticatedEventType;
  userId: string; //expecting the anonymousId generated by segment
}

export type SqlUiGalaxyEvent = SqlUiEventData;
export type GalaxyUnauthenticatedEvent = ControlPlaneUnauthenticatedEventData | UnauthenticatedSqlUiEventData;
export type GalaxyAuthenticatedEvent = ControlPlaneEventData | SqlUiEventData;
export type GalaxyEvent = GalaxyAuthenticatedEvent | GalaxyUnauthenticatedEvent;
export type GalaxyEventWithUserId = GalaxyEvent & { userId: string };

export interface GalaxyRequest<T extends GalaxyEvent | ForensicsEventData>
  extends GalaxyRpcRequest<'sendGalaxyEvent' | 'sendGalaxyForensicEvent'> {
  galaxySessionId?: string;
  data: Array<T>;
}

export interface ForensicsEventData {
  application: AnalyticsApplication;
  timestamp?: number | string; // in millis. To get this from the client side use a Date.now()
  namespace?: AnalyticsNamespace;
  component?: string;
  interaction?: AnalyticsInteraction;
  sessionId?: string;
  event: string;
  properties: GalaxyProperties;
  message: string;
  orgId?: string;
  userId?: string;
}

export interface ControlPlaneEventData {
  application: 'CONTROL_PLANE_WEB';
  timestamp?: number | string;
  namespace: ControlPlaneAnalyticsNamespace;
  component: ControlPlaneAnalyticsComponent;
  sessionId?: string;
  event: string;
  interaction: AnalyticsInteraction;
  properties: GalaxyProperties;
  identify?: boolean;
  reportExternally?: boolean;
  userId?: string;
}

export interface ControlPlaneUnauthenticatedEventData extends Omit<ControlPlaneEventData, 'event'> {
  event: ControlPlaneUnauthenticatedEventType;
  userId: string; //expecting the anonymousId generated by segment
}

export type ControlPlaneGalaxyEventRequest = GalaxyRequest<ControlPlaneEventData>;
export type ControlPlaneGalaxyUnauthenticatedEventRequest = GalaxyRequest<ControlPlaneUnauthenticatedEventData>;
export type SqlUiGalaxyEventRequest = GalaxyRequest<SqlUiGalaxyEvent>;
export type GalaxyAuthenticatedEventRequest = ControlPlaneGalaxyEventRequest | SqlUiGalaxyEventRequest;
export type GalaxyUnauthenticatedEventRequest = ControlPlaneGalaxyUnauthenticatedEventRequest;
export type SendGalaxyForensicEventRequest = GalaxyRequest<ForensicsEventData>;
