import { DeepReadonly } from '@cp/common/protocol/Common';

export interface TaxIdInfoType {
  country: string;
  countryCode?: string;
  taxId?: TaxIdDatumType;
  description: string;
  example?: string;
  taxStatus?: TaxStatusType;
}

export const ALL_TAX_STATUS_TYPES = ['unregistered', 'exempt', 'unknown', 'unsupported', 'registered'] as const;

export type TaxStatusType = (typeof ALL_TAX_STATUS_TYPES)[number];

const ALL_TAX_STATUS_TYPES_SET: ReadonlySet<TaxStatusType> = new Set<TaxStatusType>([...ALL_TAX_STATUS_TYPES]);

export function isTaxStatusType(value: unknown): value is TaxStatusType {
  return ALL_TAX_STATUS_TYPES_SET.has(value as TaxStatusType);
}

export function filterTaxIdTypesByCountry(countryCode: string): Array<TaxIdInfoType> {
  return taxIdTypes.filter((c) => c.countryCode == countryCode || c.country === 'None');
}

export type TaxIdDatumType =
  | 'ad_nrt'
  | 'ae_trn'
  | 'ar_cuit'
  | 'au_abn'
  | 'au_arn'
  | 'bg_uic'
  | 'bo_tin'
  | 'br_cnpj'
  | 'br_cpf'
  | 'ca_bn'
  | 'ca_gst_hst'
  | 'ca_pst_bc'
  | 'ca_pst_mb'
  | 'ca_pst_sk'
  | 'ca_qst'
  | 'ch_vat'
  | 'cl_tin'
  | 'cn_tin'
  | 'co_nit'
  | 'cr_tin'
  | 'do_rcn'
  | 'ec_ruc'
  | 'eg_tin'
  | 'es_cif'
  | 'eu_oss_vat'
  | 'eu_vat'
  | 'gb_vat'
  | 'ge_vat'
  | 'hk_br'
  | 'hu_tin'
  | 'id_npwp'
  | 'il_vat'
  | 'in_gst'
  | 'is_vat'
  | 'jp_cn'
  | 'jp_rn'
  | 'jp_trn'
  | 'ke_pin'
  | 'kr_brn'
  | 'li_uid'
  | 'mx_rfc'
  | 'my_frp'
  | 'my_itn'
  | 'my_sst'
  | 'no_vat'
  | 'nz_gst'
  | 'pe_ruc'
  | 'ph_tin'
  | 'ro_tin'
  | 'rs_pib'
  | 'ru_inn'
  | 'ru_kpp'
  | 'sa_vat'
  | 'sg_gst'
  | 'sg_uen'
  | 'si_tin'
  | 'sv_nit'
  | 'th_vat'
  | 'tr_tin'
  | 'tw_vat'
  | 'ua_vat'
  | 'us_ein'
  | 'uy_ruc'
  | 've_rif'
  | 'vn_tin'
  | 'za_vat';

/** Tax Ids and associated country, description and a sample to show expected format to be used as a hint.
 * see: https://stripe.com/docs/billing/customer/tax-ids
 * used this jQuery code to extract data from the page: https://jsfiddle.net/moorea/80xa6hyv/2/
 */
export const taxIdTypes: DeepReadonly<Array<TaxIdInfoType>> = [
  {
    country: 'None',
    description: 'I am not registered to pay taxes and do not have a Tax ID',
    taxStatus: 'unregistered'
  },
  {
    country: 'None',
    description: 'I am a tax exempt entity',
    taxStatus: 'exempt'
  },
  {
    country: 'Andorra',
    countryCode: 'AD',
    description: 'Andorran NRT number',
    example: 'A-123456-Z',
    taxId: 'ad_nrt'
  },
  {
    country: 'Argentina',
    countryCode: 'AR',
    description: 'Argentinian tax ID number',
    example: '12-3456789-01',
    taxId: 'ar_cuit'
  },
  {
    country: 'Australia',
    countryCode: 'AU',
    description: 'Australian Business Number (AU ABN)',
    example: '12345678912',
    taxId: 'au_abn'
  },
  {
    country: 'Australia',
    countryCode: 'AU',
    description: 'Australian Taxation Office Reference Number',
    example: '123456789123',
    taxId: 'au_arn'
  },
  {
    country: 'Austria',
    countryCode: 'AT',
    description: 'European VAT number',
    example: 'ATU12345678',
    taxId: 'eu_vat'
  },
  {
    country: 'Belgium',
    countryCode: 'BE',
    description: 'European VAT number',
    example: 'BE0123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'Bolivia',
    countryCode: 'BO',
    description: 'Bolivian tax ID',
    example: '123456789',
    taxId: 'bo_tin'
  },
  {
    country: 'Brazil',
    countryCode: 'BR',
    description: 'Brazilian CNPJ number',
    example: '01.234.456/5432-10',
    taxId: 'br_cnpj'
  },
  {
    country: 'Brazil',
    countryCode: 'BR',
    description: 'Brazilian CPF number',
    example: '123.456.789-87',
    taxId: 'br_cpf'
  },
  {
    country: 'Bulgaria',
    countryCode: 'BG',
    description: 'Bulgaria Unified Identification Code',
    example: '123456789',
    taxId: 'bg_uic'
  },
  {
    country: 'Bulgaria',
    countryCode: 'BG',
    description: 'European VAT number',
    example: 'BG0123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    description: 'Canadian BN',
    example: '123456789',
    taxId: 'ca_bn'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    description: 'Canadian GST/HST number',
    example: '123456789RT0002',
    taxId: 'ca_gst_hst'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    description: 'Canadian PST number (British Columbia)',
    example: 'PST-1234-5678',
    taxId: 'ca_pst_bc'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    description: 'Canadian PST number (Manitoba)',
    example: '123456-7',
    taxId: 'ca_pst_mb'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    description: 'Canadian PST number (Saskatchewan)',
    example: '1234567',
    taxId: 'ca_pst_sk'
  },
  {
    country: 'Canada',
    countryCode: 'CA',
    description: 'Canadian QST number (Québec)',
    example: '1234567890TQ1234',
    taxId: 'ca_qst'
  },
  {
    country: 'Chile',
    countryCode: 'CL',
    description: 'Chilean TIN',
    example: '12.345.678-K',
    taxId: 'cl_tin'
  },
  {
    country: 'China',
    countryCode: 'CN',
    description: 'Chinese tax ID',
    example: '123456789012345678',
    taxId: 'cn_tin'
  },
  {
    country: 'Colombia',
    countryCode: 'CO',
    description: 'Colombian NIT number',
    example: '123.456.789-0',
    taxId: 'co_nit'
  },
  {
    country: 'Costa Rica',
    countryCode: 'CR',
    description: 'Costa Rican tax ID',
    example: '1-234-567890',
    taxId: 'cr_tin'
  },
  {
    country: 'Croatia',
    countryCode: 'HR',
    description: 'European VAT number',
    example: 'HR12345678912',
    taxId: 'eu_vat'
  },
  {
    country: 'Cyprus',
    countryCode: 'CY',
    description: 'European VAT number',
    example: 'CY12345678Z',
    taxId: 'eu_vat'
  },
  {
    country: 'Czech Republic',
    countryCode: 'CZ',
    description: 'European VAT number',
    example: 'CZ1234567890',
    taxId: 'eu_vat'
  },
  {
    country: 'Denmark',
    countryCode: 'DK',
    description: 'European VAT number',
    example: 'DK12345678',
    taxId: 'eu_vat'
  },
  {
    country: 'Dominican Republic',
    countryCode: 'DO',
    description: 'Dominican RCN number',
    example: '123-4567890-1',
    taxId: 'do_rcn'
  },
  {
    country: 'Ecuador',
    countryCode: 'EC',
    description: 'Ecuadorian RUC number',
    example: '1234567890001',
    taxId: 'ec_ruc'
  },
  {
    country: 'Egypt',
    countryCode: 'EG',
    description: 'Egyptian Tax Identification Number',
    example: '123456789',
    taxId: 'eg_tin'
  },
  {
    country: 'El Salvador',
    countryCode: 'SV',
    description: 'El Salvadorian NIT number',
    example: '1234-567890-123-4',
    taxId: 'sv_nit'
  },
  {
    country: 'Estonia',
    countryCode: 'EE',
    description: 'European VAT number',
    example: 'EE123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'EU',
    countryCode: 'EU',
    description: 'European One Stop Shop VAT number for non-Union scheme',
    example: 'EU123456789',
    taxId: 'eu_oss_vat'
  },
  {
    country: 'Finland',
    countryCode: 'FI',
    description: 'European VAT number',
    example: 'FI12345678',
    taxId: 'eu_vat'
  },
  {
    country: 'France',
    countryCode: 'FR',
    description: 'European VAT number',
    example: 'FRAB123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'Georgia',
    countryCode: 'GE',
    description: 'Georgian VAT',
    example: '123456789',
    taxId: 'ge_vat'
  },
  {
    country: 'Germany',
    countryCode: 'DE',
    description: 'European VAT number',
    example: 'DE123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'Greece',
    countryCode: 'GR',
    description: 'European VAT number',
    example: 'EL123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'Hong Kong',
    countryCode: 'HK',
    description: 'Hong Kong BR number',
    example: '12345678',
    taxId: 'hk_br'
  },
  {
    country: 'Hungary',
    countryCode: 'HU',
    description: 'European VAT number',
    example: 'HU12345678',
    taxId: 'eu_vat'
  },
  {
    country: 'Hungary',
    countryCode: 'HU',
    description: 'Hungary tax number (adószám)',
    example: '12345678-1-23',
    taxId: 'hu_tin'
  },
  {
    country: 'Iceland',
    countryCode: 'IS',
    description: 'Icelandic VAT',
    example: '123456',
    taxId: 'is_vat'
  },
  {
    country: 'India',
    countryCode: 'IN',
    description: 'Indian GST number',
    example: '12ABCDE3456FGZH',
    taxId: 'in_gst'
  },
  {
    country: 'Indonesia',
    countryCode: 'ID',
    description: 'Indonesian NPWP number',
    example: '12.345.678.9-012.345',
    taxId: 'id_npwp'
  },
  {
    country: 'Ireland',
    countryCode: 'IE',
    description: 'European VAT number',
    example: 'IE1234567AB',
    taxId: 'eu_vat'
  },
  {
    country: 'Israel',
    countryCode: 'IL',
    description: 'Israel VAT',
    example: '000012345',
    taxId: 'il_vat'
  },
  {
    country: 'Italy',
    countryCode: 'IT',
    description: 'European VAT number',
    example: 'IT12345678912',
    taxId: 'eu_vat'
  },
  {
    country: 'Japan',
    countryCode: 'JP',
    description: 'Japanese Corporate Number (*Hōjin Bangō*)',
    example: '1234567891234',
    taxId: 'jp_cn'
  },
  {
    country: 'Japan',
    countryCode: 'JP',
    description:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    example: '12345',
    taxId: 'jp_rn'
  },
  {
    country: 'Japan',
    countryCode: 'JP',
    description: 'Japanese Tax Registration Number (*Tōroku Bangō*)',
    example: 'T1234567891234',
    taxId: 'jp_trn'
  },
  {
    country: 'Kenya',
    countryCode: 'KE',
    description: 'Kenya Revenue Authority Personal Identification Number',
    example: 'P000111111A',
    taxId: 'ke_pin'
  },
  {
    country: 'Latvia',
    countryCode: 'LV',
    description: 'European VAT number',
    example: 'LV12345678912',
    taxId: 'eu_vat'
  },
  {
    country: 'Liechtenstein',
    countryCode: 'LI',
    description: 'Liechtensteinian UID number',
    example: 'CHE123456789',
    taxId: 'li_uid'
  },
  {
    country: 'Lithuania',
    countryCode: 'LT',
    description: 'European VAT number',
    example: 'LT123456789123',
    taxId: 'eu_vat'
  },
  {
    country: 'Luxembourg',
    countryCode: 'LU',
    description: 'European VAT number',
    example: 'LU12345678',
    taxId: 'eu_vat'
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
    description: 'Malaysian FRP number',
    example: '12345678',
    taxId: 'my_frp'
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
    description: 'Malaysian ITN',
    example: 'C 1234567890',
    taxId: 'my_itn'
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
    description: 'Malaysian SST number',
    example: 'A12-3456-78912345',
    taxId: 'my_sst'
  },
  {
    country: 'Malta',
    countryCode: 'MT',
    description: 'European VAT number',
    example: 'MT12345678',
    taxId: 'eu_vat'
  },
  {
    country: 'Mexico',
    countryCode: 'MX',
    description: 'Mexican RFC number',
    example: 'ABC010203AB9',
    taxId: 'mx_rfc'
  },
  {
    country: 'Netherlands',
    countryCode: 'NL',
    description: 'European VAT number',
    example: 'NL123456789B12',
    taxId: 'eu_vat'
  },
  {
    country: 'New Zealand',
    countryCode: 'NZ',
    description: 'New Zealand GST number',
    example: '123456789',
    taxId: 'nz_gst'
  },
  {
    country: 'Norway',
    countryCode: 'NO',
    description: 'Norwegian VAT number',
    example: '123456789MVA',
    taxId: 'no_vat'
  },
  {
    country: 'Peru',
    countryCode: 'PE',
    description: 'Peruvian RUC number',
    example: '12345678901',
    taxId: 'pe_ruc'
  },
  {
    country: 'Philippines',
    countryCode: 'PH',
    description: 'Philippines Tax Identification Number',
    example: '123456789012',
    taxId: 'ph_tin'
  },
  {
    country: 'Poland',
    countryCode: 'PL',
    description: 'European VAT number',
    example: 'PL1234567890',
    taxId: 'eu_vat'
  },
  {
    country: 'Portugal',
    countryCode: 'PT',
    description: 'European VAT number',
    example: 'PT123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'Romania',
    countryCode: 'RO',
    description: 'European VAT number',
    example: 'RO1234567891',
    taxId: 'eu_vat'
  },
  {
    country: 'Romania',
    countryCode: 'RO',
    description: 'Romanian tax ID number',
    example: '1234567890123',
    taxId: 'ro_tin'
  },
  {
    country: 'Russia',
    countryCode: 'RU',
    description: 'Russian INN',
    example: '1234567891',
    taxId: 'ru_inn'
  },
  {
    country: 'Russia',
    countryCode: 'RU',
    description: 'Russian KPP',
    example: '123456789',
    taxId: 'ru_kpp'
  },
  {
    country: 'Saudi Arabia',
    countryCode: 'SA',
    description: 'Saudi Arabia VAT',
    example: '123456789012345',
    taxId: 'sa_vat'
  },
  {
    country: 'Serbia',
    countryCode: 'RS',
    description: 'Serbian PIB number',
    example: '12-3456789-01',
    taxId: 'rs_pib'
  },
  {
    country: 'Singapore',
    countryCode: 'SG',
    description: 'Singaporean GST',
    example: 'M12345678X',
    taxId: 'sg_gst'
  },
  {
    country: 'Singapore',
    countryCode: 'SG',
    description: 'Singaporean UEN',
    example: '123456789F',
    taxId: 'sg_uen'
  },
  {
    country: 'Slovakia',
    countryCode: 'SK',
    description: 'European VAT number',
    example: 'SK1234567891',
    taxId: 'eu_vat'
  },
  {
    country: 'Slovenia',
    countryCode: 'SI',
    description: 'European VAT number',
    example: 'SI12345678',
    taxId: 'eu_vat'
  },
  {
    country: 'Slovenia',
    countryCode: 'SI',
    description: 'Slovenia tax number (davčna številka)',
    example: '12345678',
    taxId: 'si_tin'
  },
  {
    country: 'South Africa',
    countryCode: 'ZA',
    description: 'South African VAT number',
    example: '4123456789',
    taxId: 'za_vat'
  },
  {
    country: 'South Korea',
    countryCode: 'KR',
    description: 'Korean BRN',
    example: '123-45-67890',
    taxId: 'kr_brn'
  },
  {
    country: 'Spain',
    countryCode: 'ES',
    description: 'Spanish NIF number (previously Spanish CIF number)',
    example: 'A12345678',
    taxId: 'es_cif'
  },
  {
    country: 'Spain',
    countryCode: 'ES',
    description: 'European VAT number',
    example: 'ESA1234567Z',
    taxId: 'eu_vat'
  },
  {
    country: 'Sweden',
    countryCode: 'SE',
    description: 'European VAT number',
    example: 'SE123456789123',
    taxId: 'eu_vat'
  },
  {
    country: 'Switzerland',
    countryCode: 'CH',
    description: 'Switzerland VAT number',
    example: 'CHE-123.456.789 MWST',
    taxId: 'ch_vat'
  },
  {
    country: 'Taiwan',
    countryCode: 'TW',
    description: 'Taiwanese VAT',
    example: '12345678',
    taxId: 'tw_vat'
  },
  {
    country: 'Thailand',
    countryCode: 'TH',
    description: 'Thai VAT',
    example: '1234567891234',
    taxId: 'th_vat'
  },
  {
    country: 'Turkey',
    countryCode: 'TR',
    description: 'Turkish Tax Identification Number',
    example: '0123456789',
    taxId: 'tr_tin'
  },
  {
    country: 'Ukraine',
    countryCode: 'UA',
    description: 'Ukrainian VAT',
    example: '123456789',
    taxId: 'ua_vat'
  },
  {
    country: 'United Arab Emirates',
    countryCode: 'AE',
    description: 'United Arab Emirates TRN',
    example: '123456789012345',
    taxId: 'ae_trn'
  },
  {
    country: 'United Kingdom',
    countryCode: 'GB',
    description: 'Northern Ireland VAT number',
    example: 'XI123456789',
    taxId: 'eu_vat'
  },
  {
    country: 'United Kingdom',
    countryCode: 'GB',
    description: 'United Kingdom VAT number',
    example: 'GB123456789',
    taxId: 'gb_vat'
  },
  {
    country: 'United States',
    countryCode: 'US',
    description: 'United States EIN',
    example: '12-3456789',
    taxId: 'us_ein'
  },
  {
    country: 'Uruguay',
    countryCode: 'UY',
    description: 'Uruguayan RUC number',
    example: '123456789012',
    taxId: 'uy_ruc'
  },
  {
    country: 'Venezuela',
    countryCode: 'VE',
    description: 'Venezuelan RIF number',
    example: 'A-12345678-9',
    taxId: 've_rif'
  },
  {
    country: 'Vietnam',
    countryCode: 'VN',
    description: 'Vietnamese tax ID number',
    example: '1234567890',
    taxId: 'vn_tin'
  }
];

export const taxIdTypesWithNoTaxValues: DeepReadonly<Array<TaxStatusType>> = taxIdTypes
  .filter((type) => type.taxStatus !== undefined)
  .map((value: TaxIdInfoType) => value.taxStatus as TaxStatusType);
