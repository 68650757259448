<mat-dialog-content *ngIf="data.maintenanceWindows[0] as firstWindow"
                    data-cy="maintenance-window-info-dialog"
                    class="maintenance_window_info_dialog"
>
  <div class="dialog_title" data-cy="maintenance-window-dialog-title">
    Scheduled Maintenance - {{firstWindow.maintenanceName}}
  </div>
  <div class="close_icon_wrapper">
    <cp-dialog-close-button [dialogRef]="dialogRef"
                            [width]="14"
                            [height]="16"
                            [fontSize]="16"
                            [top]="10"
    ></cp-dialog-close-button>
  </div>
  <div class="dialog_content">
    The following service(s) will enter a scheduled maintenance window for approximately
    {{firstWindow.endMaintenanceTime - firstWindow.startMaintenanceTime | durationMinutes: false}}
    beginning <b>{{firstWindow.startMaintenanceTime | date: 'MMMM d, y, h:mma'}} (local time):</b>
    <ul class="instance_names_list" data-cy="maintenance-window-dialog-instance-names">
      <li *ngFor="let maintenanceWindow of data.maintenanceWindows"
          [attr.data-cy]="'maintenance-window-dialog-instance-name-' + maintenanceWindow.instanceName">
        {{maintenanceWindow.instanceName}}
      </li>
    </ul>

    Your service should remain accessible during the maintenance window with minimal performance degradation. You will
    not be billed for the duration of the maintenance window.
  </div>
</mat-dialog-content>
