<form [formGroup]="companyDetailsForm" class="form" (ngSubmit)="onSubmit()"
      data-cy="change-billing-company-details-form"
>
  <div class="scrollable_wrapper padded_content">

    <div class="seed_form_element">
      <label>Company or organization</label>
      <input
        spellcheck="false"
        [galaxyClick]="buildFullyQualifiedEvent('bizNameInputFocus')"
        [galaxyBlur]="buildFullyQualifiedEvent('bizNameInputBlur')"
        formControlName="companyName"
        name="companyName"
        data-cy="company-name">

      <div class="seed_error"
           *ngIf="!companyDetailsForm.controls['companyName']?.valid && companyDetailsForm.controls['companyName']?.touched">
        Please enter a valid company name
      </div>
    </div>

    <div class="split_row">
      <div class="seed_form_element company_size_form_element">
        <label>Company size</label>
        <mat-form-field appearance="outline" class="company_size_select">
          <mat-select
            [galaxyClick]="buildFullyQualifiedEvent('bizSizeSelectorFocus')"
            [galaxyBlur]="buildFullyQualifiedEvent('bizSizeSelectorBlur')"
            [galaxyChange]="buildFullyQualifiedEvent('bizSizeSelectorSelect')"
            formControlName="companySize"
            data-cy="company-size-select"
            [typeaheadDebounceInterval]="500">
            <mat-option *ngFor="let companySizeOption of companySizeOptions"
                        [value]="companySizeOption.value"
                        [attr.data-cy]="companySizeOption.dataCy"
                        [attr.data-cy-value]="companySizeOption.dataCyValue"
            >{{companySizeOption.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="seed_error"
             *ngIf="!companyDetailsForm.controls['companySize']?.valid && companyDetailsForm.controls['companySize']?.touched">
          Please select company size
        </div>
      </div>

      <div *ngIf="isTaxIdShown" class="seed_form_element">
        <label>Tax ID number (optional)</label>
        <input
          spellcheck="false"
          [galaxyClick]="buildFullyQualifiedEvent('bizTaxInputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('bizTaxInputBlur')"
          data-cy="tax-id"
          formControlName="taxId"
          name="taxId">

        <div class="seed_error"
             *ngIf="!companyDetailsForm.controls['taxId']?.valid && companyDetailsForm.controls['taxId']?.touched">
          Please enter a valid tax ID number
        </div>
      </div>
    </div>

    <div class="seed_form_element">
      <label>Website</label>
      <input
        spellcheck="false"
        [galaxyClick]="buildFullyQualifiedEvent('bizSiteInputFocus')"
        [galaxyBlur]="buildFullyQualifiedEvent('bizSiteInputBlur')"
        formControlName="websiteUrl"
        name="websiteUrl"
        placeholder="https://"
        data-cy="website-url">
      <div class="seed_error"
           *ngIf="!companyDetailsForm.controls['websiteUrl']?.valid && companyDetailsForm.controls['websiteUrl']?.touched">
        Please enter a valid URL
      </div>
    </div>
  </div>

  <div class="vertical_buttons padded_content">
    <button
      [disabled]="companyDetailsForm.invalid || formInput?.buttonDisabled"
      class="main_action"
      color="primary" data-cy="company-details-update-button" mat-flat-button
      type="submit"
    >{{ customSubmitText || 'Proceed to payment details >' }}
    </button>
    <button
      data-cy="company-details-back-button"
      galaxyClick="billing.modal.backButtonSelect"
      (click)="handleBackButton()" class="secondary_action" mat-stroked-button>Back</button>
    <cw-system-message *ngIf="formInput?.errorMessage" [message]="formInput?.errorMessage"></cw-system-message>
  </div>
</form>
