import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'minRole'
})
export class MinRolePipe implements PipeTransform {
  constructor(private readonly organizationStateService: OrganizationStateService) {}

  transform(minRole: OrganizationRole): Observable<boolean> {
    return this.organizationStateService.observeCurrentOrganizationRole().pipe(
      map((role) => {
        if (role === 'ADMIN') return true;
        return minRole === 'DEVELOPER';
      })
    );
  }
}
