import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { trackById } from '@cp/common-services/trackById';
import { SupportCase } from '@cp/common/protocol/Support';
import { SupportCasesStateService } from '@cp/web/app/support/support-cases-state.service';
import { SupportDetailsComponent } from '@cp/web/app/support/support-details/support-details.component';
import { supportCaseStatusDisplay } from '@cp/web/app/support/support-protocol';
import { SupportUiService } from '@cp/web/app/support/support-ui.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cp-support-cases',
  templateUrl: './support-cases.component.html',
  styleUrls: ['./support-cases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportCasesComponent {
  supportCaseStatusDisplay = supportCaseStatusDisplay;
  trackById = trackById;

  supportCasesObs: Observable<Array<SupportCase>> = this.supportCasesStateService.observeSupportCases().pipe(
    map((supportCasesMap) => {
      return Object.values(supportCasesMap).sort((a, b) => {
        if (a.lastModifiedDate === b.lastModifiedDate) return 0;
        return b.lastModifiedDate > a.lastModifiedDate ? 1 : -1;
      });
    })
  );

  constructor(
    private readonly supportCasesStateService: SupportCasesStateService,
    private readonly supportUiService: SupportUiService,
    private readonly dialog: MatDialog
  ) {}

  openSupportCaseDetails(id: string): void {
    this.supportCasesStateService.setCurrentCaseId(id);
    this.dialog.open(SupportDetailsComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal_no_padding'
    });
  }
}
