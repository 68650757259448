<div class="auth_page_container">
  <div class="container">
    <div class="post_submission_text" data-cy="verify-email-address-notice">
      <div class="form_container" data-cy="check-your-email-card">
        <div class="auth_intro">
          <div class="auth_title">Check your email</div>
        </div>
        <mat-icon class="verify_email" svgIcon="verify_email"></mat-icon>
        <div class="text">
          We’ve sent an email validation link to <br/>
          <span class="email fs-exclude" data-cy="email">{{email}}</span>.
        </div>
        <div *ngIf="message" class="info-sub-text">
          {{message}}
        </div>
        <div class="buttons button-container buttons-col">
          <button (click)="resendEmailConfirmationLink()" class="button clickui-primary-button" mat-flat-button data-cy="resend-confirmation-link">
            Resend confirmation link
          </button>
          <a routerLink="/signIn">
            <button class="button clickui-secondary-button" mat-flat-button data-cy="back-to-sign-in">Back to sign in</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
