import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerifyTotpGuard extends OnDestroyComponent implements CanActivate {
  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly router: Router
  ) {
    super();
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.accountStateService
      .observeIsAwaitingTotpVerification()
      .pipe(map((isAwaitingTotpVerification) => (isAwaitingTotpVerification ? true : this.router.parseUrl('/signIn'))));
  }
}
