import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@cp/web/app/account/account.service';

@Component({
  selector: 'cp-disable-mfa-confirmation-dialog',
  templateUrl: './disable-mfa-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisableMfaConfirmationDialogComponent {
  isLoading = false;
  errorMessage = '';

  constructor(
    private readonly accountService: AccountService,
    readonly dialogRef: MatDialogRef<DisableMfaConfirmationDialogComponent>
  ) {}

  onCancel(): void {
    this.closeDialog();
  }

  async onPrimaryAction(): Promise<void> {
    await this.disableTotpMfa();
  }

  private closeDialog(): void {
    this.dialogRef.close();
  }

  private async disableTotpMfa(): Promise<void> {
    this.errorMessage = '';
    try {
      this.isLoading = true;
      await this.accountService.disableTotpMfa();
      this.closeDialog();
    } catch (e) {
      this.errorMessage = 'There was an error while disabling the authenticator app';
      this.isLoading = false;
      console.error(e);
    }
  }

  static show(dialog: MatDialog): void {
    dialog.open(DisableMfaConfirmationDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      panelClass: 'modal'
    });
  }
}
