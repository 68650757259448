export interface BlogPost {
  title: string;
  shortDescription: string;
  date: string;
  slug: string;
  url: string;
  keywords?: string;
}

export interface VideoRecording {
  title: string;
  shortDescription?: string;
  url: string;
  date?: string;
  duration?: string;
  keywords?: string;
}

export function isBlogPost(obj: unknown): obj is BlogPost {
  const blogPost = obj as BlogPost;
  return Boolean(blogPost.title && blogPost.shortDescription && blogPost.date && blogPost.slug && blogPost.keywords);
}

export function isVideoRecordings(obj: unknown): obj is VideoRecording {
  const blogPost = obj as VideoRecording;
  return Boolean(blogPost.title && blogPost.shortDescription && blogPost.url);
}
