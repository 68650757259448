import { StateLeafNode } from '@cp/common-services/state/action.applier';

export enum ActionType {
  DELETE_PATH = 'DELETE_PATH',
  SET_PATH = 'SET_PATH',
  BATCH_ACTION = 'BATCH_ACTION'
}

export declare interface BaseAction {
  type: ActionType;
}

export declare interface PathAction extends BaseAction {
  path: string[];
}

export type DeletePathAction = PathAction;

export declare interface SetPathAction extends PathAction {
  payload?: StateLeafNode;
}

export declare interface BatchAction extends BaseAction {
  payload: BaseAction[];
}

export function isPathAction(action: BaseAction): action is PathAction {
  const pathActions = [ActionType.SET_PATH, ActionType.DELETE_PATH];
  return pathActions.includes(action.type);
}

export function isSetPathAction(action: BaseAction): action is SetPathAction {
  return action.type === ActionType.SET_PATH;
}

export function isDeletePathAction(action: BaseAction): action is DeletePathAction {
  return action.type === ActionType.DELETE_PATH;
}

export function isBatchAction(action: BaseAction): action is BatchAction {
  return action.type === ActionType.BATCH_ACTION;
}

export function extractAllPathActions(action: BaseAction): PathAction[] {
  const result: PathAction[] = [];
  if (isPathAction(action)) {
    result.push(action);
  } else if (isBatchAction(action)) {
    for (const a of action.payload) {
      result.push(...extractAllPathActions(a));
    }
  }
  return result;
}

export function getSingleAction(actions: BaseAction[]): BaseAction {
  if (actions.length === 1) {
    return actions[0];
  }
  return {
    type: ActionType.BATCH_ACTION,
    payload: actions
  } as BatchAction;
}
