<ng-container *ngIf="instanceObs | async as instance">
  <mat-dialog-content class="connect_to_instance_dialog" data-cy="connect-to-instance-dialog">
    <ng-container *ngVar="passwordObs | async as password">
      <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
      <div class="left">
        <cp-dialog-title [dialogTitle]="'Connect to '+instance.name"></cp-dialog-title>
        <cp-connect-to-instance-dialog-content
          [instanceId]="instanceId"
          [username]="instance.dbUsername"
          [password]="password"
        ></cp-connect-to-instance-dialog-content>

        <div class="buttons">
          <button (click)="dialogRef.close()" class="secondary_action" data-cy="close-button" mat-stroked-button
          >Close
          </button>
          <ng-container *ngVar="isEnabled(instance) as isEnabled">
            <a
              (click)="reportSqlConsoleClicked()"
              [disabled]="!isEnabled"
              [href]="getWebConsoleUri(instance)"
              class="button connect_button"
              mat-stroked-button
              data-cy="connect-to-sql-button"
              [matTooltip]="getDisabledTooltip(instance.state)"
              matTooltipPosition="below"
              [matTooltipDisabled]="isEnabled"
              target="_blank"
            >
            Open SQL console
          </a>
        </ng-container>
        </div>
      </div>
      <cp-instance-side-details [instance]="instance"
                             class="instance_side_details"></cp-instance-side-details>
    </ng-container>
  </mat-dialog-content>
</ng-container>
