import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cp-send-org-invite-confirm-dialog',
  templateUrl: './send-org-invite-confirm-dialog.component.html',
  styleUrls: ['./send-org-invite-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendOrgInviteConfirmDialogComponent {
  constructor(public readonly dialogRef: MatDialogRef<SendOrgInviteConfirmDialogComponent>) {}
}
