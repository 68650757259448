import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GalaxyEventType } from '@cp/common/protocol/Galaxy';
import { ALL_COMPANY_SIZE_SET } from '@cp/common/protocol/Organization';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy } from '@cp/common/utils/Assert';
import { AdminBillingStateService } from '@cp/web/app/admin/admin-billing/admin-billing-state.service';
import { FullyQualifiedEvent, FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import {
  BillingConversionUiState,
  CompanyDetailsChange
} from '@cp/web/app/organizations/tackle-firmographics/tackle-firmographics.component';

@Component({
  selector: 'cp-billing-company-details-onboarding',
  templateUrl: './change-billing-company-details-onboarding.component.html',
  styleUrls: ['./change-billing-company-details-onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Form component containing company-related information (company name, taxId, firmographics).
 * This component doesn't save the input. Instead, it passes it back to its caller to handle that "external" logic.
 * See designs: https://www.figma.com/file/i00FDyul4FXB8thLi0Dqfl/Beta?node-id=4239%3A28288
 */
export class ChangeBillingCompanyDetailsOnboardingComponent implements OnInit {
  companyDetailsForm: FormGroup;

  companySizeOptions: Array<SeedSelectOption> = Array.from(ALL_COMPANY_SIZE_SET).map((value) => ({
    label: `${value.replace('-', ' - ')} employees`,
    value,
    dataCy: 'company-size-option',
    dataCyValue: `company-size-option-${value}`
  }));

  @Input()
  formInput?: BillingConversionUiState;

  @Input()
  isCancelable = true;

  @Input()
  customSubmitText?: string;

  @Input()
  isTaxIdShown = true;

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  @Output()
  companyDetailsChange = new EventEmitter<CompanyDetailsChange>();

  @Output()
  backButtonClick = new EventEmitter<CompanyDetailsChange>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly adminBillingStateService: AdminBillingStateService,
    private readonly organizationStateService: OrganizationStateService
  ) {
    // TODO(1455) Remove state services from ChangeBillingCompanyDetailsComponent
    const currentFirmographics = this.organizationStateService.getCurrentOrgOrFail().firmographics;
    const currentAddress = this.adminBillingStateService.getAddress();
    const currentMetadata = this.adminBillingStateService.getMetadata();
    this.companyDetailsForm = this.formBuilder.group({
      companyName: [currentAddress?.companyName ?? '', [Validators.required]],
      taxId: [currentMetadata?.taxId ?? '', []],
      websiteUrl: [currentFirmographics?.websiteUrl ?? '', [Validators.required]],
      companySize: [currentFirmographics?.companySize ?? '', [Validators.required]]
    });
  }

  ngOnInit(): void {
    assertTruthy(this.eventPrefix);
  }

  async onSubmit(): Promise<void> {
    if (this.companyDetailsForm.invalid) {
      return;
    }
    this.companyDetailsChange.emit({
      companyName: this.companyDetailsForm.value.companyName,
      taxId: this.companyDetailsForm.value.taxId,
      websiteUrl: this.companyDetailsForm.value.websiteUrl,
      companySize: this.companyDetailsForm.value.companySize
    });
  }

  handleBackButton() {
    this.backButtonClick.emit({
      companyName: this.companyDetailsForm.value.companyName,
      taxId: this.companyDetailsForm.value.taxId,
      websiteUrl: this.companyDetailsForm.value.websiteUrl,
      companySize: this.companyDetailsForm.value.companySize
    });
  }

  buildFullyQualifiedEvent(name: GalaxyEventType): FullyQualifiedEvent {
    return `${this.eventPrefix}.${name}`;
  }
}
