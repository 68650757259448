import { StageType } from '@cp/common/protocol/Common';
import { beforeSendSentryDedupFilter } from '@cp/web/app/common/utils/SentryWebUtils';
import { ControlPlaneWebEnvironment } from '@cp/web/environments/types';
import { CONTROL_PLANE_WEB_RELEASE_VERSION } from '@cp/web/environments/versions';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

/** Re-usable stage constant used in subcomponent configs. */
const STAGE: StageType = 'dev';

/** Customer facing URL of the Control Plane web application. */
const FRONTEND_URL = 'https://control-plane.clickhouse-dev.com';

/** Staging environment for QA testing before releasing to Prod. */
export const environment: ControlPlaneWebEnvironment = {
  cognitoUserPoolId: 'us-east-2_bPD9Bv8J4',
  cognitoUserPoolWebClientId: '17pm32oa9n8g6dmk3keiok4b7l',
  cognitoDomain: 'qa-auth.control-plane.clickhouse-dev.com',
  webUrl: 'https://control-plane.clickhouse-dev.com',
  region: 'us-east-2',
  stage: STAGE,
  apiUrl: 'https://control-plane.clickhouse-dev.com/api/',
  websocketsEndpoint: 'wss://o8hydlur88.execute-api.us-east-2.amazonaws.com/dev',
  segmentKey: 'xw3ITYuF8XCD5W6lQqtUB1K7lXw2LKEG',
  sentryConfig: {
    dsn: 'https://d4c2544d94c84927958660fbe5729ef0@o1131410.ingest.sentry.io/6175856',
    release: `${CONTROL_PLANE_WEB_RELEASE_VERSION}`,
    environment: STAGE,
    integrations: [
      // Performance tracing. Traces all client→server requests  + Angular routing events.
      // See https://docs.sentry.io/platforms/javascript/guides/angular/performance/instrumentation/automatic-instrumentation/.
      new BrowserTracing({
        tracingOrigins: [FRONTEND_URL],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],

    // 50% of traces are sent to the Sentry in 'dev' stage.
    tracesSampleRate: 0.5,

    // Sentry error handler options. Built on top of the AngularErrorHandler
    // See https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts for details.
    errorHandlerOptions: {
      // Do not show a customer facing "Submit feedback" dialog on errors.
      showDialog: false
    },
    beforeSend: beforeSendSentryDedupFilter
  },
  strapiBaseUrl: 'https://cms.clickhouse-dev.com:1337',
  stripePublishableKey:
    'pk_test_51LN1y0IAAcceGFTiOfiqg5WI730XSyPnbyrEsg61MBGOa6ppYGMv78BGU8s2B8PNzRVUOYaXoLQ8isKFkWT8o4s800F2dGAF1q',
  recaptchaSiteKey: '6Lf3eY4hAAAAADHuCPaw1RzIqbyTLm_b5fqWna3B',
  isSocialSignUpEnabled: true,
  consoleUrl: 'https://console.clickhouse-dev.com',
  awsMarketplaceProdViewId: 'gsdi4fxwso4i2',
  fullStoryOrgId: 'o-1GR1DR-na1',
  matrixLmsLoginEndpoint:
    'https://clickhouse-dev.okta.com/home/clickhouse-dev_matrixlms_1/0oa3g4mbywmPexb7x697/aln3g4sd56w3qT7SX697',
  auth0ClientOptions: {
    domain: 'ch-qa.us.auth0.com',
    customDomain: 'auth.control-plane.clickhouse-dev.com',
    clientId: 'lkZkzWIh1lRuv4v6pwbPj9XvwFOsBR6L'
  },
  auth0DatabaseConnectionName: 'ControlPlaneUserDb',
  datadogConfig: {
    rum: {
      applicationId: '30da0aec-e2b8-4a84-925d-8309a3665c30',
      clientToken: 'pub8ddb0bd629702d9871070543566f2a41',
      site: 'us3.datadoghq.com',
      service: 'control-plane',
      env: STAGE,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: CONTROL_PLANE_WEB_RELEASE_VERSION,
      sampleRate: 100,
      sessionReplaySampleRate: 20,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      startSessionReplayRecording: true
    },
    logs: {
      clientToken: 'pub8ddb0bd629702d9871070543566f2a41',
      site: 'us3.datadoghq.com',
      service: 'control-plane',
      env: STAGE,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      telemetrySampleRate: 0,
      forwardConsoleLogs: ['error']
    }
  }
};
