import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cp-wake-idle-services-dialog',
  templateUrl: './wake-idle-services-dialog.component.html',
  styleUrls: ['./wake-idle-services-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WakeIdleServicesDialogComponent {
  constructor(private readonly dialogRef: MatDialogRef<WakeIdleServicesDialogComponent>) {}

  cancel() {
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.dialogRef.close('confirm');
  }
}
