<div *ngIf="caseDetailsObs | async as details" class="support_case_page">
  <div class="side_padding">
    <div class="nav">
      <div class="left">
        <div class="title">Support</div>
      </div>

      <div class="right">
        <button (click)="showCreateSupportCaseDialog()" class="button support_button" color="primary"
                mat-flat-button>
          <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
          New case
        </button>
      </div>
    </div>

    <div class="timeline_and_details_container">
      <div class="left">
        <div class="subject">{{details.subject}}</div>
        <div class="date">Sent {{details.lastModifiedDate | date: 'medium'}}</div>
        <div class="description_container">
          <span #supportDescriptionText class="description" [class.show-less]="descriptionExpanded === false">
            {{details.description}}
          </span>
          <span (click)="descriptionExpanded = !descriptionExpanded"
                *ngIf="
      supportDescriptionText.offsetHeight < supportDescriptionText.scrollHeight ||
      (descriptionExpanded === true && supportDescriptionText.offsetHeight > 40)"
                class=" expand_or_collapse">{{descriptionExpanded ? 'Collapse' : 'Read more'}}</span>
        </div>

        <cp-case-timeline (replyClicked)="showReplyDialog()"
                          [caseDetails]="details"
                          [showReplyButton]="true">
        </cp-case-timeline>
      </div>

      <div class="right">
        <div class="title">Case details</div>
        <div class="details_field_and_value">
          <div class="field">CASE NUMBER</div>
          <div class="value">{{details.caseNumber}}</div>
        </div>
        <div *ngIf="details.primaryClickHouseContactName" class="details_field_and_value">
          <div class="field">PRIMARY CLICKHOUSE CONTACT</div>
          <div class="value">{{details.primaryClickHouseContactName}}</div>
        </div>
        <div *ngIf="instanceObs | async as instance" class="details_field_and_value">
          <div class="field">CLOUD SERVICE</div>
          <div class="value"><a [routerLink]="'/service/'+instance.id">{{instance.name}}</a></div>
        </div>
        <div *ngIf="supportCaseStatusDisplay[details.status] as displayStatus" class="details_field_and_value">
          <div class="field">STATUS</div>
          <div class="value status_value">
            <div [ngClass]="displayStatus.className" class="indicator"></div>
            {{displayStatus.text}}</div>
        </div>
        <div class="details_field_and_value">
          <div class="field">CREATED ON</div>
          <div class="value">{{details.createdOn | approximateTime: 'medium'}}</div>
        </div>
        <div class="details_field_and_value">
          <div class="field">LAST UPDATED</div>
          <div class="value">{{details.lastModifiedDate | approximateTime: 'medium'}}</div>
        </div>
        <div *ngIf="details.additionalEmails?.length" class="details_field_and_value">
          <div class="field">SHARED WITH</div>
          <div class="value">
            <div *ngFor="let email of details.additionalEmails" class="email fs-exclude">{{email}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
