<form [formGroup]="companyDetailsForm" class="form" (ngSubmit)="onSubmit()"
      data-cy="change-billing-company-details-form"
>
  <div class="scrollable_wrapper padded_content">

    <div class="organization_type_buttons_block seed_form_element">
      <div class="organization_type_buttons_row">
        <label>I am a</label>
        <button
          [galaxyClick]="['billing.modal.infoTypeSelect', {option: 'business'}]"
          (click)="enableOrDisableOrgAsBusiness(true)"
          mat-stroked-button
          class="left_button"
          [ngClass]="this.organizationIsABusiness ? 'selected': ''"
          name="organization-is-a-business-button"
          data-cy="organization-is-a-business-button"
          type="button"
        >Business
        </button>

        <button
          [galaxyClick]="['billing.modal.infoTypeSelect', {option: 'individual'}]"
          (click)="enableOrDisableOrgAsBusiness(false)"
          mat-stroked-button
          class="right_button"
          [ngClass]="!this.organizationIsABusiness ? 'selected': ''"
          name="organization-is-a-individual-button"
          data-cy="organization-is-an-individual-button"
          type="button"
        >Individual
        </button>
      </div>
    </div>

    <ng-container *ngIf="organizationIsABusiness">
      <div class="seed_form_element">
        <label>Company or organization</label>
        <input
          spellcheck="false"
          [galaxyClick]="buildFullyQualifiedEvent('bizNameInputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('bizNameInputBlur')"
          formControlName="companyName"
          name="companyName"
          data-cy="company-name">

        <div class="seed_error"
             *ngIf="!companyDetailsForm.controls['companyName']?.valid && companyDetailsForm.controls['companyName']?.touched">
          Please enter a valid company name
        </div>
      </div>

      <div class="split_row">
        <div class="seed_form_element website_url_form_element">
          <label>Website</label>
          <input
            spellcheck="false"
            [galaxyClick]="buildFullyQualifiedEvent('bizSiteInputFocus')"
            [galaxyBlur]="buildFullyQualifiedEvent('bizSiteInputBlur')"
            formControlName="websiteUrl"
            name="websiteUrl"
            placeholder="https://"
            data-cy="website-url">
          <div class="seed_error"
               *ngIf="!companyDetailsForm.controls['websiteUrl']?.valid && companyDetailsForm.controls['websiteUrl']?.touched">
            Please enter a valid URL
          </div>
        </div>

        <div class="seed_form_element company_size_form_element">
          <label>Number of employees</label>
          <mat-form-field appearance="outline" class="company_size_select">
            <mat-select
              [galaxyClick]="buildFullyQualifiedEvent('bizSizeSelectorFocus')"
              [galaxyBlur]="buildFullyQualifiedEvent('bizSizeSelectorBlur')"
              [galaxyChange]="buildFullyQualifiedEvent('bizSizeSelectorSelect')"
              formControlName="companySize"
              data-cy="company-size-select"
              [typeaheadDebounceInterval]="500">
              <mat-option *ngFor="let companySizeOption of companySizeOptions"
                          [value]="companySizeOption.value"
                          [attr.data-cy]="companySizeOption.dataCy"
                          [attr.data-cy-value]="companySizeOption.dataCyValue"
              >{{companySizeOption.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="seed_error"
               *ngIf="!companyDetailsForm.controls['companySize']?.valid && companyDetailsForm.controls['companySize']?.touched">
            Please select company size
          </div>
        </div>
      </div>

      <div *ngIf="organizationIsABusiness && isTaxIdShown && stripeSupportsCountry()" class="split_row">
        <div class="seed_form_element tax_id_type_form_element">
          <label>Tax ID type</label>
          <mat-form-field appearance="outline" class="tax_id_type_select">
            <mat-select
              formControlName="taxIdType"
              data-cy="tax_id_type_select"
              [typeaheadDebounceInterval]="500">
              <mat-option *ngFor="let taxIdTypeOption of taxIdTypeOptions"
                          [value]="taxIdTypeOption.value"
                          [attr.data-cy]="taxIdTypeOption.dataCy"
                          [attr.data-cy-value]="taxIdTypeOption.dataCyValue"
              >{{taxIdTypeOption.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="seed_error"
               *ngIf="!companyDetailsForm.controls['taxIdType']?.valid && companyDetailsForm.controls['taxIdType']?.touched && stripeSupportsCountry()">
            Please select tax ID type
          </div>
        </div>
      </div>

      <div *ngIf="organizationIsABusiness && hasTaxId()" class="seed_form_element">
        <label>Tax ID number</label>
        <input
          spellcheck="false"
          [galaxyClick]="buildFullyQualifiedEvent('bizTaxInputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('bizTaxInputBlur')"
          data-cy="tax-id"
          formControlName="taxId"
          name="taxId"
          placeholder="{{taxIdExample()}}">
        <div class="seed_error"
             *ngIf="!companyDetailsForm.controls['taxId']?.valid && companyDetailsForm.controls['taxId']?.touched && stripeSupportsCountry() && hasTaxId()">
          Please enter a valid tax ID number
        </div>
      </div>
    </ng-container>

    <cw-system-message *ngIf="formInput?.errorMessage || taxIdInfoMessage()"
                       [message]="formInput?.errorMessage || taxIdInfoMessage()"
                       [messageType]="formInput?.errorMessage ? 'error' : 'info' "
    ></cw-system-message>
  </div>

  <div class="vertical_buttons padded_content">
    <button
      [disabled]="(organizationIsABusiness && companyDetailsForm.invalid) || formInput?.buttonDisabled"
      class="main_action"
      color="primary" data-cy="company-details-update-button" mat-flat-button
      type="submit"
    >{{ customSubmitText || 'Proceed to payment details >' }}
    </button>
    <button
      galaxyClick="billing.modal.backButtonSelect"
      data-cy="company-details-back-button"
      (click)="handleBackButton()" class="secondary_action" mat-stroked-button>Back</button>
  </div>
</form>
