import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrgInviteExpiredDialogComponent } from '@cp/web/app/organizations/org-invite-expired-dialog/org-invite-expired-dialog.component';

@Component({
  selector: 'cp-org-invite-expired',
  templateUrl: './org-invite-expired.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgInviteExpiredComponent implements OnInit {
  constructor(private readonly dialog: MatDialog) {}

  ngOnInit(): void {
    OrgInviteExpiredDialogComponent.show(this.dialog);
  }
}
