import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  buildAllowListCurrentIpDescription,
  Instance,
  IpAccessListEntry,
  isAllowAnywhere
} from '@cp/common/protocol/Instance';
import { isIpAccessSource } from '@cp/common/utils/ValidationUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-first-time-set-ip-access-list',
  templateUrl: './first-time-set-ip-access-list.component.html',
  styleUrls: ['./first-time-set-ip-access-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstTimeSetIpAccessListComponent extends OnDestroyComponent implements OnInit {
  /** Current state of the IP access list. */
  ipAccessList: Array<IpAccessListEntry> = [];
  instanceObs!: Observable<Instance | undefined>;

  @Input()
  instanceId!: string;

  /** IP access list used as an initial value. Read only during the component creation. */
  @Input()
  initialIpAccessList: Array<IpAccessListEntry> = [];

  /** Emits list of IP access list entries  or 'true' in case of anywhere. */
  @Output()
  ipAccessListChange = new EventEmitter<Array<IpAccessListEntry> | true>();

  activeTab: 'NOT_SELECTED' | 'SPECIFIC_LOCATION' | 'ANYWHERE' = 'NOT_SELECTED';

  constructor(
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceUiService: InstanceUiService,
    private readonly accountStateService: AccountStateService,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.instanceObs = this.instanceStateService.observeInstance(this.instanceId);
    this.ipAccessList = this.initialIpAccessList;
    if (this.ipAccessList.length > 0) {
      this.activeTab = isAllowAnywhere(this.ipAccessList) ? 'ANYWHERE' : 'SPECIFIC_LOCATION';
    }
  }

  /** Switches to 'this.ipAccessList'. If 'this.ipAccessList' is empty adds current user IP if available. */
  onSpecificLocationOptionClicked(): void {
    if (this.ipAccessList.length === 0) {
      const ipAddress = this.accountStateService.getUserDetailsOrFail().ipAddress || '';
      if (isIpAccessSource(ipAddress)) {
        const user = this.accountStateService.getUserDetailsOrFail();
        this.ipAccessList.push({ source: ipAddress, description: buildAllowListCurrentIpDescription(user.name) });
        this.ipAccessListChange.emit([...this.ipAccessList]);
      }
    } else {
      this.ipAccessListChange.emit([...this.ipAccessList]);
    }
    this.activeTab = 'SPECIFIC_LOCATION';
  }

  onAnywhereOptionClicked(): void {
    this.activeTab = 'ANYWHERE';
    this.ipAccessListChange.emit(true);
    this.cdr.markForCheck();
  }

  onIpAccessListChange($event: Array<IpAccessListEntry>) {
    this.ipAccessList = $event;
    this.ipAccessListChange.emit([...this.ipAccessList]);
  }
}
