export type StageType = 'local' | 'dev' | 'staging' | 'production';

export type Replace<TypeToBeChecked, KeyToBeReplaced extends keyof TypeToBeChecked, NewValueToUse> = Omit<
  TypeToBeChecked,
  KeyToBeReplaced
> & { [P in KeyToBeReplaced]: NewValueToUse };

/** Makes a key optional. For example PartialBy<ClassA, 'key1'> will make key1 optional in ClassA. */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/** Makes all keys except those specified optional. For example RequireOnly<ClassA, 'key1'> will make key1 required in ClassA and all else optional. */
export type RequireOnly<T, K extends keyof T> = Pick<T, K> & Partial<T>;

/**
 *  Returns true if the value is not 'undefined'.
 *  Note: use isTruthy to filter values like null, empty strings and objects.
 */
export function isDefined<T>(input?: T): input is T {
  return input !== undefined;
}

/** Inference functions */
export function isTruthy<T>(input: T | undefined | null): input is T {
  return Boolean(input);
}

export type Primitive = string | number | boolean | bigint | symbol | undefined | null;
export type Builtin = Primitive | Date | Error | RegExp;

export type DeepReadonly<T> = T extends Builtin
  ? T
  : T extends Array<infer R>
  ? ReadonlyArray<DeepReadonly<R>>
  : T extends object
  ? DeepReadonlyObject<T>
  : Readonly<T>;

type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export type DeepReadonlyArray<T> = DeepReadonly<Array<T>>;
