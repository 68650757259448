import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cp-verification-email-message',
  templateUrl: './verification-email-message.component.html',
  styleUrls: ['./verification-email-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationEmailMessageComponent {
  @Input()
  userId?: string;

  @Input()
  customMessage?: string;

  @Input()
  email?: string;
}
