<ng-container *ngIf="userObs | async as user">
  <ng-container *ngIf="myOrgUserObs | async as myOrgUser">
    <ng-container *ngIf="organizationObs | async as organization">
      <ng-container *ngIf="numberOfAdminsObs | async as numberOfAdmins">

        <div class="settings_wrapper">
          <div class="settings_table">
            <div class="row seed_form_element">
              <label>Organization Name</label>
              <div class="org_name_row">
                <div class="seed_input fs-exclude" data-cy="org-name-cell">{{organization.name}}</div>
                <button mat-button (click)="showChangeOrganizationNameDialog()" *ngIf="myOrgUser.role === 'ADMIN'"
                        class=" edit_name_button"
                        data-cy="edit-org-name-button"
                >Edit
                </button>
              </div>
            </div>
            <div class="row seed_form_element">
              <label>Organization ID</label>
              <div class="seed_input" data-cy="org-id-cell">{{getOrgId(hideOrgId, organization.id)}}</div>
              <button (click)="$event.preventDefault(); hideOrgId = !hideOrgId"
                      [attr.aria-label]="'Hide org ID'"
                      [attr.aria-pressed]="!hideOrgId"
                      class="seed_input_icon" mat-icon-button matSuffix>
                <mat-icon [svgIcon]="hideOrgId ? 'show_password_icon' : 'hide_password_icon'"
                          data-cy="org-id-reveal-icon" class="org_id_reveal_icon"></mat-icon>
              </button>
            </div>

            <div class="row buttons">
              <button [matMenuTriggerFor]="adminGeneralActions"
                      mat-flat-button
                      class="grey_button"
                      data-cy="toggle-admin-actions-dropdown-button"
                      *ngIf="checkIfActionsButtonVisible(numberOfAdmins, myOrgUser.role)"
              >Actions
                <mat-icon svgIcon="down_arrow" class="icon"></mat-icon>
              </button>

              <mat-menu #adminGeneralActions="matMenu" xPosition="before" data-cy="admin-actions-dropdown">
                <div class="menu_buttons">
                  <button (click)="confirmDeleteOrganization()"
                          class="menu_button"
                          data-cy="delete-button"
                          mat-menu-item
                          *ngIf="checkIfDeleteOrganizationButtonVisible(myOrgUser.role)"
                  >Delete organization
                  </button>
                  <button (click)="confirmLeaveOrganization()"
                          class="menu_button"
                          data-cy="leave-button"
                          mat-menu-item
                          *ngIf="checkIfLeaveOrganizationButtonVisible(numberOfAdmins, myOrgUser.role)"
                  >Leave organization
                  </button>
                </div>
              </mat-menu>

              <button (click)="showCreateOrganizationDialog()"
                      *ngIf="user.restrictions.canCreateOrganizations"
                      class="grey_button"
                      data-cy="new-org-button"
                      mat-flat-button
              >New organization
              </button>
            </div>

            <div class="row created_at">
              Created On: {{organization.createdAt | date: 'medium'}}
            </div>
          </div>

        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
