import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { truthy } from '@cp/common/utils/Assert';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { ResetInstancePasswordUiState } from '@cp/web/app/instances/protocol/InstanceStates';
import { ResetInstancePasswordUiService } from '@cp/web/app/instances/reset-instance-password-dialog/reset-instance-password-ui.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-reset-instance-password-dialog',
  templateUrl: './reset-instance-password-dialog.component.html',
  styleUrls: ['./reset-instance-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetInstancePasswordDialogComponent {
  readonly uiStateObs: Observable<ResetInstancePasswordUiState>;
  readonly username: string;
  private readonly instanceId: string;
  private readonly showPasswordAfterWord: boolean;

  constructor(
    public dialogRef: MatDialogRef<ResetInstancePasswordDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    private readonly resetInstancePasswordUiService: ResetInstancePasswordUiService,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceUiService: InstanceUiService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { instanceId: string; username: string; showPasswordAfterward: boolean }
  ) {
    this.instanceId = truthy(data.instanceId);
    this.username = truthy(data.username);
    this.showPasswordAfterWord = data.showPasswordAfterward;
    this.uiStateObs = resetInstancePasswordUiService.observeResetInstancePasswordUiState();
    this.resetInstancePasswordUiService.setPartialState({
      resetPasswordButtonDisabled: false,
      errorMessage: undefined
    });
  }

  async resetInstancePassword(): Promise<void> {
    this.resetInstancePasswordUiService.setPartialState({ resetPasswordButtonDisabled: true, errorMessage: undefined });
    try {
      await this.instanceService.resetPassword(this.instanceId);
      if (this.showPasswordAfterWord) {
        this.instanceUiService.showConnectDialog(this.instanceId, true);
      }
      this.snackBar.open('Service user password reset', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e: any) {
      this.resetInstancePasswordUiService.setStateKey(
        'errorMessage',
        'Error when resetting service user password, please try again later'
      );
    } finally {
      this.resetInstancePasswordUiService.setStateKey('resetPasswordButtonDisabled', false);
    }
  }
}
