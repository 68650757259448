<div [style.border-left-color]="sideBarColor || null" class="username_and_password_container">
  <div class="left">
    <div class="credential_box">
      <mat-icon
        (click)="copyUserNameToClipboard(username)" class="copy_icon svg_fill" svgIcon="copy"
        title="Copy to clipboard">
      </mat-icon>
      <div class="name">{{usernameLabel}}</div>
      <input readonly type="text" [value]="username" class="value fs-exclude" autocomplete="off"/>
    </div>
    <div class="credential_box">
      <mat-icon
        (click)="copyPasswordToClipboard(password)"
        class="copy_icon svg_fill" svgIcon="copy"
        title="Copy to clipboard">
      </mat-icon>
      <mat-icon
        (click)="togglePassword()"
        class="password_icon svg_stroke" [svgIcon]="showPassword ? 'eye-closed' : 'eye'"
        [title]="showPassword ? 'Hide password' : 'Show password'">
      </mat-icon>
      <div class="name">{{passwordLabel}}</div>
      <input readonly [type]="showPassword ? 'text' : 'password'" [value]="password" class="value fs-exclude password"
             autocomplete="off"/>
    </div>
  </div>
  <div
    (click)="downloadCredentials()" class="right" title="Download credentials">
    <mat-icon class="icon svg_fill" svgIcon="download"></mat-icon>
  </div>
</div>
