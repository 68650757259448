import { ActivatedRoute, Router } from '@angular/router';
import { assertTruthy } from '@cp/common/utils/Assert';
import { getPathWithNoQueryPartFromUrl, getQueryPartFromUrl } from '@cp/common/utils/MiscUtils';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { filter, Observable, of, skip, skipUntil, takeUntil } from 'rxjs';

/** Parameters for 'installOrganizationIdPageParameterHandler'. */
export interface PageComponentWithOrganizationId {
  route: ActivatedRoute;
  router: Router;
  organizationStateService: OrganizationStateService;
  /** onDestroy observable for the component. */
  onDestroy: Observable<void>;
  buildCanonicalPagePathWithOrganizationId: (id: string, currentRouterUrl: string) => string;
}

/**
 * Parses 'organizationId' from URL and sets it as a currentOrganizationId.
 * Redirects to the correct organization page URL if needed (uses 'buildPageUrlWithOrganizationId').
 * If there is no 'organizationId' URL parameter - uses 'getCurrentOrgId' for the URL/redirect logic.
 *
 * The method must be called when user organizations are resolved from an organization page level component.
 *
 * Returns 'true' if there was no redirect as the result of the method call.
 * Returns 'false' if there was a redirect to the correct organization page URL or a fallback (services) page.
 */
export function installOrganizationIdPageParameterHandler({
  route,
  router,
  organizationStateService,
  onDestroy,
  buildCanonicalPagePathWithOrganizationId
}: PageComponentWithOrganizationId): boolean {
  const organizationIdParam = route.snapshot.paramMap.get('organizationId');
  const currentOrganizationId = organizationStateService.getCurrentOrgIdOrFail();
  const currentPath = () => getPathWithNoQueryPartFromUrl(router.url);

  // Listen and redirect to the current URL when organization id changes in the future.
  // This subscription does not handle 'organizationIdParam' redirect (it is handled in the code below) because
  // 1) when redirect is done in the subscription we do not preserve query params.
  // 2) if the url is already correct and organizationIdParam == currentOrganizationId we do not need to redirect at all.
  const currentOrgSubscription = organizationStateService
    .observeCurrentOrganizationId()
    .pipe(
      // Skip all events and do not redirect until switched to the requested organization id.
      skipUntil(
        organizationIdParam
          ? organizationStateService.observeCurrentOrganizationId().pipe(filter((id) => id === organizationIdParam))
          : of(null) // no 'organizationIdParam' -> no wait.
      ),
      // Next skip the 'current organization id' (handled in the cod ebelow) event and
      // handle all next changes of the organization id.
      skip(1),
      // Stop redirect management when the component is destroyed.
      takeUntil(onDestroy)
    )
    .subscribe((organizationId) => {
      const urlWithOrgId = organizationId
        ? // Do not append query part to the path, so switch results to the default page state.
          // Reason: not all query parts are cross-org safe, example: billing date.
          buildCanonicalPagePathWithOrganizationId(organizationId, currentPath())
        : 'services';
      console.debug(
        'installOrganizationIdPageParameterHandler: redirect to new page on organization id change',
        urlWithOrgId
      );
      router.navigate([urlWithOrgId]).then();
    });

  // Check if this is an old-style page with no organization id in the URL. Redirect to the URL with organizationId.
  if (!organizationIdParam) {
    const urlWithOrgId =
      buildCanonicalPagePathWithOrganizationId(currentOrganizationId, currentPath()) + getQueryPartFromUrl(router.url);
    console.debug('installOrganizationIdPageParameterHandler: redirect with currentOrganizationId', urlWithOrgId);
    router.navigate([urlWithOrgId]).then();
    return false;
  }
  // Check if the current URL contains organizationId and the organizationId is current.
  if (organizationIdParam === currentOrganizationId) {
    console.debug(
      `installOrganizationIdPageParameterHandler: no-op, already on the current organization id: ${currentOrganizationId}`
    );
    return true;
  }
  // Check if there is an 'organizationIdParam' and the organizationId is not current. Redirect to the current.
  const userOrganizations = organizationStateService.getOrganizations();
  assertTruthy(Object.keys(userOrganizations).length > 0, 'The method must be called after organizations are resolved');
  if (!userOrganizations[organizationIdParam]) {
    console.warn('installOrganizationIdPageParameterHandler: got unknown organization id', organizationIdParam);
    router.navigate(['services']).then();
    currentOrgSubscription.unsubscribe();
    return false;
  }
  console.debug('installOrganizationIdPageParameterHandler: switch to the organization id', organizationIdParam);
  organizationStateService.switchOrganization(organizationIdParam);
  return false;
}

/** Checks that the path starts with 'pagePathWithNoId' or looks like /{prefix}/$id/{suffix}. */
export function checkIfPathBelongsToPage(
  path: string,
  prefix: string,
  suffix: string,
  pagePathWithNoId: string
): boolean {
  if (!path.startsWith('/')) return false;
  if (path.startsWith(pagePathWithNoId)) return true;
  const pathWithNoParams = getPathWithNoQueryPartFromUrl(path);
  const tokens = pathWithNoParams.split('/');
  if (tokens[1] !== prefix) {
    return false;
  }
  let pathSuffix = tokens[3];
  for (let i = 4; i < tokens.length; i++) {
    pathSuffix += `/${tokens[i]}`;
  }
  return suffix === pathSuffix;
}
