import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@cp/web/app/account/account.service';
import { AuthenticatorAppSetUpDialogComponent } from '../authenticator-app-set-up-dialog/authenticator-app-set-up-dialog.component';

@Component({
  templateUrl: './mfa-update-dialog.component.html',
  styleUrls: ['./mfa-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaUpdateDialogComponent {
  static isOpened = false;
  errorMessage?: string;
  loading = false;

  constructor(
    public readonly dialogRef: MatDialogRef<MfaUpdateDialogComponent>,
    private readonly dialog: MatDialog,
    private readonly accountService: AccountService
  ) {}

  static show(dialog: MatDialog): void {
    if (this.isOpened) {
      // Make sure we open the dialog only once.
      return;
    }
    dialog.open(MfaUpdateDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      restoreFocus: false,
      panelClass: 'modal_no_padding',
      disableClose: true,
      autoFocus: false
    });
    this.isOpened = true;
  }

  isLoading(): boolean {
    return this.loading;
  }

  async showMfaPopup(): Promise<void> {
    try {
      this.loading = true;
      await this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['mfa-update'] });
      AuthenticatorAppSetUpDialogComponent.show(this.dialog);
      this.dialogRef.close();
    } finally {
      this.loading = false;
    }
  }

  cancel(): void {
    try {
      this.loading = true;
      this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['mfa-update'] });
      this.dialogRef.close();
    } finally {
      this.loading = false;
    }
  }
}
