<mat-dialog-content *ngIf="uiStateObs | async as uiState" class="change_organization_name_dialog"
                    data-cy="rename-organization-dialog"
>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <cp-dialog-title dialogTitle="Organization Name"></cp-dialog-title>
  <form (ngSubmit)="onSubmit()" [formGroup]="changeOrganizationNameForm" class="form">
    <div class="seed_form_element">
      <label>Organization name</label>
      <input data-cy="organization-name-input" formControlName="name" name="name" placeholder="" spellcheck="false">
      <div
        *ngIf="!changeOrganizationNameForm.controls['name'].valid && changeOrganizationNameForm.controls['name'].touched"
        class="seed_error">
        Please enter a valid organization name
      </div>
    </div>

    <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>

    <div class="vertical_buttons">
      <button [disabled]="changeOrganizationNameForm.invalid || uiState.buttonDisabled" class="main_action"
              color="primary" data-cy="update-button" mat-flat-button
              type="submit"
      >Update organization name
      </button>
      <button [mat-dialog-close]="false" class="secondary_action" mat-button>Cancel</button>
    </div>
  </form>
</mat-dialog-content>
