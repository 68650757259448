import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AccountService } from '@cp/web/app/account/account.service';

@Component({
  selector: 'cp-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfServiceComponent {
  hasAcceptedTos = true;
  processingAcceptTos: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly snackBar: MatSnackBar
  ) {}

  onToggle(value: boolean): void {
    this.hasAcceptedTos = value;
  }

  async onContinue(): Promise<void> {
    if (!this.hasAcceptedTos) {
      return;
    }

    this.processingAcceptTos = true;
    try {
      await this.accountService.acceptAccountTOS();
      console.debug('User successfully accepted Terms of Service');

      await this.router.navigateByUrl('/');
    } catch (e) {
      this.snackBar.open('Unable to accept Terms of Service', 'Dismiss', { duration: 5000 });
    }
    this.processingAcceptTos = false;
  }
}
