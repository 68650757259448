import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { IpAccessListEntry } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';
import { Subscription, takeUntil, timer } from 'rxjs';

/** Mode of the AddIpAccessListFormAndTableComponent. */
export type AddIpAccessListFormAndTableComponentMode =
  /** Form AND Table are visible at the same time. Used on 'Security' tab. */
  | 'FORM_AND_TABLE'
  /** Only Form OR Table visible at the given moment of time. Used during instance creation. */
  | 'FORM_OR_TABLE';

@Component({
  selector: 'cp-add-ip-access-list-form-and-table',
  templateUrl: './add-ip-access-list-form-and-table.component.html',
  styleUrls: ['./add-ip-access-list-form-and-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddIpAccessListFormAndTableComponent extends OnDestroyComponent implements OnChanges, OnInit {
  @Input() ipAccessList: Array<IpAccessListEntry> = [];

  @Input() mode: AddIpAccessListFormAndTableComponentMode = 'FORM_AND_TABLE';

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  @Output() ipAccessListChange = new EventEmitter<Array<IpAccessListEntry>>();

  isFormVisible = true;
  isTableVisible = true;

  /** Message shown after entry is added/deleted to the table. */
  statusMessage?: string;

  private resetStatusMessageTimerSubscription!: Subscription;

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    assertTruthy(this.eventPrefix);
  }

  ngOnChanges(changes: SimpleChanges) {
    const modeChange = changes['mode'];
    if (modeChange) {
      if (this.mode === 'FORM_AND_TABLE') {
        this.isFormVisible = true;
        this.isTableVisible = true;
      } else {
        if (modeChange.isFirstChange()) {
          // When component is initialized only table is visible.
          this.switchToTableOnlyMode();
        } else {
          // Keep the form visible if it is visible. The form it may have some user input we do not want to be lost.
          this.isTableVisible = !this.isFormVisible;
        }
      }
    }
  }

  deleteEntry(entry: IpAccessListEntry): void {
    this.ipAccessList = this.ipAccessList.filter((e) => e !== entry);
    this.ipAccessListChange.emit(this.ipAccessList);
    this.updateStatusMessage('Entry removed');
  }

  addEntry(newEntry: IpAccessListEntry): void {
    this.ipAccessList = this.ipAccessList.filter((e) => e.source !== newEntry.source);
    this.ipAccessList.push(newEntry);
    this.ipAccessListChange.emit(this.ipAccessList);
    if (this.mode === 'FORM_OR_TABLE') {
      this.switchToTableOnlyMode();
    }
    this.updateStatusMessage('Entry added');
  }

  handleAddEntryButtonClick(): void {
    assertTruthy(this.mode === 'FORM_OR_TABLE' && this.isTableVisible);
    this.switchToFormOnlyMode();
  }

  handleCancelClickInForm(): void {
    assertTruthy(this.mode === 'FORM_OR_TABLE' && this.isFormVisible);
    this.switchToTableOnlyMode();
  }

  private switchToTableOnlyMode(): void {
    this.isFormVisible = false;
    this.isTableVisible = true;
  }

  private switchToFormOnlyMode(): void {
    this.isTableVisible = false;
    this.isFormVisible = true;
  }

  get isFormOrTableMode(): boolean {
    return this.mode === 'FORM_OR_TABLE';
  }

  private updateStatusMessage(message: string): void {
    this.statusMessage = message;
    this.cdr.markForCheck();
    if (this.resetStatusMessageTimerSubscription) {
      this.resetStatusMessageTimerSubscription.unsubscribe();
    }
    this.resetStatusMessageTimerSubscription = timer(3000)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.statusMessage = undefined;
        this.cdr.markForCheck();
      });
  }
}
