<div *ngIf="myOrgRoleObs | async as myOrgRole" class="instances">
  <div *ngIf="instancesObs | async as instances" class="side_padding">
    <ng-container *ngVar="regionBlockedObs | async as blocked">
      <ng-container *ngIf="blocked">
        <div class="region_block_container">
          <div class="region_block_box">
            <div data-cy="region-block-message">
              <div class="region_content">
                <div class="region_header">ClickHouse Cloud is not available in your country</div>
                <div class="region_text">
                  Service creation is not available in your country which means that you
                  cannot use ClickHouse as a hosted database service.
                </div>
                <div class="region_text">
                  You can still use your account to have access to training.
                </div>
              </div>
              <a href="https://clickhouse.com/clickhouse" target="_blank" class="region_link">
                Try open-source ClickHouse
                <mat-icon class="region_icon" svgIcon="external_link"></mat-icon>
              </a>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!blocked">
        <ng-container *ngIf="instances.length > 0">
          <div class="inner_page_nav">
            <div class="inner_page_title">Services</div>
            <div *ngIf="myOrgRole === 'ADMIN'">
              <button
                galaxyClick="serviceList.header.newServiceButtonSelect"
                (click)="showCreateInstanceDialog()"
                [disabled]="orgBillingStatus === 'NO_TRIAL'"
                class="button new_service_button"
                color="primary"
                data-cy="create-service-button"
                mat-flat-button>
                <mat-icon class="icon svg_fill" svgIcon="plus_icon"></mat-icon>
                New Service
              </button>
            </div>
          </div>
        </ng-container>

        <cp-billing-alert></cp-billing-alert>

        <ng-container *ngIf="upcomingMaintenanceWindowsObs | async as maintenanceWindows">
          <div *ngIf="maintenanceWindows.length > 0"
               class="maintenance_window_message"
               data-cy="maintenance-window-message"
          >
            One or more of your services will enter a scheduled maintenance window for approximately
            {{maintenanceWindows[0].endMaintenanceTime - maintenanceWindows[0].startMaintenanceTime | durationMinutes: false}}
            on {{maintenanceWindows[0].startMaintenanceTime | date: 'MMMM d, y, h:mma'}} (local time).
            <a (click)="openMaintenanceWindowDialog($event)"
               data-cy="maintenance-window-learn-more-button"
               class="maintenance_window_learn_more_button">
              Learn more
            </a>
          </div>
        </ng-container>

        <div class="instance_list" *ngIf="orgBillingStatus">
          <ng-container *ngFor="let instance of instances; trackBy:trackById">
            <ng-container *ngIf="isInstanceUpgradingFromStatus(instance.upgradeStatus)">
              <cp-upgrade-instance-card
                [instance]="instance">
              </cp-upgrade-instance-card>
            </ng-container>
            <cp-instance-card
              [instance]="instance"
              [orgBillingStatus]="orgBillingStatus"
            ></cp-instance-card>
          </ng-container>
        </div>
        <ng-container *ngIf="instances.length === 0">
          <div class="no_instances_message_container" data-cy="no-services-message">
            <mat-icon class="illustration" svgIcon="no_services"></mat-icon>
            <div class="title">You have no services</div>

            <div *ngIf="myOrgRole !== 'ADMIN'" class="text">This organization does not have any services. Ask an
              administrator
              to create one.
            </div>

            <ng-container *ngIf="myOrgRole === 'ADMIN'">
              <div class="text">Get started by creating a high-powered serverless ClickHouse database in minutes.</div>
              <button (click)="showCreateInstanceDialog()"
                      [disabled]="orgBillingStatus === 'NO_TRIAL'"
                      class="button new_service_button"
                      color="primary"
                      data-cy="create-service-button"
                      mat-flat-button>
                <mat-icon class="icon svg_fill" svgIcon="plus_icon"></mat-icon>
                New Service
              </button>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
