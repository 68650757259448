<ng-container *ngIf="myRoleObs | async as myRole">
  <ng-container *ngIf="organizationObs | async as organization">
    <ng-container *ngIf="billingStateObs | async as billingState">
      <ng-container>

        <div *ngIf="checkIfTackleSubscriptionEnabled(organization.billingStatus, organization.paymentState)"
             class="tackle_payment_method_block"
             data-cy="tackle-billing-block"
             [attr.data-cy-tackle-customerId]="organization.tackleState?.customerId"
        >
          <div class="table_headline">Payment details</div>
          <div class="row">
            <ng-container *ngIf="organization.tackleState?.marketplace === 'aws'">
              <mat-icon svgIcon="AWS" class="icon"></mat-icon>
              <a [href]="awsMarketplaceProdPageUrl"
                 class="marketplace_button"
                 data-cy="aws-marketplace-link"
                 target="_blank"
              >
                View in AWS Marketplace
              </a>
            </ng-container>
            <ng-container *ngIf="organization.tackleState?.marketplace === 'gcp'">
              <mat-icon svgIcon="GCP" class="icon"></mat-icon>
              <a [href]="gcpMarketplaceProdPageUrl"
                 class="marketplace_button"
                 data-cy="gcp-marketplace-link"
                 target="_blank"
              >
                View in GCP Marketplace
              </a>
            </ng-container>
          </div>
        </div>

        <ng-container
          *ngIf="!checkIfTackleSubscriptionEnabled(organization.billingStatus, organization.paymentState) &&
          !checkIfBillingHistoryShouldBeVisible(organization.billingStatus, billingState.paymentMethod)"
        >
          <div class="add_payment_method_wrapper">
            <div class="title">Payment method</div>
            <div class="explanation">Add a credit card to continue using ClickHouse once your free trial ends.</div>
            <button (click)="showChangeBillingAddressDialog()" [disabled]="myRole !== 'ADMIN'" class="add_card_button"
                    color="primary"
                    data-cy="add-card-button" mat-flat-button>
              <mat-icon class="icon svg_fill" svgIcon="plus_icon"></mat-icon>
              Credit card
            </button>
          </div>
        </ng-container>

        <ng-container
          *ngIf="checkIfBillingHistoryShouldBeVisible(organization.billingStatus, billingState.paymentMethod)">

          <div class="limited_width">
            <div class="row"
                 *ngIf="!checkIfTackleSubscriptionEnabled(organization.billingStatus, organization.paymentState)">
              <div *ngIf="billingState.nextInvoiceDate && organization.paymentState !== 'TACKLE'"
                   class="seed_form_element">
                <label>Next invoice due</label>
                <div class="seed_input" data-cy="next-invoice-date-cell"
                >{{billingState.nextInvoiceDate | date}}</div>
              </div>

              <div *ngIf="billingState.paymentMethod" class="seed_form_element">
                <label>Payment method</label>
                <div class="edit_button_wrapper">
                  <div class="seed_input fs-exclude" data-cy="existing-payment-method-cell"
                  >**** {{billingState.paymentMethod.last4}}
                    <div [ngClass]="getPaymentMethodBrand()"></div>
                  </div>
                  <button mat-button (click)="showChangeBillingAddressDialog()" [disabled]="myRole !== 'ADMIN'"
                          class="admin_billing_edit_button"
                          data-cy="edit-payment-method-button"
                  >Edit
                  </button>
                </div>
              </div>

              <div *ngIf="!billingState.paymentMethod" class="seed_form_element solo_element">
                <label>Payment method</label>
                <button (click)="showChangeBillingAddressDialog()" [disabled]="myRole !== 'ADMIN'"
                        class="admin_billing_add_button"
                        color="primary"
                        data-cy="add-card-button" mat-flat-button>
                  <mat-icon class="icon svg_fill" svgIcon="plus_icon"></mat-icon>
                  Credit card
                </button>
              </div>
            </div>
          </div>

          <ng-container *ngIf="adminBillingUiStateObs | async as uiState">
            <div *ngIf="billingState.invoices || billingState.billUsageStatements || billingState.creditBalances"
                 class="hairline"></div>

            <div *ngIf="organization.paymentState !== 'TACKLE' && billingState.invoices" class="invoices_section">
              <div class="table_headline">
                Previous invoices
                <a (click)="toggleInvoicesVisibility()" class="link">{{isInvoicesHidden ? 'Show' : 'Hide'}}</a>
              </div>
              <div *ngIf="!isInvoicesHidden" class="billing_table">
                <div class="header_row row invoices_row">
                  <div *ngFor="let field of invoicesHeaderFields" class="seed_h5">
                    {{field}}
                  </div>
                </div>
                <div *ngFor="let invoice of billingState.invoices; trackBy: trackByInvoiceId" class="row invoices_row">
                  <div class="cell">{{parseInvoiceDateRange(invoice)}}</div>
                  <div class="cell fs-exclude">{{invoice.invoiceId}}</div>
                  <div class="cell fs-exclude">{{invoice.amount | currency:invoice.currency}}</div>
                  <div class="cell"><a *ngIf="invoice.billId"
                                       [ngClass]="{link: true, disabled_link: uiState.csvButtonDisabled}"
                                       (click)="downloadStatementCSV(invoice.billId)">
                    CSV
                  </a>
                    <ng-container *ngIf="!invoice.billId">N/A</ng-container>
                  </div>

                  <div data-cy="invoice-status" class="cell">{{invoiceStatusDisplay[invoice.status]}}</div>
                  <div class="cell">
                    <a
                      href="{{invoice.invoicePaymentLink ? invoice.invoicePaymentLink : invoice.invoicePdfDownloadLink}}"
                      target="_blank">{{invoiceStatusDisplay[invoice.status] === 'Unpaid' && invoice.invoicePaymentLink ? 'Pay Invoice' : 'View Invoice'}}</a>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="billingState.creditBalances" class="credits_section">
              <div class="table_headline">
                Credit balances
                <a (click)="toggleCreditsVisibility()" class="link">{{isCreditsHidden ? 'Show' : 'Hide'}}</a>
              </div>
              <div *ngIf="!isCreditsHidden" class="billing_table">
                <div class="header_row row credits_row">
                  <div *ngFor="let field of creditsHeaderFields" class="seed_h5">
                    {{field}}
                  </div>
                </div>
                <div *ngFor="let credit of billingState.creditBalances; trackBy: trackByPeriodDates"
                     class="row credits_row">
                  <div class="cell">{{credit.periodStartDate | date: 'mediumDate'}}</div>
                  <div class="cell">{{credit.periodEndDate | date: 'mediumDate'}}</div>
                  <div class="cell">{{credit.amountTotal | dataSize:'human-readable-number'}}</div>
                  <div class="cell fs-exclude">{{credit.amountRemaining | dataSize:'human-readable-number'}}</div>
                </div>
              </div>
              <div *ngIf="!isCreditsHidden" class="contact_sales_message">
                <button (click)="showCreditsModal()" class="contact_us">Contact us</button>
                to add more credits
              </div>
            </div>

            <div *ngIf="billingState.billUsageStatements" class="usage_section">
              <div class="table_headline">
                Usage statements
                <a (click)="toggleUsageVisibility()" class="link">{{isUsageHidden ? 'Show' : 'Hide'}}</a>
              </div>
              <div *ngIf="!isUsageHidden" class="billing_table">
                <div class="header_row row usage_row">
                  <div *ngFor="let field of usageHeaderFields" class="seed_h5">
                    {{field}}
                  </div>
                </div>
                <div *ngFor="let statement of billingState.billUsageStatements; trackBy: trackByBillId"
                     class="row usage_row">
                  <div class="cell"><span>{{parseInvoiceDateRange(statement)}}</span>
                    <mat-icon
                      *ngIf="!statement.locked"
                      svgIcon="info_circle"
                      class="unlocked_bill_info_icon"
                      matTooltipClass="column_info_icon_tooltip"
                      [matTooltip]="'Usage statements are finalized 48 hours after the end of the billing period. This statement reflects approximate usage (to date) and may change.'"
                    ></mat-icon>
                  </div>
                  <div class="cell fs-exclude">{{statement.billGrossTotal | dataSize:'human-readable-number'}}</div>
                  <div class="cell fs-exclude">{{statement.creditApplied | dataSize:'human-readable-number'}}</div>
                  <div class="cell fs-exclude">{{statement.billNetTotal | dataSize:'human-readable-number'}}</div>
                  <div class="cell"><a class="link" (click)="downloadStatementCSV(statement.billId)"
                                       target="_blank">CSV</a></div>
                </div>
              </div>
            </div>

            <ng-container *ngIf="organization.paymentState !== 'TACKLE'">
              <div *ngIf="billingState.invoices || billingState.billUsageStatements || billingState.creditBalances"
                   class="hairline"></div>

              <div class="limited_width">
                <div class="row">
                  <div class="seed_form_element solo_element">
                    <label>Billing contacts</label>
                    <div class="edit_button_wrapper">
                      <div class="seed_input billing_contact_input fs-exclude" data-cy="existing-billing-contact-cell"
                      >{{billingState.billingContact}}</div>
                      <button mat-button (click)="showChangeBillingContactDialog()" [disabled]="myRole !== 'ADMIN'"
                              class="admin_billing_edit_button"
                              data-cy="edit-billing-contact-button"
                      >Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<mat-spinner *ngIf="isLoadingObs | async" class="loading"></mat-spinner>


