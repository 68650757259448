import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { WebAuthProvider } from '@cp/common-services/web-auth/web-auth-provider';
import { truthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_SECOND } from '@cp/common/utils/DateTimeUtils';
import { UNAUTHORIZED_EXPECTED_AUTH0, UNAUTHORIZED_EXPECTED_AUTH0_STATUS } from '@cp/common/utils/HttpError';
import { RpcRequest } from '@cp/common/utils/ProtocolUtils';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { GalaxyClient } from './galaxy/client';

export class RestService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly webAuthProvider: WebAuthProvider,
    private readonly apiBaseUrl: string,
    private readonly auth0: Auth0Service | undefined = undefined
  ) {}

  /**
   * Executes a POST request on CpAPI endpoint.
   * Adds an access token to the request and Galaxy related headers.
   * The function uses auth0-mode access token if 'forceAuth0Override' is true. This mode is only used by auth0 demo.
   */
  public async post<ResponseType, RequestType = {}>(
    apiPath: string,
    requestObject: RequestType,
    forceAuth0Override = false
  ): Promise<ResponseType> {
    const accessToken = forceAuth0Override
      ? await this.auth0AccessToken()
      : await this.webAuthProvider.getAccessToken();

    // Add 'rpcAction' suffix to RpcRequest requests to improve logs readability in Sentry, browser Network tab, etc...
    const { rpcAction } = (requestObject || {}) as RpcRequest;
    if (rpcAction) {
      apiPath += `?${rpcAction}`;
    }
    const options = {
      withCredentials: !!accessToken,
      body: requestObject,
      headers: <Record<string, string>>{
        'Content-Type': 'application/json',
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        'X-Galaxy-Session-Id': GalaxyClient.getGalaxySessionId()
      }
    };
    let response: ResponseType | undefined;
    try {
      response = await firstValueFrom(
        this.httpClient.request<ResponseType>('post', this.apiBaseUrl + apiPath, options)
      );
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        // Handle Auth0 migration if needed: reload the client code by a redirect.
        // Avoid endless redirect loop if failed for some reason.
        // The reason may be some unexpected failure, like fail to load a new js bundle from S3.
        const lsKey = 'lastForcedReloadTime';
        const lastRedirectTime = Number(localStorage.getItem(lsKey) || 0);
        const skipReload = Date.now() > lastRedirectTime + 10 * MILLIS_PER_SECOND;
        if (
          !skipReload ||
          (error.status === UNAUTHORIZED_EXPECTED_AUTH0_STATUS && error.error.message === UNAUTHORIZED_EXPECTED_AUTH0)
        ) {
          localStorage.setItem(lsKey, `${Date.now()}`);
          console.log('Triggering web app reload!');
          window.location.reload();
        }
      }
      throw error;
    }
    return response;
  }

  private async auth0AccessToken(): Promise<string | undefined> {
    const auth0 = truthy(this.auth0);
    return firstValueFrom(
      auth0.isAuthenticated$.pipe(
        switchMap((isAuthenticated) => (isAuthenticated ? auth0.getAccessTokenSilently() : of(undefined)))
      )
    );
  }
}

export interface RestServiceError {
  error?: { message?: string };
}
