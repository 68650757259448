import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { isDefined } from '@cp/common/protocol/Common';
import { Instance, IpAccessListEntry } from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { checkArraysEquality } from '@cp/common/utils/MiscUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import {
  DeleteIpAccessListEntryDialogComponent,
  DeleteIpAccessListEntryDialogInput,
  getDeleteIpAccessListEntryDialogConfig
} from '@cp/web/app/instances/ip-access-list/delete-ip-access-list-entry-dialog.component';
import {
  EditIpAccessListEntryDialogComponent,
  EditIpAccessListEntryDialogInput,
  getEditIpAccessListEntryDialogConfig
} from '@cp/web/app/instances/ip-access-list/edit-ip-access-list-entry-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { BehaviorSubject, distinctUntilChanged, Observable, switchMap, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

/** IP access list table for the current instance with control buttons. */
@Component({
  selector: 'cp-ip-access-list-table',
  templateUrl: './ip-access-list-table.component.html',
  styleUrls: ['./ip-access-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpAccessListTableComponent implements OnInit, OnChanges {
  @Input()
  instanceId!: string;

  private readonly instanceIdSubject = new BehaviorSubject<string | undefined>(undefined);
  instanceObs!: Observable<Instance | undefined>;

  readonly userOrgRoleObs: Observable<OrganizationRole>;

  /** Cached list of instance IP access list entries. */
  ipAccessList: Array<IpAccessListEntry> = [];

  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceService: InstanceService,
    private readonly dialog: MatDialog,
    instanceStateService: InstanceStateService
  ) {
    this.instanceObs = this.instanceIdSubject.pipe(
      filter(isDefined),
      distinctUntilChanged(),
      switchMap((instanceId) => instanceStateService.observeInstance(instanceId)),
      tap((instance) => this.updateCachedIpAccessList(instance))
    );
    this.userOrgRoleObs = organizationStateService.observeCurrentOrganizationRole();
  }

  ngOnInit(): void {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);
  }

  ngOnChanges(): void {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);
  }

  /**
   * Updates ipAccessList array the way the array can be passed as @Inputs to Angular component.
   * Makes different contents (after pushes, splices) of instance.ipAccessList use different array object references.
   * Keep the same reference object if instance.ipAccessList is not changed by content, but changed by reference.
   */
  private updateCachedIpAccessList(instance: Instance | undefined): void {
    if (!instance) {
      this.ipAccessList = this.ipAccessList.length === 0 ? this.ipAccessList : [];
      return;
    }
    if (!checkArraysEquality(this.ipAccessList, instance.ipAccessList)) {
      this.ipAccessList = [...instance.ipAccessList];
    }
  }

  showEditFilterDialog(ipAccessListEntry: IpAccessListEntry): void {
    const config: MatDialogConfig<EditIpAccessListEntryDialogInput> = {
      ...getEditIpAccessListEntryDialogConfig(),
      data: {
        organizationId: this.organizationStateService.getCurrentOrgIdOrFail(),
        instanceId: this.instanceId,
        entry: ipAccessListEntry
      }
    };
    this.dialog.open(EditIpAccessListEntryDialogComponent, config);
  }

  showDeleteFilterDialog(ipAccessListEntry: IpAccessListEntry): void {
    const config: MatDialogConfig<DeleteIpAccessListEntryDialogInput> = {
      ...getDeleteIpAccessListEntryDialogConfig(),
      data: {
        organizationId: this.organizationStateService.getCurrentOrgIdOrFail(),
        instanceId: this.instanceId,
        ipAccessListEntry
      }
    };
    this.dialog.open(DeleteIpAccessListEntryDialogComponent, config);
  }
}
