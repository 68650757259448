<div class="integrations_container" data-cy="instance-integrations">
  <div class="column">
  <div *ngIf="wasProvisioning && instance.state === 'running'" class="notification_wrapper instance_create_message">
    <mat-icon class="icon" svgIcon="check_icon"></mat-icon><div class="notification_text">Your service has been created.</div>
  </div>
  <div *ngIf="instance.state === 'provisioning'" class="notification_wrapper instance_provisioning_message">
    <mat-icon class="icon" svgIcon="spinner"></mat-icon><div class="notification_text">Your service is being created. This will take only a few minutes.</div>
  </div>
  <div class="cards_row row">
    <!-- Add data card -->
    <div class="integration_card clickable"
         galaxyClick="servicePage.summary.loadDataGetStartedButtonSelect"
         (click)="onLoadDataClick()" data-cy="load-data-card">
      <div class="load_data_image"></div>
      <h4 class="card_title">Add data</h4>
      <div class="page_text">
        Start adding data to your service, we'll walk you through the process of setting up.
      </div>
      <button class="get_started" mat-stroked-button [disabled]="!isEnabled" data-cy="load-data-card-button">
        {{isEnabled ? 'Get Started' : isNotInFullMaintenance ? 'Creating service...' : 'Maintenance in progress'}}
      </button>
    </div>
    <!-- Connect to SQL console card -->
    <div *ngIf="!isEnabled"
         class="integration_card clickable"
         data-cy="sql-client-card"
         [galaxyClick]="['servicePage.summary.sqlConsoleGetStartedButtonSelect', {isInstanceRunning: 'false'}]"
         (click)="showInstanceProvisioningDialog()"
    >
      <div class="connect_sql_image"></div>
      <h4 class="card_title">Connect your SQL client</h4>
      <div class="page_text">Explore tables, run queries, and create charts using the built-in SQL console</div>
      <button class="get_started" data-cy="sql-client-card-button" mat-stroked-button [disabled]="true">
        {{ isNotInFullMaintenance ? 'Creating service...' : 'Maintenance in progress'}}
      </button>
    </div>
    <a *ngIf="isEnabled"
      galaxyClick="servicePage.summary.sqlConsoleGetStartedButtonSelect"
      class="integration_card clickable"
      [href]="getWebConsoleUri(instance)"
      data-cy="sql-client-card"
      target="_blank"
    >
      <div class="connect_sql_image"></div>
      <h4 class="card_title">Connect your SQL client</h4>
      <div class="page_text">Explore tables, run queries, and create charts using the built-in SQL console</div>
      <button class="get_started" mat-stroked-button data-cy="sql-client-card-button">Get Started</button>
    </a>
    <!-- Connection string card -->
    <div class="integration_card clickable"
         galaxyClick="servicePage.summary.connectStringGetStartedButtonSelect"
         (click)="showConnectDialog()" data-cy="connection-string-card">
      <div class="connection_string_image"></div>
      <h4 class="card_title">Connection string</h4>
      <div class="page_text">
        Get the details you need to connect to your service.
      </div>
      <button class="get_started" mat-stroked-button>
        View string
      </button>
    </div>
  </div>
  </div>

  <div class="bottom_row row" *ngIf="(orgRestrictionsObs | async)?.canInviteMembers && (myOrgUserObs | async)?.role === 'ADMIN'">
    <div class="members_image"></div>
    <div>
      <h4 class="bottom_header">Invite others to join you</h4>
      <div class="page_text">
        It’s really easy to add your colleagues to ClickHouse Cloud, just
        <a
          class="clickable"
          data-cy="invite-members-link"
          galaxyClick="servicePage.summary.inviteMembersGetStartedSelect"
          (click)="showInvitesDialog()">click here</a> to get started.
      </div>
    </div>
  </div>

  <div class="bottom_row row">
    <div class="video_image"></div>
    <div>
      <h4 class="bottom_header">Watch the introduction video</h4>
      <div class="page_text">
        Get to know the ClickHouse Cloud basics in this 6 minute introduction video.
        <a
          galaxyClick="servicePage.summary.watchVideoGetStartedSelect"
          class="clickable"
          target="_blank"
          data-cy="learn-link"
          href="/learn">View</a>.
      </div>
    </div>
  </div>
</div>
