<div *ngIf="invitationsObs | async as invitations"
     class="invitations_container auth_page_container">
  <div class="container">
    <cw-auth-logo type="full" color="primary-dark"></cw-auth-logo>

    <form (ngSubmit)="onCreateNewOrganization()"
          [formGroup]="acceptInvitationsForm"
          class="accept_invitations_container"
          data-cy="accept-invitations-container"
    >
      <div class="auth_intro">
        <div class="auth_title">Join your team</div>
      </div>

      <div>
        <ng-container *ngFor="let invitation of invitations; trackBy: trackById">
          <div class="user-invitation"
               [attr.data-cy-invitation]="invitation.organizationName"
               *ngIf="processingInvitation?.invitationKey !== invitation.invitationKey"
               data-cy='invitation-table-row'>
            <div class="invitation-details">
              <div class="invite_header" [title]="invitation.organizationName"
                   data-cy="accept-invitations-org">{{invitation.organizationName}}</div>
              <div data-cy="accept-invitations-inviter" class="ellipsis"
                   [title]="invitation.inviterEmail">{{invitation.inviterEmail}}</div>
            </div>
            <button *ngIf="!invitation.isExpired"
                    [disabled]="processingInvitation !== null"
                    (click)="acceptInvitation(invitation)"
                    mat-stroked-button
                    class="button clickui-primary-button"
                    data-cy="accept-invitation-button"
                    type="button"
            >Join Organization
            </button>
            <button *ngIf="invitation.isExpired"
                    [disabled]="true"
                    mat-stroked-button
                    class="button clickui-primary-button"
                    data-cy="invitation-expired-button"
                    matTooltip="Please ask you organization administrator to resend your invitation"
                    type="button"
            >Invite Expired
            </button>
          </div>
        </ng-container>

        <div class="user-invitation"
             [attr.data-cy-invitation]="processingInvitation.organizationName"
             *ngIf="processingInvitation !== null"
             data-cy='invitation-table-row'>
          <div class="invitation-details">
            <div class="invite_header" [title]="processingInvitation.organizationName"
                 data-cy="accept-invitations-org">{{processingInvitation.organizationName}}</div>
            <div data-cy="accept-invitations-inviter" class="ellipsis"
                 [title]="processingInvitation.inviterEmail">{{processingInvitation.inviterEmail}}</div>
          </div>
          <button
            [disabled]="true"
            mat-stroked-button
            class="button clickui-primary-button"
            data-cy="accept-invitation-button"
            type="button"
          >
            <mat-spinner class="loading"
                         diameter="20"></mat-spinner>
          </button>
        </div>
        <div
          class="social_buttons">
          <div class="social_divider">
            <div class="divider"></div>
            <div class="or">OR</div>
          </div>

          <button
            mat-flat-button
            class="button clickui-secondary-button"
            data-cy="create-default-org-button"
            type="submit">
            Create New Organization
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
