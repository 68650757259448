/** Map of supported region display names by AWS region id. */
import type { DeepReadonly, DeepReadonlyArray, StageType } from '@cp/common/protocol/Common';
import type { CloudProvider } from '@cp/common/protocol/Instance';
import type { SeedSelectOption } from '@cp/common/protocol/Seed';

/** List of all supported regions. */
export const AWS_REGION_ID_TYPE = [
  'ap-south-1',
  'ap-southeast-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'us-east-1',
  'us-east-2',
  'us-west-2',
  'ap-southeast-2',
  'ca-central-1',
  'sa-east-1',
  'af-south-1',
  'eu-north-1'
] as const;

export type AWSRegionId = (typeof AWS_REGION_ID_TYPE)[number];

export const GCP_REGION_ID_TYPE = [
  'gcp-us-east1',
  'gcp-us-central1',
  'gcp-europe-west4',
  'gcp-asia-southeast1'
] as const;

export type GCPRegionId = (typeof GCP_REGION_ID_TYPE)[number];

export function isGCPRegion(region: RegionId): region is GCPRegionId {
  return GCP_REGION_ID_TYPE.includes(region as GCPRegionId);
}

/** List of all Azure supported regions. */
export const AZURE_REGION_ID_TYPE = ['eastus'] as const;

export type AzureRegionId = (typeof AZURE_REGION_ID_TYPE)[number];

export type RegionId = AWSRegionId | GCPRegionId | AzureRegionId;

/**
 * List of supported countries.
 * Important - The string here is used for finding the country flag.
 */
export type Country =
  | 'us' /* United States */
  | 'de' /* Germany */
  | 'ie' /* Ireland */
  | 'sg' /* Singapore */
  | 'in' /* India */
  | 'nl' /* Netherlands */
  | 'au' /* Australia */
  | 'ca' /* Canada */
  | 'br' /* Brazil */
  | 'za' /* South Africa */
  | 'se' /* Sweden */
  | 'gb' /* Great Britain */;

export interface Region {
  id: RegionId;
  displayId: string;
  name: string;
  cloudProvider: CloudProvider;
  /** If set to 'true' the region will be visible only if 'NEW_DP_REGIONS' flag is ON. */
  isExperimental?: boolean;
  country: Country;
  /** Is this region available for dev instance. */
  isDevAvailable: boolean;
}

interface AWSRegion extends Region {
  id: AWSRegionId;
}

interface GCPRegion extends Region {
  id: GCPRegionId;
}

interface AzureRegion extends Region {
  id: AzureRegionId;
}
/**
 * All known region by ID.
 * Note: different stages have different set of enabled regions.
 */
export const AWS_REGION_BY_ID: DeepReadonly<Record<AWSRegionId, AWSRegion>> = {
  'eu-central-1': {
    id: 'eu-central-1',
    displayId: 'eu-central-1',
    name: 'Frankfurt',
    cloudProvider: 'AWS',
    country: 'de',
    isDevAvailable: true
  },
  'eu-west-1': {
    id: 'eu-west-1',
    displayId: 'eu-west-1',
    name: 'Ireland',
    cloudProvider: 'AWS',
    country: 'ie',
    isDevAvailable: true
  },
  'eu-west-2': {
    id: 'eu-west-2',
    displayId: 'eu-west-2',
    name: 'London',
    cloudProvider: 'AWS',
    country: 'gb',
    isDevAvailable: true
  },
  'us-east-1': {
    id: 'us-east-1',
    displayId: 'us-east-1',
    name: 'N. Virginia',
    cloudProvider: 'AWS',
    country: 'us',
    isDevAvailable: true
  },
  'us-east-2': {
    id: 'us-east-2',
    displayId: 'us-east-2',
    name: 'Ohio',
    cloudProvider: 'AWS',
    country: 'us',
    isDevAvailable: true
  },
  'us-west-2': {
    id: 'us-west-2',
    displayId: 'us-west-2',
    name: 'Oregon',
    cloudProvider: 'AWS',
    country: 'us',
    isDevAvailable: true
  },
  'ap-southeast-1': {
    id: 'ap-southeast-1',
    displayId: 'ap-southeast-1',
    name: 'Singapore',
    cloudProvider: 'AWS',
    country: 'sg',
    isDevAvailable: true
  },
  'ap-south-1': {
    id: 'ap-south-1',
    displayId: 'ap-south-1',
    name: 'Mumbai',
    cloudProvider: 'AWS',
    country: 'in',
    isDevAvailable: true
  },
  'ap-southeast-2': {
    id: 'ap-southeast-2',
    displayId: 'ap-southeast-2',
    name: 'Sydney',
    cloudProvider: 'AWS',
    country: 'au',
    isDevAvailable: true
  },
  'ca-central-1': {
    id: 'ca-central-1',
    displayId: 'ca-central-1',
    name: 'Canada',
    cloudProvider: 'AWS',
    country: 'ca',
    isDevAvailable: false,
    isExperimental: true
  },
  'sa-east-1': {
    id: 'sa-east-1',
    displayId: 'sa-east-1',
    name: 'São Paulo',
    cloudProvider: 'AWS',
    country: 'br',
    isDevAvailable: false,
    isExperimental: true
  },
  'af-south-1': {
    id: 'af-south-1',
    displayId: 'af-south-1',
    name: 'Cape Town',
    cloudProvider: 'AWS',
    country: 'za',
    isDevAvailable: false,
    isExperimental: true
  },
  'eu-north-1': {
    id: 'eu-north-1',
    displayId: 'eu-north-1',
    name: 'Stockholm',
    cloudProvider: 'AWS',
    country: 'se',
    isDevAvailable: false,
    isExperimental: true
  }
};

export const GCP_REGION_BY_ID: Readonly<Record<GCPRegionId, GCPRegion>> = {
  'gcp-us-east1': {
    id: 'gcp-us-east1',
    displayId: 'us-east1',
    name: 'S. Carolina',
    cloudProvider: 'GCP',
    country: 'us',
    isDevAvailable: true
  },
  'gcp-us-central1': {
    id: 'gcp-us-central1',
    displayId: 'us-central1',
    name: 'Iowa',
    cloudProvider: 'GCP',
    country: 'us',
    isDevAvailable: true
  },
  'gcp-europe-west4': {
    id: 'gcp-europe-west4',
    displayId: 'europe-west4',
    name: 'Netherlands',
    cloudProvider: 'GCP',
    country: 'nl',
    isDevAvailable: true
  },
  'gcp-asia-southeast1': {
    id: 'gcp-asia-southeast1',
    displayId: 'asia-southeast1',
    name: 'Singapore',
    cloudProvider: 'GCP',
    country: 'sg',
    isDevAvailable: true
  }
};
export const AZURE_REGION_BY_ID: Readonly<Record<AzureRegionId, AzureRegion>> = {
  eastus: {
    id: 'eastus',
    displayId: 'eastus',
    name: 'East US',
    cloudProvider: 'AZURE',
    country: 'us',
    isDevAvailable: true,
    isExperimental: true
  }
};
export const REGION_BY_ID: Readonly<Record<RegionId, Region>> = {
  ...AWS_REGION_BY_ID,
  ...GCP_REGION_BY_ID,
  ...AZURE_REGION_BY_ID
};

/** Imported from: https://raw.githubusercontent.com/astockwell/countries-and-provinces-states-regions/master/countries.json */
export const RAW_COUNTRIES: DeepReadonlyArray<CountryItem> = [
  {
    name: 'Afghanistan',
    code: 'AF',
    continent: 'Asia',
    filename: 'afghanistan'
  },
  {
    name: 'Åland Islands',
    continent: 'Europe',
    code: 'AX'
  },
  {
    name: 'Albania',
    code: 'AL',
    continent: 'Europe',
    filename: 'albania'
  },
  {
    name: 'Algeria',
    code: 'DZ',
    continent: 'Africa',
    filename: 'algeria'
  },
  {
    name: 'American Samoa',
    code: 'AS',
    continent: 'Oceania'
  },
  {
    name: 'Andorra',
    code: 'AD',
    continent: 'Europe',
    filename: 'andorra'
  },
  {
    name: 'Angola',
    code: 'AO',
    continent: 'Africa',
    filename: 'angola'
  },
  {
    name: 'Anguilla',
    code: 'AI',
    continent: 'North America'
  },
  {
    name: 'Antarctica',
    continent: 'Antarctica',
    code: 'AQ'
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    continent: 'North America',
    filename: 'antigua-and-barbuda'
  },
  {
    name: 'Argentina',
    code: 'AR',
    continent: 'South America',
    filename: 'argentina'
  },
  {
    name: 'Armenia',
    code: 'AM',
    continent: 'Europe',
    filename: 'armenia'
  },
  {
    name: 'Aruba',
    code: 'AW',
    continent: 'Europe'
  },
  {
    name: 'Australia',
    code: 'AU',
    continent: 'Oceania',
    filename: 'australia'
  },
  {
    name: 'Austria',
    code: 'AT',
    continent: 'Europe',
    filename: 'austria'
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    continent: 'Europe',
    filename: 'azerbaijan'
  },
  {
    name: 'Bahamas',
    code: 'BS',
    continent: 'North America',
    filename: 'bahamas'
  },
  {
    name: 'Bahrain',
    code: 'BH',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'bahrain'
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    continent: 'Asia',
    filename: 'bangladesh'
  },
  {
    name: 'Barbados',
    code: 'BB',
    continent: 'North America',
    filename: 'barbados'
  },
  {
    name: 'Belarus',
    code: 'BY',
    continent: 'Europe',
    filename: 'belarus'
  },
  {
    name: 'Belgium',
    code: 'BE',
    continent: 'Europe',
    filename: 'belgium'
  },
  {
    name: 'Belize',
    code: 'BZ',
    continent: 'North America',
    filename: 'belize'
  },
  {
    name: 'Benin',
    code: 'BJ',
    continent: 'Africa',
    filename: 'benin'
  },
  {
    name: 'Bermuda',
    code: 'BM',
    continent: 'North America'
  },
  {
    name: 'Bhutan',
    code: 'BT',
    continent: 'Asia',
    filename: 'bhutan'
  },
  {
    name: 'Bolivia',
    code: 'BO',
    continent: 'South America',
    filename: 'bolivia'
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    continent: 'Europe',
    filename: 'bosnia-and-herzegovina'
  },
  {
    name: 'Botswana',
    code: 'BW',
    continent: 'Africa',
    filename: 'botswana'
  },
  {
    name: 'Bouvet Island',
    continent: 'Antarctica',
    code: 'BV'
  },
  {
    name: 'Brazil',
    code: 'BR',
    continent: 'South America',
    filename: 'brazil'
  },
  {
    name: 'British Indian Ocean Territory',
    continent: 'Africa',
    code: 'IO'
  },
  {
    name: 'Brunei Darussalam',
    code: 'BN',
    continent: 'Asia',
    filename: 'brunei-darussalam'
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    continent: 'Europe',
    filename: 'bulgaria'
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    continent: 'Africa',
    filename: 'burkina-faso'
  },
  {
    name: 'Burundi',
    code: 'BI',
    continent: 'Africa',
    filename: 'burundi'
  },
  {
    name: 'Cambodia',
    code: 'KH',
    continent: 'Asia',
    filename: 'cambodia'
  },
  {
    name: 'Cameroon',
    code: 'CM',
    continent: 'Africa',
    filename: 'cameroon'
  },
  {
    name: 'Canada',
    code: 'CA',
    continent: 'North America',
    filename: 'canada'
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    continent: 'Africa',
    filename: 'cape-verde'
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    continent: 'North America'
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    continent: 'Africa',
    filename: 'central-african-republic'
  },
  {
    name: 'Chad',
    code: 'TD',
    continent: 'Africa',
    filename: 'chad'
  },
  {
    name: 'Chile',
    code: 'CL',
    continent: 'South America',
    filename: 'chile'
  },
  {
    name: 'China',
    code: 'CN',
    continent: 'Asia',
    filename: 'china'
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    continent: 'Oceania'
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    continent: 'Oceania'
  },
  {
    name: 'Colombia',
    code: 'CO',
    continent: 'South America',
    filename: 'colombia'
  },
  {
    name: 'Comoros',
    code: 'KM',
    continent: 'Africa',
    filename: 'comoros'
  },
  {
    name: 'Congo',
    code: 'CG',
    continent: 'Africa',
    filename: 'congo'
  },
  {
    name: 'Congo, The Democratic Republic of the',
    code: 'CD',
    continent: 'Africa',
    filename: 'congo-the-democratic-republic-of-the'
  },
  {
    name: 'Cook Islands',
    continent: 'Oceania',
    code: 'CK'
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    continent: 'North America',
    filename: 'costa-rica'
  },
  {
    name: "Côte d'Ivoire, Republic of",
    code: 'CI',
    continent: 'Africa',
    filename: 'cote-d-ivoire-republic-of'
  },
  {
    name: 'Croatia',
    code: 'HR',
    continent: 'Europe',
    filename: 'croatia'
  },
  {
    name: 'Cuba',
    code: 'CU',
    continent: 'North America',
    filename: 'cuba'
  },
  {
    name: 'Curaçao',
    code: 'CW',
    continent: 'Europe'
  },
  {
    name: 'Cyprus',
    code: 'CY',
    continent: 'Europe',
    filename: 'cyprus'
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    continent: 'Europe',
    filename: 'czech-republic'
  },
  {
    name: 'Denmark',
    code: 'DK',
    continent: 'Europe',
    filename: 'denmark'
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    continent: 'Africa',
    filename: 'djibouti'
  },
  {
    name: 'Dominica',
    code: 'DM',
    continent: 'North America',
    filename: 'dominica'
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    continent: 'North America',
    filename: 'dominican-republic'
  },
  {
    name: 'Ecuador',
    code: 'EC',
    continent: 'South America',
    filename: 'ecuador'
  },
  {
    name: 'Egypt',
    code: 'EG',
    continent: 'Africa',
    filename: 'egypt'
  },
  {
    name: 'El Salvador',
    code: 'SV',
    continent: 'North America',
    filename: 'el-salvador'
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    continent: 'Africa',
    filename: 'equatorial-guinea'
  },
  {
    name: 'Eritrea',
    code: 'ER',
    continent: 'Africa',
    filename: 'eritrea'
  },
  {
    name: 'Estonia',
    code: 'EE',
    continent: 'Europe',
    filename: 'estonia'
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    continent: 'Africa',
    filename: 'ethiopia'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    continent: 'South America'
  },
  {
    name: 'Faroe Islands',
    continent: 'Europe',
    code: 'FO'
  },
  {
    name: 'Fiji',
    code: 'FJ',
    continent: 'Oceania',
    filename: 'fiji'
  },
  {
    name: 'Finland',
    code: 'FI',
    continent: 'Europe',
    filename: 'finland'
  },
  {
    name: 'France',
    code: 'FR',
    continent: 'Europe',
    filename: 'france'
  },
  {
    name: 'French Guiana',
    code: 'GF',
    continent: 'South America'
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    continent: 'Oceania'
  },
  {
    name: 'French Southern Territories',
    continent: 'Antarctica',
    code: 'TF'
  },
  {
    name: 'Gabon',
    code: 'GA',
    continent: 'Africa',
    filename: 'gabon'
  },
  {
    name: 'Gambia',
    code: 'GM',
    continent: 'Africa',
    filename: 'gambia'
  },
  {
    name: 'Georgia',
    code: 'GE',
    continent: 'Europe',
    filename: 'georgia'
  },
  {
    name: 'Germany',
    code: 'DE',
    continent: 'Europe',
    filename: 'germany'
  },
  {
    name: 'Ghana',
    code: 'GH',
    continent: 'Africa',
    filename: 'ghana'
  },
  {
    name: 'Gibraltar',
    continent: 'Europe',
    code: 'GI'
  },
  {
    name: 'Greece',
    code: 'GR',
    continent: 'Europe',
    filename: 'greece'
  },
  {
    name: 'Greenland',
    code: 'GL',
    continent: 'North America',
    filename: 'greenland'
  },
  {
    name: 'Grenada',
    code: 'GD',
    continent: 'North America',
    filename: 'grenada'
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    continent: 'North America'
  },
  {
    name: 'Guam',
    code: 'GU',
    continent: 'Oceania'
  },
  {
    name: 'Guatemala',
    code: 'GT',
    continent: 'North America',
    filename: 'guatemala'
  },
  {
    name: 'Guernsey',
    continent: 'Europe',
    code: 'GG'
  },
  {
    name: 'Guinea',
    code: 'GN',
    continent: 'Africa',
    filename: 'guinea'
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    continent: 'Africa',
    filename: 'guinea-bissau'
  },
  {
    name: 'Guyana',
    code: 'GY',
    continent: 'South America',
    filename: 'guyana'
  },
  {
    name: 'Haiti',
    code: 'HT',
    continent: 'North America',
    filename: 'haiti'
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    continent: 'Oceania',
    code: 'HM'
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VA',
    continent: 'Europe'
  },
  {
    name: 'Honduras',
    code: 'HN',
    continent: 'North America',
    filename: 'honduras'
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    continent: 'Asia',
    filename: 'hong-kong'
  },
  {
    name: 'Hungary',
    code: 'HU',
    continent: 'Europe',
    filename: 'hungary'
  },
  {
    name: 'Iceland',
    code: 'IS',
    continent: 'Europe',
    filename: 'iceland'
  },
  {
    name: 'India',
    code: 'IN',
    continent: 'Asia',
    filename: 'india'
  },
  {
    name: 'Indonesia',
    code: 'ID',
    continent: 'Asia',
    filename: 'indonesia'
  },
  {
    name: 'Iran, Islamic Republic Of',
    code: 'IR',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'iran-islamic-republic-of'
  },
  {
    name: 'Iraq',
    code: 'IQ',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'iraq'
  },
  {
    name: 'Ireland',
    code: 'IE',
    continent: 'Europe',
    filename: 'ireland'
  },
  {
    name: 'Isle of Man',
    continent: 'Europe',
    code: 'IM'
  },
  {
    name: 'Israel',
    code: 'IL',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'israel'
  },
  {
    name: 'Italy',
    code: 'IT',
    continent: 'Europe',
    filename: 'italy'
  },
  {
    name: 'Jamaica',
    code: 'JM',
    continent: 'North America',
    filename: 'jamaica'
  },
  {
    name: 'Japan',
    code: 'JP',
    continent: 'Asia',
    filename: 'japan'
  },
  {
    name: 'Jersey',
    continent: 'Europe',
    code: 'JE'
  },
  {
    name: 'Jordan',
    code: 'JO',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'jordan'
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    continent: 'Europe',
    filename: 'kazakhstan'
  },
  {
    name: 'Kenya',
    code: 'KE',
    continent: 'Africa',
    filename: 'kenya'
  },
  {
    name: 'Kiribati',
    code: 'KI',
    continent: 'Oceania',
    filename: 'kiribati'
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: 'KP',
    continent: 'Asia',
    filename: 'korea-democratic-people-s-republic-of'
  },
  {
    name: 'Korea, Republic of',
    code: 'KR',
    continent: 'Asia',
    filename: 'korea-republic-of'
  },
  {
    name: 'Kuwait',
    code: 'KW',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'kuwait'
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'kyrgyzstan'
  },
  {
    name: "Lao People's Democratic Republic",
    code: 'LA',
    continent: 'Asia',
    filename: 'lao-people-s-democratic-republic'
  },
  {
    name: 'Latvia',
    code: 'LV',
    continent: 'Europe',
    filename: 'latvia'
  },
  {
    name: 'Lebanon',
    code: 'LB',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'lebanon'
  },
  {
    name: 'Lesotho',
    code: 'LS',
    continent: 'Africa',
    filename: 'lesotho'
  },
  {
    name: 'Liberia',
    code: 'LR',
    continent: 'Africa',
    filename: 'liberia'
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
    continent: 'Africa',
    filename: 'libyan-arab-jamahiriya'
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    continent: 'Europe',
    filename: 'liechtenstein'
  },
  {
    name: 'Lithuania',
    code: 'LT',
    continent: 'Europe',
    filename: 'lithuania'
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    continent: 'Europe',
    filename: 'luxembourg'
  },
  {
    name: 'Macao',
    code: 'MO',
    continent: 'Asia'
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    continent: 'Europe',
    filename: 'macedonia-the-former-yugoslav-republic-of'
  },
  {
    name: 'Madagascar',
    code: 'MG',
    continent: 'Africa',
    filename: 'madagascar'
  },
  {
    name: 'Malawi',
    code: 'MW',
    continent: 'Africa',
    filename: 'malawi'
  },
  {
    name: 'Malaysia',
    code: 'MY',
    continent: 'Asia',
    filename: 'malaysia'
  },
  {
    name: 'Maldives',
    code: 'MV',
    continent: 'Asia',
    filename: 'maldives'
  },
  {
    name: 'Mali',
    code: 'ML',
    continent: 'Africa',
    filename: 'mali'
  },
  {
    name: 'Malta',
    code: 'MT',
    continent: 'Europe',
    filename: 'malta'
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    continent: 'Oceania',
    filename: 'marshall-islands'
  },
  {
    name: 'Martinique',
    code: 'MQ',
    continent: 'North America'
  },
  {
    name: 'Mauritania',
    code: 'MR',
    continent: 'Africa',
    filename: 'mauritania'
  },
  {
    name: 'Mauritius',
    code: 'MU',
    continent: 'Africa',
    filename: 'mauritius'
  },
  {
    name: 'Mayotte',
    code: 'YT',
    continent: 'Africa'
  },
  {
    name: 'Mexico',
    code: 'MX',
    continent: 'North America',
    filename: 'mexico'
  },
  {
    name: 'Micronesia, Federated States of',
    code: 'FM',
    continent: 'Oceania',
    filename: 'micronesia-federated-states-of'
  },
  {
    name: 'Moldova, Republic of',
    code: 'MD',
    continent: 'Europe',
    filename: 'moldova-republic-of'
  },
  {
    name: 'Monaco',
    code: 'MC',
    continent: 'Europe',
    filename: 'monaco'
  },
  {
    name: 'Mongolia',
    code: 'MN',
    continent: 'Asia',
    filename: 'mongolia'
  },
  {
    name: 'Montenegro',
    code: 'ME',
    continent: 'Europe',
    filename: 'montenegro'
  },
  {
    name: 'Montserrat',
    code: 'MS',
    continent: 'North America'
  },
  {
    name: 'Morocco',
    code: 'MA',
    continent: 'Africa',
    filename: 'morocco'
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    continent: 'Africa',
    filename: 'mozambique'
  },
  {
    name: 'Myanmar',
    code: 'MM',
    continent: 'Asia',
    filename: 'myanmar'
  },
  {
    name: 'Namibia',
    code: 'NA',
    continent: 'Africa',
    filename: 'namibia'
  },
  {
    name: 'Nauru',
    code: 'NR',
    continent: 'Oceania',
    filename: 'nauru'
  },
  {
    name: 'Nepal',
    code: 'NP',
    continent: 'Asia',
    filename: 'nepal'
  },
  {
    name: 'Netherlands',
    code: 'NL',
    continent: 'Europe',
    filename: 'netherlands'
  },
  {
    name: 'Netherlands Antilles',
    code: 'AN',
    continent: 'Europe'
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    continent: 'Oceania'
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    continent: 'Oceania',
    filename: 'new-zealand'
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    continent: 'North America',
    filename: 'nicaragua'
  },
  {
    name: 'Niger',
    code: 'NE',
    continent: 'Africa',
    filename: 'niger'
  },
  {
    name: 'Nigeria',
    code: 'NG',
    continent: 'Africa',
    filename: 'nigeria'
  },
  {
    name: 'Niue',
    continent: 'Oceania',
    code: 'NU'
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    continent: 'Oceania'
  },
  {
    name: 'Northern Mariana Islands',
    continent: 'Oceania',
    code: 'MP'
  },
  {
    name: 'Norway',
    code: 'NO',
    continent: 'Europe',
    filename: 'norway'
  },
  {
    name: 'Oman',
    code: 'OM',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'oman'
  },
  {
    name: 'Pakistan',
    code: 'PK',
    continent: 'Asia',
    filename: 'pakistan'
  },
  {
    name: 'Palau',
    code: 'PW',
    continent: 'Oceania',
    filename: 'palau'
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'palestinian-territory-occupied'
  },
  {
    name: 'Panama',
    code: 'PA',
    continent: 'North America',
    filename: 'panama'
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    continent: 'Oceania',
    filename: 'papua-new-guinea'
  },
  {
    name: 'Paraguay',
    code: 'PY',
    continent: 'South America',
    filename: 'paraguay'
  },
  {
    name: 'Peru',
    code: 'PE',
    continent: 'South America',
    filename: 'peru'
  },
  {
    name: 'Philippines',
    code: 'PH',
    continent: 'Asia',
    filename: 'philippines'
  },
  {
    name: 'Pitcairn',
    continent: 'Oceania',
    code: 'PN'
  },
  {
    name: 'Poland',
    code: 'PL',
    continent: 'Europe',
    filename: 'poland'
  },
  {
    name: 'Portugal',
    code: 'PT',
    continent: 'Europe',
    filename: 'portugal'
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    continent: 'North America'
  },
  {
    name: 'Qatar',
    code: 'QA',
    continent: 'Asia',
    filename: 'qatar'
  },
  {
    name: 'Réunion',
    code: 'RE',
    continent: 'Africa'
  },
  {
    name: 'Romania',
    code: 'RO',
    continent: 'Europe',
    filename: 'romania'
  },
  {
    name: 'Russian Federation',
    code: 'RU',
    continent: 'Europe',
    filename: 'russian-federation'
  },
  {
    name: 'Rwanda',
    code: 'RW',
    continent: 'Africa',
    filename: 'rwanda'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
    continent: 'Africa',
    filename: 'saint-helena-ascension-and-tristan-da-cunha'
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    continent: 'North America',
    filename: 'saint-kitts-and-nevis'
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    continent: 'North America',
    filename: 'saint-lucia'
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    continent: 'North America'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    continent: 'North America',
    filename: 'saint-vincent-and-the-grenadines'
  },
  {
    name: 'Samoa',
    code: 'WS',
    continent: 'Oceania',
    filename: 'samoa'
  },
  {
    name: 'San Marino',
    code: 'SM',
    continent: 'Europe',
    filename: 'san-marino'
  },
  {
    name: 'São Tomé and Príncipe',
    code: 'ST',
    continent: 'Africa',
    filename: 'sao-tome-and-principe'
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'saudi-arabia'
  },
  {
    name: 'Senegal',
    code: 'SN',
    continent: 'Africa',
    filename: 'senegal'
  },
  {
    name: 'Serbia',
    code: 'RS',
    continent: 'Europe',
    filename: 'serbia'
  },
  {
    name: 'Seychelles',
    code: 'SC',
    continent: 'Africa',
    filename: 'seychelles'
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    continent: 'Africa',
    filename: 'sierra-leone'
  },
  {
    name: 'Singapore',
    code: 'SG',
    continent: 'Asia',
    filename: 'singapore'
  },
  {
    name: 'Sint Maarten',
    code: 'SX',
    continent: 'Europe'
  },
  {
    name: 'Slovakia',
    code: 'SK',
    continent: 'Europe',
    filename: 'slovakia'
  },
  {
    name: 'Slovenia',
    code: 'SI',
    continent: 'Europe',
    filename: 'slovenia'
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    continent: 'Oceania',
    filename: 'solomon-islands'
  },
  {
    name: 'Somalia',
    code: 'SO',
    continent: 'Africa',
    filename: 'somalia'
  },
  {
    name: 'South Africa',
    code: 'ZA',
    continent: 'Africa',
    filename: 'south-africa'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    continent: 'South America'
  },
  {
    name: 'South Sudan',
    code: 'SS',
    continent: 'Africa',
    filename: 'south-sudan'
  },
  {
    name: 'Spain',
    code: 'ES',
    continent: 'Europe',
    filename: 'spain'
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    continent: 'Asia',
    filename: 'sri-lanka'
  },
  {
    name: 'Sudan',
    code: 'SD',
    continent: 'Africa',
    filename: 'sudan'
  },
  {
    name: 'Suriname',
    code: 'SR',
    continent: 'South America',
    filename: 'suriname'
  },
  {
    name: 'Svalbard and Jan Mayen',
    continent: 'Europe',
    code: 'SJ'
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    continent: 'Africa',
    filename: 'swaziland'
  },
  {
    name: 'Sweden',
    code: 'SE',
    continent: 'Europe',
    filename: 'sweden'
  },
  {
    name: 'Switzerland',
    code: 'CH',
    continent: 'Europe',
    filename: 'switzerland'
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SY',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'syrian-arab-republic'
  },
  {
    name: 'Taiwan, Province of China',
    code: 'TW',
    continent: 'Asia',
    filename: 'taiwan-province-of-china'
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'tajikistan'
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
    continent: 'Africa',
    filename: 'tanzania-united-republic-of'
  },
  {
    name: 'Thailand',
    code: 'TH',
    continent: 'Asia',
    filename: 'thailand'
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    continent: 'Oceania',
    filename: 'timor-leste'
  },
  {
    name: 'Togo',
    code: 'TG',
    continent: 'Africa',
    filename: 'togo'
  },
  {
    name: 'Tokelau',
    continent: 'Oceania',
    code: 'TK'
  },
  {
    name: 'Tonga',
    code: 'TO',
    continent: 'Oceania',
    filename: 'tonga'
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    continent: 'North America',
    filename: 'trinidad-and-tobago'
  },
  {
    name: 'Tunisia',
    code: 'TN',
    continent: 'Africa',
    filename: 'tunisia'
  },
  {
    name: 'Turkey',
    code: 'TR',
    continent: 'Europe',
    filename: 'turkey'
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'turkmenistan'
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    continent: 'North America'
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    continent: 'Oceania',
    filename: 'tuvalu'
  },
  {
    name: 'Uganda',
    code: 'UG',
    continent: 'Africa',
    filename: 'uganda'
  },
  {
    name: 'Ukraine',
    code: 'UA',
    continent: 'Europe',
    filename: 'ukraine'
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'united-arab-emirates'
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    continent: 'Europe',
    filename: 'united-kingdom'
  },
  {
    name: 'United States',
    code: 'US',
    continent: 'North America',
    filename: 'united-states'
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    continent: 'North America',
    filename: 'united-states-minor-outlying-islands'
  },
  {
    name: 'Uruguay',
    code: 'UY',
    continent: 'South America',
    filename: 'uruguay'
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'uzbekistan'
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    continent: 'Oceania',
    filename: 'vanuatu'
  },
  {
    name: 'Venezuela',
    code: 'VE',
    continent: 'South America',
    filename: 'venezuela'
  },
  {
    name: 'Viet Nam',
    code: 'VN',
    continent: 'Asia',
    filename: 'viet-nam'
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
    continent: 'North America'
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
    continent: 'North America'
  },
  {
    name: 'Wallis and Futuna',
    continent: 'Oceania',
    code: 'WF'
  },
  {
    name: 'Western Sahara',
    continent: 'Africa',
    code: 'EH'
  },
  {
    name: 'Yemen',
    code: 'YE',
    continent: 'Asia',
    instanceContinent: 'Europe',
    filename: 'yemen'
  },
  {
    name: 'Zambia',
    code: 'ZM',
    continent: 'Africa',
    filename: 'zambia'
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    continent: 'Africa',
    filename: 'zimbabwe'
  }
];

// Remove blocked countries from list.
export const COUNTRIES: DeepReadonlyArray<CountryItem> = RAW_COUNTRIES.filter(
  (c) => c.code !== 'CN' && c.code !== 'RU'
);

export type CountryItemContinent =
  | 'Africa'
  | 'Antarctica'
  | 'Asia'
  | 'Europe'
  | 'North America'
  | 'Oceania'
  | 'South America';

export interface CountryItem {
  name: string;
  code: string;
  continent: CountryItemContinent;
  /** 'continent' override to select default instance region. */
  instanceContinent?: CountryItemContinent;
  filename?: string;
}

export const COUNTRIES_SEED_SELECT: DeepReadonlyArray<SeedSelectOption> = COUNTRIES.map((c) => ({
  label: c.name,
  value: c.code,
  dataCy: 'country-option',
  dataCyValue: c.code
}));

interface AdministrativeArea {
  name: string;
  code: string;
  subdivision?: string;
  native?: string;
}

/** imported from: https://github.com/astockwell/countries-and-provinces-states-regions/tree/master/countries */
export const STATES: DeepReadonly<Record<string, Array<AdministrativeArea>>> = {
  AE: [
    {
      name: "'Ajmān",
      code: 'AE-AJ'
    },
    {
      name: 'Abū Z̧aby',
      code: 'AE-AZ'
    },
    {
      name: 'Al Fujayrah',
      code: 'AE-FU'
    },
    {
      name: 'Ash Shāriqah',
      code: 'AE-SH'
    },
    {
      name: 'Dubayy',
      code: 'AE-DU'
    },
    {
      name: "Ra's al Khaymah",
      code: 'AE-RK'
    },
    {
      name: 'Umm al Qaywayn',
      code: 'AE-UQ'
    }
  ],
  AL: [
    {
      name: 'Berat',
      code: 'AL-01'
    },
    {
      name: 'Dibër',
      code: 'AL-09'
    },
    {
      name: 'Durrës',
      code: 'AL-02'
    },
    {
      name: 'Elbasan',
      code: 'AL-03'
    },
    {
      name: 'Fier',
      code: 'AL-04'
    },
    {
      name: 'Gjirokastër',
      code: 'AL-05'
    },
    {
      name: 'Korçë',
      code: 'AL-06'
    },
    {
      name: 'Kukës',
      code: 'AL-07'
    },
    {
      name: 'Lezhë',
      code: 'AL-08'
    },
    {
      name: 'Shkodër',
      code: 'AL-10'
    },
    {
      name: 'Tiranë',
      code: 'AL-11'
    },
    {
      name: 'Vlorë',
      code: 'AL-12'
    }
  ],
  AU: [
    {
      name: 'Australian Capital Territory',
      code: 'AU-ACT'
    },
    {
      name: 'New South Wales',
      code: 'AU-NSW'
    },
    {
      name: 'Northern Territory',
      code: 'AU-NT'
    },
    {
      name: 'Queensland',
      code: 'AU-QLD'
    },
    {
      name: 'South Australia',
      code: 'AU-SA'
    },
    {
      name: 'Tasmania',
      code: 'AU-TAS'
    },
    {
      name: 'Victoria',
      code: 'AU-VIC'
    },
    {
      name: 'Western Australia',
      code: 'AU-WA'
    }
  ],
  AR: [
    {
      name: 'Buenos Aires',
      code: 'AR-B'
    },
    {
      name: 'Catamarca',
      code: 'AR-K'
    },
    {
      name: 'Chaco',
      code: 'AR-H'
    },
    {
      name: 'Chubut',
      code: 'AR-U'
    },
    {
      name: 'Ciudad Autónoma de Buenos Aires',
      code: 'AR-C'
    },
    {
      name: 'Corrientes',
      code: 'AR-W'
    },
    {
      name: 'Córdoba',
      code: 'AR-X'
    },
    {
      name: 'Entre Ríos',
      code: 'AR-E'
    },
    {
      name: 'Formosa',
      code: 'AR-P'
    },
    {
      name: 'Jujuy',
      code: 'AR-Y'
    },
    {
      name: 'La Pampa',
      code: 'AR-L'
    },
    {
      name: 'La Rioja',
      code: 'AR-F'
    },
    {
      name: 'Mendoza',
      code: 'AR-M'
    },
    {
      name: 'Misiones',
      code: 'AR-N'
    },
    {
      name: 'Neuquén',
      code: 'AR-Q'
    },
    {
      name: 'Río Negro',
      code: 'AR-R'
    },
    {
      name: 'Salta',
      code: 'AR-A'
    },
    {
      name: 'San Juan',
      code: 'AR-J'
    },
    {
      name: 'San Luis',
      code: 'AR-D'
    },
    {
      name: 'Santa Cruz',
      code: 'AR-Z'
    },
    {
      name: 'Santa Fe',
      code: 'AR-S'
    },
    {
      name: 'Santiago del Estero',
      code: 'AR-G'
    },
    {
      name: 'Tierra del Fuego',
      code: 'AR-V'
    },
    {
      name: 'Tucumán',
      code: 'AR-T'
    }
  ],
  AM: [
    {
      name: 'Aragac̣otn',
      code: 'AM-AG'
    },
    {
      name: 'Ararat',
      code: 'AM-AR'
    },
    {
      name: 'Armavir',
      code: 'AM-AV'
    },
    {
      name: 'Erevan',
      code: 'AM-ER'
    },
    {
      name: "Geġark'unik'",
      code: 'AM-GR'
    },
    {
      name: "Kotayk'",
      code: 'AM-KT'
    },
    {
      name: 'Loṙi',
      code: 'AM-LO'
    },
    {
      name: "Syunik'",
      code: 'AM-SU'
    },
    {
      name: 'Tavuš',
      code: 'AM-TV'
    },
    {
      name: 'Vayoć Jor',
      code: 'AM-VD'
    },
    {
      name: 'Širak',
      code: 'AM-SH'
    }
  ],
  BB: [
    {
      name: 'Christ Church',
      code: 'BB-01'
    },
    {
      name: 'Saint Andrew',
      code: 'BB-02'
    },
    {
      name: 'Saint George',
      code: 'BB-03'
    },
    {
      name: 'Saint James',
      code: 'BB-04'
    },
    {
      name: 'Saint John',
      code: 'BB-05'
    },
    {
      name: 'Saint Joseph',
      code: 'BB-06'
    },
    {
      name: 'Saint Lucy',
      code: 'BB-07'
    },
    {
      name: 'Saint Michael',
      code: 'BB-08'
    },
    {
      name: 'Saint Peter',
      code: 'BB-09'
    },
    {
      name: 'Saint Philip',
      code: 'BB-10'
    },
    {
      name: 'Saint Thomas',
      code: 'BB-11'
    }
  ],
  BR: [
    {
      name: 'Acre',
      code: 'BR-AC'
    },
    {
      name: 'Alagoas',
      code: 'BR-AL'
    },
    {
      name: 'Amapá',
      code: 'BR-AP'
    },
    {
      name: 'Amazonas',
      code: 'BR-AM'
    },
    {
      name: 'Bahia',
      code: 'BR-BA'
    },
    {
      name: 'Ceará',
      code: 'BR-CE'
    },
    {
      name: 'Distrito Federal',
      code: 'BR-DF'
    },
    {
      name: 'Espírito Santo',
      code: 'BR-ES'
    },
    {
      name: 'Goiás',
      code: 'BR-GO'
    },
    {
      name: 'Maranhão',
      code: 'BR-MA'
    },
    {
      name: 'Mato Grosso',
      code: 'BR-MT'
    },
    {
      name: 'Mato Grosso do Sul',
      code: 'BR-MS'
    },
    {
      name: 'Minas Gerais',
      code: 'BR-MG'
    },
    {
      name: 'Paraná',
      code: 'BR-PR'
    },
    {
      name: 'Paraíba',
      code: 'BR-PB'
    },
    {
      name: 'Pará',
      code: 'BR-PA'
    },
    {
      name: 'Pernambuco',
      code: 'BR-PE'
    },
    {
      name: 'Piauí',
      code: 'BR-PI'
    },
    {
      name: 'Rio Grande do Norte',
      code: 'BR-RN'
    },
    {
      name: 'Rio Grande do Sul',
      code: 'BR-RS'
    },
    {
      name: 'Rio de Janeiro',
      code: 'BR-RJ'
    },
    {
      name: 'Rondônia',
      code: 'BR-RO'
    },
    {
      name: 'Roraima',
      code: 'BR-RR'
    },
    {
      name: 'Santa Catarina',
      code: 'BR-SC'
    },
    {
      name: 'Sergipe',
      code: 'BR-SE'
    },
    {
      name: 'São Paulo',
      code: 'BR-SP'
    },
    {
      name: 'Tocantins',
      code: 'BR-TO'
    }
  ],
  BS: [
    {
      name: 'Acklins',
      code: 'BS-AK'
    },
    {
      name: 'Berry Islands',
      code: 'BS-BY'
    },
    {
      name: 'Bimini',
      code: 'BS-BI'
    },
    {
      name: 'Black Point',
      code: 'BS-BP'
    },
    {
      name: 'Cat Island',
      code: 'BS-CI'
    },
    {
      name: 'Central Abaco',
      code: 'BS-CO'
    },
    {
      name: 'Central Andros',
      code: 'BS-CS'
    },
    {
      name: 'Central Eleuthera',
      code: 'BS-CE'
    },
    {
      name: 'City of Freeport',
      code: 'BS-FP'
    },
    {
      name: 'Crooked Island and Long Cay',
      code: 'BS-CK'
    },
    {
      name: 'East Grand Bahama',
      code: 'BS-EG'
    },
    {
      name: 'Exuma',
      code: 'BS-EX'
    },
    {
      name: 'Grand Cay',
      code: 'BS-GC'
    },
    {
      name: 'Harbour Island',
      code: 'BS-HI'
    },
    {
      name: 'Hope Town',
      code: 'BS-HT'
    },
    {
      name: 'Inagua',
      code: 'BS-IN'
    },
    {
      name: 'Long Island',
      code: 'BS-LI'
    },
    {
      name: 'Mangrove Cay',
      code: 'BS-MC'
    },
    {
      name: 'Mayaguana',
      code: 'BS-MG'
    },
    {
      name: 'Moores Island',
      code: 'BS-MI'
    },
    {
      name: 'North Abaco',
      code: 'BS-NO'
    },
    {
      name: 'North Andros',
      code: 'BS-NS'
    },
    {
      name: 'North Eleuthera',
      code: 'BS-NE'
    },
    {
      name: 'Ragged Island',
      code: 'BS-RI'
    },
    {
      name: 'Rum Cay',
      code: 'BS-RC'
    },
    {
      name: 'San Salvador',
      code: 'BS-SS'
    },
    {
      name: 'South Abaco',
      code: 'BS-SO'
    },
    {
      name: 'South Andros',
      code: 'BS-SA'
    },
    {
      name: 'South Eleuthera',
      code: 'BS-SE'
    },
    {
      name: 'Spanish Wells',
      code: 'BS-SW'
    },
    {
      name: 'West Grand Bahama',
      code: 'BS-WG'
    }
  ],
  BY: [
    {
      name: "Brestskaya voblasts'",
      code: 'BY-BR'
    },
    {
      name: "Homyel'skaya voblasts'",
      code: 'BY-HO'
    },
    {
      name: 'Horad Minsk',
      code: 'BY-HM'
    },
    {
      name: "Hrodzenskaya voblasts'",
      code: 'BY-HR'
    },
    {
      name: "Mahilyowskaya voblasts'",
      code: 'BY-MA'
    },
    {
      name: "Minskaya voblasts'",
      code: 'BY-MI'
    },
    {
      name: "Vitsyebskaya voblasts'",
      code: 'BY-VI'
    }
  ],
  CA: [
    {
      name: 'Alberta',
      code: 'CA-AB',
      subdivision: 'province',
      native: 'Alberta'
    },
    {
      name: 'British Columbia',
      code: 'CA-BC',
      subdivision: 'province',
      native: 'Colombie-Britannique'
    },
    {
      name: 'Manitoba',
      code: 'CA-MB',
      subdivision: 'province',
      native: 'Manitoba'
    },
    {
      name: 'New Brunswick',
      code: 'CA-NB',
      subdivision: 'province',
      native: 'Nouveau-Brunswick'
    },
    {
      name: 'Newfoundland and Labrador',
      code: 'CA-NL',
      subdivision: 'province',
      native: 'Terre-Neuve-et-Labrador'
    },
    {
      name: 'Nova Scotia',
      code: 'CA-NS',
      subdivision: 'province',
      native: 'Nouvelle-Écosse'
    },
    {
      name: 'Ontario',
      code: 'CA-ON',
      subdivision: 'province',
      native: 'Ontario'
    },
    {
      name: 'Prince Edward Island',
      code: 'CA-PE',
      subdivision: 'province',
      native: 'Île-du-Prince-Édouard'
    },
    {
      name: 'Quebec',
      code: 'CA-QC',
      subdivision: 'province',
      native: 'Québec'
    },
    {
      name: 'Saskatchewan',
      code: 'CA-SK',
      subdivision: 'province',
      native: 'Saskatchewan'
    },
    {
      name: 'Northwest Territories',
      code: 'CA-NT',
      subdivision: 'territory',
      native: 'Territoires du Nord-Ouest'
    },
    {
      name: 'Nunavut',
      code: 'CA-NU',
      subdivision: 'territory',
      native: 'Nunavut'
    },
    {
      name: 'Yukon',
      code: 'CA-YT',
      subdivision: 'territory',
      native: 'Yukon'
    }
  ],
  CL: [
    {
      name: 'Aisén del General Carlos Ibañez del Campo',
      code: 'CL-AI'
    },
    {
      name: 'Antofagasta',
      code: 'CL-AN'
    },
    {
      name: 'Araucanía',
      code: 'CL-AR'
    },
    {
      name: 'Arica y Parinacota',
      code: 'CL-AP'
    },
    {
      name: 'Atacama',
      code: 'CL-AT'
    },
    {
      name: 'Bío-Bío',
      code: 'CL-BI'
    },
    {
      name: 'Coquimbo',
      code: 'CL-CO'
    },
    {
      name: "Libertador General Bernardo O'Higgins",
      code: 'CL-LI'
    },
    {
      name: 'Los Lagos',
      code: 'CL-LL'
    },
    {
      name: 'Los Ríos',
      code: 'CL-LR'
    },
    {
      name: 'Magallanes',
      code: 'CL-MA'
    },
    {
      name: 'Maule',
      code: 'CL-ML'
    },
    {
      name: 'Región Metropolitana de Santiago',
      code: 'CL-RM'
    },
    {
      name: 'Tarapacá',
      code: 'CL-TA'
    },
    {
      name: 'Valparaíso',
      code: 'CL-VS'
    }
  ],
  CO: [
    {
      name: 'Amazonas',
      code: 'CO-AMA'
    },
    {
      name: 'Antioquia',
      code: 'CO-ANT'
    },
    {
      name: 'Arauca',
      code: 'CO-ARA'
    },
    {
      name: 'Atlántico',
      code: 'CO-ATL'
    },
    {
      name: 'Bolívar',
      code: 'CO-BOL'
    },
    {
      name: 'Boyacá',
      code: 'CO-BOY'
    },
    {
      name: 'Caldas',
      code: 'CO-CAL'
    },
    {
      name: 'Caquetá',
      code: 'CO-CAQ'
    },
    {
      name: 'Casanare',
      code: 'CO-CAS'
    },
    {
      name: 'Cauca',
      code: 'CO-CAU'
    },
    {
      name: 'Cesar',
      code: 'CO-CES'
    },
    {
      name: 'Chocó',
      code: 'CO-CHO'
    },
    {
      name: 'Cundinamarca',
      code: 'CO-CUN'
    },
    {
      name: 'Córdoba',
      code: 'CO-COR'
    },
    {
      name: 'Distrito Capital de Bogotá',
      code: 'CO-DC'
    },
    {
      name: 'Guainía',
      code: 'CO-GUA'
    },
    {
      name: 'Guaviare',
      code: 'CO-GUV'
    },
    {
      name: 'Huila',
      code: 'CO-HUI'
    },
    {
      name: 'La Guajira',
      code: 'CO-LAG'
    },
    {
      name: 'Magdalena',
      code: 'CO-MAG'
    },
    {
      name: 'Meta',
      code: 'CO-MET'
    },
    {
      name: 'Nariño',
      code: 'CO-NAR'
    },
    {
      name: 'Norte de Santander',
      code: 'CO-NSA'
    },
    {
      name: 'Putumayo',
      code: 'CO-PUT'
    },
    {
      name: 'Quindío',
      code: 'CO-QUI'
    },
    {
      name: 'Risaralda',
      code: 'CO-RIS'
    },
    {
      name: 'San Andrés, Providencia y Santa Catalina',
      code: 'CO-SAP'
    },
    {
      name: 'Santander',
      code: 'CO-SAN'
    },
    {
      name: 'Sucre',
      code: 'CO-SUC'
    },
    {
      name: 'Tolima',
      code: 'CO-TOL'
    },
    {
      name: 'Valle del Cauca',
      code: 'CO-VAC'
    },
    {
      name: 'Vaupés',
      code: 'CO-VAU'
    },
    {
      name: 'Vichada',
      code: 'CO-VID'
    }
  ],
  CN: [
    {
      name: 'Guangxi',
      code: 'CN-45',
      subdivision: 'Autonomous region',
      native: '广西'
    },
    {
      name: 'Nei Mongol',
      code: 'CN-15',
      subdivision: 'Autonomous region',
      native: '内蒙古'
    },
    {
      name: 'Ningxia',
      code: 'CN-64',
      subdivision: 'Autonomous region',
      native: '宁夏'
    },
    {
      name: 'Xinjiang',
      code: 'CN-65',
      subdivision: 'Autonomous region',
      native: '新疆'
    },
    {
      name: 'Xizang',
      code: 'CN-54',
      subdivision: 'Autonomous region',
      native: '西藏'
    },
    {
      name: 'Beijing',
      code: 'CN-11',
      subdivision: 'Municipality',
      native: '北京'
    },
    {
      name: 'Chongqing',
      code: 'CN-50',
      subdivision: 'Municipality',
      native: '重庆'
    },
    {
      name: 'Shanghai',
      code: 'CN-31',
      subdivision: 'Municipality',
      native: '上海'
    },
    {
      name: 'Tianjin',
      code: 'CN-12',
      subdivision: 'Municipality',
      native: '天津'
    },
    {
      name: 'Anhui',
      code: 'CN-34',
      subdivision: 'Province',
      native: '安徽'
    },
    {
      name: 'Fujian',
      code: 'CN-35',
      subdivision: 'Province',
      native: '福建'
    },
    {
      name: 'Gansu',
      code: 'CN-62',
      subdivision: 'Province',
      native: '甘肃'
    },
    {
      name: 'Guangdong',
      code: 'CN-44',
      subdivision: 'Province',
      native: '广东'
    },
    {
      name: 'Guizhou',
      code: 'CN-52',
      subdivision: 'Province',
      native: '贵州'
    },
    {
      name: 'Hainan',
      code: 'CN-46',
      subdivision: 'Province',
      native: '海南'
    },
    {
      name: 'Hebei',
      code: 'CN-13',
      subdivision: 'Province',
      native: '河北'
    },
    {
      name: 'Heilongjiang',
      code: 'CN-23',
      subdivision: 'Province',
      native: '黑龙江'
    },
    {
      name: 'Henan',
      code: 'CN-41',
      subdivision: 'Province',
      native: '河南'
    },
    {
      name: 'Hubei',
      code: 'CN-42',
      subdivision: 'Province',
      native: '湖北'
    },
    {
      name: 'Hunan',
      code: 'CN-43',
      subdivision: 'Province',
      native: '湖南'
    },
    {
      name: 'Jiangsu',
      code: 'CN-32',
      subdivision: 'Province',
      native: '江苏'
    },
    {
      name: 'Jiangxi',
      code: 'CN-36',
      subdivision: 'Province',
      native: '江西'
    },
    {
      name: 'Jilin',
      code: 'CN-22',
      subdivision: 'Province',
      native: '吉林'
    },
    {
      name: 'Liaoning',
      code: 'CN-21',
      subdivision: 'Province',
      native: '辽宁'
    },
    {
      name: 'Qinghai',
      code: 'CN-63',
      subdivision: 'Province',
      native: '青海'
    },
    {
      name: 'Shaanxi',
      code: 'CN-61',
      subdivision: 'Province',
      native: '陕西'
    },
    {
      name: 'Shandong',
      code: 'CN-37',
      subdivision: 'Province',
      native: '山东'
    },
    {
      name: 'Shanxi',
      code: 'CN-14',
      subdivision: 'Province',
      native: '山西'
    },
    {
      name: 'Sichuan',
      code: 'CN-51',
      subdivision: 'Province',
      native: '四川'
    },
    {
      name: 'Taiwan',
      code: 'CN-71',
      subdivision: 'Province',
      native: '台湾'
    },
    {
      name: 'Yunnan',
      code: 'CN-53',
      subdivision: 'Province',
      native: '云南'
    },
    {
      name: 'Zhejiang',
      code: 'CN-33',
      subdivision: 'Province',
      native: '浙江'
    },
    {
      name: 'Hong Kong',
      code: 'CN-91',
      subdivision: 'Special administrative region',
      native: '香港'
    },
    {
      name: 'Macao',
      code: 'CN-92',
      subdivision: 'Special administrative region',
      native: '澳门'
    }
  ],
  CR: [
    {
      name: 'Alajuela',
      code: 'CR-A'
    },
    {
      name: 'Cartago',
      code: 'CR-C'
    },
    {
      name: 'Guanacaste',
      code: 'CR-G'
    },
    {
      name: 'Heredia',
      code: 'CR-H'
    },
    {
      name: 'Limón',
      code: 'CR-L'
    },
    {
      name: 'Puntarenas',
      code: 'CR-P'
    },
    {
      name: 'San José',
      code: 'CR-SJ'
    }
  ],
  CU: [
    {
      name: 'Artemisa',
      code: 'CU-15'
    },
    {
      name: 'Camagüey',
      code: 'CU-09'
    },
    {
      name: 'Ciego de Ávila',
      code: 'CU-08'
    },
    {
      name: 'Cienfuegos',
      code: 'CU-06'
    },
    {
      name: 'Granma',
      code: 'CU-12'
    },
    {
      name: 'Guantánamo',
      code: 'CU-14'
    },
    {
      name: 'Holguín',
      code: 'CU-11'
    },
    {
      name: 'Isla de la Juventud',
      code: 'CU-99'
    },
    {
      name: 'La Habana',
      code: 'CU-03'
    },
    {
      name: 'Las Tunas',
      code: 'CU-10'
    },
    {
      name: 'Matanzas',
      code: 'CU-04'
    },
    {
      name: 'Mayabeque',
      code: 'CU-16'
    },
    {
      name: 'Pinar del Río',
      code: 'CU-01'
    },
    {
      name: 'Sancti Spíritus',
      code: 'CU-07'
    },
    {
      name: 'Santiago de Cuba',
      code: 'CU-13'
    },
    {
      name: 'Villa Clara',
      code: 'CU-05'
    }
  ],
  CV: [
    {
      name: 'Ilhas de Barlavento',
      code: 'CV-B'
    },
    {
      name: 'Ilhas de Sotavento',
      code: 'CV-S'
    }
  ],
  DE: [
    {
      name: 'Baden-Württemberg',
      code: 'DE-BW'
    },
    {
      name: 'Bayern',
      code: 'DE-BY'
    },
    {
      name: 'Berlin',
      code: 'DE-BE'
    },
    {
      name: 'Brandenburg',
      code: 'DE-BB'
    },
    {
      name: 'Bremen',
      code: 'DE-HB'
    },
    {
      name: 'Hamburg',
      code: 'DE-HH'
    },
    {
      name: 'Hessen',
      code: 'DE-HE'
    },
    {
      name: 'Mecklenburg-Vorpommern',
      code: 'DE-MV'
    },
    {
      name: 'Niedersachsen',
      code: 'DE-NI'
    },
    {
      name: 'Nordrhein-Westfalen',
      code: 'DE-NW'
    },
    {
      name: 'Rheinland-Pfalz',
      code: 'DE-RP'
    },
    {
      name: 'Saarland',
      code: 'DE-SL'
    },
    {
      name: 'Sachsen',
      code: 'DE-SN'
    },
    {
      name: 'Sachsen-Anhalt',
      code: 'DE-ST'
    },
    {
      name: 'Schleswig-Holstein',
      code: 'DE-SH'
    },
    {
      name: 'Thüringen',
      code: 'DE-TH'
    }
  ],
  EG: [
    {
      name: 'Ad Daqahlīyah',
      code: 'EG-DK'
    },
    {
      name: 'Al Baḩr al Aḩmar',
      code: 'EG-BA'
    },
    {
      name: 'Al Buḩayrah',
      code: 'EG-BH'
    },
    {
      name: 'Al Fayyūm',
      code: 'EG-FYM'
    },
    {
      name: 'Al Gharbīyah',
      code: 'EG-GH'
    },
    {
      name: 'Al Iskandarīyah',
      code: 'EG-ALX'
    },
    {
      name: 'Al Ismāٰīlīyah',
      code: 'EG-IS'
    },
    {
      name: 'Al Jīzah',
      code: 'EG-GZ'
    },
    {
      name: 'Al Minyā',
      code: 'EG-MN'
    },
    {
      name: 'Al Minūfīyah',
      code: 'EG-MNF'
    },
    {
      name: 'Al Qalyūbīyah',
      code: 'EG-KB'
    },
    {
      name: 'Al Qāhirah',
      code: 'EG-C'
    },
    {
      name: 'Al Uqşur',
      code: 'EG-LX'
    },
    {
      name: 'Al Wādī al Jadīd',
      code: 'EG-WAD'
    },
    {
      name: 'As Suways',
      code: 'EG-SUZ'
    },
    {
      name: 'As Sādis min Uktūbar',
      code: 'EG-SU'
    },
    {
      name: 'Ash Sharqīyah',
      code: 'EG-SHR'
    },
    {
      name: 'Aswān',
      code: 'EG-ASN'
    },
    {
      name: 'Asyūţ',
      code: 'EG-AST'
    },
    {
      name: 'Banī Suwayf',
      code: 'EG-BNS'
    },
    {
      name: 'Būr Saٰīd',
      code: 'EG-PTS'
    },
    {
      name: 'Dumyāţ',
      code: 'EG-DT'
    },
    {
      name: "Janūb Sīnā'",
      code: 'EG-JS'
    },
    {
      name: 'Kafr ash Shaykh',
      code: 'EG-KFS'
    },
    {
      name: 'Maţrūḩ',
      code: 'EG-MT'
    },
    {
      name: 'Qinā',
      code: 'EG-KN'
    },
    {
      name: "Shamāl Sīnā'",
      code: 'EG-SIN'
    },
    {
      name: 'Sūhāj',
      code: 'EG-SHG'
    },
    {
      name: 'Ḩulwān',
      code: 'EG-HU'
    }
  ],
  ES: [
    {
      name: 'A Coruña',
      code: 'ES-C',
      subdivision: 'Province'
    },
    {
      name: 'Albacete',
      code: 'ES-AB',
      subdivision: 'Province'
    },
    {
      name: 'Alicante',
      code: 'ES-A',
      subdivision: 'Province'
    },
    {
      name: 'Almería',
      code: 'ES-AL',
      subdivision: 'Province'
    },
    {
      name: 'Asturias',
      code: 'ES-O',
      subdivision: 'Province'
    },
    {
      name: 'Badajoz',
      code: 'ES-BA',
      subdivision: 'Province'
    },
    {
      name: 'Balears',
      code: 'ES-PM',
      subdivision: 'Province'
    },
    {
      name: 'Barcelona',
      code: 'ES-B',
      subdivision: 'Province'
    },
    {
      name: 'Burgos',
      code: 'ES-BU',
      subdivision: 'Province'
    },
    {
      name: 'Cantabria',
      code: 'ES-S',
      subdivision: 'Province'
    },
    {
      name: 'Castellón',
      code: 'ES-CS',
      subdivision: 'Province'
    },
    {
      name: 'Ciudad Real',
      code: 'ES-CR',
      subdivision: 'Province'
    },
    {
      name: 'Cuenca',
      code: 'ES-CU',
      subdivision: 'Province'
    },
    {
      name: 'Cáceres',
      code: 'ES-CC',
      subdivision: 'Province'
    },
    {
      name: 'Cádiz',
      code: 'ES-CA',
      subdivision: 'Province'
    },
    {
      name: 'Córdoba',
      code: 'ES-CO',
      subdivision: 'Province'
    },
    {
      name: 'Girona',
      code: 'ES-GI',
      subdivision: 'Province'
    },
    {
      name: 'Granada',
      code: 'ES-GR',
      subdivision: 'Province'
    },
    {
      name: 'Guadalajara',
      code: 'ES-GU',
      subdivision: 'Province'
    },
    {
      name: 'Guipúzcoa',
      code: 'ES-SS',
      subdivision: 'Province'
    },
    {
      name: 'Huelva',
      code: 'ES-H',
      subdivision: 'Province'
    },
    {
      name: 'Huesca',
      code: 'ES-HU',
      subdivision: 'Province'
    },
    {
      name: 'Jaén',
      code: 'ES-J',
      subdivision: 'Province'
    },
    {
      name: 'La Rioja',
      code: 'ES-LO',
      subdivision: 'Province'
    },
    {
      name: 'Las Palmas',
      code: 'ES-GC',
      subdivision: 'Province'
    },
    {
      name: 'León',
      code: 'ES-LE',
      subdivision: 'Province'
    },
    {
      name: 'Lleida',
      code: 'ES-L',
      subdivision: 'Province'
    },
    {
      name: 'Lugo',
      code: 'ES-LU',
      subdivision: 'Province'
    },
    {
      name: 'Madrid',
      code: 'ES-M',
      subdivision: 'Province'
    },
    {
      name: 'Murcia',
      code: 'ES-MU',
      subdivision: 'Province'
    },
    {
      name: 'Málaga',
      code: 'ES-MA',
      subdivision: 'Province'
    },
    {
      name: 'Navarra',
      code: 'ES-NA',
      subdivision: 'Province'
    },
    {
      name: 'Ourense',
      code: 'ES-OR',
      subdivision: 'Province'
    },
    {
      name: 'Palencia',
      code: 'ES-P',
      subdivision: 'Province'
    },
    {
      name: 'Pontevedra',
      code: 'ES-PO',
      subdivision: 'Province'
    },
    {
      name: 'Salamanca',
      code: 'ES-SA',
      subdivision: 'Province'
    },
    {
      name: 'Santa Cruz de Tenerife',
      code: 'ES-TF',
      subdivision: 'Province'
    },
    {
      name: 'Segovia',
      code: 'ES-SG',
      subdivision: 'Province'
    },
    {
      name: 'Sevilla',
      code: 'ES-SE',
      subdivision: 'Province'
    },
    {
      name: 'Soria',
      code: 'ES-SO',
      subdivision: 'Province'
    },
    {
      name: 'Tarragona',
      code: 'ES-T',
      subdivision: 'Province'
    },
    {
      name: 'Teruel',
      code: 'ES-TE',
      subdivision: 'Province'
    },
    {
      name: 'Toledo',
      code: 'ES-TO',
      subdivision: 'Province'
    },
    {
      name: 'Valencia',
      code: 'ES-V',
      subdivision: 'Province'
    },
    {
      name: 'Valladolid',
      code: 'ES-VA',
      subdivision: 'Province'
    },
    {
      name: 'Vizcaya',
      code: 'ES-BI',
      subdivision: 'Province'
    },
    {
      name: 'Zamora',
      code: 'ES-ZA',
      subdivision: 'Province'
    },
    {
      name: 'Zaragoza',
      code: 'ES-Z',
      subdivision: 'Province'
    },
    {
      name: 'Álava',
      code: 'ES-VI',
      subdivision: 'Province'
    },
    {
      name: 'Ávila',
      code: 'ES-AV',
      subdivision: 'Province'
    },
    {
      name: 'Ceuta',
      code: 'ES-CE',
      subdivision: 'autonomous city in North Africa'
    },
    {
      name: 'Melilla',
      code: 'ES-ML',
      subdivision: 'autonomous city in North Africa'
    },
    {
      name: 'Andalucía',
      code: 'ES-AN',
      subdivision: 'autonomous community'
    },
    {
      name: 'Aragón',
      code: 'ES-AR',
      subdivision: 'autonomous community'
    },
    {
      name: 'Asturias, Principado de',
      code: 'ES-AS',
      subdivision: 'autonomous community'
    },
    {
      name: 'Canarias',
      code: 'ES-CN',
      subdivision: 'autonomous community'
    },
    {
      name: 'Cantabria',
      code: 'ES-CB',
      subdivision: 'autonomous community'
    },
    {
      name: 'Castilla y León',
      code: 'ES-CL',
      subdivision: 'autonomous community'
    },
    {
      name: 'Castilla-La Mancha',
      code: 'ES-CM',
      subdivision: 'autonomous community'
    },
    {
      name: 'Catalunya',
      code: 'ES-CT',
      subdivision: 'autonomous community'
    },
    {
      name: 'Extremadura',
      code: 'ES-EX',
      subdivision: 'autonomous community'
    },
    {
      name: 'Galicia',
      code: 'ES-GA',
      subdivision: 'autonomous community'
    },
    {
      name: 'Illes Balears',
      code: 'ES-IB',
      subdivision: 'autonomous community'
    },
    {
      name: 'La Rioja',
      code: 'ES-RI',
      subdivision: 'autonomous community'
    },
    {
      name: 'Madrid, Comunidad de',
      code: 'ES-MD',
      subdivision: 'autonomous community'
    },
    {
      name: 'Murcia, Región de',
      code: 'ES-MC',
      subdivision: 'autonomous community'
    },
    {
      name: 'Navarra, Comunidad Foral de',
      code: 'ES-NC',
      subdivision: 'autonomous community'
    },
    {
      name: 'País Vasco',
      code: 'ES-PV',
      subdivision: 'autonomous community'
    },
    {
      name: 'Valenciana, Comunidad',
      code: 'ES-VC',
      subdivision: 'autonomous community'
    }
  ],
  ID: [
    {
      name: 'Jawa',
      code: 'ID-JW'
    },
    {
      name: 'Kalimantan',
      code: 'ID-KA'
    },
    {
      name: 'Maluku',
      code: 'ID-ML'
    },
    {
      name: 'Nusa Tenggara',
      code: 'ID-NU'
    },
    {
      name: 'Papua',
      code: 'ID-PP'
    },
    {
      name: 'Sulawesi',
      code: 'ID-SL'
    },
    {
      name: 'Sumatera',
      code: 'ID-SM'
    }
  ],
  IE: [
    {
      name: 'Connaught',
      code: 'IE-C'
    },
    {
      name: 'Leinster',
      code: 'IE-L'
    },
    {
      name: 'Munster',
      code: 'IE-M'
    },
    {
      name: 'Ulster',
      code: 'IE-U'
    }
  ],
  IN: [
    {
      name: 'Andaman and Nicobar Islands',
      code: 'IN-AN',
      subdivision: 'Union territory'
    },
    {
      name: 'Chandigarh',
      code: 'IN-CH',
      subdivision: 'Union territory'
    },
    {
      name: 'Dadra and Nagar Haveli',
      code: 'IN-DN',
      subdivision: 'Union territory'
    },
    {
      name: 'Daman and Diu',
      code: 'IN-DD',
      subdivision: 'Union territory'
    },
    {
      name: 'Delhi',
      code: 'IN-DL',
      subdivision: 'Union territory'
    },
    {
      name: 'Lakshadweep',
      code: 'IN-LD',
      subdivision: 'Union territory'
    },
    {
      name: 'Puducherry',
      code: 'IN-PY',
      subdivision: 'Union territory'
    },
    {
      name: 'Andhra Pradesh',
      code: 'IN-AP',
      subdivision: 'state'
    },
    {
      name: 'Arunachal Pradesh',
      code: 'IN-AR',
      subdivision: 'state'
    },
    {
      name: 'Assam',
      code: 'IN-AS',
      subdivision: 'state'
    },
    {
      name: 'Bihar',
      code: 'IN-BR',
      subdivision: 'state'
    },
    {
      name: 'Chhattisgarh',
      code: 'IN-CT',
      subdivision: 'state'
    },
    {
      name: 'Goa',
      code: 'IN-GA',
      subdivision: 'state'
    },
    {
      name: 'Gujarat',
      code: 'IN-GJ',
      subdivision: 'state'
    },
    {
      name: 'Haryana',
      code: 'IN-HR',
      subdivision: 'state'
    },
    {
      name: 'Himachal Pradesh',
      code: 'IN-HP',
      subdivision: 'state'
    },
    {
      name: 'Jammu and Kashmir',
      code: 'IN-JK',
      subdivision: 'state'
    },
    {
      name: 'Jharkhand',
      code: 'IN-JH',
      subdivision: 'state'
    },
    {
      name: 'Karnataka',
      code: 'IN-KA',
      subdivision: 'state'
    },
    {
      name: 'Kerala',
      code: 'IN-KL',
      subdivision: 'state'
    },
    {
      name: 'Madhya Pradesh',
      code: 'IN-MP',
      subdivision: 'state'
    },
    {
      name: 'Maharashtra',
      code: 'IN-MH',
      subdivision: 'state'
    },
    {
      name: 'Manipur',
      code: 'IN-MN',
      subdivision: 'state'
    },
    {
      name: 'Meghalaya',
      code: 'IN-ML',
      subdivision: 'state'
    },
    {
      name: 'Mizoram',
      code: 'IN-MZ',
      subdivision: 'state'
    },
    {
      name: 'Nagaland',
      code: 'IN-NL',
      subdivision: 'state'
    },
    {
      name: 'Odisha',
      code: 'IN-OR',
      subdivision: 'state'
    },
    {
      name: 'Punjab',
      code: 'IN-PB',
      subdivision: 'state'
    },
    {
      name: 'Rajasthan',
      code: 'IN-RJ',
      subdivision: 'state'
    },
    {
      name: 'Sikkim',
      code: 'IN-SK',
      subdivision: 'state'
    },
    {
      name: 'Tamil Nadu',
      code: 'IN-TN',
      subdivision: 'state'
    },
    {
      name: 'Telangana',
      code: 'IN-TG',
      subdivision: 'state'
    },
    {
      name: 'Tripura',
      code: 'IN-TR',
      subdivision: 'state'
    },
    {
      name: 'Uttar Pradesh',
      code: 'IN-UP',
      subdivision: 'state'
    },
    {
      name: 'Uttarakhand',
      code: 'IN-UT',
      subdivision: 'state'
    },
    {
      name: 'West Bengal',
      code: 'IN-WB',
      subdivision: 'state'
    }
  ],
  IR: [
    {
      name: 'Alborz',
      code: 'IR-32'
    },
    {
      name: 'Ardabīl',
      code: 'IR-03'
    },
    {
      name: 'Būshehr',
      code: 'IR-06'
    },
    {
      name: 'Chahār Maḩāll va Bakhtīārī',
      code: 'IR-08'
    },
    {
      name: 'Eşfahān',
      code: 'IR-04'
    },
    {
      name: 'Fārs',
      code: 'IR-14'
    },
    {
      name: 'Golestān',
      code: 'IR-27'
    },
    {
      name: 'Gīlān',
      code: 'IR-19'
    },
    {
      name: 'Hamadān',
      code: 'IR-24'
    },
    {
      name: 'Hormozgān',
      code: 'IR-23'
    },
    {
      name: 'Kermān',
      code: 'IR-15'
    },
    {
      name: 'Kermānshāh',
      code: 'IR-17'
    },
    {
      name: 'Khorāsān-e Janūbī',
      code: 'IR-29'
    },
    {
      name: 'Khorāsān-e Razavī',
      code: 'IR-30'
    },
    {
      name: 'Khorāsān-e Shemālī',
      code: 'IR-31'
    },
    {
      name: 'Khūzestān',
      code: 'IR-10'
    },
    {
      name: 'Kohgīlūyeh va Būyer Aḩmad',
      code: 'IR-18'
    },
    {
      name: 'Kordestān',
      code: 'IR-16'
    },
    {
      name: 'Lorestān',
      code: 'IR-20'
    },
    {
      name: 'Markazī',
      code: 'IR-22'
    },
    {
      name: 'Māzandarān',
      code: 'IR-21'
    },
    {
      name: 'Qazvīn',
      code: 'IR-28'
    },
    {
      name: 'Qom',
      code: 'IR-26'
    },
    {
      name: 'Semnān',
      code: 'IR-12'
    },
    {
      name: 'Sīstān va Balūchestān',
      code: 'IR-13'
    },
    {
      name: 'Tehrān',
      code: 'IR-07'
    },
    {
      name: 'Yazd',
      code: 'IR-25'
    },
    {
      name: 'Zanjān',
      code: 'IR-11'
    },
    {
      name: 'Āz̄arbāyjān-e Gharbī',
      code: 'IR-02'
    },
    {
      name: 'Āz̄arbāyjān-e Sharqī',
      code: 'IR-01'
    },
    {
      name: 'Īlām',
      code: 'IR-05'
    }
  ],
  IT: [
    {
      name: 'Abruzzo',
      code: 'IT-65'
    },
    {
      name: 'Basilicata',
      code: 'IT-77'
    },
    {
      name: 'Calabria',
      code: 'IT-78'
    },
    {
      name: 'Campania',
      code: 'IT-72'
    },
    {
      name: 'Emilia-Romagna',
      code: 'IT-45'
    },
    {
      name: 'Friuli-Venezia Giulia',
      code: 'IT-36'
    },
    {
      name: 'Lazio',
      code: 'IT-62'
    },
    {
      name: 'Liguria',
      code: 'IT-42'
    },
    {
      name: 'Lombardia',
      code: 'IT-25'
    },
    {
      name: 'Marche',
      code: 'IT-57'
    },
    {
      name: 'Molise',
      code: 'IT-67'
    },
    {
      name: 'Piemonte',
      code: 'IT-21'
    },
    {
      name: 'Puglia',
      code: 'IT-75'
    },
    {
      name: 'Sardegna',
      code: 'IT-88'
    },
    {
      name: 'Sicilia',
      code: 'IT-82'
    },
    {
      name: 'Toscana',
      code: 'IT-52'
    },
    {
      name: 'Trentino-Alto Adige',
      code: 'IT-32'
    },
    {
      name: 'Umbria',
      code: 'IT-55'
    },
    {
      name: "Valle d'Aosta",
      code: 'IT-23'
    },
    {
      name: 'Veneto',
      code: 'IT-34'
    }
  ],
  IQ: [
    {
      name: 'Al Anbār',
      code: 'IQ-AN'
    },
    {
      name: 'Al Başrah',
      code: 'IQ-BA'
    },
    {
      name: 'Al Muthanná',
      code: 'IQ-MU'
    },
    {
      name: 'Al Qādisīyah',
      code: 'IQ-QA'
    },
    {
      name: 'An Najaf',
      code: 'IQ-NA'
    },
    {
      name: 'Arbīl',
      code: 'IQ-AR'
    },
    {
      name: 'As Sulaymānīyah',
      code: 'IQ-SU'
    },
    {
      name: "At Ta'mīm",
      code: 'IQ-TS'
    },
    {
      name: 'Baghdād',
      code: 'IQ-BG'
    },
    {
      name: 'Bābil',
      code: 'IQ-BB'
    },
    {
      name: 'Dahūk',
      code: 'IQ-DA'
    },
    {
      name: 'Dhī Qār',
      code: 'IQ-DQ'
    },
    {
      name: 'Diyālá',
      code: 'IQ-DI'
    },
    {
      name: "Karbalā'",
      code: 'IQ-KA'
    },
    {
      name: 'Maysān',
      code: 'IQ-MA'
    },
    {
      name: 'Nīnawá',
      code: 'IQ-NI'
    },
    {
      name: 'Wāsiţ',
      code: 'IQ-WA'
    },
    {
      name: 'Şalāḩ ad Dīn',
      code: 'IQ-SD'
    }
  ],
  JM: [
    {
      name: 'Clarendon',
      code: 'JM-13'
    },
    {
      name: 'Hanover',
      code: 'JM-09'
    },
    {
      name: 'Kingston',
      code: 'JM-01'
    },
    {
      name: 'Manchester',
      code: 'JM-12'
    },
    {
      name: 'Portland',
      code: 'JM-04'
    },
    {
      name: 'Saint Andrew',
      code: 'JM-02'
    },
    {
      name: 'Saint Ann',
      code: 'JM-06'
    },
    {
      name: 'Saint Catherine',
      code: 'JM-14'
    },
    {
      name: 'Saint Elizabeth',
      code: 'JM-11'
    },
    {
      name: 'Saint James',
      code: 'JM-08'
    },
    {
      name: 'Saint Mary',
      code: 'JM-05'
    },
    {
      name: 'Saint Thomas',
      code: 'JM-03'
    },
    {
      name: 'Trelawny',
      code: 'JM-07'
    },
    {
      name: 'Westmoreland',
      code: 'JM-10'
    }
  ],
  JP: [
    {
      name: 'Aiti',
      code: 'JP-23'
    },
    {
      name: 'Akita',
      code: 'JP-05'
    },
    {
      name: 'Aomori',
      code: 'JP-02'
    },
    {
      name: 'Ehime',
      code: 'JP-38'
    },
    {
      name: 'Gihu',
      code: 'JP-21'
    },
    {
      name: 'Gunma',
      code: 'JP-10'
    },
    {
      name: 'Hirosima',
      code: 'JP-34'
    },
    {
      name: 'Hokkaidô',
      code: 'JP-01'
    },
    {
      name: 'Hukui',
      code: 'JP-18'
    },
    {
      name: 'Hukuoka',
      code: 'JP-40'
    },
    {
      name: 'Hukusima',
      code: 'JP-07'
    },
    {
      name: 'Hyôgo',
      code: 'JP-28'
    },
    {
      name: 'Ibaraki',
      code: 'JP-08'
    },
    {
      name: 'Isikawa',
      code: 'JP-17'
    },
    {
      name: 'Iwate',
      code: 'JP-03'
    },
    {
      name: 'Kagawa',
      code: 'JP-37'
    },
    {
      name: 'Kagosima',
      code: 'JP-46'
    },
    {
      name: 'Kanagawa',
      code: 'JP-14'
    },
    {
      name: 'Kumamoto',
      code: 'JP-43'
    },
    {
      name: 'Kyôto',
      code: 'JP-26'
    },
    {
      name: 'Kôti',
      code: 'JP-39'
    },
    {
      name: 'Mie',
      code: 'JP-24'
    },
    {
      name: 'Miyagi',
      code: 'JP-04'
    },
    {
      name: 'Miyazaki',
      code: 'JP-45'
    },
    {
      name: 'Nagano',
      code: 'JP-20'
    },
    {
      name: 'Nagasaki',
      code: 'JP-42'
    },
    {
      name: 'Nara',
      code: 'JP-29'
    },
    {
      name: 'Niigata',
      code: 'JP-15'
    },
    {
      name: 'Okayama',
      code: 'JP-33'
    },
    {
      name: 'Okinawa',
      code: 'JP-47'
    },
    {
      name: 'Saga',
      code: 'JP-41'
    },
    {
      name: 'Saitama',
      code: 'JP-11'
    },
    {
      name: 'Siga',
      code: 'JP-25'
    },
    {
      name: 'Simane',
      code: 'JP-32'
    },
    {
      name: 'Sizuoka',
      code: 'JP-22'
    },
    {
      name: 'Tiba',
      code: 'JP-12'
    },
    {
      name: 'Tokusima',
      code: 'JP-36'
    },
    {
      name: 'Totigi',
      code: 'JP-09'
    },
    {
      name: 'Tottori',
      code: 'JP-31'
    },
    {
      name: 'Toyama',
      code: 'JP-16'
    },
    {
      name: 'Tôkyô',
      code: 'JP-13'
    },
    {
      name: 'Wakayama',
      code: 'JP-30'
    },
    {
      name: 'Yamagata',
      code: 'JP-06'
    },
    {
      name: 'Yamaguti',
      code: 'JP-35'
    },
    {
      name: 'Yamanasi',
      code: 'JP-19'
    },
    {
      name: 'Ôita',
      code: 'JP-44'
    },
    {
      name: 'Ôsaka',
      code: 'JP-27'
    }
  ],
  KN: [
    {
      name: 'Nevis',
      code: 'KN-N'
    },
    {
      name: 'Saint Kitts',
      code: 'KN-K'
    }
  ],
  KP: [
    {
      name: 'Chagang',
      code: 'KP-04'
    },
    {
      name: 'Kangwon',
      code: 'KP-07'
    },
    {
      name: 'North Hamgyong',
      code: 'KP-09'
    },
    {
      name: 'North Hwanghae',
      code: 'KP-06'
    },
    {
      name: 'North Pyongan',
      code: 'KP-03'
    },
    {
      name: 'Pyongyang',
      code: 'KP-01'
    },
    {
      name: 'Rason',
      code: 'KP-13'
    },
    {
      name: 'Ryanggang',
      code: 'KP-10'
    },
    {
      name: 'South Hamgyong',
      code: 'KP-08'
    },
    {
      name: 'South Hwanghae',
      code: 'KP-05'
    },
    {
      name: 'South Pyongan',
      code: 'KP-02'
    }
  ],
  MN: [
    {
      name: 'Arhangay',
      code: 'MN-073'
    },
    {
      name: 'Bayan-Ölgiy',
      code: 'MN-071'
    },
    {
      name: 'Bayanhongor',
      code: 'MN-069'
    },
    {
      name: 'Bulgan',
      code: 'MN-067'
    },
    {
      name: 'Darhan uul',
      code: 'MN-037'
    },
    {
      name: 'Dornod',
      code: 'MN-061'
    },
    {
      name: 'Dornogovĭ',
      code: 'MN-063'
    },
    {
      name: 'Dundgovĭ',
      code: 'MN-059'
    },
    {
      name: 'Dzavhan',
      code: 'MN-057'
    },
    {
      name: 'Govĭ-Altay',
      code: 'MN-065'
    },
    {
      name: 'Govĭ-Sümber',
      code: 'MN-064'
    },
    {
      name: 'Hentiy',
      code: 'MN-039'
    },
    {
      name: 'Hovd',
      code: 'MN-043'
    },
    {
      name: 'Hövsgöl',
      code: 'MN-041'
    },
    {
      name: 'Orhon',
      code: 'MN-035'
    },
    {
      name: 'Selenge',
      code: 'MN-049'
    },
    {
      name: 'Sühbaatar',
      code: 'MN-051'
    },
    {
      name: 'Töv',
      code: 'MN-047'
    },
    {
      name: 'Ulaanbaatar',
      code: 'MN-1'
    },
    {
      name: 'Uvs',
      code: 'MN-046'
    },
    {
      name: 'Ömnögovĭ',
      code: 'MN-053'
    },
    {
      name: 'Övörhangay',
      code: 'MN-055'
    }
  ],
  MY: [
    {
      name: 'Wilayah Persekutuan Kuala Lumpur',
      code: 'MY-14',
      subdivision: 'federal territory'
    },
    {
      name: 'Wilayah Persekutuan Labuan',
      code: 'MY-15',
      subdivision: 'federal territory'
    },
    {
      name: 'Wilayah Persekutuan Putrajaya',
      code: 'MY-16',
      subdivision: 'federal territory'
    },
    {
      name: 'Johor',
      code: 'MY-01',
      subdivision: 'state'
    },
    {
      name: 'Kedah',
      code: 'MY-02',
      subdivision: 'state'
    },
    {
      name: 'Kelantan',
      code: 'MY-03',
      subdivision: 'state'
    },
    {
      name: 'Melaka',
      code: 'MY-04',
      subdivision: 'state'
    },
    {
      name: 'Negeri Sembilan',
      code: 'MY-05',
      subdivision: 'state'
    },
    {
      name: 'Pahang',
      code: 'MY-06',
      subdivision: 'state'
    },
    {
      name: 'Perak',
      code: 'MY-08',
      subdivision: 'state'
    },
    {
      name: 'Perlis',
      code: 'MY-09',
      subdivision: 'state'
    },
    {
      name: 'Pulau Pinang',
      code: 'MY-07',
      subdivision: 'state'
    },
    {
      name: 'Sabah',
      code: 'MY-12',
      subdivision: 'state'
    },
    {
      name: 'Sarawak',
      code: 'MY-13',
      subdivision: 'state'
    },
    {
      name: 'Selangor',
      code: 'MY-10',
      subdivision: 'state'
    },
    {
      name: 'Terengganu',
      code: 'MY-11',
      subdivision: 'state'
    }
  ],
  MX: [
    {
      name: 'Distrito Federal',
      code: 'MX-DIF',
      subdivision: 'federal district'
    },
    {
      name: 'Aguascalientes',
      code: 'MX-AGU',
      subdivision: 'state'
    },
    {
      name: 'Baja California',
      code: 'MX-BCN',
      subdivision: 'state'
    },
    {
      name: 'Baja California Sur',
      code: 'MX-BCS',
      subdivision: 'state'
    },
    {
      name: 'Campeche',
      code: 'MX-CAM',
      subdivision: 'state'
    },
    {
      name: 'Chiapas',
      code: 'MX-CHP',
      subdivision: 'state'
    },
    {
      name: 'Chihuahua',
      code: 'MX-CHH',
      subdivision: 'state'
    },
    {
      name: 'Coahuila',
      code: 'MX-COA',
      subdivision: 'state'
    },
    {
      name: 'Colima',
      code: 'MX-COL',
      subdivision: 'state'
    },
    {
      name: 'Durango',
      code: 'MX-DUR',
      subdivision: 'state'
    },
    {
      name: 'Guanajuato',
      code: 'MX-GUA',
      subdivision: 'state'
    },
    {
      name: 'Guerrero',
      code: 'MX-GRO',
      subdivision: 'state'
    },
    {
      name: 'Hidalgo',
      code: 'MX-HID',
      subdivision: 'state'
    },
    {
      name: 'Jalisco',
      code: 'MX-JAL',
      subdivision: 'state'
    },
    {
      name: 'Michoacán',
      code: 'MX-MIC',
      subdivision: 'state'
    },
    {
      name: 'Morelos',
      code: 'MX-MOR',
      subdivision: 'state'
    },
    {
      name: 'México',
      code: 'MX-MEX',
      subdivision: 'state'
    },
    {
      name: 'Nayarit',
      code: 'MX-NAY',
      subdivision: 'state'
    },
    {
      name: 'Nuevo León',
      code: 'MX-NLE',
      subdivision: 'state'
    },
    {
      name: 'Oaxaca',
      code: 'MX-OAX',
      subdivision: 'state'
    },
    {
      name: 'Puebla',
      code: 'MX-PUE',
      subdivision: 'state'
    },
    {
      name: 'Querétaro',
      code: 'MX-QUE',
      subdivision: 'state'
    },
    {
      name: 'Quintana Roo',
      code: 'MX-ROO',
      subdivision: 'state'
    },
    {
      name: 'San Luis Potosí',
      code: 'MX-SLP',
      subdivision: 'state'
    },
    {
      name: 'Sinaloa',
      code: 'MX-SIN',
      subdivision: 'state'
    },
    {
      name: 'Sonora',
      code: 'MX-SON',
      subdivision: 'state'
    },
    {
      name: 'Tabasco',
      code: 'MX-TAB',
      subdivision: 'state'
    },
    {
      name: 'Tamaulipas',
      code: 'MX-TAM',
      subdivision: 'state'
    },
    {
      name: 'Tlaxcala',
      code: 'MX-TLA',
      subdivision: 'state'
    },
    {
      name: 'Veracruz',
      code: 'MX-VER',
      subdivision: 'state'
    },
    {
      name: 'Yucatán',
      code: 'MX-YUC',
      subdivision: 'state'
    },
    {
      name: 'Zacatecas',
      code: 'MX-ZAC',
      subdivision: 'state'
    }
  ],
  MZ: [
    {
      name: 'Cabo Delgado',
      code: 'MZ-P'
    },
    {
      name: 'Gaza',
      code: 'MZ-G'
    },
    {
      name: 'Inhambane',
      code: 'MZ-I'
    },
    {
      name: 'Manica',
      code: 'MZ-B'
    },
    {
      name: 'Maputo',
      code: 'MZ-MPM'
    },
    {
      name: 'Maputo',
      code: 'MZ-L'
    },
    {
      name: 'Nampula',
      code: 'MZ-N'
    },
    {
      name: 'Niassa',
      code: 'MZ-A'
    },
    {
      name: 'Sofala',
      code: 'MZ-S'
    },
    {
      name: 'Tete',
      code: 'MZ-T'
    },
    {
      name: 'Zambézia',
      code: 'MZ-Q'
    }
  ],
  NI: [
    {
      name: 'Atlántico Norte',
      code: 'NI-AN'
    },
    {
      name: 'Atlántico Sur',
      code: 'NI-AS'
    },
    {
      name: 'Boaco',
      code: 'NI-BO'
    },
    {
      name: 'Carazo',
      code: 'NI-CA'
    },
    {
      name: 'Chinandega',
      code: 'NI-CI'
    },
    {
      name: 'Chontales',
      code: 'NI-CO'
    },
    {
      name: 'Estelí',
      code: 'NI-ES'
    },
    {
      name: 'Granada',
      code: 'NI-GR'
    },
    {
      name: 'Jinotega',
      code: 'NI-JI'
    },
    {
      name: 'León',
      code: 'NI-LE'
    },
    {
      name: 'Madriz',
      code: 'NI-MD'
    },
    {
      name: 'Managua',
      code: 'NI-MN'
    },
    {
      name: 'Masaya',
      code: 'NI-MS'
    },
    {
      name: 'Matagalpa',
      code: 'NI-MT'
    },
    {
      name: 'Nueva Segovia',
      code: 'NI-NS'
    },
    {
      name: 'Rivas',
      code: 'NI-RI'
    },
    {
      name: 'Río San Juan',
      code: 'NI-SJ'
    }
  ],
  NR: [
    {
      name: 'Aiwo',
      code: 'NR-01'
    },
    {
      name: 'Anabar',
      code: 'NR-02'
    },
    {
      name: 'Anetan',
      code: 'NR-03'
    },
    {
      name: 'Anibare',
      code: 'NR-04'
    },
    {
      name: 'Baiti',
      code: 'NR-05'
    },
    {
      name: 'Boe',
      code: 'NR-06'
    },
    {
      name: 'Buada',
      code: 'NR-07'
    },
    {
      name: 'Denigomodu',
      code: 'NR-08'
    },
    {
      name: 'Ewa',
      code: 'NR-09'
    },
    {
      name: 'Ijuw',
      code: 'NR-10'
    },
    {
      name: 'Meneng',
      code: 'NR-11'
    },
    {
      name: 'Nibok',
      code: 'NR-12'
    },
    {
      name: 'Uaboe',
      code: 'NR-13'
    },
    {
      name: 'Yaren',
      code: 'NR-14'
    }
  ],
  PA: [
    {
      name: 'Bocas del Toro',
      code: 'PA-1'
    },
    {
      name: 'Chiriquí',
      code: 'PA-4'
    },
    {
      name: 'Coclé',
      code: 'PA-2'
    },
    {
      name: 'Colón',
      code: 'PA-3'
    },
    {
      name: 'Darién',
      code: 'PA-5'
    },
    {
      name: 'Emberá',
      code: 'PA-EM'
    },
    {
      name: 'Herrera',
      code: 'PA-6'
    },
    {
      name: 'Kuna Yala',
      code: 'PA-KY'
    },
    {
      name: 'Los Santos',
      code: 'PA-7'
    },
    {
      name: 'Ngöbe-Buglé',
      code: 'PA-NB'
    },
    {
      name: 'Panamá',
      code: 'PA-8'
    },
    {
      name: 'Panamá Oeste',
      code: 'PA-10'
    },
    {
      name: 'Veraguas',
      code: 'PA-9'
    }
  ],
  PE: [
    {
      name: 'Amazonas',
      code: 'PE-AMA'
    },
    {
      name: 'Ancash',
      code: 'PE-ANC'
    },
    {
      name: 'Apurímac',
      code: 'PE-APU'
    },
    {
      name: 'Arequipa',
      code: 'PE-ARE'
    },
    {
      name: 'Ayacucho',
      code: 'PE-AYA'
    },
    {
      name: 'Cajamarca',
      code: 'PE-CAJ'
    },
    {
      name: 'Cusco',
      code: 'PE-CUS'
    },
    {
      name: 'El Callao',
      code: 'PE-CAL'
    },
    {
      name: 'Huancavelica',
      code: 'PE-HUV'
    },
    {
      name: 'Huánuco',
      code: 'PE-HUC'
    },
    {
      name: 'Ica',
      code: 'PE-ICA'
    },
    {
      name: 'Junín',
      code: 'PE-JUN'
    },
    {
      name: 'La Libertad',
      code: 'PE-LAL'
    },
    {
      name: 'Lambayeque',
      code: 'PE-LAM'
    },
    {
      name: 'Lima',
      code: 'PE-LIM'
    },
    {
      name: 'Loreto',
      code: 'PE-LOR'
    },
    {
      name: 'Madre de Dios',
      code: 'PE-MDD'
    },
    {
      name: 'Moquegua',
      code: 'PE-MOQ'
    },
    {
      name: 'Municipalidad Metropolitana de Lima',
      code: 'PE-LMA'
    },
    {
      name: 'Pasco',
      code: 'PE-PAS'
    },
    {
      name: 'Piura',
      code: 'PE-PIU'
    },
    {
      name: 'Puno',
      code: 'PE-PUN'
    },
    {
      name: 'San Martín',
      code: 'PE-SAM'
    },
    {
      name: 'Tacna',
      code: 'PE-TAC'
    },
    {
      name: 'Tumbes',
      code: 'PE-TUM'
    },
    {
      name: 'Ucayali',
      code: 'PE-UCA'
    }
  ],
  PH: [
    {
      name: 'Autonomous Region in Muslim Mindanao',
      code: 'PH-14'
    },
    {
      name: 'Bicol',
      code: 'PH-05'
    },
    {
      name: 'Cagayan Valley',
      code: 'PH-02'
    },
    {
      name: 'Calabarzon',
      code: 'PH-40'
    },
    {
      name: 'Caraga',
      code: 'PH-13'
    },
    {
      name: 'Central Luzon',
      code: 'PH-03'
    },
    {
      name: 'Central Visayas',
      code: 'PH-07'
    },
    {
      name: 'Cordillera Administrative Region',
      code: 'PH-15'
    },
    {
      name: 'Davao',
      code: 'PH-11'
    },
    {
      name: 'Eastern Visayas',
      code: 'PH-08'
    },
    {
      name: 'Ilocos',
      code: 'PH-01'
    },
    {
      name: 'Mimaropa',
      code: 'PH-41'
    },
    {
      name: 'National Capital Region',
      code: 'PH-00'
    },
    {
      name: 'Northern Mindanao',
      code: 'PH-10'
    },
    {
      name: 'Soccsksargen',
      code: 'PH-12'
    },
    {
      name: 'Western Visayas',
      code: 'PH-06'
    },
    {
      name: 'Zamboanga Peninsula',
      code: 'PH-09'
    }
  ],
  PG: [
    {
      name: 'Bougainville',
      code: 'PG-NSB'
    },
    {
      name: 'Central',
      code: 'PG-CPM'
    },
    {
      name: 'Chimbu',
      code: 'PG-CPK'
    },
    {
      name: 'East New Britain',
      code: 'PG-EBR'
    },
    {
      name: 'East Sepik',
      code: 'PG-ESW'
    },
    {
      name: 'Eastern Highlands',
      code: 'PG-EHG'
    },
    {
      name: 'Enga',
      code: 'PG-EPW'
    },
    {
      name: 'Gulf',
      code: 'PG-GPK'
    },
    {
      name: 'Madang',
      code: 'PG-MPM'
    },
    {
      name: 'Manus',
      code: 'PG-MRL'
    },
    {
      name: 'Milne Bay',
      code: 'PG-MBA'
    },
    {
      name: 'Morobe',
      code: 'PG-MPL'
    },
    {
      name: 'National Capital District',
      code: 'PG-NCD'
    },
    {
      name: 'New Ireland',
      code: 'PG-NIK'
    },
    {
      name: 'Northern',
      code: 'PG-NPP'
    },
    {
      name: 'Sandaun',
      code: 'PG-SAN'
    },
    {
      name: 'Southern Highlands',
      code: 'PG-SHM'
    },
    {
      name: 'West New Britain',
      code: 'PG-WBK'
    },
    {
      name: 'Western',
      code: 'PG-WPD'
    },
    {
      name: 'Western Highlands',
      code: 'PG-WHM'
    }
  ],
  KI: [
    {
      name: 'Gilbert Islands',
      code: 'KI-G'
    },
    {
      name: 'Line Islands',
      code: 'KI-L'
    },
    {
      name: 'Phoenix Islands',
      code: 'KI-P'
    }
  ],
  KR: [
    {
      name: 'Busan-gwangyeoksi',
      code: 'KR-26'
    },
    {
      name: 'Chungcheongbuk-do',
      code: 'KR-43'
    },
    {
      name: 'Chungcheongnam-do',
      code: 'KR-44'
    },
    {
      name: 'Daegu-gwangyeoksi',
      code: 'KR-27'
    },
    {
      name: 'Daejeon-gwangyeoksi',
      code: 'KR-30'
    },
    {
      name: 'Gangwon-do',
      code: 'KR-42'
    },
    {
      name: 'Gwangju-gwangyeoksi',
      code: 'KR-29'
    },
    {
      name: 'Gyeonggi-do',
      code: 'KR-41'
    },
    {
      name: 'Gyeongsangbuk-do',
      code: 'KR-47'
    },
    {
      name: 'Gyeongsangnam-do',
      code: 'KR-48'
    },
    {
      name: 'Incheon-gwangyeoksi',
      code: 'KR-28'
    },
    {
      name: 'Jeju-teukbyeoljachido',
      code: 'KR-49'
    },
    {
      name: 'Jeollabuk-do',
      code: 'KR-45'
    },
    {
      name: 'Jeollanam-do',
      code: 'KR-46'
    },
    {
      name: 'Sejong',
      code: 'KR-50'
    },
    {
      name: 'Seoul-teukbyeolsi',
      code: 'KR-11'
    },
    {
      name: 'Ulsan-gwangyeoksi',
      code: 'KR-31'
    }
  ],
  KZ: [
    {
      name: 'Almaty',
      code: 'KZ-ALA'
    },
    {
      name: 'Almaty oblysy',
      code: 'KZ-ALM'
    },
    {
      name: 'Aqmola oblysy',
      code: 'KZ-AKM'
    },
    {
      name: 'Aqtöbe oblysy',
      code: 'KZ-AKT'
    },
    {
      name: 'Astana',
      code: 'KZ-AST'
    },
    {
      name: 'Atyraū oblysy',
      code: 'KZ-ATY'
    },
    {
      name: 'Batys Qazaqstan oblysy',
      code: 'KZ-ZAP'
    },
    {
      name: 'Mangghystaū oblysy',
      code: 'KZ-MAN'
    },
    {
      name: 'Ongtüstik Qazaqstan oblysy',
      code: 'KZ-YUZ'
    },
    {
      name: 'Pavlodar oblysy',
      code: 'KZ-PAV'
    },
    {
      name: 'Qaraghandy oblysy',
      code: 'KZ-KAR'
    },
    {
      name: 'Qostanay oblysy',
      code: 'KZ-KUS'
    },
    {
      name: 'Qyzylorda oblysy',
      code: 'KZ-KZY'
    },
    {
      name: 'Shyghys Qazaqstan oblysy',
      code: 'KZ-VOS'
    },
    {
      name: 'Soltüstik Qazaqstan oblysy',
      code: 'KZ-SEV'
    },
    {
      name: 'Zhambyl oblysy',
      code: 'KZ-ZHA'
    }
  ],
  HK: [
    { name: 'Hong Kong Island', code: 'HK-HONG KONG' },
    { name: 'Kowloon', code: 'HK-KOWLOON' },
    { name: 'New Territories', code: 'HK-NEW TERRITORIES' }
  ],
  HN: [
    {
      name: 'Atlántida',
      code: 'HN-AT'
    },
    {
      name: 'Choluteca',
      code: 'HN-CH'
    },
    {
      name: 'Colón',
      code: 'HN-CL'
    },
    {
      name: 'Comayagua',
      code: 'HN-CM'
    },
    {
      name: 'Copán',
      code: 'HN-CP'
    },
    {
      name: 'Cortés',
      code: 'HN-CR'
    },
    {
      name: 'El Paraíso',
      code: 'HN-EP'
    },
    {
      name: 'Francisco Morazán',
      code: 'HN-FM'
    },
    {
      name: 'Gracias a Dios',
      code: 'HN-GD'
    },
    {
      name: 'Intibucá',
      code: 'HN-IN'
    },
    {
      name: 'Islas de la Bahía',
      code: 'HN-IB'
    },
    {
      name: 'La Paz',
      code: 'HN-LP'
    },
    {
      name: 'Lempira',
      code: 'HN-LE'
    },
    {
      name: 'Ocotepeque',
      code: 'HN-OC'
    },
    {
      name: 'Olancho',
      code: 'HN-OL'
    },
    {
      name: 'Santa Bárbara',
      code: 'HN-SB'
    },
    {
      name: 'Valle',
      code: 'HN-VA'
    },
    {
      name: 'Yoro',
      code: 'HN-YO'
    }
  ],
  RU: [
    {
      name: "Amurskaya oblast'",
      code: 'RU-AMU',
      subdivision: 'administrative region',
      native: "Amurskaja oblast'"
    },
    {
      name: "Arkhangel'skaya oblast'",
      code: 'RU-ARK',
      subdivision: 'administrative region',
      native: "Arhangel'skaja oblast'"
    },
    {
      name: "Astrakhanskaya oblast'",
      code: 'RU-AST',
      subdivision: 'administrative region',
      native: "Astrahanskaja oblast'"
    },
    {
      name: "Belgorodskaya oblast'",
      code: 'RU-BEL',
      subdivision: 'administrative region',
      native: "Belgorodskaja oblast'"
    },
    {
      name: "Bryanskaya oblast'",
      code: 'RU-BRY',
      subdivision: 'administrative region',
      native: "Brjanskaja oblast'"
    },
    {
      name: "Chelyabinskaya oblast'",
      code: 'RU-CHE',
      subdivision: 'administrative region',
      native: "Čeljabinskaja oblast'"
    },
    {
      name: "Irkutskaya oblast'",
      code: 'RU-IRK',
      subdivision: 'administrative region',
      native: "Irkutskaja oblast'"
    },
    {
      name: "Ivanovskaya oblast'",
      code: 'RU-IVA',
      subdivision: 'administrative region',
      native: "Ivanovskaja oblast'"
    },
    {
      name: "Kaliningradskaya oblast'",
      code: 'RU-KGD',
      subdivision: 'administrative region',
      native: "Kaliningradskaja oblast'"
    },
    {
      name: "Kaluzhskaya oblast'",
      code: 'RU-KLU',
      subdivision: 'administrative region',
      native: "Kalužskaja oblast'"
    },
    {
      name: "Kemerovskaya oblast'",
      code: 'RU-KEM',
      subdivision: 'administrative region',
      native: "Kemerovskaja oblast'"
    },
    {
      name: "Kirovskaya oblast'",
      code: 'RU-KIR',
      subdivision: 'administrative region',
      native: "Kirovskaja oblast'"
    },
    {
      name: "Kostromskaya oblast'",
      code: 'RU-KOS',
      subdivision: 'administrative region',
      native: "Kostromskaja oblast'"
    },
    {
      name: "Kurganskaya oblast'",
      code: 'RU-KGN',
      subdivision: 'administrative region',
      native: "Kurganskaja oblast'"
    },
    {
      name: "Kurskaya oblast'",
      code: 'RU-KRS',
      subdivision: 'administrative region',
      native: "Kurskaja oblast'"
    },
    {
      name: "Leningradskaya oblast'",
      code: 'RU-LEN',
      subdivision: 'administrative region',
      native: "Leningradskaja oblast'"
    },
    {
      name: "Lipetskaya oblast'",
      code: 'RU-LIP',
      subdivision: 'administrative region',
      native: "Lipetskaja oblast'"
    },
    {
      name: "Magadanskaya oblast'",
      code: 'RU-MAG',
      subdivision: 'administrative region',
      native: "Magadanskaja oblast'"
    },
    {
      name: "Moskovskaya oblast'",
      code: 'RU-MOS',
      subdivision: 'administrative region',
      native: "Moskovskaja oblast'"
    },
    {
      name: "Murmanskaya oblast'",
      code: 'RU-MUR',
      subdivision: 'administrative region',
      native: "Murmanskaja oblast'"
    },
    {
      name: "Nizhegorodskaya oblast'",
      code: 'RU-NIZ',
      subdivision: 'administrative region',
      native: "Nižegorodskaja oblast'"
    },
    {
      name: "Novgorodskaya oblast'",
      code: 'RU-NGR',
      subdivision: 'administrative region',
      native: "Novgorodskaja oblast'"
    },
    {
      name: "Novosibirskaya oblast'",
      code: 'RU-NVS',
      subdivision: 'administrative region',
      native: "Novosibirskaja oblast'"
    },
    {
      name: "Omskaya oblast'",
      code: 'RU-OMS',
      subdivision: 'administrative region',
      native: "Omskaja oblast'"
    },
    {
      name: "Orenburgskaya oblast'",
      code: 'RU-ORE',
      subdivision: 'administrative region',
      native: "Orenburgskaja oblast'"
    },
    {
      name: "Orlovskaya oblast'",
      code: 'RU-ORL',
      subdivision: 'administrative region',
      native: "Orlovskaja oblast'"
    },
    {
      name: "Penzenskaya oblast'",
      code: 'RU-PNZ',
      subdivision: 'administrative region',
      native: "Penzenskaja oblast'"
    },
    {
      name: "Pskovskaya oblast'",
      code: 'RU-PSK',
      subdivision: 'administrative region',
      native: "Pskovskaja oblast'"
    },
    {
      name: "Rostovskaya oblast'",
      code: 'RU-ROS',
      subdivision: 'administrative region',
      native: "Rostovskaja oblast'"
    },
    {
      name: "Ryazanskaya oblast'",
      code: 'RU-RYA',
      subdivision: 'administrative region',
      native: "Rjazanskaja oblast'"
    },
    {
      name: "Sakhalinskaya oblast'",
      code: 'RU-SAK',
      subdivision: 'administrative region',
      native: "Sahalinskaja oblast'"
    },
    {
      name: "Samarskaya oblast'",
      code: 'RU-SAM',
      subdivision: 'administrative region',
      native: "Samarskaja oblast'"
    },
    {
      name: "Saratovskaya oblast'",
      code: 'RU-SAR',
      subdivision: 'administrative region',
      native: "Saratovskaja oblast'"
    },
    {
      name: "Smolenskaya oblast'",
      code: 'RU-SMO',
      subdivision: 'administrative region',
      native: "Smolenskaja oblast'"
    },
    {
      name: "Sverdlovskaya oblast'",
      code: 'RU-SVE',
      subdivision: 'administrative region',
      native: "Sverdlovskaja oblast'"
    },
    {
      name: "Tambovskaya oblast'",
      code: 'RU-TAM',
      subdivision: 'administrative region',
      native: "Tambovskaja oblast'"
    },
    {
      name: "Tomskaya oblast'",
      code: 'RU-TOM',
      subdivision: 'administrative region',
      native: "Tomskaja oblast'"
    },
    {
      name: "Tul'skaya oblast'",
      code: 'RU-TUL',
      subdivision: 'administrative region',
      native: "Tul'skaja oblast'"
    },
    {
      name: "Tverskaya oblast'",
      code: 'RU-TVE',
      subdivision: 'administrative region',
      native: "Tverskaja oblast'"
    },
    {
      name: "Tyumenskaya oblast'",
      code: 'RU-TYU',
      subdivision: 'administrative region',
      native: "Tjumenskaja oblast'"
    },
    {
      name: "Ul'yanovskaya oblast'",
      code: 'RU-ULY',
      subdivision: 'administrative region',
      native: "Ul'janovskaja oblast'"
    },
    {
      name: "Vladimirskaya oblast'",
      code: 'RU-VLA',
      subdivision: 'administrative region',
      native: "Vladimirskaja oblast'"
    },
    {
      name: "Volgogradskaya oblast'",
      code: 'RU-VGG',
      subdivision: 'administrative region',
      native: "Volgogradskaja oblast'"
    },
    {
      name: "Vologodskaya oblast'",
      code: 'RU-VLG',
      subdivision: 'administrative region',
      native: "Vologodskaja oblast'"
    },
    {
      name: "Voronezhskaya oblast'",
      code: 'RU-VOR',
      subdivision: 'administrative region',
      native: "Voronežskaja oblast'"
    },
    {
      name: "Yaroslavskaya oblast'",
      code: 'RU-YAR',
      subdivision: 'administrative region',
      native: "Jaroslavskaja oblast'"
    },
    {
      name: 'Altayskiy kray',
      code: 'RU-ALT',
      subdivision: 'administrative territory',
      native: 'Altajskij kraj'
    },
    {
      name: 'Kamchatskiy kray',
      code: 'RU-KAM',
      subdivision: 'administrative territory',
      native: 'Kamčatskij kraj'
    },
    {
      name: 'Khabarovskiy kray',
      code: 'RU-KHA',
      subdivision: 'administrative territory',
      native: 'Habarovskij kraj'
    },
    {
      name: 'Krasnodarskiy kray',
      code: 'RU-KDA',
      subdivision: 'administrative territory',
      native: 'Krasnodarskij kraj'
    },
    {
      name: 'Krasnoyarskiy kray',
      code: 'RU-KYA',
      subdivision: 'administrative territory',
      native: 'Krasnojarskij kraj'
    },
    {
      name: 'Permskiy kray',
      code: 'RU-PER',
      subdivision: 'administrative territory',
      native: 'Permskij kraj'
    },
    {
      name: 'Primorskiy kray',
      code: 'RU-PRI',
      subdivision: 'administrative territory',
      native: 'Primorskij kraj'
    },
    {
      name: "Stavropol'skiy kray",
      code: 'RU-STA',
      subdivision: 'administrative territory',
      native: "Stavropol'skij kraj"
    },
    {
      name: "Zabaykal'skiy kray",
      code: 'RU-ZAB',
      subdivision: 'administrative territory',
      native: "Zabajkal'skij kraj"
    },
    {
      name: 'Moskva',
      code: 'RU-MOW',
      subdivision: 'autonomous city',
      native: 'Moskva'
    },
    {
      name: 'Sankt-Peterburg',
      code: 'RU-SPE',
      subdivision: 'autonomous city',
      native: 'Sankt-Peterburg'
    },
    {
      name: 'Chukotskiy avtonomnyy okrug',
      code: 'RU-CHU',
      subdivision: 'autonomous district',
      native: 'Čukotskij avtonomnyj okrug'
    },
    {
      name: 'Khanty-Mansiyskiy avtonomnyy okrug-Yugra',
      code: 'RU-KHM',
      subdivision: 'autonomous district',
      native: 'Hanty-Mansijskij avtonomnyj okrug-Jugra'
    },
    {
      name: 'Nenetskiy avtonomnyy okrug',
      code: 'RU-NEN',
      subdivision: 'autonomous district',
      native: 'Nenetskij avtonomnyj okrug'
    },
    {
      name: 'Yamalo-Nenetskiy avtonomnyy okrug',
      code: 'RU-YAN',
      subdivision: 'autonomous district',
      native: 'Jamalo-Nenetskij avtonomnyj okrug'
    },
    {
      name: "Yevreyskaya avtonomnaya oblast'",
      code: 'RU-YEV',
      subdivision: 'autonomous region',
      native: "Evrejskaja avtonomnaja oblast'"
    },
    {
      name: 'Adygeya, Respublika',
      code: 'RU-AD',
      subdivision: 'republic',
      native: 'Adygeja, Respublika'
    },
    {
      name: 'Altay, Respublika',
      code: 'RU-AL',
      subdivision: 'republic',
      native: 'Altaj, Respublika'
    },
    {
      name: 'Bashkortostan, Respublika',
      code: 'RU-BA',
      subdivision: 'republic',
      native: 'Baškortostan, Respublika'
    },
    {
      name: 'Buryatiya, Respublika',
      code: 'RU-BU',
      subdivision: 'republic',
      native: 'Burjatija, Respublika'
    },
    {
      name: 'Chechenskaya Respublika',
      code: 'RU-CE',
      subdivision: 'republic',
      native: 'Čečenskaja Respublika'
    },
    {
      name: 'Chuvashskaya Respublika',
      code: 'RU-CU',
      subdivision: 'republic',
      native: 'Čuvašskaja Respublika'
    },
    {
      name: 'Dagestan, Respublika',
      code: 'RU-DA',
      subdivision: 'republic',
      native: 'Dagestan, Respublika'
    },
    {
      name: 'Ingushetiya, Respublika',
      code: 'RU-IN',
      subdivision: 'republic',
      native: 'Ingušetija, Respublika'
    },
    {
      name: 'Kabardino-Balkarskaya Respublika',
      code: 'RU-KB',
      subdivision: 'republic',
      native: 'Kabardino-Balkarskaja Respublika'
    },
    {
      name: 'Kalmykiya, Respublika',
      code: 'RU-KL',
      subdivision: 'republic',
      native: 'Kalmykija, Respublika'
    },
    {
      name: 'Karachayevo-Cherkesskaya Respublika',
      code: 'RU-KC',
      subdivision: 'republic',
      native: 'Karačajevo-Čerkesskaja Respublika'
    },
    {
      name: 'Kareliya, Respublika',
      code: 'RU-KR',
      subdivision: 'republic',
      native: 'Karelija, Respublika'
    },
    {
      name: 'Khakasiya, Respublika',
      code: 'RU-KK',
      subdivision: 'republic',
      native: 'Hakasija, Respublika'
    },
    {
      name: 'Komi, Respublika',
      code: 'RU-KO',
      subdivision: 'republic',
      native: 'Komi, Respublika'
    },
    {
      name: 'Mariy El, Respublika',
      code: 'RU-ME',
      subdivision: 'republic',
      native: 'Marij Èl, Respublika'
    },
    {
      name: 'Mordoviya, Respublika',
      code: 'RU-MO',
      subdivision: 'republic',
      native: 'Mordovija, Respublika'
    },
    {
      name: 'Sakha, Respublika',
      code: 'RU-SA',
      subdivision: 'republic',
      native: 'Saha, Respublika'
    },
    {
      name: 'Severnaya Osetiya-Alaniya, Respublika',
      code: 'RU-SE',
      subdivision: 'republic',
      native: 'Severnaja Osetija-Alanija, Respublika'
    },
    {
      name: 'Tatarstan, Respublika',
      code: 'RU-TA',
      subdivision: 'republic',
      native: 'Tatarstan, Respublika'
    },
    {
      name: 'Tyva, Respublika',
      code: 'RU-TY',
      subdivision: 'republic',
      native: 'Tyva, Respublika'
    },
    {
      name: 'Udmurtskaya Respublika',
      code: 'RU-UD',
      subdivision: 'republic',
      native: 'Udmurtskaja Respublika'
    }
  ],
  SO: [
    {
      name: 'Awdal',
      code: 'SO-AW'
    },
    {
      name: 'Bakool',
      code: 'SO-BK'
    },
    {
      name: 'Banaadir',
      code: 'SO-BN'
    },
    {
      name: 'Bari',
      code: 'SO-BR'
    },
    {
      name: 'Bay',
      code: 'SO-BY'
    },
    {
      name: 'Galguduud',
      code: 'SO-GA'
    },
    {
      name: 'Gedo',
      code: 'SO-GE'
    },
    {
      name: 'Hiiraan',
      code: 'SO-HI'
    },
    {
      name: 'Jubbada Dhexe',
      code: 'SO-JD'
    },
    {
      name: 'Jubbada Hoose',
      code: 'SO-JH'
    },
    {
      name: 'Mudug',
      code: 'SO-MU'
    },
    {
      name: 'Nugaal',
      code: 'SO-NU'
    },
    {
      name: 'Sanaag',
      code: 'SO-SA'
    },
    {
      name: 'Shabeellaha Dhexe',
      code: 'SO-SD'
    },
    {
      name: 'Shabeellaha Hoose',
      code: 'SO-SH'
    },
    {
      name: 'Sool',
      code: 'SO-SO'
    },
    {
      name: 'Togdheer',
      code: 'SO-TO'
    },
    {
      name: 'Woqooyi Galbeed',
      code: 'SO-WO'
    }
  ],
  SR: [
    {
      name: 'Brokopondo',
      code: 'SR-BR'
    },
    {
      name: 'Commewijne',
      code: 'SR-CM'
    },
    {
      name: 'Coronie',
      code: 'SR-CR'
    },
    {
      name: 'Marowijne',
      code: 'SR-MA'
    },
    {
      name: 'Nickerie',
      code: 'SR-NI'
    },
    {
      name: 'Para',
      code: 'SR-PR'
    },
    {
      name: 'Paramaribo',
      code: 'SR-PM'
    },
    {
      name: 'Saramacca',
      code: 'SR-SA'
    },
    {
      name: 'Sipaliwini',
      code: 'SR-SI'
    },
    {
      name: 'Wanica',
      code: 'SR-WA'
    }
  ],
  SV: [
    {
      name: 'Ahuachapán',
      code: 'SV-AH'
    },
    {
      name: 'Cabañas',
      code: 'SV-CA'
    },
    {
      name: 'Chalatenango',
      code: 'SV-CH'
    },
    {
      name: 'Cuscatlán',
      code: 'SV-CU'
    },
    {
      name: 'La Libertad',
      code: 'SV-LI'
    },
    {
      name: 'La Paz',
      code: 'SV-PA'
    },
    {
      name: 'La Unión',
      code: 'SV-UN'
    },
    {
      name: 'Morazán',
      code: 'SV-MO'
    },
    {
      name: 'San Miguel',
      code: 'SV-SM'
    },
    {
      name: 'San Salvador',
      code: 'SV-SS'
    },
    {
      name: 'San Vicente',
      code: 'SV-SV'
    },
    {
      name: 'Santa Ana',
      code: 'SV-SA'
    },
    {
      name: 'Sonsonate',
      code: 'SV-SO'
    },
    {
      name: 'Usulután',
      code: 'SV-US'
    }
  ],
  TH: [
    {
      name: 'Amnat Charoen',
      code: 'TH-37'
    },
    {
      name: 'Ang Thong',
      code: 'TH-15'
    },
    {
      name: 'Bueng Kan',
      code: 'TH-38'
    },
    {
      name: 'Buri Ram',
      code: 'TH-31'
    },
    {
      name: 'Chachoengsao',
      code: 'TH-24'
    },
    {
      name: 'Chai Nat',
      code: 'TH-18'
    },
    {
      name: 'Chaiyaphum',
      code: 'TH-36'
    },
    {
      name: 'Chanthaburi',
      code: 'TH-22'
    },
    {
      name: 'Chiang Mai',
      code: 'TH-50'
    },
    {
      name: 'Chiang Rai',
      code: 'TH-57'
    },
    {
      name: 'Chon Buri',
      code: 'TH-20'
    },
    {
      name: 'Chumphon',
      code: 'TH-86'
    },
    {
      name: 'Kalasin',
      code: 'TH-46'
    },
    {
      name: 'Kamphaeng Phet',
      code: 'TH-62'
    },
    {
      name: 'Kanchanaburi',
      code: 'TH-71'
    },
    {
      name: 'Khon Kaen',
      code: 'TH-40'
    },
    {
      name: 'Krabi',
      code: 'TH-81'
    },
    {
      name: 'Krung Thep Maha Nakhon',
      code: 'TH-10'
    },
    {
      name: 'Lampang',
      code: 'TH-52'
    },
    {
      name: 'Lamphun',
      code: 'TH-51'
    },
    {
      name: 'Loei',
      code: 'TH-42'
    },
    {
      name: 'Lop Buri',
      code: 'TH-16'
    },
    {
      name: 'Mae Hong Son',
      code: 'TH-58'
    },
    {
      name: 'Maha Sarakham',
      code: 'TH-44'
    },
    {
      name: 'Mukdahan',
      code: 'TH-49'
    },
    {
      name: 'Nakhon Nayok',
      code: 'TH-26'
    },
    {
      name: 'Nakhon Pathom',
      code: 'TH-73'
    },
    {
      name: 'Nakhon Phanom',
      code: 'TH-48'
    },
    {
      name: 'Nakhon Ratchasima',
      code: 'TH-30'
    },
    {
      name: 'Nakhon Sawan',
      code: 'TH-60'
    },
    {
      name: 'Nakhon Si Thammarat',
      code: 'TH-80'
    },
    {
      name: 'Nan',
      code: 'TH-55'
    },
    {
      name: 'Narathiwat',
      code: 'TH-96'
    },
    {
      name: 'Nong Bua Lam Phu',
      code: 'TH-39'
    },
    {
      name: 'Nong Khai',
      code: 'TH-43'
    },
    {
      name: 'Nonthaburi',
      code: 'TH-12'
    },
    {
      name: 'Pathum Thani',
      code: 'TH-13'
    },
    {
      name: 'Pattani',
      code: 'TH-94'
    },
    {
      name: 'Phangnga',
      code: 'TH-82'
    },
    {
      name: 'Phatthalung',
      code: 'TH-93'
    },
    {
      name: 'Phatthaya',
      code: 'TH-S'
    },
    {
      name: 'Phayao',
      code: 'TH-56'
    },
    {
      name: 'Phetchabun',
      code: 'TH-67'
    },
    {
      name: 'Phetchaburi',
      code: 'TH-76'
    },
    {
      name: 'Phichit',
      code: 'TH-66'
    },
    {
      name: 'Phitsanulok',
      code: 'TH-65'
    },
    {
      name: 'Phra Nakhon Si Ayutthaya',
      code: 'TH-14'
    },
    {
      name: 'Phrae',
      code: 'TH-54'
    },
    {
      name: 'Phuket',
      code: 'TH-83'
    },
    {
      name: 'Prachin Buri',
      code: 'TH-25'
    },
    {
      name: 'Prachuap Khiri Khan',
      code: 'TH-77'
    },
    {
      name: 'Ranong',
      code: 'TH-85'
    },
    {
      name: 'Ratchaburi',
      code: 'TH-70'
    },
    {
      name: 'Rayong',
      code: 'TH-21'
    },
    {
      name: 'Roi Et',
      code: 'TH-45'
    },
    {
      name: 'Sa Kaeo',
      code: 'TH-27'
    },
    {
      name: 'Sakon Nakhon',
      code: 'TH-47'
    },
    {
      name: 'Samut Prakan',
      code: 'TH-11'
    },
    {
      name: 'Samut Sakhon',
      code: 'TH-74'
    },
    {
      name: 'Samut Songkhram',
      code: 'TH-75'
    },
    {
      name: 'Saraburi',
      code: 'TH-19'
    },
    {
      name: 'Satun',
      code: 'TH-91'
    },
    {
      name: 'Si Sa Ket',
      code: 'TH-33'
    },
    {
      name: 'Sing Buri',
      code: 'TH-17'
    },
    {
      name: 'Songkhla',
      code: 'TH-90'
    },
    {
      name: 'Sukhothai',
      code: 'TH-64'
    },
    {
      name: 'Suphan Buri',
      code: 'TH-72'
    },
    {
      name: 'Surat Thani',
      code: 'TH-84'
    },
    {
      name: 'Surin',
      code: 'TH-32'
    },
    {
      name: 'Tak',
      code: 'TH-63'
    },
    {
      name: 'Trang',
      code: 'TH-92'
    },
    {
      name: 'Trat',
      code: 'TH-23'
    },
    {
      name: 'Ubon Ratchathani',
      code: 'TH-34'
    },
    {
      name: 'Udon Thani',
      code: 'TH-41'
    },
    {
      name: 'Uthai Thani',
      code: 'TH-61'
    },
    {
      name: 'Uttaradit',
      code: 'TH-53'
    },
    {
      name: 'Yala',
      code: 'TH-95'
    },
    {
      name: 'Yasothon',
      code: 'TH-35'
    }
  ],
  TR: [
    {
      name: 'Adana',
      code: 'TR-01'
    },
    {
      name: 'Adıyaman',
      code: 'TR-02'
    },
    {
      name: 'Afyonkarahisar',
      code: 'TR-03'
    },
    {
      name: 'Aksaray',
      code: 'TR-68'
    },
    {
      name: 'Amasya',
      code: 'TR-05'
    },
    {
      name: 'Ankara',
      code: 'TR-06'
    },
    {
      name: 'Antalya',
      code: 'TR-07'
    },
    {
      name: 'Ardahan',
      code: 'TR-75'
    },
    {
      name: 'Artvin',
      code: 'TR-08'
    },
    {
      name: 'Aydın',
      code: 'TR-09'
    },
    {
      name: 'Ağrı',
      code: 'TR-04'
    },
    {
      name: 'Balıkesir',
      code: 'TR-10'
    },
    {
      name: 'Bartın',
      code: 'TR-74'
    },
    {
      name: 'Batman',
      code: 'TR-72'
    },
    {
      name: 'Bayburt',
      code: 'TR-69'
    },
    {
      name: 'Bilecik',
      code: 'TR-11'
    },
    {
      name: 'Bingöl',
      code: 'TR-12'
    },
    {
      name: 'Bitlis',
      code: 'TR-13'
    },
    {
      name: 'Bolu',
      code: 'TR-14'
    },
    {
      name: 'Burdur',
      code: 'TR-15'
    },
    {
      name: 'Bursa',
      code: 'TR-16'
    },
    {
      name: 'Denizli',
      code: 'TR-20'
    },
    {
      name: 'Diyarbakır',
      code: 'TR-21'
    },
    {
      name: 'Düzce',
      code: 'TR-81'
    },
    {
      name: 'Edirne',
      code: 'TR-22'
    },
    {
      name: 'Elazığ',
      code: 'TR-23'
    },
    {
      name: 'Erzincan',
      code: 'TR-24'
    },
    {
      name: 'Erzurum',
      code: 'TR-25'
    },
    {
      name: 'Eskişehir',
      code: 'TR-26'
    },
    {
      name: 'Gaziantep',
      code: 'TR-27'
    },
    {
      name: 'Giresun',
      code: 'TR-28'
    },
    {
      name: 'Gümüşhane',
      code: 'TR-29'
    },
    {
      name: 'Hakkâri',
      code: 'TR-30'
    },
    {
      name: 'Hatay',
      code: 'TR-31'
    },
    {
      name: 'Isparta',
      code: 'TR-32'
    },
    {
      name: 'Iğdır',
      code: 'TR-76'
    },
    {
      name: 'Kahramanmaraş',
      code: 'TR-46'
    },
    {
      name: 'Karabük',
      code: 'TR-78'
    },
    {
      name: 'Karaman',
      code: 'TR-70'
    },
    {
      name: 'Kars',
      code: 'TR-36'
    },
    {
      name: 'Kastamonu',
      code: 'TR-37'
    },
    {
      name: 'Kayseri',
      code: 'TR-38'
    },
    {
      name: 'Kilis',
      code: 'TR-79'
    },
    {
      name: 'Kocaeli',
      code: 'TR-41'
    },
    {
      name: 'Konya',
      code: 'TR-42'
    },
    {
      name: 'Kütahya',
      code: 'TR-43'
    },
    {
      name: 'Kırklareli',
      code: 'TR-39'
    },
    {
      name: 'Kırıkkale',
      code: 'TR-71'
    },
    {
      name: 'Kırşehir',
      code: 'TR-40'
    },
    {
      name: 'Malatya',
      code: 'TR-44'
    },
    {
      name: 'Manisa',
      code: 'TR-45'
    },
    {
      name: 'Mardin',
      code: 'TR-47'
    },
    {
      name: 'Mersin',
      code: 'TR-33'
    },
    {
      name: 'Muğla',
      code: 'TR-48'
    },
    {
      name: 'Muş',
      code: 'TR-49'
    },
    {
      name: 'Nevşehir',
      code: 'TR-50'
    },
    {
      name: 'Niğde',
      code: 'TR-51'
    },
    {
      name: 'Ordu',
      code: 'TR-52'
    },
    {
      name: 'Osmaniye',
      code: 'TR-80'
    },
    {
      name: 'Rize',
      code: 'TR-53'
    },
    {
      name: 'Sakarya',
      code: 'TR-54'
    },
    {
      name: 'Samsun',
      code: 'TR-55'
    },
    {
      name: 'Siirt',
      code: 'TR-56'
    },
    {
      name: 'Sinop',
      code: 'TR-57'
    },
    {
      name: 'Sivas',
      code: 'TR-58'
    },
    {
      name: 'Tekirdağ',
      code: 'TR-59'
    },
    {
      name: 'Tokat',
      code: 'TR-60'
    },
    {
      name: 'Trabzon',
      code: 'TR-61'
    },
    {
      name: 'Tunceli',
      code: 'TR-62'
    },
    {
      name: 'Uşak',
      code: 'TR-64'
    },
    {
      name: 'Van',
      code: 'TR-65'
    },
    {
      name: 'Yalova',
      code: 'TR-77'
    },
    {
      name: 'Yozgat',
      code: 'TR-66'
    },
    {
      name: 'Zonguldak',
      code: 'TR-67'
    },
    {
      name: 'Çanakkale',
      code: 'TR-17'
    },
    {
      name: 'Çankırı',
      code: 'TR-18'
    },
    {
      name: 'Çorum',
      code: 'TR-19'
    },
    {
      name: 'İstanbul',
      code: 'TR-34'
    },
    {
      name: 'İzmir',
      code: 'TR-35'
    },
    {
      name: 'Şanlıurfa',
      code: 'TR-63'
    },
    {
      name: 'Şırnak',
      code: 'TR-73'
    }
  ],
  TV: [
    {
      name: 'Funafuti',
      code: 'TV-FUN'
    },
    {
      name: 'Nanumanga',
      code: 'TV-NMG'
    },
    {
      name: 'Nanumea',
      code: 'TV-NMA'
    },
    {
      name: 'Niutao',
      code: 'TV-NIT'
    },
    {
      name: 'Nui',
      code: 'TV-NUI'
    },
    {
      name: 'Nukufetau',
      code: 'TV-NKF'
    },
    {
      name: 'Nukulaelae',
      code: 'TV-NKL'
    },
    {
      name: 'Vaitupu',
      code: 'TV-VAI'
    }
  ],
  TW: [
    {
      name: 'Changhua',
      code: 'TW-CHA'
    },
    {
      name: 'Chiayi',
      code: 'TW-CYQ'
    },
    {
      name: 'Chiayi',
      code: 'TW-CYI'
    },
    {
      name: 'Hsinchu',
      code: 'TW-HSZ'
    },
    {
      name: 'Hsinchu',
      code: 'TW-HSQ'
    },
    {
      name: 'Hualien',
      code: 'TW-HUA'
    },
    {
      name: 'Ilan',
      code: 'TW-ILA'
    },
    {
      name: 'Kaohsiung',
      code: 'TW-KHQ'
    },
    {
      name: 'Kaohsiung',
      code: 'TW-KHH'
    },
    {
      name: 'Keelung',
      code: 'TW-KEE'
    },
    {
      name: 'Miaoli',
      code: 'TW-MIA'
    },
    {
      name: 'Nantou',
      code: 'TW-NAN'
    },
    {
      name: 'Penghu',
      code: 'TW-PEN'
    },
    {
      name: 'Pingtung',
      code: 'TW-PIF'
    },
    {
      name: 'Taichung',
      code: 'TW-TXG'
    },
    {
      name: 'Taichung',
      code: 'TW-TXQ'
    },
    {
      name: 'Tainan',
      code: 'TW-TNN'
    },
    {
      name: 'Tainan',
      code: 'TW-TNQ'
    },
    {
      name: 'Taipei',
      code: 'TW-TPE'
    },
    {
      name: 'Taipei',
      code: 'TW-TPQ'
    },
    {
      name: 'Taitung',
      code: 'TW-TTT'
    },
    {
      name: 'Taoyuan',
      code: 'TW-TAO'
    },
    {
      name: 'Yunlin',
      code: 'TW-YUN'
    }
  ],
  UA: [
    {
      name: 'Avtonomna Respublika Krym',
      code: 'UA-43'
    },
    {
      name: "Cherkas'ka Oblast'",
      code: 'UA-71'
    },
    {
      name: "Chernihivs'ka Oblast'",
      code: 'UA-74'
    },
    {
      name: "Chernivets'ka Oblast'",
      code: 'UA-77'
    },
    {
      name: "Dnipropetrovs'ka Oblast'",
      code: 'UA-12'
    },
    {
      name: "Donets'ka Oblast'",
      code: 'UA-14'
    },
    {
      name: "Ivano-Frankivs'ka Oblast'",
      code: 'UA-26'
    },
    {
      name: "Kharkivs'ka Oblast'",
      code: 'UA-63'
    },
    {
      name: "Khersons'ka Oblast'",
      code: 'UA-65'
    },
    {
      name: "Khmel'nyts'ka Oblast'",
      code: 'UA-68'
    },
    {
      name: "Kirovohrads'ka Oblast'",
      code: 'UA-35'
    },
    {
      name: 'Kyïv',
      code: 'UA-30'
    },
    {
      name: "Kyïvs'ka Oblast'",
      code: 'UA-32'
    },
    {
      name: "L'vivs'ka Oblast'",
      code: 'UA-46'
    },
    {
      name: "Luhans'ka Oblast'",
      code: 'UA-09'
    },
    {
      name: "Mykolaïvs'ka Oblast'",
      code: 'UA-48'
    },
    {
      name: "Odes'ka Oblast'",
      code: 'UA-51'
    },
    {
      name: "Poltavs'ka Oblast'",
      code: 'UA-53'
    },
    {
      name: "Rivnens'ka Oblast'",
      code: 'UA-56'
    },
    {
      name: "Sevastopol'",
      code: 'UA-40'
    },
    {
      name: "Sums'ka Oblast'",
      code: 'UA-59'
    },
    {
      name: "Ternopil's'ka Oblast'",
      code: 'UA-61'
    },
    {
      name: "Vinnyts'ka Oblast'",
      code: 'UA-05'
    },
    {
      name: "Volyns'ka Oblast'",
      code: 'UA-07'
    },
    {
      name: "Zakarpats'ka Oblast'",
      code: 'UA-21'
    },
    {
      name: "Zaporiz'ka Oblast'",
      code: 'UA-23'
    },
    {
      name: "Zhytomyrs'ka Oblast'",
      code: 'UA-18'
    }
  ],
  US: [
    {
      name: 'District of Columbia',
      code: 'US-DC',
      subdivision: 'district'
    },
    {
      name: 'American Samoa',
      code: 'US-AS',
      subdivision: 'outlying territory'
    },
    {
      name: 'Guam',
      code: 'US-GU',
      subdivision: 'outlying territory'
    },
    {
      name: 'Northern Mariana Islands',
      code: 'US-MP',
      subdivision: 'outlying territory'
    },
    {
      name: 'Puerto Rico',
      code: 'US-PR',
      subdivision: 'outlying territory'
    },
    {
      name: 'United States Minor Outlying Islands',
      code: 'US-UM',
      subdivision: 'outlying territory'
    },
    {
      name: 'Virgin Islands, U.S.',
      code: 'US-VI',
      subdivision: 'outlying territory'
    },
    {
      name: 'Alabama',
      code: 'US-AL',
      subdivision: 'state'
    },
    {
      name: 'Alaska',
      code: 'US-AK',
      subdivision: 'state'
    },
    {
      name: 'Arizona',
      code: 'US-AZ',
      subdivision: 'state'
    },
    {
      name: 'Arkansas',
      code: 'US-AR',
      subdivision: 'state'
    },
    {
      name: 'California',
      code: 'US-CA',
      subdivision: 'state'
    },
    {
      name: 'Colorado',
      code: 'US-CO',
      subdivision: 'state'
    },
    {
      name: 'Connecticut',
      code: 'US-CT',
      subdivision: 'state'
    },
    {
      name: 'Delaware',
      code: 'US-DE',
      subdivision: 'state'
    },
    {
      name: 'Florida',
      code: 'US-FL',
      subdivision: 'state'
    },
    {
      name: 'Georgia',
      code: 'US-GA',
      subdivision: 'state'
    },
    {
      name: 'Hawaii',
      code: 'US-HI',
      subdivision: 'state'
    },
    {
      name: 'Idaho',
      code: 'US-ID',
      subdivision: 'state'
    },
    {
      name: 'Illinois',
      code: 'US-IL',
      subdivision: 'state'
    },
    {
      name: 'Indiana',
      code: 'US-IN',
      subdivision: 'state'
    },
    {
      name: 'Iowa',
      code: 'US-IA',
      subdivision: 'state'
    },
    {
      name: 'Kansas',
      code: 'US-KS',
      subdivision: 'state'
    },
    {
      name: 'Kentucky',
      code: 'US-KY',
      subdivision: 'state'
    },
    {
      name: 'Louisiana',
      code: 'US-LA',
      subdivision: 'state'
    },
    {
      name: 'Maine',
      code: 'US-ME',
      subdivision: 'state'
    },
    {
      name: 'Maryland',
      code: 'US-MD',
      subdivision: 'state'
    },
    {
      name: 'Massachusetts',
      code: 'US-MA',
      subdivision: 'state'
    },
    {
      name: 'Michigan',
      code: 'US-MI',
      subdivision: 'state'
    },
    {
      name: 'Minnesota',
      code: 'US-MN',
      subdivision: 'state'
    },
    {
      name: 'Mississippi',
      code: 'US-MS',
      subdivision: 'state'
    },
    {
      name: 'Missouri',
      code: 'US-MO',
      subdivision: 'state'
    },
    {
      name: 'Montana',
      code: 'US-MT',
      subdivision: 'state'
    },
    {
      name: 'Nebraska',
      code: 'US-NE',
      subdivision: 'state'
    },
    {
      name: 'Nevada',
      code: 'US-NV',
      subdivision: 'state'
    },
    {
      name: 'New Hampshire',
      code: 'US-NH',
      subdivision: 'state'
    },
    {
      name: 'New Jersey',
      code: 'US-NJ',
      subdivision: 'state'
    },
    {
      name: 'New Mexico',
      code: 'US-NM',
      subdivision: 'state'
    },
    {
      name: 'New York',
      code: 'US-NY',
      subdivision: 'state'
    },
    {
      name: 'North Carolina',
      code: 'US-NC',
      subdivision: 'state'
    },
    {
      name: 'North Dakota',
      code: 'US-ND',
      subdivision: 'state'
    },
    {
      name: 'Ohio',
      code: 'US-OH',
      subdivision: 'state'
    },
    {
      name: 'Oklahoma',
      code: 'US-OK',
      subdivision: 'state'
    },
    {
      name: 'Oregon',
      code: 'US-OR',
      subdivision: 'state'
    },
    {
      name: 'Pennsylvania',
      code: 'US-PA',
      subdivision: 'state'
    },
    {
      name: 'Rhode Island',
      code: 'US-RI',
      subdivision: 'state'
    },
    {
      name: 'South Carolina',
      code: 'US-SC',
      subdivision: 'state'
    },
    {
      name: 'South Dakota',
      code: 'US-SD',
      subdivision: 'state'
    },
    {
      name: 'Tennessee',
      code: 'US-TN',
      subdivision: 'state'
    },
    {
      name: 'Texas',
      code: 'US-TX',
      subdivision: 'state'
    },
    {
      name: 'Utah',
      code: 'US-UT',
      subdivision: 'state'
    },
    {
      name: 'Vermont',
      code: 'US-VT',
      subdivision: 'state'
    },
    {
      name: 'Virginia',
      code: 'US-VA',
      subdivision: 'state'
    },
    {
      name: 'Washington',
      code: 'US-WA',
      subdivision: 'state'
    },
    {
      name: 'West Virginia',
      code: 'US-WV',
      subdivision: 'state'
    },
    {
      name: 'Wisconsin',
      code: 'US-WI',
      subdivision: 'state'
    },
    {
      name: 'Wyoming',
      code: 'US-WY',
      subdivision: 'state'
    }
  ],
  UY: [
    {
      name: 'Artigas',
      code: 'UY-AR'
    },
    {
      name: 'Canelones',
      code: 'UY-CA'
    },
    {
      name: 'Cerro Largo',
      code: 'UY-CL'
    },
    {
      name: 'Colonia',
      code: 'UY-CO'
    },
    {
      name: 'Durazno',
      code: 'UY-DU'
    },
    {
      name: 'Flores',
      code: 'UY-FS'
    },
    {
      name: 'Florida',
      code: 'UY-FD'
    },
    {
      name: 'Lavalleja',
      code: 'UY-LA'
    },
    {
      name: 'Maldonado',
      code: 'UY-MA'
    },
    {
      name: 'Montevideo',
      code: 'UY-MO'
    },
    {
      name: 'Paysandú',
      code: 'UY-PA'
    },
    {
      name: 'Rivera',
      code: 'UY-RV'
    },
    {
      name: 'Rocha',
      code: 'UY-RO'
    },
    {
      name: 'Río Negro',
      code: 'UY-RN'
    },
    {
      name: 'Salto',
      code: 'UY-SA'
    },
    {
      name: 'San José',
      code: 'UY-SJ'
    },
    {
      name: 'Soriano',
      code: 'UY-SO'
    },
    {
      name: 'Tacuarembó',
      code: 'UY-TA'
    },
    {
      name: 'Treinta y Tres',
      code: 'UY-TT'
    }
  ],
  UZ: [
    {
      name: 'Andijon',
      code: 'UZ-AN'
    },
    {
      name: 'Buxoro',
      code: 'UZ-BU'
    },
    {
      name: 'Farg‘ona',
      code: 'UZ-FA'
    },
    {
      name: 'Jizzax',
      code: 'UZ-JI'
    },
    {
      name: 'Namangan',
      code: 'UZ-NG'
    },
    {
      name: 'Navoiy',
      code: 'UZ-NW'
    },
    {
      name: 'Qashqadaryo',
      code: 'UZ-QA'
    },
    {
      name: 'Qoraqalpog‘iston Respublikasi',
      code: 'UZ-QR'
    },
    {
      name: 'Samarqand',
      code: 'UZ-SA'
    },
    {
      name: 'Sirdaryo',
      code: 'UZ-SI'
    },
    {
      name: 'Surxondaryo',
      code: 'UZ-SU'
    },
    {
      name: 'Toshkent',
      code: 'UZ-TO'
    },
    {
      name: 'Toshkent',
      code: 'UZ-TK'
    },
    {
      name: 'Xorazm',
      code: 'UZ-XO'
    }
  ],
  VE: [
    {
      name: 'Amazonas',
      code: 'VE-Z'
    },
    {
      name: 'Anzoátegui',
      code: 'VE-B'
    },
    {
      name: 'Apure',
      code: 'VE-C'
    },
    {
      name: 'Aragua',
      code: 'VE-D'
    },
    {
      name: 'Barinas',
      code: 'VE-E'
    },
    {
      name: 'Bolívar',
      code: 'VE-F'
    },
    {
      name: 'Carabobo',
      code: 'VE-G'
    },
    {
      name: 'Cojedes',
      code: 'VE-H'
    },
    {
      name: 'Delta Amacuro',
      code: 'VE-Y'
    },
    {
      name: 'Dependencias Federales',
      code: 'VE-W'
    },
    {
      name: 'Distrito Capital',
      code: 'VE-A'
    },
    {
      name: 'Falcón',
      code: 'VE-I'
    },
    {
      name: 'Guárico',
      code: 'VE-J'
    },
    {
      name: 'Lara',
      code: 'VE-K'
    },
    {
      name: 'Miranda',
      code: 'VE-M'
    },
    {
      name: 'Monagas',
      code: 'VE-N'
    },
    {
      name: 'Mérida',
      code: 'VE-L'
    },
    {
      name: 'Nueva Esparta',
      code: 'VE-O'
    },
    {
      name: 'Portuguesa',
      code: 'VE-P'
    },
    {
      name: 'Sucre',
      code: 'VE-R'
    },
    {
      name: 'Trujillo',
      code: 'VE-T'
    },
    {
      name: 'Táchira',
      code: 'VE-S'
    },
    {
      name: 'Vargas',
      code: 'VE-X'
    },
    {
      name: 'Yaracuy',
      code: 'VE-U'
    },
    {
      name: 'Zulia',
      code: 'VE-V'
    }
  ],
  VN: [
    {
      name: 'An Giang',
      code: 'VN-44'
    },
    {
      name: 'Bà Rịa–Vũng Tàu',
      code: 'VN-43'
    },
    {
      name: 'Bình Dương',
      code: 'VN-57'
    },
    {
      name: 'Bình Phước',
      code: 'VN-58'
    },
    {
      name: 'Bình Thuận',
      code: 'VN-40'
    },
    {
      name: 'Bình Định',
      code: 'VN-31'
    },
    {
      name: 'Bạc Liêu',
      code: 'VN-55'
    },
    {
      name: 'Bắc Giang',
      code: 'VN-54'
    },
    {
      name: 'Bắc Kạn',
      code: 'VN-53'
    },
    {
      name: 'Bắc Ninh',
      code: 'VN-56'
    },
    {
      name: 'Bến Tre',
      code: 'VN-50'
    },
    {
      name: 'Cao Bằng',
      code: 'VN-04'
    },
    {
      name: 'Cà Mau',
      code: 'VN-59'
    },
    {
      name: 'Cần Thơ',
      code: 'VN-CT'
    },
    {
      name: 'Gia Lai',
      code: 'VN-30'
    },
    {
      name: 'Hà Giang',
      code: 'VN-03'
    },
    {
      name: 'Hà Nam',
      code: 'VN-63'
    },
    {
      name: 'Hà Nội',
      code: 'VN-HN'
    },
    {
      name: 'Hà Tây',
      code: 'VN-15'
    },
    {
      name: 'Hà Tĩnh',
      code: 'VN-23'
    },
    {
      name: 'Hòa Bình',
      code: 'VN-14'
    },
    {
      name: 'Hưng Yên',
      code: 'VN-66'
    },
    {
      name: 'Hải Dương',
      code: 'VN-61'
    },
    {
      name: 'Hải Phòng',
      code: 'VN-HP'
    },
    {
      name: 'Hậu Giang',
      code: 'VN-73'
    },
    {
      name: 'Hồ Chí Minh',
      code: 'VN-SG'
    },
    {
      name: 'Khánh Hòa',
      code: 'VN-34'
    },
    {
      name: 'Kiên Giang',
      code: 'VN-47'
    },
    {
      name: 'Kon Tum',
      code: 'VN-28'
    },
    {
      name: 'Lai Châu',
      code: 'VN-01'
    },
    {
      name: 'Long An',
      code: 'VN-41'
    },
    {
      name: 'Lào Cai',
      code: 'VN-02'
    },
    {
      name: 'Lâm Đồng',
      code: 'VN-35'
    },
    {
      name: 'Lạng Sơn',
      code: 'VN-09'
    },
    {
      name: 'Nam Định',
      code: 'VN-67'
    },
    {
      name: 'Nghệ An',
      code: 'VN-22'
    },
    {
      name: 'Ninh Bình',
      code: 'VN-18'
    },
    {
      name: 'Ninh Thuận',
      code: 'VN-36'
    },
    {
      name: 'Phú Thọ',
      code: 'VN-68'
    },
    {
      name: 'Phú Yên',
      code: 'VN-32'
    },
    {
      name: 'Quảng Bình',
      code: 'VN-24'
    },
    {
      name: 'Quảng Nam',
      code: 'VN-27'
    },
    {
      name: 'Quảng Ngãi',
      code: 'VN-29'
    },
    {
      name: 'Quảng Ninh',
      code: 'VN-13'
    },
    {
      name: 'Quảng Trị',
      code: 'VN-25'
    },
    {
      name: 'Sóc Trăng',
      code: 'VN-52'
    },
    {
      name: 'Sơn La',
      code: 'VN-05'
    },
    {
      name: 'Thanh Hóa',
      code: 'VN-21'
    },
    {
      name: 'Thái Bình',
      code: 'VN-20'
    },
    {
      name: 'Thái Nguyên',
      code: 'VN-69'
    },
    {
      name: 'Thừa Thiên–Huế',
      code: 'VN-26'
    },
    {
      name: 'Tiền Giang',
      code: 'VN-46'
    },
    {
      name: 'Trà Vinh',
      code: 'VN-51'
    },
    {
      name: 'Tuyên Quang',
      code: 'VN-07'
    },
    {
      name: 'Tây Ninh',
      code: 'VN-37'
    },
    {
      name: 'Vĩnh Long',
      code: 'VN-49'
    },
    {
      name: 'Vĩnh Phúc',
      code: 'VN-70'
    },
    {
      name: 'Yên Bái',
      code: 'VN-06'
    },
    {
      name: 'Điện Biên',
      code: 'VN-71'
    },
    {
      name: 'Đà Nẵng',
      code: 'VN-DN'
    },
    {
      name: 'Đắk Lắk',
      code: 'VN-33'
    },
    {
      name: 'Đắk Nông',
      code: 'VN-72'
    },
    {
      name: 'Đồng Nai',
      code: 'VN-39'
    },
    {
      name: 'Đồng Tháp',
      code: 'VN-45'
    }
  ]
};

export const STATES_SEED_SELECT: DeepReadonly<Record<string, Array<SeedSelectOption>>> = Object.fromEntries(
  Object.entries(STATES).map(([k, v]) => [
    k,
    v.map((r) => ({
      label: r.name,
      value: r.code.split('-')[1],
      dataCy: 'state-option',
      dataCyValue: r.code.split('-')[1]
    }))
  ])
);

export function isAwsRegionId(regionId: unknown): regionId is AWSRegionId {
  return AWS_REGION_BY_ID[regionId as AWSRegionId] !== undefined;
}

export function isGcpRegionId(regionId: unknown): regionId is GCPRegionId {
  return GCP_REGION_BY_ID[regionId as GCPRegionId] !== undefined;
}

/** regions where instances can be created in development stage.  The order is
 * the same as the order in the UI. */
const LOCAL_REGIONS: ReadonlyArray<RegionId> = [
  'us-west-2',
  'us-east-1',
  'us-east-2',
  'eu-west-1',
  'eu-west-2',
  'eu-central-1',
  'eu-north-1',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-south-1',
  'ca-central-1',
  'sa-east-1',
  'af-south-1',
  'gcp-us-east1',
  'gcp-us-central1',
  'gcp-europe-west4',
  'gcp-asia-southeast1',
  'eastus'
] as const;

/** regions where instances can be created in Dev stage. The order is the same
 * as the order in the UI. */
const DEV_REGIONS: ReadonlyArray<RegionId> = [
  'us-west-2',
  'us-east-1',
  'us-east-2',
  'eu-west-1',
  'eu-central-1',
  'gcp-us-east1',
  'gcp-us-central1',
  'gcp-europe-west4',
  'gcp-asia-southeast1',
  'eastus'
] as const;

/** regions where instances can be created in staging stage. The order is the
 * same as the order in the UI. */
const STAGING_REGIONS: ReadonlyArray<RegionId> = ['us-east-2', 'eu-west-1', 'gcp-us-central1'] as const;

/** regions where instances can be created in production stage. The order is the
 * same as the order in the UI.  */
const PROD_REGIONS: ReadonlyArray<RegionId> = [
  'us-west-2',
  'us-east-2',
  'us-east-1',
  'eu-west-1',
  'eu-west-2',
  'eu-central-1',
  'eu-north-1',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-south-1',
  'ca-central-1',
  'sa-east-1',
  'af-south-1',
  'gcp-us-east1',
  'gcp-us-central1',
  'gcp-europe-west4',
  'gcp-asia-southeast1'
] as const;

type extendedStageType = 'development' | 'test' | StageType;
/** return the region IDs that are available to create instances in the given
 * stage in the order that they should appear in the UI */
export function getAvailableRegionsByStage(stage: extendedStageType): ReadonlyArray<RegionId> {
  switch (stage) {
    case 'test':
    case 'local':
      return LOCAL_REGIONS;
    case 'dev':
    case 'development':
      return DEV_REGIONS;
    case 'staging':
      return STAGING_REGIONS;
    case 'production':
      return PROD_REGIONS;
  }
}
