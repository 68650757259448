import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadSignatureDetails } from '@cp/common/protocol/Storage';
import { catchError, combineLatest, Observable, of, shareReplay, startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface UploadFilePair {
  file: File;
  signatureDetails: UploadSignatureDetails;
}

export interface UploadFilesResponse {
  isDone: boolean;
  progress: Array<UploadProgress>;
}

export interface UploadProgress {
  progress: number;
  filePair: UploadFilePair;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private readonly httpClient: HttpClient) {}

  uploadFiles(filePairs: Array<UploadFilePair>): Observable<UploadFilesResponse> {
    const obsAr = [];
    for (const filePair of filePairs) {
      obsAr.push(
        this.uploadFile(filePair.signatureDetails, filePair.file).pipe(
          catchError((e) => {
            console.error('File upload error', filePair.file, e);
            return of(-1);
          }),
          map((progress) => {
            return { progress, filePair };
          })
        )
      );
    }

    return combineLatest(obsAr).pipe(
      map((results: Array<UploadProgress>) => {
        const response: UploadFilesResponse = {
          isDone: true,
          progress: []
        };
        for (const result of results) {
          response.progress.push(result);
          response.isDone = response.isDone && [-1, 100].includes(result.progress);
        }
        return response;
      })
    );
  }

  private uploadFile(signatureDetails: UploadSignatureDetails, file: File): Observable<number> {
    const formData = new FormData();
    const fields = signatureDetails.fields;
    formData.append('key', fields.key);
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', fields['X-Amz-Date']);
    formData.append('Policy', fields['Policy']);
    formData.append('X-Amz-Signature', fields['X-Amz-Signature']);
    if (fields['X-Amz-Security-Token']) {
      formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token']);
    }
    formData.append('file', file);

    return this.httpClient
      .post(signatureDetails.url, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        filter((event) => HttpEventType.UploadProgress === event.type),
        map((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return event.total ? Math.floor((event.loaded / event.total) * 100) : 0;
          }
          throw new Error('UNEXPECTED');
        }),
        startWith(0),
        shareReplay(1)
      );
  }
}
