<mat-dialog-content class="trial_expired_dialog" data-cy="trial-expired-dialog">
  <div class="dialog_container" *ngIf="myRoleObs | async as myRole">
    <div class="backgrounds">
      <div class="background-layer1"></div>
      <div class="background-layer2"></div>
    </div>
    <div class="clock_icon_container">
      <mat-icon class="icon" svgIcon="clock"></mat-icon>
    </div>
    <div class="title" data-cy="trial-expiration-title">Your trial has expired!</div>
    <div class="text" data-cy="trial-expiration-text">
      We hope you enjoyed your free trial.
      <span *ngIf="myRole === 'ADMIN'">
        Enter your payment details
      </span>
      <span *ngIf="myRole === 'DEVELOPER'">
        Contact your system admin
      </span>
      to continue using ClickHouse Cloud.
      <br>
      <br>
      <div class="paragraph_small">If you need more time to complete your evaluation, or would like to discuss any other
        matter, please contact our <a href="mailto:sales@clickhouse.com">sales team</a>.
      </div>
    </div>

    <div class="buttons">
      <button *ngIf="myRole === 'ADMIN'"
        (click)="goToBilling()" class="payment_button"
        color="primary"
        data-cy="enter-payment-button"
        mat-flat-button
      >Enter payment details
      </button>
      <button (click)="dialogRef.close()"
              class="close_button"
              data-cy="close-trial-expired-button" mat-button
              type="button"
      >Close
      </button>
    </div>
  </div>
</mat-dialog-content>
