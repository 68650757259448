import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy } from '@cp/common/utils/Assert';
import { normalizeEmail } from '@cp/common/utils/MiscUtils';
import { isEmail } from '@cp/common/utils/ValidationUtils';
import { times } from '@cp/web/app/common/utils/AngularUtils';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { SendOrgInvitesUiState } from '@cp/web/app/organizations/protocol/OrganizationStates';
import { SendOrgInvitesUiService } from '@cp/web/app/organizations/send-org-invites-dialog/send-org-invites-ui.service';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './send-org-invites-dialog.component.html',
  styleUrls: ['./send-org-invites-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendOrgInvitesDialogComponent {
  /** Initial numbers of email input fields - the user can add more if needed, and only the first one is required. */
  private readonly numInitInvites = 3;
  readonly sendOrgInvitesForm: FormGroup;
  readonly uiStateObs: Observable<SendOrgInvitesUiState>;
  readonly roles: Array<SeedSelectOption> = [
    { label: 'Admin', value: 'ADMIN', dataCy: 'role-option', dataCyValue: 'ADMIN' },
    { label: 'Developer', value: 'DEVELOPER', dataCy: 'role-option', dataCyValue: 'DEVELOPER' }
  ];
  emailCount = 0;
  requestSubmitted = false;

  readonly times = times;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SendOrgInvitesDialogComponent>,
    private readonly sendOrgInvitesUiService: SendOrgInvitesUiService,
    private readonly organizationService: OrganizationService,
    private readonly organizationStateService: OrganizationStateService
  ) {
    this.sendOrgInvitesForm = this.formBuilder.group({});
    for (let i = 0; i < this.numInitInvites; i++) {
      this.addNewEmailControl(i === 0);
    }
    this.uiStateObs = sendOrgInvitesUiService.observeState();
    sendOrgInvitesUiService.setPartialState({ sendInvitesButtonDisabled: false, errorMessage: undefined });
  }

  addNewEmailControl(isRequired = false): void {
    const validatorOps: ValidatorFn[] = [];
    if (isRequired) {
      validatorOps.push(Validators.required);
    }
    const suffix = this.emailCount;
    this.emailCount++;
    const emailControl = this.formBuilder.control('', [
      ...validatorOps,
      (control: AbstractControl) => this.validateEmail(control, suffix)
    ]);
    this.sendOrgInvitesForm.addControl(`email_${suffix}`, emailControl);
    this.sendOrgInvitesForm.addControl(`role_${suffix}`, this.formBuilder.control('', validatorOps));
  }

  async onSubmit(): Promise<void> {
    assertTruthy(this.sendOrgInvitesForm.valid);
    const { emails, roles } = this.getNonEmptyEmailsWithRoles();
    this.sendOrgInvitesUiService.setPartialState({ sendInvitesButtonDisabled: true, errorMessage: undefined });
    try {
      const organization = this.organizationStateService.getCurrentOrgOrFail();
      if (!organization.restrictions.canInviteMembers) {
        this.sendOrgInvitesUiService.setStateKey('errorMessage', `Organization can't accept new members`);
        return;
      }
      await this.organizationService.invite(organization.id, emails, roles);
      this.sendOrgInvitesUiService.setStateKey('errorMessage', undefined);
      this.requestSubmitted = true;
    } catch (e) {
      this.sendOrgInvitesUiService.setStateKey('errorMessage', 'Error occurred when sending invites');
    } finally {
      this.sendOrgInvitesUiService.setStateKey('sendInvitesButtonDisabled', false);
    }
  }

  private getNonEmptyEmailsWithRoles(): { emails: string[]; roles: OrganizationRole[] } {
    const details = this.sendOrgInvitesForm.value as { [key: string]: string };
    const emails: string[] = [];
    const roles: Array<OrganizationRole> = [];
    for (let i = 0; i < this.emailCount; i++) {
      const email = details[`email_${i}`];
      if (email.length === 0) continue;
      emails.push(normalizeEmail(email));
      roles.push(details[`role_${i}`] as OrganizationRole);
    }
    return { emails, roles };
  }

  private validateEmail(
    control: AbstractControl,
    index: number
  ):
    | { duplicateEmail: true }
    | { duplicatePendingEmail: true }
    | { invalidEmail: true }
    | { existingUser: true }
    | null {
    const email = control.value as string;
    if (email.length === 0) return null;
    if (!isEmail(email)) {
      return { invalidEmail: true };
    }
    const normEmail = normalizeEmail(email);
    const formEmails = this.getNonEmptyEmailsWithRoles().emails;
    formEmails.splice(index, 1);
    if (formEmails.includes(normEmail)) {
      return { duplicateEmail: true };
    }
    const org = this.organizationStateService.getCurrentOrgOrFail();
    const existingInvitations = new Set<string>(Object.values(org.invitations).map((invitation) => invitation.email));
    if (existingInvitations.has(normEmail)) {
      return { duplicatePendingEmail: true };
    }
    if (Object.values(org.users).some((u) => u.email === normEmail)) {
      return { existingUser: true };
    }
    return null;
  }

  private validateRole(control: AbstractControl, emailControl: AbstractControl): boolean {
    return (emailControl.value as string).length === 0 || control.value;
  }

  get formIsValid(): boolean {
    if (!this.sendOrgInvitesForm.valid) {
      return false;
    }
    for (let i = 0; i < this.emailCount; i++) {
      const roleControl = this.sendOrgInvitesForm.controls[`role_${i}`];
      const emailControl = this.sendOrgInvitesForm.controls[`email_${i}`];
      if (!this.validateRole(roleControl, emailControl)) {
        return false;
      }
    }
    return true;
  }

  static show(dialog: MatDialog): void {
    dialog.open(SendOrgInvitesDialogComponent, {
      width: '100%',
      maxWidth: '652px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }
}
