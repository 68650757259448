import { UserIdentityProviderType } from '@cp/common/protocol/Account';
import { assertTruthy, fail } from '@cp/common/utils/Assert';
import { isEmail, isHexString, isUuid } from '@cp/common/utils/ValidationUtils';

const FEDERATED_GOOGLE_USER_ID_PREFIX = 'Google_';
const FEDERATED_GOOGLE_USER_ID_PATTERN = /^Google_[0-9]{21}$/;

/**
 * Returns true if the user ID is a federated user id.
 * CP supports only federated Google accounts today.
 */
export function isFederatedUserId(value: unknown): value is string {
  return typeof value === 'string' && FEDERATED_GOOGLE_USER_ID_PATTERN.test(value);
}

/**
 * A prefix for `sub` used by Auth0 Database connection.
 * See https://manage.auth0.com/dashboard/us/ch-local-dev/connections/database.
 */
const AUTH0_SUB_DATABASE_PREFIX = 'auth0|';

/**
 * A prefix for `sub` used by Auth0 Social Google connection.
 * See https://manage.auth0.com/dashboard/us/ch-local-dev/connections/social.
 */
const AUTH0_SUB_SOCIAL_GOOGLE_PREFIX = 'google-oauth2|';

/**
 * A prefix for `sub` used by Auth0 SAML connection.
 * See https://manage.auth0.com/dashboard/us/ch-local-dev/connections/enterprise/samlp.
 */
const AUTH0_SUB_SAML_PREFIX = 'samlp|';

/**
 * Returns true if the sub has native auth0 local DB sub or a migrated sub from Cognito.
 * Auth0 sub created for a user registered via Auth0. Example: auth0|64d28f0aadf0a94c33d39f2c.
 * A sub of user migrated from Cognito. Example: auth0|917c94ff-8601-4e98-8d7e-3c90b7f56acf.
 */
function isAuth0DatabaseSub(sub: string): boolean {
  if (!sub.startsWith(AUTH0_SUB_DATABASE_PREFIX)) {
    return false;
  }
  const innerId = sub.substring(AUTH0_SUB_DATABASE_PREFIX.length);
  return isAuth0DatabaseSubSuffix(innerId) || isUuid(innerId);
}

/**
 *  Returns true if the `sub` is an Auth0 user id for  Google (Social) connection.
 *  Example: 'google-oauth2|108299292056104070928'
 */
function isAuth0GoogleSub(sub: string): boolean {
  return (
    sub.startsWith(AUTH0_SUB_SOCIAL_GOOGLE_PREFIX) &&
    FEDERATED_GOOGLE_USER_ID_PATTERN.test(
      `${FEDERATED_GOOGLE_USER_ID_PREFIX}${sub.substring(AUTH0_SUB_SOCIAL_GOOGLE_PREFIX.length)}`
    )
  );
}

/**
 *  Returns true if the `sub` is an Auth0 user id for SAML connection.
 *  Example: 'samlp|connection-id|email'
 */
function isAuth0SamlSub(sub: string): boolean {
  const idParts = sub.split('|');
  // As of today, the connection id has to match an organization id (a.k.a uuid4 format).
  return idParts.length === 3 && sub.startsWith(AUTH0_SUB_SAML_PREFIX) && isUuid(idParts[1]) && isEmail(idParts[2]);
}

/** Returns true if the `sub` is a supported `Social` authentication profile (any enabled provider). */
function isAuth0SocialSub(sub: string): boolean {
  return isAuth0GoogleSub(sub);
}

/** Returns true if the sub is an Auth0 user `sub` field. */
export function isAuth0Sub(sub: unknown): sub is string {
  return typeof sub === 'string' && (isAuth0DatabaseSub(sub) || isAuth0SocialSub(sub));
}

/**
 * Converts Auth0 user id (sub) to the CP user id (originally Cognito user ids).
 * These two ids use different prefixes, and we have to remap new Auth0 user ids to the old Cognito-like user
 * ids to keep the compatibility in our DB and other resources that depend on user id.
 */
export function convertAuth0SubToCpUserId(
  auth0UserId: string,
  onFailure: 'empty-string-on-fail' | 'error-on-fail' = 'error-on-fail'
): string {
  if (isAuth0DatabaseSub(auth0UserId)) {
    return auth0UserId.substring(AUTH0_SUB_DATABASE_PREFIX.length);
  } else if (isAuth0GoogleSub(auth0UserId)) {
    return FEDERATED_GOOGLE_USER_ID_PREFIX + auth0UserId.substring(AUTH0_SUB_SOCIAL_GOOGLE_PREFIX.length);
  } else if (isAuth0SamlSub(auth0UserId)) {
    // We didn't have saml user ids before auth0,
    // so no need to convert saml user id because there is no way it'll match any cp user id existing from before.
    return auth0UserId;
  } else {
    if (onFailure === 'empty-string-on-fail') {
      return '';
    }
    fail(`Unsupported Auth0 user id: ${auth0UserId}`);
  }
}

/** Converts CP user ID into Auth0 'sub' form. */
export function convertCpUserIdToAuth0Sub(cpUserId: string): string {
  assertTruthy(isUserId(cpUserId), `Invalid user id: ${cpUserId}`);
  return isAuth0SamlSub(cpUserId)
    ? cpUserId
    : cpUserId.startsWith(FEDERATED_GOOGLE_USER_ID_PREFIX)
    ? AUTH0_SUB_SOCIAL_GOOGLE_PREFIX + cpUserId.substring(FEDERATED_GOOGLE_USER_ID_PREFIX.length)
    : AUTH0_SUB_DATABASE_PREFIX + cpUserId;
}

/** Returns `true` if `userId` is a valid CP user id. */
export function isUserId(userId: unknown): userId is string {
  return (
    isUuid(userId) ||
    isAuth0DatabaseSubSuffix(userId) ||
    isFederatedUserId(userId) ||
    isOktaId(userId) ||
    isSamlUserId(userId)
  );
}

const VALID_OKTA_ID_PATTERN = /^okta_[a-zA-Z0-9]{20}$/;
const VALID_OKTA_GROUP_ID_PATTERN = /^[a-zA-Z0-9]{15,}$/;
const VALID_SYSADMIN_PROD_OKTA_GROUP_NAME_PATTERN = /^sysadmin-prod/i;
const VALID_SYSADMIN_DEV_STAGING_OKTA_GROUP_NAME_PATTERN = /^sysadmin-dev-staging/i;

export function isOktaId(value: unknown): boolean {
  return typeof value === 'string' && VALID_OKTA_ID_PATTERN.test(value);
}

export function isOktaGroupId(value: unknown): boolean {
  return typeof value === 'string' && VALID_OKTA_GROUP_ID_PATTERN.test(value);
}

export function isSysadminProdOktaGroupName(value: unknown): boolean {
  return typeof value === 'string' && VALID_SYSADMIN_PROD_OKTA_GROUP_NAME_PATTERN.test(value);
}

export function isSysadminDevStagingOktaGroupName(value: unknown): boolean {
  return typeof value === 'string' && VALID_SYSADMIN_DEV_STAGING_OKTA_GROUP_NAME_PATTERN.test(value);
}

export function isSamlUserId(value: unknown): boolean {
  return typeof value === 'string' && isAuth0SamlSub(value);
}

/**
 * Returns true if the value is a suffix (a part after 'auth|' prefix) of the Auth0 local database subs.
 * Example of the complete sub: 'auth0|64d28f0aadf0a94c33d39f2c' where the suffix is '64d28f0aadf0a94c33d39f2c'.
 */
export function isAuth0DatabaseSubSuffix(value: unknown): value is string {
  return typeof value === 'string' && value.length >= 22 && value.length <= 28 && isHexString(value);
}

/** Returns type of the identity provider by user id. */
export function getIdentityProviderTypeById(userId: string): UserIdentityProviderType {
  return isFederatedUserId(userId) ? 'social' : 'database';
}
