import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IpAccessListEntry } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isIpAccessSource } from '@cp/common/utils/ValidationUtils';
import { downloadText } from '@cp/cp-common-web/DownloadUtils';
import { trackByName } from '@cp/web/app/common/utils/AngularUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import {
  AccessListJsonFile,
  convertAccessListJsonFileToString
} from '@cp/web/app/instances/ip-access-list/ip-access-list-common';

@Component({
  selector: 'cp-import-ip-access-list-from-file-dialog',
  templateUrl: './import-ip-access-list-from-file-dialog.component.html',
  styleUrls: ['./import-ip-access-list-from-file-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportIpAccessListFromFileDialogComponent {
  @ViewChild('fileInput')
  fileInput!: ElementRef<HTMLInputElement>;

  files: Array<ParsedJsonFile> = [];

  readonly trackByName = trackByName;

  isSubmitInProgress = false;
  errorMessage?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly instanceId: string,
    readonly dialogRef: MatDialogRef<unknown>,
    private readonly cdr: ChangeDetectorRef,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly snackBar: MatSnackBar
  ) {
    assertTruthy(this.instanceId);
  }

  downloadTemplateFile(): void {
    downloadText(IP_ACCESS_LIST_TEMPLATE_FILE_CONTENT, 'ip-access-list-template.json');
  }

  async handleFileInput(event: Event): Promise<void> {
    this.errorMessage = undefined;
    this.cdr.markForCheck();
    const fileList: FileList | null = (event.target as HTMLInputElement).files;
    const file: File | null = fileList && fileList[0];
    if (!file) {
      return;
    }
    const content = await file.text();
    const parseResult = parseJsonFileContent(content);
    if (typeof parseResult === 'string') {
      this.errorMessage = `Failed to parse file content: ${parseResult}`;
      this.cdr.markForCheck();
      return;
    }
    const ipAccessList: Array<IpAccessListEntry> = parseResult.addresses.map((e) => ({
      source: e.address,
      description: e.description
    }));
    this.files = this.files.filter((f) => !(f.name === file.name && f.rawContent === content)); // Do not list the same file twice.
    this.files.push({ name: file.name, ipAccessList, rawContent: content });
    this.cdr.markForCheck();
  }

  removeFileFromList(fileToRemove: ParsedJsonFile): void {
    this.files = this.files.filter((f) => f != fileToRemove);
    this.fileInput.nativeElement.value = '';
    this.isSubmitInProgress = false;
    this.errorMessage = undefined;
    this.cdr.markForCheck();
  }

  async submitFiles(): Promise<void> {
    assertTruthy(this.files.length > 0);
    try {
      this.isSubmitInProgress = true;
      this.errorMessage = undefined;
      this.cdr.markForCheck();
      const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
      let newIpAccessList = [...instance.ipAccessList];
      for (const file of this.files) {
        newIpAccessList = newIpAccessList.filter((e1) => !file.ipAccessList.some((e2) => e2.source === e1.source));
        newIpAccessList.push(...file.ipAccessList);
      }
      await this.instanceService.updateIpAccessList(this.instanceId, newIpAccessList);
      this.snackBar.open('IP addresses have been added', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      this.errorMessage = 'Failed to update access list';
    } finally {
      this.isSubmitInProgress = false;
      this.cdr.markForCheck();
    }
  }

  get isButtonSubmitDisabled(): boolean {
    return this.files.length === 0 || this.isSubmitInProgress;
  }
}

export function getImportIpAccessListFromFileDialogConfig(): Partial<MatDialogConfig<string>> {
  return {
    width: '100%',
    maxWidth: '517px',
    autoFocus: true,
    restoreFocus: false,
    panelClass: 'modal'
  };
}

/** Uploaded file entry: file name & raw text content. */
interface ParsedJsonFile {
  name: string;
  rawContent: string;
  ipAccessList: Array<IpAccessListEntry>;
}

const IP_ACCESS_LIST_TEMPLATE_FILE_CONTENT = convertAccessListJsonFileToString({
  addresses: [
    { address: '192.168.0.1', description: 'IP address example' },
    { address: '192.168.0.1/32', description: 'CIDR address example' }
  ]
});

/** Parses JSON file and returns either object (the JSON file) or an error message: a string. */
export function parseJsonFileContent(content: string): AccessListJsonFile | string {
  try {
    const jsonFile: AccessListJsonFile = JSON.parse(content);
    if (!Array.isArray(jsonFile.addresses)) {
      return 'No "addresses" field found.';
    }
    if (jsonFile.addresses.length === 0) {
      return '"addresses" array is empty';
    }
    for (const address of jsonFile.addresses) {
      if (!isIpAccessSource(address.address)) {
        return 'Invalid address: ' + address.address;
      }
    }
    return jsonFile;
  } catch (e) {
    return (e as Error).message || 'Failed to parse JSON input';
  }
}
