import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isDefined } from '@cp/common/protocol/Common';
import {
  Instance,
  INSTANCE_TIERS_THAT_ARE_DEDICATED,
  STATES_VALID_FOR_PASSWORD_RESET,
  STATES_VALID_TO_START,
  STATES_VALID_TO_STOP,
  STATES_VALID_TO_TERMINATE
} from '@cp/common/protocol/Instance';
import {
  OrganizationBillingStatus,
  OrganizationRestrictions,
  OrganizationRole
} from '@cp/common/protocol/Organization';
import { REGION_BY_ID } from '@cp/common/protocol/Region';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { formatClickHouseVersion } from '@cp/common/utils/MiscUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { getInstancePagePath } from '@cp/web/app/app-routing-utils';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { isMaintenanceWindowNotificationShown } from '@cp/web/app/common/utils/MaintenanceWindowUtils';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { TrialServiceLimitDialogComponent } from '@cp/web/app/instances/trial-service-limit-dialog/trial-service-limit-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { distinctUntilChanged, Observable, switchMap, take, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cp-instance-card',
  templateUrl: './instance-card.component.html',
  styleUrls: ['./instance-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceCardComponent extends OnDestroyComponent implements OnInit, OnChanges {
  @Input()
  instance!: Instance;

  @Input()
  orgBillingStatus!: OrganizationBillingStatus;

  readonly regionMap = REGION_BY_ID;
  readonly STATES_VALID_TO_START = STATES_VALID_TO_START;
  //For UI purposes, we don't want to display the `stop` action when the instance is already stopped.
  readonly STATES_VALID_TO_STOP = STATES_VALID_TO_STOP.filter((state) => state != 'stopped');
  readonly STATES_VALID_TO_TERMINATE = STATES_VALID_TO_TERMINATE;
  readonly STATES_VALID_FOR_PASSWORD_RESET = STATES_VALID_FOR_PASSWORD_RESET;
  readonly myOrgRoleObs: Observable<OrganizationRole>;
  readonly myOrgRestrictionsObs: Observable<OrganizationRestrictions>;
  readonly formatCHVersion = formatClickHouseVersion;

  readonly customerKeyTooltip = 'This service is using a Customer Managed\nEncryption Key (CMEK)';

  isUpgradeFeatureActive = false;
  isDedicated = false;
  canStartService = false;
  shouldResetPassword = false;

  constructor(
    private readonly instanceUiService: InstanceUiService,
    private readonly instanceService: InstanceService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly featureService: FeaturesService,
    private readonly organizationStateService: OrganizationStateService
  ) {
    super();
    this.myOrgRoleObs = organizationStateService.observeCurrentOrganizationRole();
    this.myOrgRestrictionsObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeRestrictions(id))
    );
    this.myOrgRestrictionsObs
      .pipe(
        takeUntil(this.onDestroy),
        distinctUntilChanged((prev, curr) => prev.canStartInstances === curr.canStartInstances)
      )
      .subscribe((restrictions) => {
        this.canStartService = restrictions.canStartInstances;
      });
    this.isUpgradeFeatureActive = featureService.hasUserFlag('FT_UPGRADE_INSTANCE');
  }

  get detailsPagePath(): string {
    return getInstancePagePath(truthy(this.instance).id);
  }

  ngOnInit(): void {
    this.assertInputs();
  }

  ngOnChanges(): void {
    this.assertInputs();
    this.shouldResetPassword = this.instance.pendingActions.some((a) => a.type === 'password-reset');
    this.isDedicated = INSTANCE_TIERS_THAT_ARE_DEDICATED.has(this.instance.instanceTier);
  }

  private assertInputs(): void {
    assertTruthy(this.instance);
    assertTruthy(this.orgBillingStatus);
  }

  get isInstanceUpgradeDisabledObs(): Observable<boolean> {
    return this.instanceService.observeCanUpgradeInstance(this.instance.id);
  }

  async startInstance(): Promise<void> {
    if (!this.canStartService) {
      TrialServiceLimitDialogComponent.showStartServiceRestricted(this.dialog, this.snackBar, this.orgBillingStatus);
    } else {
      await this.instanceService.startInstance(this.instance.id);
    }
  }

  showDeleteInstanceDialog(): void {
    this.instanceUiService.showDeleteInstanceDialog(this.instance);
  }

  onUpgrade(): void {
    const dialogRef = this.instanceUiService.showUpgradeInstanceDialog();
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(async (shouldUpgrade) => {
        if (shouldUpgrade) {
          try {
            await this.instanceService.upgradeInstance(this.instance.id);
          } catch (e) {
            this.instanceUiService.showSnackBar(
              `Error: we couldn't update your service ${this.instance.name}. Please try again later or if the error persists contact our support.`
            );
          }
        }
      });
  }

  async onPasswordSetup(): Promise<void> {
    try {
      await this.instanceService.resetPassword(this.instance.id);
    } catch (e) {
      console.error(e);
      this.snackBar.open(`Error while trying to setup the password`, 'Dismiss', { duration: 5000 });
    }
    this.instanceUiService.showConnectDialog(this.instance.id, true);
    this.shouldResetPassword = false;
  }

  confirmStopInstance(instance: Instance): void {
    this.instanceUiService
      .showConfirmStopInstance(instance)
      .afterClosed()
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(async (result) => {
        if (result) {
          await this.instanceService.stopInstance(instance.id);
        }
      });
  }

  get isMaintenanceScheduled(): boolean {
    return this.instance.maintenanceWindows.some((w) => isMaintenanceWindowNotificationShown(w));
  }

  get isMaintenanceInProgress(): boolean {
    return !!this.instance.activeMaintenanceKind;
  }

  /** Return true if instance has no allowed IPs. */
  checkHasNoIpAccess(instance: Instance): boolean {
    return instance.ipAccessList.length === 0;
  }
}
