import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { InstanceState } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { capitalize } from '@cp/web/app/common/utils/StringUtils';

/**
 *  How to render the state:
 *   - BADGE: both icon & label are wrapped by a rect with a rounded corners.
 *   - ICON_AND_LABEL: icon and labels are rendered separately on a transparent background.
 */
export type InstanceStateComponentKind = 'BADGE' | 'ICON_AND_LABEL';

export type InstanceStateWithUpgrade = InstanceState | 'upgrading' | 'upgrading-error';

@Component({
  selector: 'cp-instance-state',
  templateUrl: './instance-state.component.html',
  styleUrls: ['./instance-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceStateComponent implements OnChanges, OnInit {
  @Input()
  state!: InstanceStateWithUpgrade;

  @Input()
  kind: InstanceStateComponentKind = 'BADGE';

  @Input()
  additionalClass?: 'normal_weight';

  stateText!: string;

  svgIcon!: string;

  ngOnInit(): void {
    assertTruthy(this.state);
  }

  ngOnChanges(): void {
    assertTruthy(this.state);
    this.updateState();
  }

  private updateState(): void {
    this.stateText =
      this.state === 'upgrading'
        ? 'Upgrade in progress'
        : this.state === 'upgrading-error'
        ? 'Upgrade failed'
        : capitalize(this.state);
    switch (this.state) {
      case 'provisioning':
      case 'starting':
      case 'stopping':
      case 'terminating':
      case 'upgrading':
      case 'awaking':
        this.svgIcon = 'loader';
        break;
      case 'running':
        this.svgIcon = 'check_icon';
        break;
      case 'idle':
        this.svgIcon = 'glyph_idle';
        break;
      case 'stopped':
        this.svgIcon = 'stopped';
        break;
      case 'failed':
      case 'upgrading-error':
      case 'degraded':
        this.svgIcon = 'error_icon';
        break;
      default:
        throw new Error(`Unsupported instance state: ${this.state}`);
    }
  }
}
