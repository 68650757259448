import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Inject, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AuthModule as Auth0Module, AuthService as Auth0Service } from '@auth0/auth0-angular';
import { CpCommonServicesModule } from '@cp/common-services/cp-common-services.module';
import { ApproximateTimePipe } from '@cp/common-services/pipes/approximate-time.pipe';
import { DurationHoursPipe, DurationMinutesPipe, DurationPipe } from '@cp/common-services/pipes/duration.pipe';
import { RestService } from '@cp/common-services/rest.service';
import { StateService } from '@cp/common-services/state/state.service';
import { CognitoWebAuthProvider } from '@cp/common-services/web-auth/cognito-web-auth-provider';
import { WebAuthProvider } from '@cp/common-services/web-auth/web-auth-provider';
import { WEB_SOCKET_CONSTRUCTOR, WebsocketCtor, WebSocketService } from '@cp/common-services/web-socket.service';
import { AUTH0_CP_WEB_AUDIENCE } from '@cp/common/protocol/Auth0';
import { AuthLogoComponent } from '@cp/cp-common-web/auth-logo';
import { BadgeComponent } from '@cp/cp-common-web/badge';
import { CreateInstanceAutoscalingDetailsComponent } from '@cp/cp-common-web/create-instance-auto-scaling-details';
import { NgVarDirective, TypeSafeMatCellDef } from '@cp/cp-common-web/directives';
import { HealthIconComponent } from '@cp/cp-common-web/health-icon';
import { LoadingIndicatorComponent } from '@cp/cp-common-web/loading-indicator/loading-indicator.component';
import { OrgActivityViewComponent } from '@cp/cp-common-web/org-activity-view';
import { SystemMessageComponent } from '@cp/cp-common-web/system-message';
import { SeedUiModule } from '@cp/seed-ui/lib/seed-ui.module';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { AvatarComponent } from '@cp/web/app/account/avatar/avatar.component';
import { ConfirmEmailComponent } from '@cp/web/app/account/confirm-email/confirm-email.component';
import { EmailVerificationErrorAuth0CallbackComponent } from '@cp/web/app/account/email-verification-error-auth0-callback/email-verification-error-auth0-callback.component';
import { ForgotPasswordComponent } from '@cp/web/app/account/forgot-password/forgot-password.component';
import { VerifyTotpComponent } from '@cp/web/app/account/mfa/verify-totp/verify-totp.component';
import { ChangePasswordComponent } from '@cp/web/app/account/profile/change-password/change-password.component';
import { ProfileComponent } from '@cp/web/app/account/profile/profile.component';
import { UpdatePersonalInfoComponent } from '@cp/web/app/account/profile/update-personal-info/update-personal-info.component';
import { ResendConfirmEmailComponent } from '@cp/web/app/account/resend-confirm-email/resend-confirm-email.component';
import { ResetPasswordComponent } from '@cp/web/app/account/reset-password/reset-password.component';
import { SignInAuth0Component } from '@cp/web/app/account/sign-in/sign-in-auth0.component';
import { SignInComponent } from '@cp/web/app/account/sign-in/sign-in.component';
import { SignUpCheckboxComponent } from '@cp/web/app/account/sign-up/sign-up-checkbox/sign-up-checkbox.component';
import { SignUpComponent } from '@cp/web/app/account/sign-up/sign-up.component';
import { TermsOfServiceComponent } from '@cp/web/app/account/sign-up/terms-of-service/terms-of-service.component';
import { RequestCreditsDialogComponent } from '@cp/web/app/admin/admin-billing/request-credits/request-credits-dialog.component';
import { AdminService } from '@cp/web/app/admin/admin.service';
import { BillingApiService } from '@cp/web/app/admin/billing-api.service';
import { AppRoutingModule } from '@cp/web/app/app-routing.module';
import { AppComponent } from '@cp/web/app/app.component';
import { AuthService, WEB_AUTH_PROVIDER_TOKEN } from '@cp/web/app/auth/auth.service';
import { Auth0WebAuthProvider } from '@cp/web/app/auth/auth0-web-auth-provider';
import { Auth0DemoComponent } from '@cp/web/app/auth0-demo/auth0-demo.component';
import { AUTH0_ENABLED_ENVIRONMENTS, isAuth0Mode } from '@cp/web/app/auth0-utils';
import { DriftService } from '@cp/web/app/chatbot/drift.service';
import { ConfirmationDialogComponent } from '@cp/web/app/common/components/confirmation-dialog/confirmation-dialog.component';
import { HeaderComponent } from '@cp/web/app/common/components/header/header.component';
import { LogoComponent } from '@cp/web/app/common/components/logo/logo.component';
import { ProtectedLayoutComponent } from '@cp/web/app/common/components/protected-layout/protected-layout.component';
import { SidebarLayoutComponent } from '@cp/web/app/common/components/sidebar-layout/sidebar-layout.component';
import { SidebarComponent } from '@cp/web/app/common/components/sidebar/sidebar.component';
import { UserMfaInputPageComponent } from '@cp/web/app/common/components/user-mfa-input-page/user-mfa-input-page.component';
import { ShowTooltipIfTruncatedDirective } from '@cp/web/app/common/directives/show-tooltip-if-truncated.directive';
import { BatchRequestOptimizerInterceptor } from '@cp/web/app/common/interceptors/batch-request-optimizer.interceptor';
import { OrganizationFeatureEnabledPipe, UserFeatureEnabledPipe } from '@cp/web/app/common/pipes/feature.pipe';
import { PendingUserActionsService } from '@cp/web/app/common/services/pending-user-actions.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import { THEME_SERVICE_TOKEN, ThemeService } from '@cp/web/app/common/services/theme.service';
import { isTestRun } from '@cp/web/app/common/utils/AngularUtils';
import { RECAPTCHA_PROVIDER_TOKEN, recaptchaProviderFn } from '@cp/web/app/common/utils/RecaptchaUtils';
import { ConnectToInstanceDialogComponent } from '@cp/web/app/instances/connect-to-instance-dialog/connect-to-instance-dialog.component';
import { CreateInstanceDialogComponent } from '@cp/web/app/instances/create-instance-dialog/create-instance-dialog.component';
import { CreateInstanceComponent } from '@cp/web/app/instances/create-instance/create-instance.component';
import { IframeWithContentComponent } from '@cp/web/app/instances/iframe-with-content/iframe-with-content.component';
import { DefaultInstanceApiService, INSTANCE_API_SERVICE_TOKEN } from '@cp/web/app/instances/instance-api.service';
import { InstanceBackupService } from '@cp/web/app/instances/instance-backups/instance-backup.service';
import { InstanceCardComponent } from '@cp/web/app/instances/instance-card/instance-card.component';
import { InstanceMetricsDashboardComponent } from '@cp/web/app/instances/instance-metrics-dashboard/instance-metrics-dashboard.component';
import { InstanceStateComponent } from '@cp/web/app/instances/instance-state/instance-state.component';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { InstancesComponent } from '@cp/web/app/instances/instances.component';
import { FirstTimeSetIpAccessListComponent } from '@cp/web/app/instances/ip-access-list/first-time-set-ip-access-list.component';
import { UpgradeInstanceCardComponent } from '@cp/web/app/instances/upgrade-instance-card/upgrade-instance-card.component';
import { UpgradeInstanceDialogComponent } from '@cp/web/app/instances/upgrade-instance-dialog/upgrade-instance-dialog.component';
import { MetricsFeedbackDialogComponent } from '@cp/web/app/metrics/metric-feedback-dialog/metrics-feedback-dialog.component';
import { ApiKeysService } from '@cp/web/app/organizations/api-keys/api-keys-service';
import { CreateOrganizationDialogComponent } from '@cp/web/app/organizations/create-organization/create-organization-dialog.component';
import { DbUserDetailsComponent } from '@cp/web/app/organizations/manage-db-users/db-user-details/db-user-details.component';
import { OrgActivityComponent } from '@cp/web/app/organizations/org-activity/org-activity.component';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { SupportService } from '@cp/web/app/support/support.service';
import { environment } from '@cp/web/environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/angular';
import { QRCodeModule } from 'angularx-qrcode';
import { Amplify } from 'aws-amplify';
import { AmplifyConfig } from 'aws-amplify/lib-esm/Common/types/types';
import {
  createLogCachePipe,
  createLogLevelFilterPipe,
  getOriginalConsoleMethods,
  installConsoleOverrides,
  LogPipe
} from 'logpipes';
import { AuthPasswordRequirementsComponent } from './account/auth-password-requirements/auth-password-requirements.component';
import { ConfirmEmailExpirationDialogComponent } from './account/confirm-email-expiration-dialog/confirm-email-expiration-dialog.component';
import { EntryQuestionnaireDialogComponent } from './account/entry-questionnaire-dialog/entry-questionnaire-dialog.component';
import { AuthenticatorAppSetUpDialogContentComponent } from './account/mfa/authenticator-app-set-up-dialog/authenticator-app-set-up-dialog-content/authenticator-app-set-up-dialog-content.component';
import { AuthenticatorAppSetUpDialogComponent } from './account/mfa/authenticator-app-set-up-dialog/authenticator-app-set-up-dialog.component';
import { DisableMfaConfirmationDialogContentComponent } from './account/mfa/disable-mfa-confirmation-dialog/disable-mfa-confirmation-dialog-content/disable-mfa-confirmation-dialog-content.component';
import { DisableMfaConfirmationDialogComponent } from './account/mfa/disable-mfa-confirmation-dialog/disable-mfa-confirmation-dialog.component';
import { MfaUpdateDialogComponent } from './account/mfa/mfa-update-dialog/mfa-update-dialog.component';
import { OrganizationInvitationsDialogComponent } from './account/organization-invitations-dialog/organization-invitations-dialog.component';
import { TrialCountdownComponent } from './account/trial-countdown/trial-countdown.component';
import { VerificationEmailMessageComponent } from './account/verification-email-message/verification-email-message.component';
import { AddCreditCardComponent } from './admin/add-credit-card/add-credit-card.component';
import { AdminBillingComponent } from './admin/admin-billing/admin-billing.component';
import { AdminGeneralComponent } from './admin/admin-general/admin-general.component';
import { AdminUsageChartComponent } from './admin/admin-usage-chart/admin-usage-chart.component';
import { AdminUsageComponent } from './admin/admin-usage/admin-usage.component';
import { AdminComponent } from './admin/admin.component';
import { BillingConversionDialogComponent } from './admin/billing-conversion-dialog/billing-conversion-dialog.component';
import { ChangeBillingAddressOnboardingComponent } from './admin/change-billing-address-onboarding/change-billing-address-onboarding.component';
import { ChangeBillingAddressComponent } from './admin/change-billing-address/change-billing-address.component';
import { ChangeBillingCompanyDetailsOnboardingComponent } from './admin/change-billing-company-details-onboarding/change-billing-company-details-onboarding.component';
import { ChangeBillingCompanyDetailsComponent } from './admin/change-billing-company-details/change-billing-company-details.component';
import { CodeSnippetComponent } from './common/code-snippet/code-snippet.component';
import { CredentialsContainerComponent } from './common/components/credentials-container/credentials-container.component';
import { DialogCloseButtonComponent } from './common/components/dialog-close-button/dialog-close-button.component';
import { DialogSubmittedComponent } from './common/components/dialog-submitted/dialog-submitted.component';
import { DialogTitleComponent } from './common/components/dialog-title/dialog-title.component';
import { InternalSidebarComponent } from './common/components/internal-sidebar/internal-sidebar.component';
import { OnboardComponent } from './common/components/onboard/onboard.component';
import { RegionBlockMessageComponent } from './common/components/region-block-message/region-block-message.component';
import { UploadProgressBarComponent } from './common/components/upload-progress-bar/upload-progress-bar.component';
import { GalaxyTrackBlurDirective } from './common/directives/galaxy-track-blur.directive';
import { GalaxyTrackChangeDirective } from './common/directives/galaxy-track-change.directive';
import { GalaxyTrackClickDirective } from './common/directives/galaxy-track-click.directive';
import { HighlightJsDirective } from './common/directives/highlight-js.directive';
import { NamedTemplateDirective } from './common/directives/named-template.directive';
import { HighlightedCodeSnippetComponent } from './common/highlighted-code-snippet/highlighted-code-snippet.component';
import { DataSizePipe } from './common/pipes/data-size.pipe';
import { LineClampPipe } from './common/pipes/line-clamp.pipe';
import { MarkdownPipe } from './common/pipes/markdown.pipe';
import { MinRolePipe } from './common/pipes/min-role.pipe';
import { AggregationPeriodPipe, TimePeriodPipe } from './common/pipes/time-period.pipe';
import { createGalaxyLogPipe, GalaxyService } from './common/services/galaxy.service';
import { HelpFlyOutComponent } from './help/help-fly-out/help-fly-out.component';
import { ChangeInstanceNameDialogComponent } from './instances/change-instance-name-dialog/change-instance-name-dialog.component';
import { ConnectConsoleButtonComponent } from './instances/connect-console-button/connect-console-button.component';
import { ConnectToInstanceDialogContentComponent } from './instances/connect-to-instance-dialog/connect-to-instance-dialog-content/connect-to-instance-dialog-content.component';
import { CustomerManagedEncryptionKeyDetailsComponent } from './instances/customer-managed-encryption-key-details/customer-managed-encryption-key-details.component';
import { DeleteInstanceDialogComponent } from './instances/delete-instance-dialog/delete-instance-dialog.component';
import { HttpsEndpointDetailsComponent } from './instances/https-endpoint-details/https-endpoint-details.component';
import { InstanceBackupsComponent } from './instances/instance-backups/instance-backups.component';
import { InstanceDbRolesComponent } from './instances/instance-db-roles/instance-db-roles.component';
import { InstanceIntegrationsPageComponent } from './instances/instance-integrations-page/instance-integrations-page.component';
import { InstanceNoAccessPromptComponent } from './instances/instance-no-access-prompt/instance-no-access-prompt.component';
import { InstancePageSummaryComponent } from './instances/instance-page-summary/instance-page-summary.component';
import { InstancePageComponent } from './instances/instance-page/instance-page.component';
import { InstanceProvisioningDialogComponent } from './instances/instance-provisioning-dialog/instance-provisioning-dialog.component';
import { InstanceSecurityComponent } from './instances/instance-security/instance-security.component';
import { InstanceSettingsComponent } from './instances/instance-settings/instance-settings.component';
import { InstanceSideDetailsComponent } from './instances/instance-side-details/instance-side-details.component';
import { AddIpAccessListEntryDialogComponent } from './instances/ip-access-list/add-ip-access-list-entry-dialog.component';
import { AddIpAccessListEntryFormComponent } from './instances/ip-access-list/add-ip-access-list-entry-form.component';
import { AddIpAccessListFormAndTableComponent } from './instances/ip-access-list/add-ip-access-list-form-and-table.component';
import { DeleteIpAccessListEntryDialogComponent } from './instances/ip-access-list/delete-ip-access-list-entry-dialog.component';
import { EditIpAccessListEntryDialogComponent } from './instances/ip-access-list/edit-ip-access-list-entry-dialog.component';
import { FirstTimeSetIpAccessListDialogComponent } from './instances/ip-access-list/first-time-set-ip-access-list-dialog.component';
import { ImportIpAccessListFromFileDialogComponent } from './instances/ip-access-list/import-ip-access-list-from-file-dialog.component';
import { ImportIpAccessListFromInstanceDialogComponent } from './instances/ip-access-list/import-ip-access-list-from-instance-dialog.component';
import { IpAccessListInfoIconComponent } from './instances/ip-access-list/ip-access-list-info-icon.component';
import { IpAccessListTableViewComponent } from './instances/ip-access-list/ip-access-list-table-view.component';
import { IpAccessListTableComponent } from './instances/ip-access-list/ip-access-list-table.component';
import { MaintenanceWindowInfoDialogComponent } from './instances/maintenance-window-info-dialog/maintenance-window-info-dialog.component';
import { MysqlEndpointDetailsComponent } from './instances/mysql-endpoint-details/mysql-endpoint-details.component';
import { NativeEndpointDetailsComponent } from './instances/native-endpoint-details/native-endpoint-details.component';
import { ResetInstancePasswordDialogComponent } from './instances/reset-instance-password-dialog/reset-instance-password-dialog.component';
import { RestoreInstanceBackupDialogComponent } from './instances/restore-instance-backup-dialog/restore-instance-backup-dialog.component';
import { SqlClientDialogComponent } from './instances/sql-client-dialog/sql-client-dialog.component';
import { TrialServiceLimitDialogComponent } from './instances/trial-service-limit-dialog/trial-service-limit-dialog.component';
import { UsernameAndPasswordComponent } from './instances/username-and-password/username-and-password.component';
import { IntegrationCardComponent } from './marketplace/integration-card/integration-card.component';
import { IntegrationSummaryComponent } from './marketplace/integration-summary/integration-summary.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { IntegrationRelevantContentComponent } from './marketplace/relevant-content/relevant-content.component';
import { RequestIntegrationDialogComponent } from './marketplace/request-integration-dialog/request-integration-dialog.component';
import { MetricChartWithSummaryComponent } from './metrics/metric-chart-with-summary/metric-chart-with-summary.component';
import { MetricChartComponent } from './metrics/metric-chart/metric-chart.component';
import { MetricSummaryComponent } from './metrics/metric-summary/metric-summary.component';
import { OauthAuthorizerComponent } from './oauth/oauth-authorizer/oauth-authorizer.component';
import { ApiKeysTableComponent } from './organizations/api-keys/api-keys-table/api-keys-table.component';
import { CreateApiKeyDialogComponent } from './organizations/api-keys/create-api-key-dialog/create-api-key-dialog.component';
import { EditApiKeyDialogComponent } from './organizations/api-keys/edit-api-key-dialog/edit-api-key-dialog.component';
import { EditApiKeyFormComponent } from './organizations/api-keys/edit-api-key-form/edit-api-key-form.component';
import { OrganizationApiKeysPageComponent } from './organizations/api-keys/organization-api-key-page/organization-api-keys-page.component';
import { ApproveOrgInvitationsComponent } from './organizations/approve-org-invitations/approve-org-invitations.component';
import { BillingAlertComponent } from './organizations/billing-alert/billing-alert.component';
import { ChangeBillingContactDialogComponent } from './organizations/change-billing-contact-dialog/change-billing-contact-dialog.component';
import { ChangeOrganizationNameDialogComponent } from './organizations/change-organization-name-dialog/change-organization-name-dialog.component';
import { ManageDbUsersComponent } from './organizations/manage-db-users/manage-db-users.component';
import { ManageInstanceDbUsersComponent } from './organizations/manage-db-users/manage-instance-db-users.component';
import { WakeIdleServicesDialogComponent } from './organizations/manage-db-users/wake-idle-services-dialog/wake-idle-services-dialog.component';
import { ManageOrgUsersComponent } from './organizations/manage-org-users/manage-org-users.component';
import { NonpaymentDialogComponent } from './organizations/nonpayment-dialog/nonpayment-dialog.component';
import { OrgInviteExpiredDialogComponent } from './organizations/org-invite-expired-dialog/org-invite-expired-dialog.component';
import { OrgInviteExpiredComponent } from './organizations/org-invite-expired/org-invite-expired.component';
import { SendOrgInviteConfirmDialogComponent } from './organizations/send-org-invite-confirm-dialog/send-org-invite-confirm-dialog.component';
import { SendOrgInvitesDialogComponent } from './organizations/send-org-invites-dialog/send-org-invites-dialog.component';
import { TackleFirmographicsComponent } from './organizations/tackle-firmographics/tackle-firmographics.component';
import { TrialExpiredDialogComponent } from './organizations/trial-expired-dialog/trial-expired-dialog.component';
import { UsersPageComponent } from './organizations/users-page/users-page.component';
import { CaseReplyDialogComponent } from './support/case-reply-dialog/case-reply-dialog.component';
import { CaseReplyComponent } from './support/case-reply/case-reply.component';
import { CaseTimelineComponent } from './support/case-timeline/case-timeline.component';
import { CaseSubmittedDialogComponent } from './support/create-case-dialog/case-submitted-dialog/case-submitted-dialog.component';
import { CreateCaseDialogComponent } from './support/create-case-dialog/create-case-dialog.component';
import { LearnPageComponent } from './support/learn-page/learn-page.component';
import { SupportCasePageComponent } from './support/support-case-page/support-case-page.component';
import { SupportCasesComponent } from './support/support-cases/support-cases.component';
import { SupportDetailsComponent } from './support/support-details/support-details.component';
import { SupportPageComponent } from './support/support-page/support-page.component';
import { UploadComponent } from './upload/upload.component';

@NgModule({
  declarations: [
    AddCreditCardComponent,
    AddIpAccessListEntryDialogComponent,
    AddIpAccessListEntryFormComponent,
    AddIpAccessListFormAndTableComponent,
    AdminBillingComponent,
    AdminComponent,
    AdminGeneralComponent,
    AdminUsageChartComponent,
    AdminUsageComponent,
    AggregationPeriodPipe,
    ApiKeysTableComponent,
    AppComponent,
    ApproveOrgInvitationsComponent,
    Auth0DemoComponent,
    AuthenticatorAppSetUpDialogComponent,
    AuthenticatorAppSetUpDialogContentComponent,
    AuthPasswordRequirementsComponent,
    AvatarComponent,
    BillingAlertComponent,
    BillingConversionDialogComponent,
    CaseReplyComponent,
    CaseReplyDialogComponent,
    CaseSubmittedDialogComponent,
    CaseTimelineComponent,
    ChangeBillingAddressComponent,
    ChangeBillingCompanyDetailsComponent,
    ChangeBillingCompanyDetailsOnboardingComponent,
    ChangeBillingContactDialogComponent,
    ChangeInstanceNameDialogComponent,
    ChangeOrganizationNameDialogComponent,
    ChangePasswordComponent,
    CodeSnippetComponent,
    ConfirmationDialogComponent,
    ConfirmEmailComponent,
    ConfirmEmailExpirationDialogComponent,
    ConnectConsoleButtonComponent,
    ConnectToInstanceDialogComponent,
    ConnectToInstanceDialogContentComponent,
    CreateApiKeyDialogComponent,
    CreateCaseDialogComponent,
    CreateInstanceComponent,
    CreateInstanceDialogComponent,
    CreateOrganizationDialogComponent,
    CredentialsContainerComponent,
    CustomerManagedEncryptionKeyDetailsComponent,
    DataSizePipe,
    DbUserDetailsComponent,
    DeleteInstanceDialogComponent,
    DeleteIpAccessListEntryDialogComponent,
    DialogCloseButtonComponent,
    DialogSubmittedComponent,
    DialogTitleComponent,
    DisableMfaConfirmationDialogComponent,
    DisableMfaConfirmationDialogContentComponent,
    EditApiKeyDialogComponent,
    EditApiKeyFormComponent,
    EditIpAccessListEntryDialogComponent,
    EmailVerificationErrorAuth0CallbackComponent,
    EntryQuestionnaireDialogComponent,
    FirstTimeSetIpAccessListComponent,
    FirstTimeSetIpAccessListDialogComponent,
    ForgotPasswordComponent,
    GalaxyTrackBlurDirective,
    GalaxyTrackChangeDirective,
    GalaxyTrackClickDirective,
    HeaderComponent,
    HelpFlyOutComponent,
    HighlightedCodeSnippetComponent,
    HighlightJsDirective,
    HttpsEndpointDetailsComponent,
    IframeWithContentComponent,
    ImportIpAccessListFromFileDialogComponent,
    ImportIpAccessListFromInstanceDialogComponent,
    InstanceBackupsComponent,
    InstanceCardComponent,
    InstanceIntegrationsPageComponent,
    InstanceMetricsDashboardComponent,
    InstanceNoAccessPromptComponent,
    InstancePageComponent,
    InstancePageSummaryComponent,
    InstanceProvisioningDialogComponent,
    InstancesComponent,
    InstanceSecurityComponent,
    InstanceSettingsComponent,
    InstanceSideDetailsComponent,
    InstanceStateComponent,
    IntegrationCardComponent,
    IntegrationRelevantContentComponent,
    IntegrationSummaryComponent,
    InternalSidebarComponent,
    IpAccessListInfoIconComponent,
    IpAccessListTableComponent,
    IpAccessListTableViewComponent,
    LearnPageComponent,
    LineClampPipe,
    DurationPipe,
    DurationHoursPipe,
    DurationMinutesPipe,
    LoadingIndicatorComponent,
    LogoComponent,
    ManageDbUsersComponent,
    ManageInstanceDbUsersComponent,
    ManageOrgUsersComponent,
    MarkdownPipe,
    MarketplaceComponent,
    MfaUpdateDialogComponent,
    MetricChartComponent,
    MetricChartWithSummaryComponent,
    MetricsFeedbackDialogComponent,
    MetricSummaryComponent,
    MinRolePipe,
    MysqlEndpointDetailsComponent,
    NamedTemplateDirective,
    NativeEndpointDetailsComponent,
    NonpaymentDialogComponent,
    OauthAuthorizerComponent,
    OnboardComponent,
    OrgActivityComponent,
    OrgActivityComponent,
    OrganizationApiKeysPageComponent,
    OrganizationFeatureEnabledPipe,
    OrganizationInvitationsDialogComponent,
    OrgInviteExpiredComponent,
    OrgInviteExpiredDialogComponent,
    ProfileComponent,
    ProtectedLayoutComponent,
    RegionBlockMessageComponent,
    RequestCreditsDialogComponent,
    RequestIntegrationDialogComponent,
    ResendConfirmEmailComponent,
    ResetInstancePasswordDialogComponent,
    ResetPasswordComponent,
    RestoreInstanceBackupDialogComponent,
    SendOrgInviteConfirmDialogComponent,
    SendOrgInvitesDialogComponent,
    ShowTooltipIfTruncatedDirective,
    SidebarComponent,
    SidebarLayoutComponent,
    SignInAuth0Component,
    SignInComponent,
    SignUpCheckboxComponent,
    SignUpComponent,
    SqlClientDialogComponent,
    SupportCasePageComponent,
    SupportCasesComponent,
    SupportDetailsComponent,
    SupportPageComponent,
    TackleFirmographicsComponent,
    TermsOfServiceComponent,
    TimePeriodPipe,
    TrialCountdownComponent,
    TrialExpiredDialogComponent,
    TrialServiceLimitDialogComponent,
    TypeSafeMatCellDef,
    UpdatePersonalInfoComponent,
    UpgradeInstanceCardComponent,
    UpgradeInstanceDialogComponent,
    UploadComponent,
    UploadProgressBarComponent,
    UserFeatureEnabledPipe,
    UserMfaInputPageComponent,
    UsernameAndPasswordComponent,
    UsersPageComponent,
    VerificationEmailMessageComponent,
    VerifyTotpComponent,
    WakeIdleServicesDialogComponent,
    InstanceDbRolesComponent,
    MaintenanceWindowInfoDialogComponent,
    ChangeBillingAddressOnboardingComponent
  ],
  imports: [
    AppRoutingModule,
    Auth0Module.forRoot({
      // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options.
      cacheLocation: 'localstorage',
      ...environment.auth0ClientOptions,
      domain: environment.auth0ClientOptions.customDomain, // Use custom domain by default for login redirects.
      authorizationParams: {
        skipRedirectCallback: window.location.pathname === 'oauthAuthorizer',
        audience: AUTH0_CP_WEB_AUDIENCE
      }
    }),
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSidenavModule,
    ReactiveFormsModule,
    SeedUiModule,
    SystemMessageComponent,
    AuthLogoComponent,
    QRCodeModule,
    MatRadioModule,
    HealthIconComponent,
    CreateInstanceAutoscalingDetailsComponent,
    OrgActivityViewComponent,
    NgVarDirective,
    BadgeComponent,
    CpCommonServicesModule
  ],
  providers: [
    CurrencyPipe,
    DataSizePipe,
    DecimalPipe,
    DriftService,
    DurationPipe,
    ApproximateTimePipe,
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler(environment.sentryConfig.errorHandlerOptions) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [PendingUserActionsService], multi: true },
    StateService,
    BillingApiService,
    {
      provide: WEB_AUTH_PROVIDER_TOKEN,
      useFactory: (auth0Service: Auth0Service, httpClient: HttpClient) =>
        isAuth0Mode ? new Auth0WebAuthProvider(auth0Service, httpClient) : new CognitoWebAuthProvider(),
      deps: isAuth0Mode ? [Auth0Service, HttpClient] : []
    },
    {
      provide: RestService,
      useFactory: (httpClient: HttpClient, webAuthProvider: WebAuthProvider, auth0Service: Auth0Service) => {
        return new RestService(httpClient, webAuthProvider, environment.apiUrl, auth0Service);
      },
      deps: [HttpClient, WEB_AUTH_PROVIDER_TOKEN, Auth0Service]
    },
    { provide: INSTANCE_API_SERVICE_TOKEN, useClass: DefaultInstanceApiService },
    { provide: THEME_SERVICE_TOKEN, useClass: ThemeService },
    { provide: WEB_SOCKET_CONSTRUCTOR, useValue: WebSocket },
    {
      provide: WebSocketService,
      useFactory: (accountStateService: AccountStateService, authService: AuthService, webSocketCtor: WebsocketCtor) =>
        new WebSocketService(accountStateService, environment.websocketsEndpoint, authService, webSocketCtor),
      deps: [AccountStateService, AuthService, WEB_SOCKET_CONSTRUCTOR]
    },
    { provide: RECAPTCHA_PROVIDER_TOKEN, useValue: recaptchaProviderFn },
    { provide: HTTP_INTERCEPTORS, useClass: BatchRequestOptimizerInterceptor, multi: true }
  ],
  exports: [GalaxyTrackClickDirective, GalaxyTrackBlurDirective, GalaxyTrackChangeDirective],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly stateService: StateService,
    private readonly adminService: AdminService,
    private readonly authService: AuthService,
    private readonly accountService: AccountService,
    private readonly apiKeyService: ApiKeysService,
    private readonly organizationService: OrganizationService,
    private readonly instanceService: InstanceService,
    private readonly supportService: SupportService,
    private readonly segmentService: SegmentService,
    private readonly instanceBackupService: InstanceBackupService,
    private readonly signInMetadataService: SignInMetadataService,
    private readonly driftService: DriftService,
    private readonly galaxyService: GalaxyService,
    @Inject(THEME_SERVICE_TOKEN) private readonly themeService: ThemeService,
    private injector: Injector
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const isDebugEnabled = isTestRun() || urlParams.has('debug');
    setupLogging({ isDebugEnabled });
    console.debug('AppModule object is created');

    const controlPlane: any = {};
    (window as any)['controlPlane'] = controlPlane;
    controlPlane['authService'] = authService;
    controlPlane['stateService'] = stateService;
    controlPlane['instanceService'] = instanceService;
    controlPlane['supportService'] = supportService;
    controlPlane['signInMetadataService'] = signInMetadataService;
    controlPlane['dump'] = dumpLogCache;
    controlPlane['clearDump'] = clearLogCache;
    controlPlane['injector'] = injector;
    controlPlane['adminService'] = adminService;
    controlPlane['accountService'] = accountService;
    controlPlane['galaxyService'] = galaxyService;

    if (!isAuth0Mode) {
      const cognitoParameter = AUTH0_ENABLED_ENVIRONMENTS.includes(environment.stage) ? '?cognito=1' : '';
      // See https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/ for a complete list of configurations
      const AMPLIFY_CONFIG: AmplifyConfig = {
        Auth: {
          region: environment.region,
          userPoolId: environment.cognitoUserPoolId,
          userPoolWebClientId: environment.cognitoUserPoolWebClientId,
          endpoint: environment.cognitoEndpoint ?? undefined,
          authenticationFlowType: environment.cognitoEndpoint ? 'USER_PASSWORD_AUTH' : undefined,
          oauth: {
            domain: environment.cognitoDomain,
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${environment.webUrl}${cognitoParameter}`,
            redirectSignOut: `${environment.webUrl}/signIn${cognitoParameter}`,
            responseType: 'code'
          }
        }
      };
      Amplify.configure(AMPLIFY_CONFIG);
    }

    if (environment.datadogConfig.rum) {
      datadogRum.init(environment.datadogConfig.rum);
      if (environment.datadogConfig.rum.startSessionReplayRecording) {
        datadogRum.startSessionReplayRecording();
      }
    }
    if (environment.datadogConfig.logs) {
      datadogLogs.init(environment.datadogConfig.logs);
    }
    themeService.initializeIcons();
  }
}

const logCachePipe = createLogCachePipe();

/** Configures overrides for the logging system. */
function setupLogging(mode: { isDebugEnabled: boolean }): void {
  // Add timestamp to every message with a millisecond precision. Use en-GB to avoid dealing with AM/PM.
  const addTimestampPipe: LogPipe = (_, ...args) => [
    `[${new Date().toLocaleTimeString('en-GB')}.${`000${Date.now() % 1000}`.slice(-3)}]`,
    ...args
  ];
  const logLevelFilterPipe = createLogLevelFilterPipe({ excludedLogLevels: mode.isDebugEnabled ? [] : ['debug'] });
  const galaxyLogPipe = createGalaxyLogPipe();
  installConsoleOverrides(addTimestampPipe, logCachePipe, galaxyLogPipe, logLevelFilterPipe);
}

function dumpLogCache(): void {
  const systemConsole = getOriginalConsoleMethods();
  systemConsole.log('%c===== Dump start =====', 'color: green;');
  for (const m of logCachePipe.getMessages()) {
    const levelPrefix = `[${m.level[0].toUpperCase()}]`; // Print 1 char of the log level to keep the log messages aligned.
    systemConsole[m.level](levelPrefix, ...m.args);
  }
  systemConsole.log('%c===== Dump end =====', 'color: green;');
}

function clearLogCache(): void {
  logCachePipe.clearMessages();
}
