import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SupportCaseDetails } from '@cp/common/protocol/Support';
import { HelpFlyOutUiService } from '@cp/web/app/help/help-fly-out/help-fly-out-ui.service';
import { SupportCasesStateService } from '@cp/web/app/support/support-cases-state.service';
import { NEVER, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cp-support-details',
  templateUrl: './support-details.component.html',
  styleUrls: ['./support-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportDetailsComponent {
  descriptionExpanded = false;

  @ViewChild('caseDetailsContainer')
  caseDetailsContainerElement!: ElementRef;
  caseDetailsObs: Observable<SupportCaseDetails | undefined> = this.supportCasesStateService
    .observeCurrentSupportCaseId()
    .pipe(
      switchMap((currentCaseId) => {
        if (!currentCaseId) return NEVER;
        return this.supportCasesStateService.observeSupportCases().pipe(
          map((supportCases) => {
            if (!(supportCases[currentCaseId] as SupportCaseDetails)?.events) {
              return undefined;
            }
            return supportCases[currentCaseId] as SupportCaseDetails;
          })
        );
      })
    );

  constructor(
    public readonly dialogRef: MatDialogRef<SupportDetailsComponent>,
    private readonly supportCasesStateService: SupportCasesStateService,
    private readonly router: Router,
    private readonly helpFlyOutUiService: HelpFlyOutUiService
  ) {}

  scrollToReply() {
    const nativeElement = this.caseDetailsContainerElement.nativeElement;
    nativeElement.scroll({ top: nativeElement.scrollHeight, behavior: 'smooth' });
  }

  getNumberOfLines(description: string) {
    return description.split(/\n/).length;
  }

  async viewFullCaseDetails(caseId: string) {
    this.dialogRef.close();
    await this.helpFlyOutUiService.hideFlyOut();
    await this.router.navigate(['/support', caseId]);
  }
}
