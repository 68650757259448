import { assertTruthy } from '@cp/common/utils/Assert';

export function getErrorMessageFromError(error: unknown): string | undefined {
  if (!error) {
    return undefined;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return undefined;
}

/** Extracts UPPER_CASE_TEXT prefix from the string. */
export function parseErrorTokenFromErrorMessage(errorMessage: string): string {
  if (!errorMessage) return '';
  let errorTokenEndIndex = 0;
  for (; errorTokenEndIndex < errorMessage.length; errorTokenEndIndex++) {
    const c = errorMessage.charAt(errorTokenEndIndex);
    const isAlpha = c >= 'A' && c <= 'Z';
    const isDigit = c >= '0' && c <= '9';
    const isUnderscore = c === '_';
    if (!isAlpha && !isDigit && !isUnderscore) {
      break;
    }
  }
  // Error tokens can't be 1 symbol long (an upper case word).
  return errorTokenEndIndex > 1 ? errorMessage.substring(0, errorTokenEndIndex) : '';
}

/** Returns HTTP status code parsed from the error token in the message. */
export function parseStatusCodeFromErrorMessageToken(message: unknown): number {
  if (typeof message !== 'string') return 500;
  const errorToken = parseErrorTokenFromErrorMessage(message) || INTERNAL_ERROR;
  // If status code can't be found by the token use the following logic:
  // If we have a token: this is an expected error -> return 400 (Bad Request), otherwise return 500: internal error.
  // This way we will encourage to use tokens.
  return getStatusCodeByErrorToken(errorToken) || (errorToken.length ? 400 : 500);
}

/** Map of the statusCodeByErrorToken. Extendable via 'registerStatusCodeByErrorToken'. */
const statusCodeByErrorToken = new Map<string, number>();

/**
 * The server cannot or will not process the request due to something that is perceived to be a client error .
 * Same as "400 Bad Request" HTTP code.
 */
export const BAD_REQUEST = 'BAD_REQUEST';
export const BAD_REQUEST_STATUS = 400;

/** Request misses any authentication information. Same as "401 Unauthorized." HTTP code. */
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const UNAUTHORIZED_STATUS = 401;

/**
 * Request has valid authorization data but that data does allow to run the requested action
 * because of some internal state: permission, billing status, etc.
 * Same as '403 Forbidden' HTTP code.
 */
export const FORBIDDEN = 'FORBIDDEN';
export const FORBIDDEN_STATUS = 403;

/** Requested resource is not found. Same as '404 Not Found'. */
export const NOT_FOUND = 'NOT_FOUND';
export const NOT_FOUND_STATUS = 404;

/** Request conflicts with the current state of the target resource. */
export const CONFLICT = 'CONFLICT';
export const CONFLICT_STATUS = 409;

/** Too many requests. */
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
export const TOO_MANY_REQUESTS_STATUS = 429;

/** Unexpected error. Same as "500 Internal Server Error". */
export const INTERNAL_ERROR = 'INTERNAL_ERROR';
export const INTERNAL_ERROR_STATUS = 500;

/**
 * Special error pair (code + message) used for Auth0 migration.
 * When the server responds with this code, the client must refresh its page (re-instantiate web application).
 * The code is issued when Auth0 mode was forced, but the client still sends Cognito auth token (the client code is old).
 */
export const UNAUTHORIZED_EXPECTED_AUTH0 = 'UNAUTHORIZED_EXPECTED_AUTH0';
export const UNAUTHORIZED_EXPECTED_AUTH0_STATUS = UNAUTHORIZED_STATUS;

registerStatusCodeByErrorToken(BAD_REQUEST, BAD_REQUEST_STATUS);
registerStatusCodeByErrorToken(UNAUTHORIZED, UNAUTHORIZED_STATUS);
registerStatusCodeByErrorToken(FORBIDDEN, FORBIDDEN_STATUS);
registerStatusCodeByErrorToken(NOT_FOUND, NOT_FOUND_STATUS);
registerStatusCodeByErrorToken(CONFLICT, CONFLICT_STATUS);
registerStatusCodeByErrorToken(TOO_MANY_REQUESTS, TOO_MANY_REQUESTS_STATUS);
registerStatusCodeByErrorToken(INTERNAL_ERROR, INTERNAL_ERROR_STATUS);
registerStatusCodeByErrorToken(UNAUTHORIZED_EXPECTED_AUTH0, UNAUTHORIZED_EXPECTED_AUTH0_STATUS);

/** Registers a new error token -> status code mapping. */
export function registerStatusCodeByErrorToken(errorToken: string, statusCode: number): void {
  assertTruthy(
    errorToken.length > 0 && parseErrorTokenFromErrorMessage(errorToken).length === errorToken.length,
    () => `Bad error token format: ${errorToken}`
  );
  const currentStatusCode = statusCodeByErrorToken.get(errorToken);
  assertTruthy(
    !currentStatusCode || currentStatusCode === statusCode,
    () =>
      `Attempt to re-register error token to different status code! Token: ${errorToken}, current code: ${currentStatusCode}, new code: ${statusCode}`
  );
  statusCodeByErrorToken.set(errorToken, statusCode);
}

export function getStatusCodeByErrorToken(errorToken: string): number | undefined {
  return statusCodeByErrorToken.get(errorToken);
}

/**
 * Returns true if the error is a client side error.
 * Such errors are less important for the server alerts.
 */
export function isHttpClientSideError(status: number): boolean {
  return status >= 400 && status < 500;
}
