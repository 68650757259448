import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  AssignTackleSubscriptionAuditPayload,
  ChangeInstanceAutoScalingMemoryAuditPayload,
  ChangeInstanceGptUsageConsentAuditPayload,
  ChangeInstanceNameAuditPayload,
  ChangeInstanceServiceIdlingAuditPayload,
  ChangeUserRoleAuditPayload,
  DeleteInstanceBackupAuditPayload,
  DeleteOrganizationInvitationAuditPayload,
  InviteUsersToOrganizationAuditPayload
} from '@cp/common/protocol/Audit';
import { OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS } from '@cp/common/protocol/OpenapiKey';
import { OrganizationActivity, OrganizationUserNameChangedPayload } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { secondsToString } from '@cp/common/utils/FormatUtils';
import { isEmpty, isIp4Address } from '@cp/common/utils/ValidationUtils';
import { NgVarDirective } from '@cp/cp-common-web/directives';
import { downloadCsv } from '@cp/cp-common-web/DownloadUtils';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

type SortField = 'activity' | 'actor' | 'ipAddress' | 'time' | 'payload';

interface SortFieldAndDirection {
  field: SortField;
  ascending: boolean;
}

interface HeaderField {
  label: string;
  sortField: SortField;
}

interface DisplayOrgActivity {
  id: string;
  activity: string;
  /** User name/email, openapi key suffix. */
  actor: string;
  ipAddress?: string;
  time: Date;
  openapiKeySuffix?: string;
  payload: string;
}

const ALL_HEADERS_FIELDS_ARRAY: Array<HeaderField> = [
  { sortField: 'activity', label: 'Activity' },
  { sortField: 'actor', label: 'User' },
  { sortField: 'payload', label: 'Payload' },
  { sortField: 'ipAddress', label: 'IP Address' },
  { sortField: 'time', label: 'Time' }
];

@Component({
  standalone: true,
  selector: 'cw-org-activity-view',
  templateUrl: './org-activity-view.component.html',
  styleUrls: ['./org-activity-view.component.scss'],
  imports: [CommonModule, FormsModule, MatIconModule, NgVarDirective, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgActivityViewComponent implements OnChanges, OnInit {
  @Input()
  activities!: Array<OrganizationActivity>;

  @Input()
  showPayload = false;

  readonly isValidIP4Address = isIp4Address;
  readonly sortSubject = new BehaviorSubject<SortFieldAndDirection>({
    field: 'time',
    ascending: false
  });
  /** Ordered list of fields in the table. Note: must be synchronized with cells order in the rows in the html file. */
  headerFields!: Array<HeaderField>;
  searchText?: string;
  allActivitiesObs!: Observable<Array<DisplayOrgActivity>>;
  filteredActivitiesObs!: Observable<Array<DisplayOrgActivity>>;
  private readonly searchTextSubject = new BehaviorSubject<string>('');

  constructor() {}

  ngOnInit() {
    assertTruthy(this.activities);
    this.headerFields = this.showPayload
      ? ALL_HEADERS_FIELDS_ARRAY
      : ALL_HEADERS_FIELDS_ARRAY.filter(({ label }) => label !== 'Payload');
  }

  ngOnChanges() {
    assertTruthy(this.activities);

    this.allActivitiesObs = of(this.activities).pipe(
      map((activities) =>
        activities.map<DisplayOrgActivity>((activity) => {
          const openapiKeyInfo = activity.openapiKeySuffix ? formatKeySuffix(activity.openapiKeySuffix) : undefined;
          return {
            id: activity.id,
            activity: this.getDisplayActivityName(activity),
            actor: openapiKeyInfo || activity.email || activity.fullName || '',
            ipAddress: activity.ipAddress || '',
            time: activity.timestamp,
            payload: JSON.stringify(activity.payload)
          };
        })
      )
    );

    this.filteredActivitiesObs = combineLatest([this.allActivitiesObs, this.searchTextSubject, this.sortSubject]).pipe(
      map(([activities, searchText, sortAndDirection]) => {
        return activities
          .filter((activity) => {
            if (isEmpty(searchText)) {
              return true;
            }

            const textToFilter = (searchText as string).toLowerCase().trim();

            return (
              textToFilter
                .split(' ')
                .every(
                  (keyword) =>
                    activity.activity.toLowerCase().trim().includes(keyword) ||
                    activity.actor.toLowerCase().trim().includes(keyword) ||
                    activity.ipAddress?.toLowerCase().trim().includes(keyword)
                ) || this.getDisplayDate(activity.time).toLowerCase().includes(textToFilter)
            );
          })
          .sort((a, b) => {
            const aValue = a[sortAndDirection.field] || '';
            const bValue = b[sortAndDirection.field] || '';
            if (aValue === bValue) {
              return 0;
            }
            if (sortAndDirection.ascending) {
              return aValue > bValue ? 1 : -1;
            } else {
              return bValue > aValue ? 1 : -1;
            }
          });
      })
    );
  }

  searchInputChanged(): void {
    this.searchTextSubject.next(this.searchText || '');
  }

  setSort(field: SortField, currentSort: SortFieldAndDirection): void {
    this.sortSubject.next({ field, ascending: field === currentSort.field ? !currentSort.ascending : true });
  }

  exportCsv(activities: Array<DisplayOrgActivity>) {
    const csvAr: Array<string[]> = [['Id', 'Activity', 'User', 'IP Address', 'Time']];
    for (const activity of activities) {
      csvAr.push([activity.id, activity.activity, activity.actor, activity.ipAddress || '', activity.time + '']);
    }
    downloadCsv(csvAr, 'activity.csv');
  }

  private getDisplayActivityName(activity: OrganizationActivity): string {
    const { type, actorDetails } = activity;
    const instanceNameOrId = activity.instanceName || activity.instanceId;
    switch (type) {
      case 'OPENAPI_KEY_UPDATE': {
        const { type, value } = activity.payload;
        const keysSuffix = formatKeySuffix(activity.entityDetails || 'unknown');
        switch (type) {
          case 'created':
            return `API key created: ${keysSuffix}`;
          case 'deleted':
            return `API key deleted: ${keysSuffix}`;
          case 'name-changed':
            return `API key renamed: ${keysSuffix}`;
          case 'role-changed':
            return `API key ${keysSuffix} role changed to: ${value}`;
          case 'state-changed':
            return `API key ${keysSuffix} state changed to: ${value}`;
          case 'date-changed': {
            const date = new Date(value || 'must always be set');
            const displayValue =
              date.getTime() >= OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS
                ? 'Never'
                : date.toISOString().substring(0, 16).replace('T', ' '); // Trim up to minutes.
            return `API key ${keysSuffix} expiration set to: ${displayValue}`;
          }
        }
        break;
      }
      case 'CREATE_INSTANCE':
        return `Service created - ${instanceNameOrId}`;
      case 'TERMINATING_INSTANCE':
        return `Service terminated - ${instanceNameOrId}`;
      case 'STOP_INSTANCE':
        return `Service stopped - ${instanceNameOrId}`;
      case 'START_INSTANCE':
        return `Service started - ${instanceNameOrId}`;
      case 'CHANGE_INSTANCE_NAME': {
        const pl = activity.payload as ChangeInstanceNameAuditPayload;
        return `Service name changed - ${pl.oldName} -> ${pl.newName}`;
      }
      case 'CHANGE_INSTANCE_GPT_USAGE_CONSENT': {
        const pl = activity.payload as ChangeInstanceGptUsageConsentAuditPayload;
        return `GPT usage consent changed to ${pl.gptUsageConsent} - ${instanceNameOrId}`;
      }
      case 'CHANGE_INSTANCE_IP_ACCESS_LIST':
        return `Service access list changed - ${instanceNameOrId}`;
      case 'CHANGE_INSTANCE_AUTOSCALING_MEMORY': {
        const pl = activity.payload as ChangeInstanceAutoScalingMemoryAuditPayload;
        return `Service scaling settings adjusted - ${pl.minAutoScalingTotalMemory}GB (min) to ${pl.maxAutoScalingTotalMemory}GB (max) - ${instanceNameOrId}`;
      }
      case 'CHANGE_INSTANCE_SERVICE_IDLING': {
        const pl = activity.payload as ChangeInstanceServiceIdlingAuditPayload;
        const enabledIdleScalingString = `enabled, minimum idle time: ${secondsToString(pl.idleTimeoutMinutes * 60)}`;
        return `Service idling ${pl.enableIdleScaling ? enabledIdleScalingString : 'disabled'} - ${instanceNameOrId}`;
      }
      case 'CREATE_ORGANIZATION':
        return `Organization created`;
      case 'DELETE_ORGANIZATION':
        return `Organization deleted`;
      case 'CHANGE_ORGANIZATION_NAME':
        return `Organization name changed - ${(activity.payload as OrganizationUserNameChangedPayload).newName}`;
      case 'CHANGE_USER_ROLE': {
        const pl = activity.payload as ChangeUserRoleAuditPayload;
        return `User role changed - ${pl.changedUserEmail} -> ${pl.newRole}`;
      }
      case 'REMOVE_USER_FROM_ORGANIZATION':
        return `User removed from organization - ${activity.payload.removedUserFullName}`;
      case 'INVITE_USERS_TO_ORGANIZATION': {
        const pl = activity.payload as InviteUsersToOrganizationAuditPayload;
        const emails = pl.invitedEmails.join(', ');
        return `Invited users to organization - ${emails}`;
      }
      case 'JOIN_ORGANIZATION':
        return `User joined organization - ${actorDetails}`;
      case 'ADD_ORGANIZATION_MEMBER':
        return `User joined organization - ${actorDetails}`;
      case 'DELETE_ORGANIZATION_INVITATION': {
        const pl = activity.payload as DeleteOrganizationInvitationAuditPayload;
        return `Organization invitation deleted - ${pl.invitedEmail}`;
      }
      case 'LEAVE_ORGANIZATION':
        return `User left organization - ${actorDetails}`;
      case 'INSTANCE_PASSWORD_RESET':
        return `Service password was reset - ${actorDetails} -> ${instanceNameOrId}`;
      case 'DELETE_INSTANCE_BACKUP': {
        const { backupId } = activity.payload as DeleteInstanceBackupAuditPayload;
        return `Delete backup (${backupId}) of service ${instanceNameOrId}`;
      }
      case 'ASSIGN_TACKLE_SUBSCRIPTION': {
        const { token } = activity.payload as AssignTackleSubscriptionAuditPayload;
        return `Assigned marketplace subscription: ${token.marketplace}/${token.customerId}`;
      }
      default: {
        const name = (type as string).replace(/_/g, ' ').toLowerCase().trim();
        return name.charAt(0).toUpperCase() + name.slice(1);
      }
    }
  }

  getDisplayDate(time: Date): string {
    return `${new Date(time).toUTCString().split('GMT')[0].trim()}(UTC)`;
  }

  trackById(index: number, item: { id: string | number }): string {
    return `${item.id}`;
  }
}

function formatKeySuffix(openapiKeySuffix: string): string {
  return `****${openapiKeySuffix}`;
}
