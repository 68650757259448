<ng-template #global_menu>
  <div class="sidebar_menu" data-cy="sidebar-menu">
    <ng-container *ngFor="let menuButton of menuButtons; trackBy: trackByName">
      <ng-container *ngVar="menuButton.userFeature | userFeatureEnabled | async as isMenuUserFeatureEnabled">
        <ng-container
          *ngVar="menuButton.organizationFeature | organizationFeatureEnabled | async as isMenuOrgFeatureEnabled">
          <div *ngIf="((!menuButton.userFeature && !menuButton.organizationFeature) || isMenuUserFeatureEnabled || isMenuOrgFeatureEnabled)
                      && (!menuButton.minRole || (menuButton.minRole | minRole | async))">
            <div
              [galaxyClick]="menuButton.event"
              class="sidebar_menu_item"
              data-cy="sidebar-menu-item"
              [attr.data-cy-value]="menuButton.name"
              [ngClass]="{active: isMenuButtonActive(menuButton.name)}"
              (click)="clickMenuItem(menuButton); closeMobileMenu()"
            >
              <div class="active_indication"></div>
              <div class="icon_wrapper">
                <mat-icon [svgIcon]="menuButton.icon" class="icon svg_fill"></mat-icon>
              </div>
              <div class="menu_item_name" data-cy="menu-item-name">{{menuButton.name}}</div>
            </div>
            <div class="sub_navs_container" *ngIf="isMenuButtonExpanded(menuButton)">
              <ng-container *ngFor="let subNav of menuButton.subNavs">
                <ng-container *ngVar="subNav.userFeature | userFeatureEnabled | async as isSubNavUserFeatureEnabled">
                  <ng-container
                    *ngVar="subNav.organizationFeature | organizationFeatureEnabled | async as isSubNavOrgFeatureEnabled">
                    <div *ngIf="((!subNav.userFeature && !subNav.organizationFeature) || isSubNavUserFeatureEnabled || isSubNavOrgFeatureEnabled)
                                && (!subNav.minRole || (subNav.minRole | minRole | async))"
                         [ngClass]="{active: isMenuButtonActive(subNav.name)}"
                         [routerLink]="subNav.link"
                         class="sub_nav_item"
                         data-cy="sidebar-sub-nav-item"
                         (click)="closeMobileMenu()"
                    >
                      <div class="menu_item_name sub_nav_name" data-cy="menu-item-name">{{subNav.name}}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<div class="sidebar_container">
  <div
    galaxyClick="leftbar.header.logoClick"
    class="sidebar_logo_container"
    routerLink="/">
    <cw-auth-logo type="short" color="white"></cw-auth-logo>
    <div class="logo_details">
      <div class="clickhouse" [ngClass]="!environmentText ? 'full_height' : ''">ClickHouse</div>
      <div class="environment_indicator" *ngIf="environmentText">{{environmentText}}</div>
    </div>
  </div>

  <ng-template [ngTemplateOutlet]="global_menu"></ng-template>

  <div class="bottom_controls">
    <cp-trial-countdown *ngIf="inTrialObs | async"></cp-trial-countdown>

    <div class="sidebar_feedback_container">
      <button *ngIf="isChatAvailable()"
              galaxyClick="leftbar.footer.feedbackButtonSelect"
              (click)="chatWithSupport()"
              class="chat_with_support_button"
              data-cy="chat-with-support-button"
              mat-flat-button>
        <mat-icon class="icon" svgIcon="chat_icon"></mat-icon>
        Chat with us
      </button>
    </div>
  </div>
</div>

<button (click)="toggleMobileMenu()" class="mobile_menu_button" mat-icon-button>
  <mat-icon class="icon" svgIcon="glyph_menu"></mat-icon>
</button>

<div *ngIf="mobileMenuVisible" [@flyInOut]="mobileMenuVisible" class="mobile_menu">
  <button (click)="toggleMobileMenu()" class="close_menu_button" mat-icon-button>
    <mat-icon class="icon" svgIcon="close_icon"></mat-icon>
  </button>

  <div class="mobile_logo_container" routerLink="/" galaxyClick="leftbar.header.logoClick">
    <div class="mobile_sidebar_logo"></div>
  </div>

  <ng-template [ngTemplateOutlet]="global_menu"></ng-template>
</div>
