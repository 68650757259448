import { Event, EventHint } from '@sentry/types/types/event';

const maxTrackedErrorIdentities = 10;
const reportedErrorIdentities: string[] = [];

/** Unique string associated with the error. 2 errors with the same identity string are considered the same. */
function getErrorIdentity(error: Error): string | undefined {
  return error.stack ? (error.stack.length > 500 ? error.stack.substring(0, 500) : error.stack) : undefined;
}

/** Returns true if the error was already reported. */
function isReportedError(error: Error): boolean {
  const errorIdentity = getErrorIdentity(error);
  return !!errorIdentity && reportedErrorIdentities.some((i) => i === errorIdentity);
}

/** Registers 'error' as a reported error. */
function registerReportedError(error: Error): void {
  const errorIdentity = getErrorIdentity(error);
  if (errorIdentity) {
    reportedErrorIdentities.push(errorIdentity);
    if (reportedErrorIdentities.length > maxTrackedErrorIdentities) {
      reportedErrorIdentities.shift();
    }
  }
}

/**
 * Filters up 'maxTrackedErrorIdentities' recently sent errors from being sent to Sentry more than once.
 * This filtering behaviour is used to avoid a single client sending many 'spam' events that generate alerts in PD.
 */
export function beforeSendSentryDedupFilter(event: Event, hint: EventHint): PromiseLike<Event | null> | Event | null {
  const error = hint.originalException;
  if (error && typeof error === 'object') {
    if (isReportedError(error as Error)) {
      return null;
    }
    registerReportedError(error as Error);
  }
  return event;
}
