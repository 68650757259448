<div class="side_padding">
  <h2>Users</h2>

  <mat-tab-group
    *ngIf="dbUsersEnabled | async"
    (selectedTabChange)="onTabChange($event)"
    [disableRipple]="true"
    [selectedIndex]="getSelectedTabIndex()"
    animationDuration="0ms">
    <mat-tab label="Cloud users">
      <manage-org-users *ngIf="activeTab === 'organization'"></manage-org-users>
    </mat-tab>
    <mat-tab label="Database users" *ngIf="dbUsersEnabled | async">
      <manage-db-users *ngIf="activeTab === 'database'"></manage-db-users>
    </mat-tab>
  </mat-tab-group>
  
  <manage-org-users *ngIf="(dbUsersEnabled | async) === false"></manage-org-users>
</div>

