import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isIpAccessSource } from '@cp/common/utils/ValidationUtils';

/** JSON file format for IP access list. */
export interface AccessListJsonFile {
  addresses: Array<AccessListJsonFileEntry>;
}

/** A single entry in JSON file format for IP access list. */
export interface AccessListJsonFileEntry {
  address: string;
  description: string;
}

/** Returns correctly formatted JSON file content for AccessListJsonFile data. */
export function convertAccessListJsonFileToString(file: AccessListJsonFile): string {
  return JSON.stringify(file, null, 4);
}

/** Angular form validator for IP access list 'source' field. */
export function ipAccessListSourceValidator(control: AbstractControl): ValidationErrors | null {
  return isIpAccessSource(control.value) ? null : { ipAccessListSource: { value: control.value } };
}
