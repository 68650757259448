<div class="container" [formGroup]="form">
  <h4 class="title">Autoscaling Settings</h4>
  <div class="section">
    <h6 class="section_title">
      SERVICE SIZING
      <mat-icon
        svgIcon="info_circle"
        matTooltip="By default, ClickHouse Cloud automatically scales compute capacity to accommodate the needs of your workload. The default behaviour is to scale down your compute instances after a period of inactivity and vertically scale available memory until it reaches the maximum memory limit."
        matTooltipClass="memory_range_tooltip"
        class="info_icon"
      >
      </mat-icon>
    </h6>

    <div class="label">
      Cluster Size
      <mat-icon
        svgIcon="info_circle"
        matTooltip="Controls the number of replicas in your service cluster. Please contact support to increase this setting (production only)"
        matTooltipClass="memory_range_tooltip"
        class="info_icon"
        >
      </mat-icon>
    </div>

    <seed-select
      [disabled]="true"
      [options]="[]"
      label="3 replicas"
      data-cy="cluster-size"
      id="clusterSize"
      class="select control"
      >
    </seed-select>

    <div class="label">Minimum Replica Size</div>

    <seed-select
      [options]="memorySizeOptions"
      [dataCy]="'min-memory-options'"
      [value]="form.controls['minMemory'].value"
      (selectionChange)="minChanged($event.value)"
      class="select control"
      data-cy="min-memory-select"
      formControlName="minMemory"
    ></seed-select>

    <div class="label">Maximum Replica size</div>

    <seed-select
      [options]="memorySizeOptions"
      [dataCy]="'max-memory-options'"
      [value]="form.controls['maxMemory'].value"
      (selectionChange)="maxChanged($event.value)"
      class="select control"
      data-cy="max-memory-select"
      formControlName="maxMemory"
    ></seed-select>
  </div>

  <div class="section" *ngIf="isIdlingShownObs | async">
    <h6 class="section_title">IDLING</h6>

    <div class="idling_note">
      Idling services when inactive saves on costs. Services usually take 20-30 seconds to resume after idling.
      <a href="https://clickhouse.com/docs/en/manage/scaling/" target="_blank">Read more</a>
    </div>

    <div class="label">Minimum idle time</div>
    <seed-select class="select"
                 [options]="idleTimeoutToSelectOptions"
                 [value]="form.controls['idleTimeout'].value"
                 formControlName="idleTimeout"
                 data-cy="idle-timeout-select"
    ></seed-select>
  </div>
</div>
