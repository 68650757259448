import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BAD_REQUEST_WITH_USER_MESSAGE, InstanceEndpoint, InstanceMysqlSettings } from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { makeDoubleSha1HashBrowser, makeSha256Base64Browser } from '@cp/cp-common-web/utils/CryptoUtilsBrowser';
import { removeNewlinesAndBackslashesTransformer } from '@cp/web/app/common/utils/CopyTransformers';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { Observable } from 'rxjs';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';

@Component({
  selector: 'cp-mysql-endpoint-details',
  styleUrls: ['./mysql-endpoint-details.component.scss'],
  templateUrl: './mysql-endpoint-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MysqlEndpointDetailsComponent implements OnInit, OnChanges {
  removeNewlinesAndBackslashesTransformer = removeNewlinesAndBackslashesTransformer;

  @Input()
  instanceId!: string;

  @Input()
  mysqlSettings!: InstanceMysqlSettings;

  @Input()
  instanceEndpoint!: InstanceEndpoint;

  @Input()
  username!: string;

  @Input()
  password?: string;

  readonly myRoleObs: Observable<OrganizationRole>;

  loading = true;
  error: string | null = null;
  enteringPassword: boolean = false;
  mysqlPasswordForm?: FormGroup;
  text!: string;

  constructor(
    private readonly instanceService: InstanceService,
    private readonly cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly organizationStateService: OrganizationStateService
  ) {
    this.myRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
  }

  ngOnInit(): void {
    this.checkRequiredFields();
    this.mysqlPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]]
    });
    this.text = this.getMysqlText();
  }

  ngOnChanges(): void {
    this.checkRequiredFields();
    this.text = this.getMysqlText();
  }

  private getMysqlText(): string {
    return `mysql -h ${this.instanceEndpoint.hostname} -u ${this.mysqlSettings.username} -P 3306 --password`;
  }

  private checkRequiredFields(): void {
    assertTruthy(this.instanceId);
    assertTruthy(this.mysqlSettings);
    assertTruthy(this.instanceEndpoint);
    assertTruthy(this.username);
  }

  private async updateMysqlSettings(instanceId: string, enabled: boolean): Promise<void> {
    await this.instanceService.updateMysqlSettings(instanceId, enabled);
    await this.instanceService.fetchMysqlSettings(instanceId);
  }

  async submitMysqlPassword(): Promise<void> {
    assertTruthy(this.mysqlPasswordForm);
    if (this.mysqlPasswordForm.invalid) {
      this.error = 'Password invalid. Please check the password and try again.';
      return;
    }

    this.error = null;

    try {
      const details = this.mysqlPasswordForm.value;
      const doubleSha1Password = await makeDoubleSha1HashBrowser(details.password);
      const sha256Password = await makeSha256Base64Browser(details.password);
      await this.instanceService.backfillMysqlPassword(this.instanceId, doubleSha1Password, sha256Password);
      this.enteringPassword = false;
      await this.updateMysqlSettings(this.instanceId, true);
    } catch (e) {
      this.mysqlSettings = { ...this.mysqlSettings, enabled: false };
      this.cdr.markForCheck();
      this.handleError(e);
    }
  }

  async toggleMysqlEnabled(event: MatSlideToggleChange): Promise<void> {
    try {
      this.error = null;

      if (!this.mysqlSettings.enabled && !this.mysqlSettings.passwordSet) {
        this.enteringPassword = !this.enteringPassword;
        return;
      } else {
        await this.updateMysqlSettings(this.instanceId, event.checked);
      }
    } catch (e) {
      this.handleError(e);
    }

    this.cdr.markForCheck();
  }

  private handleError(e: unknown) {
    if (e instanceof Error) {
      console.error(e);
      this.error = 'Internal error';
    } else if (e instanceof HttpErrorResponse) {
      if (Math.floor(e.status / 100) !== 5) {
        this.error = this.processErrorMessage(e.error.message);
      } else {
        console.error(e);
        this.error = 'Internal error';
      }
    }
  }

  private processErrorMessage(message: string): string {
    if (message.includes(BAD_REQUEST_WITH_USER_MESSAGE)) {
      return message.replace(new RegExp(`${BAD_REQUEST_WITH_USER_MESSAGE}: `, 'g'), '');
    } else {
      return 'Internal Error';
    }
  }
}
