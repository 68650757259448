<ng-container *ngVar="regionBlockedObs | async as blocked">
  <div class="onboard_in_container auth_page_container" data-cy="onboard-container"
       [ngClass]="{region_page_container: blocked}">
    <div class="container">
      <cw-auth-logo type="full" [color]="blocked ? 'primary-dark' : 'black'"></cw-auth-logo>
      <ng-container *ngIf="blocked">
        <form>
          <cp-region-block-message [showContinueButton]="true"
                                   (continueClick)="onContinueClicked()"></cp-region-block-message>
        </form>
      </ng-container>
      <ng-container *ngIf="!blocked">
        <ng-container *ngIf="stage==='CREATE_INSTANCE'">
          <form (ngSubmit)="onSubmitCreateInstance()"
                *ngIf="createInstanceUiStateObs | async as uiState"
                [formGroup]="createInstanceForm"
                [ngClass]="{create_instance_container: true, expanded: sidebarOpen && createInstanceForm.value['instanceDetails']?.provider !== 'AZURE'}"
                data-cy="create-instance-container"
          >
            <div class="left">
              <div class="intro">
                <div class="header">Nice to meet
                  you, {{accountName | async}}!
                </div>
                <div class="paragraph_medium_regular">
                  Let’s create your first hosted ClickHouse database. This will just take a few moments.
                </div>
              </div>
              <cp-create-instance defaultTier="Development" defaultServiceName="My First Service"
                                  eventPrefix="auth.onboarding1">
              </cp-create-instance>

              <div *ngIf="createInstanceForm.value['instanceDetails']?.provider !== 'AZURE'">
                <div class="configure_links_section">
                  <div class="sidebar_links">
                    <div class="sidebar_link">
                      <a
                        class="sidebar_link"
                        *ngIf="createInstanceForm.value['instanceDetails'].tier === 'Production'"
                        (click)="toggleSidebar('AUTO_SCALING')"
                        data-cy="create-service-advanced-settings"
                      >
                        Configure service sizing
                      </a>
                    </div>

                    <div class="sidebar_link" *ngIf="(cmekEnabledObs | async) && cmekAllowedInRegion">
                      <a
                        class="sidebar_link"
                        *ngIf="createInstanceForm.value['instanceDetails'].tier === 'Production'"
                        (click)="toggleSidebar('CUSTOMER_MANAGED_ENCRYPTION')"
                        data-cy="create-service-customer-managed-encryption-toggle"
                      >
                        Set up encryption key (CMEK)
                      </a>
                    </div>
                  </div>
                </div>

                <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"
                                   class="system_message"></cw-system-message>
                <button
                  galaxyClick="auth.onboarding1.continueButtonSelect"
                  [disabled]="!canSubmitCreateInstance || uiState.createInstanceButtonDisabled" class="button"
                  color="primary" data-cy="create-service-submit-button" mat-flat-button
                  type="submit">
                  <mat-icon *ngIf="uiState.createInstanceButtonDisabled" class="button_loader_icon icon svg_stroke"
                            svgIcon="loader"></mat-icon>
                  Create Service
                </button>
              </div>
            </div>
            <div *ngIf="createInstanceForm.value['instanceDetails']?.provider !== 'AZURE'">
              <cw-create-instance-auto-scaling-details
                *ngIf="autoScalingSettingsOpen && autoScalingLimits && (billingStatusObs | async) as billingStatus"
                class="instance_side_details"
                formControlName="autoScalingDetails"
                [instanceTier]="createInstanceForm.value['instanceDetails'].tier"
                [regionId]="createInstanceForm.value['instanceDetails'].region?.id"
                [billingStatus]="billingStatus"
                [autoScalingLimits]="autoScalingLimits"
              ></cw-create-instance-auto-scaling-details>

              <cp-customer-managed-encryption-key-details
                *ngIf="customerManagedEncryptionOpen"
                formControlName="customerManagedEncryption"
                (changeValid)="setCustomerEncryptionValid($event)"
                >
              </cp-customer-managed-encryption-key-details>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="stage === 'SECURITY'">
          <form class="security_container">
            <ng-container *ngIf="instanceObs | async as instance">
              <div *ngIf="instance.state === 'provisioning'" class="instance_provision_progress">
                <mat-icon class="icon" svgIcon="spinner"></mat-icon>
                <div class="text">Excellent, we’re now creating your first service...</div>
              </div>
              <div class="security_content_container">
                <cp-first-time-set-ip-access-list
                  [instanceId]="instance.id"
                  (ipAccessListChange)="onIpListChange($event)"
                ></cp-first-time-set-ip-access-list>
                <button
                  galaxyClick="auth.onboarding2.continueButtonSelect"
                  (click)="onFinishIpList()"
                  [disabled]="!ipListSelected || isIpListSubmitInProgress"
                  class="continue_button"
                  color="primary"
                  data-cy="continue-button"
                  mat-flat-button
                >
                  <mat-icon *ngIf="isIpListSubmitInProgress" class="button_loader_icon icon svg_stroke"
                            svgIcon="loader"></mat-icon>
                  Continue
                </button>
              </div>
            </ng-container>
          </form>
        </ng-container>
        <ng-container *ngIf="stage === 'SERVICE_DETAILS'">
          <form class="service_details">
            <ng-container *ngIf="instanceObs | async as instance">
              <div class="container">
                <div class="backgrounds">
                  <div class="background-layer1"></div>
                  <div class="background-layer2"></div>
                </div>
                <div class="check_icon_container">
                  <mat-icon class="icon" svgIcon="check_icon"></mat-icon>
                </div>
                <h4>Database user generated!</h4>
                <div class="text paragraph_medium_regular">Make sure to copy your database user password, we’ll only
                  show
                  it
                  to you once.
                </div>
                <cp-username-and-password
                  [password]="(instancePasswordObs | async) || ''"
                  [username]="instance.dbUsername"
                  [onUserNameCopyEvent]="'auth.onboarding3.usernameCopy'"
                  [onPasswordCopyEvent]="'auth.onboarding3.passwordCopy'"
                  [onDownLoadCredentialsEvent]="'auth.onboarding3.credentialDownload'"
                  sideBarColor="#FFB200">
                </cp-username-and-password>

                <button
                  galaxyClick="auth.onboarding3.continueButtonSelect"
                  (click)="finish()"
                  class="finish_button"
                  data-cy="copy-confirm-button"
                  mat-flat-button
                  type="button">I’ve copied my password
                </button>
              </div>
            </ng-container>
          </form>
        </ng-container>
        <div class="progress_container">
          <div [ngClass]="{active: stage==='CREATE_INSTANCE'}" class="progress_dot"></div>
          <div [ngClass]="{active: stage==='SECURITY'}" class="progress_dot"></div>
          <div [ngClass]="{active: stage==='SERVICE_DETAILS'}" class="progress_dot"></div>
        </div>
      </ng-container>
    </div>
  </div>

</ng-container>
