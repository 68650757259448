<div class="metrics_feedback_dialog">
  <ng-container *ngIf="!feedbackSubmitted">
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
    <cp-dialog-title data-cy="feedback-dialog-title" dialogTitle="Request new metrics"></cp-dialog-title>
    <div class="metrics_feedback_prompt">
      Please indicate the metric that you’d like to see included and a brief summary of why you think it would be
      helpful.
    </div>

    <form (ngSubmit)="sendRequestAndClose().then()" [formGroup]="feedbackForm" class="form">
      <div class="seed_form_element">
        <textarea
          galaxyClick="servicePage.metricRequest.metricRequestInputFocus"
          galaxyBlur="servicePage.metricRequest.metricRequestInputBlur"
          class="textarea seed_input"
          cdkAutosizeMaxRows="5"
          cdkAutosizeMinRows="1"
          cdkTextareaAutosize
          data-cy="feedback-textarea"
          formControlName="feedbackText"
          name="feedbackText"
          placeholder="Metric name and description"
        ></textarea>
      </div>

      <div class="buttons">
        <button [disabled]="!feedbackForm.valid"
          galaxyClick="servicePage.metricRequest.metricRequestButtonSelect"
          class="submit_button"
          color="primary"
          data-cy="submit-feedback-button"
          mat-flat-button>
          Request metric
        </button>
        <button [mat-dialog-close]="false"
          galaxyClick="servicePage.metricRequest.closeButtonSelect"
          class="close_button"
          data-cy="close-feedback-dialog-button"
          mat-button>
          Close
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="feedbackSubmitted">
    <cp-dialog-submitted
      title="Thanks so much for your request!"
      data-cy="feedback-submitted-title"
      subtitle="Our team will review your request for a new metric and will reach out if we need more information."
      [dialogRef]="dialogRef">
    </cp-dialog-submitted>
  </ng-container>
</div>
