import { CountryItem, CountryItemContinent, RAW_COUNTRIES, Region } from '@cp/common/protocol/Region';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';

/**
 * Links continents: if continent A (key) has no available region, a linked continent B (value) regions will be used.
 * This mapping relies on the convention that 'North America' always has available regions.
 */
const CONTINENT_TO_CONTINENT_WITH_REGIONS_MAP = new Map<CountryItemContinent, CountryItemContinent>([
  ['Africa', 'Europe'],
  ['Antarctica', 'Oceania'],
  ['Asia', 'Europe'],
  ['Europe', 'North America'],
  ['Oceania', 'Asia'],
  ['South America', 'North America']
]);

/** CountryItem by country code (both lower & upper-case). */
const COUNTRY_BY_CODE = RAW_COUNTRIES.reduce((map, country) => {
  map.set(country.code.toLowerCase(), country);
  map.set(country.code, country);
  return map;
}, new Map<string, CountryItem>());

/**
 * Returns regions from the same continent.
 * The result may be an empty array if 'regions' have no regions from the continent.
 */
export function selectRegionsByContinent(continent: CountryItemContinent, regions: Array<Region>): Array<Region> {
  return regions.filter((region) => truthy(COUNTRY_BY_CODE.get(region.country)).continent === continent);
}

/**
 * Selects regions that belong to the continent from the 'regions' list.
 * If no regions found for the continent: returns regions from CONTINENT_TO_CONTINENT_WITH_REGIONS_MAP[continent] value.
 * The 'regions' list must include at least 1 US region to guarantee the result.
 */
export function selectRegionsByNearestContinent(
  continent: CountryItemContinent,
  regions: Array<Region>
): Array<Region> {
  assertTruthy(
    regions.some((r) => r.country === 'us'),
    () => `Region list must include a US region: ${regions.map((r) => r.id)}`
  );
  let sameContinentRegions: Array<Region> = selectRegionsByContinent(continent, regions);
  while (sameContinentRegions.length === 0) {
    continent = truthy(CONTINENT_TO_CONTINENT_WITH_REGIONS_MAP.get(continent));
    sameContinentRegions = selectRegionsByContinent(continent, regions);
  }
  return sameContinentRegions;
}
