export interface BackRouteData {
  /** Path to back route. */
  backPath: string;
  /** Back route button text. */
  backText: string;
}

export function isBackRouteData(obj: any): obj is BackRouteData {
  return obj?.backPath !== undefined;
}
