import { InstanceMaintenanceWindow } from '@cp/common/protocol/Instance';
import { MILLIS_PER_MINUTE } from '@cp/common/utils/DateTimeUtils';

/**
 * Return true if the maintenance window haven't ended yet,
 * and it already began or about to begin in the next notificationMinutes.
 */
export function isMaintenanceWindowNotificationShown(
  maintenanceWindow: InstanceMaintenanceWindow,
  isMaintenanceInProgress = false
): boolean {
  const now = Date.now();
  const millisBeforeStart = maintenanceWindow.notificationMinutes * MILLIS_PER_MINUTE;
  return (
    (maintenanceWindow.endMaintenanceTime > now || isMaintenanceInProgress) &&
    maintenanceWindow.startMaintenanceTime < now + millisBeforeStart
  );
}
