<mat-dialog-content data-cy="instance-provisioning-dialog" class="instance_provisioning_dialog">
  <div class="dialog_title" data-cy="instance-provisioning-dialog-title">Service being created</div>
  <div class="close_icon_wrapper">
    <cp-dialog-close-button [dialogRef]="dialogRef"
                            [width]="14"
                            [height]="16"
                            [fontSize]="16"
                            [top]="10"
    ></cp-dialog-close-button>
  </div>
  <div class="dialog_content">
    Your service is being created, this should only take a few minutes. Once completed, you’ll be able to begin loading data into this service.
  </div>
  <div class="loading_spinner_block" data-cy="instance-provisioning-spinner">
    <mat-spinner></mat-spinner>
  </div>
  <div class="close_button_wrapper">
    <button (click)="dialogRef.close()" class="close_button dialog_button" data-cy="cancel-button" mat-button type="button">
      Close
    </button>
  </div>
</mat-dialog-content>
