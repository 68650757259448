import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { HelpFlyOutUiState } from '@cp/web/app/help/protocol/HelpFlyOutUiState';
import { firstValueFrom, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpFlyOutUiService extends BaseUiStateService<HelpFlyOutUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'helpFlyOut'], stateService);
  }

  async showFlyOut(): Promise<void> {
    this.setPartialState({ hidden: false });
    await firstValueFrom(timer(0));
    this.setPartialState({ active: true });
  }

  async hideFlyOut(): Promise<void> {
    this.setPartialState({ active: false });
    await firstValueFrom(timer(500));
    this.setPartialState({ hidden: true });
  }
}
