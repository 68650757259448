import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Instance } from '@cp/common/protocol/Instance';

@Component({
  selector: 'cp-instance-side-details',
  templateUrl: './instance-side-details.component.html',
  styleUrls: ['./instance-side-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceSideDetailsComponent {
  @Input()
  instance?: Instance;
}
