import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface NgVarContext<T> {
  ngVar: T;
  $implicit: T;
}

@Directive({
  standalone: true,
  selector: '[ngVar]'
})
export class NgVarDirective<T> {
  /** @internal */
  public static ngVarUseIfTypeGuard: void;
  /**
   * Assert the correct type of the expression bound to the `NgVar` input within the template.
   *
   * The presence of this static field is a signal to the Ivy template type check compiler that
   * when the `NgVar` structural directive renders its template, the type of the expression bound
   * to `NgVar` should be narrowed in some way. For `NgVar`, the binding expression itself is used to
   * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `NgVar`.
   */
  static ngTemplateGuard_ngVar: 'binding';
  private context: NgVarContext<T | null> = { ngVar: null, $implicit: null };
  private hasView: boolean = false;

  // eslint-disable-next-line no-unused-vars
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<NgVarContext<T>>
  ) {}

  @Input()
  set ngVar(value: T) {
    this.context.$implicit = this.context.ngVar = value;
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    }
  }

  /**
   * Asserts the correct type of the context for the template that `NgVar` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgVar` structural directive renders its template with a specific context type.
   */
  static ngTemplateContextGuard<T>(
    dir: NgVarDirective<T>,
    ctx: any
  ): ctx is NgVarContext<Exclude<T, false | 0 | '' | null | undefined>> {
    return true;
  }
}
