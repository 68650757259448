import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'seed-checkbox',
  templateUrl: './seed-checkbox.component.html',
  styleUrls: ['./seed-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SeedCheckboxComponent)
    }
  ]
})
export class SeedCheckboxComponent implements ControlValueAccessor {
  onChange = (ignored: boolean): void => {}; // eslint-disable-line  @typescript-eslint/no-unused-vars
  onTouched = (ignored: boolean): void => {}; // eslint-disable-line  @typescript-eslint/no-unused-vars

  /** Emits the new value of the checkbox on every click. */
  @Output()
  checkedChange = new EventEmitter<boolean>();

  @Input()
  set checked(value: boolean) {
    this.checkedValue = value;
  }
  checkedValue = false;

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: boolean) => void): void {
    this.onTouched = fn;
  }

  writeValue(): void {}

  onClick(): void {
    // Toggle checkbox.
    this.checkedValue = !this.checkedValue;
    this.onChange(this.checkedValue);
    this.checkedChange.emit(this.checkedValue);
  }
}
