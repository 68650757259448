import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { ChangeInstanceNameUiState } from '@cp/web/app/instances/protocol/InstanceStates';

@Injectable({
  providedIn: 'root'
})
export class ChangeInstanceNameDialogUiService extends BaseUiStateService<ChangeInstanceNameUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'changeInstanceName'], stateService);
  }
}
