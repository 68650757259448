import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Instance } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { getInstancePageSettingsTabPath } from '@cp/web/app/app-routing-utils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { Observable, ReplaySubject, switchMap } from 'rxjs';

/**
 * Shows "No IP access set" warning if the instance has no access.
 * See https://github.com/ClickHouse/control-plane/issues/1055.
 */
@Component({
  selector: 'cp-instance-no-access-prompt',
  templateUrl: './instance-no-access-prompt.component.html',
  styleUrls: ['./instance-no-access-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceNoAccessPromptComponent implements OnInit, OnChanges {
  @Input() instanceId!: string;

  readonly getInstancePageSecurityTabPath = getInstancePageSettingsTabPath;

  readonly instanceObs: Observable<Instance | undefined>;

  private readonly instanceIdObs = new ReplaySubject<string>(1);

  constructor(instanceStateService: InstanceStateService) {
    this.instanceObs = this.instanceIdObs.pipe(
      switchMap((instanceId) => instanceStateService.observeInstance(instanceId))
    );
  }

  ngOnInit(): void {
    assertTruthy(this.instanceId, 'instanceId is required');
  }

  ngOnChanges(): void {
    assertTruthy(this.instanceId, 'instanceId is required');
    this.instanceIdObs.next(this.instanceId);
  }

  /** Return true if instance has no allowed IPs. */
  checkHasNoIpAccess(instance: Instance): boolean {
    return instance.ipAccessList.length === 0;
  }
}
