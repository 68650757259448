import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { assertTruthy } from '@cp/common/utils/Assert';
import { getInitials } from '@cp/common/utils/MiscUtils';

@Component({
  selector: 'cp-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('avatar')
  avatarElement!: ElementRef;

  @Input()
  name!: string;

  @Input()
  template: 'GREY' | 'ORANGE' | 'WHITE' = 'GREY';

  @Input()
  fixedFontSize?: number;

  fontSize?: number;

  /** Cached user initials. Parsed from 'name' input. */
  initials!: string;

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    assertTruthy(this.name);
    this.updateInitials();
  }

  ngOnChanges(): void {
    assertTruthy(this.name);
    this.updateInitials();
  }

  ngAfterViewInit(): void {
    this.setFontSize();
  }

  // TODO: replace with ResizeObserver.
  @HostListener('window:resize')
  onResize(): void {
    this.setFontSize();
  }

  private updateInitials(): void {
    this.initials = getInitials(this.name);
  }

  private setFontSize(): void {
    if (this.fixedFontSize) {
      this.fontSize = this.fixedFontSize;
    } else {
      const currentWidth = this.avatarElement.nativeElement.offsetWidth;
      this.fontSize = currentWidth > 0 ? Math.floor(currentWidth / 2) : undefined;
    }
    this.cd.detectChanges();
  }
}
