/** Browser level, Platform, raw-DOM utils. */
import UAParser from 'ua-parser-js';

export type BrowserPlatform = 'linux' | 'mac' | 'windows' | 'unknown';

/** Returns platform of the active browser. */
export function getBrowserPlatform(): BrowserPlatform {
  // See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform.
  if (!navigator?.platform) return 'unknown';
  if (navigator.platform.includes('Linux')) return 'linux';
  if (navigator.platform.includes('Mac')) return 'mac';
  if (navigator.platform.includes('Win')) return 'windows';
  return 'unknown';
}

/**
 * Extracts information about the user's device using the UAParser library.
 * @param userAgent The user agent string to parse.
 * @returns An UAParserInstance object containing information about the user's device.
 */
export function getDeviceInfo(userAgent: string): UAParser.UAParserInstance {
  return new UAParser(userAgent);
}

/**
 * Extracts information about the user's browser using the UAParser library.
 * @param userAgent The user agent string to parse.
 * @returns An IBrowser object containing information about the user's browser.
 */
export function getDeviceBrowser(userAgent: string): UAParser.IBrowser {
  return getDeviceInfo(userAgent).getBrowser();
}

/**
 * Extracts information about the user's operating system using the UAParser library.
 * @param userAgent The user agent string to parse.
 * @returns An IOS object containing information about the user's operating system.
 */
export function getDeviceOs(userAgent: string): UAParser.IOS {
  return getDeviceInfo(userAgent).getOS();
}

let globalCounter = 0;

/**
 * Returns global counter that can be used to create unique ids across components.
 */
export function getNextCounterValue(): number {
  return globalCounter++;
}
