import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isEmpty } from '@cp/common/utils/ValidationUtils';
import { AccountService } from '@cp/web/app/account/account.service';
import { RequestIntegrationDialogUiState } from '@cp/web/app/marketplace/protocol/MarketplaceStates';
import { RequestIntegrationDialogUiService } from '@cp/web/app/marketplace/request-integration-dialog/request-integration-dialog-ui.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-request-integration-dialog',
  templateUrl: './request-integration-dialog.component.html',
  styleUrls: ['./request-integration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestIntegrationDialogComponent {
  requestText?: string;
  readonly requestIntegrationDialogUiStateObs: Observable<RequestIntegrationDialogUiState>;

  constructor(
    public readonly dialogRef: MatDialogRef<RequestIntegrationDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly accountService: AccountService,
    private readonly cdr: ChangeDetectorRef,
    private readonly integrationDialogUiService: RequestIntegrationDialogUiService,
    @Inject(MAT_DIALOG_DATA) readonly data: RequestIntegrationDialogInput
  ) {
    this.requestText = data.integrationName;
    this.requestIntegrationDialogUiStateObs = integrationDialogUiService.observeState();
    integrationDialogUiService.setPartialState({
      sendIntegrationRequestButtonDisabled: false,
      requestSubmitted: false
    });
  }

  isSubmitButtonDisabled(): boolean {
    return isEmpty(this.requestText);
  }

  async submitRequest(): Promise<void> {
    assertTruthy(this.requestText, `Can't submit an empty integration request.`);
    this.integrationDialogUiService.setStateKey('sendIntegrationRequestButtonDisabled', true);
    try {
      await this.accountService.sendRequestIntegration(this.requestText);
      this.integrationDialogUiService.setStateKey('requestSubmitted', true);
    } catch (unused) {
      this.snackBar.open('There was a problem submitting your integration request, please try again later', 'Dismiss', {
        duration: 5000
      });
    } finally {
      this.integrationDialogUiService.setStateKey('sendIntegrationRequestButtonDisabled', false);
    }
    this.cdr.markForCheck();
  }
}

export interface RequestIntegrationDialogInput {
  integrationName?: string;
}
