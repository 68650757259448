<mat-dialog-content data-cy="disable-mfa-dialog">
  <cp-dialog-close-button
    class="disable_mfa_dialog_close_button"
    [dialogRef]="dialogRef"
    [width]="14"
    [height]="16"
    [fontSize]="16"
    [top]="24"
  ></cp-dialog-close-button>
  <cp-disable-mfa-confirmation-dialog-content
    (cancel)="onCancel()"
    (primaryAction)="onPrimaryAction()"
    [isLoading]="isLoading"
    [errorMessage]="errorMessage">
  </cp-disable-mfa-confirmation-dialog-content>
</mat-dialog-content>
