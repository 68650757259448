import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { USER_NOT_FOUND } from '@cp/common/protocol/Account';
import { Address, OrganizationDetails } from '@cp/common/protocol/Billing';
import {
  CompanySize,
  ORG_WITH_SAME_NAME_ALREADY_EXISTS,
  ORGANIZATION_LIMIT_REACHED
} from '@cp/common/protocol/Organization';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { AccountService } from '@cp/web/app/account/account.service';
import { AdminBillingService } from '@cp/web/app/admin/admin-billing/admin-billing.service';
import { PendingUserActionsService } from '@cp/web/app/common/services/pending-user-actions.service';
import { TackleSubscriptionService } from '@cp/web/app/common/services/tackle-subscription.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import * as Sentry from '@sentry/angular';

export type DialogStage = 'ADDRESS' | 'COMPANY' | 'CREDIT_CARD';

export interface BillingConversionUiState {
  buttonDisabled: boolean;
  errorMessage?: string;
  addressFormInput?: AddressChange;
  companyDetailsFormInput?: CompanyDetailsChange;
  organizationIsABusiness: boolean;
}

export interface AddressChange {
  billingAddress: Address;
  bothAddressesSame: boolean;
  shippingAddress?: Address;
}

export interface CompanyDetailsChange {
  companyName: string;
  taxId?: string;
  websiteUrl: string;
  companySize: CompanySize;
}

@Component({
  selector: 'cp-tackle-firmographics',
  templateUrl: './tackle-firmographics.component.html',
  styleUrls: ['./tackle-firmographics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TackleFirmographicsComponent {
  dialogStage: DialogStage = 'ADDRESS';
  uiState: BillingConversionUiState = {
    buttonDisabled: false,
    organizationIsABusiness: true
  };

  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly adminBillingService: AdminBillingService,
    private readonly tackleSubscriptionService: TackleSubscriptionService,
    private readonly pendingActionsService: PendingUserActionsService,
    private readonly accountService: AccountService,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {}

  async onAddressChange($event: AddressChange): Promise<void> {
    // Save the new address in the local state
    this.uiState.addressFormInput = $event;
    this.uiState.buttonDisabled = true;
    this.uiState.errorMessage = undefined;

    const organizationIsABusiness = this.uiState.organizationIsABusiness;
    try {
      if (organizationIsABusiness) {
        // If this is a business, continue to the next page to complete the business details
        this.dialogStage = 'COMPANY';
      } else {
        // If this is an individual, save the address and complete the firmographics registration.
        await this.finishTackleRegistration();
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      const serverErrorMessage = getServerErrorMessage(e);
      await this.handleTackleSubscriptionError(this.uiState, serverErrorMessage, e);
    } finally {
      this.uiState = { ...this.uiState, buttonDisabled: false };
      this.cdr.markForCheck();
    }
  }

  async onCompanyDetailsChange($event: CompanyDetailsChange): Promise<void> {
    try {
      // Save the new company details in the local state
      this.uiState.companyDetailsFormInput = $event;
      this.uiState.buttonDisabled = true;
      this.uiState.errorMessage = undefined;

      await this.finishTackleRegistration($event);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      const serverErrorMessage = getServerErrorMessage(e);
      await this.handleTackleSubscriptionError(this.uiState, serverErrorMessage, e);
    } finally {
      this.uiState = { ...this.uiState, buttonDisabled: false };
      this.cdr.markForCheck();
    }
  }

  onCompanyDetailsBack($event: CompanyDetailsChange): void {
    this.uiState.companyDetailsFormInput = $event;
    this.dialogStage = 'ADDRESS';
    this.uiState.buttonDisabled = false;
    this.uiState.errorMessage = undefined;
  }

  enableOrDisableOrgAsBusiness(organizationIsABusiness: boolean) {
    this.uiState.organizationIsABusiness = organizationIsABusiness;
  }

  async finishTackleRegistration(companyDetails?: CompanyDetailsChange): Promise<void> {
    const action = this.pendingActionsService.getPendingAction('tackle-subscription');
    assertTruthy(action, `Can't find tackle-subscription pending action.`);
    assertTruthy(this.uiState.addressFormInput);

    const organizationDetails: OrganizationDetails = {
      billingAddress: this.uiState.addressFormInput.billingAddress,
      organizationIsABusiness: this.uiState.organizationIsABusiness,
      shippingAddress: this.uiState.addressFormInput.shippingAddress,
      companyName: companyDetails?.companyName,
      taxId: companyDetails?.taxId,
      websiteUrl: companyDetails?.websiteUrl,
      companySize: companyDetails?.companySize
    };
    const organizationId = truthy(
      await this.tackleSubscriptionService.handleTackleSubscriptionPendingAction(action, organizationDetails)
    );

    if (organizationId !== this.organizationStateService.getCurrentOrgIdOrFail()) {
      this.organizationStateService.switchOrganization(organizationId);
    }
    this.adminBillingService.updateAdminBillingServiceState(organizationDetails);
    this.snackBar.open('Address information saved', 'Dismiss', { duration: 5000 });

    if (this.pendingActionsService.getPendingAction('onboarding')) {
      this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['onboarding'] }).then();
      this.router.navigateByUrl('/onboard').then();
      return;
    }
    this.router.navigateByUrl('/').then();
  }

  async handleTackleSubscriptionError(
    uiState: BillingConversionUiState,
    serverErrorMessage: string | undefined,
    e: unknown
  ): Promise<void> {
    switch (serverErrorMessage) {
      case 'INVALID_PRODUCT_ID':
        await this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['tackle-subscription'] });
        this.snackBar.open('Invalid product. Please restart the flow on the marketplace', 'Dismiss', {
          duration: 5000
        });
        this.router.navigateByUrl('/').then();
        return;
      case 'INVALID_NAME':
        uiState.errorMessage = 'User name does not adhere to requirements. Try removing special characters';
        return;
      case ORGANIZATION_LIMIT_REACHED:
      case USER_NOT_FOUND:
      case ORG_WITH_SAME_NAME_ALREADY_EXISTS:
        uiState.errorMessage = 'An error has occurred. Please try again or contact support';
        return;
      default:
        // These error messages arrive in the form of `SUBSCRIPTION_TOKEN_IS_ALREADY_IN_USE: ${orgWithTheSameSubscription.id}`
        if (serverErrorMessage?.startsWith('SUBSCRIPTION_TOKEN_IS_ALREADY_IN_USE')) {
          await this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['tackle-subscription'] });
          this.snackBar.open(
            'This AWS account is already associated to another organization. Please restart the flow on the marketplace or contact support',
            'Dismiss',
            { duration: 5000 }
          );
          this.router.navigateByUrl('/').then();
          return;
        }
        if (serverErrorMessage?.startsWith('INVALID_PRODUCT_ID')) {
          await this.accountService.updateUserDetails({ pendingActionTypesToRemove: ['tackle-subscription'] });
          this.snackBar.open('Invalid product. Please restart the flow on the marketplace', 'Dismiss', {
            duration: 5000
          });
          this.router.navigateByUrl('/').then();
          return;
        }
        handleUpdateOrganizationBillingDetailsError(uiState, 'Organization registration failed', serverErrorMessage, e);
    }
  }
}

export function handleUpdateOrganizationBillingDetailsError(
  uiState: BillingConversionUiState,
  defaultErrorMessage: string,
  serverErrorMessage: string | undefined,
  e: unknown
): void {
  switch (serverErrorMessage) {
    case 'INVALID_ADDRESS':
    case 'BILLING_ADDRESS_IS_REQUIRED':
      uiState.errorMessage = 'An address is required';
      return;
    case 'INVALID_COMPANY_NAME':
      uiState.errorMessage = 'Company name is required';
      return;
    case 'INVALID_WEBSITE_URL':
      uiState.errorMessage = 'Website URL is required';
      return;
    case 'INVALID_COMPANY_SIZE':
      uiState.errorMessage = 'Company size is required';
      return;
    case 'ORGANIZATION_IS_A_BUSINESS_IS_REQUIRED':
      uiState.errorMessage = 'Please specify if this is a business or individual';
      return;
    default:
      console.error(defaultErrorMessage, e);
      uiState.errorMessage = defaultErrorMessage;
  }
}
