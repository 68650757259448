import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { isTruthy } from '@cp/common/protocol/Common';
import { isOrganizationFeatureId, isUserFeatureId } from '@cp/common/protocol/features';
import { FeatureId } from '@cp/common/protocol/features/Features';
import { validateOrganizationRole } from '@cp/common/utils/ValidationUtils';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { combineLatest, Observable, of, take, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageAccessGuard implements CanActivate {
  constructor(
    private readonly featuresService: FeaturesService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | UrlTree {
    const data = route.data;
    if (!data) return this.router.createUrlTree(['/']);

    const obsArray: Array<Observable<boolean>> = [];

    let features = data['feature'];
    if (features) {
      features = Array.isArray(features) ? features : [features];
      // Allow access if any of the features is present.
      const featureObsArray: Array<Observable<boolean>> = features.map((featureId: FeatureId) =>
        (isUserFeatureId(featureId)
          ? this.featuresService.observeUserFeature(featureId)
          : isOrganizationFeatureId(featureId)
          ? this.featuresService.observeCurrentOrganizationFeature(featureId)
          : throwError(() => `Invalid feature: ${featureId}`)
        ).pipe(take(1))
      );
      obsArray.push(combineLatest(featureObsArray).pipe(map((flags) => flags.some(isTruthy))));
    } else {
      obsArray.push(of(true));
    }

    if (data['minRole']) {
      const minRole = validateOrganizationRole(data['minRole']);
      obsArray.push(
        this.organizationStateService.observeCurrentOrganizationRole().pipe(
          take(1),
          map((orgRole) => {
            return orgRole === minRole;
          })
        ) as Observable<boolean>
      );
    } else {
      obsArray.push(of(true));
    }

    return combineLatest(obsArray).pipe(
      map(([userFeatureEnabled, minRoleCorrect]) => {
        if (userFeatureEnabled && minRoleCorrect) return true;
        return this.router.createUrlTree([data['redirectUrl'] ?? '/']);
      })
    );
  }
}
