import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Instance } from '@cp/common/protocol/Instance';
import { Integration, IntegrationCategory } from '@cp/common/protocol/Integration';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { getConsoleUrl } from '@cp/web/app/common/utils/WebConsoleUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { IntegrationsService } from '@cp/web/app/marketplace/integrations.service';
import { from, map, Observable } from 'rxjs';

const INTEGRATION_NAMES_TO_SHOW: string[] = ['DataGrip', 'DBeaver'];

@Component({
  selector: 'cp-sql-client-dialog',
  templateUrl: './sql-client-dialog.component.html',
  styleUrls: ['./sql-client-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SqlClientDialogComponent {
  readonly instanceObs: Observable<Instance | undefined>;
  readonly sqlIntegrationsObs: Observable<Array<Integration>>;
  readonly disabledButtonTooltip = `ClickHouse Play UI will be available when your
Service has been successfully created`;

  constructor(
    public dialogRef: MatDialogRef<SqlClientDialogComponent>,
    private readonly instanceStateService: InstanceStateService,
    private readonly segmentService: SegmentService,
    integrationService: IntegrationsService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) private readonly data: { instanceId: string },
    private readonly featuresService: FeaturesService,
    private readonly cdr: ChangeDetectorRef
  ) {
    const { instanceId } = data;
    this.instanceObs = this.instanceStateService.observeInstance(instanceId);
    this.sqlIntegrationsObs = from(integrationService.loadIntegrations()).pipe(
      map((integrations) =>
        integrations.filter(
          (integration) => integration.category === 'SQL_CLIENT' && INTEGRATION_NAMES_TO_SHOW.includes(integration.name)
        )
      )
    );
  }

  getWebConsoleUri(instance: Instance): string {
    return getConsoleUrl(instance);
  }

  reportSqlConsoleClicked(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'sql console',
      category: 'cloud ui service',
      component: 'modal',
      view: 'servicePage'
    });
  }

  goToSqlClients(): void {
    const categoryFilter: IntegrationCategory = 'SQL_CLIENT';
    this.router.navigate(['integrations'], { state: { categoryFilter } }).then();
    this.dialogRef.close();
  }
}
