<div class="summary_container">
  <div [ngSwitch]="type">
    <div *ngSwitchCase="'S3_STORAGE_USAGE'" class="summary_s3_storage_usage" data-cy="summary-S3_STORAGE_USAGE">
      <ng-container *ngVar="s3StorageUsageReportSummary as summary">
        <div class="summary_title">Data stored</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value" data-cy="current-usage-in-bytes">
            {{summary.currentUsageInBytes | dataSize}}
          </div>
          <div class="summary_separator_line"></div>
          <div class="summary_details">
            <div class="summary_details_line">{{s3StorageUsageReportSummaryDeltaText}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'ALLOCATED_MEMORY'" class="summary_allocated_memory" data-cy="summary-ALLOCATED_MEMORY">
      <ng-container *ngVar="allocatedMemoryUsageReportSummary as summary">
        <div class="summary_title">Memory allocation</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value" data-cy="allocated-memory-in-bytes">
            {{summary.currentMemoryInBytes | dataSize: 'binary-bytes-letter'}}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'RESIDENT_MEMORY_USAGE'" class="summary_memory_usage" data-cy="summary-MEMORY_USAGE">
      <ng-container *ngVar="residentMemoryUsageReportSummary as summary">
        <div class="summary_title">Memory usage</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value" data-cy="current-usage-in-bytes">
            {{summary.currentUsageInBytes | dataSize: 'binary-bytes-letter'}}
          </div>
          <div class="summary_separator_line"></div>
          <div class="summary_details">
            <div class="summary_details_line">{{memoryUsageReportSummaryDeltaText}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'SUCCESSFUL_QUERIES'" class="summary_successful_queries" data-cy="summary-SUCCESSFUL_QUERIES">
      <ng-container *ngVar="successfulQueriesReportSummary as summary">
        <div class="summary_title">Successful queries</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">
            <div class="summary_dot summary_success_dot"></div>
            {{summary.successRatePercent|number:"1.0-0"}}%
          </div>
          <div class="summary-spacer-20"></div>
        </ng-container>
        <div class="summary_title">Failed queries</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">
            <div class="summary_dot summary_failed_dot"></div>
            {{summary.failRatePercent|number:"1.0-0"}}%
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'READ_THROUGHPUT'" class="summary_read_size" data-cy="summary-READ_THROUGHPUT">
      <ng-container *ngVar="readThroughputMetricReportSummary as summary">
        <div class="summary_title">Total read size</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">{{summary.totalBytesRead|dataSize}}</div>
          <div class="summary_separator_line"></div>
          <div class="summary_details">
            Average read size per second: {{summary.bytesReadPerSecond|dataSize}}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'ROW_COUNT'" class="summary_row_count" data-cy="summary-ROW_COUNT">
      <ng-container *ngVar="rowCountMetricReportSummary as summary">
        <div class="summary_title">Selected rows</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">
            <div class="summary_dot summary_selects_dot"></div>
            {{summary.selectedRows|dataSize:'short-scale-letter':0}}
          </div>
          <div class="summary-spacer-20"></div>
        </ng-container>
        <div class="summary_title">Inserted rows</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">
            <div class="summary_dot summary_inserts_dot"></div>
            {{summary.insertedRows|dataSize:'short-scale-letter':0}}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'SELECT_QPS'" class="summary_select_qps" data-cy="summary-SELECT_QPS">
      <ng-container *ngVar="selectQpsMetricReportSummary as summary">
        <div class="summary_title">Total selects</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">{{summary.totalSelects|dataSize:'short-scale-letter':0}}</div>
          <div class="summary_separator_line"></div>
          <div class="summary_details">
            Average selects per second: {{summary.selectQueriesPerSecond|dataSize:'short-scale-letter'}}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'INSERT_QPS'" class="summary_insert_qps" data-cy="summary-INSERT_QPS">
      <ng-container *ngVar="insertQpsMetricReportSummary as summary">
        <div class="summary_title">Total inserts</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">{{summary.totalInserts|dataSize:'short-scale-letter':0}}</div>
          <div class="summary_separator_line"></div>
          <div class="summary_details">
            Average inserts per second: {{summary.insertQueriesPerSecond|dataSize:'short-scale-letter'}}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'WRITE_THROUGHPUT'" class="summary_write_size" data-cy="summary-WRITE_THROUGHPUT">
      <ng-container *ngVar="writeThroughputMetricReportSummary as summary">
        <div class="summary_title">Total write size</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">{{summary.totalBytesWritten|dataSize}}</div>
          <div class="summary_separator_line"></div>
          <div class="summary_details">
            Average write size per second: {{summary.bytesWrittenPerSecond|dataSize}}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="'SQL_STATEMENTS_PER_TYPE'" class="summary_sql_statements_per_type"
         data-cy="summary-SQL_STATEMENTS_PER_TYPE">
      <ng-container *ngVar="sqlStatementsPerTypeMetricReportSummary as summary">
        <div class="summary_title">Total selects</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">
            <div class="summary_dot summary_selects_dot"></div>
            {{summary.selectsPercentPerPeriod|number:"1.0-0"}}%
          </div>
          <div class="summary-spacer-20"></div>
        </ng-container>
        <div class="summary_title">Total inserts</div>
        <div *ngIf="isError" class="summary_value summary_na_value">{{naText}}</div>
        <div *ngIf="isLoading" class="summary_value summary_loading_text">{{loadingText}}</div>
        <ng-container *ngIf="summary">
          <div class="summary_value">
            <div class="summary_dot summary_inserts_dot"></div>
            {{summary.insertsPercentPerPeriod|number:"1.0-0"}}%
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngSwitchDefault class="summary_not_implemented">{{naText}}</div>

  </div>
</div>
