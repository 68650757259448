<div class="seed_form_element">
  <div class="terms_wrapper">
    <label
      class="horizontal_label">
      <ng-container [ngTemplateOutlet]="checkboxInput"></ng-container>
      <span>
                I understand and agree to the
                <a
                  galaxyClick="auth.register.tosLinkSelect"
                  class="link"
                  href="https://clickhouse.com/legal/agreements/terms-of-service"
                  target="_blank">Terms of Service</a>
                and
                <a
                  galaxyClick="auth.register.privacyLinkSelect"
                  class="link" href="https://clickhouse.com/legal/privacy-policy" target="_blank">Privacy Policy</a>.
              </span>
    </label>
  </div>
</div>

<ng-template #checkboxInput>
  <div *ngIf="formGroup; else noFormGroup" [formGroup]="formGroup">
    <input formControlName="terms" name="terms" galaxyClick="auth.register.tosAgreeCheck" class="checkbox"
           data-cy="terms-input"
           type="checkbox">
  </div>
  <ng-template #noFormGroup>
    <input galaxyClick="auth.register.tosAgreeCheck" class="checkbox"
           data-cy="terms-input"
           type="checkbox" [checked]="hasAcceptedTos" (change)="onToggle()">
  </ng-template>
</ng-template>
