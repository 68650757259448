import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IpAccessListEntry, MAX_IP_ACCESS_LIST_DESCRIPTION_LENGTH } from '@cp/common/protocol/Instance';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { ipAccessListSourceValidator } from '@cp/web/app/instances/ip-access-list/ip-access-list-common';

@Component({
  templateUrl: './edit-ip-access-list-entry-dialog.component.html',
  styleUrls: ['./edit-ip-access-list-entry-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditIpAccessListEntryDialogComponent {
  editEntryFormGroup: FormGroup;

  isSubmitInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: EditIpAccessListEntryDialogInput,
    readonly dialogRef: MatDialogRef<unknown>,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder
  ) {
    this.editEntryFormGroup = this.formBuilder.group({
      value: [this.data.entry.source, [Validators.required, ipAccessListSourceValidator]],
      description: [this.data.entry.description, Validators.maxLength(MAX_IP_ACCESS_LIST_DESCRIPTION_LENGTH)]
    });
  }

  hasChanges(): boolean {
    return (
      this.editEntryFormGroup.controls['value'].value !== this.data.entry.source ||
      this.editEntryFormGroup.controls['description'].value !== this.data.entry.description
    );
  }

  /** Stores the IP access list entries and closes the dialog. */
  async save(): Promise<void> {
    try {
      this.isSubmitInProgress = true;
      const oldSource = this.data.entry.source;
      const newSource = this.editEntryFormGroup.controls['value'].value;
      const description = this.editEntryFormGroup.controls['description'].value;
      const { ipAccessList } = this.instanceStateService.getInstanceOrFail(this.data.instanceId);
      const newIpAccessList =
        newSource === oldSource
          ? ipAccessList.map<IpAccessListEntry>((e) =>
              e.source === oldSource ? { source: oldSource, description } : e
            )
          : [
              ...ipAccessList.filter((e) => e.source !== oldSource && e.source !== newSource),
              {
                source: newSource,
                description
              }
            ];
      await this.instanceService.updateIpAccessList(this.data.instanceId, newIpAccessList);
      this.snackBar.open('Your IP address has been updated', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      this.snackBar.open('Failed to update access list', 'Dismiss', { duration: 5000 });
    } finally {
      this.isSubmitInProgress = false;
    }
  }
}

export function getEditIpAccessListEntryDialogConfig(): Partial<MatDialogConfig<EditIpAccessListEntryDialogInput>> {
  return {
    width: '100%',
    maxWidth: '517px',
    autoFocus: true,
    restoreFocus: false,
    panelClass: 'modal'
  };
}

export interface EditIpAccessListEntryDialogInput {
  organizationId: string;
  instanceId: string;
  entry: IpAccessListEntry;
}
