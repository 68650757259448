import { truthy } from '@cp/common/utils/Assert';
import { BaseAction, extractAllPathActions } from '@cp/common-services/state/actions';

export class PathTrie<T> {
  private root = new PathTrieNode<T>();

  getOrCreatePathTrieNode(path: string[], nodeData?: T): PathTrieNode<T> {
    let currentNode = this.root;
    for (const key of path) {
      if (!currentNode.children.get(key)) {
        currentNode.children.set(key, new PathTrieNode<T>());
      }
      currentNode = truthy(currentNode.children.get(key));
    }
    if (nodeData !== undefined) {
      currentNode.nodeData = nodeData;
    }
    return currentNode;
  }

  getPathTrieNode(path: string[]): PathTrieNode<T> | undefined {
    let currentNode: PathTrieNode<T> | undefined = this.root;
    for (const key of path) {
      if (!currentNode) return undefined;
      currentNode = currentNode.children.get(key);
    }
    return currentNode;
  }

  getPathTrie(path: string[]): PathTrie<T> | undefined {
    let currentNode: PathTrieNode<T> | undefined = this.root;
    for (const key of path) {
      if (!currentNode) return undefined;
      currentNode = currentNode.children.get(key);
    }
    if (currentNode) {
      const newTrie = new PathTrie<T>();
      newTrie.root = currentNode;
      return newTrie;
    }
    return undefined;
  }

  /**
   * If the callback returns false, it will not iterate into the children of the current node.
   */
  iterateBfs(callback: (data: T, path: string[]) => void | boolean): void {
    this.iterateInternal(this.root, callback as (data: T | undefined, path: string[]) => void, []);
  }

  removeNode(path: string[]): void {
    if (!path.length) {
      this.root = new PathTrieNode<T>();
    }
    const ancestors: Array<PathTrieNode<T>> = [this.root];
    for (const key of path) {
      const ancestor = ancestors[ancestors.length - 1].children.get(key);
      if (!ancestor) {
        throw new Error('Node does not exist');
      }
      ancestors.push(ancestor);
    }
    for (let i = ancestors.length - 1; i > 0; i--) {
      const itemToRemove = ancestors[i];
      const itemKey = path[i - 1];
      // If the parent of the item that we want to remove is empty - delete it.
      if ((itemToRemove.nodeData || itemToRemove.children.size) && i !== ancestors.length - 1) {
        break;
      }
      ancestors[i - 1].children.delete(itemKey);
    }
  }

  clearNodeData(path: string[]): void {
    const node = this.getPathTrieNode(path);
    if (!node) return;
    node.nodeData = undefined;
    if (!node.children.size) {
      this.removeNode(path);
    }
  }

  getNodeList(path: string[]): Array<PathTrieNode<T>> {
    const result: Array<PathTrieNode<T>> = [];
    let currentNode: PathTrieNode<T> | undefined = this.root;
    for (const key of path) {
      if (!currentNode) return result;
      result.push(currentNode);
      currentNode = currentNode.children.get(key);
    }
    return result;
  }

  private iterateInternal(
    root: PathTrieNode<T>,
    callback: (data: T | undefined, path: string[]) => void | boolean,
    path: string[]
  ): void {
    if (callback(root.nodeData, path) === false) return;
    for (const [childId, childNode] of root.children) {
      this.iterateInternal(childNode, callback as (data: T | undefined, path: string[]) => void, path.concat(childId));
    }
  }
}

export class PathTrieNode<T> {
  children = new Map<string, PathTrieNode<T>>([]);
  nodeData: T | undefined;
}

/**
 * Returns a trie which contains all the paths affected by the action. For
 * example, if the action contains a path action applied on path [A, B, C], then
 * [A], [A, B], [A, B, C] will be in the returned trie. Moreover, if
 * subscriptionTree is provided and contains [A, B, C, ...], then [A, B, C, ...]
 * will also be included in the returned trie.
 */
export function getPathTrieFromAction(action: BaseAction, subscriptionTree?: PathTrie<{}>): PathTrie<boolean> {
  // The data of a path trie node in this tree indicates whether a copy from
  // subscriptionTree under the corresponding path has been done, the boolean is
  // used to avoid duplicate copy.
  const lookupTree = new PathTrie<boolean>();
  for (const a of extractAllPathActions(action)) {
    let lookupNode = lookupTree.getOrCreatePathTrieNode(a.path);
    // Skips the step to copy the subtree rooted by the node with action path
    // in subscriptionTree if subscriptionTree is not provided or the copy has
    // been done.
    if (!subscriptionTree || lookupNode.nodeData) continue;
    let subscriptionNode = subscriptionTree.getPathTrieNode(a.path);
    if (subscriptionNode) {
      const nodesQueue: Array<[PathTrieNode<boolean>, PathTrieNode<{}>]> = [[lookupNode, subscriptionNode]];
      while (nodesQueue.length) {
        const nodePair = truthy(nodesQueue.shift());
        lookupNode = nodePair[0];
        subscriptionNode = nodePair[1];
        // Stop copying if has been done before.
        if (lookupNode.nodeData) continue;
        for (const [childId, childNode] of subscriptionNode.children) {
          if (!lookupNode.children.get(childId)) {
            lookupNode.children.set(childId, new PathTrieNode<boolean>());
          }
          nodesQueue.push([truthy(lookupNode.children.get(childId)), childNode]);
        }
        lookupNode.nodeData = true;
      }
    }
  }
  return lookupTree;
}
