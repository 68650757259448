<ng-template #defaultTemplate let-label="label">
  {{label}}
</ng-template>
<div class="seed_tab_group" data-cy="seed-tab-group">
  <div *ngFor="let label of labels; let i = index;" data-cy="seed-tab-option"
       [attr.data-cy-value]="label"
       [ngClass]="{seed_tab_option: true, selected: label === labels[selectedOptionIndex]}"
       (click)="onClick(i)"
  >
    <ng-template [ngTemplateOutletContext]="{label}"
                 [ngTemplateOutlet]="template || defaultTemplate"></ng-template>
  </div>
</div>
<div *ngIf="contentTemplate" class="seed_tabs_content" data-cy="seed-tabs-content">
  <ng-container *ngTemplateOutlet="contentTemplate; context: {$implicit: labels[selectedOptionIndex]}"></ng-container>
</div>
