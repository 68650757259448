import { XAxisOptions } from 'highcharts';

/**
 * Default color for all metric charts.
 * May be overridden per metric: see 'createMetricsChartInput'.
 */
export const DEFAULT_COLOR_FOR_METRICS = '#2CA300';

/**  Success series color for metric charts. */
export const SUCCESS_COLOR_FOR_METRICS = '#2CA300';

/** Error series color for metric charts. */
export const ERROR_COLOR_FOR_METRICS = '#C70F0F';

/**
 * Info series color for metric charts.
 * Used as a complementary color for success or default color for multi-series.
 */
export const INFO_COLOR_FOR_METRICS = '#057AFF';

/**
 * Set of recommended colors to be used by data series if no other special color is assigned.
 * Source: mix of Highchart theme + CP designers updates.
 */
const CHART_SERIES_COLORS = [
  '#3B73DE',
  '#407B24',
  '#ED561B',
  '#DDDF00',
  '#24CBE5',
  '#64E572',
  '#FF9655',
  '#FFF263',
  '#6AF9C4'
];

/** Returns one of 'CHART_SERIES_COLORS' colors to be used with for a chart data series with a given index. */
export function getSeriesColorByIndex(seriesIndex: number): string {
  return CHART_SERIES_COLORS[seriesIndex % CHART_SERIES_COLORS.length];
}

/** Returns UI options for X-axis. */
export function getXAxisDateTimeOptions(): XAxisOptions {
  return {
    title: { text: null },
    type: 'datetime',
    showEmpty: false,
    // See https://api.highcharts.com/highstock/xAxis.dateTimeLabelFormats for defaults.
    // We have 'millis' rounded to 'seconds' to make 1-point mode
    // (when Highcharts can't detect the correct rounding interval) do not show millis at all.
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e. %b',
      week: '%e. %b',
      month: "%b '%y",
      year: '%Y'
    }
  };
}
