import { DbGrant, DbRoleGrant } from '../protocol/DbUsers';

export interface DirectGrantSummary {
  accessType: string;
  database: string | null;
  table: string | null;
  column: string | null;
}

export interface RoleGrantSummary {
  roleName: string;
  grants: Array<DirectGrantSummary>;
}

export interface UserPermissionsSummary {
  username: string;
  roleGrants: Array<RoleGrantSummary>;
  directGrants: Array<DirectGrantSummary>;
}

export interface InstancePermissionsLoaded {
  status: 'loaded';
  users: Array<UserPermissionsSummary>;
}

export interface InstancePermissionsError {
  status: 'error';
  error: string;
}

export interface InstancePermissionsOther {
  status: 'loading' | 'unloaded';
}

export type InstancePermissionsState = InstancePermissionsError | InstancePermissionsLoaded | InstancePermissionsOther;

export function allUserRoleNames(username: string, roleGrants: Array<DbRoleGrant>): Array<string> {
  // Roles can be linked directly to users, as well as indirectly through other roles.
  // see https://clickhouse.com/docs/en/operations/system-tables/role-grants
  // We do a breadth first search of the tree of roles

  const directRoleNames = roleGrants
    .filter((grant) => grant.userName === username)
    .map((grant) => grant.grantedRoleName);

  const rolesVisited = new Set<string>();
  const queue = [...directRoleNames];

  while (queue.length > 0) {
    const roleName = queue.shift();

    if (roleName !== undefined && !rolesVisited.has(roleName)) {
      rolesVisited.add(roleName);

      const linkedRoleNames = roleGrants
        .filter((grant) => grant.roleName === roleName)
        .map((grant) => grant.grantedRoleName);

      for (const name of linkedRoleNames) {
        queue.push(name);
      }
    }
  }

  return [...rolesVisited];
}

export function userPermissionsSummary(
  grants: Array<DbGrant>,
  roleGrants: Array<DbRoleGrant>,
  username: string
): UserPermissionsSummary {
  const directGrants: Array<DirectGrantSummary> = grants
    .filter((grant) => grant.userName === username)
    .map((grant) => ({
      accessType: grant.accessType,
      database: grant.database,
      table: grant.table,
      column: grant.column
    }));

  const roleNames = allUserRoleNames(username, roleGrants);

  const roleGrantSummaries: Array<RoleGrantSummary> = roleNames.map((roleName) => ({
    roleName,
    grants: grants
      .filter((grant) => grant.roleName === roleName)
      .map((grant) => ({
        roleName: grant.roleName as string,
        accessType: grant.accessType,
        database: grant.database,
        table: grant.table,
        column: grant.column
      }))
  }));

  return {
    username,
    directGrants,
    roleGrants: roleGrantSummaries
  };
}
