import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RestService } from '@cp/common-services/rest.service';
import { truthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_HOUR } from '@cp/common/utils/DateTimeUtils';
import { AccountService } from '@cp/web/app/account/account.service';
import { ConfirmEmailExpirationDialogComponent } from '@cp/web/app/account/confirm-email-expiration-dialog/confirm-email-expiration-dialog.component';
import { SignInUiService } from '@cp/web/app/account/sign-in/sign-in-ui.service';
import { isAuth0Mode } from '@cp/web/app/auth0-utils';
import { GalaxyService } from '@cp/web/app/common/services/galaxy.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { environment } from '@cp/web/environments/environment';
import { Amplify } from 'aws-amplify';
import { AmplifyConfig } from 'aws-amplify/lib-esm/Common/types/types';

@Component({
  selector: 'cp-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmEmailComponent implements OnInit {
  private readonly queryParams: Params;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly restService: RestService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly accountService: AccountService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly segmentService: SegmentService,
    private readonly galaxyService: GalaxyService,
    private readonly uiService: SignInUiService,
    private readonly dialog: MatDialog
  ) {
    this.queryParams = activatedRoute.snapshot.queryParams;
  }

  async ngOnInit(): Promise<void> {
    //Configure Amplify directly here only in Auth0 Mode to enable cognito email confirmation
    if (isAuth0Mode) {
      const AMPLIFY_CONFIG: AmplifyConfig = {
        Auth: {
          region: environment.region,
          userPoolId: environment.cognitoUserPoolId,
          userPoolWebClientId: environment.cognitoUserPoolWebClientId,
          endpoint: environment.cognitoEndpoint ?? undefined,
          authenticationFlowType: environment.cognitoEndpoint ? 'USER_PASSWORD_AUTH' : undefined,
          oauth: {
            domain: environment.cognitoDomain,
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${environment.webUrl}`,
            redirectSignOut: `${environment.webUrl}/signIn`,
            responseType: 'code'
          }
        }
      };
      Amplify.configure(AMPLIFY_CONFIG);
    }

    const activationCode = truthy(this.route.snapshot.paramMap.get('activationCode'));
    const userId = truthy(this.route.snapshot.paramMap.get('userId'));
    try {
      const { lastConfirmationTime } = await this.accountService.getConfirmEmailLastRetry(userId);
      if (Date.now() - lastConfirmationTime > 24 * MILLIS_PER_HOUR) {
        this.dialog.open(ConfirmEmailExpirationDialogComponent, {
          data: { userId },
          width: '100%',
          maxWidth: '517px',
          minHeight: '269px',
          autoFocus: false,
          restoreFocus: false,
          disableClose: true,
          panelClass: 'modal'
        });
        return;
      }
      await this.accountService.confirmEmail(activationCode, userId, this.queryParams);
      this.uiService.setPartialState({
        signInButtonDisabled: false,
        successMessage: `Email confirmed`
      });
      this.segmentService.trackGaEvent({
        event: 'click email',
        label: 'verify email address',
        category: 'cloud ui',
        component: 'verifyEmail',
        view: 'auth'
      });
      this.galaxyService.track({
        event: 'auth.verifyEmail.loginLinkSelect',
        interaction: 'click'
      });
    } catch (e) {
      console.error(`Unable to confirm email`, e);
      this.snackBar.open('Unable to confirm email. Please try again later', 'Dismiss', { duration: 5000 });
    }
    if (this.queryParams['dest'] === 'matrixlms') {
      window.location.href = environment.matrixLmsLoginEndpoint;
    } else {
      await this.router.navigate(['/signIn'], { queryParams: this.queryParams });
    }
  }
}
