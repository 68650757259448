import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy } from '@cp/common/utils/Assert';
import { checkValuesEquality } from '@cp/common/utils/MiscUtils';

const SELECT_INDEX_INIT_VALUE = -1;

@Component({
  selector: 'seed-select',
  templateUrl: './seed-select.component.html',
  styleUrls: ['./seed-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SeedSelectComponent)
    }
  ]
})
export class SeedSelectComponent<ValueType> implements ControlValueAccessor, OnChanges, OnInit {
  private onChange = (ignored: ValueType | undefined): void => {}; // eslint-disable-line  @typescript-eslint/no-unused-vars
  private onTouched = (ignored: ValueType | undefined): void => {}; // eslint-disable-line  @typescript-eslint/no-unused-vars

  @Output()
  selectionChange = new EventEmitter<SeedSelectOption<ValueType>>();

  @Input()
  value?: ValueType;

  @Input()
  template?: TemplateRef<{ item: SeedSelectOption<ValueType>; isSelected: boolean }>;

  /**
   * Select dropdown label inside the selection input. This will be visible only when there's no selected option yet.
   * If not provided, the first option will be selected by default and be displayed in the input.
   * Use an empty string value to have no selected option if value is not provided.
   */
  @Input()
  label?: string;

  selectedOptionIndex = SELECT_INDEX_INIT_VALUE;

  @Input()
  options!: ReadonlyArray<SeedSelectOption<ValueType>>;

  @Input()
  disabled? = false;

  /** If true add dividers between the dropdown options. */
  @Input()
  withDivider = false;

  shouldPreventGlobalClick = false;

  optionsMenuOpen = false;

  /**
   * Allows passing a Cypress attribute to the 'seed_select_options' element. Handles cases when multiple selectors are
   * displayed where their options share the same values.
   */
  @Input()
  dataCy?: string;

  @HostListener('document:click', ['$event'])
  onGlobalClick(): void {
    if (this.shouldPreventGlobalClick) {
      this.shouldPreventGlobalClick = false;
      return;
    }
    this.optionsMenuOpen = false;
  }

  ngOnInit(): void {
    assertTruthy(this.options);
    // Select the first option if there is no 'label' and 'newSelectedOptionIndex' is not set.
    if (this.label === undefined && this.selectedOptionIndex === SELECT_INDEX_INIT_VALUE && this.options.length > 0) {
      this.selectOption(0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    assertTruthy(this.options);
    if (changes['value']) {
      const newSelectedOptionIndex = this.options.findIndex((i) => checkValuesEquality(i.value, this.value));
      this.selectOption(newSelectedOptionIndex, true);
    }
  }

  registerOnChange(fn: (value: ValueType | undefined) => void): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  writeValue(value: ValueType): void {
    const index = this.options.findIndex((option) => option.value === value);
    if (index >= 0) {
      this.selectOption(index);
    }
  }

  registerOnTouched(fn: (value: ValueType | undefined) => void): void {
    this.onTouched = fn;
  }

  private selectOption(optionIndex: number, isPartOfOnChange = false): SeedSelectOption | undefined {
    if (optionIndex === this.selectedOptionIndex) {
      return;
    }

    if (this.options[optionIndex].disabled) {
      return;
    }

    this.selectedOptionIndex = optionIndex;
    const selectedOption = this.options[optionIndex];

    if (isPartOfOnChange) {
      // onChange ignores the value if the call is made within Angular change detection cycle.
      setTimeout(() => {
        this.onChange(selectedOption?.value);
      }, 0);
    } else {
      this.onChange(selectedOption?.value);
    }
    return selectedOption;
  }

  onUserSelect(optionIndex: number): void {
    if (optionIndex !== this.selectedOptionIndex && !this.options[optionIndex].disabled) {
      this.selectionChange.emit(this.options[optionIndex]);
      this.writeValue(this.options[optionIndex]?.value);
    }
  }

  handleClick(): void {
    if (this.disabled) {
      return;
    }
    this.optionsMenuOpen = !this.optionsMenuOpen;
    this.shouldPreventGlobalClick = true;
    this.onTouched(this.selectedOption?.value);
  }

  trackByLabel(index: number, item: SeedSelectOption): string {
    return `${item.label}`;
  }

  get selectedOption(): SeedSelectOption | undefined {
    return this.options[this.selectedOptionIndex];
  }

  get selectedLabel(): { label: string } {
    return this.options[this.selectedOptionIndex] || { label: this.label };
  }
}
