import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityProvider, MfaMethod } from '@cp/common/protocol/Account';
import { isDefined } from '@cp/common/protocol/Common';
import { OrganizationRestrictions, OrganizationRole, OrganizationUser } from '@cp/common/protocol/Organization';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import {
  installOrganizationIdPageParameterHandler,
  PageComponentWithOrganizationId
} from '@cp/web/app/organizations/current-organization.helper';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { SendOrgInviteConfirmDialogComponent } from '@cp/web/app/organizations/send-org-invite-confirm-dialog/send-org-invite-confirm-dialog.component';
import { SendOrgInvitesDialogComponent } from '@cp/web/app/organizations/send-org-invites-dialog/send-org-invites-dialog.component';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface UserRow {
  /** Control plane user id for the type = 'USER' or email for type = 'Invitation'. */
  id: string;
  name: string;
  email: string;
  role: OrganizationRole;
  comment?: string;
  type: 'USER' | 'INVITATION';
  resend: Boolean;
  mfaPreferredMethod?: MfaMethod;
  identityProviders?: Array<IdentityProvider>;
}

@Component({
  selector: 'manage-org-users',
  templateUrl: './manage-org-users.component.html',
  styleUrls: ['./manage-org-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageOrgUsersComponent extends OnDestroyComponent implements PageComponentWithOrganizationId {
  readonly buildCanonicalPagePathWithOrganizationId = (id: string) => `organizations/${id}/members`;

  readonly usersObs: Observable<Array<UserRow>>;
  readonly myOrgUserObs: Observable<OrganizationUser>;
  readonly orgRestrictionsObs: Observable<OrganizationRestrictions>;

  readonly displayedColumns = ['name', 'email', 'role', 'provider', 'mfa-status', 'comment', 'actions'];

  constructor(
    readonly instanceService: InstanceService,
    readonly instanceStateService: InstanceStateService,
    readonly organizationStateService: OrganizationStateService,
    readonly organizationService: OrganizationService,
    readonly dialog: MatDialog,
    readonly route: ActivatedRoute,
    readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    super();

    installOrganizationIdPageParameterHandler(this);

    this.orgRestrictionsObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeRestrictions(id))
    );
    this.myOrgUserObs = this.organizationStateService.observeCurrentOrganizationUser();
    this.usersObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((organizationId) =>
        combineLatest([
          this.organizationStateService.observeOrganizationUsers(organizationId),
          this.organizationStateService.observeInvitations(organizationId)
        ])
      ),
      map(([userMap, invitationMap]) => {
        const userRows: Array<UserRow> = Object.values(userMap).map((user) => ({
          id: user.userId,
          name: user.name,
          email: user.email,
          role: user.role,
          type: 'USER',
          resend: false,
          mfaPreferredMethod: user.mfaPreferredMethod,
          identityProviders: user.identityProviders
        }));
        userRows.push(
          ...Object.values(invitationMap).map((invitation) => {
            const isInvitationExpired = ManageOrgUsersComponent.isExpired(invitation.expirationDate);
            const userRow: UserRow = {
              id: invitation.email,
              name: invitation.email,
              email: invitation.email,
              role: invitation.role,
              comment: isInvitationExpired ? 'Invitation expired - ' : 'Invitation',
              type: 'INVITATION',
              resend: isInvitationExpired
            };
            return userRow;
          })
        );
        return userRows;
      })
    );
  }

  async changeUserRole(userId: string, role: OrganizationRole): Promise<void> {
    await this.organizationService.changeRole(this.organizationStateService.getCurrentOrgIdOrFail(), userId, role);
  }

  async removeUser(userId: string): Promise<void> {
    await this.organizationService.removeUser(this.organizationStateService.getCurrentOrgIdOrFail(), userId);
  }

  async deleteInvitation(email: string): Promise<void> {
    await this.organizationService.deleteInvitation(this.organizationStateService.getCurrentOrgIdOrFail(), email);
  }

  showInvitesDialog(): void {
    SendOrgInvitesDialogComponent.show(this.dialog);
  }

  static isExpired(expirationDate: number): boolean {
    return expirationDate - Date.now() < 0;
  }

  async resendInvitation(email: string, role: OrganizationRole): Promise<void> {
    try {
      const organization = this.organizationStateService.getCurrentOrgOrFail();
      if (!organization.restrictions.canInviteMembers) {
        return;
      }
      await this.organizationService.resendInvite(organization.id, email, role);
      this.dialog.open(SendOrgInviteConfirmDialogComponent, {
        width: '100%',
        maxWidth: '517px',
        minHeight: '269px',
        autoFocus: false,
        restoreFocus: false,
        disableClose: true,
        panelClass: 'modal'
      });
    } catch (e) {
      this.snackBar.open('Error occurred when resending invite', 'Dismiss', { duration: 5000 });
    }
  }

  isGoogleUser(identityProviders: Array<IdentityProvider> | undefined): boolean {
    return !!identityProviders?.includes('GOOGLE');
  }

  isCognitoUser(identityProviders: Array<IdentityProvider> | undefined): boolean {
    return !!identityProviders?.includes('COGNITO');
  }

  getUserMfaDisplayStatus(mfa: MfaMethod | undefined, identityProviders: Array<IdentityProvider> | undefined): string {
    if (!this.isCognitoUser(identityProviders)) {
      return 'N/A';
    }
    switch (mfa) {
      case 'SOFTWARE_TOKEN_MFA':
        return 'Enabled';
      case 'NOMFA':
      case undefined:
      default:
        return 'Not set';
    }
  }
}
