/**
 * Information passed by Tackle.
 * See https://developers.tackle.io/docs/setup-your-self-hosted-registration-page.
 */
import { Injectable } from '@angular/core';
import { UserDetailsUpdate } from '@cp/common/protocol/Account';
import { OrganizationDetails } from '@cp/common/protocol/Billing';
import { PendingUserAction } from '@cp/common/protocol/PendingUserActions';
import { isTackleMarketplace, TackleMarketplace, TackleSubscriptionToken } from '@cp/common/protocol/Tackle';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isNotEmpty } from '@cp/common/utils/ValidationUtils';
import { AccountApiService } from '@cp/web/app/account/account-api.service';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { BillingApiService } from '@cp/web/app/admin/billing-api.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';

@Injectable({
  providedIn: 'root'
})
export class TackleSubscriptionService {
  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly accountApiService: AccountApiService,
    private readonly billingApiService: BillingApiService,
    private readonly signInMetadataService: SignInMetadataService,
    private readonly organizationStateService: OrganizationStateService
  ) {}

  /** Checks if sign-in metadata contains tackle registration details and stores these details as a pending action. */
  async createPendingUserActionFromSignInMetadata(): Promise<void> {
    const userId = this.accountStateService.getUserId();
    const tackleToken =
      this.signInMetadataService.tackleToken ||
      (await this.accountApiService.getSignInMetadata(this.signInMetadataService.metadataId, userId)).tackleToken;
    if (!isTackleSubscriptionToken(tackleToken)) {
      console.debug(
        'TackleSubscriptionService::createPendingUserActionFromSignInMetadata: not a valid tackle token',
        tackleToken
      );
      return;
    }
    console.debug(
      'TackleSubscriptionService::createPendingUserActionFromSignInMetadata: add tackle pending action',
      tackleToken
    );
    const update: UserDetailsUpdate = {
      pendingActionsToAdd: [
        {
          type: 'tackle-subscription',
          data: JSON.stringify(tackleToken)
        }
      ]
    };
    const userDetails = await this.accountApiService.updateUserDetails(update);
    this.accountStateService.setUserDetails(userDetails);
  }

  /** Creates Tackle subscription and returns subscribed organization id. */
  async handleTackleSubscriptionPendingAction(
    action: PendingUserAction,
    organizationDetails: OrganizationDetails
  ): Promise<string | undefined> {
    console.debug('TackleSubscriptionService: handleTackleSubscriptionPendingAction', action, organizationDetails);
    assertTruthy(action.data, `Can't handle tackle subscription - Tackle pending action must have data field`);
    const tackleToken = JSON.parse(action.data);
    if (!isTackleSubscriptionToken(tackleToken)) {
      return undefined;
    }
    const organization = await this.billingApiService.handleTackleSubscription(tackleToken, organizationDetails);
    this.organizationStateService.setOrganization(organization);
    return organization.id;
  }
}

export function isTackleSubscriptionToken(token: unknown): token is TackleSubscriptionToken {
  if (!token || typeof token !== 'object') {
    return false;
  }
  const { marketplace, customerId, productId, awsAccountId } = token as TackleSubscriptionToken;
  let isValid = isTackleMarketplace(marketplace) && isNotEmpty(customerId) && isNotEmpty(productId);
  if (!isValid) {
    return false;
  }
  if (marketplace === 'aws') {
    const expectedFieldCount = typeof awsAccountId === 'string' ? 4 : 3;
    isValid = expectedFieldCount === Object.keys(token).length;
  } // GCP or Azure have no extra parameters to store.
  return isValid;
}

/**
 *  Parses tackle subscription token from the current URL.
 *  Remove subscription params from the URL.
 *  See https://developers.tackle.io/docs/setup-your-self-hosted-registration-page.
 */
export function parseTackleSubscriptionTokenFromUrlOnAppInit(): TackleSubscriptionToken | undefined {
  const url = new URL(window.location.href);
  const marketplace: TackleMarketplace | undefined = url.searchParams.get('aws_customer_id')
    ? 'aws'
    : url.searchParams.get('gcp_customer_id')
    ? 'gcp'
    : undefined;
  if (!isTackleMarketplace(marketplace)) {
    return undefined;
  }
  const customerIdParamName = `${marketplace}_customer_id`;
  const productIdParamName = `${marketplace}_product_id`;
  const token: TackleSubscriptionToken = {
    marketplace,
    customerId: url.searchParams.get(customerIdParamName) || '',
    productId: url.searchParams.get(productIdParamName) || ''
  };
  const awsAccountId = marketplace === 'aws' ? url.searchParams.get('CustomerAWSAccountID') : undefined;
  if (awsAccountId) {
    token.awsAccountId = awsAccountId;
  }
  if (!isTackleSubscriptionToken(token)) {
    // The received token is invalid - do nothing.
    return undefined;
  }
  console.debug('TackleSubscriptionService::parseTackleSubscriptionTokenFromUrlOnAppInit: ', token);
  return token;
}
