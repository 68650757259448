import { InjectionToken } from '@angular/core';
import { environment } from '@cp/web/environments/environment';

export type RecaptchaProviderFn = () => Promise<string>;
export const RECAPTCHA_PROVIDER_TOKEN = new InjectionToken<RecaptchaProviderFn>('recaptchaProvider');

export const recaptchaProviderFn: RecaptchaProviderFn = () => {
  const recaptcha: any = (window as any).grecaptcha;
  return new Promise((resolve, reject) => {
    recaptcha.ready(async () => {
      try {
        const token = await recaptcha.execute(environment.recaptchaSiteKey, { action: 'submit' });
        resolve(token);
      } catch (e) {
        console.error('Unable to get recaptcha token', e);
        reject(e);
      }
    });
  });
};

const RECAPTCHA_MESSAGE = 'please check your internet connection and reload to get a recaptcha challenge';

function styleRecaptcha(node: Node): void {
  node.parentNode?.removeChild(node);
  const recaptchaErrorContainer = document.querySelector('.recaptcha-error-container');

  if (recaptchaErrorContainer) {
    recaptchaErrorContainer.textContent = '';
    const div = document.createElement('div');
    div.classList.add('recaptcha-error');
    div.textContent =
      'Cannot connect to the reCAPTCHA service. Please check your internet connection and reload the page to get a reCAPTCHA challenge';
    recaptchaErrorContainer.append(div);
  }
}

export function buildRecaptchaMutationObserver(): MutationObserver {
  const handler = (mutations: MutationRecord[]): void => {
    mutations.forEach((mutation: MutationRecord) => {
      mutation.addedNodes.forEach((node) => {
        if (node?.textContent?.toLowerCase()?.includes(RECAPTCHA_MESSAGE)) {
          styleRecaptcha(node);
        }
      });
    });
  };

  return new MutationObserver(handler);
}
