import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GalaxyClient } from '@cp/common-services/galaxy/client';
import { RestService } from '@cp/common-services/rest.service';
import {
  AnalyticsInteraction,
  ControlPlaneAnalyticsComponent,
  ControlPlaneAnalyticsNamespace,
  ControlPlaneEventData,
  GalaxyEventType,
  GalaxyProperties
} from '@cp/common/protocol/Galaxy';
import { replaceSensitiveFieldValue } from '@cp/common/utils/LogUtils';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import * as Sentry from '@sentry/angular';
import { getOriginalConsoleMethods, LogLevel, LogPipe, simplifyJson } from 'logpipes';

export type GalaxyEventNamespace = ControlPlaneAnalyticsNamespace;

export type GalaxyServiceData = {
  interaction: AnalyticsInteraction;
  event: FullyQualifiedEvent;
  properties?: GalaxyProperties;
  reportExternally?: boolean;
  identify?: boolean;
  userId?: string;
};

export type FullyQualifiedEventPrefix = `${GalaxyEventNamespace}.${ControlPlaneAnalyticsComponent}`;

export type FullyQualifiedEvent = `${FullyQualifiedEventPrefix}.${GalaxyEventType}`;
export type GalaxyTrackerData = FullyQualifiedEvent | [FullyQualifiedEvent, Record<string, string>];
export type GalaxyTrackerEventObj = {
  event: FullyQualifiedEvent;
  properties?: Record<string, string>;
};

export const getTracker =
  (galaxyService: GalaxyService) =>
  async (interaction: AnalyticsInteraction, data: GalaxyTrackerEventObj): Promise<void> => {
    try {
      const trackableEvent = {
        interaction,
        event: data.event,
        properties: data.properties || {}
      };
      await galaxyService.track(trackableEvent);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
}

export interface TraceElapsedOptions {
  skipTraceResult?: boolean;
}

const systemConsole = getOriginalConsoleMethods();

@Injectable({
  providedIn: 'root'
})
export class GalaxyService {
  private galaxyClient: GalaxyClient;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly restService: RestService,
    private readonly accountStateService: AccountStateService,
    private readonly organizationStateService: OrganizationStateService
  ) {
    this.galaxyClient = new GalaxyClient(this.restService, Sentry);
  }

  anonymousId(): string {
    try {
      const analytics = (window as any)?.analytics;

      if (analytics && typeof analytics.user === 'function' && typeof analytics.user().anonymousId === 'function') {
        return analytics.user().anonymousId() || 'unknown-anonymous-id';
      }

      return 'unknown-anonymous-id';
    } catch (error) {
      systemConsole.warn(error);
      return 'unknown-anonymous-id';
    }
  }

  trace(message: string, data?: Record<string, any> | null): void {
    try {
      const instanceId = this.route.snapshot.paramMap.get('instanceId');

      const { event, namespace, component, ...rest } = data || {};

      const properties: GalaxyProperties = {
        properties: {
          page: window.location.href,
          userAgent: navigator.userAgent,
          orgId: this.organizationStateService.getCurrentOrgId(),
          service: instanceId
            ? {
                id: instanceId
              }
            : undefined
        },
        data: rest ?? undefined
      };

      const userId = this.accountStateService.getUserId() || this.anonymousId();
      const orgId = this.organizationStateService.getCurrentOrgId();

      this.galaxyClient.trace({
        application: 'CONTROL_PLANE_WEB',
        timestamp: Date.now(),
        namespace: namespace || 'forensics',
        event: event || 'trace',
        orgId,
        userId,
        message,
        component,
        properties
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async traceElapsed<T>(
    message: string,
    data: Record<string, any>,
    genericFn: () => T | Promise<T>,
    options: TraceElapsedOptions = {},
    component?: string
  ): Promise<T> {
    const start = Date.now();
    const fnValue = await genericFn();
    data['elapsedMs'] = Date.now() - start;

    if (!options.skipTraceResult && fnValue) {
      data['traceElapsedResult'] = fnValue;
    }

    this.trace(message, { component, ...data });
    return fnValue;
  }

  async track(event: GalaxyServiceData): Promise<void> {
    if (!this.isGalaxyReportingEnabled()) return;

    try {
      await this.trackEvent(event);
    } catch (e) {
      Sentry.captureEvent({
        level: 'warning',
        message: `Could not send events: ${getErrorMessage(e)}`
      });
    }
  }

  async trackEvent({
    event,
    properties,
    interaction = 'trigger',
    reportExternally = false,
    identify = false,
    userId
  }: GalaxyServiceData): Promise<void> {
    if (!properties) {
      properties = {};
    }

    const unauthenticatedUserId = this.anonymousId();

    properties = {
      ...properties,
      orgId: this.organizationStateService.getCurrentOrgId(),
      userId: userId || unauthenticatedUserId
    };

    const instanceId = this.route.snapshot.paramMap.get('instanceId');

    if (instanceId) {
      properties['instanceId'] = instanceId;
    }

    const [namespace, component, eventName] = event.split('.') as [
      GalaxyEventNamespace,
      ControlPlaneAnalyticsComponent,
      GalaxyEventType
    ];
    const galaxyEvent: ControlPlaneEventData = {
      timestamp: Date.now(),
      application: 'CONTROL_PLANE_WEB',
      event: eventName,
      namespace,
      component,
      properties,
      reportExternally,
      identify,
      interaction
    };

    this.galaxyClient.track(galaxyEvent);
  }

  getGalaxySessionId(): string {
    return GalaxyClient.getGalaxySessionId();
  }

  flushEvents(): Promise<void> {
    return this.galaxyClient.flushEvents();
  }

  private isGalaxyReportingEnabled(): boolean {
    return true;
  }
}

export function getCurrentGalaxyService(): GalaxyService {
  const application = (window as any)['controlPlane'] || (window as any)['sysadmin'];
  return application?.galaxyService as GalaxyService;
}

export interface TraceDecoratorArgs {
  message?: string;
  component?: string;
  data?: Record<string, unknown>;
  skipTraceResult?: boolean;
}

export function Trace(traceArgs?: TraceDecoratorArgs) {
  return (target: any, memberName: string, propertyDescriptor: PropertyDescriptor) => {
    return {
      get() {
        const wrapperFn = async (...args: any[]) => {
          const className = target.constructor.name;
          const galaxyService = getCurrentGalaxyService();

          if (!galaxyService) {
            console.log('Could not detect galaxy in `window`. Returning without instrumentation');
            return propertyDescriptor.value.apply(this, args);
          }

          return galaxyService.traceElapsed(
            traceArgs?.message || `${className}.${memberName}`,
            traceArgs?.data || {},
            () => propertyDescriptor.value.apply(this, args),
            { skipTraceResult: traceArgs?.skipTraceResult },
            traceArgs?.component || className
          );
        };

        Object.defineProperty(this, memberName, {
          value: wrapperFn,
          configurable: true,
          writable: true
        });

        return wrapperFn;
      }
    };
  };
}

export function trace(message: string, component: string, data?: Record<string, any>) {
  const galaxyService = getCurrentGalaxyService();

  if (!galaxyService) {
    systemConsole.log('Could not detect galaxy in `window`. Falling back to console.log');
    systemConsole.log(`${message} | ${component}`, data);
    return;
  }

  galaxyService.trace(message, { component, ...data });
}

/**
 * Sideloads all log messages to Galaxy.
 * Does not change args.
 * The method is non-blocking.
 */
export function createGalaxyLogPipe(): LogPipe {
  return (level, ...args) => {
    logToGalaxy(level, ...args);
    return args;
  };
}

/**
 * Enqueues message in Galaxy service.
 * Warning:
 *  The method can't be async in order to stay safe to be called in different environments.
 *  Example:
 *   1. console.log() is triggered and logToGalaxy() is called.
 *   2. Promise.then() inside this method triggers a microtask (https://javascript.plainenglish.io/angular-zone-js-3b5e2347b7).
 *   3 A microtask triggers change detection in Angular (via zone-js).
 *   4. Change detection is our code and can trigger errors or logging.
 *   5. Some console.log() method will be called.
 *   6. goto 1.
 *
 */
export function logToGalaxy(level: LogLevel, ...args: unknown[]) {
  if (window.Cypress) {
    return;
  }

  try {
    const galaxyService = getCurrentGalaxyService();
    if (!galaxyService) {
      return;
    }

    const message = ['[WEB]'];

    const serializableArgs = simplifyJson([...args], { replacePropertyValue: replaceSensitiveFieldValue }) as unknown[];

    if (typeof serializableArgs[0] === 'string') {
      const part = serializableArgs.shift() as string;
      message.push(part);
    }

    const data: Record<string, unknown> = {
      component: level,
      namespace: 'logs'
    };

    if (serializableArgs.length > 0) {
      data['values'] = serializableArgs;
    }
    const messageString = message.join(' ').slice(0, 200);
    galaxyService.trace(messageString, data);
  } catch (error) {
    systemConsole.error('Could not log to galaxy', error);
  }
}
