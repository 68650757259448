<div class="root">
  <div *ngIf="(isDemoEnabled$ | async) !== true">
    Not enabled
  </div>
  <div *ngIf="isDemoEnabled$ | async">
    <h3>Auth0 Playground</h3>

    <ng-container *ngVar="user$ | async as user">

      <!-- Initialization state. We do not know if the user is signed in or not yet. -->
      <div *ngIf="user === 'loading' || user === null">Loading...</div>

      <!-- User is not signed in. -->
      <div *ngIf="user === 'signed-out'">
        <div>
          <div class="option_title">
            Login method
            <a href="https://auth0.com/docs/libraries/lock/lock-authentication-modes" target="_blank">(?)</a>
          </div>
          <mat-radio-group [(ngModel)]="loginMethod">
            <mat-radio-button *ngFor="let method of AUTH0_LOGIN_METHODS" [value]="method">
              {{method}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <br>
        <div>
          <div class="option_title">
            Display mode
            <a href="https://auth0.github.io/auth0-react/interfaces/AuthorizationParams.html#display" target="_blank">(?)</a>
          </div>
          <mat-radio-group [(ngModel)]="loginDisplayMode">
            <mat-radio-button *ngFor="let mode of AUTH0_LOGIN_DISPLAY_MODES" [value]="mode">
              {{mode}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <br>
        <div>
          <div class="option_title">
            Prompt
            <a href="https://auth0.github.io/auth0-spa-js/interfaces/AuthorizationParams.html#prompt" target="_blank">(?)</a>
          </div>
          <mat-radio-group [(ngModel)]="loginPrompt">
            <mat-radio-button *ngFor="let prompt of AUTH0_LOGIN_PROMPTS" [value]="prompt">
              {{prompt}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <br>
        <div>
          <div class="option_title">
            Screen hint
            <a href="https://auth0.github.io/auth0-spa-js/interfaces/AuthorizationParams.html#screen_hint"
               target="_blank">(?)</a>
          </div>
          <mat-radio-group [(ngModel)]="screenHint">
            <mat-radio-button *ngFor="let hint of AUTH0_SCREEN_HINTS" [value]="hint">
              {{hint}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <br>
        <button (click)="signIn()" *ngIf="user">Sign in</button>
      </div>

      <!-- User is signed in. -->
      <div *ngIf="user && user !== 'loading' && user !== 'signed-out'">
        <div class="block_title">Access token:</div>

        <div appHighlightJs class="access_token">{{accessToken}}</div>
        <button (click)="copyAccessToken()">Copy</button>

        <div class="block_title">Parsed access token:</div>
        <div appHighlightJs class="json">{{parsedAccessToken}}</div>

        <div class="block_title">Current user:</div>
        <div appHighlightJs class="json">{{stringify(user, undefined, 4)}}</div>

        <div class="block_title">MFA devices: {{mfaStatusMessage}}</div>
        <div *ngFor="let mfaDevice of mfaDevices" class="mfa_device_block">
          <div appHighlightJs class="json">{{stringify(mfaDevice)}}</div>
          <button (click)="deleteMfaDevice(mfaDevice)">Delete</button>
        </div>
        <button (click)="refreshMfaDevices()">Refresh</button>
        &nbsp;
        <button (click)="addNewMfaDevice()">Add new device</button>
        <div *ngIf="newMfaDeviceInfo">
          <qrcode [qrdata]="newMfaDeviceInfo.qrcode" [width]="300"></qrcode>
          <div>
            Enter the 6 digit OTP code produced by the MFA device for the QR code:
            <div>
              <input type="text" [(ngModel)]="otpCode" placeholder="OTP code">
              &nbsp;<button (click)="verifyOtpCode()" [disabled]="otpCode?.length!==6">Verify code</button>
              &nbsp;<button (click)="hideMfaVerification()">Cancel</button>
            </div>
          </div>
        </div>

        <div class="use_mfa_buttons_block">
          <button (click)="toggleMfa(true)">Set 'use_mfa' to true</button>
          <button (click)="toggleMfa(false)">Set 'use_mfa' to false</button>
        </div>

        <div class="block_title">Other actions:</div>
        <button (click)="signOut()">Sign Out</button>
      </div>
    </ng-container>
  </div>
  <div>
    <div class="block_title"></div>
    <button (click)="runApiCall()">Run CP-API call</button>
    <div class="api_call_result">Result: {{apiCallResult}}</div>
  </div>
  <div>
    <div class="block_title"></div>
    <button (click)="triggerAppReloadByBackend()">Test refresh by backend</button>
  </div>
</div>
