import { HttpErrorResponse } from '@angular/common/http';
import { ForensicsEventData, GALAXY_API_PATH, GalaxyEvent } from '@cp/common/protocol/Galaxy';

import { MILLIS_PER_SECOND } from '@cp/common/utils/DateTimeUtils';
import { interval, Subject, takeUntil } from 'rxjs';
import { v4 as uuid } from 'uuid';

const BATCH_TIME_SECONDS = 3;

interface HttpClient {
  post<T>(apiPath: string, requestObject?: {}): Promise<T>;
}

interface ErrorHandler {
  captureException: (exception: unknown) => void;
}

declare global {
  interface Window {
    Cypress?: { env: () => Record<string, string | number> };
  }
}
export const GALAXY_SESSION_ID_PARAM_NAME = 'glxid';
export const TRACKING_PARAMS_KEY = 'glx_tracking_params';

function setTrackingParams(): void {
  const searchParams = new URLSearchParams(window.location.search);
  const glxSessionId = searchParams.get(GALAXY_SESSION_ID_PARAM_NAME);
  console.debug('setting galaxy sid from params', glxSessionId);

  if (glxSessionId) {
    window.sessionStorage.setItem('glx_id', glxSessionId);
  }

  const currentTrackingParamsSerialized = window.sessionStorage.getItem(TRACKING_PARAMS_KEY) ?? '{}';
  const currentTrackingParams: Record<string, string | undefined> = JSON.parse(currentTrackingParamsSerialized);

  for (const key of searchParams.keys()) {
    if (key !== GALAXY_SESSION_ID_PARAM_NAME) {
      const value = searchParams.get(key);

      if (value) {
        currentTrackingParams[key] = value;
      }
    }
  }

  window.sessionStorage.setItem(TRACKING_PARAMS_KEY, JSON.stringify(currentTrackingParams));
}

window.addEventListener('load', setTrackingParams);

export class GalaxyClient {
  private eventsQueue: GalaxyEvent[];
  private forensicsQueue: ForensicsEventData[];
  private readonly onDestroy = new Subject<void>();

  static getGalaxySessionId = (): string => {
    const GALAXY_SESSION_ID_KEY = 'glx_id';
    try {
      if (!window.sessionStorage.getItem(GALAXY_SESSION_ID_KEY)) {
        window.sessionStorage.setItem(GALAXY_SESSION_ID_KEY, uuid());
      }

      return window.sessionStorage.getItem(GALAXY_SESSION_ID_KEY) ?? 'unknown';
    } catch (error) {
      return 'unknown';
    }
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorHandler?: ErrorHandler
  ) {
    if (window.Cypress) {
      console.log('Creating Galaxy client');
    }
    this.eventsQueue = [];
    this.forensicsQueue = [];
    interval(BATCH_TIME_SECONDS * MILLIS_PER_SECOND)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => this.flushEvents());
    const onBeforeUnload = (event: any) => {
      if (window.Cypress) {
        console.log('Galaxy onBeforeUnload');
      }
      void this.cleanup();
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    window.addEventListener('window:unload', onBeforeUnload);
  }

  decorateEventWithTracker<T extends GalaxyEvent | ForensicsEventData>(event: T): T {
    event.properties = event.properties || {};
    event.properties['tracker'] = JSON.parse(window.sessionStorage.getItem(TRACKING_PARAMS_KEY) ?? '{}');
    return event;
  }

  track(event: GalaxyEvent): void {
    this.eventsQueue.push(this.decorateEventWithTracker(event));
  }

  trace(event: ForensicsEventData): void {
    this.forensicsQueue.push(this.decorateEventWithTracker(event));
  }

  private async sendEvents(rpcCall: string, queue: GalaxyEvent[] | ForensicsEventData[]): Promise<void> {
    try {
      const numEvents = queue.length;
      if (numEvents > 0) {
        const request = {
          rpcAction: rpcCall,
          galaxySessionId: GalaxyClient.getGalaxySessionId(),
          data: queue.slice(0, numEvents)
        };
        await this.httpClient.post(GALAXY_API_PATH, request);
        queue.splice(0, numEvents);
      }
    } catch (error) {
      this.captureException(error);
    }
  }

  private async sendAuthenticatedEvents(): Promise<void> {
    return await this.sendEvents('sendGalaxyEvent', this.eventsQueue);
  }

  private async sendForensicsEvents(): Promise<void> {
    return await this.sendEvents('sendGalaxyForensicEvent', this.forensicsQueue);
  }

  captureException(error: unknown): void {
    if (this.errorHandler && error instanceof Error) {
      this.errorHandler.captureException(error);
    } else if (this.errorHandler && typeof error === 'string') {
      this.errorHandler.captureException(new Error(error));
    } else if (this.errorHandler && error instanceof HttpErrorResponse) {
      this.errorHandler.captureException(new Error(error.message));
    } else {
      /*Noop can't log to console otherwise we might end up on a infinite loop */
    }
  }

  cleanup(): Promise<void> {
    this.onDestroy.next();
    this.onDestroy.complete();
    return this.flushEvents();
  }

  async flushEvents(): Promise<void> {
    try {
      await Promise.all([this.sendAuthenticatedEvents(), this.sendForensicsEvents()]);
    } catch (error) {
      this.captureException(error);
    }
  }
}
