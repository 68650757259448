import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { GetUserDetailsResponse } from '@cp/common/protocol/Account';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { SignInStorageService } from '@cp/web/app/account/sign-in/sign-in-storage.service';
import { FullStoryService } from '@cp/web/app/common/services/full-story.service';
import { trace } from '@cp/web/app/common/services/galaxy.service';
import { PendingUserActionsService } from '@cp/web/app/common/services/pending-user-actions.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { firstValueFrom } from 'rxjs';

/**
 * This guard performs multiple post signin checks:
 * 1. Checks whether there are pending invitations.
 * 2. Checks this is the first time the user signs-in, they will be redirected to '/onboard'.
 * 3. Updates ironclad state.
 * 4. Processes tackle subscriptions.
 * ... or any other post-sign-in callbacks.
 */
@Injectable({
  providedIn: 'root'
})
export class PostSignInGuard implements CanActivate {
  constructor(
    private readonly signInStorageService: SignInStorageService,
    private readonly signInMetadataService: SignInMetadataService,
    private readonly organizationService: OrganizationService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly segmentService: SegmentService,
    private readonly fullStoryService: FullStoryService,
    private readonly accountStateService: AccountStateService,
    private readonly accountService: AccountService,
    private readonly pendingActionsService: PendingUserActionsService,
    private readonly router: Router
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    // The navigation is allowed only if user details are available (isAuthenticated).
    if (!(await this.accountStateService.isAuthenticated())) {
      await this.router
        .navigate(['/signIn'])
        .then()
        .catch((e) => console.error('Navigation:', e));
      return false;
    }

    if (!(await this.accountService.userHasAcceptedTOS())) {
      await this.router.navigateByUrl('/termsOfService');
      return false;
    }

    if (await this.shouldGoToOrgInvitations()) {
      this.router.navigateByUrl('/approveOrgInvitations').then();
      return false;
    }

    const userDetails = this.accountStateService.getUserDetailsOrFail();
    const isExplicitSignInInProgress = this.signInStorageService.isSignInInProgress();

    if (isExplicitSignInInProgress) {
      await this.signInMetadataService.waitUntilSignInMetadataIsProcessed();
    }

    // If tackleRedirect is a url (evaluates true) keep going and redirect to it eventually.
    // This forces the user to go through the tackle onboarding as long as it's not completed successfully.
    const tackleRedirect = await this.handleTackleFirmographicsRedirects();
    this.signInStorageService.clear(); // Resets 'signInInProgress()' flag.
    if (!tackleRedirect && !isExplicitSignInInProgress) {
      await this.pendingActionsService.triggerPendingActionByType('entry-questionnaire');
      return true;
    }

    this.trackSignInInSegment(userDetails);
    const res = tackleRedirect ? false : this.handleOnboardingRedirects();

    if (typeof res === 'boolean' && res) {
      // In case the user didn't complete the entry-questionnaire - show it.
      await this.pendingActionsService.triggerPendingActionByType('entry-questionnaire');
    }
    return res;
  }

  private trackSignInInSegment(userDetails: GetUserDetailsResponse): void {
    this.segmentService.identify(userDetails.email, userDetails.name, userDetails.userId);
    this.segmentService.trackGaEvent({
      event: 'sign in',
      label: 'email',
      category: 'cloud ui',
      reportToServer: true,
      identifyOnServer: true,
      view: 'auth',
      component: 'postSignIn',
      properties: {
        referrer: document.referrer,
        url: this.signInMetadataService.url || window.location.href
      }
    });
    this.fullStoryService.identify(userDetails.userId);
  }

  private async handleTackleFirmographicsRedirects(): Promise<boolean> {
    if (!this.accountService.hasOauth2FlowParamsInUrl()) {
      return await this.pendingActionsService.triggerPendingActionByType('tackle-subscription');
    }
    return false;
  }

  private handleOnboardingRedirects(): UrlTree | boolean {
    if (!this.accountService.hasOauth2FlowParamsInUrl()) {
      try {
        const finishedOnboardingFlow = !this.pendingActionsService.getPendingAction('onboarding');
        if (finishedOnboardingFlow) {
          return true;
        }
        return this.router.parseUrl('/onboard');
      } catch (e) {
        return true;
      }
    }
    return true;
  }

  private async shouldGoToOrgInvitations(): Promise<boolean> {
    const orgInvitations = Object.values(await firstValueFrom(this.accountStateService.observeOrgInvitations()));
    const userOrganizations = Object.values(await firstValueFrom(this.organizationStateService.observeOrganizations()));
    const hasInvitationsForMe = orgInvitations.length > 0;
    const hasOrganizations = userOrganizations.length > 0;

    if (hasOrganizations || !hasInvitationsForMe) {
      this.organizationService.assignCurrentOrganizationId();
      trace('Assigned current organization', 'AccountService');
    }

    /** Check if we need to put up invitations UI because user has no orgs and they do have invitations.
     * Ordering is important: this must happen before assigning org id.
     * Process invitations after update user details to avoid race conditions. */
    return !hasOrganizations && hasInvitationsForMe;
  }
}
