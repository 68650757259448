<div class="forgot_password_container auth_page_container">
  <div *ngIf="forgotPasswordUiStateObs | async as uiState" class="container">
    <cw-auth-logo type="full" color="primary-dark"></cw-auth-logo>
    <ng-container *ngIf="!uiState.forgotPasswordEmailSent">
      <form (ngSubmit)="onSubmit()" [formGroup]="forgotPasswordForm"
            class="form">
        <div class="auth_intro">
          <div class="auth_title">Reset password</div>
        </div>
        <div class="intro_text">
          Enter the email address associated with your account and we'll send you a link to reset your password.
        </div>
        <div class="seed_form_element"
             [attr.data-error]="!forgotPasswordForm.controls['email'].valid && forgotPasswordForm.controls['email'].touched">
          <label>Email</label>
          <input
            galaxyClick="auth.passwordReset.emailInputFocus"
            galaxyBlur="auth.passwordReset.emailInputBlur"
            formControlName="email" name="email" type="email">
          <div
            *ngIf="!forgotPasswordForm.controls['email'].valid && forgotPasswordForm.controls['email'].touched"
            class="seed_error" data-cy="forgot-password-error">
            Invalid e-mail address
          </div>
          <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>
        </div>

        <div class="buttons">
          <button
            galaxyClick="auth.passwordReset.resetButtonSelect"
            [disabled]="forgotPasswordForm.invalid || uiState.sendForgotPasswordButtonDisabled"
            class="button clickui-primary-button"
            mat-flat-button
            data-cy="forgot-password-submit"
            type="submit">
            Send password reset link
          </button>
        </div>

        <a
          galaxyClick="auth.passwordReset.loginLinkSelect"
          [queryParams]="queryParams"
          class="back_to_sign_in_link link"
          data-cy="sign-in-link"
          routerLink="/signIn">
          <button class="button clickui-secondary-button" mat-flat-button>
            Back to sign in
          </button>

        </a>
      </form>
    </ng-container>
    <div *ngIf="uiState.forgotPasswordEmailSent" class="post_submission_text">
      <div class="form_container">
        <div class="auth_intro">
          <div class="auth_title">Check your email</div>
        </div>
        <mat-icon class="verify_email" svgIcon="verify_email"></mat-icon>
        <div class="text">
          We’ve sent a link to reset your password to <br/>
          <div class="email fs-exclude" data-cy="email">
            {{forgotPasswordForm.controls['email'].value}}
          </div>
        </div>
        <div class="info-sub-text">
          If you haven’t received an email in 5 minutes and couldn’t find it in your spam folder either, you might have
          registered with a different account.
        </div>
        <div class="buttons">
          <button
            galaxyClick="auth.passwordReset.resendPassword"
            class="button clickui-primary-button"
            mat-flat-button
            data-cy="resend-password-button"
            (click)="resendPasswordLink()">
            Resend password reset link
          </button>
          <a [queryParams]="queryParams" class="back_to_sign_in_link link" data-cy="sign-in-link" routerLink="/signIn">
            <button class="button clickui-secondary-button" mat-flat-button>
              Back to sign in
            </button>
          </a>
        </div>
      </div>
      <div class="bottom_links">
        <div class="sign_up_link">
          Don’t have an account yet? <a
          galaxyClick="auth.login.registerLinkSelect"
          [queryParams]="queryParams"
          class="link"
          data-cy="sign-up-link"
          routerLink="/signUp">
          Register
        </a>
        </div>
      </div>
    </div>
  </div>
</div>
