import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { isDefined } from '@cp/common/protocol/Common';
import { Organization } from '@cp/common/protocol/Organization';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { combineLatestWith, firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

/** Resolves after getting the initial user organizations list and setting a current organization. */
@Injectable({
  providedIn: 'root'
})
export class OrganizationsResolver implements Resolve<Record<string, Organization>> {
  constructor(private readonly organizationsStateService: OrganizationStateService) {}

  resolve(): Promise<Record<string, Organization>> {
    const organizations$ = this.organizationsStateService.observeOrganizations();
    const currentOrganizationId$ = this.organizationsStateService
      .observeCurrentOrganizationId()
      .pipe(filter(isDefined));
    return firstValueFrom(organizations$.pipe(combineLatestWith(currentOrganizationId$))).then(
      ([organizations, currentOrganizationId]) => {
        console.debug(
          'OrganizationsResolver:',
          Object.keys(organizations),
          ', current organization: ',
          currentOrganizationId
        );
        return organizations;
      }
    );
  }
}
