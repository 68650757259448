import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { isDefined } from '@cp/common/protocol/Common';
import { SupportCase, SupportCaseDetails } from '@cp/common/protocol/Support';
import { convertArrayToRecord } from '@cp/common/utils/MiscUtils';
import { SupportCasesState } from '@cp/web/app/support/support-protocol';
import { distinctUntilChanged, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupportCasesStateService extends BaseUiStateService<SupportCasesState> {
  constructor(stateService: StateService) {
    super(['supportCases'], stateService);
  }

  setSupportCases(cases: Array<SupportCase> | Record<string, SupportCase>): void {
    const organizationsRecord = Array.isArray(cases) ? convertArrayToRecord(cases) : cases;
    this.stateService.setInPath([...this.STATE_PATH, 'cases'], organizationsRecord);
  }

  setSupportCase(supportCase: SupportCase): void {
    this.stateService.setInPath([...this.STATE_PATH, 'cases', supportCase.id], supportCase);
  }

  deleteSupportCase(id: string) {
    this.stateService.deletePath([...this.STATE_PATH, 'cases', id]);
  }

  setCurrentCaseId(caseId: string | undefined) {
    const currentCaseId = this.getStateForKey('currentCaseId');
    if (currentCaseId === caseId) return;
    this.setStateKey('currentCaseId', caseId);
  }

  getCurrentCaseId() {
    return this.getStateForKey('currentCaseId');
  }

  clearSupportCases(): void {
    this.stateService.deletePath([...this.STATE_PATH]);
  }

  observeSupportCases(): Observable<Record<string, SupportCase>> {
    return this.stateService
      .observePath<Record<string, SupportCase>>([...this.STATE_PATH, 'cases'])
      .pipe(filter(isDefined));
  }

  observeSupportCase(caseId: string): Observable<SupportCaseDetails> {
    return this.stateService
      .observePath<SupportCaseDetails>([...this.STATE_PATH, 'cases', caseId])
      .pipe(filter(isDefined));
  }

  observeSupportCaseDetails(caseId: string): Observable<SupportCaseDetails> {
    return this.observeSupportCase(caseId).pipe(filter((supportCase) => supportCase.events?.length > 0));
  }

  observeCurrentSupportCaseId(): Observable<string | undefined> {
    return this.stateService
      .observePath<string | undefined>([...this.STATE_PATH, 'currentCaseId'])
      .pipe(distinctUntilChanged());
  }
}
