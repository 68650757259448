import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isDefined } from '@cp/common/protocol/Common';
import { OrganizationFeatureId, UserFeatureId } from '@cp/common/protocol/features';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AdminComponent } from '@cp/web/app/admin/admin.component';
import { DriftService } from '@cp/web/app/chatbot/drift.service';
import { FullyQualifiedEvent } from '@cp/web/app/common/services/galaxy.service';
import { trackByName } from '@cp/web/app/common/utils/AngularUtils';
import { OrganizationApiKeysPageComponent } from '@cp/web/app/organizations/api-keys/organization-api-key-page/organization-api-keys-page.component';
import { OrgActivityComponent } from '@cp/web/app/organizations/org-activity/org-activity.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { UsersPageComponent } from '@cp/web/app/organizations/users-page/users-page.component';
import { environment } from '@cp/web/environments/environment';
import { filter, map, Observable, switchMap, takeUntil } from 'rxjs';

type MenuItemName =
  | 'Services'
  | 'Users'
  | 'API Keys'
  | 'Admin'
  | 'Integrations'
  | 'Support'
  | 'Help'
  | 'Activity'
  | 'Learn'
  | 'Superpowers';

export interface MenuButton {
  name: MenuItemName;
  icon: string;
  link: string;
  event: FullyQualifiedEvent;
  /** The menu button is enabled only if userFeature OR organizationFeature is matched. */
  userFeature?: UserFeatureId;
  /** The menu button is enabled only if userFeature OR organizationFeature is matched. */
  organizationFeature?: OrganizationFeatureId;
  minRole?: OrganizationRole;
  expanded?: boolean;
  subNavs?: Array<Omit<MenuButton, 'icon' | 'subNavs' | 'event'>>;
}

@Component({
  selector: 'cp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [animate('200ms ease-out', style({ transform: 'translateX(-100%)' }))])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent extends OnDestroyComponent {
  inTrialObs: Observable<boolean>;
  mobileMenuVisible = false;

  readonly menuButtons: Array<MenuButton> = [
    { name: 'Services', icon: 'sidebar_services', link: '/services', event: 'leftbar.nav.servicesSectionFocus' },
    {
      name: 'Integrations',
      icon: 'sidebar_marketplace',
      link: '/integrations',
      event: 'leftbar.nav.integrationsSectionFocus'
    },
    { name: 'Users', icon: 'sidebar_members', link: '/organization/members', event: 'leftbar.nav.membersSectionFocus' },
    {
      name: 'API Keys',
      icon: 'sidebar_key',
      link: '/organization/keys',
      event: 'leftbar.nav.apiKeysSectionFocus'
    },
    {
      name: 'Activity',
      icon: 'sidebar_activities',
      link: '/organization/activity',
      minRole: 'ADMIN',
      event: 'leftbar.nav.activitySectionFocus'
    },
    { name: 'Admin', icon: 'sidebar_admin', link: '/organization/admin', event: 'leftbar.nav.adminSectionFocus' },
    {
      name: 'Help',
      icon: 'sidebar_support',
      link: '',
      event: 'leftbar.nav.helpSectionFocus',
      subNavs: [
        { name: 'Learn', link: '/learn' },
        { name: 'Support', link: '/support' }
      ]
    }
  ];

  readonly environmentText?: string;

  readonly trackByName = trackByName;

  constructor(
    private readonly driftService: DriftService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    organizationStateService: OrganizationStateService
  ) {
    super();
    switch (environment.stage) {
      case 'local':
        this.environmentText = 'Local Environment';
        break;
      case 'dev':
        this.environmentText = 'Dev Environment';
        break;
      case 'staging':
        this.environmentText = 'Staging Environment';
        break;
      default:
        this.environmentText = undefined;
        break;
    }
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        this.cd.markForCheck();
      });

    this.inTrialObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeTrialCommitmentState(id)),
      map((commitment) => commitment.commitmentType === 'TRIAL')
    );
  }

  chatWithSupport(): void {
    this.driftService.openChat();
  }

  toggleMobileMenu() {
    this.mobileMenuVisible = !this.mobileMenuVisible;
  }

  closeMobileMenu() {
    this.mobileMenuVisible = false;
  }

  async clickMenuItem(menuButton: MenuButton): Promise<void> {
    if (menuButton.subNavs) {
      if (menuButton.expanded) {
        // The menu button is already expanded, ignore this click.
        return;
      }
      menuButton.expanded = true;
      // When the menu item expands, its first sab nav should be selected.
      await this.router.navigateByUrl(menuButton.subNavs[0].link);
      return;
    }
    await this.router.navigateByUrl(menuButton.link);
  }

  isMenuButtonActive(name: MenuItemName): boolean {
    if (this.isNameActive(name)) {
      return true;
    }
    const menuItem = this.menuButtons.find((item) => item.name === name);
    return !!menuItem?.subNavs && menuItem.subNavs.findIndex((item) => this.isNameActive(item.name)) >= 0;
  }

  isMenuButtonExpanded(menuButton: MenuButton): boolean {
    const isExpanded = !!menuButton.subNavs && this.isMenuButtonActive(menuButton.name);
    menuButton.expanded = isExpanded;
    return isExpanded;
  }

  private isNameActive(name: MenuItemName): boolean {
    const url = this.router.url;
    switch (name) {
      case 'Services':
        return !!url.match(/^\/service\/.*/) || url === '/services';
      case 'Users':
        return UsersPageComponent.isPagePath(url);
      case 'API Keys':
        return OrganizationApiKeysPageComponent.isPagePath(url);
      case 'Admin':
        return AdminComponent.isPagePath(url);
      case 'Integrations':
        return !!url.match(/^\/integrations\/.*/) || url === '/integrations';
      case 'Support':
        return !!url.match(/^\/support\/.*/) || url === '/support';
      case 'Learn':
        return !!url.match(/^\/learn\/.*/) || url === '/learn';
      case 'Activity':
        return OrgActivityComponent.isPagePath(url);
      case 'Superpowers':
        return !!url.match('/superpowers');
    }
    return false;
  }

  isChatAvailable(): boolean {
    return this.driftService.isChatAvailableOnThisPage();
  }
}
