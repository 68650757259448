import { Injectable } from '@angular/core';
import { StateService } from '@cp/common-services/state/state.service';
import { isDefined } from '@cp/common/protocol/Common';
import { Instance } from '@cp/common/protocol/Instance';
import {
  Organization,
  OrganizationBillingStatus,
  OrganizationCommitment,
  OrganizationInvitation,
  OrganizationPaymentStateType,
  OrganizationRestrictions,
  OrganizationRole,
  OrganizationUser
} from '@cp/common/protocol/Organization';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { checkObjectEqualityByShallowCompare, convertArrayToRecord } from '@cp/common/utils/MiscUtils';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { OrganizationsState } from '@cp/web/app/organizations/protocol/OrganizationStates';
import { combineLatest, distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationStateService {
  private readonly STATE_PATH = ['organizations'];

  constructor(
    private readonly stateService: StateService,
    private readonly accountStateService: AccountStateService
  ) {}

  setStateKey<K extends keyof OrganizationsState & string>(key: K, value: OrganizationsState[K]): void {
    this.stateService.setKeyInPath<OrganizationsState>(this.STATE_PATH, key, value, true);
  }

  setPartialState(partialState: Partial<OrganizationsState>): void {
    this.stateService.setPartial<OrganizationsState>(this.STATE_PATH, partialState, true);
  }

  setOrganizations(organizations: Array<Organization> | Record<string, Organization>): void {
    const organizationsRecord = Array.isArray(organizations) ? convertArrayToRecord(organizations) : organizations;
    this.stateService.setInPath([...this.STATE_PATH, 'organizations'], organizationsRecord);
  }

  setOrganization(organization: Organization): void {
    this.stateService.setInPath([...this.STATE_PATH, 'organizations', organization.id], organization);
  }

  deleteOrganization(organizationId: string) {
    this.stateService.deletePath([...this.STATE_PATH, 'organizations', organizationId]);
  }

  observeOrganizations(): Observable<Record<string, Organization>> {
    return this.stateService
      .observePath<Record<string, Organization>>([...this.STATE_PATH, 'organizations'])
      .pipe(filter(isDefined), distinctUntilChanged());
  }

  observeOrganizationUsers(organizationId: string): Observable<Record<string, OrganizationUser>> {
    return this.stateService
      .observePath<Record<string, OrganizationUser>>([...this.STATE_PATH, 'organizations', organizationId, 'users'])
      .pipe(map((userMap) => (userMap && Object.keys(userMap).length ? userMap : {})));
  }

  observeInvitations(organizationId: string): Observable<Record<string, OrganizationInvitation>> {
    return this.stateService
      .observePath<Record<string, OrganizationInvitation>>([
        ...this.STATE_PATH,
        'organizations',
        organizationId,
        'invitations'
      ])
      .pipe(map((invitationMap) => (invitationMap && Object.keys(invitationMap).length ? invitationMap : {})));
  }

  observeOrganization(organizationId: string): Observable<Organization> {
    return this.stateService.observePath<Organization>([...this.STATE_PATH, 'organizations', organizationId]).pipe(
      filter((org) => org !== undefined),
      distinctUntilChanged()
    );
  }

  /**
   * Emits a new value every time 'currentOrganizationId' changes.
   * Never returns the same 'currentOrganizationId' twice in a row.
   */
  observeCurrentOrganizationId(): Observable<string | undefined> {
    return this.stateService
      .observePath<string>([...this.STATE_PATH, 'currentOrganizationId'])
      .pipe(distinctUntilChanged());
  }

  observeCurrentOrganization(): Observable<Organization> {
    return this.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.observeOrganization(id))
    );
  }

  observeCurrentOrganizationUser(): Observable<OrganizationUser> {
    return this.accountStateService.observeUserId().pipe(
      filter(isDefined),
      switchMap((userId) => {
        return this.observeCurrentOrganizationId().pipe(
          filter(isDefined),
          switchMap((currentOrganizationId) => {
            const orgUserPath = [...this.STATE_PATH, 'organizations', currentOrganizationId, 'users', userId];
            return this.stateService.observePath<OrganizationUser>(orgUserPath).pipe(filter(isDefined));
          })
        );
      })
    );
  }

  observeCurrentOrganizationRole(): Observable<OrganizationRole> {
    return this.observeCurrentOrganizationUser().pipe(
      distinctUntilChanged((prev, curr) => prev.role === curr.role),
      map((organizationUser) => organizationUser.role)
    );
  }

  observeRestrictions(organizationId: string): Observable<OrganizationRestrictions> {
    return this.observeOrganization(organizationId).pipe(
      filter(isDefined),
      distinctUntilChanged((prev, curr) => checkObjectEqualityByShallowCompare(prev.restrictions, curr.restrictions)),
      map((org) => org.restrictions)
    );
  }

  /** Returns an observable of a boolean indicating whether the organization can start a new service. */
  observeNewServiceRestriction(organizationId: string): Observable<boolean> {
    const numInstancesObs = this.stateService.observePath<Record<string, Instance>>(['instances', 'instances']).pipe(
      filter(isDefined),
      distinctUntilChanged((prev, curr) => Object.values(prev).length === Object.values(curr).length),
      map((instancesMap) => Object.values(instancesMap).length)
    );
    const orgRestrictionObs = this.observeRestrictions(organizationId);
    return combineLatest([orgRestrictionObs, numInstancesObs]).pipe(
      map(
        ([orgRestrictions, numInstances]) =>
          orgRestrictions.canCreateInstances && numInstances < orgRestrictions.maxInstanceCount
      ),
      distinctUntilChanged()
    );
  }

  observeTrialCommitmentState(organizationId: string): Observable<OrganizationCommitment> {
    return this.observeOrganization(organizationId).pipe(
      filter(isDefined),
      map((org) => org.cachedCommitmentState),
      filter((commitmentState) => !!commitmentState['TRIAL']),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev['TRIAL']) === JSON.stringify(curr['TRIAL'])),
      map((cachedCommitmentState) => {
        assertTruthy(cachedCommitmentState['TRIAL']); // This should never fail.
        return cachedCommitmentState['TRIAL'];
      })
    );
  }

  observeBillingStatus(organizationId: string): Observable<OrganizationBillingStatus> {
    return this.observeOrganization(organizationId).pipe(
      filter(isDefined),
      distinctUntilChanged((prev, curr) => prev.billingStatus === curr.billingStatus),
      map((org) => org.billingStatus)
    );
  }

  observePaymentState(organizationId: string): Observable<OrganizationPaymentStateType> {
    return this.observeOrganization(organizationId).pipe(
      filter(isDefined),
      distinctUntilChanged((prev, curr) => prev.paymentState === curr.paymentState),
      map((org) => org.paymentState)
    );
  }

  getCurrentOrgIdOrFail(): string {
    return truthy(this.getCurrentOrgId());
  }

  getCurrentOrgId(): string | undefined {
    return this.stateService.getStateInPath<string>([...this.STATE_PATH, 'currentOrganizationId']);
  }

  getCurrentOrgOrFail(): Organization {
    return truthy(
      this.stateService.getStateInPath<Organization>([
        ...this.STATE_PATH,
        'organizations',
        this.getCurrentOrgIdOrFail()
      ])
    );
  }

  clearOrganizations(): void {
    this.stateService.deletePath([...this.STATE_PATH, 'organizations']);
  }

  getOrganizations(): Record<string, Organization> {
    return truthy(
      this.stateService.getStateInPath<Record<string, Organization>>([...this.STATE_PATH, 'organizations'])
    );
  }

  switchOrganization(organizationId: string): void {
    localStorage.setItem('currentOrganizationId', organizationId);
    this.setStateKey('currentOrganizationId', organizationId);
  }
}
