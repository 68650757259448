import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Instance } from '@cp/common/protocol/Instance';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstancesResolverService implements Resolve<Record<string, Instance>> {
  constructor(private readonly instanceStateService: InstanceStateService) {}

  resolve(): Promise<Record<string, Instance>> {
    return firstValueFrom(this.instanceStateService.observeInstances());
  }
}
