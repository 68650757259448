import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import {
  CreateOpenapiKeyRequest,
  CreateOpenapiKeyResponse,
  DeleteOpenapiKeyRequest,
  OPENAPI_API_PATH,
  UpdateOpenapiKeyRequest
} from '@cp/common/protocol/OpenapiKey';

export type CreateOpenapiKeyOptions = Omit<CreateOpenapiKeyRequest, 'rpcAction'>;
export type UpdateOpenapiKeyOptions = Omit<UpdateOpenapiKeyRequest, 'rpcAction'>;

@Injectable({
  providedIn: 'root'
})
export class ApiKeysApiService {
  constructor(private readonly restService: RestService) {}

  async createKey(options: CreateOpenapiKeyOptions): Promise<CreateOpenapiKeyResponse> {
    const request: CreateOpenapiKeyRequest = { ...options, rpcAction: 'create' };
    return await this.restService.post<CreateOpenapiKeyResponse>(OPENAPI_API_PATH, request);
  }

  async updateKey(options: UpdateOpenapiKeyOptions): Promise<void> {
    const request: UpdateOpenapiKeyRequest = { ...options, rpcAction: 'update' };
    await this.restService.post(OPENAPI_API_PATH, request);
  }

  async deleteKey(keyId: string): Promise<void> {
    const request: DeleteOpenapiKeyRequest = { rpcAction: 'delete', keyId };
    await this.restService.post(OPENAPI_API_PATH, request);
  }
}
