<mat-dialog-content *ngIf="invitationsObs | async as invitations"
                    class="organization_invitation_dialog"
                    data-cy="organization_invitation-dialog"
>
  <ng-container *ngIf="hasInvitations(invitations)">
    <table mat-table [dataSource]="invitations" class="invitations_table" data-cy="invitation-table">
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>Invitations
          <div class="invite-border"></div>
        </th>
        <td *matCellDef="let element; typedBy: invitations" mat-cell>
          <div class="invite_header" data-cy="accept-invitations-org">{{element.organizationName}}</div>
          <p data-cy="accept-invitations-inviter">Invited by {{element.inviterEmail}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element; typedBy: invitations" mat-cell>
          <button *ngIf="!element.isExpired"
                  (click)="acceptInvitation(element.invitationKey)"
                  [disabled]="isAcceptButtonEnabled"
                  mat-flat-button
                  class="right_button accept_button"
                  data-cy="accept-invitation-button"
                  type="button"
                  color="primary"
          >Accept
          </button>
          <button *ngIf="!element.isExpired"
                  (click)="declineInvitation(element.invitationKey)"
                  [disabled]="false"
                  mat-stroked-button
                  class="right_button decline_button"
                  data-cy="decline-invitation-button"
                  type="button"
          >Decline
          </button>
          <button *ngIf="element.isExpired"
                  [disabled]="true"
                  mat-stroked-button
                  class="right_button"
                  data-cy="invitation-expired-button"
                  matTooltip="Please ask you organization administrator to resend your invitation"
                  type="button"
          >Invite Expired
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" [attr.data-cy-invitation]="row.organizationName"
          data-cy='invitation-table-row' mat-row></tr>
    </table>
  </ng-container>
</mat-dialog-content>
