import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { assertTruthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { MetricChartInput } from '@cp/web/app/metrics/metric-chart/metric-chart.component';
import { MetricSummaryInput } from '@cp/web/app/metrics/metric-summary/metric-summary.component';
import { MetricsService } from '@cp/web/app/metrics/metrics.service';
import { combineLatestWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

/** An instance metrics chart with a summary panel. */
@Component({
  selector: 'cp-metric-chart-with-summary',
  templateUrl: './metric-chart-with-summary.component.html',
  styleUrls: ['./metric-chart-with-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricChartWithSummaryComponent extends OnDestroyComponent implements OnInit, OnChanges {
  /** Metrics related chart parameters. */
  @Input() metric!: MetricChartInput;

  /** An instance id to show metrics for. */
  @Input() instanceId!: string;

  seriesData: Array<Array<[number, number]>> = [];

  summaryInput!: MetricSummaryInput;

  private readonly reportRefreshSubject = new Subject<void>();

  constructor(
    private readonly instanceMetricsService: MetricsService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();

    this.reportRefreshSubject
      .pipe(
        tap(() => {
          this.instanceMetricsService.refreshInstanceMetric(this.instanceId, this.metric.query).then();
        }),
        switchMap(() => {
          const instanceMetricObs = this.instanceMetricsService.observeInstanceMetric(
            this.instanceId,
            this.metric.query
          );
          const instanceMetricErrorObs = this.instanceMetricsService.observeInstanceMetricError(
            this.instanceId,
            this.metric.query
          );
          return instanceMetricObs.pipe(combineLatestWith(instanceMetricErrorObs));
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(([lastFetchedReport, error]) => {
        const isError = error !== undefined;
        const report = isError ? undefined : lastFetchedReport;
        this.summaryInput = { query: this.metric.query, report, isError };
        this.seriesData = report?.data || [];
        this.summaryInput.report = this.summaryInput.isError ? undefined : report;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    this.checkRequiredInputs();
  }

  ngOnChanges(): void {
    this.checkRequiredInputs();
    this.reportRefreshSubject.next();
  }

  refresh(): void {
    this.reportRefreshSubject.next();
  }

  private checkRequiredInputs(): void {
    assertTruthy(this.metric);
    assertTruthy(this.instanceId);
  }
}
