import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadSignatureDetails } from '@cp/common/protocol/Storage';
import { truthy } from '@cp/common/utils/Assert';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { SupportService } from '@cp/web/app/support/support.service';
import { UploadFilePair, UploadService } from '@cp/web/app/upload/upload.service';
import { BehaviorSubject, finalize } from 'rxjs';

interface DisplayProgress {
  progressBars: number;
  totalProgress: number;
  fileNames: string;
}

@Component({
  selector: 'cp-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadComponent {
  @Input()
  multipleFiles = true;

  @Input()
  maxFileSizeBytes = 209_715_200;

  @Input()
  uploadType: 'support' | 'import_to_clickhouse' = 'support';

  @Output()
  uploadStarted = new EventEmitter<void>();

  @Output()
  uploadFinished = new EventEmitter<Array<string>>();

  uploadStage: 'select_files' | 'upload_in_progress' | 'upload_complete' = 'select_files';

  readonly displayProgressSubject = new BehaviorSubject<DisplayProgress>({
    totalProgress: 0,
    fileNames: '',
    progressBars: 0
  });

  fileNames: string[] = [];

  constructor(
    private readonly supportService: SupportService,
    private readonly uploadService: UploadService,
    private readonly snackBar: MatSnackBar,
    private readonly instanceService: InstanceService
  ) {}

  inputChanged($event: Event): void {
    const files = ($event.target as HTMLInputElement).files;
    this.startUpload(files).then();
  }

  onDrop($event: DragEvent): void {
    $event.preventDefault();
    const files = ($event.dataTransfer as any).files;
    this.startUpload(files).then();
  }

  allowDrop($event: DragEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    ($event as any).dataTransfer.dropEffect = 'copy';
  }

  getBarsArray(displayProgress: DisplayProgress): boolean[] {
    const barArray: boolean[] = [];
    for (let i = 0; i < 8; i++) {
      barArray.push(i < displayProgress.progressBars);
    }
    return barArray;
  }

  removeFiles(fileInput: HTMLInputElement): void {
    this.uploadFinished.emit([]);
    this.uploadStage = 'select_files';
    this.fileNames = [];
    fileInput.value = '';
  }

  private async startUpload(files: FileList | null): Promise<void> {
    const filePairs: Array<UploadFilePair> = await this.convertToUploadFilePairs(files);
    if (!filePairs.length) return;

    const storageIds: Array<string> = [];
    this.uploadService
      .uploadFiles(filePairs)
      .pipe(
        finalize(() => {
          this.uploadFinished.emit(storageIds);
          this.uploadStage = 'upload_complete';
        })
      )
      .subscribe((result) => {
        const fileNamesArray: string[] = [];
        const progressArray: number[] = [];

        for (const progress of result.progress) {
          if (progress.progress > -1) {
            progressArray.push(progress.progress);
            fileNamesArray.push(progress.filePair.file.name);
          }
          if (result.isDone && progress.progress === 100) {
            storageIds.push(progress.filePair.signatureDetails.id);
          }
        }

        if (result.isDone) {
          const failedFiles = result.progress
            .filter((progress) => progress.progress === -1)
            .map((progress) => progress.filePair.file.name);
          if (failedFiles.length) {
            this.snackBar.open(`Some files failed uploading: ${failedFiles.join(', ')}`, 'Dismiss', { duration: 5000 });
          }
        }

        const minProgress = Math.min(...progressArray);
        this.fileNames = fileNamesArray;
        this.displayProgressSubject.next({
          progressBars: Math.floor((8 / 100) * minProgress),
          fileNames: this.fileNames.join(', '),
          totalProgress: minProgress
        });
      });

    this.uploadStage = 'upload_in_progress';
    this.uploadStarted.emit();
  }

  private async convertToUploadFilePairs(files: FileList | null): Promise<Array<UploadFilePair>> {
    if (!files) return [];
    console.log('Files', files);
    const filePairs: Array<UploadFilePair> = [];
    const filesExceedingLimit: string[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = truthy(files.item(i));
      if (file.size > this.maxFileSizeBytes) {
        filesExceedingLimit.push(file.name);
        continue;
      }
      let signatureDetails: UploadSignatureDetails;
      const mimeType = file.type || 'application/octet-stream';
      switch (this.uploadType) {
        case 'support':
          signatureDetails = await this.supportService.prepareSupportAttachmentUpload(file.name, mimeType);
          break;
        case 'import_to_clickhouse':
          signatureDetails = await this.instanceService.prepareImportFile(file.name, mimeType);
          break;
        default:
          throw new Error('UNSUPPORTED_UPLOAD_TYPE');
      }

      filePairs.push({ file, signatureDetails });
    }

    if (filesExceedingLimit.length) {
      const plural = filesExceedingLimit.length > 1;
      this.snackBar.open(
        `File${plural ? 's' : ''} ${filesExceedingLimit.join(', ')} exceed${!plural ? 's' : ''} the size limit`,
        'Dismiss',
        { duration: 5000 }
      );
    }
    return filePairs;
  }
}
