import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsInteraction } from '@cp/common/protocol/Galaxy';
import {
  GalaxyService,
  GalaxyTrackerData,
  GalaxyTrackerEventObj,
  getTracker
} from '@cp/web/app/common/services/galaxy.service';

type SeedSelectValue = {
  dataCyValue: string;
  value?: {
    id: string;
  };
};

type SeedTabValue = string;

type MatSelectValue = { value: string; dataCyValue: never };

type PossibleValues = SeedSelectValue | SeedTabValue | MatSelectValue;

@Directive({
  selector: '[galaxyChange]'
})
export class GalaxyTrackChangeDirective {
  @Input('galaxyChange') data!: GalaxyTrackerData;
  @Input() public input: any;

  private track!: (interaction: AnalyticsInteraction, data: GalaxyTrackerEventObj) => Promise<void>;

  constructor(private readonly galaxyService: GalaxyService) {
    this.track = getTracker(this.galaxyService);
  }

  extractValue(e: PossibleValues): string {
    if (typeof e === 'string' || e instanceof String) {
      return e.toString();
    } else if (e.value && typeof e.value === 'string') {
      return e.value;
    } else if (e.dataCyValue) {
      return e.dataCyValue;
    } else {
      throw new Error('Cannot extract value from object');
    }
  }

  getEvent() {
    return typeof this.data === 'string' ? this.data : this.data[0];
  }

  getProperties() {
    return (typeof this.data === 'string' ? {} : this.data[1]) || {};
  }

  @HostListener('selectionChange', ['$event'])
  onChange(e: PossibleValues) {
    const event = this.getEvent();
    const properties = this.getProperties();
    properties['value'] = this.extractValue(e);
    this.track('click', { event, properties });
  }
}
