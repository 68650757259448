<div *ngIf="userDetailsObs | async as userDetails" class="profile_container">
  <div class="side_padding">
    <div class="inner_page_nav">
      <div class="inner_page_title">Profile</div>
    </div>
    <div class="info_container">
      <div class="left">
        <div class="title">Personal info</div>
        <div class="description">Your email address is used to sign in and should we need to contact you.</div>
      </div>
      <div class="right">
        <div>
          <div class="seed_form_element">
            <label>Name</label>
            <div class="field_value fs-exclude">{{userDetails.name}}</div>
          </div>
          <div class="seed_form_element">
            <label>Email</label>
            <div class="field_value fs-exclude">{{userDetails.email}}</div>
          </div>
        </div>
        <button (click)="showUpdatePersonalInfoDialog()" class="edit_button" mat-flat-button>Update information</button>
      </div>
    </div>
    <ng-container *ngIf="isPasswordUpdateSupported(userDetails)">
      <div class="info_container">
        <div class="left">
          <div class="title">Password</div>
          <div class="description">Your password is used to sign in to the ClickHouse Cloud console.</div>
        </div>
        <div class="right">
          <div>
            <div class="seed_form_element">
              <label>Password</label>
              <div class="field_value">****************</div>
            </div>
          </div>
          <button (click)="showChangePasswordDialog()" class="edit_button" mat-flat-button>Edit</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isMfaUpdateSupported(userDetails)">
      <div class="info_container">
        <div class="left">
          <div class="title">Multi-factor authentication</div>
          <div class="description">Add an extra layer of security to your account. To sign in, you'll need to provide a
            code along with your email address and password.
          </div>
        </div>
        <div class="right">
          <div class="mfa_auth_card">
            <div>
              <mat-icon class="card_icon" svgIcon="mfa_totp_authenticator"></mat-icon>
            </div>
            <div class="mfa_auth_card_content">
              <div>
                <div class="label_wrapper">
                  <label>Authenticator app</label>
                </div>
                <p class="description">Generate one time security codes from an app like Authy, 1Password or Google
                  Authenticator
                </p>
              </div>
              <ng-container *ngIf="isTotpMfaActiveObs | async; else totpNotConfigured">
                <div class="mfa_configured_indicator_wrapper">
                  <mat-icon svgIcon="check_icon"></mat-icon>
                  <label>Configured</label>
                </div>
              </ng-container>

              <ng-template #totpNotConfigured>
                <button (click)="showTotpSetupDialog()" class="edit_button" mat-flat-button>Set up</button>
              </ng-template>
            </div>
            <div *ngIf="isTotpMfaActiveObs | async">
              <mat-icon (click)="showDisableMfaConfirmationDialog()" class="card_icon_small pointer"
                        svgIcon="glyph_trash_bin"></mat-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
