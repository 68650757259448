import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { isDefined } from '@cp/common/protocol/Common';
import { Instance, InstanceDatabaseAccessType, InstanceDatabaseAccessMapping } from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { combineLatest, filter, map, Observable, ReplaySubject, switchMap, takeUntil, tap } from 'rxjs';
import { OrganizationStateService } from '../../organizations/organization-state.service';
import { InstanceStateService } from '../instance-state.service';
import { InstanceService } from '../instance.service';

interface RoleDisplayInfo extends InstanceDatabaseAccessMapping {
  displayName: string;
  userCount: number;
}

const ROLE_DISPLAY_NAMES: Record<OrganizationRole, string> = {
  DEVELOPER: 'Developer',
  ADMIN: 'Admin'
};

@Component({
  selector: 'cp-instance-db-roles',
  templateUrl: './instance-db-roles.component.html',
  styleUrls: ['./instance-db-roles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceDbRolesComponent extends OnDestroyComponent implements OnInit, OnChanges {
  @Input()
  instanceId!: string;

  instance?: Instance;

  instanceIdSubject = new ReplaySubject<string>(1);

  databaseAccess?: ReadonlyArray<RoleDisplayInfo>;
  databaseAccessObs: Observable<ReadonlyArray<RoleDisplayInfo>>;

  readonly accessTypeOptions: ReadonlyArray<SeedSelectOption<InstanceDatabaseAccessType>> = [
    {
      label: 'No access',
      value: 'NO_ACCESS'
    },
    {
      label: 'Read only',
      value: 'READ_ONLY'
    },
    {
      label: 'Full access',
      value: 'FULL_ACCESS'
    }
  ] as const;

  constructor(
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly organizationStateService: OrganizationStateService
  ) {
    super();

    const roleMappingsObs = this.instanceIdSubject.pipe(
      switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId)),
      filter(isDefined),
      map((instance) => instance?.defaultDatabaseRoleMappings)
    );

    const usersObs = this.organizationStateService
      .observeCurrentOrganization()
      .pipe(map((organization) => organization.users));

    this.databaseAccessObs = combineLatest([roleMappingsObs, usersObs]).pipe(
      map(([roleMappings, users]) => {
        return roleMappings.map((roleMapping) => {
          const userCount = Object.values(users).reduce(
            (count, user) => (user.role === roleMapping.cpRole ? count + 1 : count),
            0
          );

          return {
            ...roleMapping,
            displayName: ROLE_DISPLAY_NAMES[roleMapping.cpRole],
            userCount
          };
        });
      }),
      tap((mappings) => (this.databaseAccess = mappings))
    );
  }

  ngOnInit(): void {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);

    this.instanceIdSubject
      .pipe(
        takeUntil(this.onDestroy),
        switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId))
      )
      .subscribe((instance) => {
        this.instance = instance;
      });
  }

  ngOnChanges() {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);
  }

  trackByRole(_: number, access: InstanceDatabaseAccessMapping): string {
    return access.cpRole;
  }

  getAccessForRole(role: OrganizationRole): InstanceDatabaseAccessType {
    const roleMapping = this.instance?.defaultDatabaseRoleMappings.find((mapping) => mapping.cpRole === role);
    return roleMapping?.databaseAccess ?? 'NO_ACCESS';
  }

  selectAccessType(role: OrganizationRole, newAccess: InstanceDatabaseAccessType): void {
    assertTruthy(this.databaseAccess);

    const newMappings: ReadonlyArray<InstanceDatabaseAccessMapping> = this.databaseAccess.map((mapping) => ({
      cpRole: mapping.cpRole,
      databaseAccess: role === mapping.cpRole ? newAccess : mapping.databaseAccess
    }));

    this.instanceService.updateInstanceDbRoleMapping(this.instanceId, newMappings);
  }

  formattedUserCount(roleInfo: RoleDisplayInfo): string {
    if (roleInfo.userCount === 1) {
      return `${roleInfo.userCount} user`;
    } else {
      return `${roleInfo.userCount} users`;
    }
  }
}
