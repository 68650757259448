<ng-container *ngIf="changePasswordForm">
  <mat-dialog-content *ngIf="changePasswordUiStateObs | async as uiState" class="change_password_dialog">
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
    <cp-dialog-title dialogTitle="Change Password"></cp-dialog-title>

    <form (ngSubmit)="onSubmit()" [formGroup]="changePasswordForm" class="form">
      <div class="seed_form_element">
        <label>Current password</label>
        <input #oldPasswordInput [type]="hideOldPassword ? 'password' : 'text'" formControlName="oldPassword"
               name="oldPassword">
        <button (click)="$event.preventDefault(); hideOldPassword = !hideOldPassword; oldPasswordInput.focus()"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hideOldPassword"
                class="seed_input_icon" mat-icon-button matSuffix>
          <mat-icon [svgIcon]="hideOldPassword ? 'show_password_icon' : 'hide_password_icon'"
                    class="password_reveal_icon"></mat-icon>
        </button>
        <div
          *ngIf="!changePasswordForm.controls['oldPassword'].valid && changePasswordForm.controls['oldPassword'].touched"
          class="seed_error">
          Invalid password entered
        </div>
      </div>

      <div class="seed_form_element new_password_container">
        <label>New Password</label>
        <input
          #passwordInput
          (blur)="showPasswordRequirements = false"
          (focus)="showPasswordRequirements = true"
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
          matInput
          name="password">
        <button (click)="$event.preventDefault(); hidePassword = !hidePassword; passwordInput.focus()"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hidePassword"
                class="seed_input_icon" mat-icon-button matSuffix>
          <mat-icon [svgIcon]="hidePassword ? 'show_password_icon' : 'hide_password_icon'"
                    class="password_reveal_icon"></mat-icon>
        </button>
        <cp-auth-password-requirements *ngIf="showPasswordRequirements"
                                       [password]="changePasswordForm.controls['password'].value"></cp-auth-password-requirements>
        <div *ngIf="!changePasswordForm.controls['password'].valid && changePasswordForm.controls['password'].touched"
             class="seed_error">
          Password does not meet all <a (click)="passwordInput.focus()" class="error_link">requirements</a>
        </div>
      </div>

      <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>


      <div class="vertical_buttons">
        <button [disabled]="changePasswordForm.invalid || uiState.changePasswordButtonDisabled" class="main_action"
                color="primary" mat-flat-button
                type="submit">
          Change Password
        </button>
        <button [mat-dialog-close]="false" class="secondary_action" mat-flat-button type="button">Cancel</button>
      </div>
    </form>
  </mat-dialog-content>

</ng-container>
