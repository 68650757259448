import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { UserFeatureId } from '@cp/common/protocol/features';
import { GalaxyEventType } from '@cp/common/protocol/Galaxy';
import {
  CloudProvider,
  idleTimeoutValuesMinutes,
  IDLING_TIMEOUT_MINUTES_DEFAULT,
  INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED,
  InstanceAutoscalingParams,
  InstanceTier
} from '@cp/common/protocol/Instance';
import { getAvailableRegionsByStage, RAW_COUNTRIES, Region, REGION_BY_ID } from '@cp/common/protocol/Region';
import { selectRegionsByNearestContinent } from '@cp/common/protocol/RegionUtils';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { timeoutMinutesToString } from '@cp/common/utils/FormatUtils';
import { randInt } from '@cp/common/utils/MathUtils';
import { getOrganizationCloudProviders } from '@cp/common/utils/OrganizationUtils';
import { MAX_INSTANCE_NAME_LENGTH, MIN_INSTANCE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import { AutoScalingSettings } from '@cp/cp-common-web/create-instance-auto-scaling-details';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { FullyQualifiedEvent, FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import {
  instanceNameValidator,
  maxLengthTrimmedValidator,
  minLengthTrimmedValidator
} from '@cp/web/app/common/utils/FormValidationUtils';
import { CreateInstanceFormDetails } from '@cp/web/app/instances/protocol/InstanceStates';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { environment } from '@cp/web/environments/environment';
import { map, Observable, takeUntil } from 'rxjs';

/** Enabled cloud providers + waiting list ones (Azure). */
type ExtendedCloudProvider = CloudProvider | 'AZURE';

export const IDLE_DISABLED = -1;

@Component({
  selector: 'cp-create-instance',
  templateUrl: './create-instance.component.html',
  styleUrls: ['./create-instance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CreateInstanceComponent extends OnDestroyComponent implements OnInit {
  @Input()
  defaultTier: InstanceTier = 'Production';

  @Input()
  defaultServiceName?: string;

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  readonly idlingTooltip = `Allow service to idle when not in active use.
Idling services when inactive saves on costs.`;

  readonly idleTimeoutToSelectOptions: Array<SeedSelectOption> = [
    {
      value: IDLE_DISABLED,
      label: 'Disable Idling',
      dataCy: 'idle-timeout-option',
      dataCyValue: 'DisableIdling'
    },
    ...idleTimeoutValuesMinutes.map((value) => ({
      value: value,
      label: `${timeoutMinutesToString(value)}`,
      dataCy: 'idle-timeout-option',
      dataCyValue: `${value}`
    }))
  ];

  readonly serviceTabLabels: InstanceTier[] = ['Development', 'Production'];
  readonly devServiceDetailsItems: string[] = [
    'Great for smaller workloads',
    'Up to 1 TB storage and 16 GB total memory',
    'Competitive monthly pricing'
  ];
  readonly productionServiceDetailsItems: string[] = [
    'Designed to handle larger production workloads',
    'Unlimited storage with 24 GB+ total memory',
    'Usage based pricing with spend limits'
  ];
  readonly form: FormGroup;
  gcpForced = false;
  showCloudProviderSelector = true;
  parentForm!: FormGroup;
  selectedProvider: ExtendedCloudProvider = ['AWS', 'GCP'][randInt(0, 2)] as ExtendedCloudProvider;
  successMessage?: string;
  /** All cloud providers available for the org. */
  availableCloudProviders: Array<CloudProvider> = [];
  /** All regions from all cloud providers available for the org. */
  private allAvailableRegions: Array<Region> = [];
  private currentInstanceTier!: InstanceTier;
  /** List of visible regions per cloud provider. Computed lazily. */
  private computedRegionOptions: Partial<Record<ExtendedCloudProvider, Array<SeedSelectOption<Region>>>> = {};
  /** Default regions per cloud provider. Computed lazily. */
  private computedDefaultRegion: Partial<Record<ExtendedCloudProvider, Region>> = {};
  readonly isIdlingShownObs: Observable<boolean>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly organizationStateService: OrganizationStateService,
    private readonly accountService: AccountService,
    private readonly accountStateService: AccountStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly segmentService: SegmentService,
    private readonly parent: FormGroupDirective
  ) {
    super();
    this.form = this.formBuilder.group({});
    this.isIdlingShownObs = this.accountStateService
      .observeUserDetails()
      .pipe(
        map((userDetails) => !!userDetails?.features && userDetails.features.includes('FT_IDLE_IN_CREATE_SERVICE'))
      );
  }

  ngOnInit(): void {
    this.currentInstanceTier = this.defaultTier;
    this.allAvailableRegions = [];
    this.form.addControl('provider', this.formBuilder.control('aws', [Validators.required]));
    this.form.addControl(
      'name',
      this.formBuilder.control(this.defaultServiceName || '', [
        Validators.required,
        minLengthTrimmedValidator(MIN_INSTANCE_NAME_LENGTH),
        maxLengthTrimmedValidator(MAX_INSTANCE_NAME_LENGTH),
        instanceNameValidator()
      ])
    );
    this.form.addControl('region', this.formBuilder.control(undefined, [Validators.required]));
    this.form.addControl('tier', this.formBuilder.control(this.defaultTier, [Validators.required]));

    this.isIdlingShownObs.pipe(takeUntil(this.onDestroy)).subscribe((isIdlingShown: boolean) => {
      if (isIdlingShown) {
        this.form.addControl(
          'idleTimeout',
          this.formBuilder.control(IDLING_TIMEOUT_MINUTES_DEFAULT, [Validators.required])
        );
      } else if (this.form.controls['idleTimeout']) {
        this.form.removeControl('idleTimeout');
      }
    });

    this.parentForm = this.parent.form;
    this.parentForm.addControl('instanceDetails', this.form);

    this.organizationStateService
      .observeCurrentOrganization()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((org) => {
        const useExperimentalRegions = this.accountStateService
          .getUserDetailsOrFail()
          .features.includes('NEW_DP_REGIONS');
        this.availableCloudProviders = getOrganizationCloudProviders(org);
        this.allAvailableRegions = getAvailableRegions(
          this.availableCloudProviders,
          useExperimentalRegions,
          org.regionsWhiteList
        );
        assertTruthy(
          this.allAvailableRegions.length > 0,
          () => `Computed regions list is empty, providers: ${this.availableCloudProviders.join(',')}`
        );

        this.gcpForced = org.features.includes('FT_GCP_ORG');
        this.showCloudProviderSelector = org.tackleState === undefined && !this.gcpForced;
        this.computedDefaultRegion = {};
        this.computedRegionOptions = {};
        if (!this.availableCloudProviders.includes(this.selectedProvider as CloudProvider)) {
          this.changeProvider(this.availableCloudProviders[0]);
        }
        this.cdr.markForCheck();
      });
    assertTruthy(this.eventPrefix);
  }

  async joinWaitlist(): Promise<void> {
    assertTruthy(this.selectedProvider === 'AZURE');
    await this.accountService.addUserToCloudWaitlist({ waitlistName: 'azure' });
    this.successMessage = `You joined the ${this.selectedProviderName} waitlist`;
    this.selectedProvider = this.availableCloudProviders[0];
    this.cdr.markForCheck();
  }

  changeProvider(selectedProvider: ExtendedCloudProvider): void {
    this.selectedProvider = selectedProvider;
    this.successMessage = undefined;
    this.form.patchValue({
      name: this.form.controls['name'].value,
      provider: selectedProvider,
      region: this.defaultRegion,
      idleTimeout: this.form.controls['idleTimeout']?.value
    });
    this.segmentService.trackGaEvent({
      event: 'click',
      label: `clicked on ${selectedProvider} provider`,
      category: 'cloud ui service',
      component: 'modal',
      view: 'auth'
    });
  }

  get selectedProviderName(): string {
    const capName = this.selectedProvider.toUpperCase();
    return capName === 'AZURE' ? 'Azure' : capName;
  }

  updateSelectionTier($event: string) {
    assertTruthy($event === 'Production' || $event === 'Development');
    this.currentInstanceTier = $event;
    this.computedRegionOptions = {};
  }

  get instanceRegionOptions(): Array<SeedSelectOption<Region>> {
    let regionOptions = this.computedRegionOptions[this.selectedProvider];
    if (!regionOptions) {
      assertTruthy(this.allAvailableRegions.length > 0, 'Available regions list is empty.');
      const visibleRegions = this.allAvailableRegions.filter(
        (region) =>
          region.cloudProvider === this.selectedProvider &&
          (this.currentInstanceTier !== 'Development' || region.isDevAvailable)
      );
      regionOptions = visibleRegions.map<SeedSelectOption<Region>>((region) => ({
        label: `${region.name} (${region.displayId})`,
        value: region,
        dataCy: 'region-option',
        dataCyValue: region.id
      }));
      this.computedRegionOptions[this.selectedProvider] = regionOptions;
    }
    return regionOptions;
  }

  /**
   * Returns default instance region based on the currently selected cloud provider & user country.
   * Returns 'undefined' only for 'Azure' provider that has no impl today.
   */
  get defaultRegion(): Region | undefined {
    assertTruthy(this.instanceRegionOptions.length > 0, 'Available region options list is empty.');
    let defaultOption = this.computedDefaultRegion[this.selectedProvider];
    if (!defaultOption) {
      const userCountryCode = this.accountStateService.getUserDetailsOrFail().countryCode || 'US';
      const userCountryItem = RAW_COUNTRIES.find((c) => c.code === userCountryCode);
      if (!userCountryItem) {
        defaultOption = this.instanceRegionOptions[0].value;
      } else {
        const activeRegions = this.instanceRegionOptions.map((o) => o.value);
        const continentRegions = selectRegionsByNearestContinent(
          userCountryItem.instanceContinent || userCountryItem.continent,
          activeRegions
        );
        const selectedRegionIndex = Math.floor(Math.random() * continentRegions.length);
        defaultOption = continentRegions[selectedRegionIndex];
      }
      this.computedDefaultRegion[this.selectedProvider] = defaultOption;
    }
    return defaultOption;
  }

  buildFullyQualifiedEvent(name: GalaxyEventType): FullyQualifiedEvent {
    return `${this.eventPrefix}.${name}`;
  }

  get serviceError(): string {
    const name = this.form.controls['name'];
    if (name.errors?.['required']) {
      return 'Service name is required.';
    }

    if (name.errors?.['minlength']) {
      return 'Service name must be at least 1 characters long.';
    }
    if (name.errors?.['maxLength']) {
      return 'Service name must be less than 51 characters long';
    }
    return 'Invalid service name entered';
  }

  get currentTier() {
    return this.currentInstanceTier ?? this.defaultTier;
  }
}

/** Returns list of regions available to create a new instance for the given organization state. */
function getAvailableRegions(
  cloudProviders: Array<CloudProvider>,
  useExperimentalRegions: boolean,
  whileListedRegionNames: string
): Array<Region> {
  const cloudProviderRegions = [...Object.values(REGION_BY_ID)]
    .filter((r) => cloudProviders.includes(r.cloudProvider))
    .map((r) => r.id);
  const whitelistedRegionNames = whileListedRegionNames.split(',').map((regionName) => regionName.trim());
  return getAvailableRegionsByStage(environment.stage)
    .map<Region>((regionId) => truthy(REGION_BY_ID[regionId]))
    .filter(
      (region) =>
        cloudProviderRegions.includes(region.id) &&
        (useExperimentalRegions || !region.isExperimental || whitelistedRegionNames.includes(region.name))
    );
}

export function getAutoscalingConfig(
  createInstanceForm: FormGroup,
  userFeatures: Array<UserFeatureId>
): Partial<InstanceAutoscalingParams> {
  const details = createInstanceForm.controls['instanceDetails'].value as CreateInstanceFormDetails;
  const autoScalingDetails = createInstanceForm.controls['autoScalingDetails'].value as AutoScalingSettings;
  const idlingInServiceExperience = userFeatures.includes('FT_IDLE_IN_CREATE_SERVICE');
  if (
    !idlingInServiceExperience &&
    (!INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(details.tier) || !autoScalingDetails)
  ) {
    return {};
  }
  const idleTimeoutMinutes = idlingInServiceExperience
    ? Math.max(details.idleTimeout, IDLING_TIMEOUT_MINUTES_DEFAULT)
    : autoScalingDetails.idleTimeout ?? IDLING_TIMEOUT_MINUTES_DEFAULT;
  const enableIdleScaling = idlingInServiceExperience
    ? details.idleTimeout !== IDLE_DISABLED
    : autoScalingDetails.enableIdleScaling;
  const minMaxParams: Partial<InstanceAutoscalingParams> = autoScalingDetails
    ? {
        minAutoScalingTotalMemory: autoScalingDetails?.minMemory,
        maxAutoScalingTotalMemory: autoScalingDetails?.maxMemory
      }
    : {};
  return { enableIdleScaling, idleTimeoutMinutes, ...minMaxParams };
}

export function checkInstanceTierAndRegionChanged(
  object1: { instanceDetails: CreateInstanceFormDetails },
  object2: { instanceDetails: CreateInstanceFormDetails }
): boolean {
  if (object1 === object2) return true;
  const instanceDetails1 = object1.instanceDetails;
  const instanceDetails2 = object2.instanceDetails;
  return instanceDetails1.tier === instanceDetails2.tier && instanceDetails1.region.id === instanceDetails2.region.id;
}
