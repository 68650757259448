import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { assertTruthy } from '@cp/common/utils/Assert';
import { AccountService } from '@cp/web/app/account/account.service';
import { ChangePasswordUiService } from '@cp/web/app/account/profile/change-password/change-password-ui.service';
import { ChangePasswordUiState } from '@cp/web/app/account/protocol/AccountStates';
import { validateFormPassword } from '@cp/web/app/common/utils/FormValidationUtils';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  changePasswordForm?: FormGroup;
  changePasswordUiStateObs: Observable<ChangePasswordUiState>;
  hideOldPassword = true;
  hidePassword = true;
  showPasswordRequirements = false;
  private readonly onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly changePasswordUiService: ChangePasswordUiService,
    private readonly accountService: AccountService,
    private readonly snackBar: MatSnackBar
  ) {
    this.changePasswordUiStateObs = changePasswordUiService.observeState().pipe(takeUntil(this.onDestroy));

    this.changePasswordUiService.setPartialState({
      changePasswordButtonDisabled: false,
      errorMessage: undefined
    });
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required], validateFormPassword]
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  async onSubmit(): Promise<void> {
    assertTruthy(this.changePasswordForm);
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changePasswordUiService.setPartialState({ changePasswordButtonDisabled: true, errorMessage: undefined });
    const details = this.changePasswordForm.value;
    try {
      await this.accountService.changePassword(details.oldPassword, details.password);
      this.snackBar.open('Password Changed', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e: any) {
      console.error(e);
      const cognitoCode = e?.code;
      let errorMessage: string;
      switch (cognitoCode) {
        case 'NotAuthorizedException':
          errorMessage = 'Current password is incorrect';
          break;
        case 'InvalidPasswordException':
          errorMessage = e?.message ? e.message : 'Password does not meet all requirements';
          break;
        default:
          errorMessage = 'Unable to change password, please try again later';
          break;
      }
      this.changePasswordUiService.setStateKey('errorMessage', errorMessage);
    } finally {
      this.changePasswordUiService.setStateKey('changePasswordButtonDisabled', false);
    }
  }
}
