import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isDefined, isTruthy } from '@cp/common/protocol/Common';
import { OPENAPI_MAX_KEYS_PER_ORGANIZATION, OpenapiKey } from '@cp/common/protocol/OpenapiKey';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { truthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { ConfirmationDialogComponent } from '@cp/web/app/common/components/confirmation-dialog/confirmation-dialog.component';
import { ApiKeysService } from '@cp/web/app/organizations/api-keys/api-keys-service';
import { ApiKeysStateService } from '@cp/web/app/organizations/api-keys/api-keys-state-service';
import { CreateApiKeyDialogComponent } from '@cp/web/app/organizations/api-keys/create-api-key-dialog/create-api-key-dialog.component';
import {
  EditApiKeyDialogComponent,
  EditApiKeyDialogComponentData
} from '@cp/web/app/organizations/api-keys/edit-api-key-dialog/edit-api-key-dialog.component';
import {
  checkIfPathBelongsToPage,
  installOrganizationIdPageParameterHandler,
  PageComponentWithOrganizationId
} from '@cp/web/app/organizations/current-organization.helper';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { Observable, of, switchMap, take } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface OrganizationContext {
  /** Current organization ID. */
  organizationId: string;
  /** Current user id. */
  userId: string;
  /** Role of the current user in the organization. */
  role: OrganizationRole;
}

@Component({
  templateUrl: './organization-api-keys-page.component.html',
  styleUrls: ['./organization-api-keys-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationApiKeysPageComponent extends OnDestroyComponent implements PageComponentWithOrganizationId {
  readonly buildCanonicalPagePathWithOrganizationId = (id: string) => `organizations/${id}/keys`;
  readonly organizationContext$: Observable<OrganizationContext>;
  readonly keys$: Observable<Array<OpenapiKey>>;

  constructor(
    readonly organizationStateService: OrganizationStateService,
    readonly apiKeysStateService: ApiKeysStateService,
    readonly apiKeysService: ApiKeysService,
    readonly accountStateService: AccountStateService,
    readonly route: ActivatedRoute,
    readonly router: Router,
    private readonly dialog: MatDialog
  ) {
    super();
    installOrganizationIdPageParameterHandler(this);
    this.organizationContext$ = this.organizationStateService.observeCurrentOrganizationId().pipe(
      switchMap((id) => (id ? this.organizationStateService.observeOrganization(id) : of(undefined))),
      filter(isDefined),
      map((organization) => {
        const userId = this.accountStateService.getUserIdOrFail();
        const role = truthy(organization.users[userId], 'Current user not found in the current organization!').role;
        return { organizationId: organization.id, userId, role };
      })
    );
    this.keys$ = this.organizationContext$.pipe(
      switchMap(({ organizationId }) => this.apiKeysStateService.observeOrganizationApiKeys(organizationId)),
      map((keysRecord) => Object.values(keysRecord))
    );
  }

  showCreateApiKeyDialog(orgContext: OrganizationContext): void {
    CreateApiKeyDialogComponent.show(this.dialog, orgContext);
  }

  /** Returns true if the given path part or the URL is handled by the current page component. */
  static isPagePath(path: string): boolean {
    return checkIfPathBelongsToPage(path, 'organizations', 'keys', '/organization/keys');
  }

  showEditKeyDialog(key: OpenapiKey, orgContext: OrganizationContext): void {
    const dialogData: EditApiKeyDialogComponentData = { role: orgContext.role, key };
    EditApiKeyDialogComponent.show(this.dialog, dialogData);
  }

  toggleKeyState(key: OpenapiKey): void {
    this.apiKeysService
      .updateOrganizationApiKey({
        keyId: key.id,
        state: key.state === 'enabled' ? 'disabled' : 'enabled'
      })
      .then();
  }

  showDeleteKeyDialog(key: OpenapiKey): void {
    ConfirmationDialogComponent.show(this.dialog, {
      title: `Delete API key "${key.name}"?`,
      text: 'If you delete this API key, all systems that use this API key will lose access to ClickHouse Cloud and will be unable to perform their usual operations.',
      action: 'Delete API key',
      buttonLayout: 'VERTICAL',
      buttonStyle: 'btn_danger'
    })
      .afterClosed()
      .pipe(take(1), filter(isTruthy))
      .subscribe(() => {
        this.apiKeysService.deleteOrganizationApiKey(key.id).then();
      });
  }

  isCreateNewKeyButtonDisabled(keys: Array<OpenapiKey>): boolean {
    return keys.length >= OPENAPI_MAX_KEYS_PER_ORGANIZATION;
  }
}
