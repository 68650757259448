import { State } from '@cp/common-services/state/action.applier';
import { StateService } from '@cp/common-services/state/state.service';
import { distinctUntilChanged, Observable } from 'rxjs';

export class BaseUiStateService<StateInterface extends State> {
  constructor(
    protected readonly STATE_PATH: Array<string>,
    protected readonly stateService: StateService
  ) {}

  observeState(): Observable<StateInterface> {
    return this.stateService.observePath<StateInterface>(this.STATE_PATH).pipe(distinctUntilChanged());
  }

  setStateKey<K extends keyof StateInterface & string, V extends StateInterface[K]>(key: K, value: V): void {
    this.stateService.setKeyInPath<StateInterface>(this.STATE_PATH, key, value, true);
  }

  deleteStateKey<K extends keyof StateInterface & string>(key: K): void {
    this.stateService.deletePath([...this.STATE_PATH, key]);
  }

  getStateForKey<K extends keyof StateInterface & string>(key: K): StateInterface[K] | undefined {
    return this.stateService.getStateInPath<StateInterface[K]>([...this.STATE_PATH, key]);
  }

  setPartialState(partialState: Partial<StateInterface>): void {
    this.stateService.setPartial<StateInterface>(this.STATE_PATH, partialState, true);
  }
}
