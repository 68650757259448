import { Injectable } from '@angular/core';
import { WebSocketService } from '@cp/common-services/web-socket.service';
import { CreateOpenapiKeyResponse, OpenapiEntityKeysUpdatePayload } from '@cp/common/protocol/OpenapiKey';
import {
  ApiKeysApiService,
  CreateOpenapiKeyOptions,
  UpdateOpenapiKeyOptions
} from '@cp/web/app/organizations/api-keys/api-keys-api.service';
import { ApiKeysStateService } from '@cp/web/app/organizations/api-keys/api-keys-state-service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { combineLatest, NEVER, of, switchMap } from 'rxjs';

export type CreateOrganizationOpenapiKeyOptions = Omit<CreateOpenapiKeyOptions, 'entityType'>;

@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {
  constructor(
    private readonly apiKeysApiService: ApiKeysApiService,
    private readonly apiKeysStateService: ApiKeysStateService,
    webSocketService: WebSocketService,
    organizationStateService: OrganizationStateService
  ) {
    organizationStateService
      .observeCurrentOrganizationId()
      .pipe(
        switchMap((organizationId) =>
          organizationId
            ? combineLatest([
                of(organizationId),
                webSocketService.observeNotification<OpenapiEntityKeysUpdatePayload>({
                  type: 'ORG_OPENAPI_ORG_KEY_UPDATE',
                  objId: organizationId
                })
              ])
            : NEVER
        )
      )
      .subscribe(([organizationId, message]) =>
        this.apiKeysStateService.setOrganizationApiKeys(organizationId, message.payload.keys)
      );
  }

  async createOrganizationApiKey(options: CreateOrganizationOpenapiKeyOptions): Promise<CreateOpenapiKeyResponse> {
    const response = await this.apiKeysApiService.createKey({ ...options, entityType: 'organization' });
    this.apiKeysStateService.setOrganizationApiKeys(options.entityId, response.keys);
    return response;
  }

  async updateOrganizationApiKey(options: UpdateOpenapiKeyOptions): Promise<void> {
    await this.apiKeysApiService.updateKey(options);
  }

  async deleteOrganizationApiKey(keyId: string): Promise<void> {
    await this.apiKeysApiService.deleteKey(keyId);
  }
}
