import { Injectable } from '@angular/core';

/**
 * A service that manages the session storage during the sign-in process.
 * Such a storage is needed because when the user signs in with a federated provider we cannot pass context to
 * our backends using Cognito triggers. In order to overcome this limitation, the context is set in a session storage
 * and removed when the sign-in page opens or once the user actually signs-in.
 *
 * In Auth0 mode this service should not be used because Auth0 supports passing a state (JSON object)
 * via sign-in/sign-up process (see 'appState'in Auth0WebAuthProvider).
 */
@Injectable({
  providedIn: 'root'
})
export class SignInStorageService {
  private readonly sessionStorage: Storage;

  constructor() {
    this.sessionStorage = window.sessionStorage;
  }

  setSignInInProgress() {
    this.sessionStorage.setItem('signIn-progress', '1');
  }

  clear() {
    console.debug('SignInStorageService:clear');
    this.sessionStorage.removeItem('signIn-oik');
    this.sessionStorage.removeItem('signIn-progress');
  }

  isSignInInProgress() {
    return !!this.sessionStorage.getItem('signIn-progress');
  }
}
