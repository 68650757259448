<div class="sign_in_container auth_page_container">
  <div class="auth_page_section">
    <div *ngIf="signInUiStateObs | async as uiState" class="container">
      <cw-auth-logo type="full" color="primary-dark"></cw-auth-logo>
      <form (ngSubmit)="onSubmit()" [formGroup]="signInForm" class="form">
        <div class="auth_intro">
          <div class="auth_title">Sign in</div>
        </div>

        <div *ngIf="!tackleMarketplace" class="social_buttons">
          <button
            galaxyClick="auth.login.googleAuthSelect"
            (click)="signInWithGoogle()"
            class="button social_button google_button"
            mat-flat-button
            type="button"
            data-cy="sign-in-with-google-button"
          >
            <mat-icon class="icon" svgIcon="google_logo"></mat-icon>
            Continue with Google
          </button>

          <div class="social_divider">
            <div class="divider"></div>
            <div class="or">OR</div>
          </div>

        </div>

        <div class="seed_form_element"
             [attr.data-error]="!signInForm.controls['email'].valid && signInForm.controls['email'].touched">
          <label>Email</label>
          <input
            data-cy="email-input"
            formControlName="email"
            galaxyClick="auth.login.emailInputFocus"
            galaxyBlur="auth.login.emailInputBlur"
            name="email"
            type="email">
          <div *ngIf="!signInForm.controls['email'].valid && signInForm.controls['email'].touched"
               class="seed_error">
            Invalid e-mail address
          </div>
        </div>

        <div class="seed_form_element"
             [attr.data-error]="!signInForm.controls['password'].valid && signInForm.controls['password'].touched">
          <label>Password</label>
          <input
            galaxyClick="auth.login.passwordInputFocus"
            galaxyBlur="auth.login.passwordInputBlur"
            data-cy="password-input"
            formControlName="password"
            name="password"
            type="password">
          <div
            *ngIf="!signInForm.controls['password'].valid && signInForm.controls['password'].touched"
            class="seed_error">
            Invalid password entered
          </div>
        </div>

        <cw-system-message
          *ngIf="uiState.errorMessage || uiState.successMessage" class="system_message"
          [messageType]="uiState.errorMessage ? 'error' : 'success'"
          [message]="uiState.errorMessage || uiState.successMessage">
        </cw-system-message>

        <div class="form-element sign-in-form-element">
          <button [disabled]="uiState.signInButtonDisabled"
                  class="button clickui-primary-button"
                  galaxyClick="auth.login.loginButtonSubmit"
                  data-cy="sign-in-button" mat-flat-button
                  type="submit">

            <div class="relative">
              <mat-icon *ngIf="uiState.signInButtonDisabled" class="button_loader_icon_absolute icon svg_stroke"
                        svgIcon="loader"></mat-icon>
              Sign In
            </div>

          </button>
        </div>

        <div class="forgot_password_link">
          Forgot your password? <a
          galaxyClick="auth.login.passwordResetLink"
          [queryParams]="queryParams"
          class="link"
          routerLink="/forgotPassword"
          data-cy="forgot-password-link">
          Reset
        </a>
        </div>
      </form>
      <div class="bottom_links">
        <div class="sign_up_link">
          Don’t have an account yet? <a
          galaxyClick="auth.login.registerLinkSelect"
          [queryParams]="queryParams"
          class="link"
          data-cy="sign-up-link"
          routerLink="/signUp">
          Register
        </a>
        </div>
      </div>
      <div class="recaptcha-error-container"></div>
    </div>
  </div>
</div>
