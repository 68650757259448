import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { SignInUiState } from '@cp/web/app/account/protocol/AccountStates';

@Injectable({
  providedIn: 'root'
})
export class SignInUiService extends BaseUiStateService<SignInUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'signIn'], stateService);
  }
}
