import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private readonly defaultTitle = 'ClickHouse Cloud';
  private readonly titleSuffix = ` • ${this.defaultTitle}`;
  private readonly pageUrlToTitle: Record<string, string> = {
    '/services': 'Services',
    '/organization/admin': 'Admin',
    '/organization/members': 'Members',
    '/profile': 'Profile',
    '/marketplace': 'Marketplace'
  };

  constructor(private titleService: Title) {}

  setTitleByUrl(pageUrl?: string): void {
    this.titleService.setTitle(
      pageUrl && this.pageUrlToTitle[pageUrl] ? this.pageUrlToTitle[pageUrl] + this.titleSuffix : this.defaultTitle
    );
  }

  setTitle(title: string): void {
    this.titleService.setTitle(title + this.titleSuffix);
  }
}
