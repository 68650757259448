import { HttpClient } from '@angular/common/http';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { AuthorizationParams as Auth0AuthorizationParams } from '@auth0/auth0-spa-js/dist/typings/global';
import {
  AuthEvent,
  AuthEventAuthenticated,
  AuthEventNotAuthenticated,
  SignInWithCredentialsResponse
} from '@cp/common-services/web-auth';
import { CognitoWebAuthProvider } from '@cp/common-services/web-auth/cognito-web-auth-provider';
import { WebAuthProvider } from '@cp/common-services/web-auth/web-auth-provider';
import { TotpAuthenticatorAppSetUpData } from '@cp/common/protocol/Account';
import { assertTruthy } from '@cp/common/utils/Assert';
import { AUTH0_MODE_QUERY_PARAM } from '@cp/common/utils/HttpUtils';
import { removeCookiesByNamePrefix } from '@cp/web/app/common/utils/CookieUtils';
import { removeLocalStorageKeysByKeyPrefix } from '@cp/web/app/common/utils/LocalStorageUtils';
import { environment } from '@cp/web/environments/environment';
import { firstValueFrom, from, Observable, of, switchMap, take } from 'rxjs';
import { share } from 'rxjs/operators';

interface Auth0MfaAssociateResponse {
  authenticator_type: string;
  secret: string;
  barcode_uri: string;
  recovery_codes: string[];
}

/** Auth0 representation of the MFA device. */
interface Auth0MfaDevice {
  id: string;
  authenticator_type: string;
  active: boolean;
}

interface Auth0CustomAuthParams {
  marketingParams?: Record<string, string>;
  idpConnection?: string;
}

/** Auth0 based implementation for WebAuthProvider. */
export class Auth0WebAuthProvider<AppState extends object, CustomAuthParams extends Auth0CustomAuthParams>
  implements WebAuthProvider<AppState, CustomAuthParams>
{
  isSelfHostedAuthProfileManagement = false;

  /**
   * Last seen app state from Auth0. Provided only after signinWithRedirect
   * and is not available for automatic sign-ins.
   */
  private appState?: AppState;

  /**
   * A shared source of Auth events.
   * We use a shared observable version instead of creation of a new observable in `observeAuthEvents()`,
   * so each Auth0 event is processed/transformed by Auth0WebAuthProvider only once.
   */
  private readonly authEvents$: Observable<AuthEvent>;

  private readonly cognitoAuth = new CognitoWebAuthProvider(true);

  constructor(
    private readonly auth0: Auth0Service<AppState>,
    private readonly httpClient: HttpClient
  ) {
    const buildNotAuthenticatedEvent = async (): Promise<AuthEventNotAuthenticated> => {
      console.debug('buildNotAuthenticatedEvent');
      this.appState = undefined;
      return { type: 'signOut' };
    };

    const buildAuthenticatedEvent = async (): Promise<AuthEventAuthenticated<AppState>> => {
      console.debug('buildAuthenticatedEvent', this.appState);
      return {
        type: 'signIn',
        accessToken: await firstValueFrom(this.auth0.getAccessTokenSilently()),
        // The following tokens are not used by Auth0 because are needed only for 'exchangeTokensForAuthorizationCode'
        // endpoint, and this endpoint won't be needed with Auth0: Auth0 will provide its implementation.
        idToken: 'not used by auth0',
        refreshToken: 'not used by auth0',
        accessTokenExpiration: 0,
        appState: this.appState
      };
    };
    this.authEvents$ = this.auth0.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => from(isAuthenticated ? buildAuthenticatedEvent() : buildNotAuthenticatedEvent())),
      share()
    );
  }

  startService(): void {
    this.auth0.appState$.subscribe((appState) => {
      console.debug('appState$', appState);
      return (this.appState = appState);
    });
  }

  changePassword(): Promise<void> {
    throw new Error('Must not be used with Auth0');
  }

  async confirmEmail(activationCode: string, userId: string): Promise<void> {
    // This function is called only with cognito confirmation email -
    // in this case we still want to confirm the email in order to let the user completing their authentication + migration
    //TODO #5540 Remove this and use cognitoAuthProvider explicitly when calling `confirmEmail`
    await this.cognitoAuth.confirmEmail(activationCode, userId);
  }

  confirmTotpSignIn(): Promise<void> {
    // Not used with Auth0: Auth0 handles MFA on their side.
    throw new Error('Must not be used with Auth0');
  }

  async disableTotpMfa(): Promise<void> {
    // List and delete all available MFA devices.
    const accessToken = await this.getAccessToken();
    const mfaDevices = await firstValueFrom(
      this.httpClient.get<Array<Auth0MfaDevice>>(
        // The method below requires 'read:authenticators' scope in the access token.
        `https://${environment.auth0ClientOptions.domain}/mfa/authenticators`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
    );
    console.debug(`Found ${mfaDevices.length} MFA devices to cleanup`);
    // User will have a few devices: usually 1 active, sometimes may be extra 3-4 inactive if
    // the QR code enrollment is not completed.
    // Auth0 won't block this loop with 429 for such a small number: checked for 10 devices in the list.
    for (const { id } of mfaDevices) {
      await firstValueFrom(
        this.httpClient.delete(
          // The method below requires 'remove:authenticators' scope in the access token.
          `https://${environment.auth0ClientOptions.domain}/mfa/authenticators/${id}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      );
    }
  }

  getAccessToken(): Promise<string | undefined> {
    return firstValueFrom(
      this.auth0.isAuthenticated$.pipe(
        switchMap((isAuthenticated) => (isAuthenticated ? this.auth0.getAccessTokenSilently() : of(undefined)))
      )
    );
  }

  async getTotpAuthenticatorAppSetupData(): Promise<TotpAuthenticatorAppSetUpData> {
    const accessToken = await firstValueFrom(this.auth0.getAccessTokenSilently());
    // The HTTP method requires 'enroll' scope in the access token.
    const response = await firstValueFrom(
      this.httpClient.post<Auth0MfaAssociateResponse>(
        `https://${environment.auth0ClientOptions.domain}/mfa/associate`,
        { authenticator_types: ['otp'] },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
    );
    return {
      secret: response.secret,
      qrCodeString: response.barcode_uri
    };
    // Auth0 creates a persistent non-confirmed OTP device here.
    // We need to clean up unconfirmed devices later if user does not confirm to avoid
    // a long list of dead data in the user profile.
    // One of the places where the cleanup is done is in 'disableTotpMfa' function.
  }

  observeAuthErrors(): Observable<string | undefined> {
    // Not used in 'auth0' mode.
    throw new Error('Must not be used with Auth0');
  }

  observeAuthEvents(): Observable<AuthEvent> {
    return this.authEvents$;
  }

  resetPassword(): Promise<void> {
    throw new Error('Must not be used with Auth0');
  }

  async signInWithCredentials(): Promise<SignInWithCredentialsResponse> {
    throw new Error('Not supported. Use signInWithRedirect');
  }

  async signInWithGoogle(): Promise<void> {
    throw new Error('Not supported. Use signInWithRedirect');
  }

  signinWithRedirect(screenHint: 'login' | 'signup', appState: AppState, customAuthParams?: CustomAuthParams): void {
    console.debug('signinWithRedirect is called with an app state', appState, 'custom auth params', customAuthParams);
    this.signInOrSignUp(screenHint, customAuthParams || {}, appState);
  }

  private signInOrSignUp(
    screenHint: 'login' | 'signup',
    customAuth0Params: Partial<CustomAuthParams>,
    appState: AppState
  ): void {
    const { marketingParams, idpConnection, ...otherParams } = customAuth0Params;

    const queryParams: Record<string, string> = {
      ...(marketingParams ?? {})
    };

    if (queryParams['referrer']) {
      delete queryParams['referrer'];
    }

    const searchParams = new URLSearchParams(queryParams);
    const redirectUri = `${window.location.origin}?${AUTH0_MODE_QUERY_PARAM}&${searchParams.toString()}`;

    const authorizationParams: Auth0AuthorizationParams = {
      ...(marketingParams ?? {}),
      ...otherParams,
      display: 'page',
      prompt: 'login',
      screen_hint: screenHint,
      redirect_uri: redirectUri,
      scope: 'openid profile email read:authenticators remove:authenticators enroll'
    };

    // currently we have 3 different connections configured in Auth0: google-oauth2, email, and Username/Password Authentication
    // Username/Password authentication connection is named 'ControlPlaneUserDb' in Auth0 and that is why we do this mapping here.
    // https://auth0.com/docs/api/authentication#social
    if (idpConnection === 'email') {
      authorizationParams.connection = environment.auth0DatabaseConnectionName;
    }

    this.auth0.loginWithRedirect({ authorizationParams, appState });
  }

  signOut(): Promise<void> {
    // openUrl: false is to avoid any Auth0 redirect. CP-Web does its own redirects on signout.
    return firstValueFrom(this.auth0.logout({ openUrl: false }));
  }

  async signUp(): Promise<string> {
    throw new Error('Not supported. Use signInWithRedirect');
  }

  async verifyTotpTokenAndActivateOtpDevice(otpCode: string): Promise<void> {
    // First confirm OTP enrollment: this will make the OTP device to have 'active' state in Auth0.
    const mfaAccessToken = await firstValueFrom(this.getMfaEnrollmentAccessToken());
    const response = await firstValueFrom(
      this.httpClient.post<{ access_token?: string }>(
        `https://${environment.auth0ClientOptions.domain}/oauth/token`,
        {
          grant_type: 'http://auth0.com/oauth/grant-type/mfa-otp',
          client_id: environment.auth0ClientOptions.clientId,
          mfa_token: mfaAccessToken,
          otp: otpCode
        },
        { headers: { Authorization: `Bearer ${mfaAccessToken}` } }
      )
    );
    assertTruthy(response.access_token, 'MFA verification failed');
  }

  /**
   *  To enroll an MFA device, we have to use a dedicated audience:  https://{domain}/mfa/.
   *  The enrollment won't work with any other audience, even with 'enroll' scope & consent from a user.
   */
  private getMfaEnrollmentAccessToken(): Observable<string | undefined> {
    // Since the audience is not the same used during user sign-in/sign-up, we have to show a consent screen to a user
    // in order to get the access token. The consent screen will be shown only once.
    return this.auth0
      .getAccessTokenWithPopup({
        authorizationParams: {
          scope: 'enroll',
          audience: `https://${environment.auth0ClientOptions.domain}/mfa/`
        }
      })
      .pipe(take(1));
  }
}

/** Disables auto-signin on application init. */
export function disableAutoSigninOnAppInitAuth0(): void {
  removeLocalStorageKeysByKeyPrefix('@@auth0spajs@@');
  removeCookiesByNamePrefix('auth0');
  removeCookiesByNamePrefix('_legacy_auth0');
}
