import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { assertTruthy } from '@cp/common/utils/Assert';

@Component({
  selector: 'cp-dialog-close-button',
  templateUrl: './dialog-close-button.component.html',
  styleUrls: ['./dialog-close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogCloseButtonComponent implements OnInit, OnChanges {
  @Input()
  dialogRef!: MatDialogRef<unknown>;

  @Input() width = 20;
  @Input() height = 20;
  @Input() fontSize = 20;
  @Input() top = 21;

  ngOnChanges(): void {
    assertTruthy(this.dialogRef);
  }

  ngOnInit(): void {
    assertTruthy(this.dialogRef);
  }
}
