import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { InstanceEndpoint } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { getBrowserPlatform } from '@cp/web/app/common/utils/BrowserUtils';
import { removeNewlinesAndBackslashesTransformer } from '@cp/web/app/common/utils/CopyTransformers';

@Component({
  selector: 'cp-native-endpoint-details',
  templateUrl: './native-endpoint-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NativeEndpointDetailsComponent implements OnInit, OnChanges {
  removeNewlinesAndBackslashesTransformer = removeNewlinesAndBackslashesTransformer;

  @Input()
  instanceEndpoint!: InstanceEndpoint;

  @Input()
  username!: string;

  @Input()
  password?: string;

  text?: string;
  params: Record<string, any> = {};

  ngOnInit(): void {
    assertTruthy(this.username);
  }

  ngOnChanges(): void {
    assertTruthy(this.username);

    this.params = {
      replacePasswordHere: this.password || ''
    };

    const clickhouseCommand = getBrowserPlatform() === 'linux' ? 'clickhouse' : './clickhouse';
    if (this.username === 'default') {
      this.text = `${clickhouseCommand} client --host ${this.instanceEndpoint.hostname} --secure --password`;
    } else {
      this.text = `${clickhouseCommand} client --host ${this.instanceEndpoint.hostname} --secure --user '${this.username}' --password`;
    }

    if (this.password) {
      // Concatenate the password to the native connection endpoint. If the password doesn't exist in memory keep it
      // blank so the user will be prompt to insert it when they copy this endpoint to their terminal.
      this.text += ` '$$$replacePasswordHere'`;
    }
  }
}
