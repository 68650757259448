import { AccountRpcRequest } from '@cp/common/protocol/Account';
import { TackleSubscriptionToken } from '@cp/common/protocol/Tackle';

export const EMPTY_SIGN_IN_METADATA_ID = 'empty-sign-in-metadata-id';

export type MarketingParamKey = `utm_${string}` | 'glxid' | 'gclid' | 'loc' | 'ad_group' | 'referrer';
export type MarketingParams = Partial<Record<MarketingParamKey, string>>;

export interface SignInOauthParams {
  /* camelCase oauth required params: response_type, client_id, redirect_uri */
  responseType: string;
  clientId: string;
  redirectUri: string;
  state?: string;
}

/**
 * Data model for the sign-in/sign-up time metadata.
 *
 * SignInMetadata is stored before sign-in (metadataId is generated) and can be successfully restored after the
 * sign-in (using the metadataId).
 *
 * The metadataId is either passed as a custom state string (federated sign in)
 * or is kept in the browser memory (local form sign in).
 */
export interface SignInMetadata {
  marketingParams?: MarketingParams;
  idpConnection?: string;
  tackleToken?: TackleSubscriptionToken;
  oauthParams?: SignInOauthParams;
  url?: string;
}

export interface StoreSignInMetadataRequest extends AccountRpcRequest<'storeSignInMetadata'> {
  metadata: SignInMetadata;
}

export interface StoreSignInMetadataResponse {
  metadataId: string;
}

export interface GetSignInMetadataRequest extends AccountRpcRequest<'getSignInMetadata'> {
  metadataId: string;
  userId?: string;
}

export interface GetSignInMetadataResponse {
  metadata: SignInMetadata;
}
