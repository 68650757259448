import { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import {
  AuthEvent,
  AuthEventAuthenticated,
  AuthEventType,
  SignInWithCredentialsResponse
} from '@cp/common-services/web-auth/index';
import { SysadminWebAuthProvider, WebAuthProvider } from '@cp/common-services/web-auth/web-auth-provider';
import { ClientMetadataKey, TotpAuthenticatorAppSetUpData } from '@cp/common/protocol/Account';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_MINUTE, MILLIS_PER_SECOND } from '@cp/common/utils/DateTimeUtils';
import { Trace, trace } from '@cp/web/app/common/services/galaxy.service';
import { removeLocalStorageKeysByKeyPrefix } from '@cp/web/app/common/utils/LocalStorageUtils';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { fromEvent, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

/** Cognito based implementation for WebAuthProvider. */
export class CognitoWebAuthProvider implements WebAuthProvider, SysadminWebAuthProvider {
  private serviceRunning = false;
  private currentUser: CognitoUser | null = null;
  isSelfHostedAuthProfileManagement = true;

  private readonly authEventSubject = new ReplaySubject<AuthEvent>(1);
  private readonly hostedUiAuthErrorSubject = new ReplaySubject<string | undefined>(1);

  /** Current 'refresh session' call in-flight. */
  private refreshSessionPromiseInFlight?: Promise<CognitoUserSession>;

  constructor(skipAuthEvents = false) {
    if (!skipAuthEvents) {
      this.authEventSubject
        .pipe(
          // Amplify will not auto sign out/sign-in when the user signs out/sign-in in other tabs - this code forces a session check when
          // the local storage entries change.
          switchMap(({ type: originalEventType }) =>
            fromEvent<StorageEvent>(window, 'storage').pipe(
              filter((storageEvent) => !!storageEvent.key?.startsWith('CognitoIdentityServiceProvider')),
              switchMap(() => of({ originalEventType }))
            )
          )
        )
        .subscribe(async ({ originalEventType }) => {
          try {
            const authEvent = await this.getAuthEventAuthenticated(originalEventType);
            this.authEventSubject.next({ ...authEvent });
          } catch (e) {
            console.info('Signing out due to localStorage change', e);
            await this.signOut();
          }
        });
    }
  }

  startService(): void {
    if (this.serviceRunning) {
      return;
    }
    // See https://docs.amplify.aws/guides/authentication/listening-for-auth-events/q/platform/js/
    // Expected auth event order:
    // Federated sign-in: parsingCallbackUrl, codeFlow, configured, signIn, cognitoHostedUI, customOAuthState, tokenRefresh…
    // Form sign-in: parsingCallbackUrl, configured, signIn…
    Hub.listen('auth', async (authEvent) => {
      console.debug('Cognito Hub.auth callback ', authEvent.payload?.event, authEvent);
      const payload = authEvent.payload;
      const type = payload.event as AuthEventType;
      switch (type) {
        case 'configured':
          // Once Auth Hub is configured (initialized) try refresh it to get tokens.
          // The 'refreshSession' will either fail or result into 'tokenRefresh' event.
          try {
            await this.refreshSession();
          } catch (ignored) {
            // The error is expected if there is no active user/session to refresh.
            // The result state is 'not authenticated'
            this.authEventSubject.next({ type });
          }
          break;
        case 'signIn':
        case 'cognitoHostedUI':
        case 'tokenRefresh': {
          const authEventAuthenticated = await this.getAuthEventAuthenticated(type);
          this.authEventSubject.next({ ...authEventAuthenticated });
          break;
        }
        case 'signOut':
          this.authEventSubject.next({ type });
          break;
        case 'cognitoHostedUI_failure':
          this.hostedUiAuthErrorSubject.next((authEvent as any).payload?.data?.message);
          break;
        case 'customOAuthState':
          const authEventAuthenticated = await this.getAuthEventAuthenticated(type);
          this.authEventSubject.next({
            ...authEventAuthenticated,
            type: 'customOAuthState',
            signInMetadataId: payload.data
          });
          break;
      }
    });
    this.serviceRunning = true;
  }

  @Trace({ skipTraceResult: true })
  async getAuthEventAuthenticated(type: AuthEventType): Promise<AuthEventAuthenticated> {
    const session = await this.currentSession();
    const accessTokenObj = session.getAccessToken();
    const accessToken = truthy(accessTokenObj.getJwtToken());
    const accessTokenExpiration = truthy(accessTokenObj.getExpiration());
    const refreshToken = truthy(session.getRefreshToken().getToken());
    const idToken = truthy(session.getIdToken().getJwtToken());

    return {
      type,
      accessToken,
      accessTokenExpiration,
      refreshToken,
      idToken
    };
  }

  observeAuthEvents(): Observable<AuthEvent> {
    return this.authEventSubject;
  }

  observeAuthErrors(): Observable<string | undefined> {
    return this.hostedUiAuthErrorSubject;
  }

  async getAccessToken(): Promise<string | undefined> {
    try {
      const currentSession = await this.currentSession();
      const expiryTimestamp = currentSession.getAccessToken().getExpiration() * MILLIS_PER_SECOND;
      const millisToStayValid = expiryTimestamp - Date.now();
      if (millisToStayValid > 2 * MILLIS_PER_MINUTE) {
        return currentSession.getAccessToken().getJwtToken();
      } else {
        trace('Token is expired. Refreshing to obtain the correct token', 'CognitoWebAuthProvider');
        const newSession = await this.refreshSession();
        return newSession.getAccessToken().getJwtToken();
      }
    } catch (error) {
      if (error !== 'No current user') {
        // 'No current user' is not an error: the method can return undefined.
        console.error('getAccessToken failed!', error);
      }
      // Warning: can't sign out here: MFA auth can be in-progress.
      return undefined;
    }
  }

  private async signInAndCreateResponse(
    username: string,
    password: string,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ) {
    this.currentUser = await Auth.signIn(username, password, clientMetadata);
    console.debug('CognitoWebAuthProvider::signInAndCreateResponse: user', this.currentUser);
    const userId = this.currentUser?.getUsername() || '';
    if (this.currentUser?.challengeName === 'SOFTWARE_TOKEN_MFA') {
      return { isAwaitingTotpVerification: true, userId };
    }
    return { userId };
  }

  async signInWithCredentials(
    username: string,
    password: string,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ): Promise<SignInWithCredentialsResponse> {
    try {
      return this.signInAndCreateResponse(username, password, clientMetadata);
    } catch (e: any) {
      // See https://github.com/aws-amplify/amplify-js/issues/9140
      if (e?.name === 'QuotaExceededError') {
        console.error('Got QuotaExceededError from Amplify, clearing localStorage and retrying');
        window.localStorage.clear();
        return this.signInAndCreateResponse(username, password, clientMetadata);
      }
      throw e;
    }
  }

  async signUp(
    username: string,
    password: string,
    name: string,
    customAttributes?: Record<string, any>,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ): Promise<string> {
    const attributes: Record<string, any> = { name, email: username };
    for (const [attrKey, attrValue] of Object.entries(customAttributes || {})) {
      if (attrValue !== undefined) {
        attributes[`custom:${attrKey}`] = attrValue;
      }
    }
    const signupResult = await Auth.signUp({ username, password, attributes, clientMetadata });
    return signupResult.userSub;
  }

  async signOut(): Promise<void> {
    console.debug('CognitoWebAuthProvider::signOut: setting user to null');
    this.currentUser = null;
    await Auth.signOut();
  }

  async sendForgotPasswordEmail(
    email: string,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ): Promise<void> {
    await Auth.forgotPassword(email, clientMetadata);
  }

  async resetPassword(userId: string, recoveryToken: string, newPassword: string): Promise<void> {
    await Auth.forgotPasswordSubmit(userId, recoveryToken, newPassword);
  }

  async confirmEmail(activationCode: string, userId: string): Promise<void> {
    await Auth.confirmSignUp(userId, activationCode);
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  }

  async signInWithGoogle(clientMetadataId: string): Promise<void> {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: clientMetadataId });
  }

  async signInWithOkta() {
    await Auth.federatedSignIn({ customProvider: 'Okta' });
  }

  async currentSession(): Promise<CognitoUserSession> {
    return Auth.currentSession();
  }

  @Trace({ skipTraceResult: true })
  async currentAuthenticatedUser(): Promise<CognitoUser | any> {
    return Auth.currentAuthenticatedUser();
  }

  /**
   *  Refreshes the session and returns a new session.
   *  Throws error if the session can't be refreshed: a user is undefined/no active session to refresh.
   */
  @Trace({ skipTraceResult: true })
  private async refreshSession(): Promise<CognitoUserSession> {
    if (this.refreshSessionPromiseInFlight) {
      const start = Date.now();
      const result = await this.refreshSessionPromiseInFlight;
      const elapsedMs = Date.now() - start;
      trace('Session refresh', 'CognitoWebAuthProvider', { elapsedMs });
      return result;
    }

    const [session, authenticatedUser] = await Promise.all([this.currentSession(), this.currentAuthenticatedUser()]);

    this.refreshSessionPromiseInFlight = new Promise<CognitoUserSession>((resolve, reject) => {
      try {
        authenticatedUser.refreshSession(
          session.getRefreshToken(),
          async (error: any, newSession: CognitoUserSession) => {
            if (error) {
              console.error('Error while retrieving session in refresh session', error);
              trace('Error refreshing session', 'CognitoWebAuthProvider');
              await this.signOut();
              reject(error);
            } else {
              resolve(newSession);
            }
            this.refreshSessionPromiseInFlight = undefined;
          }
        );
      } catch (e) {
        this.refreshSessionPromiseInFlight = undefined;
        console.error('Error in refresh session', e);
        reject(e);
      }
    });

    return await this.refreshSessionPromiseInFlight;
  }

  @Trace()
  setPreferredMFA(
    user: CognitoUser | any,
    mfaMethod: 'TOTP' | 'SMS' | 'NOMFA' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
  ): Promise<string> {
    return Auth.setPreferredMFA(user, mfaMethod);
  }

  @Trace()
  setupTOTP(user: CognitoUser | any): Promise<string> {
    return Auth.setupTOTP(user);
  }

  async getTotpAuthenticatorAppSetupData(): Promise<TotpAuthenticatorAppSetUpData> {
    const user = await this.currentAuthenticatedUser();
    const secret = await this.setupTOTP(user);
    const qrCodeString = `otpauth://totp/${user.attributes.email}?secret=${secret}&issuer=ClickHouse`;

    return { secret, qrCodeString };
  }

  @Trace()
  async verifyTotpTokenAndActivateOtpDevice(token: string): Promise<void> {
    const user = await this.currentAuthenticatedUser();
    await Auth.verifyTotpToken(user, token);
    await this.setPreferredMFA(user, 'TOTP');
  }

  @Trace()
  async confirmTotpSignIn(token: string): Promise<void> {
    assertTruthy(this.currentUser, `Internal Error: can't confirm MFA token with no user`);
    await Auth.confirmSignIn(this.currentUser, token, 'SOFTWARE_TOKEN_MFA');
  }

  @Trace()
  async disableTotpMfa(): Promise<void> {
    const user = await this.currentAuthenticatedUser();
    await this.setPreferredMFA(user, 'NOMFA');
  }

  signinWithRedirect(): void {
    // Note: we can actually use this mode with Cognito too, but just do not need it now.
    throw new Error('Not supported');
  }
}

/** Disables auto-signin on application init. */
export function disableAutoSigninOnAppInitCognito(): void {
  removeLocalStorageKeysByKeyPrefix('CognitoIdentityServiceProvider');
}
