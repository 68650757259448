import { AuthProviderUserProfile, UserRestrictions } from '@cp/common/protocol/Account';
import { StageType } from '@cp/common/protocol/Common';
import { truthy } from '@cp/common/utils/Assert';

export type TestUserProfileName =
  /** A CP admin (Organization1) registered in Cognito. */
  | 'admin1'
  /** A CP admin (Organization2) registered in Cognito. */
  | 'admin2'
  /** A user registered in Cognito and not registered in CP. */
  | 'user1'
  /** A user registered in Cognito and listed as a user in CP admin2's org. */
  | 'user2'
  /** A user isn't registered in Cognito nor in CP. */
  | 'user3'
  /** A user isn't registered in Cognito nor in CP. */
  | 'user4';

export type TestUserProfile = AuthProviderUserProfile & {
  password: string;
  testUserProfileName: TestUserProfileName;
  restrictions?: Partial<UserRestrictions>;
};

export const TEST_USER_MAIL_DOMAIN_BY_STAGE: Record<StageType, string> = {
  // 'local' domain is used for 'npm run offline'.
  // mailosaur.net variant: aduec0c0.mailosaur.net. Not using because of daily limits.
  local: 'clickhouse.testinator.com',
  dev: 'ejwxt8ay.mailosaur.net',
  staging: '1l1zhjsd.mailosaur.net',
  production: 'waeczrup.mailosaur.net'
};

export function getTestUsers(
  stage: StageType,
  isAuth0Mode: boolean,
  isAuth0E2eTestMode = false
): Array<TestUserProfile> {
  const mailServerDomain = truthy(TEST_USER_MAIL_DOMAIN_BY_STAGE[stage]);
  return [
    {
      // A CP admin (Organization1) registered in Cognito.
      testUserProfileName: 'admin1',
      userId: getTestUserIdByStage(stage, 'admin1', isAuth0Mode, isAuth0E2eTestMode),
      sub: getTestUserIdByStage(stage, 'admin1', isAuth0Mode, isAuth0E2eTestMode),
      email: `e2e+a1@${mailServerDomain}`,
      password: 'set-by-cypress', // See getTestUserPassword.
      name: 'Tony Soprano',
      initials: 'TS'
    },
    {
      // A CP admin (Organization2) registered in Cognito.
      testUserProfileName: 'admin2',
      userId: getTestUserIdByStage(stage, 'admin2', isAuth0Mode, isAuth0E2eTestMode),
      sub: getTestUserIdByStage(stage, 'admin2', isAuth0Mode, isAuth0E2eTestMode),
      email: `e2e+a2@${mailServerDomain}`,
      password: 'set-by-cypress',
      name: 'Junior Soprano',
      initials: 'JS',
      restrictions: { canCreateOrganizations: true, maxOrganizationCount: 10 }
    },
    {
      // A user registered in Cognito and not registered in CP.
      testUserProfileName: 'user1',
      userId: getTestUserIdByStage(stage, 'user1', isAuth0Mode, isAuth0E2eTestMode),
      sub: getTestUserIdByStage(stage, 'user1', isAuth0Mode, isAuth0E2eTestMode),
      email: `e2e+u1@${mailServerDomain}`,
      password: 'set-by-cypress',
      name: 'Christopher Moltisanti',
      initials: 'CM'
    },
    {
      // A user registered in Cognito and listed as a user in CP admin2's org.
      testUserProfileName: 'user2',
      userId: getTestUserIdByStage(stage, 'user2', isAuth0Mode, isAuth0E2eTestMode),
      sub: getTestUserIdByStage(stage, 'user2', isAuth0Mode, isAuth0E2eTestMode),
      email: `e2e+u2@${mailServerDomain}`,
      password: 'set-by-cypress',
      name: 'Silvio Dante',
      initials: 'SD'
    },
    {
      // A user isn't registered in Cognito nor in CP.
      testUserProfileName: 'user3',
      userId: getTestUserIdByStage(stage, 'user3', isAuth0Mode, isAuth0E2eTestMode),
      sub: getTestUserIdByStage(stage, 'user3', isAuth0Mode, isAuth0E2eTestMode),
      email: `e2e+u3@${mailServerDomain}`,
      password: 'set-by-cypress',
      name: 'Paulie Gualtieri',
      initials: 'PG'
    },
    {
      // A user isn't registered in Cognito nor in CP.
      testUserProfileName: 'user4',
      userId: getTestUserIdByStage(stage, 'user4', isAuth0Mode, isAuth0E2eTestMode),
      sub: getTestUserIdByStage(stage, 'user4', isAuth0Mode, isAuth0E2eTestMode),
      email: `e2e+u4@${mailServerDomain}`,
      password: 'set-by-cypress',
      name: 'Vito Spatafore',
      initials: 'PG'
    }
  ];
}

function getTestUserIdByStage(
  stage: StageType,
  testUserProfileName: TestUserProfileName,
  isAuth0Mode: boolean,
  isAuth0E2eTestMode: boolean
): string {
  const mailServerDomain = truthy(TEST_USER_MAIL_DOMAIN_BY_STAGE[stage]);

  const testUserIds: Record<StageType, Record<TestUserProfileName, string>> = {
    local: {
      admin1: isAuth0Mode
        ? isAuth0E2eTestMode
          ? '6578db7c4c647ddb9b3c3c9e'
          : '64fb59ec1af55a38ae7062e9'
        : `e2e+a1@${mailServerDomain}`,
      admin2: isAuth0Mode
        ? isAuth0E2eTestMode
          ? '6578dd8aa952b20f42d39333'
          : '64fb77a9e2c29ce3479db370'
        : `e2e+a2@${mailServerDomain}`,
      user1: isAuth0Mode
        ? isAuth0E2eTestMode
          ? '6578ddf450a7dcd591894365'
          : '64fb77f6634c0df5fc61760a'
        : `e2e+u1@${mailServerDomain}`,
      user2: isAuth0Mode
        ? isAuth0E2eTestMode
          ? '6578de18f32d0230b83b6868'
          : '64fb781f13e441aa724e994c'
        : `e2e+u2@${mailServerDomain}`,
      user3: isAuth0Mode ? 'not-registered' : `e2e+u3@${mailServerDomain}`,
      user4: isAuth0Mode ? 'not-registered' : `e2e+u4@${mailServerDomain}`
    },
    dev: {
      admin1: 'f2df5b0d-7c38-4fec-acbb-d455517e6102',
      admin2: 'b13294bf-759c-4870-a8d1-76cbd5e17a2c',
      user1: `e2e+u1@${mailServerDomain}`,
      user2: 'f246fdee-0e89-4b01-96bc-1ccdb29d2d96',
      user3: `e2e+u3@${mailServerDomain}`,
      user4: `e2e+u4@${mailServerDomain}`
    },
    staging: {
      admin1: '9556f10f-c6d4-41d8-86fc-5b9a06659326',
      admin2: '52db0937-1b56-4bdb-830d-19144c6ca8f2',
      user1: `e2e+u1@${mailServerDomain}`,
      user2: '6c034137-dffd-40f6-904c-84dbd56f649d',
      user3: `e2e+u3@${mailServerDomain}`,
      user4: `e2e+u4@${mailServerDomain}`
    },
    production: {
      admin1: '7d570fee-f2e5-4b79-b438-ab73fb27704f',
      admin2: '0eb59df1-9cc8-4316-a39b-e5a39857f45f',
      user1: `e2e+u1@${mailServerDomain}`,
      user2: '2a24675d-d36d-41a0-82b4-420d12bdc2ba',
      user3: `e2e+u3@${mailServerDomain}`,
      user4: `e2e+u4@${mailServerDomain}`
    }
  };

  return testUserIds[stage][testUserProfileName];
}

/** Email suffix for internal clickhouse.com users. */
export const INTERNAL_USERS_EMAIL_SUFFIX = '@clickhouse.com';

/**
 * Returns true if the `email` is an internal user email: @clickhouse.com.
 * For such users, some extra functionality may be enabled, not available for regular users.
 * See function usages for details.
 */
export function isInternalUserEmail(email: string | undefined): boolean {
  return !!email && email.endsWith(INTERNAL_USERS_EMAIL_SUFFIX);
}

/**
 * Returns true if the `email` is a qa user email: @redwerk.com or @qawerk.com.
 * For such users, some extra functionality may be enabled, not available for regular users.
 * See function usages for details.
 */
export function isQaUserEmail(email: string | undefined): boolean {
  return (
    !!email &&
    (email.endsWith('@redwerk.com') || email.endsWith('@qawerk.com') || email.toLowerCase() === 'alexqawerk@gmail.com')
  );
}

/** Returns true if 'email' is sent by the E2E test user. */
export function isTestUser(email: string, stage: StageType): boolean {
  const testUsers = getTestUsers(stage, false); // We do not care if Auth0 mode or not here: we need only emails.
  const testUsersMailPrefix = `e2e+`;
  const testUsersMailSuffix = `@${TEST_USER_MAIL_DOMAIN_BY_STAGE[stage]}`;
  return testUsers.some(
    (u) => u.email === email || (email.startsWith(testUsersMailPrefix) && email.endsWith(testUsersMailSuffix))
  );
}

/**
 * Returns true if the email belongs to a test user and the user has a right to
 * enable/disable user/org and instance level features.
 */
export function isTestUserWithFeatureFlagsSupport(email: string, stage: StageType): boolean {
  return (
    isTestUser(email, stage) && (email.startsWith('e2e+a2') || email.startsWith('e2e+u2')) // Only 2 test users have feature admin rights.
  );
}

/** Returns sales recipient in case if feedback is sent by a test user. */
export function getSalesEmailOverrideForTestUsers(stage: StageType): string {
  return `sales-${stage}@${TEST_USER_MAIL_DOMAIN_BY_STAGE[stage]}`;
}

/** Returns request integration recipient in case if the request is sent by a test user. */
export function getRequestIntegrationEmailOverrideForTestUsers(stage: StageType): string {
  return `request-integration-${stage}@${TEST_USER_MAIL_DOMAIN_BY_STAGE[stage]}`;
}

/**
 * ID of the instance that has all metrics sets to 0.
 * Must be synchronized with 'mock-data-plane' module.
 */
export const TEST_INSTANCE_ID_WITH_ZERO_METRICS = '0bcb8899-1698-46d9-aa2e-60783b621107';

export const TEST_TERMS_OF_SERVICE_CONTRACT_ID = 'd2133a97-a23f-4c71-9edb-b0a447c1569d';
