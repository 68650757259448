<div
  [galaxyClick]="['integrationList.summary.integrationSelect', {integration: integration.name}]"
  class="card_wrapper"
  data-cy="integration-card-wrapper"
  (click)="onViewIntegrationClick()">
  <div class="card_header side_padding">
    <div class="logo" [style.background]="'url(' + integration.logo.url + ') center center no-repeat'"></div>
    <div class="card_name">{{integration.name}}</div>
    <span *ngIf="integration.readiness" class="readiness">
      {{getReadinessText(integration.readiness)}}
    </span>
  </div>
  <div class="card_body">{{integration.shortDescription}}</div>
  <div class="card_foot side_padding">
    <div [ngClass]="['support_tag', supportClassName]">
      {{supportLevel}}
    </div>
    <div class="integration_button"
         data-cy="goto-integration-button"
    >
      View Integration <mat-icon>arrow_right_alt</mat-icon>
    </div>
  </div>
</div>
