<div
  #slider
  class="slider_wrapper"
  [style.width.px]="sliderWidth"
  (pointerdown)="onPointerDown($event)"
  (pointerup)="finalizeHandleMovement($event)"
  (pointermove)="onPointerMove($event)">
  <div class="slider_bar_wrapper">
    <div *ngIf="convertIndexToPercentage(handlesDomain.min) as width"
         class="slider_bar background dashed"
         [style.width.%]="width"
    ></div>
    <div *ngIf="leftPercentage - convertIndexToPercentage(handlesDomain.min) as width"
         class="slider_bar background"
         [ngClass]="{disabled: disabled}"
         [style.width.%]="width"
    ></div>
    <div *ngIf="rightPercentage - leftPercentage as width"
         class="slider_bar foreground"
         [ngClass]="{disabled: disabled}"
         [style.width.%]="width"
    ></div>
    <div *ngIf="convertIndexToPercentage(handlesDomain.max) - rightPercentage as width"
         class="slider_bar background"
         [ngClass]="{disabled: disabled}"
         [style.width.%]="width"
    ></div>
    <div *ngIf="100 - convertIndexToPercentage(handlesDomain.max) as width"
         class="slider_bar background dashed"
         [style.width.%]="width"
    ></div>
  </div>
  <div class="handles_wrapper">
    <div class="handle"
         [ngClass]="{disabled: disabled}"
         [style.left.%]="leftPercentageStyle"
         (mousedown)="onHandleClick('left')"
    ></div>
    <div class="handle handle_right"
         [ngClass]="{disabled: disabled}"
         [style.left.%]="rightPercentageStyle"
         (mousedown)="onHandleClick('right')"
    ></div>
  </div>
  <div class="ticks_row">
    <div *ngFor="let tick of ticksLayouts" class="tick_wrapper">
      <div [ngClass]="{tick: true, out_of_range: isTickOutsideOfDomain(tick)}"></div>
      <div [ngClass]="{tick_label: true, out_of_range: isTickOutsideOfDomain(tick)}">
        <ng-container
          *ngTemplateOutlet="tickLabelTemplate || defaultTickLabel; context: {$implicit: tick.index}"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultTickLabel let-index>
  {{index}}
</ng-template>
