<div data-cy="form_container" data-cy="region-block-message">
  <div [ngClass]="{region_content: !showContinueButton}">
    <div class="auth_intro">
      <div class="auth_title">ClickHouse Cloud is not available in your country</div>
    </div>
    <div class="intro_text">
      Service creation is not available in your country, which means that you cannot use ClickHouse as a hosted database
      service.
    </div>
    <div class="intro_text">
      You can still use your account to gain access to training or to paid support for your self managed ClickHouse.
    </div>
    <div class="buttons">
      <button
        *ngIf="showContinueButton"
        class="button clickui-primary-button"
        color="primary"
        mat-flat-button
        data-cy="region-block-continue"
        (click)="continueClick.emit()">
        Continue Anyway
      </button>
      <a href="https://clickhouse.com/clickhouse" target="_blank">
        <button class="button clickui-secondary-button" mat-flat-button
                [ngClass]="{region_block_action: !showContinueButton}">
          Try open-source ClickHouse
          <mat-icon class="region_icon" svgIcon="external_link" width="16" height="16"></mat-icon>
        </button>
      </a>
    </div>
  </div>
</div>
