import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineClamp'
})
export class LineClampPipe implements PipeTransform {
  transform(text: string, numberOfLines?: number, numberOfCharacters?: number, stripNewlines = false): string {
    if (!text) {
      return text;
    }
    if (numberOfLines) {
      text = text.split('\n').slice(0, numberOfLines).join('\n');
    }
    if (numberOfCharacters) {
      text = text.split('').slice(0, numberOfCharacters).join('');
    }
    if (stripNewlines) {
      text = text.replace(/\n/g, ' ').replace(/ +/g, ' ');
    }
    return text.trim();
  }
}
