import { RpcRequest } from '@cp/common/utils/ProtocolUtils';

export const DRIFT_API_PATH = 'drift';

export interface GetDriftIdentityJwtRequest extends RpcRequest<'getDriftIdentityJwt'> {
  userId: string;
}

export interface GetDriftIdentityJwtResponse {
  userJwt: string;
  /** Expiration timestamp in seconds. */
  expiresIn: number;
}

export type DriftRpcAction = 'getDriftIdentityJwt';
