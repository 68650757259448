import { assertTruthy } from '@cp/common/utils/Assert';
import { checkArrayHasUniqueElements } from '@cp/common/utils/MiscUtils';

export type Validator<T> = ValueValidator<T> | ObjectValidator<T>;

/** Value validator is a function that asserts about value type & value. */
export type ValueValidator<ValueType> = (
  value: unknown,
  errorContextProvider?: ValidationContextProvider
) => asserts value is ValueType;

/**
 * Dedicated validator type for '$o'. Accepts an object with a correct type and checks cross-field rules.
 * Throws error if validation failed.
 */
export type ValueValidatorWithType<ValueType> = (
  value: ValueType,
  errorContextProvider?: ValidationContextProvider
) => void;

/**
 * Object validator is a compiler-time checked set of fields with value or object validators assigned per field.
 * If the optional '$o' field is provided the $o() is called after all fields validators are finished.
 * (see sendForgotPasswordEmail handler as an example of '$o').
 */
export type ObjectValidator<ObjectType> = {
  [key in keyof Required<ObjectType>]: Validator<ObjectType[key]>;
} & { $o?: ValueValidatorWithType<ObjectType> };

/**
 * Builds a readable context for error message during validation. Called only on errors.
 * It is recommended to use a function if evaluation of the result require compute time.
 */
export type ValidationContextProvider = (() => string) | string;

/**
 * Returns error message if the validation fails.
 * It is recommended to use a function if evaluation of the error message require compute time.
 */
export type ValidationErrorProvider = ((value: unknown) => string) | string;

export const noValidationContext: ValidationContextProvider = () => '';

export interface ValidateObjectConstraints {
  /** Makes validateObject() fail if 'value' has any properties not covered by the 'validator'. */
  failOnMissedValidators?: boolean;
}

/**
 *  Validates object using provided validator. Throws error if validation fails.
 *  Use 'validateArray' to validate arrays of object.
 */
export function validateObject<ObjectType>(
  value: unknown,
  validator: ObjectValidator<ObjectType>,
  validationContext = noValidationContext,
  constraints: ValidateObjectConstraints = {}
): asserts value is ObjectType {
  const ctx = (): string => {
    return deriveContext(validationContext);
  };
  const errorWithContext = (message: string): string => {
    const context = ctx();
    return context.length === 0 ? message : `${context} ${message}`;
  };
  assertTruthy(typeof value === 'object', () => errorWithContext(`is not an object: ${typeof value}`));
  assertTruthy(value !== undefined, () => errorWithContext(`is not defined`));
  assertTruthy(value !== null, () => errorWithContext(`is null`));
  assertTruthy(!Array.isArray(value), () => errorWithContext(`is an array.`));
  const validatorEntries = Object.entries(validator);
  if (constraints.failOnMissedValidators) {
    for (const objectFieldName in value) {
      assertTruthy(
        validatorEntries.some(([validatorFieldName]) => objectFieldName === validatorFieldName),
        errorWithContext(`property can't be validated: ${objectFieldName}`)
      );
    }
  }
  let $oValidator: ValueValidatorWithType<ObjectType> | undefined;
  for (const [fieldKey, fieldValidator] of validatorEntries) {
    assertTruthy(
      typeof fieldValidator === 'function' || (typeof fieldValidator === 'object' && fieldValidator !== null),
      () => `${ctx()}.${fieldKey} validator is not an object or a function: ${typeof fieldValidator}`
    );

    const fieldValue: unknown = (value as Record<string, unknown>)[fieldKey];
    const fieldCtx: ValidationContextProvider = () => `${ctx()}.${fieldKey}`;
    if (typeof fieldValidator === 'object') {
      assertTruthy(
        !Array.isArray(fieldValue),
        () => `${ctx()}.${fieldCtx()} use makeArrayValidator() to create a ValueValidator`
      );
      validateObject(fieldValue, fieldValidator, fieldCtx);
    } else {
      assertTruthy(typeof fieldValidator === 'function', () => `${ctx()}.${fieldCtx()} validator is not a function`);
      if (fieldKey === '$o') {
        $oValidator = fieldValidator; // Will be run last.
      } else {
        const validatorResult = (fieldValidator as ValueValidator<unknown>)(fieldValue, fieldCtx);
        assertTruthy(
          validatorResult === undefined,
          `Validator must asserts (void) but it returns a value: ${validatorResult}. Use $v()?`
        );
      }
    }
  }
  if ($oValidator) {
    $oValidator(value as unknown as ObjectType, validationContext);
  }
}

/** A shortcut to build new object validator. */
export function makeObjectValidator<ObjectType>(
  validator: ObjectValidator<ObjectType>,
  validationContext = noValidationContext
): ValueValidator<ObjectType> {
  return (o) => validateObject(o, validator, validationContext);
}

export interface ArrayConstraints<T = unknown> {
  minLength?: number;
  maxLength?: number;
  /**
   * If provided the array is validated to have only unique elements.
   * This function must return identity of the element. See checkArrayHasUniqueElements.
   */
  uniqueByIdentity?: (element: T) => string;
}

/** Validates array using provided elementValidator. Throws error if validation fails. */
export function validateArray<T>(
  value: unknown,
  elementValidator: Validator<T>,
  constraints: ArrayConstraints<T> = {},
  errorContextProvider = noValidationContext
): asserts value is Array<T> {
  const ctx = (mode: 'with-space-separator' | 'no-space-separator' = 'with-space-separator'): string => {
    const text = deriveContext(errorContextProvider);
    return text ? `${text}${mode === 'with-space-separator' ? ' ' : ''}` : '';
  };
  assertTruthy(Array.isArray(value), () => `${ctx()}value is not an array: ${value}`);
  const minLength = constraints.minLength ?? 0;
  const maxLength = constraints.maxLength ?? Infinity;
  assertTruthy(
    value.length >= minLength,
    () => `${ctx()}array length < minLength. Array length: ${value.length}, minLength: ${minLength}`
  );
  assertTruthy(
    value.length <= maxLength,
    () => `${ctx()}array length > maxLength. Array length: ${value.length}, maxLength: ${maxLength}`
  );
  if (constraints.uniqueByIdentity) {
    assertTruthy(
      checkArrayHasUniqueElements(value, constraints.uniqueByIdentity),
      () => `${ctx()}array contains non-unique elements`
    );
  }
  let i = 0;
  const elementErrorContextProvider: ValidationContextProvider = () => `${ctx('no-space-separator')}[${i}]`;
  for (; i < value.length; i++) {
    const element: unknown = value[i];
    if (typeof elementValidator === 'object') {
      assertTruthy(
        !Array.isArray(element),
        () => `${elementErrorContextProvider}: use makeArrayValidator() to create a ValueValidator`
      );
      validateObject(element, elementValidator, elementErrorContextProvider);
    } else {
      callValidator(element, elementValidator, elementErrorContextProvider);
    }
  }
}

/**
 *  Creates a validator that checks that array exists and have expected values.
 *  Both min/max length bounds are inclusive.
 */
export function makeArrayValidator<T>(
  elementValidator: Validator<T>,
  constraints: ArrayConstraints<T> = {}
): ValueValidator<Array<T>> {
  const { minLength, maxLength } = constraints;
  assertTruthy(
    (minLength ?? 0) <= (maxLength ?? Infinity),
    `minLength must be < maxLength! minLength ${minLength}, maxLength: ${maxLength}`
  );
  assertTruthy((minLength ?? 0) >= 0, `minLength must be a positive number: ${minLength}`);
  assertTruthy((maxLength ?? 0) >= 0, `maxLength must be a positive number: ${maxLength}`);
  return (array: unknown, errorContextProvider = noValidationContext): asserts array is Array<T> => {
    validateArray(array, elementValidator, constraints, errorContextProvider);
  };
}

/**
 *  Returns a validator that perform comparison by reference for the value before calling 'orValidator'.
 *  If comparison succeeds the created validator does not call the 'orValidator' and returns true.
 *  Used for null/undefined values support since the framework does not support meta-language with chaining
 *  of validators in declarative way: like Or(IsUndefined(), IsString()).
 *  In order to chain/compose validators in this framework a standard TypeScript function must be created (like this one).
 *
 *  Note: support for a validator chaining like Or(IsUndefined(), IsString()) requires to return and post-process
 *  validation results on user side. Current implementation with no chaining just throws an error from the active
 *  validator that reduces code size (removes boilerplate).
 *
 *  Throwing of errors in case of errors also simplifies type assertions: if a validator does not throw an error it
 *  makes a type assertion. This way assertion function are highly reusable in a regular code independently of
 *  validateObject/Array() calls: like assertString/assertBoolean, etc..
 */
export function valueOr<T>(expectedValue: T, orValidator: Validator<T>): Validator<T> {
  return (
    value: unknown,
    errorContextProvider: ValidationContextProvider = noValidationContext
  ): asserts value is T => {
    if (value === expectedValue) return;
    if (typeof orValidator === 'object') {
      validateObject(value, orValidator, errorContextProvider);
    } else {
      callValidator(value, orValidator, errorContextProvider);
    }
  };
}

/** Allows a value to be undefined. See valueOr for details. */
export function undefinedOr<T>(validator: Validator<T>): Validator<T | undefined> {
  return valueOr<T | undefined>(undefined, validator as Validator<undefined>);
}

/** Allows a value to be null. See valueOr for details. */
export function nullOr<T>(validator: Validator<T>): Validator<T | null> {
  return valueOr<T | null>(null, validator as Validator<undefined>);
}

/** Type of the checking function for v$. */
export type UnknownCheck = (v: unknown) => boolean;

/**
 * Relaxed type of the checking function for v$. Experimental.
 * Should be used with care because inside the check the input argument is 'any'.
 */
export type SafeAnyCheck = (v: any) => boolean;

/** Creates a new validator. The validator accepts the value as valid if 'check(value)' returns true. */
export function $v<T>(check: UnknownCheck, error?: ValidationErrorProvider): ValueValidator<T> {
  assertTruthy(typeof check === 'function', `"check" is not a function: ${check}`);
  return (value: unknown, context = noValidationContext): asserts value is T =>
    assertTruthy(check(value), () => {
      const ctxMessage = deriveContext(context);
      let errorContext = ctxMessage || 'Check is failed';
      if (!errorContext.endsWith(':')) {
        errorContext += ':';
      }
      const err = error ? deriveError(error, value) : undefined;
      const renderedValue = typeof value === 'object' ? '[object]' : `'${value}'`;
      const errorDetails = err || renderedValue;
      return `${errorContext} ${errorDetails}`;
    });
}

/** Returns validation context as a string. Calls contextProvider() if needed. */
export function deriveContext(contextProvider: ValidationContextProvider): string {
  return typeof contextProvider === 'string' ? contextProvider : contextProvider();
}

/** Returns validation error as a string. Calls errorProvider() if needed. */
export function deriveError(errorProvider: ValidationErrorProvider, value: unknown): string {
  return typeof errorProvider === 'string' ? errorProvider : errorProvider(value);
}

/**
 * Calls the validator.
 * Workaround for TS issue with assertion on genetic arrow function. See https://github.com/microsoft/TypeScript/issues/34523.
 */
function callValidator<T>(
  value: unknown,
  validator: ValueValidator<T>,
  errorContextProvider: ValidationContextProvider
): asserts value is T {
  validator(value, errorContextProvider);
}
