import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cp-authenticator-app-set-up-dialog-content',
  templateUrl: './authenticator-app-set-up-dialog-content.component.html',
  styleUrls: ['./authenticator-app-set-up-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthenticatorAppSetUpDialogContentComponent {
  @Output() tokenUpdate = new EventEmitter<string>();
  @Output() tokenConfirmation = new EventEmitter();
  @Input() token = '';
  @Input() qrCode = '';
  @Input() code = '';

  isEnterCodeManually = false;

  constructor() {}

  onClickEnterCodeManually() {
    this.isEnterCodeManually = !this.isEnterCodeManually;
  }

  onTokenUpdate(token: string) {
    this.tokenUpdate.emit(token);
  }

  onTokenConfirmation(): void {
    this.tokenConfirmation.emit();
  }
}
