<ng-container *ngIf="instance">
  <div class="section" *ngIf="(myRoleObs | async) === 'ADMIN'">
    <h4 class="section_header">General Settings</h4>

    <h5 class="setting_header">Service name</h5>
    <div class="setting_body">
      <div class="setting_description">
        The name used to easily identify your service on the service page
      </div>
      <div class="setting_controls">
        <button mat-stroked-button (click)="renameServiceClicked()" data-cy="rename-service-button">
          Rename service
        </button>
      </div>
    </div>

    <h5 class="setting_header">Password</h5>
    <div class="setting_body">
      <div class="setting_description">
        Password used to access the default user
      </div>
      <div class="setting_controls">
        <button mat-stroked-button (click)="resetPasswordClicked()" data-cy="reset-password-button"
                [disabled]="!!activeMaintenanceKind">
          Reset password
        </button>
      </div>
    </div>

    <div *ngIf="instance.instanceTier === 'Development' && !activeMaintenanceKind">
      <h5 class="setting_header">Upgrade to production</h5>
      <div class="setting_body">
        <div class="setting_description">
          Upgrade your service to remove storage and scaling restrictions
        </div>
        <div class="setting_controls"
             [matTooltipDisabled]="(isInstanceUpgradeDisabledObs | async)"
             [matTooltip]="'Please contact support to enable this feature'">
          <button
            mat-stroked-button
            [disabled]="(isInstanceUpgradeDisabledObs | async) === false"
            (click)="onUpgrade()"
            data-cy="upgrade-button">
            {{upgradeBtnLabel}}
          </button>

          <div *ngIf="instance.upgradeErrorMessage; else upgradeInProgress"
               class="upgrade_error">
            <p>{{getUpgradeDisplayErrorMessage(instance.upgradeErrorMessage)}}</p>
          </div>

          <ng-template #upgradeInProgress>
            <p *ngIf="instance.upgradeStatus === 'in_progress'"
               class="setting_description setting_controls_description">
              Service upgrade started. Go to
              the <a
              [routerLink]="'/services'">services page</a>.</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="section" [formGroup]="form">
    <h4 class="section_header">Scaling</h4>

    <div class="setting_body">
      <div class="setting_description">
        <h5 class="setting_header">Service size</h5>
        ClickHouse Cloud will automatically scale your total memory depending on your needs, use the controls below if
        you would like to override with precise limits.
        <a href="https://clickhouse.com/docs/en/manage/scaling/" target="_blank">Read more</a>
      </div>
      <div class="setting_controls">
        <div class="sizing">
          <div class="sizes">
            <div class="memory_sizes">
              <label for="memoryMin">
                Minimum replica size
              </label>

              <seed-select [options]="memoryOptions ?? []"
                           (selectionChange)="selectMinMemory($event.value)"
                           [label]="formatMinAllocation()"
                           [value]="form.controls['minMemoryGb'].value ?? 24"
                           [disabled]="!canBeScaled() || !autoScalingSettings || !memoryOptions || !canEditAutoscaling"
                           formControlName="minMemoryGb"
                           class="select control"
                           data-cy="min-memory-select"
                           id="memoryMin">
              </seed-select>

              <label for="memoryMax">
                Maximum replica size
              </label>

              <seed-select [options]="memoryOptions ?? []"
                           (selectionChange)="selectMaxMemory($event.value)"
                           [label]="formatMaxAllocation()"
                           [value]="form.controls['maxMemoryGb'].value ?? 24"
                           [disabled]="!canBeScaled() || !autoScalingSettings || !memoryOptions || !canEditAutoscaling"
                           formControlName="maxMemoryGb"
                           class="select control"
                           data-cy="max-memory-select"
                           id="memoryMax">
              </seed-select>

              <div class="button_row" *ngIf="canBeScaled() && hasMemoryChanges()">
                <button
                  mat-button
                  [disabled]="!canBeScaled() || !hasMemoryChanges()"
                  (click)="cancelMemoryChanges()"
                  data-cy="cancel-memory-changes">
                  Cancel
                </button>
                <button
                  mat-button
                  mat-flat-button
                  color="primary"
                  [disabled]="!canBeScaled() || !hasMemoryChanges()"
                  (click)="applyMemoryChanges()"
                  data-cy="apply-memory-changes">
                  Apply changes
                </button>
              </div>
            </div>

            <div class="separator">
              <mat-icon class="separator_icon" fontIcon="close"></mat-icon>
            </div>

            <div class="cluster_size">
              <label for="clusterSize">
                Cluster size
                <mat-icon
                  svgIcon="info_circle"
                  matTooltip="Controls the number of replicas in your service cluster. Please contact support to increase this setting (production only)"
                  matTooltipClass="multiline_tooltip"
                  class="info_icon"
                  >
                </mat-icon>
              </label>

              <seed-select
                [disabled]="true"
                [options]="[]"
                [label]="numReplicasFormatted"
                data-cy="cluster-size"
                id="clusterSize"
                class="select control"
                >
              </seed-select>
            </div>
          </div>

          <div class="sizing_explanation" *ngIf="form.controls['minMemoryGb'].value !== null && form.controls['maxMemoryGb'].value !== null && instance && dpNumReplicas !== undefined && canBeScaled()">
            Your service will automatically scale between
            <span class="paragraph_bold_regular">
              {{formatClusterMemoryAllocation(form.controls['minMemoryGb'].value, instance, dpNumReplicas)}}
            </span>
            and
            <span class="paragraph_bold_regular">
              {{formatClusterMemoryAllocation(form.controls['maxMemoryGb'].value, instance, dpNumReplicas)}}
            </span>
          </div>
        </div>

        <div *ngIf="(isPayingOrgObs | async) === false && canBeScaled()"
             class="trial_restrictions_message"
             data-cy="trial-restrictions-message">
          Trial users are limited to a maximum size of 360 GiB RAM. To scale your service to 720 GiB RAM, please
          <a (click)="openPaymentMethodDialog()" class="info_link">add your credit card details</a>.
        </div>
        <div *ngIf="isDevInstance()" class="trial_restrictions_message" data-cy="trial-restrictions-message">
          Automatic scaling is only available for Production services.
          <a href="https://clickhouse.com/docs/en/manage/scaling/" target="_blank" class="info_link">Learn more</a>
        </div>
        <div *ngIf="!isDevInstance() && !canBeScaled() && !activeMaintenanceKind"
             class="trial_restrictions_message"
             data-cy="trial-restrictions-message">
          Automatic scaling is not available for this service.
        </div>
      </div>
    </div>

    <div class="setting_body">
      <div class="setting_description">
        <h5 class="setting_header">Idling options</h5>
        After a period of inactivity, allow this service to idle. Idling services when inactive saves on costs. Services
        may take 20-30 seconds to resume after idling.
        <a href="https://clickhouse.com/docs/en/manage/scaling/#automatic-idling" target="_blank">Read more</a>
      </div>
      <div class="setting_controls">
        <label for="idleTimeout">
          Minimum idle timeout
        </label>
        <seed-select [options]="idleTimeoutOptions"
                     [value]="form.controls['idleTimeoutMinutes'].value"
                     [disabled]="!autoScalingSettings || !!activeMaintenanceKind || !canEditAutoscaling"
                     [label]="formatIdleTimeout()"
                     class="select control"
                     formControlName="idleTimeoutMinutes"
                     id="idleTimeout"
                     data-cy="idle-timeout-select">
        </seed-select>

        <div class="button_row">
          <button
            mat-button
            (click)="cancelIdleTimeoutChanges()"
            [disabled]="!hasIdleTimeoutChanges()"
            data-cy="cancel-idle-changes">
            Cancel
          </button>
          <button
            mat-button
            mat-flat-button
            color="primary"
            [disabled]="!hasIdleTimeoutChanges()"
            (click)="applyIdleTimeoutChanges()"
            data-cy="apply-idle-changes">
            Apply changes
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <h4 class="section_header" id="networking">Networking</h4>
    <cp-instance-security [instanceId]="instanceId"></cp-instance-security>
  </div>

  <div class="section" *ngIf="(customizeSqlConsoleAccessObs | async) && (myRoleObs | async) === 'ADMIN'">
    <h4 class="section_header" id="sql_console_access">SQL console access</h4>
    <cp-instance-db-roles [instanceId]="instanceId"></cp-instance-db-roles>
  </div>

  <div class="section">
    <h4 class="section_header">About this service</h4>

    <div class="about">
      <div class="about_item">
        <div class="about_field">Platform</div>
        <div class="about_value">
          {{regionName()}}
          <div class="cloud_provider_icon_box" *ngIf="regionIconName() as icon">
            <mat-icon [svgIcon]="icon">
            </mat-icon>
          </div>
        </div>
      </div>

      <div class="about_item">
        <div class="about_field">Version</div>
        <div class="about_value">ClickHouse {{instance.clickhouseVersion}}</div>
      </div>

      <div class="about_item">
        <div class="about_field">Created at</div>
        <div class="about_value">{{instance.creationDate | date}}</div>
      </div>

      <div class="about_item" *ngIf="(shouldShowIamRoleObs | async) && instance.cloudProvider !== 'gcp'">
        <div class="about_field">IAM Role</div>
        <div class="about_value">
          <div *ngIf="(iamRoleObs | async) as iamRole; else iamRoleLoading" class="iam_role">
            <div class="iam_role_name">
              {{iamRole}}
            </div>
            <mat-icon
              (click)="copyToClipboard(iamRole)"
              class="copy_icon"
              svgIcon="copy"
              title="Copy to clipboard">
            </mat-icon>
          </div>
          <ng-template #iamRoleLoading>
            <mat-spinner [diameter]="30"></mat-spinner>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
