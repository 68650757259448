import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { GALAXY_SESSION_ID_PARAM_NAME, GalaxyClient } from '@cp/common-services/galaxy/client';
import {
  AuthEvent,
  AuthEventAuthenticated,
  isAuthenticated,
  IWebAuthService,
  SignInWithCredentialsResponse
} from '@cp/common-services/web-auth';
import { WebAuthProvider } from '@cp/common-services/web-auth/web-auth-provider';
import { ClientMetadataKey, TotpAuthenticatorAppSetUpData } from '@cp/common/protocol/Account';
import { EMPTY_SIGN_IN_METADATA_ID, MarketingParamKey, MarketingParams } from '@cp/common/protocol/SignInMetadata';
import { TackleSubscriptionToken } from '@cp/common/protocol/Tackle';
import { truthy } from '@cp/common/utils/Assert';
import { normalizeEmail } from '@cp/common/utils/MiscUtils';
import { AccountApiService } from '@cp/web/app/account/account-api.service';
import { SignInStorageService } from '@cp/web/app/account/sign-in/sign-in-storage.service';
import { trace, Trace } from '@cp/web/app/common/services/galaxy.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import {
  parseTackleSubscriptionTokenFromUrlOnAppInit,
  TackleSubscriptionService
} from '@cp/web/app/common/services/tackle-subscription.service';
import { delay, Observable, Subject, take, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

export const WEB_AUTH_PROVIDER_TOKEN = new InjectionToken<WebAuthProvider>('webAuthProvider');

/**
 * CP Web application state is stored by Auth provider in signinWithRedirect()
 * and provided back to the app when the redirect is completed.
 */
export interface CpWebAuthProviderAppState {
  tackleToken: TackleSubscriptionToken | undefined;
  tracker?: MarketingParams;
}

/**
 * Custom parameters passed by CP to Auth0 with every signin/signup request.
 * The values must be strings.
 */
export interface CpWebCustomAuthParams {
  tackleTokenJson?: string;
  marketingParams?: MarketingParams;
  idpConnection?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IWebAuthService {
  constructor(
    @Inject(WEB_AUTH_PROVIDER_TOKEN)
    private readonly webAuthProvider: WebAuthProvider<CpWebAuthProviderAppState, CpWebCustomAuthParams>,
    private readonly accountApiService: AccountApiService,
    private readonly signInMetadataService: SignInMetadataService,
    private readonly router: Router,
    private readonly tackleSubscriptionService: TackleSubscriptionService,
    private readonly signInStorageService: SignInStorageService
  ) {
    this.initializeWebAuthProvider().then();

    // In case of federated sign-in process the metadata on 'customOAuthState' (when metadataId is available).
    // In case of form sign-in process the metadata on 'signIn' event using in-memory metadataId.
    this.observeAuthEvents().subscribe((event) => {
      // Old Cognito way to transfer sign-in state.
      if (event.type === 'customOAuthState') {
        // 'customOAuthState' contains signInMetadata for federated sign in.
        const { signInMetadataId } = event as AuthEventAuthenticated;
        if (signInMetadataId) {
          this.signInMetadataService.setMetadataId(signInMetadataId);
        }
        this.processSignInMetadata().then();
      }
      // New Auth0 way to transfer sign-in state.
      if (event.type === 'signIn') {
        const { appState } = event as AuthEventAuthenticated<CpWebAuthProviderAppState>;
        if (appState?.tackleToken) {
          this.signInMetadataService.setTackleToken(appState.tackleToken);
        }
        this.processSignInMetadata().then();
      }
    });
  }

  private parseMarketingParams(): MarketingParams | undefined {
    const marketingParams: MarketingParams = {};

    const searchParams = new URLSearchParams(window.location.search);
    const paramKeys: Array<MarketingParamKey> = ['loc', GALAXY_SESSION_ID_PARAM_NAME, 'gclid', 'ad_group'];
    for (const key of searchParams.keys()) {
      if (key.startsWith('utm_') || paramKeys.some((k) => k === key)) {
        const value = searchParams.get(key);
        if (value) {
          marketingParams[key as MarketingParamKey] = value;
        }
      }
    }

    marketingParams['referrer'] = document.referrer;
    marketingParams[GALAXY_SESSION_ID_PARAM_NAME] =
      marketingParams[GALAXY_SESSION_ID_PARAM_NAME] ?? GalaxyClient.getGalaxySessionId();

    return Object.keys(marketingParams).length > 0 ? marketingParams : undefined;
  }

  private async initializeWebAuthProvider(): Promise<void> {
    // Parse tackle token here, because we need to be able to sign out every time a token is found.
    const token = parseTackleSubscriptionTokenFromUrlOnAppInit();
    const forcedIdpConnection = new URLSearchParams(window.location.search).get('with');
    if (forcedIdpConnection) {
      this.signInMetadataService.setIdpConnection(forcedIdpConnection.toLowerCase());
    }
    const marketingParams = this.parseMarketingParams();

    if (marketingParams) {
      this.signInMetadataService.setMarketingParams(marketingParams);
    }

    if (token) {
      this.signInMetadataService.setTackleToken(token);
    }
    this.webAuthProvider.startService();
  }

  async getAccessToken(): Promise<string | undefined> {
    return this.webAuthProvider.getAccessToken();
  }

  observeAuthEvents(): Observable<AuthEvent> {
    return this.webAuthProvider.observeAuthEvents();
  }

  /**
   * Listens for auth state initiated by Cognito and redirects to the default '/' path once user is authenticated.
   * For example, if user signs-in in a separate tab all other tabs with also receive 'accessToken' (authenticated state).
   */
  subscribeRedirectToHomePageIfAlreadyAuthenticated(onDestroy: Subject<void>): void {
    this.observeAuthEvents()
      .pipe(
        filter(() => !this.signInStorageService.isSignInInProgress()),
        // Wait for 2 reasons:
        // 1. Do not concur with local in-flight signin/signup logic. After delay the component will be already destroyed
        // (onDestroy) and this subscription will become no-op.
        // 2. Allow authenticated state delivered to all other listeners first, before. Otherwise,
        // router guards still consider this redirect as non-authenticated and 'navigate' call does not work.
        delay(5000),
        filter(isAuthenticated),
        take(1),
        takeUntil(onDestroy)
      )
      .subscribe(() => {
        trace('Redirecting to home following auth event.', 'AuthService');
        this.router.navigate(['/']).then();
      });
  }

  observeAuthErrors(): Observable<string | undefined> {
    return this.webAuthProvider.observeAuthErrors();
  }

  async signInWithCredentials(
    email: string,
    password: string,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ): Promise<SignInWithCredentialsResponse> {
    const metadataId = await this.storeSignInMetadataIfNotEmpty();

    /** Normalize email before sending to auth provider */
    const normalizedEmail = normalizeEmail(email);

    trace('Sign in with credentials', 'AuthService', { metadataId });
    let result: SignInWithCredentialsResponse;

    /** First try signing in with normalized email.  If this fails, possibly due to case mismatch, then try again
     * with the email as the user entered it.
     */
    try {
      result = await this.webAuthProvider.signInWithCredentials(normalizedEmail, password, clientMetadata);
    } catch (e) {
      console.debug(`signInWithCredentials failed with normalized email, retrying original: ${email}`);
      result = await this.webAuthProvider.signInWithCredentials(email, password, clientMetadata);
    }

    if (result.isAwaitingTotpVerification) {
      trace('Awaiting Totp verification', 'AuthService');
      return result;
    }

    trace('Signed in with credentials', 'AuthService', { userId: result.userId });
    return result;
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await this.webAuthProvider.changePassword(oldPassword, newPassword);
  }

  async signUp(
    email: string,
    password: string,
    name: string,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ): Promise<string> {
    const recaptchaToken = truthy(clientMetadata?.recaptchaToken, 'INVALID_RECAPTCHATOKEN');
    const metadataId = await this.storeSignInMetadataIfNotEmpty();

    /** Normalize email before sending to auth provider */
    const normalizedEmail = normalizeEmail(email);

    trace('signUp: metadataId', 'AuthService', { metadataId });
    await this.accountApiService.createUserPrototypeByEmail(normalizedEmail, name, metadataId, recaptchaToken);
    this.signInMetadataService.setMetadataId(metadataId);
    let clientMetadataWithSignInKey = clientMetadata || {};
    let customAttributesWithMetadataId = {};
    if (metadataId !== EMPTY_SIGN_IN_METADATA_ID) {
      clientMetadataWithSignInKey = { ...clientMetadataWithSignInKey, signInMetadataId: metadataId };
      customAttributesWithMetadataId = { signInMetadataId: metadataId };
    }

    return await this.webAuthProvider.signUp(
      normalizedEmail,
      password,
      name,
      customAttributesWithMetadataId,
      clientMetadataWithSignInKey
    );
  }

  async resetPassword(userId: string, recoveryToken: string, newPassword: string): Promise<void> {
    await this.webAuthProvider.resetPassword(userId, recoveryToken, newPassword);
  }

  async signOut(): Promise<void> {
    console.debug('AuthService::signOut');
    await this.webAuthProvider.signOut();
    console.debug('AuthService::signOut DONE');
  }

  async confirmEmail(activationCode: string, userId: string): Promise<void> {
    await this.webAuthProvider.confirmEmail(activationCode, userId);
  }

  async signInWithGoogle(): Promise<void> {
    const metadataId = await this.storeSignInMetadataIfNotEmpty();
    trace('signInWithGoogle: metadataId', 'AuthService', { metadataId });
    await this.webAuthProvider.signInWithGoogle(metadataId);
  }

  /**
   * Stores sign-in metadata in MongoDB if present.
   * Returns stored db record id or EMPTY_SIGN_IN_METADATA_ID if nothing was stored.
   */
  @Trace()
  private async storeSignInMetadataIfNotEmpty(): Promise<string> {
    const { metadata } = this.signInMetadataService;
    console.log(`Store sign in metadata: `, metadata);
    const metadataId = await this.accountApiService.storeSignInMetadata(metadata);
    this.signInMetadataService.setMetadataId(metadataId);
    return this.signInMetadataService.metadataId;
  }

  getTotpAuthenticatorAppSetupData(): Promise<TotpAuthenticatorAppSetUpData> {
    return this.webAuthProvider.getTotpAuthenticatorAppSetupData();
  }

  async verifyTotpTokenAndActivateOtpDevice(token: string): Promise<void> {
    await this.webAuthProvider.verifyTotpTokenAndActivateOtpDevice(token);
  }

  async confirmTotpSignIn(token: string): Promise<void> {
    await this.webAuthProvider.confirmTotpSignIn(token);
  }

  async disableTotpMfa(): Promise<void> {
    await this.webAuthProvider.disableTotpMfa();
  }

  /**
   * Processes current sign-in metadata (creates pending actions, etc..).
   * Called after user is signed in.
   */
  @Trace()
  async processSignInMetadata(): Promise<void> {
    trace('processSignInMetadata: metadataId', 'AuthService', this.signInMetadataService.metadata);

    if (this.signInMetadataService.isProcessed()) {
      trace('processSignInMetadata: already processed', 'AuthService');
      return;
    }

    const accessToken = await this.webAuthProvider.getAccessToken();

    if (!accessToken) {
      trace('processSignInMetadata: no valid access token', 'AuthService');
      return;
    }

    trace('processSignInMetadata: creating pending actions', 'AuthService', this.signInMetadataService.metadata);

    try {
      await this.tackleSubscriptionService.createPendingUserActionFromSignInMetadata();
    } finally {
      this.signInMetadataService.markSignInMetadataAsProcessed();
    }
  }

  signinWithRedirect(screenHint: 'login' | 'signup'): void {
    const { tackleToken, marketingParams, idpConnection } = this.signInMetadataService;

    // The app state will be returned back to the application after the redirect is complete.
    const appState: CpWebAuthProviderAppState = { tackleToken };

    // Tackle token will be stored in the user metadata and may be accessed later when needed.
    // This way we transfer tackle state via registration with non-confirmed email: even if email is confirmed some
    // time later, the tackle token will be preserved and still available during the CP (Mongo) user creation.
    const customAuthParams: CpWebCustomAuthParams = {
      tackleTokenJson: tackleToken && JSON.stringify(tackleToken)
    };

    if (marketingParams) {
      customAuthParams.marketingParams = marketingParams;
    }

    if (idpConnection) {
      customAuthParams.idpConnection = idpConnection;
    }

    this.webAuthProvider.signinWithRedirect(screenHint, appState, customAuthParams);
  }

  get isSelfHostedAuthProfileManagement(): boolean {
    return this.webAuthProvider.isSelfHostedAuthProfileManagement;
  }
}
