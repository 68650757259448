import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControlStatus,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { Observable, takeUntil } from 'rxjs';
import { InstanceService } from '../instance.service';

export interface CustomerManagedEncryptionFormValues {
  keyArn: string;
  assumedRoleArn: string;
}

@Component({
  selector: 'cp-customer-managed-encryption-key-details',
  templateUrl: './customer-managed-encryption-key-details.component.html',
  styleUrls: ['./customer-managed-encryption-key-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomerManagedEncryptionKeyDetailsComponent)
    }
  ]
})
export class CustomerManagedEncryptionKeyDetailsComponent extends OnDestroyComponent implements ControlValueAccessor {
  @Output() changeValid = new EventEmitter<boolean>();

  private onTouched?: () => void;
  private onChange?: (val: typeof this.form.value) => void;
  keyArnValidationStatus: Observable<FormControlStatus | null>;

  form = this.formBuilder.nonNullable.group(
    {
      keyArn: [''],
      assumedRoleArn: ['']
    },
    {
      updateOn: 'blur',
      asyncValidators: async (): Promise<ValidationErrors | null> => {
        if (!this.form.value.keyArn) {
          if (!this.form.value.assumedRoleArn) {
            return null;
          } else {
            return { roleWithNoKey: true };
          }
        }

        const result = await this.instanceService.verifyCustomerKeyConfig({
          keyArn: this.form.value.keyArn ?? '',
          assumedRoleArn: this.form.value.assumedRoleArn ?? ''
        });
        return result.valid ? null : { keyFailedValidation: true };
      }
    }
  );

  constructor(
    private formBuilder: FormBuilder,
    private instanceService: InstanceService
  ) {
    super();
    this.form.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((values) => {
      this.onChange?.(values);
    });

    this.keyArnValidationStatus = this.form.statusChanges;

    this.form.statusChanges.pipe(takeUntil(this.onDestroy)).subscribe((status) => {
      this.changeValid.emit(status === 'VALID');
    });
  }

  registerOnChange(fn: (val: typeof this.form.value) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: unknown) {
    if (value) {
      this.form.patchValue(value, { emitEvent: false });
    } else {
      this.onChange?.(this.form.value);
    }
  }
}
