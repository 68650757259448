import { Injectable, InjectionToken } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export interface Icon {
  name: string;
  svgRelativePath: string;
}

export const THEME_SERVICE_TOKEN = new InjectionToken<ThemeService>('themeService');

@Injectable()
export class ThemeService {
  private iconList: Icon[] = [
    { name: 'AWS', svgRelativePath: 'aws_icon.svg' },
    { name: 'aws', svgRelativePath: 'aws_icon.svg' },
    { name: 'GCP', svgRelativePath: 'gcp_icon.svg' },
    { name: 'gcp', svgRelativePath: 'gcp_icon.svg' },
    { name: 'backup_delete', svgRelativePath: 'backup_delete.svg' },
    { name: 'backup_restore', svgRelativePath: 'backup_restore.svg' },
    { name: 'bell', svgRelativePath: 'bell.svg' },
    { name: 'check_icon', svgRelativePath: 'check_icon.svg' },
    { name: 'check_circle', svgRelativePath: 'check_circle_icon.svg' },
    { name: 'check_in_circle', svgRelativePath: 'check_in_circle.svg' },
    { name: 'chevron_left', svgRelativePath: 'chevron_left.svg' },
    { name: 'close_icon', svgRelativePath: 'close_icon.svg' },
    { name: 'connect', svgRelativePath: 'connect.svg' },
    { name: 'copy', svgRelativePath: 'copy.svg' },
    { name: 'document_icon', svgRelativePath: 'document_icon.svg' },
    { name: 'down_arrow', svgRelativePath: 'down_arrow.svg' },
    { name: 'download', svgRelativePath: 'download.svg' },
    { name: 'edit_icon', svgRelativePath: 'edit_icon.svg' },
    { name: 'email_icon', svgRelativePath: 'email_icon.svg' },
    { name: 'error_icon', svgRelativePath: 'error_icon.svg' },
    { name: 'error_icon_yellow', svgRelativePath: 'error_icon_yellow.svg' },
    { name: 'external_link', svgRelativePath: 'external_link.svg' },
    { name: 'feedback_icon', svgRelativePath: 'feedback_icon.svg' },
    { name: 'chat_icon', svgRelativePath: 'chat.svg' },
    { name: 'chat_stroked_icon', svgRelativePath: 'chat_stroked.svg' },
    { name: 'google_logo', svgRelativePath: 'google_logo.svg' },
    { name: 'glyph_chart', svgRelativePath: 'glyph_chart.svg' },
    { name: 'glyph_code', svgRelativePath: 'glyph_code.svg' },
    { name: 'glyph_connection', svgRelativePath: 'glyph_connection.svg' },
    { name: 'glyph_console', svgRelativePath: 'glyph_console.svg' },
    { name: 'glyph_gift', svgRelativePath: 'glyph_gift.svg' },
    { name: 'glyph_ingest', svgRelativePath: 'glyph_ingest.svg' },
    { name: 'glyph_integration', svgRelativePath: 'glyph_integration.svg' },
    { name: 'glyph_manage', svgRelativePath: 'glyph_manage.svg' },
    { name: 'glyph_menu', svgRelativePath: 'glyph_menu.svg' },
    { name: 'glyph_secure', svgRelativePath: 'glyph_secure.svg' },
    { name: 'glyph_trash_bin', svgRelativePath: 'glyph_trash_bin.svg' },
    { name: 'glyph_idle', svgRelativePath: 'glyph_idle.svg' },
    { name: 'health_error', svgRelativePath: 'health_error.svg' },
    { name: 'health_healthy', svgRelativePath: 'health_healthy.svg' },
    { name: 'health_loading', svgRelativePath: 'health_loading.svg' },
    { name: 'health_warning', svgRelativePath: 'health_warning.svg' },
    { name: 'help', svgRelativePath: 'help.svg' },
    { name: 'help_calendar', svgRelativePath: 'help_calendar.svg' },
    { name: 'help_documentation', svgRelativePath: 'help_documentation.svg' },
    { name: 'help_learn', svgRelativePath: 'help_learn.svg' },
    { name: 'help_support', svgRelativePath: 'help_support.svg' },
    { name: 'hide_password_icon', svgRelativePath: 'hide_password_icon.svg' },
    { name: 'i_letter', svgRelativePath: 'i_letter.svg' },
    { name: 'info_circle', svgRelativePath: 'info_circle.svg' },
    { name: 'info_icon', svgRelativePath: 'info_icon.svg' },
    { name: 'info_yellow', svgRelativePath: 'info_yellow.svg' },
    { name: 'loader', svgRelativePath: 'loader.svg' },
    { name: 'logo_grafana', svgRelativePath: 'logo_grafana.svg' },
    { name: 'logo_kafka', svgRelativePath: 'logo_kafka.svg' },
    { name: 'mailbox_icon', svgRelativePath: 'mailbox_icon.svg' },
    { name: 'mfa_totp_authenticator', svgRelativePath: 'mfa_totp_authenticator.svg' },
    { name: 'mfa_sms_authenticator', svgRelativePath: 'mfa_sms_authenticator.svg' },
    { name: 'no_backups', svgRelativePath: 'no_backups.svg' },
    { name: 'no_services', svgRelativePath: 'no_services.svg' },
    { name: 'pencil', svgRelativePath: 'pencil.svg' },
    { name: 'plus_icon', svgRelativePath: 'plus_icon.svg' },
    { name: 'provisioning', svgRelativePath: 'provisioning.svg' },
    { name: 'rocket_icon', svgRelativePath: 'rocket_icon.svg' },
    { name: 'running', svgRelativePath: 'running.svg' },
    { name: 'service_deleted', svgRelativePath: 'service_deleted.svg' },
    { name: 'share', svgRelativePath: 'share.svg' },
    { name: 'show_password_icon', svgRelativePath: 'show_password_icon.svg' },
    { name: 'sidebar_key', svgRelativePath: 'sidebar_key.svg' },

    { name: 'sidebar_marketplace', svgRelativePath: 'sidebar_integration.svg' },
    { name: 'sidebar_members', svgRelativePath: 'sidebar_members.svg' },
    { name: 'sidebar_services', svgRelativePath: 'sidebar_services.svg' },
    { name: 'sidebar_admin', svgRelativePath: 'sidebar_admin.svg' },
    { name: 'sidebar_settings', svgRelativePath: 'sidebar_settings.svg' },
    { name: 'stopped', svgRelativePath: 'stopped.svg' },
    { name: 'trash_bin', svgRelativePath: 'trash_bin.svg' },
    { name: 'upload_cloud', svgRelativePath: 'upload_cloud.svg' },
    { name: 'video', svgRelativePath: 'video.svg' },
    { name: 'case_status', svgRelativePath: 'case_status.svg' },
    { name: 'case_reply', svgRelativePath: 'case_reply.svg' },
    { name: 'case_upload', svgRelativePath: 'case_upload.svg' },
    { name: 'google_logo', svgRelativePath: 'google_logo.svg' },
    { name: 'search', svgRelativePath: 'search.svg' },
    { name: 'sidebar_support', svgRelativePath: 'sidebar_support.svg' },
    { name: 'sidebar_activities', svgRelativePath: 'sidebar_activities.svg' },
    { name: 'sort_arrow', svgRelativePath: 'sort_arrow.svg' },
    { name: 'inbox', svgRelativePath: 'inbox.svg' },
    { name: 'export_csv_icon', svgRelativePath: 'export_csv_icon.svg' },
    { name: 'paper_airplane_icon', svgRelativePath: 'paper_airplane_icon.svg' },
    { name: 'console_icon', svgRelativePath: 'console.svg' },
    { name: 'console_gradient', svgRelativePath: 'console_gradient.svg' },
    { name: 'console_icon_white', svgRelativePath: 'console_white.svg' },
    { name: 'load_data', svgRelativePath: 'load_data.svg' },
    { name: 'load_data_white', svgRelativePath: 'load_data_white.svg' },
    { name: 'sample_data', svgRelativePath: 'sample_data.svg' },
    { name: 'sample_data_white', svgRelativePath: 'sample_data_white.svg' },
    { name: 'file_icon', svgRelativePath: 'file_icon.svg' },
    { name: 'logo', svgRelativePath: 'logo.svg' },
    { name: 'logo_clickhouse', svgRelativePath: 'logo_clickhouse.svg' },
    { name: 'orange_paper_airplane', svgRelativePath: 'orange_paper_airplane.svg' },
    { name: 'us_flag', svgRelativePath: 'us_flag.svg' },
    { name: 'au_flag', svgRelativePath: 'au_flag.svg' },
    { name: 'br_flag', svgRelativePath: 'br_flag.svg' },
    { name: 'ca_flag', svgRelativePath: 'ca_flag.svg' },
    { name: 'de_flag', svgRelativePath: 'de_flag.svg' },
    { name: 'ie_flag', svgRelativePath: 'ie_flag.svg' },
    { name: 'sg_flag', svgRelativePath: 'sg_flag.svg' },
    { name: 'in_flag', svgRelativePath: 'in_flag.svg' },
    { name: 'nl_flag', svgRelativePath: 'nl_flag.svg' },
    { name: 'gb_flag', svgRelativePath: 'gb_flag.svg' },
    { name: 'za_flag', svgRelativePath: 'za_flag.svg' },
    { name: 'se_flag', svgRelativePath: 'se_flag.svg' },
    { name: 'spinner', svgRelativePath: 'spinner.svg' },
    { name: 'building', svgRelativePath: 'building.svg' },
    { name: 'white_clock', svgRelativePath: 'white_clock.svg' },
    { name: 'clock', svgRelativePath: 'clock.svg' },
    { name: 'bars', svgRelativePath: 'bars.svg' },
    { name: 'customer_key', svgRelativePath: 'customer_key.svg' },
    { name: 'small_check_in_circle', svgRelativePath: 'small_check_in_circle.svg' },
    { name: 'invalid', svgRelativePath: 'invalid.svg' },
    { name: 'verify_email', svgRelativePath: 'verify_email.svg' },
    { name: 'eye', svgRelativePath: 'eye.svg' },
    { name: 'eye-closed', svgRelativePath: 'eye-closed.svg' },
    { name: 'users', svgRelativePath: 'users.svg' }
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  initializeIcons(): void {
    for (const icon of this.iconList) {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon.svgRelativePath}`)
      );
    }
  }

  registerIcon(hash: string, url: string): void {
    this.matIconRegistry.addSvgIcon(hash, this.domSanitizer.bypassSecurityTrustResourceUrl(url));
  }
}
