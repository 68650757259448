<div class="reset_password_container auth_page_container">
  <div *ngIf="resetPasswordUiStateObs | async as uiState" class="container">
    <cw-auth-logo type="full" color="primary-dark"></cw-auth-logo>
    <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm" class="form">
      <div class="auth_intro">
        <div class="auth_title">Create new password</div>
      </div>

      <div class="intro_text">
        Your new password must be different from your previous password.
      </div>
      <div class="seed_form_element"
           [attr.data-error]="!resetPasswordForm.controls['password'].valid && resetPasswordForm.controls['password'].touched">
        <label>Password</label>
        <cp-auth-password-requirements
          *ngIf="showPasswordRequirements"
          [password]="resetPasswordForm.controls['password'].value">
        </cp-auth-password-requirements>
        <input
          #passwordInput
          (blur)="showPasswordRequirements = false"
          (focus)="showPasswordRequirements = true"
          [type]="hidePassword ? 'password' : 'text'"
          data-cy="password-input"
          formControlName="password"
          name="password">
        <button (click)="$event.preventDefault(); hidePassword = !hidePassword; passwordInput.focus()"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hidePassword"
                class="seed_input_icon" mat-icon-button
                matSuffix>
          <mat-icon [svgIcon]="hidePassword ? 'show_password_icon' : 'hide_password_icon'"
                    class="password_reveal_icon"></mat-icon>
        </button>
        <div
          *ngIf="!resetPasswordForm.controls['password'].valid && resetPasswordForm.controls['password'].touched"
          class="seed_error">
          Password does not meet all <a (click)="passwordInput.focus()" class="error_link">requirements</a>
        </div>
      </div>

      <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"></cw-system-message>

      <div class="form-element">
        <button [disabled]="resetPasswordForm.invalid || uiState.resetPasswordButtonDisabled"
                class="button clickui-primary-button"
                color="primary" mat-flat-button
                type="submit">
          Reset Password
        </button>
      </div>
    </form>
  </div>
</div>
