import { Injectable } from '@angular/core';
import { isTestRun } from '@cp/web/app/common/utils/AngularUtils';
import { environment } from '@cp/web/environments/environment';
import * as FullStory from '@fullstory/browser';

@Injectable({
  providedIn: 'root'
})
export class FullStoryService {
  constructor() {}

  init(): void {
    if (this.isReportingEnabled()) {
      FullStory.init({ orgId: environment.fullStoryOrgId });
    }
  }

  identify(userId: string): void {
    if (this.isReportingEnabled()) {
      FullStory.identify(userId);
    }
  }

  private isReportingEnabled(): boolean {
    // Full story is enabled only in production.
    return environment.stage === 'production' && !isTestRun();
  }
}
