import { HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { AuthEventAuthenticated, isAuthenticated, SignInWithCredentialsResponse } from '@cp/common-services/web-auth';
import { WebSocketService } from '@cp/common-services/web-socket.service';
import {
  ClientMetadataKey,
  GetConfirmEmailLastRetryResponse,
  InvitationUpdatedPayload,
  MfaMethod,
  TotpAuthenticatorAppSetUpData,
  UserCloudWaitlistRegistration,
  UserDetailsUpdate,
  UserDetailsUpdatedPayload,
  UserEntryQuestionnaire
} from '@cp/common/protocol/Account';
import { isDefined, Replace } from '@cp/common/protocol/Common';
import { UserFeatureId } from '@cp/common/protocol/features';
import { ExchangeTokensRequest } from '@cp/common/protocol/Oauth';
import { SignInOauthParams } from '@cp/common/protocol/SignInMetadata';
import { UserDeletedPayload } from '@cp/common/protocol/User';
import { WebSocketsMessage } from '@cp/common/protocol/WebSocket';
import { isSamlUserId } from '@cp/common/utils/AuthUtils';
import { UNAUTHORIZED_STATUS } from '@cp/common/utils/HttpError';
import { AccountApiService } from '@cp/web/app/account/account-api.service';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AuthService } from '@cp/web/app/auth/auth.service';
import { GalaxyService, Trace } from '@cp/web/app/common/services/galaxy.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import { RECAPTCHA_PROVIDER_TOKEN, RecaptchaProviderFn } from '@cp/web/app/common/utils/RecaptchaUtils';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { environment } from '@cp/web/environments/environment';
import {
  distinctUntilChanged,
  firstValueFrom,
  merge,
  NEVER,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap
} from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private isInProtectedView = false;

  constructor(
    private readonly accountApiService: AccountApiService,
    private readonly authService: AuthService,
    private readonly accountStateService: AccountStateService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly webSocketService: WebSocketService,
    private readonly segmentService: SegmentService,
    private readonly signInMetadataService: SignInMetadataService,
    private readonly galaxyService: GalaxyService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly organizationService: OrganizationService,
    private readonly dialog: MatDialog,
    @Inject(RECAPTCHA_PROVIDER_TOKEN) private readonly recaptchaProviderFn: RecaptchaProviderFn
  ) {
    this.maybeInitiateOauth2FlowAfterRouteHasLoaded().then();

    authService
      .observeAuthEvents()
      .pipe(map(isAuthenticated), distinctUntilChanged())
      .subscribe(async (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          await this.refreshUserDetails();
        } else {
          console.debug('User is not authenticated. Signing out');
          if (this.isInProtectedView) {
            await this.signOut();
          }
          this.accountStateService.setUserDetails(null);
          console.debug('Resetting user details');
          if (this.isInProtectedView) {
            await this.router
              .navigate(['/signIn'])
              .then()
              .catch((e) => console.error('Navigation:', e));
          }
        }
      });

    this.authService
      .observeAuthEvents()
      .pipe(
        filter((event) => event.type === 'customOAuthState'),
        map((event) => (event as AuthEventAuthenticated).signInMetadataId),
        filter(isDefined)
      )
      .subscribe(async (metadataId) => {
        const oauthParams =
          this.signInMetadataService.oauthParams ||
          (await this.accountApiService.getSignInMetadata(metadataId, this.accountStateService.getUserId()))
            .oauthParams;
        this.maybeInitiateOauth2Flow(oauthParams).then();
      });

    this.accountStateService
      .observeUserId()
      .pipe(
        switchMap((userId) => {
          if (!userId) {
            this.organizationStateService.clearOrganizations();
            return NEVER;
          }
          return merge(
            this.listenToUserUpdates(userId),
            this.organizationService.listenToOrgUpdates(),
            this.listenToInvitationUpdates()
          );
        })
      )
      .subscribe();
  }

  hasOauth2FlowParamsInUrl(): boolean {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    return this.isOauthParams(queryParams);
  }

  private isOauthParams(queryParams: Params): queryParams is SignInOauthParams {
    return ['response_type', 'client_id', 'redirect_uri'].every((i) => !!queryParams[i]);
  }

  async isOauth2Flow(): Promise<boolean> {
    const signInOauthParams =
      this.signInMetadataService.oauthParams ||
      (
        await this.accountApiService.getSignInMetadata(
          this.signInMetadataService.metadataId,
          this.accountStateService.getUserId()
        )
      ).oauthParams;
    return this.hasOauth2FlowParamsInUrl() || !!signInOauthParams;
  }

  setIsInProtectedView(isInProtectedView: boolean): void {
    this.isInProtectedView = isInProtectedView;
  }

  async signInWithCredentials(email: string, password: string): Promise<SignInWithCredentialsResponse> {
    const { isAwaitingTotpVerification, userId } = await this.authService.signInWithCredentials(email, password);

    if (isAwaitingTotpVerification) {
      this.accountStateService.setMfaSignInData({ isAwaitingTotpVerification, userId });
      return { isAwaitingTotpVerification, userId };
    }

    return { userId };
  }

  private setOauthParamsMetadataIfPresent() {
    /*
     * A third party application uses the Control Plane to authenticate users
     * and the control plane uses google.
     * Save the third party application information to be used after the sign in with Google
     */
    if (this.hasOauth2FlowParamsInUrl()) {
      const {
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
        state
      } = this.activatedRoute.snapshot.queryParams;
      this.signInMetadataService.setOauthParams({ clientId, redirectUri, responseType, state });
    }
  }

  async signInWithGoogle(): Promise<void> {
    this.setOauthParamsMetadataIfPresent();
    this.signInMetadataService.setUrl();
    await this.authService.signInWithGoogle();
  }

  async signUp(
    name: string,
    email: string,
    password: string,
    queryParams: Record<string, string>,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ): Promise<string> {
    const recaptchaToken = await this.recaptchaProviderFn();
    let mergedClientMetadata = { ...this.getClientMetadata(queryParams), recaptchaToken };
    if (clientMetadata) {
      mergedClientMetadata = { ...mergedClientMetadata, ...clientMetadata };
    }
    return await this.authService.signUp(email, password, name, mergedClientMetadata);
  }

  async resetPassword(userId: string, recoveryToken: string, newPassword: string): Promise<void> {
    await this.authService.resetPassword(userId, recoveryToken, newPassword);
  }

  async sendForgotPasswordEmail(email: string, queryParams: Record<string, string>): Promise<void> {
    const clientMetadata = this.getClientMetadata(queryParams);
    await this.accountApiService.sendForgotPasswordEmail(email, clientMetadata);
  }

  async confirmEmail(activationCode: string, userId: string, queryParams: Record<string, string>): Promise<void> {
    await this.authService.confirmEmail(activationCode, userId);
    if (queryParams['dest'] === 'matrixlms') {
      window.location.href = environment.matrixLmsLoginEndpoint;
    } else {
      await this.router.navigateByUrl('/signIn');
    }
  }

  async updateUserDetails(request: UserDetailsUpdate): Promise<void> {
    if (Object.keys(request).length === 0) {
      return;
    }
    const userDetails = await this.accountApiService.updateUserDetails(request);
    this.accountStateService.setUserDetails(userDetails);
    this.segmentService.trackGaEvent({
      event: 'update profile',
      label: 'update profile',
      category: 'cloud ui',
      view: 'auth',
      component: 'onboarding3'
    });
  }

  async addUserToCloudWaitlist(request: UserCloudWaitlistRegistration): Promise<void> {
    await this.accountApiService.addUserToCloudWaitlist(request);
    const userId = this.accountStateService.getUserIdOrFail();
    this.segmentService.trackGaEvent({
      event: 'update profile',
      label: `add ${userId} to ${request.waitlistName} waitlist`,
      category: 'cloud ui',
      view: 'auth',
      component: 'onboarding1'
    });
  }

  async addUserEntryQuestionnaire(request: UserEntryQuestionnaire): Promise<void> {
    await this.accountApiService.addUserEntryQuestionnaire(request);
    const userId = this.accountStateService.getUserIdOrFail();
    this.segmentService.trackGaEvent({
      event: 'update profile',
      label: `add user ${userId} entry questionnaire`,
      category: 'cloud ui',
      view: 'auth',
      component: 'onboarding1'
    });
  }

  async updateExperimentFlag(flag: UserFeatureId, isEnabled: boolean): Promise<void> {
    await this.accountApiService.updateExperimentFlag(flag, isEnabled);
    await this.refreshUserDetails();
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await this.authService.changePassword(oldPassword, newPassword);
  }

  async sendRequestIntegration(text: string): Promise<void> {
    const recaptchaToken = await this.recaptchaProviderFn();
    await this.accountApiService.sendRequestIntegration(text, recaptchaToken);
  }

  async signOut(): Promise<void> {
    await this.galaxyService.flushEvents();
    this.dialog.closeAll();
    await this.authService.signOut();
  }

  async createUserPrototypeByEmail(email: string, name: string, metadataId: string, recaptchaToken: string) {
    await this.accountApiService.createUserPrototypeByEmail(email, name, metadataId, recaptchaToken);
  }

  /**
   * Calls 'resendEmailConfirmation' HTTP API.
   * Has no other side effects.
   * See `resendEmailConfirmation` for mode details.
   */
  async resendEmailConfirmation(cpUserIdOrAuthProviderUserId: string): Promise<void> {
    await this.accountApiService.resendEmailConfirmation(cpUserIdOrAuthProviderUserId);
  }

  async getConfirmEmailLastRetry(userId: string): Promise<GetConfirmEmailLastRetryResponse> {
    return await this.accountApiService.getConfirmEmailLastRetry(userId);
  }

  async getTotpAuthenticatorAppSetupData(): Promise<TotpAuthenticatorAppSetUpData> {
    return await this.authService.getTotpAuthenticatorAppSetupData();
  }

  async setMfaPreferredMethod(mfaMethod: MfaMethod): Promise<void> {
    const previousMfaMethod = this.accountStateService.getUserDetailsOrFail().mfaPreferredMethod;
    if (previousMfaMethod === mfaMethod) {
      return;
    }
    await this.accountApiService.updateUserDetails({ mfaPreferredMethod: mfaMethod });
  }

  async verifyTotpTokenAndEnableMfa(token: string): Promise<void> {
    await this.authService.verifyTotpTokenAndActivateOtpDevice(token);
    await this.setMfaPreferredMethod('SOFTWARE_TOKEN_MFA');
  }

  async disableTotpMfa(): Promise<void> {
    await this.authService.disableTotpMfa();
    await this.setMfaPreferredMethod('NOMFA');
  }

  async confirmTotpSignIn(token: string): Promise<void> {
    await this.authService.confirmTotpSignIn(token);
  }

  private listenToUserUpdates(userId: string): Observable<WebSocketsMessage> {
    return merge(this.listenToUserDetailsUpdated(userId), this.listenToUserDeletes(userId));
  }

  private getClientMetadata(queryParams: Record<string, string>): Partial<Record<ClientMetadataKey, string>> {
    const uriParams = Object.keys(queryParams).length
      ? Object.entries(queryParams)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      : undefined;
    return uriParams ? { uri_params: uriParams } : {};
  }

  private listenToUserDetailsUpdated(
    userId: string
  ): Observable<Replace<WebSocketsMessage, 'payload', UserDetailsUpdatedPayload>> {
    return this.webSocketService
      .observeNotification<UserDetailsUpdatedPayload>({
        type: 'USER_DETAILS_UPDATED',
        objId: userId
      })
      .pipe(
        tap((notification) => {
          this.accountStateService.setUserDetails(notification.payload.userDetails);
        })
      );
  }

  private listenToUserDeletes(userId: string): Observable<Replace<WebSocketsMessage, 'payload', UserDeletedPayload>> {
    return this.webSocketService
      .observeNotification<UserDeletedPayload>({
        type: 'USER_DELETED',
        objId: userId
      })
      .pipe(
        tap((notification) => {
          if (notification.payload.userId === userId) {
            void this.signOut();
          }
        })
      );
  }

  private listenToInvitationUpdates(): Observable<Replace<WebSocketsMessage, 'payload', InvitationUpdatedPayload>> {
    const user = this.accountStateService.getUserDetailsOrFail();
    return this.webSocketService
      .observeNotification<InvitationUpdatedPayload>({
        type: 'INVITE_UPDATE',
        objId: user.email
      })
      .pipe(
        // SAML accounts are not allowed to be invited to organizations.
        // The reason is that SAML accounts can set their email to any email they would like regardless of their actual idp email.
        filter(() => !isSamlUserId(user.userId)),
        tap(({ payload }) => {
          this.accountStateService.setOrgInvitations(payload.invitations);
        })
      );
  }

  @Trace()
  private async refreshUserDetails(): Promise<void> {
    const userDetails = await this.accountApiService.initializeUserSession();
    this.accountStateService.setUserDetails(userDetails);
    this.accountStateService.setOrgInvitations(userDetails.invitations);
    this.organizationStateService.setOrganizations(userDetails.organizations);

    /** catch case where we don't have any metadata yet, see if we can get it from user profile. */
    console.log('refreshCpUserDetails -- processSignInMetadata');
    this.authService.processSignInMetadata().then();
  }

  private convertOauthParamsToUrlFormat(oauthParams: SignInOauthParams): Params {
    return {
      redirect_uri: oauthParams.redirectUri,
      response_type: oauthParams.responseType,
      client_id: oauthParams.clientId,
      state: oauthParams.state
    };
  }

  async maybeInitiateOauth2Flow(oauthParams?: SignInOauthParams): Promise<void> {
    if (!this.hasOauth2FlowParamsInUrl() && !oauthParams) {
      return;
    }

    const authEvent = await firstValueFrom(this.authService.observeAuthEvents().pipe(filter(isAuthenticated)));
    const queryParams = oauthParams
      ? this.convertOauthParamsToUrlFormat(oauthParams)
      : this.activatedRoute.snapshot.queryParams;
    const redirectUri = queryParams['redirect_uri'];

    const request: ExchangeTokensRequest = {
      accessToken: authEvent.accessToken,
      idToken: authEvent.idToken,
      refreshToken: authEvent.refreshToken,
      accessTokenExpiration: authEvent.accessTokenExpiration,
      requestingClientId: queryParams['client_id'],
      redirectUri: redirectUri
    };

    const response = await this.accountApiService.exchangeTokensForAuthorizationCode(request);
    const authorizationCode = response.authorizationCode;

    const params = new HttpParams().set('state', queryParams['state']).set('code', authorizationCode);

    window.location.href = new HttpRequest('GET', redirectUri, null, { params }).urlWithParams;
  }

  async maybeInitiateOauth2FlowAfterRouteHasLoaded(oauthParams?: SignInOauthParams): Promise<void> {
    await firstValueFrom(
      this.router.events.pipe(
        filter((evt) => evt instanceof NavigationEnd),
        take(1)
      )
    );
    await this.maybeInitiateOauth2Flow(oauthParams);
  }

  private regionIsBlockedObs: ReplaySubject<boolean> | undefined = undefined;

  /**
   * Observes the region access status and returns an Observable that
   * emits a boolean value indicating whether the region is blocked or not.
   * @returns An Observable<boolean> that emits true if the region is blocked,
   *          and false otherwise.
   */
  observeRegionIsBlocked(): Observable<boolean> {
    if (this.regionIsBlockedObs === undefined) {
      const observer = new ReplaySubject<boolean>(1);
      this.regionIsBlockedObs = observer;
      this.accountApiService
        .checkRegionAccess()
        .then(() => {
          // Access granted, region is not blocked.
          observer.next(false);
        })
        .catch((error) => {
          const { status } = error as { status?: unknown };
          // Block region if server returned authorized
          // but allow access in case of any other error.
          observer.next(status === UNAUTHORIZED_STATUS);
        });
    }
    return this.regionIsBlockedObs;
  }

  async changePasswordWithRedirect(): Promise<void> {
    window.location.href = await this.accountApiService.getChangePasswordWithRedirectUrl();
  }

  async userHasAcceptedTOS(): Promise<boolean> {
    return (await this.accountApiService.checkUserHasAcceptedTOS()) !== undefined;
  }

  async acceptAccountTOS(): Promise<void> {
    await this.accountApiService.acceptAccountTOS();
  }
}
