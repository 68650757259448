/** Defines all 'instance' states, it is in lowercase because the Data Plane defined it */
import type { InstanceFeatureId } from '@cp/common/protocol/features';
import type { OrganizationBillingStatus, OrganizationRole } from '@cp/common/protocol/Organization';
import type { InstancePendingAction } from '@cp/common/protocol/PendingInstanceActions';
import { isGCPRegion, type RegionId } from '@cp/common/protocol/Region';
import type { SaInstance } from '@cp/common/protocol/SaInstance';
import type { UpdateType } from '@cp/common/protocol/WebSocket';
import type { Range } from '@cp/common/types/MathTypes';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import type { RpcRequest, WithInstanceId, WithOrganizationId } from '@cp/common/utils/ProtocolUtils';

export const VOLUNTARY_INSTANCE_STATE_TYPE = ['starting', 'stopping', 'terminating', 'awaking'] as const;

/**
 * States that a user can voluntarily ask to set on an instance.
 * This is a subset of InstanceState.
 */
export type VoluntaryInstanceState = (typeof VOLUNTARY_INSTANCE_STATE_TYPE)[number];

export const INSTANCE_STATE = [
  ...VOLUNTARY_INSTANCE_STATE_TYPE,
  'provisioning',
  'running',
  'stopped',
  'terminated',
  'degraded',
  'failed',
  'idle'
] as const;
/** Valid states for instances to be in. */
export type InstanceState = (typeof INSTANCE_STATE)[number];

/** Transitional maintenance break states. */
export type MaintenanceState = 'start_maintenance' | 'finished_maintenance';

/** Possible instance maintenance kinds. For more details about maintenance restrictions see https://github.com/ClickHouse/control-plane/issues/4360. */
export const ACTIVE_MAINTENANCE_KIND = ['fullMaintenance', 'partialMaintenance'] as const;
export type ActiveMaintenanceKind = (typeof ACTIVE_MAINTENANCE_KIND)[number];

export const MAINTENANCE_KIND = [...ACTIVE_MAINTENANCE_KIND, 'Maintenance'] as const;
export type MaintenanceKind = (typeof MAINTENANCE_KIND)[number];

/** This list will eventually contain GCS and other cloud providers once we support them */
export type CloudProvider = 'AWS' | 'GCP' | 'AZURE';

/** URL path for instance handler: /api/instance. */
export const INSTANCE_API_PATH = 'instance';

/** Error codes. */
export const INSTANCE_LIMIT_REACHED = 'INSTANCE_LIMIT_REACHED';
export const DUPLICATE_INSTANCE_NAME = 'DUPLICATE_INSTANCE_NAME';
export const INVALID_INSTANCE_STATE_FOR_UPGRADE = 'INVALID_INSTANCE_STATE_FOR_UPGRADE';
export const BAD_REQUEST_WITH_USER_MESSAGE = 'BAD_REQUEST_WITH_USER_MESSAGE';
export const INVALID_ENCRYPTION_KEY = 'INVALID_ENCRYPTION_KEY';

export const DEFAULT_INSTANCE_REPLICAS = 3;

/** Each autoscaling value must be a multiple of 12. */
export const AUTOSCALING_MEMORY_STEP_MULTIPLIER = 12;
export const MIN_AUTOSCALING_PRODUCTION_MEMORY = 24;
/** Max autoscaling default value for production instances. */
export const MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG = 360;
/** Max autoscaling range for paid organization. This applies only for production instances. */
export const MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG = 720;
/** Max autoscaling range for a GCP service of a paid organization. This applies only for production instances. */
export const MAX_AUTOSCALING_RANGE_FOR_PAID_ORG_GCP = 708;
export const CUSTOM_MAX_AUTOSCALING_PRODUCTION_FT_DEFAULT = 720;
export const MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY = 16;
export const DEFAULT_AUTOSCALING_PRODUCTION_MEMORY = 48;
/** Autoscaling idling timeout default in minutes. */
export const IDLING_TIMEOUT_MINUTES_DEFAULT = 5;
/** By default autoscale idling is enabled. */
export const IDLE_SCALING_DEFAULT = true;
/** Hard-coding replica count.  TODO:: We should grab from DP in the future https://github.com/ClickHouse/control-plane/issues/3753 */
export const DEDICATED_NUM_REPLICAS = 3;

/**
 * Valid states for actions based on DP code.
 * See: https://github.com/ClickHouse/data-plane-application/blob/main/data-plane-management/internal/server/actions.go
 */
export const STATES_VALID_TO_START: Array<InstanceState> = ['stopped'];
export const STATES_VALID_TO_AWAKE: Array<InstanceState> = ['idle'];
export const STATES_VALID_TO_STOP: Array<InstanceState> = [
  'starting',
  'running',
  'stopped',
  'degraded',
  'idle',
  'awaking'
];

/** List of states valid to terminate according to DP logic. */
export const STATES_VALID_TO_TERMINATE: Array<InstanceState> = [
  'provisioning',
  'starting',
  'running',
  'stopping',
  'stopped',
  'degraded',
  'failed',
  'idle',
  'awaking'
];
export const STATES_VALID_FOR_PASSWORD_RESET: Array<InstanceState> = [
  'starting',
  'running',
  'stopping',
  'degraded',
  'idle',
  'awaking'
];

/** List of states valid to terminate for an OpenAPI user. OpenAPI requires a service to be stopped before termination. */
export const STATES_VALID_TO_TERMINATE_BY_OPENAPI: Array<InstanceState> = [
  'provisioning',
  'starting',
  'awaking',
  'stopped',
  'degraded',
  'failed'
];

/** Set of all RPC actions for 'instance' handler. */
export type InstanceRpcAction =
  | 'create'
  | 'delete'
  | 'upgrade'
  | 'cancelUpgrade'
  | 'getImportFileDownloadUrl'
  | 'list'
  | 'prepareUploadSignatureDetails'
  | 'rename'
  | 'resetPassword'
  | 'start'
  | 'stop'
  | 'updateAutoScaling'
  | 'updateIpAccessList'
  | 'refreshUserDataFlag'
  | 'markInstanceHasUserData'
  | 'getInstanceUserRole'
  | 'getAutoScaling'
  | 'verifyCustomerKeyConfig'
  | 'getMysqlSettings'
  | 'updateMysqlSettings'
  | 'backfillMysqlPassword'
  | 'getInstanceIamPrincipal'
  | 'updateInstanceDbRoleMapping'
  | 'updateInstanceState';

export type InstanceRpcRequest<T extends InstanceRpcAction> = RpcRequest<T>;

export const INSTANCE_TIER_ARRAY = [
  'Development',
  'Production',
  'Dedicated-High-Mem',
  'Dedicated-High-Cpu',
  'Dedicated-Standard'
] as const;
export type InstanceTier = (typeof INSTANCE_TIER_ARRAY)[number];

export function isInstanceTier(value: unknown): value is InstanceTier {
  return typeof value === 'string' && INSTANCE_TIER_ARRAY.includes(value as any);
}

export interface InstanceMemorySizeOptions {
  // List of size options to display
  sizes: Array<number>;

  // Range of valid indexes in sizes (others will be greyed out)
  selectableDomain: Range;
}

export const defaultInstanceMemorySizes = [
  MIN_AUTOSCALING_PRODUCTION_MEMORY,
  48,
  96,
  192,
  MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG,
  CUSTOM_MAX_AUTOSCALING_PRODUCTION_FT_DEFAULT
];

interface InstanceTierProperties {
  /** Compute units are defined based on assumed amount of replicas per tier. See https://clickhouse.com/pricing */
  replicaCountInPricing: number;
  /** Only some tiers can leverage autoscaling. */
  canBeAutoScaled: boolean;
  /** Only some tiers can be created by end-users. */
  canBeCreatedByUsers: boolean;
  /** Dedicated services run outside the multi-tenant environment on dedicated hardware with a separate base configuration. */
  isDedicated: boolean;
  /** Count of backups to retain in the database. */
  countOfBackupsToRetainInDb: number;
  /** Count of backups to show in the UI. Should be <= countOfBackupsToRetainInDb. */
  countOfBackupsToShowInUi: number;
  /** If true, we will check the exact baseConfiguration in DP and use that for the new instance.*/
  restoreBackupWithBaseConfiguration: boolean;
  /** ratio of memory GIB / number of CPUs */
  memoryGbPerCpu: number;
}

function makeDedicatedInstanceTierProperties(memoryGbPerCpu: number): InstanceTierProperties {
  return {
    replicaCountInPricing: 3,
    canBeAutoScaled: false,
    canBeCreatedByUsers: false,
    isDedicated: true,
    countOfBackupsToShowInUi: 7,
    countOfBackupsToRetainInDb: 7,
    restoreBackupWithBaseConfiguration: true,
    memoryGbPerCpu
  };
}

export const INSTANCE_TIERS: Readonly<Record<InstanceTier, InstanceTierProperties>> = {
  Development: {
    replicaCountInPricing: 2,
    canBeAutoScaled: false,
    canBeCreatedByUsers: true,
    isDedicated: false,
    countOfBackupsToShowInUi: 1,
    countOfBackupsToRetainInDb: 1,
    restoreBackupWithBaseConfiguration: false,
    memoryGbPerCpu: 8
  },
  Production: {
    replicaCountInPricing: 3,
    canBeAutoScaled: true,
    canBeCreatedByUsers: true,
    isDedicated: false,
    countOfBackupsToShowInUi: 2,
    countOfBackupsToRetainInDb: 2,
    restoreBackupWithBaseConfiguration: false,
    memoryGbPerCpu: 4
  },
  'Dedicated-High-Mem': makeDedicatedInstanceTierProperties(8),
  'Dedicated-High-Cpu': makeDedicatedInstanceTierProperties(2),
  'Dedicated-Standard': makeDedicatedInstanceTierProperties(4)
};

export const INSTANCE_TIER_TO_REPLICA_COUNT_MAP: Readonly<Record<InstanceTier, number>> = (
  Object.keys(INSTANCE_TIERS) as Array<InstanceTier>
).reduce(
  (obj, el) => {
    obj[el] = INSTANCE_TIERS[el].replicaCountInPricing;
    return obj;
  },
  {} as Record<InstanceTier, number>
);

type InstanceTierProperty = keyof InstanceTierProperties;

export function getReplicaCpuCount(
  tier: InstanceTier,
  instanceMemoryGib: number // memory size per replica, not cluster total
): number {
  const properties = INSTANCE_TIERS[tier];
  return instanceMemoryGib / properties.memoryGbPerCpu;
}

function getInstanceTiersSetWithProperty(property: InstanceTierProperty): ReadonlySet<InstanceTier> {
  return new Set<InstanceTier>(
    (Object.keys(INSTANCE_TIERS) as Array<InstanceTier>).filter((key) => INSTANCE_TIERS[key][property])
  );
}

/**
 * Tiers that can be auto-scaled.
 */
export const INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED = getInstanceTiersSetWithProperty('canBeAutoScaled');

/**
 * Tiers that can be created by end-users.
 */
export const INSTANCE_TIERS_THAT_CAN_BE_CREATED_BY_USERS = getInstanceTiersSetWithProperty('canBeCreatedByUsers');

/**
 * Tiers that run on dedicated hardware.
 */
export const INSTANCE_TIERS_THAT_ARE_DEDICATED = getInstanceTiersSetWithProperty('isDedicated');

/**
 * Tiers that restore backups with DP's baseConfiguration.
 */
export const INSTANCE_TIERS_THAT_RESTORE_BACKUPS_WITH_BASE_CONFIGURATION = getInstanceTiersSetWithProperty(
  'restoreBackupWithBaseConfiguration'
);

export type InstanceCloudProvider = 'aws' | 'gcp' | 'azure';
export type InstanceUpgradeStatus = 'done' | 'in_progress' | 'error';

export interface InstanceMaintenanceWindow {
  /** A timestamp of the beginning of the maintenance window. */
  startMaintenanceTime: number;
  /** A timestamp of the end of the maintenance window. */
  endMaintenanceTime: number;
  /**
   * A period of time when a notification message will be displayed before the maintenance starts in minutes.
   * The message will be displayed until the window closes and as long as the service is not still in maintenance status.
   */
  notificationMinutes: number;

  /** Name of the maintenance/migration. This will be shown in the info dialog of that maintenance window. */
  maintenanceName: string;

  /**
   * A timestamp of the last time this maintenance window was notified to start.
   * Note: CP notifies DP that this maintenance window should start,
   * but DP is responsible for triggering the maintenance window and notifying CP when it actually starts.
   */
  lastTimeNotified?: number;
}

export interface Instance {
  id: string;
  creationDate: number;
  name: string;
  state: InstanceState;
  organizationId: string;
  regionId: RegionId;
  clickhouseVersion: string;
  endpoints: Record<InstanceEndpointProtocol, InstanceEndpoint>;
  dbUsername: string;
  lastBackupStarted: number;
  backupPeriodHours?: number;
  /**
   * Custom incremental backup chain length. Used if FT_INSTANCE_CUSTOM_INCREMENTAL_BACKUP_CHAIN_LENGTH feature is enabled.
   * If the FF is defined and this field is missing, we use the default value INSTANCE_BACKUP_MAX_INCREMENTAL_CHAIN_LENGTH.
   */
  incrementalBackupChainLength?: number;
  /**
   * Custom number of backups to retain. Used if FT_INSTANCE_CUSTOM_BACKUPS_TO_RETAIN feature is enabled.
   * If this field is missing, we use the default value given for the instance tier.
   */
  countOfBackupsToRetainInDb?: number;
  /**
   * Custom autoscale values (in GB) that will be added to the list of available autoscale values.
   * Used if FT_CUSTOM_AUTOSCALE_VALUES feature is enabled.
   */
  customAutoscaleValues: number[];
  /**
   * List of the active IP filters for the instance.
   * The filters are unique.
   * Order of the filters is not important.
   * An instance with no filters has no access allowed.
   */
  ipAccessList: Array<IpAccessListEntry>;
  /* See InstanceBo.hasUserData. */
  hasUserData: boolean;
  /** Min total memory of all workers during auto-scaling. */
  minAutoScalingTotalMemory?: number;
  /** Max total memory of all workers during auto-scaling. */
  maxAutoScalingTotalMemory?: number;
  mysqlSettings?: InstanceMysqlSettings;
  /** Allow instance to scale down to 0 when idle. */
  enableIdleScaling?: boolean;
  /** Amount of time (in minutes) it takes for the service to go idle from inactivity. */
  idleTimeoutMinutes: number;
  /** Instance tier. */
  instanceTier: InstanceTier;
  /** Instance feature list. */
  features: Array<InstanceFeatureId>;
  pendingActions: Array<InstancePendingAction>;
  cloudProvider: InstanceCloudProvider;
  upgradeStatus?: InstanceUpgradeStatus;
  upgradeErrorMessage?: string;
  customerManagedEncryption?: InstanceCustomerManagedEncryptionConfig;
  /** Upcoming maintenance windows for the instance. */
  maintenanceWindows: Array<InstanceMaintenanceWindow>;
  /** Kind of current maintenance break. Undefined if the instance is not in maintenance break. */
  activeMaintenanceKind?: ActiveMaintenanceKind | undefined;
  defaultDatabaseRoleMappings: ReadonlyArray<InstanceDatabaseAccessMapping>;
}

export const INSTANCE_ENDPOINT_PROTOCOL_TYPE = ['https', 'nativesecure', 'mysql'] as const;
export type InstanceEndpointProtocol = (typeof INSTANCE_ENDPOINT_PROTOCOL_TYPE)[number];

export interface InstanceEndpoint {
  hostname: string;
  port: number;
}

/** Single entry in ipAccessList. */
export interface IpAccessListEntry {
  /** Filter value. */
  source: string;
  /** Filter description. Optional: may be an empty string. */
  description: string;
}

/** Maximum length of IP access list description. */
export const MAX_IP_ACCESS_LIST_DESCRIPTION_LENGTH = 100;

export interface CreateInstanceResponse {
  instanceId: string;
}

export interface CreateInstanceRequest extends WithOrganizationId, InstanceRpcRequest<'create'> {
  name: string;
  regionId: RegionId;
  ipAccessList: Array<IpAccessListEntry>;
  instanceTier: InstanceTier;
  /** Hashed password. Uses makeSha256HexString(). Must be compatible with DP. */
  passwordHash: string;
  /** SHA1 hashed password. Uses makeDoubleSha1(). Must be compatible with DP. */
  doubleSha1Password: string;
  gcpTermsChecked?: boolean;
  autoscalingParams: Partial<InstanceAutoscalingParams>;
  customerManagedEncryption?: InstanceCustomerManagedEncryptionConfig;
}

export interface VerifyCustomerKeyConfigRequest
  extends WithOrganizationId,
    InstanceRpcRequest<'verifyCustomerKeyConfig'> {
  customerManagedEncryption: InstanceCustomerManagedEncryptionConfig;
}

export interface VerifyCustomerKeyConfigResponse {
  valid: boolean;
}

export type GetInstanceIamPrincipalRequest = WithInstanceId &
  WithOrganizationId &
  InstanceRpcRequest<'getInstanceIamPrincipal'>;

export interface GetInstanceIamPrincipalResponse {
  iamPrincipal: string;
}

export interface UpdateInstanceDbRoleMappingRequest
  extends WithOrganizationId,
    WithInstanceId,
    InstanceRpcRequest<'updateInstanceDbRoleMapping'> {
  defaultDatabaseRoleMappings: ReadonlyArray<InstanceDatabaseAccessMapping>;
}

export type ListInstancesRequest = WithOrganizationId & InstanceRpcRequest<'list'>;

export interface ListInstancesResponse {
  instances: Array<Instance>;
}

export type StopInstanceRequest = WithOrganizationId & WithInstanceId & InstanceRpcRequest<'stop'>;

export type StartInstanceRequest = WithOrganizationId & WithInstanceId & InstanceRpcRequest<'start'>;

/** Request to update the instance details by 'instanceId'. */
export interface UpdateInstanceStateRequest extends InstanceRpcRequest<'updateInstanceState'>, WithInstanceId {
  state: VoluntaryInstanceState;
}

/**
 * Request payload for deleting an instance.
 * Setting the optional `force` field to `true` will force the deletion of the instance from Data Plane,
 * regardless of state of the instance (for example, `provisioning`).
 * Default value of `force` is `false`, and deletion will fail if the instance is in `provisioning` state.
 */
export interface DeleteInstanceRequest extends WithOrganizationId, WithInstanceId, InstanceRpcRequest<'delete'> {
  force?: boolean;
}

export interface UpgradeInstanceRequest extends WithInstanceId, WithOrganizationId, InstanceRpcRequest<'upgrade'> {}

export interface CancelInstanceUpgradeRequest
  extends WithInstanceId,
    WithOrganizationId,
    InstanceRpcRequest<'cancelUpgrade'> {}

export interface ResetInstancePasswordResponse {
  password: string;
}

export interface ResetInstancePasswordRequest
  extends WithOrganizationId,
    WithInstanceId,
    InstanceRpcRequest<'resetPassword'> {
  /** Hashed password. Uses makeSha256HexString(). Must be compatible with DP. */
  passwordHash: string;
  /** Double SHA1 hashed password. Uses makeDoubleSha1(). Must be compatible with DP. */
  doubleSha1Password: string;
}

export interface ChangeInstanceNameRequest extends WithOrganizationId, WithInstanceId, InstanceRpcRequest<'rename'> {
  name: string;
}

export interface DpInstanceAutoScalingRequest {
  min_total_memory_gb?: number;
  max_total_memory_gb?: number;
  enable_idle_scaling: boolean;
  idle_timeout_minute: number;
  enable_vertical_scaling?: boolean;
  enable_horizontal_scaling?: boolean;
  min_replicas?: number;
  max_replicas?: number;
}

/** MySQL Interface settings from Data Plane Management API */
export interface InstanceMysqlSettings {
  enabled: boolean;
  username?: string;
  passwordSet: boolean;
}

/** Payload for retrieving MySQL settings */
export type GetMysqlSettingsRequest = WithOrganizationId & WithInstanceId & InstanceRpcRequest<'getMysqlSettings'>;

/** Payload for updating MySQL Settings. Currently only enablement is able to be changed
 *  but more keys may be added in the future such as username.
 */
export type UpdateMysqlSettingsRequest = WithOrganizationId &
  WithInstanceId &
  InstanceRpcRequest<'updateMysqlSettings'> & {
    enabled: boolean;
  };

/** Payload for backfilling MySQL passwords for instances that don't have
 *  `passwordSet` true
 */
export type BackfillMysqlPasswordRequest = WithOrganizationId &
  WithInstanceId &
  InstanceRpcRequest<'backfillMysqlPassword'> & {
    doubleSha1Password: string;
    sha256Password: string;
  };

export type InstanceAutoScalingRequest = InstanceAutoscalingParams &
  WithOrganizationId &
  WithInstanceId &
  InstanceRpcRequest<'updateAutoScaling'>;

export type InstanceAutoscalingParams = Pick<
  Instance,
  'minAutoScalingTotalMemory' | 'maxAutoScalingTotalMemory' | 'enableIdleScaling' | 'idleTimeoutMinutes'
>;

export interface InstanceCustomerManagedEncryptionConfig {
  keyArn: string;
  assumedRoleArn: string;
}

/**
 * Request payload for to update IP Access List.
 * The 'accessList' must contain all filters for the instance and will update any current filters.
 */
export interface ChangeIpAccessListRequest
  extends WithOrganizationId,
    WithInstanceId,
    InstanceRpcRequest<'updateIpAccessList'> {
  ipAccessList: Array<IpAccessListEntry>;
}

/** Payload of 'ORG_INSTANCE_UPDATE' message type. */
export interface InstanceUpdatePayload {
  /** List of updated instances. */
  instances: Array<Instance>;
  /**
   * How many instances are in the list:
   * - 'complete' - the update includes details about all non-terminated instances in the organization.
   *     This kind of update is sent during Web-Socket subscription and never includes 'terminated' instances.
   * - 'partial' - a partial update includes information about some instances in the organization.
   *     This kind of update is sent in runtime when some instances are changed.
   *     The update includes 'terminated' instances: when instance state changes from 'terminating'->'terminated'
   *     (once an instance reaches 'terminated' state it is removed from the database and will never be reported again).
   *
   *  When the value is not defined the update is 'PARTIAL'.
   */
  updateType?: UpdateType;
}

/** Payload of 'ORG_INSTANCE_UPDATE' message type. */
export interface SaInstanceUpdatePayload {
  /** List of updated instances. */
  instances: Array<SaInstance>;
  /**
   * How many instances are in the list:
   * - 'complete' - the update includes details about all non-terminated instances in the organization.
   *     This kind of update is sent during Web-Socket subscription and never includes 'terminated' instances.
   * - 'partial' - a partial update includes information about some instances in the organization.
   *     This kind of update is sent in runtime when some instances are changed.
   *     The update includes 'terminated' instances: when instance state changes from 'terminating'->'terminated'
   *     (once an instance reaches 'terminated' state it is removed from the database and will never be reported again).
   *
   *  When the value is not defined the update is 'PARTIAL'.
   */
  updateType?: UpdateType;
}

// ORG_INSTANCE_PASSWORD_CHANGED
export interface InstancePasswordChangedPayload {
  instanceId: string;
  dbPassword: string;
}

/** IP access list entry to allow all IPs to access an instance. */
export const ALLOW_ANYWHERE_IP_ACCESS_LIST_IP = '0.0.0.0';
export const ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE = `${ALLOW_ANYWHERE_IP_ACCESS_LIST_IP}/0`;
export const ALLOW_ANYWHERE_IP_ACCESS_LIST_DESCRIPTION = 'Anywhere';

/** Builds 'currentIP' access list description using username. */
export function buildAllowListCurrentIpDescription(userName: string): string {
  return `${userName}'s IP`;
}

/** Returns IP access list with 'allow-all' access. */
export function getAllowAnywhereIpAccessList(): Array<IpAccessListEntry> {
  return [getAllowAnywhereIpAccessListEntry()];
}

/** Returns IP access list entry that allows access from everywhere. */
export function getAllowAnywhereIpAccessListEntry(): IpAccessListEntry {
  return {
    source: ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE,
    description: ALLOW_ANYWHERE_IP_ACCESS_LIST_DESCRIPTION
  };
}

/** Returns true if the filter value evaluates to ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE. */
export function isAllowAnywhere(filters: string | IpAccessListEntry | Array<IpAccessListEntry>): boolean {
  return typeof filters === 'string'
    ? filters === ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE || filters === ALLOW_ANYWHERE_IP_ACCESS_LIST_IP
    : Array.isArray(filters)
    ? filters.some(isAllowAnywhere)
    : isAllowAnywhere(filters.source);
}

export function checkInstanceIsUpgradingFromStatus(status: InstanceUpgradeStatus | undefined): boolean {
  return status === 'in_progress' || status === 'error';
}

export function checkInstanceUpgradeErrorFromStatus(upgradingStatus: InstanceUpgradeStatus | undefined): boolean {
  return upgradingStatus === 'error';
}

export function checkInstanceStateCanBeUpgraded(state: InstanceState): boolean {
  return ['running', 'starting', 'started'].includes(state);
}

export interface PrepareUploadSignatureDetailsRequest
  extends WithOrganizationId,
    InstanceRpcRequest<'prepareUploadSignatureDetails'> {
  fileName: string;
  mimeType?: string;
}

export interface GetImportFileDownloadUrlRequest
  extends WithOrganizationId,
    WithInstanceId,
    InstanceRpcRequest<'getImportFileDownloadUrl'> {
  /** The id of the Storage model that represents the S3 bucket. */
  storageId: string;
}

export interface GetImportFileDownloadUrlResponse {
  downloadUrl: string;
}

/** Asks CP API refresh 'hasUserData' instance flag. */
export type RefreshUserDataFlagRequest = InstanceRpcRequest<'refreshUserDataFlag'> &
  WithOrganizationId &
  WithInstanceId;

export interface RefreshUserDataFlagResponse {
  /** Current instance state with 'hasUserData' flag updated. */
  instance: Instance;
}

/** Sets 'Instance.hasUserData' flag to true. */
export type MarkInstanceHasUserDataRequest = InstanceRpcRequest<'markInstanceHasUserData'> &
  WithOrganizationId &
  WithInstanceId;

export type MarkInstanceHasUserDataResponse = RefreshUserDataFlagResponse;

export type GetInstanceUserRoleRequest = InstanceRpcRequest<'getInstanceUserRole'> & WithInstanceId;

export interface GetInstanceUserRoleResponse {
  /** Requesting user's organization role. */
  role: OrganizationRole;
}

export interface InstanceAutoScalingConfig {
  enableIdleScaling: boolean;
  enableVerticalScaling: boolean;
  idleTimeoutMinutes: number;
  minMemoryGb?: number;
  maxMemoryGb?: number;
  enableHorizontalScaling?: boolean;
  minReplicas?: number;
  maxReplicas?: number;
}

export interface GetInstanceAutoScalingRequest extends InstanceRpcRequest<'getAutoScaling'>, WithInstanceId {}

export interface GetInstanceAutoScalingResponse {
  enableIdleScaling: boolean;
  enableVerticalScaling: boolean;
  idleTimeoutMinutes: number;
  minMemoryGb: number;
  maxMemoryGb: number;
  enableHorizontalScaling?: boolean;
  minReplicas?: number;
  maxReplicas?: number;
}

export interface GetInstanceDetailsResponse {
  state: InstanceState;
  baseConfigurationName: string;
  dpInstanceName: string;
  iamPrincipal: string;
}

/**
 *  User-defined data plane instance settings.
 *  See https://github.com/ClickHouse/data-plane-configuration/blob/main/data-plane-mgmt/schemas/user-defined-settings-schema.json.
 */
export interface DpInstanceSettings {
  excludeConstraints: Array<string>;
}

export interface DpInstanceAutoscalingConfig {
  enable_vertical_scaling: boolean;
  enable_idle_scaling: boolean;
  min_total_memory_gb?: number;
  max_total_memory_gb?: number;
  idle_timeout_minute: number;
  enable_horizontal_scaling?: boolean;
  min_replicas?: number;
  max_replicas?: number;
}

export type DpCellName = '' | 'default' | 'cell1';

export interface DpCustomerManagedEncryptionKeyConfig {
  provider: 'AWS';
  uri: string;
  auth_principal: string;
}

export type DpInstanceCreationCustomFields = Record<string, string | boolean | number>;

export const DEFAULT_DP_INSTANCE_AUTOSCALING_CONFIG_DEDICATED: DpInstanceAutoscalingConfig = {
  enable_vertical_scaling: false,
  enable_idle_scaling: true,
  min_total_memory_gb: undefined,
  max_total_memory_gb: undefined,
  idle_timeout_minute: IDLING_TIMEOUT_MINUTES_DEFAULT
};

export const DEFAULT_DP_INSTANCE_AUTOSCALING_CONFIG_PROD: DpInstanceAutoscalingConfig = {
  enable_vertical_scaling: true,
  enable_idle_scaling: true,
  min_total_memory_gb: DEFAULT_AUTOSCALING_PRODUCTION_MEMORY,
  max_total_memory_gb: MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG,
  idle_timeout_minute: IDLING_TIMEOUT_MINUTES_DEFAULT
};

export const DEFAULT_DP_INSTANCE_AUTOSCALING_CONFIG_DEV: DpInstanceAutoscalingConfig = {
  enable_vertical_scaling: false,
  enable_idle_scaling: true,
  idle_timeout_minute: IDLING_TIMEOUT_MINUTES_DEFAULT
};

export function getMaxNewInstanceMemoryInRegion(
  regionId: RegionId,
  orgBillingStatus: OrganizationBillingStatus
): number {
  if (isPayingStatus(orgBillingStatus)) {
    if (isGCPRegion(regionId)) {
      return MAX_AUTOSCALING_RANGE_FOR_PAID_ORG_GCP;
    } else {
      return MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG;
    }
  } else {
    return MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG;
  }
}

export const idleTimeoutValuesMinutes = [5, 10, 15, 30, 60, 180, 720, 1440];

export const INSTANCE_DATABASE_ACCESS_TYPES = ['FULL_ACCESS', 'READ_ONLY', 'NO_ACCESS'] as const;
export type InstanceDatabaseAccessType = (typeof INSTANCE_DATABASE_ACCESS_TYPES)[number];

export const DEFAULT_DATABASE_ROLE_MAPPING: ReadonlyArray<InstanceDatabaseAccessMapping> = [
  {
    cpRole: 'ADMIN',
    databaseAccess: 'FULL_ACCESS'
  },
  {
    cpRole: 'DEVELOPER',
    databaseAccess: 'NO_ACCESS'
  }
];

export interface InstanceDatabaseAccessMapping {
  readonly cpRole: OrganizationRole;
  readonly databaseAccess: InstanceDatabaseAccessType;
}
