import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { truthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountService } from '@cp/web/app/account/account.service';
import { ResetPasswordUiState } from '@cp/web/app/account/protocol/AccountStates';
import { ResetPasswordUiService } from '@cp/web/app/account/reset-password/reset-password-ui.service';
import { validateFormPassword } from '@cp/web/app/common/utils/FormValidationUtils';
import { Observable, takeUntil } from 'rxjs';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent extends OnDestroyComponent {
  readonly resetPasswordForm: FormGroup;
  readonly resetPasswordUiStateObs: Observable<ResetPasswordUiState>;
  showPasswordRequirements = false;
  hidePassword = true;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly snackBar: MatSnackBar,
    readonly resetPasswordUiService: ResetPasswordUiService
  ) {
    super();
    this.resetPasswordUiStateObs = resetPasswordUiService.observeState().pipe(takeUntil(this.onDestroy));

    resetPasswordUiService.setPartialState({ resetPasswordButtonDisabled: false, errorMessage: undefined });

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required], validateFormPassword]
    });
  }

  async onSubmit(): Promise<void> {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.resetPasswordUiService.setPartialState({ resetPasswordButtonDisabled: true, errorMessage: undefined });
    const recoveryToken = truthy(this.route.snapshot.paramMap.get('recoveryToken'));
    const userId = truthy(this.route.snapshot.paramMap.get('userId'));
    const details = this.resetPasswordForm.value;
    try {
      await this.accountService.resetPassword(userId, recoveryToken, details.password);
      this.snackBar.open('Password changed successfully', 'Dismiss', { duration: 5000 });
      await this.router.navigateByUrl('/');
    } catch (e: any) {
      let errorMessage = 'Reset password link has expired. Please try again.';
      if (e.errorCode) {
        switch (e.errorCode) {
          case 'E0000080':
            errorMessage = 'The entered password does not meet the complexity requirements';
            break;
        }
      }
      this.resetPasswordUiService.setStateKey('errorMessage', errorMessage);
    } finally {
      this.resetPasswordUiService.setStateKey('resetPasswordButtonDisabled', false);
    }
  }
}
