import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isDefined } from '@cp/common/protocol/Common';
import { OrganizationBillingStatus, OrganizationCommitment } from '@cp/common/protocol/Organization';
import { BillingConversionDialogComponent } from '@cp/web/app/admin/billing-conversion-dialog/billing-conversion-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { map, Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cp-trial-countdown',
  templateUrl: './trial-countdown.component.html',
  styleUrls: ['./trial-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrialCountdownComponent {
  numDaysLeftObs: Observable<number | undefined>;
  billingStatusIsTrialObs: Observable<boolean>;

  numDaysLeftMessageMapping: { [k: string]: string } = {
    '=0': 'Under a day left in trial',
    '=1': 'One day left in trial',
    other: '# days left in trial'
  };

  constructor(
    organizationStateService: OrganizationStateService,
    private readonly dialog: MatDialog
  ) {
    this.numDaysLeftObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeTrialCommitmentState(id)),
      map<OrganizationCommitment, number | undefined>((commitment) => commitment?.timeRemainingInDays)
    );

    this.billingStatusIsTrialObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeBillingStatus(id)),
      map<OrganizationBillingStatus, boolean>((billingStatus) => billingStatus === 'IN_TRIAL')
    );
  }

  showCreditCardDialog(): void {
    BillingConversionDialogComponent.show(this.dialog);
  }
}
