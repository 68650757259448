import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Allow retrieving ng-templates based on a dynamic name.
 * This directive protects the templates' names from obfuscation in production.
 */
@Directive({
  selector: '[namedTemplate]'
})
export class NamedTemplateDirective {
  @Input() namedTemplate: string = '';
  constructor(public templateRef: TemplateRef<any>) {}
}
