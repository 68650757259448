import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ALL_INTEGRATION_CATEGORIES, Integration, IntegrationCategory } from '@cp/common/protocol/Integration';
import { assertTruthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { IntegrationsService } from '@cp/web/app/marketplace/integrations.service';
import { RequestIntegrationDialogComponent } from '@cp/web/app/marketplace/request-integration-dialog/request-integration-dialog.component';
import { BehaviorSubject, from, takeUntil } from 'rxjs';

/** Adding the 'View All' option to the list of all integration categories. */
type MarketplaceCategory = IntegrationCategory | 'ALL';

@Component({
  selector: 'cp-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketplaceComponent extends OnDestroyComponent {
  // Map integration category to a list of all integrations of that category.
  integrationsPerCategory: Record<string, Array<Integration>> = {};
  allIntegrations: Array<Integration> = [];

  categories: Array<IntegrationCategory> = [];
  currentCategories: Array<MarketplaceCategory> = [];
  searchText?: string;
  readonly searchTextSubject = new BehaviorSubject<string>('');

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    integrationService: IntegrationsService
  ) {
    super();
    const routeState = this.router.getCurrentNavigation()?.extras.state;
    from(integrationService.loadIntegrations())
      .pipe(takeUntil(this.onDestroy))
      .subscribe((integrations) => {
        this.allIntegrations = [];
        this.integrationsPerCategory = integrations.reduce(
          (obj, integration) => {
            if (!obj[integration.category]) {
              obj[integration.category] = [];
            }
            obj[integration.category].push(integration);
            this.allIntegrations.push(integration);
            return obj;
          },
          {} as { [k: string]: Integration[] }
        );
        this.categories = ALL_INTEGRATION_CATEGORIES.filter((cat) => !!this.integrationsPerCategory[cat]);
        this.currentCategories = this.categories;
        if (routeState && routeState['categoryFilter']) {
          this.filterCategories(routeState['categoryFilter']);
        }
        this.cd.markForCheck();
      });
  }

  filterCategories(category: MarketplaceCategory): void {
    assertTruthy(
      category === 'ALL' || this.categories.includes(category),
      `Found an unknown category to filter by - ${category}`
    );
    if (category === 'ALL') {
      this.currentCategories = this.categories;
    } else {
      this.currentCategories = [category];
    }
  }

  /** Converts category type to text. */
  getCategoryName(cat: MarketplaceCategory): string {
    switch (cat) {
      case 'DATA_INGESTION':
        return 'Data Ingestion';
      case 'DATA_VISUALIZATION':
        return 'Data Visualization';
      case 'LANGUAGE_CLIENT':
        return 'Language Client';
      case 'SQL_CLIENT':
        return 'SQL Client';
      default:
        console.assert('An invalid category type was provided');
        return '';
    }
  }

  showRequestIntegrationDialog(integrationName?: string): void {
    this.dialog.open(RequestIntegrationDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: { integrationName }
    });
  }

  searchInputChanged(): void {
    this.searchTextSubject.next(this.searchText || '');
  }

  get searchedIntegrations(): Array<Integration> {
    const searchText = this.searchText || '';
    return this.allIntegrations.filter((int) => int.name.toLowerCase().includes(searchText.toLowerCase()));
  }
}
