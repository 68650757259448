import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { assertTruthy } from '@cp/common/utils/Assert';
import { MetricsService } from '@cp/web/app/metrics/metrics.service';

@Component({
  selector: 'cp-metrics-feedback-dialog',
  templateUrl: './metrics-feedback-dialog.component.html',
  styleUrls: ['./metrics-feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricsFeedbackDialogComponent {
  feedbackSubmitted = false;
  readonly feedbackForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<MetricsFeedbackDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly metricsService: MetricsService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.feedbackForm = this.formBuilder.group({
      feedbackText: ['', [Validators.required]]
    });
  }

  async sendRequestAndClose(): Promise<void> {
    assertTruthy(this.feedbackForm.valid);
    const feedbackText = this.feedbackForm.controls['feedbackText'].value;
    try {
      await this.metricsService.sendMetricsFeedback(feedbackText);
      this.feedbackSubmitted = true;
    } catch (e: unknown) {
      console.error(e);
      let errMessage;
      const errorCode = e instanceof HttpErrorResponse ? e.error?.message || e.message : 'unknown';
      switch (errorCode) {
        case 'FAILED_RECAPTCHA':
          errMessage = `Submitting your request failed the recaptcha challenge, please try again later`;
          break;
        default:
          errMessage = 'There was a problem submitting your request, please try again later';
      }
      this.snackBar.open(errMessage, 'Dismiss', { duration: 10000 });
    }
    this.cdr.markForCheck();
  }
}
