import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trackById } from '@cp/common-services/trackById';
import { OrganizationInvitationDetails } from '@cp/common/protocol/Organization';
import { truthy } from '@cp/common/utils/Assert';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { trace } from '@cp/web/app/common/services/galaxy.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'cp-organization-invitations-dialog',
  templateUrl: './organization-invitations-dialog.component.html',
  styleUrls: ['./organization-invitations-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationInvitationsDialogComponent implements OnInit {
  readonly displayedColumns = ['name', 'actions'];

  readonly invitationsObs = this.accountStateService.observeOrgInvitations();

  constructor(
    public dialogRef: MatDialogRef<OrganizationInvitationsDialogComponent>,
    private readonly accountStateService: AccountStateService,
    private readonly organizationService: OrganizationService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    trace('pageLoad', 'window', { namespace: 'app', event: 'pageLoad' });
    const invitations = truthy(
      await firstValueFrom(this.accountStateService.observeOrgInvitations()),
      'No invitations'
    );
    await this.organizationService.markViewedInvitations(invitations);
  }

  async acceptInvitation(invitationKey: string): Promise<void> {
    try {
      this.isAcceptButtonEnabled = true;
      const result = await this.organizationService.acceptInvitation(invitationKey);

      this.organizationStateService.switchOrganization(result.organizationId);
      this.organizationService.assignCurrentOrganizationId();
      this.snackBar.open('Organization invitation accepted', 'Dismiss', { duration: 5000 });
    } catch (e) {
      this.snackBar.open(`Unable to accept invitation`, 'Dismiss', { duration: 5000 });
      this.isAcceptButtonEnabled = false;
    }
  }

  async declineInvitation(invitationKey: string): Promise<void> {
    await this.organizationService.declineInvitation(invitationKey);
    this.snackBar.open('Organization invitation declined', 'Dismiss', { duration: 5000 });
  }

  public isAcceptButtonEnabled: boolean = false;

  hasInvitations(invitations: OrganizationInvitationDetails[]): boolean {
    if (invitations.length === 0) {
      this.dialogRef.close();
      return false;
    }

    return invitations.length > 0;
  }

  protected readonly trackById = trackById;
}
