import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CaseReplyDialogComponent } from '@cp/web/app/support/case-reply-dialog/case-reply-dialog.component';
import { CaseSubmittedDialogComponent } from '@cp/web/app/support/create-case-dialog/case-submitted-dialog/case-submitted-dialog.component';
import { CreateCaseDialogComponent } from '@cp/web/app/support/create-case-dialog/create-case-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SupportUiService {
  constructor(private readonly dialog: MatDialog) {}

  showCreateCaseDialog() {
    this.dialog.open(CreateCaseDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }

  showCaseSubmittedDialog(caseId: string) {
    this.dialog.open(CaseSubmittedDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal_no_padding',
      data: caseId
    });
  }

  showCaseReplyDialog(caseId: string) {
    this.dialog.open(CaseReplyDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: caseId
    });
  }
}
