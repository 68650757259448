import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { State } from '@cp/common-services/state/action.applier';
import { StateService } from '@cp/common-services/state/state.service';
import {
  Address,
  BillUsageSummary,
  CreditBalanceSummary,
  InvoiceSummary,
  PaymentMethod
} from '@cp/common/protocol/Billing';
import { TaxIdDatumType, TaxStatusType } from '@cp/common/protocol/Stripe';

export interface AdminBillingState extends State {
  address: AdminBillingStateAddress;
  paymentMethod?: PaymentMethod;
  metadata: AdminBillingStateMetadata;
  invoices?: Array<InvoiceSummary>;
  billUsageStatements?: Array<BillUsageSummary>;
  creditBalances?: Array<CreditBalanceSummary>;
  nextInvoiceDate?: number;
  billingContact: string;
  canUpdatePaymentMethod: boolean;
}

export interface AdminBillingStateAddress {
  billingAddress: Partial<Address>;
  bothAddressesSame: boolean;
  shippingAddress?: Partial<Address>;
  companyName?: string;
}

export interface AdminBillingStateMetadata {
  organizationIsABusiness?: boolean;
  taxId?: string;
  taxIdType?: TaxIdDatumType;
  taxStatus?: TaxStatusType;
}

@Injectable({
  providedIn: 'root'
})
export class AdminBillingStateService extends BaseUiStateService<AdminBillingState> {
  private static readonly PATH = ['billing'];

  constructor(stateService: StateService) {
    super(AdminBillingStateService.PATH, stateService);
  }

  setLastUpdated(): void {
    this.stateService.setInPath([...this.STATE_PATH, 'lastUpdated'], Date.now());
  }

  getAddress(): AdminBillingStateAddress | undefined {
    return this.stateService.getStateInPath<AdminBillingStateAddress>([...this.STATE_PATH, 'address']);
  }

  getCanUpdatePaymentMethod(): boolean {
    return !!this.stateService.getStateInPath<boolean>([...this.STATE_PATH, 'canUpdatePaymentMethod']);
  }

  getMetadata(): AdminBillingStateMetadata | undefined {
    return this.stateService.getStateInPath<AdminBillingStateMetadata>([...this.STATE_PATH, 'metadata']);
  }

  clear(): void {
    this.stateService.deletePath([...this.STATE_PATH]);
  }
}
