import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { truthy } from '@cp/common/utils/Assert';
import { AccountService } from '@cp/web/app/account/account.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';

@Component({
  templateUrl: './resend-confirm-email.component.html',
  styleUrls: ['./resend-confirm-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResendConfirmEmailComponent implements OnInit {
  errorMessage?: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly segmentService: SegmentService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const userId = truthy(this.route.snapshot.paramMap.get('userId'));
      await this.accountService.resendEmailConfirmation(userId);
      this.segmentService.trackGaEvent({
        event: 'click email',
        label: 'resend confirmation email',
        category: 'cloud ui',
        view: 'auth',
        component: 'resendEmailModal'
      });
    } catch (e) {
      this.errorMessage = `We’re unable to send the confirmation email at this time as the maximum number of emails sent
      has been reached. Please try again later.`;
      console.error(`Unable to resend confirm email`, e);
      this.cdr.markForCheck();
    }
  }
}
