import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DUPLICATE_INSTANCE_NAME,
  Instance,
  INSTANCE_LIMIT_REACHED,
  INVALID_INSTANCE_STATE_FOR_UPGRADE
} from '@cp/common/protocol/Instance';
import { CONFLICT } from '@cp/common/utils/HttpError';
import { ConfirmationDialogComponent } from '@cp/web/app/common/components/confirmation-dialog/confirmation-dialog.component';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { ConnectToInstanceDialogComponent } from '@cp/web/app/instances/connect-to-instance-dialog/connect-to-instance-dialog.component';
import { DeleteInstanceDialogComponent } from '@cp/web/app/instances/delete-instance-dialog/delete-instance-dialog.component';
import { IframeWithContentComponent } from '@cp/web/app/instances/iframe-with-content/iframe-with-content.component';
import { ResetInstancePasswordDialogComponent } from '@cp/web/app/instances/reset-instance-password-dialog/reset-instance-password-dialog.component';
import { UpgradeInstanceDialogComponent } from '@cp/web/app/instances/upgrade-instance-dialog/upgrade-instance-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class InstanceUiService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly segmentService: SegmentService
  ) {}

  showConnectDialog(instanceId: string, userInitiated: boolean) {
    if (userInitiated) {
      this.segmentService.trackGaEvent({
        event: 'click',
        label: 'connect to service',
        category: 'cloud ui service',
        component: 'header',
        view: 'servicePage'
      });
    }
    this.dialog.open(ConnectToInstanceDialogComponent, {
      width: '100%',
      maxWidth: '840px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal_no_padding',
      data: { instanceId }
    });
  }

  showDeleteInstanceDialog(instance: Instance): void {
    this.dialog.open(DeleteInstanceDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal',
      data: { instance }
    });
  }

  showUpgradeInstanceDialog(): MatDialogRef<UpgradeInstanceDialogComponent> {
    return UpgradeInstanceDialogComponent.show(this.dialog);
  }

  showConfirmStopInstance(instance: Instance): MatDialogRef<ConfirmationDialogComponent> {
    return ConfirmationDialogComponent.show(this.dialog, {
      title: 'Stop service?',
      text: `Are you sure you would like to stop <b>${instance.name}</b>?`,
      action: 'Stop service',
      buttonStyle: 'btn_danger_light',
      buttonLayout: 'VERTICAL'
    });
  }

  showConfirmAccessFromAnywhereDialog(): MatDialogRef<ConfirmationDialogComponent> {
    return ConfirmationDialogComponent.show(this.dialog, {
      title: 'Set access to Anywhere?',
      text: `<p>Switching to ‘Anywhere’ will remove your previously saved IP access list settings and allow service access to anyone with your credentials.</p>
      <p>Are you sure you wish to continue?</p>`,
      action: 'Set access to Anywhere',
      buttonLayout: 'VERTICAL'
    });
  }

  // noinspection JSUnusedGlobalSymbols
  showPlayUiDialog(url: string): void {
    this.dialog.open(IframeWithContentComponent, {
      width: '90%',
      height: '90%',
      maxWidth: '100%',
      maxHeight: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'iframe_modal',
      data: { url }
    });
  }

  showInstanceResetPasswordDialog(instanceId: string, username: string, showPasswordAfterward: boolean = false): void {
    this.dialog.open(ResetInstancePasswordDialogComponent, {
      width: '100%',
      maxWidth: '582px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: { instanceId, username, showPasswordAfterward }
    });
  }

  showSnackBar(message: string): void {
    this.snackBar.open(message, 'Dismiss', { duration: 5000 });
  }

  getUpgradeDisplayErrorMessage(error: string): string {
    switch (error) {
      case DUPLICATE_INSTANCE_NAME:
        return 'An upgraded service with the same name already exists';
      case INSTANCE_LIMIT_REACHED:
        return 'You reached your service limit. You cannot create additional services. Please contact support for help';
      case CONFLICT:
        return 'Another upgrade is already in progress';
      case INVALID_INSTANCE_STATE_FOR_UPGRADE:
        return 'Cannot upgrade service in its current state';
      default:
        return 'Cannot upgrade instance. Please contact support for further information';
    }
  }
}
