import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { isDefined } from '@cp/common/protocol/Common';
import {
  ActiveMaintenanceKind,
  checkInstanceIsUpgradingFromStatus,
  getReplicaCpuCount,
  idleTimeoutValuesMinutes,
  IDLING_TIMEOUT_MINUTES_DEFAULT,
  Instance,
  INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED,
  INSTANCE_TIER_TO_REPLICA_COUNT_MAP,
  MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY
} from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { isAwsRegionId, isGcpRegionId, REGION_BY_ID } from '@cp/common/protocol/Region';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import { timeoutMinutesToString } from '@cp/common/utils/FormatUtils';
import { checkObjectEqualityByShallowCompare } from '@cp/common/utils/MiscUtils';
import { isNumber } from '@cp/common/utils/ValidationUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { ChangeInstanceNameDialogComponent } from '@cp/web/app/instances/change-instance-name-dialog/change-instance-name-dialog.component';
import { InstanceAutoScalingService } from '@cp/web/app/instances/instance-auto-scaling.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import copy from 'copy-to-clipboard';
import {
  distinct,
  distinctUntilChanged,
  filter,
  map,
  NEVER,
  Observable,
  ReplaySubject,
  switchMap,
  takeUntil
} from 'rxjs';
import { BillingConversionDialogComponent } from '../../admin/billing-conversion-dialog/billing-conversion-dialog.component';
import { OrganizationStateService } from '../../organizations/organization-state.service';
import { InstanceStateService } from '../instance-state.service';

interface IdleSettings {
  enableIdleScaling: boolean;
  idleTimeoutMinutes: number;
}

interface MemorySettings {
  minMemoryGb: number;
  maxMemoryGb: number;
}

interface AutoscalingSettings extends IdleSettings, MemorySettings {}

interface InitAutoscalingSettings {
  idleTimeoutMinutes: number;
  minMemoryGb: number;
  maxMemoryGb: number;
}

const IDLE_DISABLED = -1;

function instanceNumReplicas(instance: Instance, dpNumReplicas: number) {
  if (dpNumReplicas === 0) {
    return INSTANCE_TIER_TO_REPLICA_COUNT_MAP[instance.instanceTier];
  } else {
    return dpNumReplicas;
  }
}

@Component({
  selector: 'cp-instance-settings',
  templateUrl: './instance-settings.component.html',
  styleUrls: ['./instance-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceSettingsComponent extends OnDestroyComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  instanceId!: string;

  instance?: Instance;

  memoryOptions?: Array<SeedSelectOption<number>>;

  autoScalingSettings?: AutoscalingSettings;

  idleTimeoutOptions: Array<SeedSelectOption<number>> = [
    {
      value: IDLE_DISABLED,
      label: 'Disable Idling',
      dataCy: 'idle-timeout-option',
      dataCyValue: 'Disable Idling'
    },
    ...idleTimeoutValuesMinutes.map((value) => ({
      value: value,
      label: `${timeoutMinutesToString(value)}`,
      dataCy: 'idle-timeout-option',
      dataCyValue: `${value}`
    }))
  ];

  form = this.formBuilder.group<InitAutoscalingSettings>({
    minMemoryGb: 24,
    maxMemoryGb: 24,
    idleTimeoutMinutes: IDLING_TIMEOUT_MINUTES_DEFAULT
  });

  private lastMinMemory = this.form.value.minMemoryGb;
  private lastMaxMemory = this.form.value.maxMemoryGb;

  instanceIdSubject = new ReplaySubject<string>(1);

  isPayingOrgObs: Observable<boolean>;

  myRoleObs: Observable<OrganizationRole>;
  myRole: OrganizationRole | undefined;

  customizeSqlConsoleAccessObs: Observable<boolean>;

  upgradeBtnLabel = 'Upgrade service';

  iamRoleObs: Observable<string>;

  shouldShowIamRoleObs: Observable<boolean>;

  dpNumReplicas: number | undefined;

  readonly getUpgradeDisplayErrorMessage = this.instanceUiService.getUpgradeDisplayErrorMessage;

  constructor(
    private readonly dialog: MatDialog,
    private readonly instanceUiService: InstanceUiService,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceAutoScalingService: InstanceAutoScalingService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    super();

    this.form.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((val) => {
      let minMemoryGb = truthy(val.minMemoryGb);
      let maxMemoryGb = truthy(val.maxMemoryGb);

      if (minMemoryGb > maxMemoryGb) {
        if (minMemoryGb !== this.lastMinMemory) {
          maxMemoryGb = minMemoryGb;
        } else if (maxMemoryGb !== this.lastMaxMemory) {
          minMemoryGb = maxMemoryGb;
        }

        this.form.patchValue({ minMemoryGb, maxMemoryGb });
      }

      this.lastMinMemory = minMemoryGb;
      this.lastMaxMemory = maxMemoryGb;
    });

    this.isPayingOrgObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeBillingStatus(id)),
      map((billingStatus) => isPayingStatus(billingStatus))
    );

    this.myRoleObs = this.organizationStateService.observeCurrentOrganizationRole();

    this.myRoleObs.pipe(takeUntil(this.onDestroy)).subscribe((role) => {
      this.myRole = role;
    });

    this.instanceStateService
      .observeInstance(this.instanceId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((instance) => {
        this.instance = instance;
        this.upgradeBtnLabel = checkInstanceIsUpgradingFromStatus(this.instance?.upgradeStatus)
          ? 'Upgrading service...'
          : 'Upgrade service';
        this.cdr.markForCheck();
      });

    this.shouldShowIamRoleObs = this.organizationStateService.observeCurrentOrganization().pipe(
      map((org) => org.features.includes('FT_ORG_SHOW_IAM_ROLE')),
      distinctUntilChanged()
    );

    const observeIamRole = this.instanceIdSubject.pipe(
      switchMap((instanceId) => this.instanceService.getInstanceIamPrincipal(instanceId)),
      map((result) => result.iamPrincipal)
    );

    this.iamRoleObs = this.shouldShowIamRoleObs.pipe(
      switchMap((shouldShowRole) => (shouldShowRole ? observeIamRole : NEVER))
    );

    this.customizeSqlConsoleAccessObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeOrganization(id)),
      map((org) => org.features.includes('FT_ORG_CUSTOMIZE_DATABASE_ACCESS'))
    );
  }

  ngOnInit(): void {
    assertTruthy(this.instanceId);

    this.instanceIdSubject.next(this.instanceId);

    this.instanceIdSubject
      .pipe(
        takeUntil(this.onDestroy),
        switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId))
      )
      .subscribe((instance) => {
        this.instance = instance;
      });

    this.instanceIdSubject
      .pipe(
        takeUntil(this.onDestroy),
        switchMap((instanceId) =>
          this.instanceAutoScalingService.observeMemorySizeOptionsForExistingInstance(instanceId)
        )
      )
      .subscribe(({ sizes, selectableDomain }) => {
        if (this.isDevInstance()) {
          sizes = [MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY];
        }

        const sliderDomain = selectableDomain;
        const memorySizes = sizes.filter((size, idx) => idx >= sliderDomain.min && idx <= sliderDomain.max);

        this.memoryOptions = memorySizes.map((size) => {
          return {
            label: this.formatMemoryAllocation(size),
            value: size,
            dataCy: 'memory-option',
            dataCyValue: String(size)
          };
        });

        this.cdr.markForCheck();
      });

    this.instanceIdSubject
      .pipe(
        switchMap((instanceId) => this.instanceService.getInstanceAutoscaling(instanceId)),
        takeUntil(this.onDestroy)
      )
      .subscribe((autoScaling) => {
        const minMemoryGb = this.isDevInstance() ? MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY : autoScaling.minMemoryGb;

        const maxMemoryGb = this.isDevInstance() ? MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY : autoScaling.maxMemoryGb;

        const settings: AutoscalingSettings = {
          minMemoryGb,
          maxMemoryGb,
          enableIdleScaling: autoScaling.enableIdleScaling,
          idleTimeoutMinutes: autoScaling.idleTimeoutMinutes
        };

        this.autoScalingSettings = settings;

        this.form.setValue({
          minMemoryGb: settings.minMemoryGb,
          maxMemoryGb: settings.maxMemoryGb,
          idleTimeoutMinutes: settings.enableIdleScaling ? settings.idleTimeoutMinutes : IDLE_DISABLED
        });

        this.dpNumReplicas = autoScaling.minReplicas ?? 0;

        this.cdr.markForCheck();
      });
  }

  ngAfterViewInit(): void {
    this.route.fragment.pipe(takeUntil(this.onDestroy), distinct()).subscribe((fragment) => {
      if (fragment === 'networking') {
        document.querySelector('#networking')?.scrollIntoView();
      }
    });
  }

  ngOnChanges() {
    assertTruthy(this.instanceId);
    this.instanceIdSubject.next(this.instanceId);
  }

  renameServiceClicked(): void {
    this.dialog.open(ChangeInstanceNameDialogComponent, {
      data: this.instanceId,
      width: '100%',
      maxWidth: '517px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }

  resetPasswordClicked(): void {
    assertTruthy(this.instance);
    this.instanceUiService.showInstanceResetPasswordDialog(this.instanceId, this.instance.dbUsername, true);
  }

  onUpgrade(): void {
    const dialogRef = this.instanceUiService.showUpgradeInstanceDialog();
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(async (shouldUpgrade) => {
        if (shouldUpgrade) {
          try {
            await this.instanceService.upgradeInstance(this.instanceId);
          } catch (e) {
            this.instanceUiService.showSnackBar(
              `Error: we couldn't update your service ${this.instance?.name}. Please try again later or if the error persists contact our support.`
            );
          }
        }
      });
  }

  regionName(): string {
    assertTruthy(this.instance);
    const region = REGION_BY_ID[this.instance.regionId];
    return `${region.name} (${this.instance.regionId})`;
  }

  regionIconName(): 'AWS' | 'GCP' | undefined {
    assertTruthy(this.instance);
    if (isGcpRegionId(this.instance.regionId)) {
      return 'GCP';
    } else if (isAwsRegionId(this.instance.regionId)) {
      return 'AWS';
    } else {
      return;
    }
  }

  isDevInstance(): boolean {
    assertTruthy(this.instance);
    return this.instance.instanceTier === 'Development';
  }

  canBeScaled(): boolean {
    return (
      this.instance !== undefined &&
      INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(this.instance.instanceTier) &&
      !this.instance.features.includes('FT_DISABLE_AUTOSCALING') &&
      !this.activeMaintenanceKind
    );
  }

  get canEditAutoscaling(): boolean {
    return this.myRole === 'ADMIN';
  }

  formatMemoryAllocation(memoryAllocation: number): string {
    const instanceTier = this.instance?.instanceTier ?? 'Production';
    const defaultReplicas = INSTANCE_TIER_TO_REPLICA_COUNT_MAP[instanceTier];
    const instanceMemoryGib = memoryAllocation / defaultReplicas;
    const cpuCount = getReplicaCpuCount(instanceTier, instanceMemoryGib);
    return `${instanceMemoryGib} GiB, ${cpuCount} vCPU`;
  }

  formatClusterMemoryAllocation(memoryAllocation: number, instance: Instance, dpNumReplicas: number): string {
    const { instanceTier } = instance;
    const defaultReplicas = INSTANCE_TIER_TO_REPLICA_COUNT_MAP[instanceTier];
    const replicaSize = memoryAllocation / defaultReplicas;
    const replicaCpuCount = getReplicaCpuCount(instanceTier, replicaSize);
    const replicaCount = instanceNumReplicas(instance, dpNumReplicas);
    const clusterMemorySize = replicaSize * replicaCount;
    const clusterCpuCount = replicaCpuCount * replicaCount;
    return `${clusterMemorySize} GiB, ${clusterCpuCount} vCPU`;
  }

  formatMinAllocation(): string {
    if (!this.autoScalingSettings || !this.memoryOptions) {
      return 'Loading...';
    } else {
      return this.formatMemoryAllocation(this.autoScalingSettings.minMemoryGb);
    }
  }

  formatMaxAllocation(): string {
    if (!this.autoScalingSettings || !this.memoryOptions) {
      return 'Loading...';
    } else {
      return this.formatMemoryAllocation(this.autoScalingSettings.maxMemoryGb);
    }
  }

  selectMinMemory(newMin: number): void {
    const { minMemoryGb, maxMemoryGb } = this.form.value;

    if (isNumber(minMemoryGb) && isNumber(maxMemoryGb) && maxMemoryGb < newMin) {
      this.form.patchValue({ minMemoryGb: maxMemoryGb });
    }
  }

  selectMaxMemory(newMax: number): void {
    const { minMemoryGb, maxMemoryGb } = this.form.value;

    if (isNumber(minMemoryGb) && isNumber(maxMemoryGb) && newMax < minMemoryGb) {
      this.form.patchValue({ maxMemoryGb: minMemoryGb });
    }
  }

  formatIdleTimeout() {
    const selectedTimeout = this.form.value['idleTimeoutMinutes'] ?? this.autoScalingSettings?.idleTimeoutMinutes;
    assertTruthy(isDefined(selectedTimeout));

    const minTimeout = Math.min(...idleTimeoutValuesMinutes);
    const timeout = Math.max(minTimeout, selectedTimeout);

    if (timeout === IDLE_DISABLED) {
      return 'Disable Idling';
    } else {
      return timeoutMinutesToString(timeout);
    }
  }

  get isInstanceUpgradeDisabledObs(): Observable<boolean> {
    return this.instanceService.observeCanUpgradeInstance(this.instanceId);
  }

  private async updateAutoScaling(newSettings: Partial<AutoscalingSettings>): Promise<void> {
    assertTruthy(this.autoScalingSettings);

    if (!checkObjectEqualityByShallowCompare(newSettings, this.autoScalingSettings)) {
      const minMemoryGb = this.isDevInstance() ? undefined : newSettings.minMemoryGb;

      const maxMemoryGb = this.isDevInstance() ? undefined : newSettings.maxMemoryGb;

      this.autoScalingSettings = { ...this.autoScalingSettings, ...newSettings };

      await this.instanceService.changeAutoScaling(
        this.instanceId,
        this.autoScalingSettings.enableIdleScaling,
        this.autoScalingSettings.idleTimeoutMinutes,
        minMemoryGb,
        maxMemoryGb
      );

      this.cdr.markForCheck();
    }
  }

  openPaymentMethodDialog(): void {
    BillingConversionDialogComponent.show(this.dialog);
  }

  hasMemoryChanges(): boolean {
    return (
      this.autoScalingSettings !== undefined &&
      isNumber(this.form.value['maxMemoryGb']) &&
      isNumber(this.form.value['minMemoryGb']) &&
      (this.autoScalingSettings.maxMemoryGb !== this.form.value.maxMemoryGb ||
        this.autoScalingSettings.minMemoryGb !== this.form.value.minMemoryGb)
    );
  }

  cancelMemoryChanges(): void {
    if (this.autoScalingSettings) {
      this.form.patchValue({
        minMemoryGb: this.autoScalingSettings.minMemoryGb,
        maxMemoryGb: this.autoScalingSettings.maxMemoryGb
      });
    }
  }

  async applyMemoryChanges(): Promise<void> {
    if (isNumber(this.form.value['minMemoryGb']) && isNumber(this.form.value['maxMemoryGb'])) {
      await this.updateAutoScaling({
        minMemoryGb: this.form.value['minMemoryGb'],
        maxMemoryGb: this.form.value['maxMemoryGb']
      });
      this.snackBar.open('Service size has been updated', 'Dismiss', { duration: 5000 });
    }
  }

  getIdleSettingsFromForm(): IdleSettings {
    const idleSelectValue = this.form.value['idleTimeoutMinutes'] ?? this.autoScalingSettings?.idleTimeoutMinutes;
    assertTruthy(isDefined(idleSelectValue));
    const enableIdleScaling = idleSelectValue !== IDLE_DISABLED;
    return {
      enableIdleScaling,
      idleTimeoutMinutes: enableIdleScaling ? idleSelectValue : IDLING_TIMEOUT_MINUTES_DEFAULT
    };
  }

  hasIdleTimeoutChanges(): boolean {
    const formSettings = this.getIdleSettingsFromForm();
    return (
      this.autoScalingSettings !== undefined &&
      (this.autoScalingSettings.enableIdleScaling !== formSettings.enableIdleScaling ||
        this.autoScalingSettings.idleTimeoutMinutes !== formSettings.idleTimeoutMinutes)
    );
  }

  cancelIdleTimeoutChanges(): void {
    if (this.autoScalingSettings) {
      this.form.patchValue({
        idleTimeoutMinutes: this.autoScalingSettings.idleTimeoutMinutes
      });
    }
  }

  async applyIdleTimeoutChanges(): Promise<void> {
    await this.updateAutoScaling(this.getIdleSettingsFromForm());
    this.snackBar.open('Idle timeout has been updated', 'Dismiss', { duration: 5000 });
  }

  copyToClipboard(text: string): void {
    copy(text);
    this.snackBar.open('Copied to clipboard', 'Dismiss', { duration: 5000 });
  }

  get activeMaintenanceKind(): ActiveMaintenanceKind | undefined {
    const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
    return instance.activeMaintenanceKind;
  }

  get isFullMaintenance(): boolean {
    return this.activeMaintenanceKind === 'fullMaintenance';
  }

  get numReplicasFormatted(): string {
    if (this.dpNumReplicas === undefined || this.instance === undefined) {
      return 'Loading...';
    } else {
      const replicas = instanceNumReplicas(this.instance, this.dpNumReplicas);
      return `${replicas} replicas`;
    }
  }
}
