import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cp-sign-up-checkbox',
  templateUrl: './sign-up-checkbox.component.html',
  styleUrls: ['./sign-up-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpCheckboxComponent implements OnInit {
  hasAcceptedTos = true;

  @Input() defaultValue!: boolean;
  @Input() formGroup?: FormGroup;
  @Output() toggle = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    this.hasAcceptedTos = this.defaultValue;
  }

  onToggle(): void {
    this.hasAcceptedTos = !this.hasAcceptedTos;
    this.toggle.emit(this.hasAcceptedTos);
  }
}
