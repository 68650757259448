import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { trackById } from '@cp/common-services/trackById';
import { OrganizationInvitationDetails } from '@cp/common/protocol/Organization';
import { truthy } from '@cp/common/utils/Assert';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { trace } from '@cp/web/app/common/services/galaxy.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'cp-approve-org-invitations',
  templateUrl: './approve-org-invitations.component.html',
  styleUrls: ['./approve-org-invitations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApproveOrgInvitationsComponent implements OnInit {
  public readonly acceptInvitationsForm: FormGroup = new FormGroup({});
  processingInvitation: OrganizationInvitationDetails | null = null;

  readonly invitationsObs = this.accountStateService.observeOrgInvitations();

  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly organizationService: OrganizationService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    trace('pageLoad', 'window', { namespace: 'app', event: 'pageLoad' });
    const invitations = truthy(
      await firstValueFrom(this.accountStateService.observeOrgInvitations()),
      'No invitations'
    );
    await this.organizationService.markViewedInvitations(invitations);
  }

  async onCreateNewOrganization(): Promise<void> {
    try {
      console.debug('User is creating default organization');
      await this.organizationService.createDefaultOrgForUser();

      console.debug('User successfully created default organization, moving to onboard');
      await this.router.navigateByUrl('/onboard');
    } catch (e) {
      this.snackBar.open(`Unable to create default organization, please refresh page and try again.`, 'Dismiss', {
        duration: 5000
      });
    }
  }

  async acceptInvitation(invitation: OrganizationInvitationDetails): Promise<void> {
    try {
      this.processingInvitation = invitation;
      await this.organizationService.acceptInvitation(invitation.invitationKey);
      await this.organizationStateService.getCurrentOrgOrFail();

      this.snackBar.open('Organization invitation accepted', 'Dismiss', { duration: 5000 });
      await this.router.navigateByUrl('/services');
    } catch (e) {
      this.snackBar.open(`Unable to accept invitation`, 'Dismiss', { duration: 5000 });
    }
    this.processingInvitation = null;
  }

  protected readonly trackById = trackById;
  protected readonly Object = Object;
}
