import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { State } from '@cp/common-services/state/action.applier';
import { StateService } from '@cp/common-services/state/state.service';

@Injectable({
  providedIn: 'root'
})
export class AdminBillingUiService extends BaseUiStateService<AdminBillingUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'adminBilling'], stateService);
  }
}

export interface AdminBillingUiState extends State {
  csvButtonDisabled: boolean;
}
