import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { ClientMetadataKey } from '@cp/common/protocol/Account';
import { getCognitoErrorCode } from '@cp/common/utils/MiscUtils';
import { MAX_SIMPLE_NAME_LENGTH, MIN_SIMPLE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountApiService } from '@cp/web/app/account/account-api.service';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { SignUpUiState } from '@cp/web/app/account/protocol/AccountStates';
import { SignInStorageService } from '@cp/web/app/account/sign-in/sign-in-storage.service';
import { SignUpUiService } from '@cp/web/app/account/sign-up/sign-up-ui.service';
import { AuthService } from '@cp/web/app/auth/auth.service';
import { FullStoryService } from '@cp/web/app/common/services/full-story.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import {
  firstLastNameLengthValidator,
  maxLengthTrimmedValidator,
  minLengthTrimmedValidator,
  userNameValidator,
  validateFormEmail,
  validateFormPassword
} from '@cp/web/app/common/utils/FormValidationUtils';
import { buildRecaptchaMutationObserver } from '@cp/web/app/common/utils/RecaptchaUtils';
import { ScriptsService } from '@cp/web/app/scripts/scripts.service';
import { environment } from '@cp/web/environments/environment';
import { Observable } from 'rxjs';

const SIGN_UP_BLOCKED_REGION_IGNORED_SESSION_KEY = 'signUp-blockedRegionIgnored';

@Component({
  selector: 'cp-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  readonly signUpForm: FormGroup;
  readonly signUpUiStateObs: Observable<SignUpUiState>;
  readonly queryParams: Params;
  hidePassword = true;
  showPasswordRequirements = false;
  private recaptchaObserver: MutationObserver;
  readonly regionBlockedObs: Observable<boolean>;

  userId: string | undefined;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly accountService: AccountService,
    private readonly scriptsService: ScriptsService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly uiService: SignUpUiService,
    private readonly accountStateService: AccountStateService,
    private readonly accountApiService: AccountApiService,
    private readonly snackBar: MatSnackBar,
    private readonly segmentService: SegmentService,
    private readonly fullStoryService: FullStoryService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly signInStorageService: SignInStorageService,
    private readonly signInMetadataService: SignInMetadataService
  ) {
    super();
    this.signInStorageService.clear();
    this.queryParams = activatedRoute.snapshot.queryParams;
    this.signUpUiStateObs = uiService.observeState();
    uiService.setPartialState({
      signUpButtonDisabled: false,
      errorMessage: undefined,
      serverErrorMessage: undefined,
      signUpComplete: false
    });

    authService.subscribeRedirectToHomePageIfAlreadyAuthenticated(this.onDestroy);

    this.signUpForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          maxLengthTrimmedValidator(MAX_SIMPLE_NAME_LENGTH),
          minLengthTrimmedValidator(MIN_SIMPLE_NAME_LENGTH),
          userNameValidator(),
          firstLastNameLengthValidator()
        ]
      ],
      email: ['', [Validators.required, validateFormEmail]],
      password: ['', [Validators.required], validateFormPassword],
      terms: ['', [Validators.requiredTrue]]
    });

    this.recaptchaObserver = buildRecaptchaMutationObserver();
    this.regionBlockedObs = this.accountService.observeRegionIsBlocked();
  }

  ngOnInit(): void {
    try {
      this.scriptsService.loadGoogleTagScript();
      this.recaptchaObserver.observe(document.body, {
        attributes: false,
        childList: true,
        characterData: false
      });
    } catch (error) {
      console.log(error);
    }
  }

  override ngOnDestroy(): void {
    try {
      this.scriptsService.unloadGoogleTagScript();
      this.recaptchaObserver.disconnect();
    } catch (error) {
      console.log(error);
    }
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  get isSubmitDisabled(): boolean {
    return this.signUpForm.invalid;
  }

  async onSubmit(): Promise<void> {
    if (this.isSubmitDisabled) {
      return;
    }

    this.uiService.setPartialState({ signUpButtonDisabled: true, errorMessage: undefined, signUpComplete: false });
    const details = this.signUpForm.value;
    const email = details.email;

    try {
      // Restrict signup to Staging and Dev envs only for clickhouse.com users
      const isInternalEnv = environment.stage === 'staging' || environment.stage === 'dev';
      if (
        (isInternalEnv && !['clickhouse.com', 'qawerk.com', 'redwerk.com'].includes(email.trim().split('@')?.[1])) ||
        email.toLowerCase() === 'alexqawerk@gmail.com'
      ) {
        this.uiService.setStateKey(
          'errorMessage',
          `ClickHouse Cloud is not open to the public at this time. To get early access to our serverless hosted offering, join the <a href="https://clickhouse.com/cloud/" target="_blank">wait list</a> today.`
        );
        this.uiService.setStateKey('signUpButtonDisabled', false);
        return;
      }

      const clientMetadata: Partial<Record<ClientMetadataKey, string>> = {};
      const userId = await this.accountService.signUp(
        details.name,
        details.email,
        details.password,
        this.queryParams,
        clientMetadata
      );

      this.segmentService.identify(email, details.name, userId);
      this.segmentService.trackGaEvent({
        event: 'sign up',
        label: 'email',
        category: 'cloud ui',
        reportToServer: true,
        view: 'signup',
        component: 'form',
        properties: {
          referrer: document.referrer,
          url: window.location.href
        }
      });
      this.fullStoryService.identify(userId);

      this.userId = userId;
      this.uiService.setStateKey('signUpComplete', true);
    } catch (e: any) {
      this.uiService.setStateKey('signUpButtonDisabled', false);
      let cognitoCode = getCognitoErrorCode(e);

      /** if Cognito is not reporting an error, check for CP API error message. */
      if (!cognitoCode) {
        cognitoCode = e.error.message || e.message || 'unknown';
      }
      console.error('sign up error', e, cognitoCode);
      let errorMessage: string;
      switch (cognitoCode) {
        case 'USER_ALREADY_EXISTS':
        case 'UsernameExistsException':
          errorMessage = 'An account with this email already exists. Please sign in or use a different email';
          break;
        case 'USER_PROTOTYPE_ALREADY_EXISTS':
          errorMessage =
            'A partially created account with this email already exists. Please verify your email, sign in or use a different email';
          break;
        case 'InvalidPasswordException':
          errorMessage = e?.message ? e.message : 'Password does not meet all requirements';
          break;
        case 'INVALID_NAME':
          errorMessage = 'Invalid account name. Please avoid using special characters';
          break;
        case 'INVALID_RECAPTCHATOKEN':
        case 'FAILED_RECAPTCHA':
          errorMessage = `Signup failed the recaptcha challenge, please reload the page and try again`;
          break;
        default:
          errorMessage = 'Unable to sign up, please try again later';
          break;
      }
      this.uiService.setPartialState({ errorMessage, serverErrorMessage: cognitoCode });
    }
  }

  initiateGoogleSignIn(): void {
    this.signInWithGoogle().then();
    return;
  }

  async signInWithGoogle(): Promise<void> {
    this.uiService.setPartialState({ signUpButtonDisabled: true, errorMessage: undefined });
    try {
      this.signInStorageService.setSignInInProgress();
      await this.accountService.signInWithGoogle();
    } catch (e) {
      console.error('Google sign in error', e);
    } finally {
      this.uiService.setPartialState({ signUpButtonDisabled: false, errorMessage: undefined });
    }
  }

  /** Returns Tackle token marketplace or undefined if there's no tackle token. */
  get tackleMarketplace(): string | undefined {
    return this.signInMetadataService.metadata.tackleToken?.marketplace;
  }

  get authTitle(): string {
    if (this.tackleMarketplace) {
      const csp = this.tackleMarketplace === 'aws' ? 'AWS' : 'GCP';
      return `Sign up for ClickHouse Cloud on ${csp} Marketplace`;
    }
    return 'Sign up for ClickHouse Cloud';
  }

  get name() {
    return this.signUpForm.get('name');
  }

  /**
   * Handles the click event of the "Continue Anyway" button
   * if the user is in a blocked region.
   */
  onContinueClicked(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'Continue Anyway (region block)',
      category: 'cloud ui',
      view: 'signup',
      component: 'serviceCard'
    });
    this.setBlockedRegionIgnored();
  }

  /**
   * Checks the flag if the user has clicked Continue on the region blocked message.
   * @returns true if the user has clicked Continue.
   */
  get isBlockedRegionIgnored(): boolean {
    return !!window.sessionStorage.getItem(SIGN_UP_BLOCKED_REGION_IGNORED_SESSION_KEY);
  }

  /**
   * Sets the flag that the user has clicked Continue on the region blocked message.
   */
  private setBlockedRegionIgnored(): void {
    window.sessionStorage.setItem(SIGN_UP_BLOCKED_REGION_IGNORED_SESSION_KEY, '1');
  }

  get awsMarketplaceProdPageUrl(): string {
    return `https://aws.amazon.com/marketplace/pp/prodview-${environment.awsMarketplaceProdViewId}`;
  }
}
