<ng-container *ngIf="instancePageUiStateObs | async as uiState">
  <ng-container *ngIf="instanceObs | async as instance">
    <ng-container *ngIf="myOrgRoleObs | async as myOrgRole">
      <div class="side_padding">
        <div class="inner_page_nav">
          <div class="inner_page_title" data-cy="instance-name-header">{{instance.name}}<div *ngVar="!isDedicated ? instance.instanceTier : 'Dedicated' as tierTag" data-cy="dev-instance-chip" class="dev_instance_chip">
            {{tierTag}}
          </div></div>

          <div class="buttons">
            <button
              *ngIf="instance.state !== 'terminating'"
              galaxyClick="servicePage.header.actionsContextMenuOpen"
              galaxyBlur="servicePage.header.actionsContextMenuBlur"
              [matMenuTriggerFor]="instanceMenu"
              class="button actions_button"
              color="primary"
              data-cy="actions-menu-button"
              mat-stroked-button
            >Actions
              <mat-icon class="icon" svgIcon="down_arrow"></mat-icon>
            </button>

            <cp-connect-console-button
              eventPrefix="servicePage.header"
              [instance]="instance"
              color="primary">
            </cp-connect-console-button>
          </div>
        </div>

        <div *ngIf="maintenanceWindow"
             class="maintenance_window_message"
             data-cy="instance-page-maintenance-window-message"
        >
          This service will enter a scheduled maintenance window for approximately
          {{maintenanceWindow.endMaintenanceTime - maintenanceWindow.startMaintenanceTime | durationMinutes: false}}
          on {{maintenanceWindow.startMaintenanceTime | date: 'MMMM d, y, h:mma'}} (local time).
          <a (click)="openMaintenanceWindowDialog($event)"
             data-cy="maintenance-window-learn-more-button"
             class="maintenance_window_learn_more_button">
            Learn more
          </a>
        </div>


        <mat-tab-group
          *ngIf="uiState.activeTab"
          (selectedTabChange)="onTabChange($event)"
          [disableRipple]="true"
          [selectedIndex]="getSelectedTabIndex(uiState)"
          animationDuration="0ms">
          <mat-tab label="Metrics">
            <cp-instance-page-summary [instanceId]="instance.id"
                                      [hasUserData]="!!instanceHasUserData"
                                      class="instance_page_summary"
            ></cp-instance-page-summary>

            <!-- Do not have instance-metrics-dashboard component when tab is closed to avoid unneeded metrics requests. -->
            <cp-instance-metrics-dashboard
              *ngIf="instance.state !== 'provisioning' && instance.state !== 'terminated' && uiState.activeTab === 'METRICS_TAB'"
              [instanceId]="instanceId"
            ></cp-instance-metrics-dashboard>

            <div *ngIf="instance.state === 'provisioning'" class="provisioning_block" data-cy="provisioning-card">
              <mat-icon class="icon" svgIcon="provisioning"></mat-icon>
              <h3>Service is provisioning</h3>
              <div class="details_text">
                This service is being created. Service details will be available in a few moments.
              </div>
            </div>

            <div *ngIf="instance.state === 'terminated'" class="terminated_block">
              <mat-icon class="icon" svgIcon="service_deleted"></mat-icon>
              <h3>Service deleted</h3>
            </div>
          </mat-tab>

          <mat-tab label="Backups">
            <cp-instance-backups *ngIf="myOrgBillingStatusObs | async as orgBillingStatus"
                                 [instanceId]="instance.id"
                                 [orgBillingStatus]="orgBillingStatus"
            ></cp-instance-backups>
          </mat-tab>

          <mat-tab label="Connection">
            <cp-instance-integrations-page [instance]="instance"></cp-instance-integrations-page>
          </mat-tab>
          <mat-tab label="Settings">
            <cp-instance-settings [instanceId]="instanceId"></cp-instance-settings>
          </mat-tab>
        </mat-tab-group>
      </div>

      <mat-menu #instanceMenu="matMenu" class="instance_menu with_padding" xPosition="before">
        <div class="menu_buttons">
          <ng-container *ngVar="(myOrgRole === 'ADMIN') as isAdmin">
            <div class="menu_header">ANALYZE DATA</div>
            <a
              galaxyClick="servicePage.header.actionsContextMenuDataLoad"
              *ngIf="isAdmin && ['running', 'idle', 'awaking'].includes(instance.state) && !isFullMaintenance"
              [href]="consoleImportsUrl"
              target="_blank"
              class="menu_button" data-cy="menu-item-add-data"
              mat-menu-item
            >Add data
            </a>
            <button
              galaxyClick="servicePage.header.actionsContextMenuVisualize"
              (click)="goToDataVisualizations()"
              class="menu_button" data-cy="menu-item-visualize-data"
              mat-menu-item
            >Visualize data
            </button>
            <div *ngIf="isAdmin && !activeMaintenanceKind">
              <div class="menu_hairline"></div>
              <div class="menu_header">MANAGE SERVICE</div>

              <ng-container *ngIf="myOrgBillingStatusObs | async as orgBillingStatus">
                <button
                  galaxyClick="servicePage.header.actionsContextMenuServiceStart"
                  (click)="startInstance(orgBillingStatus)"
                  *ngIf="STATES_VALID_TO_START.includes(instance.state) && isAdmin && !activeMaintenanceKind"
                  class="menu_button" data-cy="menu-item-start"
                  mat-menu-item
                >Start service
                </button>
              </ng-container>

              <button
                galaxyClick="servicePage.header.actionsContextMenuServiceStop"
                (click)="confirmStopInstance(instance)"
                *ngIf="STATES_VALID_TO_STOP.includes(instance.state) && isAdmin && !activeMaintenanceKind"
                class="menu_button" data-cy="menu-item-stop"
                mat-menu-item
              >Stop service
              </button>

              <button
                galaxyClick="servicePage.header.actionsContextMenuServiceDelete"
                (click)="showDeleteInstanceDialog(instance)"
                *ngIf="STATES_VALID_TO_TERMINATE.includes(instance.state) && isAdmin && !activeMaintenanceKind"
                class="delete menu_button"
                data-cy="menu-item-delete"
                mat-menu-item
              >Delete service
              </button>
            </div>
          </ng-container>
        </div>
      </mat-menu>
    </ng-container>
  </ng-container>
</ng-container>
