<div
  *ngVar="['running', 'idle', 'awaking'].includes(instance.state) as isEnabled"
  (click)="$event.stopPropagation()"
  matTooltipPosition="below"
  [matTooltipDisabled]="isEnabled"
  [matTooltip]="getDisabledTooltip(instance.state)"
>
  <button
    [galaxyClick]="buildFullyQualifiedEvent('connectContextMenuOpen')"
    [galaxyBlur]="buildFullyQualifiedEvent('connectContextMenuBlur')"
    [matMenuTriggerFor]="instanceConnectMenu"
    mat-stroked-button
    [class.mat-flat-button]="color === 'primary'"
    [color]="color"
    class="button connect_menu_button"
    color="primary"
    data-cy="connect-menu-button"
    [disabled]="!isEnabled"
  >
    Connect
    <mat-icon class="icon" svgIcon="down_arrow"></mat-icon>
  </button>
  <mat-menu #instanceConnectMenu="matMenu" class="connect_menu with_padding" xPosition="before">
    <div class="menu_buttons">
      <a
        [galaxyClick]="buildFullyQualifiedEvent('connectContextMenuConsoleOpen')"
        class="menu_button"
        (click)="reportSqlConsoleClicked()"
        [href]="getWebConsoleUri(instance)"
        data-cy="open-sql-button"
        target="_blank"
        mat-menu-item
      >
        Open SQL console
      </a>
      <button
        [galaxyClick]="buildFullyQualifiedEvent('connectContextMenuDetailsOpen')"
        (click)="showConnectDialog()"
        class="menu_button show_connect_dialog_button"
        data-cy="connect-button"
        mat-menu-item
        >
        View connection string
        </button>
    </div>
  </mat-menu>
</div>
