import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HelpFlyOutUiService } from '@cp/web/app/help/help-fly-out/help-fly-out-ui.service';
import { HelpFlyOutUiState } from '@cp/web/app/help/protocol/HelpFlyOutUiState';
import { SupportUiService } from '@cp/web/app/support/support-ui.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-help-fly-out',
  templateUrl: './help-fly-out.component.html',
  styleUrls: ['./help-fly-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpFlyOutComponent {
  readonly uiStateObs: Observable<HelpFlyOutUiState>;

  constructor(
    private readonly helpFlyOutUiService: HelpFlyOutUiService,
    private readonly supportUiService: SupportUiService
  ) {
    this.uiStateObs = helpFlyOutUiService.observeState();
  }

  async closeFlyout(): Promise<void> {
    await this.helpFlyOutUiService.hideFlyOut();
  }

  showCreateSupportCaseDialog(): void {
    this.closeFlyout().then();
    this.supportUiService.showCreateCaseDialog();
  }
}
