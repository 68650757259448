<mat-dialog-content>
  <cp-dialog-title [dialogTitle]="title"></cp-dialog-title>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  <div [innerHTML]="text" class="text" [ngClass]="{'text_vertical': buttonLayout === 'VERTICAL'}"></div>
</mat-dialog-content>

<div class="actions" *ngIf="buttonLayout==='HORIZONTAL'">
  <button [mat-dialog-close]="false" class="button secondary_action" mat-stroked-button
          data-cy="cancel-button">Cancel
  </button>
  <button [mat-dialog-close]="true" [ngClass]="buttonStyle" class="button" mat-flat-button
          data-cy="confirm-action-button">{{action}}</button>
</div>

<div *ngIf="buttonLayout==='VERTICAL'" class="vertical_buttons">
  <button [mat-dialog-close]="true" [ngClass]="buttonStyle" class="button" mat-flat-button
          data-cy="confirm-action-button">{{action}}</button>

  <button [mat-dialog-close]="false" class="button secondary_action" mat-flat-button
          data-cy="cancel-button">Cancel
  </button>
</div>
