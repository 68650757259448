import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isDefined } from '@cp/common/protocol/Common';
import { Instance } from '@cp/common/protocol/Instance';
import { OrganizationRestrictions, OrganizationUser } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { getConsoleUrl } from '@cp/web/app/common/utils/WebConsoleUtils';
import { InstanceProvisioningDialogComponent } from '@cp/web/app/instances/instance-provisioning-dialog/instance-provisioning-dialog.component';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { SendOrgInvitesDialogComponent } from '@cp/web/app/organizations/send-org-invites-dialog/send-org-invites-dialog.component';
import { Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cp-instance-integrations-page',
  templateUrl: './instance-integrations-page.component.html',
  styleUrls: ['./instance-integrations-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceIntegrationsPageComponent implements OnInit, OnChanges {
  @Input() instance!: Instance;
  wasProvisioning = false;
  readonly orgRestrictionsObs: Observable<OrganizationRestrictions>;
  readonly myOrgUserObs: Observable<OrganizationUser>;
  constructor(
    private readonly dialog: MatDialog,
    private readonly segmentService: SegmentService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceUiService: InstanceUiService
  ) {
    this.orgRestrictionsObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeRestrictions(id))
    );
    this.myOrgUserObs = this.organizationStateService.observeCurrentOrganizationUser();
  }

  ngOnInit(): void {
    assertTruthy(this.instance);
  }

  ngOnChanges(): void {
    assertTruthy(this.instance);
    if (this.instance.state === 'provisioning') {
      this.wasProvisioning = true;
    }
  }

  showInvitesDialog(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'Invite members',
      category: 'cloud ui service',
      view: 'servicePage',
      component: 'serviceCard'
    });
    SendOrgInvitesDialogComponent.show(this.dialog);
  }

  onLoadDataClick(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'Load data card',
      category: 'cloud ui service',
      view: 'servicePage',
      component: 'serviceCard',
      properties: { isInstanceRunning: this.isEnabled }
    });
    if (!this.isEnabled) {
      this.showInstanceProvisioningDialog();
    } else {
      // Redirect to the SQL console.
      window.open(`${this.getWebConsoleUri(this.instance)}/imports`, '_blank')?.focus();
    }
  }

  showInstanceProvisioningDialog(): void {
    InstanceProvisioningDialogComponent.show(this.dialog, this.instance.id);
  }

  getWebConsoleUri(instance: Instance): string {
    return getConsoleUrl(instance);
  }

  showConnectDialog(): void {
    this.instanceUiService.showConnectDialog(this.instance.id, true);
  }

  get isEnabled(): boolean {
    return ['running', 'idle', 'awaking'].includes(this.instance.state) && this.isNotInFullMaintenance;
  }

  get isNotInFullMaintenance(): boolean {
    return this.instance.activeMaintenanceKind !== 'fullMaintenance';
  }
}
