/** Browser-side version of 'makeSha256Base64Server'.  */
export async function makeSha256Base64Browser(text: string): Promise<string> {
  const textBuffer = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', textBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Make an array of char code numbers.
  const binaryString = String.fromCharCode.apply(null, hashArray); // Convert char codes to a binary string.
  return btoa(binaryString);
}

/** Browser-side sha1 hash function */
export async function makeDoubleSha1HashBrowser(text: string): Promise<string> {
  const textEncoder = new TextEncoder();
  const hash1 = await crypto.subtle.digest('SHA-1', textEncoder.encode(text));
  const hash2 = await crypto.subtle.digest('SHA-1', hash1);
  return Array.from(new Uint8Array(hash2))
    .map((v) => v.toString(16).padStart(2, '0'))
    .join('');
}
