import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './upgrade-instance-dialog.component.html',
  styleUrls: ['./upgrade-instance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradeInstanceDialogComponent {
  constructor(public readonly dialogRef: MatDialogRef<UpgradeInstanceDialogComponent>) {}

  static show(dialog: MatDialog): MatDialogRef<UpgradeInstanceDialogComponent> {
    return dialog.open(UpgradeInstanceDialogComponent, {
      maxWidth: '517px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }
}
