import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import {
  DEFAULT_AUTOSCALING_PRODUCTION_MEMORY,
  DEFAULT_INSTANCE_REPLICAS,
  defaultInstanceMemorySizes,
  idleTimeoutValuesMinutes,
  IDLING_TIMEOUT_MINUTES_DEFAULT,
  InstanceTier,
  MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG
} from '@cp/common/protocol/Instance';
import { OrganizationBillingStatus } from '@cp/common/protocol/Organization';
import { RegionId } from '@cp/common/protocol/Region';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import { timeoutMinutesToString } from '@cp/common/utils/FormatUtils';
import { getMemorySizeOptionsForNewInstance } from '@cp/common/utils/InstanceUtils';
import { getMinMaxValues } from '@cp/cp-common-web/create-instance-auto-scaling-details/utils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { SeedUiModule } from '@cp/seed-ui/lib/seed-ui.module';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { map, Observable, takeUntil } from 'rxjs';

function createMemorySizeOption(clusterSizeInGibibytes: number): SeedSelectOption<number> {
  const replicaSize = clusterSizeInGibibytes / DEFAULT_INSTANCE_REPLICAS;
  const vcpus = clusterSizeInGibibytes / 4 / DEFAULT_INSTANCE_REPLICAS;
  return {
    label: `${replicaSize} GiB, ${vcpus} vCPU`,
    value: clusterSizeInGibibytes,
    dataCy: 'memory-size-option',
    dataCyValue: String(clusterSizeInGibibytes)
  };
}

const IDLE_DISABLED = -1;

export interface AutoScalingSettings {
  enableIdleScaling: boolean;
  idleTimeout: number;
  minMemory: number;
  maxMemory: number;
}

interface FormValues {
  idleTimeout: number;
  minMemory: number;
  maxMemory: number;
}

@Component({
  standalone: true,
  selector: 'cw-create-instance-auto-scaling-details',
  templateUrl: './create-instance-auto-scaling-details.component.html',
  styleUrls: ['./create-instance-auto-scaling-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    SeedUiModule,
    CommonModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CreateInstanceAutoscalingDetailsComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => CreateInstanceAutoscalingDetailsComponent)
    }
  ]
})
export class CreateInstanceAutoscalingDetailsComponent
  extends OnDestroyComponent
  implements ControlValueAccessor, Validator, OnChanges, OnInit
{
  @Input()
  instanceTier!: InstanceTier;

  @Input()
  regionId!: RegionId;

  @Input()
  billingStatus!: OrganizationBillingStatus;

  @Input()
  autoScalingLimits!: GetAutoScalingLimitsResponse;

  private onTouched?: () => void;
  private onChange?: (val: AutoScalingSettings) => void;

  minMemory = DEFAULT_AUTOSCALING_PRODUCTION_MEMORY;
  maxMemory = MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG;

  readonly form = this.formBuilder.nonNullable.group<FormValues>({
    idleTimeout: IDLING_TIMEOUT_MINUTES_DEFAULT,
    minMemory: this.minMemory,
    maxMemory: this.maxMemory
  });

  memorySizeOptions: Array<SeedSelectOption<number>> = defaultInstanceMemorySizes.map(createMemorySizeOption);

  idleTimeoutToSelectOptions: Array<SeedSelectOption> = [
    {
      value: IDLE_DISABLED,
      label: 'Disable Idling',
      dataCy: 'idle-timeout-option',
      dataCyValue: 'Disable Idling'
    },
    ...idleTimeoutValuesMinutes.map((value) => ({
      value: value,
      label: `${timeoutMinutesToString(value)}`,
      dataCy: 'idle-timeout-option',
      dataCyValue: `${value}`
    }))
  ];

  readonly isIdlingShownObs: Observable<boolean>;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly accountStateService: AccountStateService
  ) {
    super();
    this.form.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.onChange?.(this.getAutoScalingSettingsFromForm());
    });

    this.isIdlingShownObs = this.accountStateService
      .observeUserDetails()
      .pipe(
        map((userDetails) => !(userDetails?.features && userDetails.features.includes('FT_IDLE_IN_CREATE_SERVICE')))
      );
  }

  ngOnInit(): void {
    this.assertInputs();
  }

  ngOnChanges(): void {
    this.assertInputs();

    this.updateMemorySizeOptions();
  }

  assertInputs(): void {
    assertTruthy(this.instanceTier);
    assertTruthy(this.regionId);
    assertTruthy(this.billingStatus);
    assertTruthy(this.autoScalingLimits);
  }

  updateMemorySizeOptions() {
    const { sizes, selectableDomain } = getMemorySizeOptionsForNewInstance({
      regionId: this.regionId,
      instanceTier: this.instanceTier,
      billingStatus: this.billingStatus,
      autoScalingLimits: this.autoScalingLimits
    });
    const selectableSizes = sizes.slice(selectableDomain.min, selectableDomain.max + 1);
    this.memorySizeOptions = selectableSizes.map(createMemorySizeOption);
    const minValid = sizes[selectableDomain.min];
    const maxValid = sizes[selectableDomain.max];

    const formMinMemory = truthy(this.form.value.minMemory);
    const formMaxMemory = truthy(this.form.value.maxMemory);

    const [minToSet, maxToSet] = getMinMaxValues(formMinMemory, formMaxMemory, minValid, maxValid);

    // avoid triggering a new render if the value are the same
    if (minToSet !== formMinMemory || maxToSet !== formMaxMemory) {
      this.form.patchValue({ minMemory: minToSet, maxMemory: maxToSet });
    }
  }

  getAutoScalingSettingsFromForm(): AutoScalingSettings {
    const timeoutValue = truthy(this.form.value['idleTimeout']);
    const enableIdleScaling = timeoutValue !== IDLE_DISABLED;
    return {
      enableIdleScaling,
      idleTimeout: enableIdleScaling ? timeoutValue : IDLING_TIMEOUT_MINUTES_DEFAULT,
      minMemory: truthy(this.form.value['minMemory']),
      maxMemory: truthy(this.form.value['maxMemory'])
    };
  }

  writeValue(value: unknown) {
    if (value) {
      this.form.patchValue(value, { emitEvent: false });
    } else {
      this.onChange?.(this.getAutoScalingSettingsFromForm());
    }
  }

  registerOnChange(fn: (val: typeof this.form.value) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(): null | ValidationErrors {
    if (this.form.valid) {
      return null;
    }

    let errors: ValidationErrors = {};

    for (const [controlName, control] of Object.entries(this.form.controls)) {
      if (control.errors) {
        errors[controlName] = control.errors;
      }
    }

    if (this.form.errors) {
      errors = { ...errors, ...this.form.errors };
    }

    return errors;
  }

  minChanged(newMin: number): void {
    const minControl = this.form.controls.minMemory;
    const maxControl = this.form.controls.maxMemory;

    if (maxControl.value < newMin) {
      minControl.setValue(maxControl.value);
    }
  }

  maxChanged(newMax: number): void {
    const minControl = this.form.controls.minMemory;
    const maxControl = this.form.controls.maxMemory;

    if (minControl.value > newMax) {
      maxControl.setValue(minControl.value);
    }
  }
}
