import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { isDefined } from '@cp/common/protocol/Common';
import { Instance } from '@cp/common/protocol/Instance';
import { getDefaultOrganizationRestrictions } from '@cp/common/protocol/Organization';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_SUBJECT_LENGTH,
  SUPPORT_CASE_PRIORITY_TO_DESCRIPTION_MAP,
  SupportCasePriority
} from '@cp/common/protocol/Support';
import {
  checkArraysEquality,
  checkObjectEqualityByShallowCompare,
  getServerErrorMessage
} from '@cp/common/utils/MiscUtils';
import { isEmail } from '@cp/common/utils/ValidationUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { CreateCaseUiService } from '@cp/web/app/support/create-case-dialog/create-case-ui.service';
import { SupportUiService } from '@cp/web/app/support/support-ui.service';
import { SupportService } from '@cp/web/app/support/support.service';
import * as Sentry from '@sentry/angular';
import { distinctUntilChanged, Observable, switchMap, takeUntil } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface FormDetails {
  priority: SupportCasePriority;
  instance?: Instance;
  subject: string;
  description: string;
  additionalEmail1?: string;
  additionalEmail2?: string;
}

@Component({
  selector: 'cp-create-case-dialog',
  templateUrl: './create-case-dialog.component.html',
  styleUrls: ['./create-case-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCaseDialogComponent extends OnDestroyComponent {
  readonly uiStateObs = this.createCaseUiService.observeState();
  readonly formGroup: FormGroup;
  allPriorityOptions: ReadonlyArray<SeedSelectOption> = Object.entries(SUPPORT_CASE_PRIORITY_TO_DESCRIPTION_MAP).map(
    (entry) => ({ label: entry[1], value: entry[0] })
  );

  // Since SEV_1 < SEV_2, lexicographically speaking, we can use >= to determine if a specific priority is allowed.
  priorityOptions: Array<SeedSelectOption> = this.allPriorityOptions.filter(
    (po) => po.value >= getDefaultOrganizationRestrictions().maxCasePriority
  );

  readonly storageIds: Array<string> = [];

  readonly instanceListObs: Observable<Array<SeedSelectOption>>;

  constructor(
    private readonly createCaseUiService: CreateCaseUiService,
    public readonly dialogRef: MatDialogRef<CreateCaseDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly instanceStateService: InstanceStateService,
    private readonly supportService: SupportService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly supportUiService: SupportUiService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.createCaseUiService.setPartialState({ submitButtonDisabled: false, errorMessage: undefined });

    this.formGroup = this.formBuilder.group({
      priority: ['', [Validators.required]],
      instance: ['', []],
      subject: ['', [Validators.required, Validators.maxLength(MAX_SUBJECT_LENGTH)]],
      description: ['', [Validators.required, Validators.maxLength(MAX_DESCRIPTION_LENGTH)]],
      additionalEmail1: ['', []],
      additionalEmail2: ['', []]
    });

    this.instanceListObs = instanceStateService.observeInstances().pipe(
      map((instanceMap) => {
        const instanceList: Array<SeedSelectOption> = Object.values(instanceMap).map((instance) => {
          return {
            label: instance.name,
            value: instance
          };
        });

        if (instanceList.length) {
          instanceList.unshift({ label: 'N/A', value: undefined });
        }
        return instanceList;
      })
    );

    organizationStateService
      .observeCurrentOrganizationId()
      .pipe(
        filter(isDefined),
        switchMap((id) => organizationStateService.observeOrganization(id)),
        map((organization) => organization.restrictions),
        distinctUntilChanged(checkObjectEqualityByShallowCompare),
        takeUntil(this.onDestroy)
      )
      .subscribe((restrictions) => {
        // Since SEV_1 < SEV_2, lexicographically speaking, we can use >= to determine if a specific priority is allowed.
        const priorityOptions: Array<SeedSelectOption> = this.allPriorityOptions.filter(
          (po) => po.value >= restrictions.maxCasePriority
        );

        if (!checkArraysEquality(this.priorityOptions, priorityOptions)) {
          this.priorityOptions = priorityOptions;
          this.cdr.markForCheck();
        }
      });
  }

  async onSubmit() {
    const details: FormDetails = this.formGroup.value;

    const additionalEmails: string[] = [];
    if (isEmail(details.additionalEmail1)) additionalEmails.push(details.additionalEmail1);
    if (isEmail(details.additionalEmail2)) additionalEmails.push(details.additionalEmail2);

    this.createCaseUiService.setPartialState({ submitButtonDisabled: true, errorMessage: undefined });
    try {
      const createCaseResponse = await this.supportService.createCase(
        details.priority,
        details.subject,
        details.description,
        additionalEmails,
        details.instance?.id,
        details.instance?.name,
        this.storageIds
      );

      this.dialogRef.close();
      this.supportUiService.showCaseSubmittedDialog(createCaseResponse.caseId);
    } catch (e) {
      console.error('Cannot create support case', e);
      Sentry.captureException(e);

      const serverErrorMessage = getServerErrorMessage(e);
      if (serverErrorMessage === 'SEV_2_PRIORITY_NOT_ALLOWED') {
        this.createCaseUiService.setStateKey('errorMessage', 'Severity 2 is not allowed');
        return;
      }
      if (serverErrorMessage === 'SEV_1_PRIORITY_NOT_ALLOWED') {
        this.createCaseUiService.setStateKey('errorMessage', `Severity 1 is not allowed`);
        return;
      }

      this.createCaseUiService.setStateKey('errorMessage', 'Unable to create a support case at this time');
    } finally {
      this.createCaseUiService.setStateKey('submitButtonDisabled', false);
    }
  }

  async handleUploadStarted() {
    this.createCaseUiService.setPartialState({ fileUploadInProgress: true });
  }

  async handleUploadFinished(storageIds: Array<string>) {
    this.storageIds.splice(0);
    this.storageIds.push(...storageIds);
    this.createCaseUiService.setPartialState({ fileUploadInProgress: false });
  }

  get subject(): AbstractControl<unknown, unknown> | null {
    return this.formGroup.get('subject');
  }

  get description(): AbstractControl<unknown, unknown> | null {
    return this.formGroup.get('description');
  }
}
