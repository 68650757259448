import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import {
  checkIfPathBelongsToPage,
  installOrganizationIdPageParameterHandler,
  PageComponentWithOrganizationId
} from '@cp/web/app/organizations/current-organization.helper';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';

@Component({
  templateUrl: './org-activity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgActivityComponent extends OnDestroyComponent implements PageComponentWithOrganizationId {
  readonly buildCanonicalPagePathWithOrganizationId = (id: string) => `organizations/${id}/activity`;

  readonly allActivitiesObs = this.organizationService.observeActivities();

  constructor(
    readonly organizationService: OrganizationService,
    readonly organizationStateService: OrganizationStateService,
    readonly route: ActivatedRoute,
    readonly router: Router
  ) {
    super();
    installOrganizationIdPageParameterHandler(this);
  }

  /** Returns true if the given path part or the URL is handled by the current page component. */
  static isPagePath(path: string): boolean {
    return checkIfPathBelongsToPage(path, 'organizations', 'activity', '/organization/activity');
  }
}
