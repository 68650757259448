import { TitleCasePipe } from '@angular/common';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { format3LetterMonthName } from '@cp/common/utils/FormatUtils';

const titleCasePipe = new TitleCasePipe();

export function formatOrgRoleAsPermissionName(role: OrganizationRole): string {
  return titleCasePipe.transform(role);
}

export function formatDateMonthDayYear(dateOrTimestamp: Date | number): string {
  const date = typeof dateOrTimestamp === 'number' ? new Date(dateOrTimestamp) : dateOrTimestamp;
  const month = format3LetterMonthName(date);
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
}

export function formatDateMonthDayYearHourMinutes(dateOrTimestamp: Date | number): string {
  const date = typeof dateOrTimestamp === 'number' ? new Date(dateOrTimestamp) : dateOrTimestamp;
  const month = format3LetterMonthName(date);
  const twoDigits = (v: number) => `${v < 10 ? '0' : ''}${v}`;
  return `${month} ${date.getDate()}, ${date.getFullYear()} ${twoDigits(date.getUTCHours())}:${twoDigits(
    date.getUTCMinutes()
  )}`;
}

export const apiKeyOrgPermissionsTooltipText =
  'Developer: read only access to organization resources.\nAdmin: full access to organization resources.';
