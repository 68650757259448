import { Injectable } from '@angular/core';
import { StateService } from '@cp/common-services/state/state.service';

import {
  BillPeriodDates,
  OrganizationUsagePeriodChart,
  OrganizationUsageReport,
  UsageMetric
} from '@cp/common/protocol/Billing';
import { assertTruthy } from '@cp/common/utils/Assert';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminUsageStateService {
  private readonly STATE_PATH = ['admin-usage'];

  constructor(private readonly stateService: StateService) {}

  /** Returns current usage report state. Uses 'null' state as a 'failed' state. */
  observeOrganizationUsageReport(
    organizationId: string,
    billDate: string
  ): Observable<OrganizationUsageReport | undefined | null> {
    return this.stateService.observePath([...this.STATE_PATH, organizationId, 'report', billDate]);
  }

  /** Sets new usage report state. Uses 'null' state as a 'failed' state. */
  setOrganizationUsageReport(organizationId: string, billDate: string, report: OrganizationUsageReport | null): void {
    const effectiveBillDate = billDate || report?.billDate;
    assertTruthy(effectiveBillDate);
    assertTruthy(report === null || report.organizationId === organizationId);
    assertTruthy(report === null || report.billDate === effectiveBillDate);
    const path = [...this.STATE_PATH, organizationId, 'report', billDate];
    this.stateService.setInPath(path, report);
  }

  /** Returns current usage report chart state. Uses 'null' state as a 'failed' state. */
  observeOrganizationUsagePeriodChart(
    organizationId: string,
    billDate: string,
    metricType: UsageMetric
  ): Observable<OrganizationUsagePeriodChart | undefined | null> {
    return this.stateService.observePath([...this.STATE_PATH, organizationId, 'chart', billDate, metricType]);
  }

  /** Sets new usage report chart state. Uses 'null' state as a 'failed' state. */
  setOrganizationUsagePeriodChart(
    organizationId: string,
    billDate: string,
    metricType: UsageMetric,
    chart: OrganizationUsagePeriodChart | null
  ): void {
    assertTruthy(chart === null || chart.organizationId === organizationId);
    assertTruthy(chart === null || chart.billDate === billDate);
    assertTruthy(chart === null || chart.type === metricType);
    const path = [...this.STATE_PATH, organizationId, 'chart', billDate, metricType];
    this.stateService.setInPath(path, chart);
  }

  /** Returns bill dates for the organization. Uses 'null' state as a 'failed' state. */
  observeOrganizationBillDates(organizationId: string): Observable<Array<BillPeriodDates> | undefined | null> {
    return this.stateService.observePath([...this.STATE_PATH, organizationId, 'bill-dates']);
  }

  /**
   * Sets bill dates for the organization.
   * Uses 'null' state as a 'failed' state.
   * 'nullValueMode' allows to keep the old valid dates even in case of error. See usages for details.
   */
  setOrganizationBillDates(
    organizationId: string,
    billDates: Array<BillPeriodDates> | null,
    nullValueMode: 'keep-non-null-value-if-present' | 'default' = 'default'
  ): void {
    const path = [...this.STATE_PATH, organizationId, 'bill-dates'];
    // Do not reset cached bill dates if the new dates are null (error state).
    if (
      billDates === null &&
      nullValueMode === 'keep-non-null-value-if-present' &&
      this.stateService.getStateInPath(path)
    )
      return;
    this.stateService.setInPath(path, billDates);
  }
}
