import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { assertTruthy } from '@cp/common/utils/Assert';
import { isValidFeedbackText } from '@cp/common/utils/ValidationUtils';
import { AccountService } from '@cp/web/app/account/account.service';
import { AdminBillingService } from '@cp/web/app/admin/admin-billing/admin-billing.service';

@Component({
  templateUrl: './request-credits-dialog.component.html',
  styleUrls: ['./request-credits-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestCreditsDialogComponent {
  requestCreditsText?: string;
  creditsRequestButtonDisabled: boolean = false;

  constructor(
    public readonly dialogRef: MatDialogRef<RequestCreditsDialogComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly accountService: AccountService,
    private readonly billingService: AdminBillingService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  get isValidCreditsDescription(): boolean {
    return isValidFeedbackText(this.requestCreditsText);
  }

  async submitForm(): Promise<void> {
    assertTruthy(this.isValidCreditsDescription);
    this.creditsRequestButtonDisabled = true;
    try {
      if (this.requestCreditsText) {
        await this.billingService.requestAdditionalCredits(this.requestCreditsText);
        this.snackBar.open('Our sales team will be contacting you shortly.', 'Dismiss', { duration: 5000 });
      }
    } catch (unused) {
      this.snackBar.open('There was a problem submitting your request, please try again later', 'Dismiss', {
        duration: 5000
      });
    } finally {
      this.creditsRequestButtonDisabled = false;
      this.dialogRef.close();
    }
    this.cdr.markForCheck();
  }
}
