<div class="side_padding" data-cy="org-api-keys-page">
  <ng-container *ngVar="organizationContext$ | async as organizationContext">
    <ng-container *ngVar="keys$ | async as keys">

      <!-- Loading state. -->
      <cw-loading-indicator *ngIf="keys === null || organizationContext === null"></cw-loading-indicator>

      <!-- No keys state. -->
      <ng-container *ngIf="keys && keys.length === 0 && organizationContext">
        <div class="no_keys_message_container" data-cy="no-keys-message">
          <div class="no_keys_message_container_top">
            <div class="title">Create your first API Key</div>

            <div class="text">
              Manage your ClickHouse Cloud service using APIs.
              <br>
              Includes services, users, network access and scaling information.
              <br>
              <br>
              Does <b>not include</b> database access.
            </div>
            <button (click)="showCreateApiKeyDialog(organizationContext)"
                    class="button new_key_button"
                    color="primary"
                    data-cy="create-first-key-button"
                    mat-flat-button
            >
              Create API Key
            </button>
            <br>
          </div>
          <div class="no_keys_message_container_bottom">
            <div class="text">
              Want to learn more? <a href="https://clickhouse.com/docs/en/cloud/manage/openapi" target="_blank">Read the
              docs.</a>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Table with keys state. -->
      <ng-container *ngIf="keys && keys.length > 0 && organizationContext">
        <div class="inner_page_nav">
          <div class="inner_page_title">API Keys</div>
          <div>
            <button
              (click)="showCreateApiKeyDialog(organizationContext)"
              [disabled]="isCreateNewKeyButtonDisabled(keys)"
              class="button new_key_button"
              color="primary"
              data-cy="create-key-button"
              mat-flat-button
            >
              <mat-icon class="icon svg_fill" svgIcon="plus_icon"></mat-icon>
              New API Key
            </button>
          </div>
        </div>

        <div class="above_table_notice">
          View and create API keys for the ClickHouse Cloud console.
          API permissions mirror user roles and do not grant access to your ClickHouse databases.
          <a href="https://clickhouse.com/docs/en/cloud/manage/openapi" target="_blank">Learn more</a>
        </div>
        <cp-api-keys-table
          [organizationId]="organizationContext.organizationId"
          [currentUserId]="organizationContext.userId"
          [currentUserRole]="organizationContext.role"
          [keys]="keys"
          (editKey)="showEditKeyDialog($event,organizationContext )"
          (toggleKey)="toggleKeyState($event)"
          (deleteKey)="showDeleteKeyDialog($event)"
        ></cp-api-keys-table>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
