import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationPipe } from '@cp/common-services/pipes/duration.pipe';
import { trackById } from '@cp/common-services/trackById';
import { INSTANCE_BACKUP_INTERVAL_MILLIS, InstanceBackup, InstanceBackupStatus } from '@cp/common/protocol/Backup';
import { isDefined } from '@cp/common/protocol/Common';
import { isFeatureEnabled } from '@cp/common/protocol/features/Features';
import { Instance } from '@cp/common/protocol/Instance';
import { OrganizationBillingStatus, OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_HOUR } from '@cp/common/utils/DateTimeUtils';
import { selectBackupsToShow } from '@cp/common/utils/InstanceUtils';
import { HealthIconType } from '@cp/cp-common-web/health-icon/health-icon.component';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { getNextCounterValue } from '@cp/web/app/common/utils/BrowserUtils';
import { InstanceBackupStateService } from '@cp/web/app/instances/instance-backups/instance-backup-state.service';
import { InstanceBackupService } from '@cp/web/app/instances/instance-backups/instance-backup.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { RestoreInstanceBackupDialogComponent } from '@cp/web/app/instances/restore-instance-backup-dialog/restore-instance-backup-dialog.component';
import {
  TrialServiceLimitDialogComponent,
  TrialServiceLimitDialogContentTemplateRef
} from '@cp/web/app/instances/trial-service-limit-dialog/trial-service-limit-dialog.component';
import { TrialServiceLimitUiService } from '@cp/web/app/instances/trial-service-limit-dialog/trial-service-limit-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  interval,
  Observable,
  switchMap,
  tap
} from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cp-instance-backups',
  templateUrl: './instance-backups.component.html',
  styleUrls: ['./instance-backups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceBackupsComponent extends OnDestroyComponent implements OnInit, OnChanges {
  readonly trackById = trackById;
  readonly Date = Date;

  @ViewChild('trialServiceLimitDialogContent')
  trialServiceLimitDialogContent!: TrialServiceLimitDialogContentTemplateRef;

  @Input()
  instanceId!: string;

  @Input()
  orgBillingStatus!: OrganizationBillingStatus;

  readonly nextScheduledBackupTimeObs: Observable<number>;

  readonly backupsObs: Observable<Array<InstanceBackup>>;
  readonly tickObs = interval(1000);
  private readonly instanceIdSubject = new BehaviorSubject<string | undefined>(undefined);
  readonly instanceObs: Observable<Instance>;

  readonly userOrgRoleObs: Observable<OrganizationRole>;
  readonly backupDivIdPrefix = `backup_id_${getNextCounterValue()}_`;

  constructor(
    private readonly durationPipe: DurationPipe,
    private readonly instanceBackupService: InstanceBackupService,
    private readonly instanceBackupStateService: InstanceBackupStateService,
    private readonly instanceStateService: InstanceStateService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly organizationStateService: OrganizationStateService,
    private readonly trialServiceLimitUiService: TrialServiceLimitUiService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();

    this.userOrgRoleObs = organizationStateService.observeCurrentOrganizationRole();

    this.backupsObs = this.instanceIdSubject.pipe(
      filter(isDefined),
      distinctUntilChanged(),
      tap(() => this.instanceBackupService.refreshInstanceBackups(this.instanceId).then()),
      switchMap((instanceId) =>
        combineLatest([
          this.instanceStateService.observeInstance(instanceId).pipe(filter(isDefined)),
          this.instanceBackupStateService.observeInstanceBackups(instanceId)
        ])
      ),
      map(([instance, instanceBackupMap]) => {
        const allInstanceBackupsSortedDesc = Object.values(instanceBackupMap).sort((a, b) => b.createdAt - a.createdAt);
        return selectBackupsToShow(
          allInstanceBackupsSortedDesc,
          instance.features,
          instance.instanceTier,
          instance.countOfBackupsToRetainInDb
        );
      })
    );

    this.nextScheduledBackupTimeObs = this.instanceIdSubject.pipe(
      filter(isDefined),
      distinctUntilChanged(),
      switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId).pipe(filter(isDefined))),
      map((instance) => {
        const backupIntervalMillis =
          instance.backupPeriodHours && isFeatureEnabled(instance.features, 'FT_CUSTOM_BACKUP_PERIOD')
            ? instance.backupPeriodHours * MILLIS_PER_HOUR
            : INSTANCE_BACKUP_INTERVAL_MILLIS;
        return instance.lastBackupStarted + backupIntervalMillis;
      })
    );

    this.instanceObs = this.instanceIdSubject.pipe(
      filter(isDefined),
      distinctUntilChanged(),
      switchMap((instanceId) => this.instanceStateService.observeInstance(instanceId).pipe(filter(isDefined))),
      takeUntil(this.onDestroy)
    );
  }

  ngOnInit(): void {
    this.assertInputs();
    this.instanceIdSubject.next(this.instanceId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.assertInputs();
    this.instanceIdSubject.next(this.instanceId);

    if (changes['orgBillingStatus']) {
      this.trialServiceLimitUiService.setStateKey('isInTrial', this.isInTrial);
    }
  }

  assertInputs(): void {
    assertTruthy(this.instanceId);
    assertTruthy(this.orgBillingStatus);
  }

  convertToHealthIconType(backupStatus: InstanceBackupStatus): HealthIconType {
    switch (backupStatus) {
      case 'done':
        return 'healthy';
      case 'error':
        return 'error';
      case 'in_progress':
        return 'loading';
    }
  }

  getDurationString(backup: InstanceBackup): string {
    const end = backup.finished ? backup.finished : Date.now();
    return this.durationPipe.transform(end - backup.createdAt).replace(/(.*,.*),.*/g, '$1');
  }

  getLastSuccessfulBackupTime(backups: Array<InstanceBackup>): number | undefined {
    const successfulBackups = backups
      .filter((backup) => backup.status === 'done')
      .sort((a, b) => b.createdAt - a.createdAt);
    return successfulBackups.length ? successfulBackups[0].finished : undefined;
  }

  get isInTrial(): boolean {
    return this.orgBillingStatus === 'IN_TRIAL_GRACE_PERIOD' || this.orgBillingStatus === 'IN_TRIAL';
  }

  async showRestoreInstanceBackupDialog(backup: InstanceBackup): Promise<void> {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    const canCreateNewService = await firstValueFrom(
      this.organizationStateService.observeNewServiceRestriction(organizationId)
    );
    if (!canCreateNewService) {
      // Restore backup is blocked, don't attempt to restore backup.
      if (this.isInTrial) {
        // Show trial service limit dialog.
        TrialServiceLimitDialogComponent.show(this.dialog, this.trialServiceLimitDialogContent);
      } else {
        // Show error message.
        this.snackBar.open(
          `Can't create a new service at the moment, probably because you maxed-out the number
of allowed services. Please contact support for help.`,
          'Dismiss',
          { duration: 10000 }
        );
      }
      return;
    }
    RestoreInstanceBackupDialogComponent.show(this.dialog, { backup });
  }

  isBackupIdVisible(backupId: string): boolean {
    const div = document.getElementById(this.backupDivIdPrefix + backupId);
    return !!div && div.offsetWidth >= div.scrollWidth;
  }
}
