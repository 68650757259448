<div class="create_instance_container">
  <form [formGroup]="form" class="form" *ngIf="availableCloudProviders.length > 0">
    <div class="cloud_provider_region_row">
      <div *ngIf="showCloudProviderSelector" class="cloud_provider_selector seed_form_element">
        <label>Cloud provider</label>
        <div class="cloud_providers">
          <div
            data-cy="aws-provider-select-button"
            [galaxyClick]="[buildFullyQualifiedEvent('providerSelect'), { provider: 'aws'}]"
            [ngClass]="{logo_container: true, aws: true, selected: selectedProvider === 'AWS'}"
            (click)="changeProvider('AWS')">

            <mat-icon svgIcon="aws"
                      class="logo"
                      data-cy="cloud-provider-icon"></mat-icon>
          </div>
          <div
            data-cy="gcp-provider-select-button"
            [galaxyClick]="[buildFullyQualifiedEvent('providerSelect'), { provider: 'gcp'}]"
            [ngClass]="{logo_container: true, gcp: true, selected: selectedProvider === 'GCP'}"
            (click)="changeProvider('GCP')">

            <mat-icon svgIcon="gcp"
                      class="logo"
                      data-cy="cloud-provider-icon"></mat-icon>
          </div>
          <div
            data-cy="azure-provider-select-button"
            [galaxyClick]="[buildFullyQualifiedEvent('providerSelect'), { provider: 'azure'}]"
            [ngClass]="{logo_container: true, azure: true, selected: selectedProvider === 'AZURE'}"
            (click)="changeProvider('AZURE')">
            <div class="logo"></div>
          </div>
          <input
            type="hidden"
            [ngModel]="selectedProvider"
            [value]="selectedProvider"
            spellcheck="false"
            formControlName="provider" name="provider"/>
        </div>
      </div>
      <ng-container *ngIf="availableCloudProviders.includes(selectedProvider)">
        <div class="seed_form_element service_region_select_div">
          <label>Region</label>
          <seed-select
            [galaxyClick]="buildFullyQualifiedEvent('regionSelectorFocus')"
            [galaxyBlur]="buildFullyQualifiedEvent('regionSelectorBlur')"
            [galaxyChange]="buildFullyQualifiedEvent('regionSelectorSelect')"
            [options]="instanceRegionOptions"
            [value]="defaultRegion"
            [template]="regionTemplate"
            data-cy="service-region-select"
            formControlName="region"
          ></seed-select>
        </div>
      </ng-container>
    </div>
    <div *ngIf="successMessage" class="waitlist_registration_success_">
      <mat-icon svgIcon="check_icon" class="success_icon"></mat-icon>
      {{successMessage}}
    </div>
    <ng-container
      *ngIf="availableCloudProviders.includes(selectedProvider)">
      <div class="seed_form_element service_name_div">
        <label>Service name</label>
        <input
          spellcheck="false"
          [galaxyClick]="buildFullyQualifiedEvent('serviceNameInputFocus')"
          [galaxyBlur]="buildFullyQualifiedEvent('serviceNameBlur')"
          data-cy="service-name-input" formControlName="name" name="instanceName"
          autocomplete="off" placeholder="A short, unique name for your service" type="text">
        <div
          *ngIf="!form.controls['name'].valid && form.controls['name'].touched"
          class="seed_error">
          {{serviceError}}
        </div>
      </div>
      <div *ngIf="isIdlingShownObs | async" class="seed_form_element">
        <label class="idling_label">Idle service time
          <mat-icon class="info_icon" svgIcon="info_circle" [matTooltip]="idlingTooltip"
                    matTooltipPosition="right"></mat-icon>
        </label>
        <seed-select class="select"
                     [options]="idleTimeoutToSelectOptions"
                     [value]="form.controls['idleTimeout'].value"
                     formControlName="idleTimeout"
                     data-cy="idle-timeout-select"
                     [dataCy]="'idle-timeout-select-options'"
        ></seed-select>
      </div>
      <div class="seed_form_element service_tiers_div">
        <label>Purpose</label>
        <seed-tab
          data-cy="service-tier-tabs"
          formControlName="tier"
          [labels]="serviceTabLabels"
          [initialValue]="currentTier"
          [contentTemplate]="serviceTierDetails"
          (selectionChange)="updateSelectionTier($event)"
          [galaxyChange]="buildFullyQualifiedEvent('purposeSelect')"
        ></seed-tab>
        <div class="service_private_link_message">
          {{selectedProvider === 'AWS' ? "AWS Private Link support, " : selectedProvider === 'GCP' ? "GCP Private Service Connect support, " : ""}}
          Uptime SLAs, and automatic scaling are
          available for production services only
        </div>
      </div>
    </ng-container>
    <div *ngIf="selectedProvider === 'AZURE' && !availableCloudProviders.includes(selectedProvider)"
         class="waitlist_box">
      <div class="seed_h4">Azure is coming soon!</div>
      <div class="waitlist_text">Join the waitlist to be notified when it becomes available or get started with
        <a class="aws_link" (click)="changeProvider(availableCloudProviders[0])">
          ClickHouse Cloud on {{availableCloudProviders[0] === 'GCP' ? 'GCP' : 'AWS'}}.
        </a>
      </div>
      <button
        [galaxyClick]="buildFullyQualifiedEvent('azureWaitlistButtonSelect')"
        class="waitlist_button"
        color="primary" data-cy="join-waitlist-button" mat-flat-button
        (click)="joinWaitlist()"
      >
        Join waitlist
      </button>
    </div>
  </form>
</div>

<ng-template #regionTemplate let-item="item">
  <div *ngIf="item.value" class="region_item" data-cy="region-item">
    <mat-icon [svgIcon]="item.value['country'] + '_flag'" class="flag_icon"></mat-icon>
    <div class="region_name">{{item.value.name}}&nbsp;<span class="region_id"
                                                            data-cy="region-id">({{item.value.displayId}})</span></div>
  </div>
</ng-template>

<ng-template #serviceTierDetails let-label>
  <div *ngVar="(label === 'Development'? devServiceDetailsItems : productionServiceDetailsItems) as items">
    <div *ngFor="let item of items" class="service_tier_item">
      <mat-icon class="icon" svgIcon="check_icon"></mat-icon>
      <div>{{item}}</div>
    </div>
  </div>
</ng-template>
