import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import {
  AdditionalCreditsRequest,
  BILLING_API_PATH,
  ConfirmUpdatedPaymentMethodRequest,
  ConfirmUpdatedPaymentMethodResponse,
  GetClientSecretRequest,
  GetClientSecretResponse,
  GetOrganizationBillingDetailsRequest,
  GetOrganizationBillingDetailsResponse,
  GetUsageStatementRequest,
  GetUsageStatementResponse,
  OrganizationDetails,
  UpdateOrganizationAddressRequest,
  UpdateOrganizationBillingContact
} from '@cp/common/protocol/Billing';
import { Organization } from '@cp/common/protocol/Organization';
import {
  TackleSubscriptionRequest,
  TackleSubscriptionResponse,
  TackleSubscriptionToken
} from '@cp/common/protocol/Tackle';

@Injectable()
export class BillingApiService {
  constructor(private readonly restService: RestService) {}

  async getOrganizationBillingDetails(organizationId: string): Promise<GetOrganizationBillingDetailsResponse> {
    const request: GetOrganizationBillingDetailsRequest = {
      rpcAction: 'getOrganizationBillingDetails',
      organizationId
    };
    return await this.restService.post<GetOrganizationBillingDetailsResponse>(BILLING_API_PATH, request);
  }

  async getUsageStatement(organizationId: string, billId: string): Promise<GetUsageStatementResponse> {
    const request: GetUsageStatementRequest = {
      rpcAction: 'getUsageStatement',
      organizationId,
      billId
    };
    return await this.restService.post<GetUsageStatementResponse>(BILLING_API_PATH, request);
  }

  async updateOrganizationBillingDetails(
    organizationId: string,
    organizationDetails: OrganizationDetails
  ): Promise<void> {
    const request: UpdateOrganizationAddressRequest = {
      rpcAction: 'updateOrganizationBillingDetails',
      organizationId,
      ...organizationDetails
    };
    await this.restService.post<void>(BILLING_API_PATH, request);
  }

  async getClientSecret(organizationId: string): Promise<GetClientSecretResponse> {
    const request: GetClientSecretRequest = {
      rpcAction: 'getClientSecret',
      organizationId
    };
    return await this.restService.post<GetClientSecretResponse>(BILLING_API_PATH, request);
  }

  async confirmUpdatedPaymentMethod(
    organizationId: string,
    paymentMethodUpdateId: string
  ): Promise<ConfirmUpdatedPaymentMethodResponse> {
    const request: ConfirmUpdatedPaymentMethodRequest = {
      rpcAction: 'confirmUpdatedPaymentMethod',
      paymentMethodUpdateId,
      organizationId
    };
    return await this.restService.post<ConfirmUpdatedPaymentMethodResponse>(BILLING_API_PATH, request);
  }

  async updateBillingContact(organizationId: string, billingContact: string): Promise<void> {
    const request: UpdateOrganizationBillingContact = {
      rpcAction: 'updateOrganizationBillingContact',
      organizationId,
      billingContact
    };
    await this.restService.post<void>(BILLING_API_PATH, request);
  }

  async handleTackleSubscription(
    token: TackleSubscriptionToken,
    organizationDetails: OrganizationDetails
  ): Promise<Organization> {
    const request: TackleSubscriptionRequest = {
      rpcAction: 'handleTackleSubscription',
      token,
      organizationDetails
    };
    const { organization } = await this.restService.post<TackleSubscriptionResponse>(BILLING_API_PATH, request);
    return organization;
  }

  async requestAdditionalCredits(creditsText: string): Promise<void> {
    const request: AdditionalCreditsRequest = { creditsText, rpcAction: 'requestCredits' };
    await this.restService.post<void>(BILLING_API_PATH, request);
  }
}
