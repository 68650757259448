<mat-dialog-content data-cy="add-ip-access-list-entry-dialog">
  <h4>Add entry</h4>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>

  <cp-add-ip-access-list-form-and-table
    eventPrefix="servicePage.security"
    [ipAccessList]="ipAccessList"
    (ipAccessListChange)="ipAccessList = $event"
  ></cp-add-ip-access-list-form-and-table>

  <div class="dialog_buttons_row">
    <button (click)="save()"
            galaxyClick="servicePage.security.whitelistSubmitButtonSelect"
            [disabled]="(ipAccessList.length === 0 && !isSwitchFromAnywhereMode) || isSubmitInProgress"
            color="primary"
            mat-flat-button
            data-cy="save-button"
    >
      <mat-icon *ngIf="isSubmitInProgress" class="button_loader_icon icon svg_stroke" svgIcon="loader"></mat-icon>
      Submit entries
    </button>
    <a
      galaxyClick="servicePage.security.whitelistCancelButtonSelect"
      (click)="dialogRef.close()" class="cancel_button" data-cy="cancel-button">Cancel</a>
  </div>

</mat-dialog-content>
