import { BAD_REQUEST } from '@cp/common/utils/HttpError';

/** Type of the action expected to be completed for/by user. */
export type PendingUserActionType =
  /** A user has unhandled Tackle subscription token. 'data' is a serialized Tackle subscription token. */
  | 'tackle-subscription'
  /** User will see a snackbar message after sign-in. Used for tests. */
  | 'snackbar-message'
  /** User will see am entry questionnaire after completing the onboarding. */
  | 'entry-questionnaire'
  /** User needs to complete the onboarding flow. */
  | 'onboarding'
  /** MFA update modal will be shown asking user to update his settings */
  | 'mfa-update';

/**
 * All pending actions in this list won't be triggered until PendingUserActionsService.triggerPendingActionManually
 * is called explicitly.
 */
const MANUALLY_TRIGGERED_ACTIONS: ReadonlyArray<PendingUserActionType> = [
  'tackle-subscription',
  'entry-questionnaire',
  'onboarding'
];

/** Action expected to be completed for/by user. */
export interface PendingUserAction {
  type: PendingUserActionType;
  data?: string;
}

export function validatePendingUserActionType(type: unknown): PendingUserActionType {
  if (
    type === 'tackle-subscription' ||
    type === 'snackbar-message' ||
    type === 'entry-questionnaire' ||
    type === 'onboarding'
  )
    return type;
  throw new Error(`${BAD_REQUEST}: Invalid action type: ${type}`);
}

export function isManuallyTriggeredAction(type: PendingUserActionType): boolean {
  return MANUALLY_TRIGGERED_ACTIONS.includes(type);
}
