<mat-dialog-content data-cy="confirm-email-expiration-dialog">
  <div class="content_center">
    <div class="title">Verification link expired</div>
    <mat-icon svgIcon="clock" class="clock_icon"></mat-icon>
    <div class="text">The verification link used has now expired. To request a new link, please click below.</div>
    <a
      galaxyClick="auth.verifyEmail.resendLinkSelect"
      (click)="resendConfirmationEmail()">
      Resend verification link
    </a>
  </div>
</mat-dialog-content>
