import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { FullStoryService } from '@cp/web/app/common/services/full-story.service';
import { PageService } from '@cp/web/app/common/services/page.service';
import { BehaviorSubject } from 'rxjs';
import { GalaxyService, trace } from './common/services/galaxy.service';
import { SegmentService } from './common/services/segment.service';

@Component({
  selector: 'cp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  loadingSubject = new BehaviorSubject(true);

  constructor(
    private router: Router,
    private readonly segmentService: SegmentService,
    private readonly fullStoryService: FullStoryService,
    private readonly accountStateService: AccountStateService,
    private readonly galaxyService: GalaxyService,
    private readonly pageService: PageService
  ) {
    this.router.events.subscribe((evt: unknown) => {
      if (evt instanceof NavigationStart) {
        this.setTitle(evt.url);
      }
      if (evt instanceof NavigationEnd || evt instanceof NavigationCancel || evt instanceof NavigationError) {
        this.loadingSubject.next(false);
        if (evt instanceof NavigationEnd) {
          segmentService.reportPageView();
          trace('pageLoad', 'window', { namespace: 'app', event: 'pageLoad' });
        }
      }
    });
    this.fullStoryService.init();
  }

  private setTitle(url: string): void {
    this.pageService.setTitleByUrl(url);
  }

  @HostListener('window:load')
  async onLoad(): Promise<void> {
    if (await this.accountStateService.isAuthenticated()) {
      this.galaxyService.track({
        event: 'auth.window.load',
        interaction: 'trigger'
      });
    }
  }

  @HostListener('window:focus')
  async onFocus(): Promise<void> {
    if (await this.accountStateService.isAuthenticated()) {
      this.galaxyService.track({
        event: 'auth.window.focus',
        interaction: 'trigger'
      });
    }
  }

  @HostListener('window:blur')
  async onBlur(): Promise<void> {
    if (await this.accountStateService.isAuthenticated()) {
      this.galaxyService.track({
        event: 'auth.window.blur',
        interaction: 'trigger'
      });
    }
  }
}
