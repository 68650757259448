import { Instance, InstanceState } from '@cp/common/protocol/Instance';
import { truthy } from '@cp/common/utils/Assert';
import { environment } from '@cp/web/environments/environment';
import { isAuth0Mode } from '@cp/web/app/auth0-utils';

export function getConsoleUrl(instance?: Instance): string {
  const extraParam = isAuth0Mode ? '?auth0=true' : '';
  if (instance) {
    return `${environment.consoleUrl}/services/${instance.id}${extraParam}`;
  } else {
    return `${environment.consoleUrl}/${extraParam}`;
  }
}

export function getConsoleTableUrl(instanceId: string, databaseName: string, tableName: string) {
  return `${environment.consoleUrl}/services/${instanceId}/database/${databaseName}/table/${tableName}`;
}

function getPlayUiUrl(instance: Instance): string {
  const httpsEndpoint = truthy(instance.endpoints.https);
  const protocol = environment.stage === 'local' ? 'http' : 'https';
  return `${protocol}://${httpsEndpoint.hostname}:${httpsEndpoint.port}/play?user=${instance.dbUsername}&theme=dark`;
}

export function getWebConsoleUri(isNewConsoleUi: boolean, instance: Instance): string {
  if (isNewConsoleUi) {
    return getConsoleUrl(instance);
  } else {
    return getPlayUiUrl(instance);
  }
}

export function getConsoleDisabledTooltip(state: InstanceState) {
  if (['running', 'idle'].includes(state)) {
    return '';
  }

  if (state === 'provisioning') {
    return 'SQL Console will be available after this service is provisioned';
  }

  if (state === 'starting') {
    return 'SQL Console will be available when this service is running';
  }

  return 'Start this service to enable the SQL Console';
}
