import { Injectable } from '@angular/core';
import { StateService } from '@cp/common-services/state/state.service';
import { isDefined } from '@cp/common/protocol/Common';
import { OpenapiKey } from '@cp/common/protocol/OpenapiKey';
import { truthy } from '@cp/common/utils/Assert';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiKeysStateService {
  private readonly STATE_PATH = ['api-keys'];

  constructor(private readonly stateService: StateService) {}

  setOrganizationApiKeys(organizationId: string, apiKeys: Array<OpenapiKey>): void {
    const keysRecord: Record<string, OpenapiKey> = {};
    for (const key of apiKeys) {
      keysRecord[key.id] = key;
    }
    this.stateService.setInPath([...this.STATE_PATH, 'organizations', organizationId], keysRecord);
  }

  cleanOrganizationData(organizationId: string) {
    this.stateService.deletePath([...this.STATE_PATH, 'organizations', organizationId]);
  }

  observeOrganizationApiKeys(organizationId: string): Observable<Record<string, OpenapiKey>> {
    return this.stateService
      .observePath<Record<string, OpenapiKey>>([...this.STATE_PATH, 'organizations', organizationId])
      .pipe(filter(isDefined));
  }

  observeOrganizationApiKey(organizationId: string, keyId: string): Observable<OpenapiKey | undefined> {
    return this.stateService.observePath<OpenapiKey>([...this.STATE_PATH, 'organizations', organizationId, keyId]);
  }

  getOrganizationApiKeyOrFail(organizationId: string, keyId: string): OpenapiKey {
    return truthy(
      this.stateService.getStateInPath<OpenapiKey>([...this.STATE_PATH, 'organizations', organizationId, keyId]),
      () => `Organization key is not found! Organization: ${organizationId}, keyId: ${keyId}`
    );
  }
}
