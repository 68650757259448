import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApproximateTimePipe } from '@cp/common-services/pipes/approximate-time.pipe';
import { LinkifyPipe } from '@cp/common-services/pipes/linkify.pipe';

@NgModule({
  declarations: [ApproximateTimePipe, LinkifyPipe],
  imports: [],
  providers: [DatePipe],
  exports: [ApproximateTimePipe, LinkifyPipe]
})
export class CpCommonServicesModule {}
