import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  checkCanDeleteOpenapiKey,
  checkCanUpdateOpenapiKey,
  getOpenapiKeyDisplayStatus,
  isExpiredOpenapiKey,
  OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS,
  OpenapiKey,
  OpenapiKeyActorWithRole
} from '@cp/common/protocol/OpenapiKey';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import {
  apiKeyOrgPermissionsTooltipText,
  formatDateMonthDayYear,
  formatDateMonthDayYearHourMinutes,
  formatOrgRoleAsPermissionName
} from '@cp/web/app/organizations/api-keys/api-key-ui-utils';

@Component({
  selector: 'cp-api-keys-table',
  templateUrl: './api-keys-table.component.html',
  styleUrls: ['./api-keys-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiKeysTableComponent implements OnInit, OnChanges {
  @Input() organizationId!: string;
  @Input() currentUserId!: string;
  @Input() currentUserRole!: OrganizationRole;
  @Input() keys!: Array<OpenapiKey>;

  @Output() editKey = new EventEmitter<OpenapiKey>();
  @Output() toggleKey = new EventEmitter<OpenapiKey>();
  @Output() deleteKey = new EventEmitter<OpenapiKey>();

  readonly displayedColumns = ['key', 'permissions', 'status', 'expiration', 'creation', 'actions'];

  constructor() {}

  ngOnInit(): void {
    assertTruthy(this.organizationId && this.keys && this.currentUserId && this.currentUserRole);
  }

  ngOnChanges(): void {
    assertTruthy(this.organizationId && this.keys && this.currentUserId && this.currentUserRole);
  }

  readonly formatStatus = getOpenapiKeyDisplayStatus;

  formatLastUsedDate(key: OpenapiKey): string {
    return key.useDate ? `Last used ${formatDateMonthDayYear(key.useDate)}` : `Hasn't been used yet`;
  }

  formatExpirationDate(key: OpenapiKey): string {
    return key.expirationDate === OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS
      ? 'Never'
      : formatDateMonthDayYearHourMinutes(key.expirationDate);
  }

  formatCreationDate(key: OpenapiKey): string {
    return `On ${formatDateMonthDayYear(key.creationDate)}`;
  }

  getStatusBadgeClass(key: OpenapiKey): string {
    if (isExpiredOpenapiKey(key)) return 'badge_error';
    return key.state === 'enabled' ? 'badge_success' : 'badge_warning';
  }

  /** Check if current user can toggle key: 'enabled'<->'disabled'. */
  canToggle(key: OpenapiKey): boolean {
    return !isExpiredOpenapiKey(key) && this.canEdit(key);
  }

  /** Check if current user can edit key. */
  canEdit(key: OpenapiKey): boolean {
    const actor: OpenapiKeyActorWithRole = {
      actorType: 'user',
      actorId: this.currentUserId,
      role: this.currentUserRole
    };
    return checkCanUpdateOpenapiKey(actor, key);
  }

  /** Check if current user can delete key. */
  canDelete(key: OpenapiKey): boolean {
    const actor: OpenapiKeyActorWithRole = {
      actorType: 'user',
      actorId: this.currentUserId,
      role: this.currentUserRole
    };
    return checkCanDeleteOpenapiKey(actor, key);
  }

  readonly isExpired = isExpiredOpenapiKey;
  readonly formatOrgRole = formatOrgRoleAsPermissionName;
  readonly apiKeyOrgPermissionsTooltipText = apiKeyOrgPermissionsTooltipText;
}
