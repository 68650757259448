import { State } from '@cp/common-services/state/action.applier';
import { SupportCase, SupportCaseDetails, SupportCaseStatus } from '@cp/common/protocol/Support';

export interface SupportCasesState extends State {
  cases: Record<string, SupportCase | SupportCaseDetails>;
  currentCaseId: string | undefined;
}

export interface CreateCaseState extends State {
  submitButtonDisabled: boolean;
  fileUploadInProgress: boolean;
  errorMessage: string;
}

export const supportCaseStatusDisplay: Record<SupportCaseStatus, { text: string; className: string }> = {
  NEW: { text: 'New', className: 'new' },
  CLOSED: { text: 'Closed', className: 'closed' },
  WAITING_ON_CLICKHOUSE: { text: 'Waiting on ClickHouse', className: 'waiting_clickhouse' },
  WAITING_ON_CUSTOMER: { text: 'ClickHouse responded', className: 'waiting_customer' }
};
