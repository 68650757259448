import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignInStorageService } from '@cp/web/app/account/sign-in/sign-in-storage.service';
import { AuthService } from '@cp/web/app/auth/auth.service';

@Component({
  selector: 'cp-sign-in-auth0',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInAuth0Component implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly signInStorageService: SignInStorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.data['isSignIn']) {
      this.signIn();
    } else {
      this.signUp();
    }
  }

  signIn(): void {
    this.signInStorageService.setSignInInProgress();
    this.authService.signinWithRedirect('login');
  }

  signUp(): void {
    this.signInStorageService.setSignInInProgress();
    this.authService.signinWithRedirect('signup');
  }
}
