import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FontsResolverService } from '@cp/common-services/fonts-resolver.service';
import { ConfirmEmailComponent } from '@cp/web/app/account/confirm-email/confirm-email.component';
import { EmailVerificationErrorAuth0CallbackComponent } from '@cp/web/app/account/email-verification-error-auth0-callback/email-verification-error-auth0-callback.component';
import { ForgotPasswordComponent } from '@cp/web/app/account/forgot-password/forgot-password.component';
import { VerifyTotpGuard } from '@cp/web/app/account/mfa/verify-totp/verify-totp-guard.service';
import { VerifyTotpComponent } from '@cp/web/app/account/mfa/verify-totp/verify-totp.component';
import { ProfileComponent } from '@cp/web/app/account/profile/profile.component';
import { ResendConfirmEmailComponent } from '@cp/web/app/account/resend-confirm-email/resend-confirm-email.component';
import { ResetPasswordComponent } from '@cp/web/app/account/reset-password/reset-password.component';
import { SignInSignUpPageIfAuthenticatedGuard } from '@cp/web/app/account/sign-in-sign-up-page-if-authenticated-guard.service';
import { PostSignInGuard } from '@cp/web/app/account/sign-in/post-sign-in-guard.service';
import { SignInAuth0Component } from '@cp/web/app/account/sign-in/sign-in-auth0.component';
import { SignInComponent } from '@cp/web/app/account/sign-in/sign-in.component';
import { SignUpComponent } from '@cp/web/app/account/sign-up/sign-up.component';
import { TermsOfServiceComponent } from '@cp/web/app/account/sign-up/terms-of-service/terms-of-service.component';

import { AdminComponent } from '@cp/web/app/admin/admin.component';
import { AuthGuardService } from '@cp/web/app/auth/auth-guard.service';
import { NonAuthenticatedUsersOnlyGuardService } from '@cp/web/app/auth/non-auth-guard.service';
import { Auth0DemoComponent } from '@cp/web/app/auth0-demo/auth0-demo.component';
import { isAuth0Mode } from '@cp/web/app/auth0-utils';
import { OnboardGuardService } from '@cp/web/app/common/components/onboard/onboard-guard.service';
import { OnboardComponent } from '@cp/web/app/common/components/onboard/onboard.component';
import { ProtectedLayoutComponent } from '@cp/web/app/common/components/protected-layout/protected-layout.component';
import { SidebarLayoutComponent } from '@cp/web/app/common/components/sidebar-layout/sidebar-layout.component';
import { PageAccessGuard } from '@cp/web/app/common/services/page-access.guard';
import { InstancePageComponent } from '@cp/web/app/instances/instance-page/instance-page.component';
import { InstancesResolverService } from '@cp/web/app/instances/instances-resolver.service';
import { InstancesComponent } from '@cp/web/app/instances/instances.component';
import { IntegrationSummaryComponent } from '@cp/web/app/marketplace/integration-summary/integration-summary.component';
import { MarketplaceComponent } from '@cp/web/app/marketplace/marketplace.component';
import { OauthAuthorizerComponent } from '@cp/web/app/oauth/oauth-authorizer/oauth-authorizer.component';
import { OauthResolver } from '@cp/web/app/oauth/oauth.resolver';
import { OrganizationApiKeysPageComponent } from '@cp/web/app/organizations/api-keys/organization-api-key-page/organization-api-keys-page.component';
import { ApproveOrgInvitationsComponent } from '@cp/web/app/organizations/approve-org-invitations/approve-org-invitations.component';
import { OrgActivityComponent } from '@cp/web/app/organizations/org-activity/org-activity.component';
import { OrgInviteExpiredComponent } from '@cp/web/app/organizations/org-invite-expired/org-invite-expired.component';
import { OrganizationsResolver } from '@cp/web/app/organizations/organizations.resolver';
import { TackleFirmographicsGuardService } from '@cp/web/app/organizations/tackle-firmographics/tackle-firmographics-guard.service';
import { TackleFirmographicsComponent } from '@cp/web/app/organizations/tackle-firmographics/tackle-firmographics.component';
import { UsersPageComponent } from '@cp/web/app/organizations/users-page/users-page.component';
import { LearnPageComponent } from '@cp/web/app/support/learn-page/learn-page.component';
import { SupportCasePageComponent } from '@cp/web/app/support/support-case-page/support-case-page.component';
import { SupportPageComponent } from '@cp/web/app/support/support-page/support-page.component';

const routes: Routes = [
  {
    path: '',
    resolve: {
      fonts: FontsResolverService
    },
    children: [
      {
        path: '',
        component: ProtectedLayoutComponent,
        children: [
          {
            path: 'onboard',
            component: OnboardComponent,
            canActivate: [OnboardGuardService]
          },
          {
            path: 'marketplaceOnboarding',
            component: TackleFirmographicsComponent,
            canActivate: [TackleFirmographicsGuardService]
          },
          {
            path: 'approveOrgInvitations',
            component: ApproveOrgInvitationsComponent
          },
          {
            path: 'termsOfService',
            component: TermsOfServiceComponent
          },
          {
            path: '',
            component: SidebarLayoutComponent,
            resolve: {
              oauth: OauthResolver
            },
            children: [
              { path: '', redirectTo: 'services', pathMatch: 'full' },
              { path: 'services', component: InstancesComponent },
              {
                path: 'service/:instanceId/backups',
                component: InstancePageComponent,
                resolve: { instances: InstancesResolverService }
              },
              {
                path: 'service/:instanceId/settings',
                component: InstancePageComponent,
                resolve: { instances: InstancesResolverService }
              },
              {
                path: 'service/:instanceId/connection',
                component: InstancePageComponent,
                resolve: { instances: InstancesResolverService }
              },
              {
                path: 'service/:instanceId',
                component: InstancePageComponent,
                resolve: { instances: InstancesResolverService }
              },
              { path: 'profile', component: ProfileComponent },
              { path: 'integrations', component: MarketplaceComponent },
              {
                path: 'integrations/:integrationName',
                component: IntegrationSummaryComponent,
                data: { backPath: 'integrations', backText: 'View integrations' }
              },
              {
                path: 'organization/members',
                component: UsersPageComponent,
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organizations/:organizationId/members',
                component: UsersPageComponent,
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organization/keys',
                component: OrganizationApiKeysPageComponent,
                resolve: { organizations: OrganizationsResolver },
                canActivate: [PageAccessGuard]
              },
              {
                path: 'organizations/:organizationId/keys',
                component: OrganizationApiKeysPageComponent,
                resolve: { organizations: OrganizationsResolver },
                canActivate: [PageAccessGuard]
              },
              {
                path: 'organization/db-users',
                component: UsersPageComponent,
                resolve: { organizations: OrganizationsResolver },
                canActivate: [PageAccessGuard],
                data: { feature: 'FT_DB_USER_PAGE' }
              },
              {
                path: 'organizations/:organizationId/db-users',
                component: UsersPageComponent,
                resolve: { organizations: OrganizationsResolver },
                canActivate: [PageAccessGuard],
                data: { feature: 'FT_DB_USER_PAGE' }
              },
              {
                path: 'organization/admin',
                component: AdminComponent,
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organizations/:organizationId/admin',
                component: AdminComponent,
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organization/admin/billing',
                component: AdminComponent,
                data: { minRole: 'ADMIN', redirectUrl: '/organization/admin' },
                canActivate: [PageAccessGuard],
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organizations/:organizationId/admin/billing',
                component: AdminComponent,
                data: { minRole: 'ADMIN', redirectUrl: '/organization/admin' },
                canActivate: [PageAccessGuard],
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organization/admin/usage',
                component: AdminComponent,
                data: { minRole: 'ADMIN', redirectUrl: '/organization/admin' },
                canActivate: [PageAccessGuard],
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organizations/:organizationId/admin/usage',
                component: AdminComponent,
                data: { minRole: 'ADMIN', redirectUrl: '/organization/admin' },
                canActivate: [PageAccessGuard],
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organization/activity',
                component: OrgActivityComponent,
                canActivate: [PageAccessGuard],
                data: { minRole: 'ADMIN' },
                resolve: { organizations: OrganizationsResolver }
              },
              {
                path: 'organizations/:organizationId/activity',
                component: OrgActivityComponent,
                canActivate: [PageAccessGuard],
                data: { minRole: 'ADMIN' },
                resolve: { organizations: OrganizationsResolver }
              },
              { path: 'learn', component: LearnPageComponent },
              { path: 'support', component: SupportPageComponent },
              {
                path: 'support/:caseId',
                component: SupportCasePageComponent,
                data: { backPath: 'support', backText: 'View all cases' }
              }
            ],
            canActivate: [PostSignInGuard]
          }
        ],
        canActivate: [AuthGuardService]
      },
      {
        path: 'signIn',
        component: isAuth0Mode ? SignInAuth0Component : SignInComponent,
        data: { isSignIn: true },
        canActivate: [SignInSignUpPageIfAuthenticatedGuard]
      },
      { path: 'mfa/verify-totp', component: VerifyTotpComponent, canActivate: [VerifyTotpGuard] },
      {
        path: 'signUp',
        component: isAuth0Mode ? SignInAuth0Component : SignUpComponent,
        data: { isSignIn: false },
        canActivate: [SignInSignUpPageIfAuthenticatedGuard]
      },
      { path: 'expiredInvite', component: OrgInviteExpiredComponent },
      { path: 'confirmEmail/:activationCode/:userId', component: ConfirmEmailComponent },
      { path: 'resendConfirmEmail/:userId', component: ResendConfirmEmailComponent },
      { path: 'resetPassword/:recoveryToken/:userId', component: ResetPasswordComponent },
      { path: 'forgotPassword', component: ForgotPasswordComponent },
      { path: 'oauthAuthorizer', component: OauthAuthorizerComponent },
      {
        path: 'error/emailVerification',
        component: EmailVerificationErrorAuth0CallbackComponent,
        canActivate: [NonAuthenticatedUsersOnlyGuardService]
      }
    ]
  },
  { path: 'auth0', component: Auth0DemoComponent },
  { path: '**', redirectTo: '/services', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
