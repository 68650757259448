import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IpAccessListEntry, isAllowAnywhere } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';

/** Mode of the dialog. Alters the behavior. */
export type AddIpAccessListEntryDialogMode =
  /** Default mode:
   * 1. An empty dialog is shown.
   * 2. User adds new entries. A submit button is disabled for an empty list.
   * 3. Once user submits the entries are added to the instance IP access list.
   */
  | 'DEFAULT'
  /**
   * The dialog is shown as a part of switch from 'Anywhere' to 'Specific location' for running services.
   * 1. Empty dialog is shown.
   * 2. User adds new entries. A submit button is enabled for all states.
   * 3. Once user submits the entries from the table replace the current instance IP access list.
   * */
  | 'SWITCH_FROM_ANYWHERE_TO_SPECIFIC_IPS';

/** Input data for AddIpAccessListEntryDialog. */
export interface AddIpAccessListEntryDialogInput {
  instanceId: string;
  mode: AddIpAccessListEntryDialogMode;
}

@Component({
  templateUrl: './add-ip-access-list-entry-dialog.component.html',
  styleUrls: ['./add-ip-access-list-entry-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddIpAccessListEntryDialogComponent {
  ipAccessList: Array<IpAccessListEntry> = [];
  isSubmitInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly input: AddIpAccessListEntryDialogInput,
    readonly dialogRef: MatDialogRef<unknown>,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly snackBar: MatSnackBar
  ) {
    this.ipAccessList = this.isSwitchFromAnywhereMode
      ? this.instanceStateService.getInstanceOrFail(input.instanceId).ipAccessList.filter((e) => !isAllowAnywhere(e))
      : [];
  }

  /** Stores the IP access list entries and closes the dialog. */
  async save(): Promise<void> {
    try {
      this.isSubmitInProgress = true;
      let newIpAccessList: Array<IpAccessListEntry> = [];
      if (!this.isSwitchFromAnywhereMode) {
        assertTruthy(this.ipAccessList.length > 0);
        const instance = this.instanceStateService.getInstanceOrFail(this.input.instanceId);
        newIpAccessList = instance.ipAccessList.filter(
          (e1) => !this.ipAccessList.some((e2) => e2.source === e1.source)
        );
      }
      newIpAccessList.push(...this.ipAccessList);
      await this.instanceService.updateIpAccessList(this.input.instanceId, newIpAccessList);
      const snackBarMessage = newIpAccessList.length > 0 ? 'Your IP address has been added' : 'Access list is updated';
      this.snackBar.open(snackBarMessage, 'Dismiss', { duration: 5000 });

      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      this.snackBar.open('Failed to update access list', 'Dismiss', { duration: 5000 });
    } finally {
      this.isSubmitInProgress = false;
    }
  }

  get isSwitchFromAnywhereMode(): boolean {
    return this.input.mode === 'SWITCH_FROM_ANYWHERE_TO_SPECIFIC_IPS';
  }

  static show(
    dialog: MatDialog,
    instanceId: string,
    mode: AddIpAccessListEntryDialogMode = 'DEFAULT'
  ): MatDialogRef<AddIpAccessListEntryDialogComponent> {
    return dialog.open(AddIpAccessListEntryDialogComponent, {
      data: { instanceId, mode },
      width: '100%',
      maxWidth: '517px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }
}
