import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import {
  BACKUP_API_PATH,
  CreateInstanceBackupRequest,
  ListInstanceBackupsRequest,
  ListInstanceBackupsResponse
} from '@cp/common/protocol/Backup';
import { InstanceBackupStateService } from '@cp/web/app/instances/instance-backups/instance-backup-state.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';

@Injectable({
  providedIn: 'root'
})
export class InstanceBackupApiService {
  constructor(
    private readonly instanceBackupStateService: InstanceBackupStateService,
    private readonly instanceStateService: InstanceStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly restService: RestService
  ) {}

  async listInstanceBackups(organizationId: string, instanceId: string): Promise<ListInstanceBackupsResponse> {
    const request: ListInstanceBackupsRequest = {
      rpcAction: 'list',
      organizationId,
      instanceId
    };
    return await this.restService.post(BACKUP_API_PATH, request);
  }

  async createInstanceBackup(instanceId: string): Promise<void> {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    const request: CreateInstanceBackupRequest = { rpcAction: 'create', organizationId, instanceId };
    await this.restService.post(BACKUP_API_PATH, request);
  }
}
