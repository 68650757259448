import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  templateUrl: './confirm-email-expiration-dialog.component.html',
  styleUrls: ['./confirm-email-expiration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmEmailExpirationDialogComponent {
  readonly userId: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmEmailExpirationDialogComponent>,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) private readonly data: { userId: string }
  ) {
    this.userId = data.userId;
  }

  resendConfirmationEmail(): void {
    this.dialogRef.close();
    this.router.navigateByUrl(`/resendConfirmEmail/${this.userId}/`).then();
  }
}
