<div class="disable_mfa_dialog_content_wrapper">
  <div class="disable_mfa_dialog_title_wrapper" data-test="disable-mfa-dialog-title">
    <h4 class="disable_mfa_dialog_title">Remove authenticator app?</h4>
  </div>
  <div>
    <p data-test="disable-mfa-dialog-description">
      After removing the authenticator app, your account will not have multi-factor authentication enabled.
    </p>

    <div class="disable_mfa_dialog_vertical_buttons">
      <div *ngIf="errorMessage"
           data-test="disable-mfa-dialog-error-message">
        <cw-system-message
          messageType="error"
          [message]="errorMessage">
        </cw-system-message>
      </div>
      <button class="disable_mfa_dialog_primary_action"
              type="button"
              [disabled]="isLoading"
              data-test="disable-mfa-dialog-primary-action-button"
              mat-button
              mat-flat-button
              (click)="onPrimaryAction()">
        Remove Authenticator app
      </button>
      <button (click)="onCancel()" mat-button mat-flat-button type="button"
              [disabled]="isLoading"
              class="disable_mfa_dialog_cancel_button" data-test="disable-mfa-dialog-cancel-button">
        Cancel
      </button>
    </div>
  </div>
</div>
