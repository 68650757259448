import { Pipe, PipeTransform } from '@angular/core';
import { getAggregationPeriodMillis, TimePeriod } from '@cp/common/protocol/Metrics';
import { assertTruthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_DAY, MILLIS_PER_HOUR, MILLIS_PER_MINUTE } from '@cp/common/utils/DateTimeUtils';

/** Transforms TimePeriod enum value to a display text. */
@Pipe({
  name: 'timePeriod'
})
export class TimePeriodPipe implements PipeTransform {
  transform(value: TimePeriod): string {
    return formatTimePeriod(value);
  }
}

/** Formats value as a time period. */
export function formatTimePeriod(timePeriod: TimePeriod): string {
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      return 'Last 15 minutes';
    case 'LAST_HOUR':
      return 'Last hour';
    case 'LAST_DAY':
      return 'Last 24 hours';
    case 'LAST_WEEK':
      return 'Last week';
    case 'LAST_MONTH':
      return 'Last month';
    case 'LAST_YEAR':
      return 'Last year';
  }
  return 'Unknown period';
}

/** Transforms TimePeriod enum value to a display text. */
@Pipe({
  name: 'aggregationPeriod'
})
export class AggregationPeriodPipe implements PipeTransform {
  transform(value: TimePeriod): string {
    return getAggregationPeriodTextByTimePeriod(value);
  }
}

/** Returns visual representation of the aggregation period for the given time period. */
export function getAggregationPeriodTextByTimePeriod(timePeriod: TimePeriod): string {
  // Validate every value before we return result.
  // So if 'getAggregationPeriodMillis' is changed tests with method will fail.
  const aggregationPeriodMillis = getAggregationPeriodMillis(timePeriod);
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      assertTruthy(aggregationPeriodMillis === MILLIS_PER_MINUTE);
      return '1 minute';
    case 'LAST_HOUR':
      assertTruthy(aggregationPeriodMillis === MILLIS_PER_MINUTE);
      return '1 minute';
    case 'LAST_DAY':
      assertTruthy(aggregationPeriodMillis === 5 * MILLIS_PER_MINUTE);
      return '5 minutes';
    case 'LAST_WEEK':
      assertTruthy(aggregationPeriodMillis === MILLIS_PER_HOUR);
      return '1 hour';
    case 'LAST_MONTH':
      assertTruthy(aggregationPeriodMillis === 3 * MILLIS_PER_HOUR);
      return '3 hours';
    case 'LAST_YEAR':
      assertTruthy(aggregationPeriodMillis === MILLIS_PER_DAY);
      return '1 day';
  }
}
