import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { assertTruthy } from '@cp/common/utils/Assert';

/** Dialog submit confirmation screen. Use this component to show confirmation of a dialog submit action. */
@Component({
  selector: 'cp-dialog-submitted',
  templateUrl: './dialog-submitted.component.html',
  styleUrls: ['./dialog-submitted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogSubmittedComponent implements OnInit, OnChanges {
  @Input()
  title!: string;

  @Input()
  subtitle!: string;

  @Input()
  dialogRef!: MatDialogRef<unknown>;

  ngOnInit(): void {
    this.checkRequiredFields();
  }

  ngOnChanges(): void {
    this.checkRequiredFields();
  }

  private checkRequiredFields(): void {
    assertTruthy(this.title);
    assertTruthy(this.subtitle);
    assertTruthy(this.dialogRef);
  }
}
