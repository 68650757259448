import type { RangeSettings } from '@cp/common/protocol/features/Features';

/**
 * List of all organization level feature flags registered in the system.
 */
export const ORGANIZATION_FEATURES = [
  /**
   * Flag name used to check organization feature flag system itself.
   */
  'FT_ORG_SELF_TEST',
  /**
   * Enables shorter trial and trial grace periods (1 day instead of 14 days).
   */
  'FT_ORG_SHORT_TRIAL',
  /**
   * Enables activity (audit) OpenAPI endpoints for organization.
   */
  'FT_ORG_OPENAPI_ACTIVITY',
  /**
   * Enables billing OpenAPI endpoints for organization.
   */
  'FT_ORG_OPENAPI_BILLING',
  /**
   * Enables metrics OpenAPI endpoints for service in organization.
   */
  'FT_ORG_OPENAPI_METRICS',
  /**
   * Flag name used to determine if the organization is limited to GCP instances only.
   */
  'FT_GCP_ORG',
  /**
   * Enable Azure region
   */
  'FT_ORG_AZURE',
  /**
   * Can create instances with customer-managed encryption keys.
   */
  'FT_ORG_CUSTOMER_MANAGED_ENCRYPTION',
  /**
   * Add AWS marketplace prefix to tackle external mapping in m3ter.
   */
  'FT_ORG_PREFIX_AWS_MARKETPLACE_EXTERNAL_MAPPING',
  /**
   * All new Development tier instances in this organization will use 'dev-memory-ssd-multists'
   * base configuration instead of the old 'dev-memory-ssd'.
   */
  'FT_ORG_NEW_DEV_TIER_MULTISTS_INSTANCES',
  /**
   * All new Production tier instances in this organization 'memory-ssd-extra-small-multists' (AWS) or 'server-multists' (GCP).
   */
  'FT_ORG_NEW_PROD_TIER_MULTISTS_INSTANCES',
  /**
   * Enables user to toggle mysql protocol for instances
   */
  'FT_ORG_MYSQL_PROTOCOL',
  /**
   * Allow customer to pay using ACH
   */
  'FT_ORG_ALLOW_ACH_PAYMENT_ON_NEW_INVOICES',
  /**
   * All new Development tier instances in this organization will use 'dev-memory-ssd-smt'
   * base configuration instead of the old 'dev-memory-ssd'.
   */
  'FT_ORG_NEW_DEV_TIER_SMT_INSTANCES',
  /**
   * All new Production tier instances in this organization 'memory-ssd-extra-small-smt' (AWS) or
   * 'server-smt' (GCP).
   */
  'FT_ORG_NEW_PROD_TIER_SMT_INSTANCES',
  /**
   * Grant new credits using m3ter's balance object, no longer using commitments.
   */
  'FT_ORG_GRANT_CREDITS_USING_M3TER_BALANCES',
  /**
   * Enable the new flow that manages Indian CC mandates.
   */
  'FT_ORG_NEW_INDIAN_CC_MANDATE_FLOW',
  /**
   * Enables the "IAM Role" field in the instance settings page
   */
  'FT_ORG_SHOW_IAM_ROLE',
  /**
   * Enables user to customize mapping of organization roles -> database access
   */
  'FT_ORG_CUSTOMIZE_DATABASE_ACCESS',
  /**
   * Generate bill statement after locking bill.
   */
  'FT_ORG_GENERATE_STATEMENT_FOR_LOCKED_BILLS',
  /**
   * Enable specific configuration for DP cell when creating a new instance
   */
  'FT_ORG_CELL',
  /**
   * Enable configuration of private endpoints in organization and its instances
   */
  'FT_ORG_PRIVATE_ENDPOINT',
  /**
   * Enable sending the billing period as a custom_field to be displayed on Stripe invoice for customer.
   */
  'FT_ORG_INVOICE_BILLING_PERIOD',
  /**
   * Invoice the organization once the spending passed a threshold, don't wait for the bill period to end.
   */
  'FT_ORG_AUTO_INVOICE_AT_THRESHOLD',
  /**
   * When enabled will set Stripe tax_exempt field to support EU VAT reverse charge.
   */
  'FT_ORG_VAT_REVERSE_CHARGE',
  /**
   * Disable automatic bill locking for this organization.
   */
  'FT_ORG_DISABLE_BILL_LOCKING'
] as const;

export type OrganizationFeatureId = (typeof ORGANIZATION_FEATURES)[number];

export type OrganizationFeatureIdWithDataPayload = 'FT_ORG_AUTO_INVOICE_AT_THRESHOLD';

const ALL_ORGANIZATION_FEATURE_FLAG_SET: ReadonlySet<OrganizationFeatureId> = new Set<OrganizationFeatureId>([
  ...ORGANIZATION_FEATURES
]);

export const ORG_FEATURE_WITH_DATA_PAYLOAD_SETTINGS: Record<OrganizationFeatureIdWithDataPayload, RangeSettings> = {
  FT_ORG_AUTO_INVOICE_AT_THRESHOLD: {
    minValue: 1_000,
    maxValue: 20_000,
    defaultValue: 10_000
  }
};

export function isOrganizationFeatureId(value: unknown): value is OrganizationFeatureId {
  return ALL_ORGANIZATION_FEATURE_FLAG_SET.has(value as OrganizationFeatureId);
}
