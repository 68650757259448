import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isDefined } from '@cp/common/protocol/Common';
import { OrganizationBillingStatus, OrganizationCommitment, OrganizationRole } from '@cp/common/protocol/Organization';
import { MILLIS_PER_DAY } from '@cp/common/utils/DateTimeUtils';
import { BillingConversionDialogComponent } from '@cp/web/app/admin/billing-conversion-dialog/billing-conversion-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { map, Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

type BillingAlertType =
  | 'NO_ALERT'
  | 'CREDIT_NEAR_DEPLETION'
  | 'CREDIT_NEAR_EXPIRATION'
  | 'CREDIT_DEPLETED'
  | 'CREDIT_EXPIRED';

@Component({
  selector: 'cp-billing-alert',
  templateUrl: './billing-alert.component.html',
  styleUrls: ['./billing-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingAlertComponent {
  readonly billingStatusStateObs: Observable<OrganizationBillingStatus>;

  private readonly creditDepletionPercentThreshold = 50;
  private readonly creditExpirationDaysLeftThreshold = 1;
  readonly commitmentStateObs: Observable<OrganizationCommitment>;
  readonly myRoleObs: Observable<OrganizationRole>;
  readonly billingStatusIsTrialRelatedObs: Observable<boolean>;

  // Only show the system message if the org is in one of these billing statuses.
  private readonly billingStatusesToShow: Set<OrganizationBillingStatus> = new Set([
    'IN_TRIAL',
    'IN_TRIAL_GRACE_PERIOD',
    'DECOMMISSIONED'
  ]);

  remainingDaysMessageMapping: { [k: string]: string } = {
    '=0': 'There is less than one day left',
    '=1': 'There is one day left',
    other: 'There are # days left'
  };

  totalDaysMessageMapping: { [k: string]: string } = {
    '=0': 'in your free trial.',
    other: 'in your # day free trial.'
  };

  constructor(
    private readonly dialog: MatDialog,
    private readonly organizationStateService: OrganizationStateService
  ) {
    this.commitmentStateObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeTrialCommitmentState(id))
    );

    this.billingStatusStateObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeBillingStatus(id))
    );

    this.billingStatusIsTrialRelatedObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeBillingStatus(id)),
      map<OrganizationBillingStatus, boolean>((billingStatus) => this.billingStatusesToShow.has(billingStatus))
    );

    this.myRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
  }

  showBillingAlertType(billingStatus: OrganizationBillingStatus, commitment: OrganizationCommitment): boolean {
    return this.getBillingAlertType(billingStatus, commitment) !== 'NO_ALERT';
  }

  getBillingAlertType(
    billingStatus: OrganizationBillingStatus,
    trialCommitment: OrganizationCommitment
  ): BillingAlertType {
    if (!this.billingStatusesToShow.has(billingStatus)) {
      return 'NO_ALERT';
    }

    if (trialCommitment.usagePercent >= 100) {
      return 'CREDIT_DEPLETED';
    }

    if (trialCommitment.expirationDate < Date.now()) {
      return 'CREDIT_EXPIRED';
    }

    if (trialCommitment.usagePercent >= this.creditDepletionPercentThreshold) {
      return 'CREDIT_NEAR_DEPLETION';
    }

    if (trialCommitment.expirationDate < Date.now() + this.creditExpirationDaysLeftThreshold * MILLIS_PER_DAY) {
      return 'CREDIT_NEAR_EXPIRATION';
    }

    return 'NO_ALERT';
  }

  getDismissalKey(billingStatus: OrganizationBillingStatus, commitment: OrganizationCommitment): string | undefined {
    const billingAlertType = this.getBillingAlertType(billingStatus, commitment);
    if (billingAlertType !== 'NO_ALERT') {
      return `${billingAlertType}-${commitment.id}`;
    }
    return undefined;
  }

  showCreditCardDialog(): void {
    BillingConversionDialogComponent.show(this.dialog);
  }
}
