<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <mat-dialog-content *ngIf="createInstanceUiStateObs | async as uiState" class="create_instance_dialog">
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
    <div class="left">
      <cp-dialog-title dialogTitle="Create a service"></cp-dialog-title>

      <cp-create-instance eventPrefix="serviceList.new"></cp-create-instance>

      <div class="sidebar_links" *ngIf="form.value['instanceDetails']?.provider !== 'AZURE'">
        <div class="sidebar_link">
          <a
            class="sidebar_link"
            *ngIf="form.value['instanceDetails'].tier === 'Production'"
            (click)="toggleSidebar('AUTO_SCALING')"
            data-cy="create-service-advanced-settings"
          >
            Configure service sizing
          </a>
        </div>

        <div class="sidebar_link" *ngIf="(cmekEnabled | async) && cmekAllowedInRegion">
          <a
            class="sidebar_link"
            *ngIf="form.value['instanceDetails'].tier === 'Production'"
            (click)="toggleSidebar('CUSTOMER_MANAGED_ENCRYPTION')"
            data-cy="create-service-customer-managed-encryption-toggle"
            >
            Set up encryption key (CMEK)
          </a>
        </div>
      </div>

      <cw-system-message *ngIf="uiState.errorMessage" [message]="uiState.errorMessage"
                         class="system_message"></cw-system-message>
      <div class="buttons" *ngIf="form.value['instanceDetails']?.provider !== 'AZURE'">
        <button (click)="dialogRef.close()" class="close_button" mat-stroked-button type="button">Cancel</button>
        <button [disabled]="!canSubmit || uiState.createInstanceButtonDisabled" class="button"
                color="primary" data-cy="create-service-submit-button" mat-flat-button
                type="submit">
          <mat-icon *ngIf="uiState.createInstanceButtonDisabled" class="button_loader_icon icon svg_stroke"
                    svgIcon="loader"></mat-icon>
          Create Service
        </button>
      </div>

    </div>

    <div *ngIf="form.value['instanceDetails']?.provider !== 'AZURE'">
      <cw-create-instance-auto-scaling-details
        *ngIf="autoScalingSettingsOpen && autoScalingLimits &&(billingStatusObs | async) as billingStatus"
        class="instance_side_details"
        formControlName="autoScalingDetails"
        [instanceTier]="form.value['instanceDetails'].tier"
        [regionId]="form.value['instanceDetails'].region.id"
        [billingStatus]="billingStatus"
        [autoScalingLimits]="autoScalingLimits"
      ></cw-create-instance-auto-scaling-details>

      <cp-customer-managed-encryption-key-details
        *ngIf="customerManagedEncryptionOpen"
        formControlName="customerManagedEncryption"
        (changeValid)="setCustomerEncryptionValid($event)"
      >
      </cp-customer-managed-encryption-key-details>
    </div>
  </mat-dialog-content>
</form>
