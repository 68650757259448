import { OrganizationFeatureId } from '@cp/common/protocol/features';
import { CloudProvider } from '@cp/common/protocol/Instance';
import { OrganizationTackleSubscription } from '@cp/common/protocol/Organization';
import { fail } from '@cp/common/utils/Assert';

/** Returns list of Cloud Providers the organization can use to create instances. */
export function getOrganizationCloudProviders(org: {
  features?: Array<OrganizationFeatureId>;
  tackleState?: OrganizationTackleSubscription;
}): Array<CloudProvider> {
  if (org.tackleState) {
    switch (org.tackleState.marketplace) {
      case 'aws':
        return ['AWS'];
      case 'gcp':
        return ['GCP'];
      default:
        fail(`Unsupported Tackle marketplace: ${org.tackleState.marketplace}`);
    }
  }
  if (org?.features?.includes('FT_GCP_ORG')) {
    return ['GCP'];
  }

  if (org?.features?.includes('FT_ORG_AZURE')) {
    return ['AWS', 'GCP', 'AZURE'];
  }

  return ['AWS', 'GCP'];
}
