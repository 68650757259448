<div (dragover)="allowDrop($event)" (drop)="onDrop($event)" [ngSwitch]="uploadStage" class="upload_container">
  <input #fileInput (change)="inputChanged($event)" [attr.multiple]="multipleFiles" class="file_input" type="file" data-cy="file-input"/>

  <div *ngSwitchCase="'select_files'" class="file_upload_stage">
    <mat-icon (click)="fileInput.click()" class="plus_icon" svgIcon="plus_icon"></mat-icon>
    <div class="text">Drag and drop one or more files or
      <span (click)="fileInput.click()" class="link">browse</span>
    </div>
    <div class="size_limit">max size: {{maxFileSizeBytes | dataSize: 'bytes-letter':0}}</div>
  </div>

  <ng-container *ngSwitchCase="'upload_in_progress'">
    <cp-upload-progress-bar *ngIf="displayProgressSubject | async as displayProgress"
                         [displayProgress]="displayProgress"
    ></cp-upload-progress-bar>
  </ng-container>

  <ng-container *ngSwitchCase="'upload_complete'">
    <div class="upload_complete_stage" data-cy="upload-complete-stage">
      <mat-icon class="file_icon" svgIcon="file_icon"></mat-icon>
      <div class="fileNames">{{fileNames.join(', ')}}</div>
      <div (click)="removeFiles(fileInput)" class="remove_files_button link">
        <span *ngIf="fileNames.length <= 1" >remove file</span>
        <span *ngIf="fileNames.length > 1">remove {{fileNames.length}} files</span>
      </div>
    </div>
  </ng-container>
</div>
