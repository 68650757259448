import Showdown, { ShowdownExtension } from 'showdown';

Showdown.extension('highlightjs', () => {
  return replacePreCodeBlocks(highlightReplacement);
});

Showdown.extension('highlightjsWithCopy', () => {
  return replacePreCodeBlocks(highlightReplacementWithCopy);
});

const highlightConverter = new Showdown.Converter({
  noHeaderId: false,
  customizedHeaderId: true,
  tables: true,
  extensions: ['highlightjs']
});

const highlightWithCopyConverter = new Showdown.Converter({
  noHeaderId: false,
  customizedHeaderId: true,
  tables: true,
  extensions: ['highlightjsWithCopy']
});

function htmlDecode(text: string) {
  return text.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
}

function replacePreCodeBlocks(
  replacement: (_wholeMatch: unknown, match: string, left: string, right: string) => string
): ShowdownExtension | ShowdownExtension[] {
  // use new showdown's regexp engine to conditionally parse codeblocks
  const left = `<pre><code\\b[^>]*>`;
  const right = '</code></pre>';
  const flags = 'g';
  return [
    {
      type: 'output',
      filter: function (text) {
        return Showdown.helper.replaceRecursiveRegExp(text, replacement, left, right, flags);
      }
    }
  ];
}

function highlightReplacement(_wholeMatch: unknown, match: string, left: string, right: string): string {
  return '<div class="highlightjs_snippet">' + replacement(_wholeMatch, match, left, right) + '</div>';
}

function highlightReplacementWithCopy(_wholeMatch: unknown, match: string, left: string, right: string): string {
  return `<div class="highlightjs_snippet">
            <div class="highlightjs_copy_button" onclick="navigator.clipboard.writeText(this.nextElementSibling.innerText);">
            </div>
           ${replacement(_wholeMatch, match, left, right)}
         </div>`;
}

function replacement(_wholeMatch: unknown, match: string, left: string, right: string): string {
  // unescape match to prevent double escaping
  match = htmlDecode(match);
  const hljs = (window as any)?.hljs;
  if (hljs) {
    return `${left}${
      hljs.highlightAuto(match, [
        'javascript',
        'sql',
        'bash',
        'shell',
        'typescript',
        'python',
        'xml',
        'java',
        'yaml',
        'json',
        'go'
      ]).value
    }${right}`;
  }
  return left + match + right;
}

/** Converts markdown string to html. */
export function convertMarkdown(markdown: string, addCopyButton = false): string {
  if (addCopyButton) {
    return highlightWithCopyConverter.makeHtml(markdown);
  }
  return highlightConverter.makeHtml(markdown);
}
