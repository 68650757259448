<mat-dialog-content class="entry_questionnaire_dialog" data-cy="entry-questionnaire-dialog">
  <ng-container>
    <cp-dialog-title data-cy="questionnaire-dialog-title" dialogTitle="Tell us a bit about your use case">
      <div class="title_underline"></div>
    </cp-dialog-title>

    <form (ngSubmit)="onSubmit()" class="form">
      <div *ngFor="let multiChoice of questions; let questionIndex = index;">
        <section class="question_section">
          <div class="question_title">{{multiChoice.question}}</div>
          <div *ngFor="let option of multiChoice.options; trackBy: trackByValue"
               class="checkbox_wrapper"
               (focusin)="errorMessage = ''"
          >
            <mat-checkbox class="option_checkbox"
                          color="primary"
                          data-cy="entry-questionnaire-option"
                          [galaxyClick]="['entryQuestionnaire.checkbox.questionnaireCheckboxCheck', {question: multiChoice.question, value: option.value, isChecked: '' + !option.isChecked}]"
                          [attr.data-value]="option.value"
                          [checked]="option.isChecked"
                          (change)="onCheckBoxClick(questionIndex, option.value)">
              {{option.value}}
            </mat-checkbox>
            <div *ngIf="option.value === 'Other'" class="seed_form_element">
              <input class="other_input" [id]="otherInputIdPrefix + questionIndex"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="option.otherValue"
                     (change)="option.isChecked = true"
                     data-cy="other-input"
                     maxlength="255"
                     height="32"
                     spellCheck="false"
              />
            </div>
          </div>
        </section>
      </div>

      <cw-system-message
        *ngIf="errorMessage" class="error_message"
        messageType="error"
        [message]="errorMessage">
      </cw-system-message>

      <div class="buttons">
        <button [disabled]="isSubmitButtonDisabled()" class="submit_button"
                data-cy="entry-questionnaire-button" mat-flat-button
                color="primary"
                type="submit">
            Let's go!
        </button>
      </div>
    </form>

  </ng-container>

</mat-dialog-content>

