import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import { UploadSignatureDetails } from '@cp/common/protocol/Storage';
import {
  CaseDetailsRequest,
  CaseDetailsResponse,
  CreateSupportCaseRequest,
  CreateSupportCaseResponse,
  ListCasesRequest,
  ListCasesResponse,
  PrepareAttachmentUploadRequest,
  ReportSupportCaseEventRequest,
  SupportCase,
  SupportCaseEventType,
  SupportCasePriority,
  SupportCaseStatus
} from '@cp/common/protocol/Support';

/** CP API for support management. */
@Injectable({ providedIn: 'root' })
export class SupportApiService {
  constructor(private readonly restService: RestService) {}

  /** Returns list of support cases sorted by creation date. */
  async listCases(organizationId: string): Promise<Array<SupportCase>> {
    const request: ListCasesRequest = { organizationId };
    const response = await this.restService.post<ListCasesResponse>('support/listCases', request);
    return response.cases || [];
  }

  async getCaseDetails(organizationId: string, caseId: string) {
    const request: CaseDetailsRequest = { organizationId, caseId };
    const response = await this.restService.post<CaseDetailsResponse>('support/getCaseDetails', request);
    return response.details;
  }

  async prepareSupportAttachmentUpload(
    organizationId: string,
    fileName: string,
    mimeType?: string
  ): Promise<UploadSignatureDetails> {
    const request: PrepareAttachmentUploadRequest = { organizationId, fileName, mimeType };
    return await this.restService.post<UploadSignatureDetails>('support/prepareAttachmentUpload', request);
  }

  async createCase(
    organizationId: string,
    priority: SupportCasePriority,
    subject: string,
    description: string,
    additionalEmails: string[],
    recaptchaToken: string,
    serviceId?: string,
    serviceName?: string,
    attachmentStorageIds?: string[]
  ): Promise<CreateSupportCaseResponse> {
    const request: CreateSupportCaseRequest = {
      organizationId,
      priority,
      subject,
      description,
      additionalEmails,
      serviceId,
      serviceName,
      attachmentStorageIds,
      recaptchaToken
    };
    return await this.restService.post<CreateSupportCaseResponse>('support/createCase', request);
  }

  async reportCaseEvent(
    organizationId: string,
    caseId: string,
    type: SupportCaseEventType,
    reply?: string,
    status?: SupportCaseStatus,
    attachmentStorageIds?: string[]
  ) {
    const request: ReportSupportCaseEventRequest = {
      organizationId,
      caseId,
      type,
      reply,
      status,
      attachmentStorageIds
    };
    await this.restService.post<void>('support/reportCaseEvent', request);
  }
}
