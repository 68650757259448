import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CpUserDetails } from '@cp/common/protocol/Account';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { AccountService } from '@cp/web/app/account/account.service';
import { AuthenticatorAppSetUpDialogComponent } from '@cp/web/app/account/mfa/authenticator-app-set-up-dialog/authenticator-app-set-up-dialog.component';
import { DisableMfaConfirmationDialogComponent } from '@cp/web/app/account/mfa/disable-mfa-confirmation-dialog/disable-mfa-confirmation-dialog.component';
import { ChangePasswordComponent } from '@cp/web/app/account/profile/change-password/change-password.component';
import { UpdatePersonalInfoComponent } from '@cp/web/app/account/profile/update-personal-info/update-personal-info.component';
import { AuthService } from '@cp/web/app/auth/auth.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'cp-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent extends OnDestroyComponent {
  readonly userDetailsObs: Observable<CpUserDetails>;
  isTotpMfaActiveObs: Observable<boolean>;

  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly accountService: AccountService
  ) {
    super();
    this.userDetailsObs = accountStateService.observeUserDetails().pipe(filter(Boolean));
    this.isTotpMfaActiveObs = this.accountStateService
      .observeMfaPreferredMethod()
      .pipe(map((mfaPreferredMethod) => mfaPreferredMethod === 'SOFTWARE_TOKEN_MFA'));
  }

  showTotpSetupDialog(): void {
    AuthenticatorAppSetUpDialogComponent.show(this.dialog);
  }

  showDisableMfaConfirmationDialog(): void {
    DisableMfaConfirmationDialogComponent.show(this.dialog);
  }

  showUpdatePersonalInfoDialog(): void {
    this.dialog.open(UpdatePersonalInfoComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }

  showChangePasswordDialog(): void {
    if (this.authService.isSelfHostedAuthProfileManagement) {
      this.dialog.open(ChangePasswordComponent, {
        width: '100%',
        maxWidth: '517px',
        autoFocus: true,
        restoreFocus: false,
        panelClass: 'change_password_modal'
      });
      return;
    }
    this.accountService.changePasswordWithRedirect().then();
  }

  isPasswordUpdateSupported(userDetails: CpUserDetails): boolean {
    return userDetails.identityProviderType === 'database';
  }

  isMfaUpdateSupported(userDetails: CpUserDetails): boolean {
    return userDetails.identityProviderType === 'database';
  }
}
