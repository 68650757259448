<mat-dialog-content *ngIf="uiStateObs | async as uiState"
                    class="change_billing_contact_dialog" data-cy="change-billing-contact-dialog">
  <ng-container *ngIf="billingContactOptionsObs | async as contactOptions">
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
    <cp-dialog-title dialogTitle="Update billing contact"></cp-dialog-title>
    <form (ngSubmit)="onSubmit()" [formGroup]="changeBillingContactForm" class="form">
      <div class="seed_form_element">
        <label>Billing contact</label>
        <seed-select [options]="contactOptions"
                     [value]="initialContact"
                     formControlName="contact"
                     class="select fs-exclude"
        ></seed-select>
      </div>

      <div class="vertical_buttons">
        <button [disabled]="changeBillingContactForm.invalid || uiState.submitButtonDisabled" class="main_action"
                color="primary" data-cy="update-billing-contact-button" mat-flat-button
                type="submit"
        >Update billing contact
        </button>
        <button [mat-dialog-close]="false" class="secondary_action" mat-flat-button>Cancel</button>
      </div>
    </form>
  </ng-container>
</mat-dialog-content>

