import { ORG_FEATURE_WITH_DATA_PAYLOAD_SETTINGS } from './OrganizationFeatures';
import { INSTANCE_FEATURE_WITH_DATA_PAYLOAD_SETTINGS } from './InstanceFeatures';
import {
  type InstanceFeatureId,
  type InstanceFeatureIdWithDataPayload,
  isInstanceFeatureId
} from '@cp/common/protocol/features/InstanceFeatures';
import {
  isOrganizationFeatureId,
  type OrganizationFeatureId,
  type OrganizationFeatureIdWithDataPayload
} from '@cp/common/protocol/features/OrganizationFeatures';
import { isSystemFeatureId, type SystemFeatureId } from '@cp/common/protocol/features/SystemFeatures';
import { isUserFeatureId, type UserFeatureId } from '@cp/common/protocol/features/UserFeatures';
import { type RegionId } from '@cp/common/protocol/Region';
import { assertTruthy } from '@cp/common/utils/Assert';

export type FeatureId = UserFeatureId | OrganizationFeatureId | InstanceFeatureId | SystemFeatureId;

export type FeatureIdWithDataPayload = InstanceFeatureIdWithDataPayload | OrganizationFeatureIdWithDataPayload;

export const FEATURE_WITH_DATA_PAYLOAD_SETTINGS: Record<FeatureIdWithDataPayload, RangeSettings> = {
  ...INSTANCE_FEATURE_WITH_DATA_PAYLOAD_SETTINGS,
  ...ORG_FEATURE_WITH_DATA_PAYLOAD_SETTINGS
};

export interface RangeSettings {
  minValue: number;
  maxValue: number;
  defaultValue: number | string;
}

export interface FeatureDescription {
  /**
   * Visual name of the feature. It is OK to have it the same with a flag,
   * but since the set of flags is publicly available (part of the JS source bundle) for all users
   * for those flags we want to hide the internal feature details we should use 'obfuscated' flag value.
   * Example: flag: FF_ORG_25, name: 'Free CH service per org'.
   */
  name: string;

  /** 1 line shown on the feature list page. */
  shortDescription: string;

  /** Pre-formatted multi-line shown on the feature page. */
  longDescription: string;
}

/** Feature representation for sysadmin UI. */
export interface SysadminFeature extends FeatureDescription {
  id: FeatureId;
  /** When present shows the partial rollout share. */
  rolloutShare?: number;
  /** When present indicates that the feature in under partial rollout per regions. */
  rolloutRegions?: Array<RegionId>;
  /** Count of the entities (instances/users/organizations) with the feature enabled. */
  entityCount: number;
}

export function isFeatureId(value: unknown): value is FeatureId {
  return (
    isUserFeatureId(value) || isOrganizationFeatureId(value) || isInstanceFeatureId(value) || isSystemFeatureId(value)
  );
}

export function isFeatureIdWithDataPayload(value: unknown): value is FeatureIdWithDataPayload {
  return (value as FeatureIdWithDataPayload) in FEATURE_WITH_DATA_PAYLOAD_SETTINGS;
}

/**
 *  Detailed feature flag configuration with an optional payload.
 *  Payload depends on the feature type (id).
 */
export type FeatureConfig = { isEnabled: boolean; payload?: unknown };

/** FeatureId -> boolean|FeatureConfig map. Used to enable/disable features. */
export type FeatureConfigRecord<T extends FeatureId> = Partial<Record<T, boolean | FeatureConfig>>;
export type UserFeatureConfigRecord = FeatureConfigRecord<UserFeatureId>;
export type InstanceFeatureConfigRecord = FeatureConfigRecord<InstanceFeatureId>;
export type OrganizationFeatureConfigRecord = FeatureConfigRecord<OrganizationFeatureId>;

export function isFeatureConfig(value: unknown): value is FeatureConfig {
  return typeof value === 'object' && typeof (value as FeatureConfig)?.isEnabled === 'boolean';
}

export function isFeatureConfigOrBoolean(value: unknown): boolean {
  return typeof value === 'boolean' || isFeatureConfig(value);
}

export function isUserFeatureConfigRecord(value: unknown): value is UserFeatureConfigRecord {
  return (
    typeof value === 'object' &&
    Object.entries(value as UserFeatureConfigRecord).every(
      ([k, v]) => isUserFeatureId(k) && isFeatureConfigOrBoolean(v)
    )
  );
}

export function isInstanceFeatureConfigRecord(value: unknown): value is InstanceFeatureConfigRecord {
  return (
    typeof value === 'object' &&
    Object.entries(value as InstanceFeatureConfigRecord).every(
      ([k, v]) => isInstanceFeatureId(k) && isFeatureConfigOrBoolean(v)
    )
  );
}

export function isOrganizationFeatureConfigRecord(value: unknown): value is OrganizationFeatureConfigRecord {
  return (
    typeof value === 'object' &&
    Object.entries(value as OrganizationFeatureConfigRecord).every(
      ([k, v]) => isOrganizationFeatureId(k) && isFeatureConfigOrBoolean(v)
    )
  );
}

/** Adds/removes features from the array of features using FeatureFlagRecord. Returns a new array of features. */
export function toggleFeatures<T extends FeatureId>(features: Array<T>, flags: FeatureConfigRecord<T>): Array<T> {
  const featureSet = new Set<T>(features || []);
  for (const key of Object.keys(flags)) {
    const featureId = key as T;
    featureSet.delete(featureId);
    const configOrBoolean: boolean | FeatureConfig | undefined = flags[featureId];
    const isEnabled = typeof configOrBoolean === 'boolean' ? configOrBoolean : configOrBoolean?.isEnabled;
    if (isEnabled) {
      featureSet.add(featureId);
    }
  }
  return [...featureSet];
}

export function isFeatureEnabled<F extends FeatureId>(features: Array<F> | undefined, feature: F): boolean {
  return features !== undefined && features.includes(feature);
}

export function getCustomFeatureSettings(customFeatureId: FeatureIdWithDataPayload): RangeSettings {
  assertTruthy(isFeatureIdWithDataPayload(customFeatureId));
  return FEATURE_WITH_DATA_PAYLOAD_SETTINGS[customFeatureId];
}
