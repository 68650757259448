import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  getAllowAnywhereIpAccessList,
  Instance,
  IpAccessListEntry,
  isAllowAnywhere
} from '@cp/common/protocol/Instance';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { Observable } from 'rxjs';

interface FirstTimeSetIpAccessListDialogInput {
  instanceId: string;
  ipAccessList: Array<IpAccessListEntry>;
}

@Component({
  templateUrl: './first-time-set-ip-access-list-dialog.component.html',
  styleUrls: ['./first-time-set-ip-access-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstTimeSetIpAccessListDialogComponent extends OnDestroyComponent {
  readonly instanceObs: Observable<Instance | undefined>;

  selected = false;
  private ipAccessList: Array<IpAccessListEntry> = [];
  private anywhere = false;

  isSubmitInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly input: FirstTimeSetIpAccessListDialogInput,
    private readonly dialogRef: MatDialogRef<unknown>,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceUiService: InstanceUiService,
    private readonly accountStateService: AccountStateService,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    dialogRef.disableClose = true;
    this.instanceObs = this.instanceStateService.observeInstance(this.input.instanceId);
    this.ipAccessList = [...this.input.ipAccessList];
    if (this.ipAccessList.length > 0) {
      this.updateUiState(isAllowAnywhere(this.ipAccessList) || this.input.ipAccessList);
    }
  }

  handleIpAccessListChangeEvent(ipAccessListOrAnywhere: Array<IpAccessListEntry> | true) {
    this.updateUiState(ipAccessListOrAnywhere);
    this.cdr.markForCheck();
  }

  private updateUiState(ipAccessListOrAnywhere: Array<IpAccessListEntry> | true) {
    this.selected = true;
    this.ipAccessList = ipAccessListOrAnywhere === true ? [] : ipAccessListOrAnywhere;
    this.anywhere = ipAccessListOrAnywhere === true;
  }

  async next(): Promise<void> {
    try {
      this.isSubmitInProgress = true;
      const effectiveIpAccessList = this.anywhere ? getAllowAnywhereIpAccessList() : this.ipAccessList;
      await this.instanceService.updateIpAccessList(this.input.instanceId, effectiveIpAccessList);
      this.instanceUiService.showConnectDialog(this.input.instanceId, false);
    } catch (e) {
      console.error(e);
      this.snackBar.open('Failed to update access list', 'Dismiss', { duration: 5000 });
    } finally {
      this.dialogRef.close();
      this.isSubmitInProgress = false;
    }
  }

  static show(dialog: MatDialog, instanceId: string, ipAccessList: Array<IpAccessListEntry> = []): void {
    dialog.open(FirstTimeSetIpAccessListDialogComponent, {
      data: { instanceId, ipAccessList },
      width: '100%',
      maxWidth: '840px',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal_no_padding'
    });
  }
}
