import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { UpdatePersonalInfoUiState } from '@cp/web/app/account/protocol/AccountStates';

@Injectable({
  providedIn: 'root'
})
export class UpdatePersonalInfoUiService extends BaseUiStateService<UpdatePersonalInfoUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'updatePersonalInfo'], stateService);
  }
}
