<div class="firmographics_container auth_page_container">
  <div class="container">
    <cw-auth-logo type="full" color="black"></cw-auth-logo>

    <form class="form">
      <div class="auth_intro">
        <div class="auth_title">Before you start</div>
        <div class="auth_description">{{'We just need to ask you a few questions to get started.\nSelect an account type.'}}</div>
      </div>

      <div class="organization_type_buttons_block">
        <div class="organization_type_buttons_row" *ngIf="dialogStage !== 'ADDRESS'">
          <div *ngIf="uiState.organizationIsABusiness" class="sub_title">Business account</div>
          <div *ngIf="!uiState.organizationIsABusiness" class="sub_title">Individual</div>
        </div>
        <div class="organization_type_buttons_row" *ngIf="dialogStage === 'ADDRESS'">
          <button (click)="enableOrDisableOrgAsBusiness(true)"
                  mat-stroked-button
                  class="left_button"
                  [ngClass]="uiState.organizationIsABusiness ? 'selected': ''"
                  data-cy="organization-is-a-business-button"
          >Business
          </button>

          <button (click)="enableOrDisableOrgAsBusiness(false)"
                  mat-stroked-button
                  class="right_button"
                  [ngClass]="!uiState.organizationIsABusiness ? 'selected': ''"
                  data-cy="organization-is-an-individual-button"
          >Individual
          </button>
        </div>
      </div>

      <div class="progress_container_wrapper padded_content" *ngIf="uiState.organizationIsABusiness">
        <div class="progress_container">
          <div [ngClass]="dialogStage === 'ADDRESS' ? 'active': ''" class="progress_dot"></div>
          <div [ngClass]="dialogStage === 'COMPANY' ? 'active': ''" class="progress_dot"></div>
        </div>
      </div>

      <ng-container [ngSwitch]="dialogStage">
        <cp-change-billing-address-onboarding *ngSwitchCase="'ADDRESS'" [formInput]="uiState"
                                   [organizationIsABusiness]="uiState.organizationIsABusiness"
                                   [isCancelable]="false"
                                   eventPrefix="tackle.firmographics"
                                   [customSubmitText]="!uiState.organizationIsABusiness? 'Complete sign up' : ''"
                                              (addressChange)="onAddressChange($event)"></cp-change-billing-address-onboarding>

        <cp-billing-company-details-onboarding *ngSwitchCase="'COMPANY'" [formInput]="uiState"
                                    [isCancelable]="false"
                                    [isTaxIdShown]="false"
                                    eventPrefix="tackle.firmographics"
                                    customSubmitText="Complete sign up"
                                    (companyDetailsChange)="onCompanyDetailsChange($event)"
                                    (backButtonClick)="onCompanyDetailsBack($event)"></cp-billing-company-details-onboarding>
      </ng-container>
    </form>
  </div>
</div>
