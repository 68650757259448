import type { FeatureId } from '@cp/common/protocol/features/Features';

/**
 * List of features that do not depend on User/Org/Instance and change the whole system behavior.
 * Usually these kinds of features exist only with a "partial rollout" state and are removed once enabled by 100%.
 * The current state for these features is stored in FeatureBo table.
 */
export const SYSTEM_FEATURES = [
  /** Enables new metric cluster in the system for all metric requests for GCP instances: billing, instance dashboard. */
  'FT_SYS_USE_GCP_METRIC_CLUSTER',
  /**
   * All new Development tier instances when this feature is enabled 'dev-memory-ssd-multists'
   * base configuration instead of the old 'dev-memory-ssd'.
   */
  'FT_SYS_NEW_DEV_TIER_MULTISTS_INSTANCES',
  /**
   * All new Production tier instances when this feature is enabled 'memory-ssd-extra-small-multists' (AWS) or
   * 'server-multists' (GCP).
   */
  'FT_SYS_NEW_PROD_TIER_MULTISTS_INSTANCES',
  /**
   * Switch metrics rate limit implementation to use Redis instead of Mongo.
   * Should be either 1 (100% to Redis) or 0 (100% to Mongo) during progressive rollout.
   */
  'FT_SYS_USE_REDIS_DB_METRICS_CACHE',
  /**
   * Switch resource locks backend implementation to use Redis instead of Mongo.
   * Should be either 1 (100% to Redis) or 0 (100% to Mongo) during progressive rollout.
   */
  'FT_SYS_USE_REDIS_RESOURCE_LOCKS',
  /**
   * All new Development tier instances in this organization will use 'dev-memory-ssd-smt'
   * base configuration instead of the old 'dev-memory-ssd'.
   */
  'FT_SYS_NEW_DEV_TIER_SMT_INSTANCES',
  /**
   * All new Production tier instances in this organization 'memory-ssd-extra-small-smt' (AWS) or
   * 'server-smt' (GCP).
   */
  'FT_SYS_NEW_PROD_TIER_SMT_INSTANCES',
  /**
   * When enabled, backend uses Auth0 as a default auth provider when the auth provider is not clear from the context (like cron jobs).
   * Example when Auth0 can be found from the context: a JWT token passed by frontend contains details about Auth provider.
   * Can have 0 or 100% rollout state.
   */
  'FT_SYS_DEFAULT_AUTH0',
  /**
   * When enabled only Auth0 auth provider can be used to authenticate users on the backend.
   * Can have 0 or 100% rollout state. Overrides 'FT_SYS_DEFAULT_AUTH0_ON_BACKEND'.
   */
  'FT_SYS_FORCE_AUTH0',
  /**
   * When enabled, trigger china blocked card campaign when user attempts to pay using a Chinese credit card.
   */
  'FT_SYS_CHINA_BLOCKED',
  /**
   * When enabled, pulsar will begin to send batches of usage reports to m3ter.
   */
  'FT_SYS_M3TER_BATCHING',
  /**
   * When enabled, Deduplicate messages sent to WS handler.
   */
  'FT_SYS_DEDUPLICATE_WS_MESSAGES',
  /**
   * When enabled, decommission delinquent orgs after 30 days of nonpayment.
   */
  'FT_SYS_DECOMMISSION_DQ_ORGANIZATIONS',
  /**
   * When enabled, commitmentPollingJob queries M3ter for only expired commitments.
   */
  'FT_SYS_COMMITMENT_POLLING_JOB_QUERIES_EXPIRED_COMMITMENTS_ONLY',
  /**
   * Enable sending trial expiration reminders via SendGrid instead of Marketo.
   */
  'FT_SYS_SENDGRID_TRIAL_EXPIRATION_REMINDERS',
  /**
   * When enabled, skip billing metrics for terminating services.
   */
  'FT_SYS_SKIP_BILLING_METRICS_FOR_TERMINATING_SERVICES',
  /**
   * When enabled, pause the entire billing metrics pipeline to avoid sending incorrect metrics to m3ter.
   */
  'FT_SYS_PAUSE_BILLING_METRICS_PIPELINE',
  /**
   * When enabled, send calls to mulesoft instead of the workato.
   */
  'FT_SYS_USE_MULESOFT_API',
  /**
   * When enabled, send requests to CMC in one second batches instead of at the beginning of each minute.
   */
  'FT_SYS_USE_ONE_SECOND_BATCHES_CMC_BILLING'
] as const;

export type SystemFeatureId = (typeof SYSTEM_FEATURES)[number];

const ALL_SYSTEM_FEATURE_FLAG_SET: ReadonlySet<SystemFeatureId> = new Set<SystemFeatureId>([...SYSTEM_FEATURES]);

export function isSystemFeatureId(value: unknown): value is SystemFeatureId {
  return ALL_SYSTEM_FEATURE_FLAG_SET.has(value as SystemFeatureId);
}

/** Contains features that can only have 0% or 100% rollout state. */
const allOrNothingRolloutPlanFeatures = new Set<FeatureId>([
  'FT_SYS_USE_REDIS_DB_METRICS_CACHE',
  'FT_SYS_USE_REDIS_RESOURCE_LOCKS',
  'FT_SYS_USE_GCP_METRIC_CLUSTER',
  'FT_SYS_DEFAULT_AUTH0',
  'FT_SYS_FORCE_AUTH0',
  'FT_SYS_CHINA_BLOCKED',
  'FT_SYS_M3TER_BATCHING',
  'FT_SYS_DEDUPLICATE_WS_MESSAGES',
  'FT_SYS_DECOMMISSION_DQ_ORGANIZATIONS',
  'FT_SYS_COMMITMENT_POLLING_JOB_QUERIES_EXPIRED_COMMITMENTS_ONLY',
  'FT_SYS_SKIP_BILLING_METRICS_FOR_TERMINATING_SERVICES',
  'FT_SYS_PAUSE_BILLING_METRICS_PIPELINE'
]);

export function isAllOrNothingFeatureRolloutPlan(featureId: FeatureId): boolean {
  return allOrNothingRolloutPlanFeatures.has(featureId);
}
