import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { SendOrgInvitesUiState } from '@cp/web/app/organizations/protocol/OrganizationStates';

@Injectable({
  providedIn: 'root'
})
export class SendOrgInvitesUiService extends BaseUiStateService<SendOrgInvitesUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'sendOrgInvites'], stateService);
  }
}
