import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  CREATING_ORGANIZATIONS_DISABLED,
  ORG_WITH_SAME_NAME_ALREADY_EXISTS,
  ORGANIZATION_LIMIT_REACHED
} from '@cp/common/protocol/Organization';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { MAX_SIMPLE_NAME_LENGTH, MIN_SIMPLE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import {
  maxLengthTrimmedValidator,
  minLengthTrimmedValidator,
  organizationNameValidator
} from '@cp/web/app/common/utils/FormValidationUtils';
import { CreateOrganizationUiService } from '@cp/web/app/organizations/create-organization/create-organization-ui.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { OrganizationService } from '@cp/web/app/organizations/organization.service';
import { CreateOrganizationUiState } from '@cp/web/app/organizations/protocol/OrganizationStates';
import { Observable } from 'rxjs';

interface CreateOrganizationFormDetails {
  name: string;
}

@Component({
  selector: 'cp-create-organization',
  templateUrl: './create-organization-dialog.component.html',
  styleUrls: ['./create-organization-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOrganizationDialogComponent {
  readonly createOrganizationForm: FormGroup;
  readonly createOrganizationUiStateObs: Observable<CreateOrganizationUiState>;

  constructor(
    public dialogRef: MatDialogRef<CreateOrganizationDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly organizationService: OrganizationService,
    private readonly createOrganizationUiService: CreateOrganizationUiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly snackBar: MatSnackBar
  ) {
    this.createOrganizationUiStateObs = createOrganizationUiService.observeState();
    createOrganizationUiService.setPartialState({ createOrganizationButtonDisabled: false, errorMessage: undefined });

    this.createOrganizationForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          maxLengthTrimmedValidator(MAX_SIMPLE_NAME_LENGTH),
          minLengthTrimmedValidator(MIN_SIMPLE_NAME_LENGTH),
          organizationNameValidator()
        ]
      ]
    });
  }

  async onSubmit(): Promise<void> {
    if (this.createOrganizationForm.invalid) {
      return;
    }

    this.createOrganizationUiService.setPartialState({
      createOrganizationButtonDisabled: true,
      errorMessage: undefined
    });
    const details = this.createOrganizationForm.value as CreateOrganizationFormDetails;
    try {
      const organization = await this.organizationService.createOrganization(details.name.trim());
      this.organizationStateService.setOrganization(organization);
      this.organizationStateService.switchOrganization(organization.id);
      this.snackBar.open('Organization created', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      const serverErrorMessage = getServerErrorMessage(e);
      let errorMessage;
      switch (serverErrorMessage) {
        case ORG_WITH_SAME_NAME_ALREADY_EXISTS:
          errorMessage = 'Organization with same name already exists, please choose another';
          break;
        case ORGANIZATION_LIMIT_REACHED:
          errorMessage = `You've reached the maximum amount of organizations allowed`;
          break;
        case CREATING_ORGANIZATIONS_DISABLED:
          errorMessage = `Organization creation is currently disabled`;
          break;
        default:
          errorMessage = 'Error occurred when creating a organization';
          break;
      }
      this.createOrganizationUiService.setStateKey('errorMessage', errorMessage);
    } finally {
      this.createOrganizationUiService.setStateKey('createOrganizationButtonDisabled', false);
    }
  }
}

export function getCreateOrganizationDialogConfig(): MatDialogConfig {
  return {
    width: '100%',
    maxWidth: '517px',
    autoFocus: true,
    restoreFocus: false,
    panelClass: 'modal'
  };
}
