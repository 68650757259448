import { assertTruthy } from '@cp/common/utils/Assert';
import { BAD_REQUEST } from '@cp/common/utils/HttpError';

/** An HTTP header name used to parse request country. */
export const CLOUD_FRONT_COUNTRY_HEADER = 'CloudFront-Viewer-Country';

/**
 * When added to the first time CP Web application URL, the application is switched into Auth0 mode
 * and uses Auth0 web auth provider instead of Cognito.
 * Backend automatically supports Auth0 mode with no switches by testing access-token fields.
 */
export const AUTH0_MODE_QUERY_PARAM = 'auth0';
export const COGNITO_MODE_QUERY_PARAM = 'cognito';

/** Disables auto-sign in during application init. */
export const DISABLE_AUTO_SIGN_IN_QUERY_PARAM = 'no-auto-signin';

/**
 * Returns [username, password] parsed from Basic Auth header.
 * Important: username can't contain ':' character.
 * Throws error if parsing fails.
 */
export function parseBasicAuthHeader(authHeaderValue: string): [string, string] {
  const authHeaderTokens = authHeaderValue.split(' ');
  assertTruthy(authHeaderTokens.length === 2, `${BAD_REQUEST}: Bad Authorization header format`);
  assertTruthy(authHeaderTokens[0].toLowerCase() === 'basic', `${BAD_REQUEST}: Only Basic authentication is supported`);
  const userAndPasswordBase64 = authHeaderTokens[1];
  let userAndPassword: string | undefined;
  try {
    userAndPassword = Buffer.from(userAndPasswordBase64, 'base64').toString('ascii');
  } catch (error) {
    console.warn(`Failed to decode auth header: ${userAndPasswordBase64}`);
    throw new Error(`${BAD_REQUEST}: Failed to decode auth header`);
  }
  const separatorIndex = userAndPassword.indexOf(':');
  assertTruthy(
    separatorIndex > 0 && userAndPassword.length - 1,
    `${BAD_REQUEST}: Bad Authorization header user:password format`
  );
  const username = userAndPassword?.substring(0, separatorIndex);
  const password = userAndPassword?.substring(separatorIndex + 1);
  return [username, password];
}

export function buildQueryString(queryParams: Record<string, string>): string {
  return Object.entries(queryParams).reduce((queryString, [key, value], i) => {
    if (i === 0) {
      queryString = '?';
    } else {
      queryString += '&';
    }

    queryString += `${key}=${value}`;

    return queryString;
  }, '');
}
