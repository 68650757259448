import { Injectable } from '@angular/core';
import {
  BlogPost,
  isBlogPost,
  isVideoRecordings,
  VideoRecording
} from '@cp/common/protocol/IntegrationRelevantContent';
import { assertTruthy } from '@cp/common/utils/Assert';
import { StrapiService } from '@cp/web/app/common/services/strapi.service';

const blogBasePath = 'https://clickhouse.com/blog/';

@Injectable({
  providedIn: 'root'
})
export class RelevantContentService {
  constructor(private readonly strapiService: StrapiService) {}

  async load(integrationSlug: string): Promise<Array<BlogPost | VideoRecording>> {
    const [blogs, videos] = await Promise.all([
      this.loadBlogPosts(integrationSlug),
      this.loadVideoRecordings(integrationSlug)
    ]);

    return [...videos, ...blogs];
  }

  private async loadBlogPosts(integrationSlug: string): Promise<BlogPost[]> {
    const strapiObj = await this.strapiService.getStrapi().find('blog-posts', {
      populate: ['thumbnailPng'],
      filters: {
        keywords: {
          $contains: integrationSlug
        }
      },
      fields: ['title', 'shortDescription', 'date', 'keywords', 'slug']
    });

    const blogPosts = this.strapiService.convertStrapiObject(strapiObj.data);

    assertTruthy(Array.isArray(blogPosts) && blogPosts.every((blogPost) => isBlogPost(blogPost)));
    return blogPosts.map(this.normalizeBlogPosts);
  }

  private normalizeBlogPosts(blogPost: BlogPost): BlogPost {
    return {
      ...blogPost,
      url: new URL(blogPost.slug, blogBasePath).href
    };
  }

  private async loadVideoRecordings(integrationSlug: string): Promise<VideoRecording[]> {
    const strapiObj = await this.strapiService.getStrapi().find('video-recordings', {
      filters: {
        keywords: {
          $contains: integrationSlug
        }
      },
      fields: ['title', 'shortDescription', 'date', 'keywords', 'url', 'duration']
    });

    const videoRecordings = this.strapiService.convertStrapiObject(strapiObj.data);

    assertTruthy(
      Array.isArray(videoRecordings) && videoRecordings.every((videoRecording) => isVideoRecordings(videoRecording))
    );
    return videoRecordings;
  }
}
