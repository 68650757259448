import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BillUsageSummary,
  CardPaymentMethod,
  CreditCardBrand,
  HasPeriodDates,
  INVOICE_STATUS_RAW,
  InvoiceSummary,
  PaymentMethod
} from '@cp/common/protocol/Billing';
import { isDefined } from '@cp/common/protocol/Common';
import {
  Organization,
  OrganizationBillingStatus,
  OrganizationPaymentStateType,
  OrganizationRole
} from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { downloadText } from '@cp/cp-common-web/DownloadUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import {
  AdminBillingState,
  AdminBillingStateService
} from '@cp/web/app/admin/admin-billing/admin-billing-state.service';
import { AdminBillingUiService, AdminBillingUiState } from '@cp/web/app/admin/admin-billing/admin-billing-ui.service';
import { AdminBillingService } from '@cp/web/app/admin/admin-billing/admin-billing.service';
import { BillingConversionDialogComponent } from '@cp/web/app/admin/billing-conversion-dialog/billing-conversion-dialog.component';
import {
  ChangeBillingContactDialogComponent,
  getChangeBillingContactDialogConfig
} from '@cp/web/app/organizations/change-billing-contact-dialog/change-billing-contact-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { environment } from '@cp/web/environments/environment';
import { combineLatest, distinctUntilChanged, map, Observable, switchMap, takeUntil, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RequestCreditsDialogComponent } from './request-credits/request-credits-dialog.component';

@Component({
  selector: 'cp-admin-billing',
  templateUrl: './admin-billing.component.html',
  styleUrls: ['./admin-billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminBillingComponent extends OnDestroyComponent implements OnInit {
  readonly organizationObs: Observable<Organization>;
  readonly myRoleObs: Observable<OrganizationRole>;
  readonly billingStateObs: Observable<AdminBillingState>;
  readonly isLoadingObs: Observable<boolean>;
  readonly adminBillingUiStateObs: Observable<AdminBillingUiState>;

  readonly invoicesHeaderFields: string[] = ['Time period', 'Invoice no.', 'Amount', 'Usage Statement', 'Status', ' '];
  readonly creditsHeaderFields: string[] = ['Start date', 'End date', 'Credits added', 'Credits remaining'];
  readonly usageHeaderFields: string[] = ['Time period', 'Gross usage', 'Credit applied', 'Net usage', 'Statement'];

  isInvoicesHidden = false;
  isCreditsHidden = false;
  isUsageHidden = false;
  organizationId?: string;
  billingContact = '';
  paymentMethodBrand?: CreditCardBrand;
  readonly invoiceStatusDisplay = INVOICE_STATUS_RAW;

  constructor(
    private readonly organizationStateService: OrganizationStateService,
    private readonly adminBillingService: AdminBillingService,
    private readonly adminBillingStateService: AdminBillingStateService,
    private readonly adminBillingUiService: AdminBillingUiService,
    private readonly dialog: MatDialog
  ) {
    super();
    this.myRoleObs = this.organizationStateService.observeCurrentOrganizationRole();
    this.organizationObs = this.organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => this.organizationStateService.observeOrganization(id)),
      tap((org) => {
        this.organizationId = org.id;
      })
    );
    // Refresh the billingState if paymentMethodCaptured changes to true.
    this.organizationObs
      .pipe(
        filter((organization) => organization.paymentDetails.paymentMethodCaptured),
        distinctUntilChanged(
          (p, n) => p.paymentDetails.paymentMethodCaptured === n.paymentDetails.paymentMethodCaptured
        ),
        takeUntil(this.onDestroy)
      )
      .subscribe((organization) => this.adminBillingService.refreshAdminBillingState(organization.id));
    this.adminBillingUiStateObs = this.adminBillingUiService.observeState();
    this.adminBillingUiService.setPartialState({ csvButtonDisabled: false });

    this.billingStateObs = this.adminBillingStateService.observeState().pipe(
      tap((billingState) => {
        this.billingContact = billingState?.billingContact;
        this.paymentMethodBrand =
          billingState?.paymentMethod?.type === 'card'
            ? (billingState.paymentMethod as CardPaymentMethod).brand
            : undefined;
      })
    );
    this.isLoadingObs = combineLatest([this.myRoleObs, this.organizationObs, this.billingStateObs]).pipe(
      map(([myRole, organization, billingState]) => !myRole || !organization || !billingState)
    );
  }

  async ngOnInit(): Promise<void> {
    const organizationId = this.organizationStateService.getCurrentOrgId();
    if (organizationId) {
      await this.adminBillingService.refreshAdminBillingState(organizationId);
    }
  }

  checkIfBillingHistoryShouldBeVisible(
    billingStatus: OrganizationBillingStatus,
    paymentMethod?: PaymentMethod
  ): boolean {
    const statusesToShow = new Set<OrganizationBillingStatus>([
      'IN_NONPAYMENT_GRACE_PERIOD',
      'PAID',
      'PREPAID',
      'REVIEW_MANUALLY',
      'IN_MP_REMORSE_PERIOD',
      'IN_MP_GRACE_PERIOD'
    ]);
    return !!paymentMethod || statusesToShow.has(billingStatus);
  }

  checkIfTackleSubscriptionEnabled(
    billingStatus: OrganizationBillingStatus,
    paymentState: OrganizationPaymentStateType
  ): boolean {
    return paymentState === 'TACKLE' && !['IN_MP_GRACE_PERIOD', 'DECOMMISSIONED'].includes(billingStatus);
  }

  showChangeBillingAddressDialog(): void {
    BillingConversionDialogComponent.show(this.dialog);
  }

  showChangeBillingContactDialog(): void {
    assertTruthy(this.billingContact);
    this.dialog.open(ChangeBillingContactDialogComponent, getChangeBillingContactDialogConfig(this.billingContact));
  }

  parseInvoiceDateRange(invoice: Partial<HasPeriodDates>): string {
    if (!invoice.periodEndDate) {
      // No dates available to show.
      return 'N/A';
    }

    // Create dates in local time.
    const end = new Date(invoice.periodEndDate).toLocaleDateString('en-us', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    if (!invoice.periodStartDate) {
      // No start date, so this isn't a period but an invoice that doesn't correspond to a period (sales-led, finance correction, etc.).
      return end;
    }

    const from = new Date(invoice.periodStartDate).toLocaleDateString('en-us', { month: 'short', day: 'numeric' });
    return `${from} - ${end}`;
  }

  toggleInvoicesVisibility(): void {
    this.isInvoicesHidden = !this.isInvoicesHidden;
  }

  toggleCreditsVisibility(): void {
    this.isCreditsHidden = !this.isCreditsHidden;
  }

  toggleUsageVisibility(): void {
    this.isUsageHidden = !this.isUsageHidden;
  }

  async downloadStatementCSV(billId: string): Promise<void> {
    if (this.adminBillingUiService.getStateForKey('csvButtonDisabled')) return;
    assertTruthy(this.organizationId);
    this.adminBillingUiService.setStateKey('csvButtonDisabled', true);
    const report = await this.adminBillingService.getUsageStatementCSV(this.organizationId, billId);
    downloadText(report, `statement-${billId}.csv`);
    this.adminBillingUiService.setStateKey('csvButtonDisabled', false);
  }

  trackByBillId(index: number, item: BillUsageSummary): string {
    return `${item.billId}`;
  }

  trackByInvoiceId(index: number, item: InvoiceSummary): string {
    return `${item.invoiceId}`;
  }

  trackByPeriodDates(index: number, item: HasPeriodDates): string {
    return `${item.periodStartDate}_${item.periodEndDate}`;
  }

  showCreditsModal(): void {
    this.dialog.open(RequestCreditsDialogComponent, {
      width: '100%',
      maxWidth: '517px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal'
    });
  }

  get awsMarketplaceProdPageUrl(): string {
    return `https://aws.amazon.com/marketplace/pp/prodview-${environment.awsMarketplaceProdViewId}`;
  }

  readonly gcpMarketplaceProdPageUrl = 'https://console.cloud.google.com/marketplace/orders';

  getPaymentMethodBrand(): Record<string, boolean> {
    const unknown_icon = !!this.paymentMethodBrand && !['visa', 'mastercard', 'amex'].includes(this.paymentMethodBrand);
    return {
      cc_brand_icon: true,
      visa_icon: this.paymentMethodBrand === 'visa',
      mastercard_icon: this.paymentMethodBrand === 'mastercard',
      amex_icon: this.paymentMethodBrand === 'amex',
      unknown_icon
    };
  }
}
