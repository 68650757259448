export interface StrapiObject<AttributesType> {
  id: number;
  attributes: AttributesType;
}

/** A subset of Strapi Media field. */
export interface StrapiImage {
  name: string;
  width: number;
  height: number;
  ext: string;
  url: string;
  hash: string;
}

export function isStrapiImage(obj: unknown): obj is StrapiImage {
  if (typeof obj !== 'object') return false;
  const img = obj as StrapiImage;
  return !!(img.ext && img.height && img.width && img.url && img.name && img.hash);
}
