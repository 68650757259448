<div class="credentials_container">
  <div class="disclaimer">Please provide credentials before inserting data into your database
  </div>

  <form *ngIf="credentialsForm" [formGroup]="credentialsForm" class="inputs">
    <div class="seed_form_element">
      <label class="control_label">Database user</label>
      <input formControlName="user" name="user"
             spellcheck="false"
             (keydown)="onCredentialsChange()"
             (input)="onCredentialsChange()"
             (paste)="onCredentialsChange()"
             (change)="onCredentialsChange()">
      <div *ngIf="!credentialsForm.controls['user'].valid && credentialsForm.controls['user'].touched"
           class="seed_error">
        Please enter your database username
      </div>
    </div>
    <div class="seed_form_element">
      <label class="control_label">Database password</label>
      <input formControlName="password" name="password" type="password" data-cy="database-password"
             (keydown)="onCredentialsChange()"
             (input)="onCredentialsChange()"
             (paste)="onCredentialsChange()"
             (change)="onCredentialsChange()">
      <div *ngIf="!credentialsForm.controls['password'].valid && credentialsForm.controls['password'].touched"
           class="seed_error">
        Please enter your database password
      </div>
    </div>
  </form>
</div>
