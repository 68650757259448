import { Injectable } from '@angular/core';
import { RestService } from '@cp/common-services/rest.service';
import {
  ACCOUNT_API_PATH,
  AddUserEntryQuestionnaireRequest,
  AddUserToCloudWaitlistRequest,
  CheckRegionAccessRequest,
  ClientMetadataKey,
  CreatePasswordChangeTicketRequest,
  CreatePasswordChangeTicketResponse,
  CreateUserPrototypeByEmailRequest,
  EmailConfirmLastRetryRequest,
  GetConfirmEmailLastRetryResponse,
  InitializeUserSessionRequest,
  InitializeUserSessionResponse,
  ResendEmailConfirmationRequest,
  SendForgotPasswordRequest,
  SignUpRequest,
  SignUpResponse,
  UpdateExperimentFlagRequest,
  UpdateUserDetailsRequest,
  UpdateUserDetailsResponse,
  UserCloudWaitlistRegistration,
  UserDetailsUpdate,
  UserEntryQuestionnaire,
  CheckUserHasAcceptedTOSRequest,
  CheckUserHasAcceptedTOSResponse,
  AcceptAccountTOSRequest
} from '@cp/common/protocol/Account';
import { UserFeatureId } from '@cp/common/protocol/features';
import { SendRequestIntegration } from '@cp/common/protocol/Integration';
import { ExchangeTokensRequest, ExchangeTokensResponse } from '@cp/common/protocol/Oauth';
import {
  EMPTY_SIGN_IN_METADATA_ID,
  GetSignInMetadataRequest,
  GetSignInMetadataResponse,
  SignInMetadata,
  StoreSignInMetadataRequest,
  StoreSignInMetadataResponse
} from '@cp/common/protocol/SignInMetadata';
import { toDetailedLocaleDate, toTimeByTimezone } from '@cp/common/utils/DateTimeUtils';
import { AUTH0_MODE_QUERY_PARAM } from '@cp/common/utils/HttpUtils';
import { Trace } from '@cp/web/app/common/services/galaxy.service';
import { SignInMetadataService } from '@cp/web/app/common/services/sign-in-metadata.service';
import { getDeviceBrowser, getDeviceOs } from '@cp/web/app/common/utils/BrowserUtils';
import { environment } from '@cp/web/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {
  constructor(
    private readonly restService: RestService,
    private readonly signInMetadataService: SignInMetadataService
  ) {}

  async signUp(name: string, email: string, password: string): Promise<SignUpResponse> {
    const request: SignUpRequest = {
      name,
      email: email,
      password: password
    };
    return await this.restService.post('account/signUp', request);
  }

  async initializeUserSession(): Promise<InitializeUserSessionResponse> {
    const request: InitializeUserSessionRequest = { rpcAction: 'initializeUserSession' };
    return await this.restService.post<InitializeUserSessionResponse>(ACCOUNT_API_PATH, request);
  }

  @Trace()
  async updateUserDetails(userDetailsUpdate: UserDetailsUpdate): Promise<UpdateUserDetailsResponse> {
    const updateUserDetailsRequest: UpdateUserDetailsRequest = { rpcAction: 'updateUserDetails', ...userDetailsUpdate };
    return await this.restService.post<UpdateUserDetailsResponse>(ACCOUNT_API_PATH, updateUserDetailsRequest);
  }

  async addUserToCloudWaitlist(userCloudWaitlistRegistration: UserCloudWaitlistRegistration): Promise<void> {
    const addUserToWaitlistRequest: AddUserToCloudWaitlistRequest = {
      rpcAction: 'addUserToCloudWaitlist',
      ...userCloudWaitlistRegistration
    };
    await this.restService.post(ACCOUNT_API_PATH, addUserToWaitlistRequest);
  }

  async addUserEntryQuestionnaire(userEntryQuestionnaire: UserEntryQuestionnaire): Promise<void> {
    const addUserEntryQuestionnaireRequest: AddUserEntryQuestionnaireRequest = {
      rpcAction: 'addUserEntryQuestionnaire',
      ...userEntryQuestionnaire
    };
    await this.restService.post(ACCOUNT_API_PATH, addUserEntryQuestionnaireRequest);
  }

  async sendRequestIntegration(text: string, recaptchaToken: string): Promise<void> {
    const request: SendRequestIntegration = { text, recaptchaToken };
    await this.restService.post('account/sendRequestIntegration', request);
  }

  async exchangeTokensForAuthorizationCode(request: ExchangeTokensRequest): Promise<ExchangeTokensResponse> {
    return await this.restService.post<ExchangeTokensResponse>('oauth/exchangeTokensForAuthorizationCode', request);
  }

  async updateExperimentFlag(flag: UserFeatureId, isEnabled: boolean): Promise<void> {
    const request: UpdateExperimentFlagRequest = { rpcAction: 'updateUserFeatureFlags', flags: { [flag]: isEnabled } };
    await this.restService.post(ACCOUNT_API_PATH, request);
  }

  /**
   * Triggers email confirmation re-sending by the server.
   * `authProviderUserId` here may be either a plain CP user id
   * or an auth-provider user id: must be post-processed on the server.
   */
  async resendEmailConfirmation(cpUserIdOrAuthProviderUserId: string): Promise<void> {
    const request: ResendEmailConfirmationRequest = {
      rpcAction: 'resendEmailConfirmation',
      userId: cpUserIdOrAuthProviderUserId
    };
    await this.restService.post(ACCOUNT_API_PATH, request);
  }

  async sendForgotPasswordEmail(
    email: string,
    clientMetadata?: Partial<Record<ClientMetadataKey, string>>
  ): Promise<void> {
    const now = new Date();
    const dateString = toDetailedLocaleDate(now);
    const timeString = toTimeByTimezone(now, undefined, 'shortGeneric');

    const userAgent = window.navigator.userAgent;
    const deviceBrowser = getDeviceBrowser(userAgent);
    const deviceOs = getDeviceOs(userAgent);

    clientMetadata = {
      ...clientMetadata,
      requestTime: `${dateString} ${timeString}`,
      device: `${deviceBrowser.name} ${deviceBrowser.version}, ${deviceOs.name} ${deviceOs.version}`
    };

    const request: SendForgotPasswordRequest = {
      rpcAction: 'sendForgotPasswordEmail',
      email,
      clientMetadata
    };
    await this.restService.post(ACCOUNT_API_PATH, request);
  }

  async getConfirmEmailLastRetry(userId: string): Promise<GetConfirmEmailLastRetryResponse> {
    const request: EmailConfirmLastRetryRequest = { rpcAction: 'getConfirmEmailLastRetry', userId };
    return await this.restService.post(ACCOUNT_API_PATH, request);
  }

  /**
   * Stores sign-in metadata in the database. Returns metadata id.
   * Designed to preserve metadata when user navigates to a 3rd party site during sign-in/up.
   */
  async storeSignInMetadata(metadata: SignInMetadata): Promise<string> {
    console.debug('AccountApiService::storeSignInMetadata', JSON.stringify(metadata));
    if (Object.keys(metadata).length === 0 || Object.values(metadata).every((v) => v === undefined)) {
      return EMPTY_SIGN_IN_METADATA_ID;
    }
    const request: StoreSignInMetadataRequest = { rpcAction: 'storeSignInMetadata', metadata };
    const response = await this.restService.post<StoreSignInMetadataResponse>(ACCOUNT_API_PATH, request);
    return response.metadataId;
  }

  /** Queries sign-in metadata from the server by metadata id. */
  async getSignInMetadata(metadataId: string, userId: string | undefined): Promise<SignInMetadata> {
    if (metadataId === EMPTY_SIGN_IN_METADATA_ID) {
      if (!userId) {
        return {};
      }

      /** We haven't found any meta data yet so attempt to get it from the user profile -- exit gracefully if it doesn't work. */
      const request: GetSignInMetadataRequest = { rpcAction: 'getSignInMetadata', metadataId: '', userId };
      try {
        const { metadata } = await this.restService.post<GetSignInMetadataResponse>(ACCOUNT_API_PATH, request);
        console.debug('AccountApiService::getSignInMetadata', metadata);
        return metadata;
      } catch {
        return {};
      }
    }

    const request: GetSignInMetadataRequest = { rpcAction: 'getSignInMetadata', metadataId, userId };
    const { metadata } = await this.restService.post<GetSignInMetadataResponse>(ACCOUNT_API_PATH, request);
    console.debug('AccountApiService::getSignInMetadata', metadata);

    if (metadata.url) {
      this.signInMetadataService.setUrl(metadata.url);
    }

    return metadata;
  }

  async checkRegionAccess() {
    const request: CheckRegionAccessRequest = { rpcAction: 'checkRegionAccess' };
    console.debug('AccountApiService::checkRegionAccess');
    return this.restService.post(ACCOUNT_API_PATH, request);
  }

  async createUserPrototypeByEmail(email: string, name: string, metadataId: string, recaptchaToken: string) {
    console.debug(`AccountApiService::createUserPrototypeByEmail ${email}:${name}:${metadataId}`);
    const request: CreateUserPrototypeByEmailRequest = {
      rpcAction: 'createUserPrototypeByEmail',
      email,
      fullName: name,
      metadataId: metadataId === EMPTY_SIGN_IN_METADATA_ID ? '' : metadataId,
      recaptchaToken: recaptchaToken
    };
    await this.restService.post<void>(ACCOUNT_API_PATH, request);
  }

  /** Retrieves an IDP URL to change password on the IDP side. Used for Auth0 only. */
  async getChangePasswordWithRedirectUrl(): Promise<string> {
    const request: CreatePasswordChangeTicketRequest = {
      rpcAction: 'createPasswordChangeTicket',
      returnUrl: `${environment.webUrl}/profile?${AUTH0_MODE_QUERY_PARAM}`
    };
    const response = await this.restService.post<CreatePasswordChangeTicketResponse, CreatePasswordChangeTicketRequest>(
      ACCOUNT_API_PATH,
      request
    );
    return response.redirectUrl;
  }

  async checkUserHasAcceptedTOS(): Promise<string | undefined> {
    const request: CheckUserHasAcceptedTOSRequest = {
      rpcAction: 'checkUserHasAcceptedTOS'
    };
    const response = await this.restService.post<CheckUserHasAcceptedTOSResponse, CheckUserHasAcceptedTOSRequest>(
      ACCOUNT_API_PATH,
      request
    );
    return response.contractId;
  }

  async acceptAccountTOS(): Promise<void> {
    const request: AcceptAccountTOSRequest = {
      rpcAction: 'acceptAccountTOS'
    };
    await this.restService.post<AcceptAccountTOSRequest>(ACCOUNT_API_PATH, request);
  }
}
