import { assertTruthy, truthy } from '@cp/common/utils/Assert';
import {
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
  SECONDS_PER_MONTH,
  SECONDS_PER_YEAR
} from '@cp/common/utils/DateTimeUtils';
import { BAD_REQUEST } from '@cp/common/utils/HttpError';
import { isIsoDateString, IsoDateFormat } from '@cp/common/utils/ValidationUtils';

/**
 * Strips long fractional part.
 * Keeps only integer values for thousands and above, 1 digit for 10..99,
 * 2 digits for 1..9 and up to 3 non-zero digits for values in 0..1 range.
 *
 * Can be used to optimize size of a large response with data series.
 */
export function compressNumber(value: string): string {
  const length = value.length;
  const dotIndex = value.indexOf('.');
  if (dotIndex === -1) {
    return value;
  }
  // Check that value format is supported. Do not optimize unsupported formats or random strings.
  for (let i = value[0] === '-' ? 1 : 0; i < value.length; i++) {
    if (i !== dotIndex && Number.isNaN(Number(value[i]))) {
      return value;
    }
  }
  let result: string;
  if (dotIndex >= 3) {
    // >=100: remove the fractional part.
    return value.substring(0, dotIndex);
  } else if (dotIndex >= 2) {
    // 10..99: keep 1 digit from the fractional part.
    result = value.substring(0, Math.min(dotIndex + 2, length));
  } else if (dotIndex >= 1 && value[0] !== '0') {
    // 1..9: keep 2 digits from the fractional part.
    result = value.substring(0, Math.min(dotIndex + 3, length));
  } else {
    // Keep 3 non-zero digits.
    let nonZeroDigitCount = 0;
    result = '0.';
    for (let i = dotIndex + 1; i < length && nonZeroDigitCount < 3; i++) {
      const digit = value[i];
      result += digit;
      const isZero = digit === '0';
      nonZeroDigitCount += !isZero || nonZeroDigitCount > 0 ? 1 : 0; // Count all digits after the first non-zero one.
    }
  }
  // Remove trailing zeros.
  assertTruthy(result.length > dotIndex);
  let newLength = result.length;
  while (newLength - 1 >= dotIndex) {
    const ch = result[newLength - 1];
    if (ch !== '0' && ch !== '.') {
      break;
    }
    newLength--;
  }
  if (newLength < result.length) {
    result = result.substring(0, newLength);
  }
  return result;
}

/*
 * Builds a safe and valid CSV value text.
 * Quotes CSV value with double quotes if the value contains a comma.
 */
export function formatCsvField(field: string | undefined | null): string {
  const result = (field || '').replace(/"/g, '""');
  assertTruthy(!result.includes('\n'), 'Not a valid CSV value');
  return result.includes(',') ? `"${result}"` : result;
}

export function formatImportFilename(raw: string | undefined | null): string {
  // Remove all characters except of alphabet, spaces, dots and numbers.
  const result = (raw || '').replace(/[^a-zA-Z0-9 \\.]/g, '-');
  // Replace all white spaces with '_'.
  return result.split(' ').join('_');
}

/** 3-letter month names used by formatMonthName. */
const MONTH_NAMES_3_LETTERS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/** Returns an ISO-8601 formatted date like 2023-04-18T18:01:14.000Z for a date in any of the following formats:
 * - A Date object
 * - Timestamp in millis
 * - an ISO-8601 formatted date like 2023-04-18T18:01:14.000Z or 2023-04-18 (if the input was a date only string)
 */
export function extractIsoDateString(date: Date | number | string, format: IsoDateFormat = 'DATE_ONLY'): string {
  switch (typeof date) {
    case 'object':
      return date.toISOString();
    case 'number':
      assertTruthy(date >= 0, BAD_REQUEST);
      return new Date(date).toISOString();
    case 'string':
      truthy(isIsoDateString(date, format));
      return date;
    default:
      throw Error(BAD_REQUEST);
  }
}

/** Formats ISO date as 'MMM'. Example "Apr".
 * date can be:
 * - A Date object
 * - Timestamp in millis
 * - an ISO-8601 formatted date like 2023-04-18T18:01:14.000Z
 */
export function format3LetterMonthName(date: Date | number | string): string {
  const isoDateStr = extractIsoDateString(date);
  const monthIndex = Number(isoDateStr.substring(5, 7)) - 1;
  return MONTH_NAMES_3_LETTERS[monthIndex];
}

/** Formats ISO date as 'MMM dd'. Example "Apr 2".
 * date can be:
 * - A Date object
 * - Timestamp in millis
 * - an ISO-8601 formatted date like 2023-04-18T18:01:14.000Z
 */
export function formatDateAndMonthAndDate(date: Date | number | string): string {
  const isoDateStr = extractIsoDateString(date);
  return format3LetterMonthName(isoDateStr) + ' ' + Number(isoDateStr.substring(8, 10));
}

/** Formats time interval in seconds to human readable format. For example 3668 => 1 hour, 1 minute and 8 seconds. */
export function secondsToString(seconds: number): string {
  if (seconds <= 0) {
    return 'N/A';
  }
  let remaining = seconds;
  const timeParts: string[] = [];
  if (remaining >= SECONDS_PER_YEAR) {
    const numYears = Math.floor(seconds / SECONDS_PER_YEAR);
    timeParts.push(`${numYears} ${numYears > 1 ? 'years' : 'year'}`);
    remaining = remaining % SECONDS_PER_YEAR;
  }
  if (remaining >= SECONDS_PER_MONTH) {
    const numMonths = Math.floor(remaining / SECONDS_PER_MONTH);
    timeParts.push(`${numMonths} ${numMonths > 1 ? 'months' : 'month'}`);
    remaining = remaining % SECONDS_PER_MONTH;
  }
  if (remaining >= SECONDS_PER_DAY) {
    const numDays = Math.floor(remaining / SECONDS_PER_DAY);
    timeParts.push(`${numDays} ${numDays > 1 ? 'days' : 'day'}`);
    remaining = remaining % SECONDS_PER_DAY;
  }
  if (remaining >= SECONDS_PER_HOUR) {
    const numHours = Math.floor(remaining / SECONDS_PER_HOUR);
    timeParts.push(`${numHours} ${numHours > 1 ? 'hours' : 'hour'}`);
    remaining = remaining % SECONDS_PER_HOUR;
  }
  if (remaining >= SECONDS_PER_MINUTE) {
    const numMinutes = Math.floor(remaining / SECONDS_PER_MINUTE);
    timeParts.push(`${numMinutes} ${numMinutes > 1 ? 'minutes' : 'minute'}`);
  }
  const numSeconds = remaining % SECONDS_PER_MINUTE;
  if (numSeconds > 0) {
    timeParts.push(`${numSeconds} ${numSeconds === 1 ? 'second' : 'seconds'}`);
  }
  return timeParts.join(', ').replace(/,([^,]*)$/, ' and' + '$1');
}

export function timeoutMinutesToString(minutes: number): string {
  if (minutes === 5) {
    return '5 minutes (default)';
  }
  return secondsToString(minutes * SECONDS_PER_MINUTE);
}
