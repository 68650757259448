import { Observable } from 'rxjs';

export type AuthEvent = AuthEventAuthenticated | AuthEventNotAuthenticated;

export interface IWebAuthService {
  observeAuthEvents: () => Observable<AuthEvent>;
  getAccessToken: () => Promise<string | undefined>;
}

export type MfaType =
  /** Mfa is disabled */
  | 'NOMFA'

  /** sms verification */
  | 'SMS_MFA'

  /**
   * Totp token generated by an authenticator app
   * (e.g. Google Authenticator, 1Password, Okta verifier etc)
   */
  | 'SOFTWARE_TOKEN_MFA'

  /**
   * Unknown mfa verification method
   */
  | 'UNKNOWN_MFA';

export type AuthEventType =
  | 'parsingCallbackUrl'
  | 'codeFlow'
  | 'configured'
  | 'signIn'
  | 'signIn_failure'
  | 'signOut'
  | 'tokenRefresh'
  | 'cognitoHostedUI'
  | 'cognitoHostedUI_failure'
  | 'customOAuthState'
  | 'tokenRefresh_failure';

export type AuthEventAuthenticated<AppState extends object = {}> = {
  type: AuthEventType;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  /** Access token expiration time (Unix Epoch) in seconds. */
  accessTokenExpiration: number;
  /** Sign in metadata id. Supported by Cognito only. Deprecated. To be removed as a part of #4760:  */
  signInMetadataId?: string;
  /** Application state at the time of sign-in/sign-up. Supported by Auth0. */
  appState?: AppState;
};

export type AuthEventNotAuthenticated = {
  type: AuthEventType;
};

/**
 * Returns true if auth event is 'AuthEventAuthenticated'.
 * Type assertion/filter for authenticated auth events.
 */
export function isAuthenticated(e: AuthEvent): e is AuthEventAuthenticated {
  return !!(e as AuthEventAuthenticated).accessToken;
}

/**
 * Returns true if auth event is 'AuthEventNotAuthenticated'.
 * Type assertion/filter for non-authenticated auth events.
 */
export function isNotAuthenticated(e: AuthEvent): e is AuthEventNotAuthenticated {
  return !isAuthenticated(e);
}

export interface SignInWithCredentialsResponse {
  isAwaitingTotpVerification?: boolean;
  userId: string;
}
