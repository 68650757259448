import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

export type SystemMessageType = 'error' | 'warning' | 'info' | 'spinner' | 'success';

@Component({
  standalone: true,
  selector: 'cw-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMessageComponent implements OnInit, OnChanges {
  @Input()
  /**
   * This is optional because system-message supports ng-content.
   * You can alternatively pass html content into tag itself.
   */
  message?: string;

  @Input()
  messageType: SystemMessageType = 'error';

  @Input()
  /**
   * If this systemMessage is dismissible, you can pass an appropriately unique and consistent dismissalKey.
   * Based on this key we will know not to display this message after it was dismissed.
   * The dismissal is saved in localStorage.
   *
   * Leave undefined to mean the message is not dismissible.
   */
  dismissalKey: string | undefined;

  @Input()
  /** Whether to show message icon. */
  showIcon = true;

  svgIcon?: string = undefined;

  ngOnInit(): void {
    this.setMessageTypeIcon();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['messageType']) {
      this.setMessageTypeIcon();
    }
  }

  private setMessageTypeIcon(): void {
    switch (this.messageType) {
      case 'error':
        this.svgIcon = 'error_icon';
        break;
      case 'info':
        this.svgIcon = 'info_icon';
        break;
      case 'spinner':
        this.svgIcon = 'loader';
        break;
      case 'success':
        this.svgIcon = 'paper_airplane_icon';
        break;
      case 'warning':
        // no icon in the designs
        break;
    }
  }

  dismissMessage(): void {
    if (this.dismissalKey) {
      localStorage.setItem(`systemMessageDismissed-${this.dismissalKey}`, 'true');
    }
  }

  isDismissible(): boolean {
    return !!this.dismissalKey;
  }

  isDismissed(): boolean {
    if (!this.dismissalKey) {
      return false;
    }
    const isDismissed = localStorage.getItem(`systemMessageDismissed-${this.dismissalKey}`);
    return isDismissed === 'true';
  }
}
