import { Pipe, PipeTransform } from '@angular/core';
import { assertTruthy } from '@cp/common/utils/Assert';

export type DataSizePointFormat =
  /**
   * Formats 'bytes' values into bytes abbreviation. 1k = 1000.
   * We use this format to present storage metrics in bytes.
   * Example: 1000 => '1 KB'
   */
  | 'bytes-letter'
  /**
   * Formats 'bytes' values into binary exponent bytes abbreviation. 1k = 1024.
   * We use this format to present memory consumption metrics in bytes.
   * Example: 1024 => '1 KiB'
   */
  | 'binary-bytes-letter'
  /**
   * Formats values like bytes using a whole word suffix. 1k = 1000.
   * Example: 1000 => '1 Kilobytes'.
   */
  | 'bytes-word'
  /**
   * Formats values like bytes using a whole word suffix (binary exponents). 1k = 1024.
   * Example: 1024 => '1 Kibibytes'.
   */
  | 'binary-bytes-word'
  /**
   * Adds numeric symbols to value, same as in Highcharts by default. 1k = 1000.
   * See See https://api.highcharts.com/highcharts/lang.numericSymbols.
   * Example: 10000 => '10k'
   */
  | 'numeric-symbols'
  /**
   * Formats numeric values using short scale letters. 1k = 1000.
   * See https://en.wikipedia.org/wiki/Long_and_short_scale.
   * Example: 10000 => '10 K'
   */
  | 'short-scale-letter'
  /**
   * Formats values using short scale words. 1k = 1000.
   * See https://en.wikipedia.org/wiki/Long_and_short_scale.
   * Example: 10000 => '10 thousands'
   */
  | 'short-scale-word'
  /**
   * Formats values using locale and by rounding decimal points (2 decimals for numbers greater than 1, 8 decimals for fractions).
   * Example: 12345678.9876 => '12,345,678.99'
   * Example: 0.12345678901234 => '0.12345678'
   */
  | 'human-readable-number';

/** TODO: these values are not localized correctly for plural form. Example: "1 thousands". */
const UNITS_BY_TYPE: Readonly<Record<DataSizePointFormat, string[]>> = {
  'bytes-letter': ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  'binary-bytes-letter': ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'],
  'bytes-word': ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Petabytes'],
  'binary-bytes-word': ['Bytes', 'Kibibytes', 'Mebibytes', 'Gigibytes', 'Pebibytes'],
  'numeric-symbols': ['', 'k', 'M', 'G', 'T', 'P', 'E'],
  'short-scale-letter': ['', 'K', 'M', 'B', 'T'],
  'short-scale-word': ['', 'thousands', 'millions', 'billions', 'trillions'],
  'human-readable-number': ['']
};

/** Spacer value between value & unit. */
const UNIT_SPACER_BY_TYPE: Readonly<Record<DataSizePointFormat, string>> = {
  'bytes-letter': ' ',
  'binary-bytes-letter': ' ',
  'bytes-word': ' ',
  'binary-bytes-word': ' ',
  'numeric-symbols': '',
  'short-scale-letter': ' ',
  'short-scale-word': ' ',
  'human-readable-number': ''
};

export const DEFAULT_NAN_TEXT = 'N/A';
export const DEFAULT_DATA_SIZE_DECIMALS = 2;

/** See formatDataSize() method for details. */
@Pipe({
  name: 'dataSize'
})
export class DataSizePipe implements PipeTransform {
  transform(
    size: number,
    unitsType: DataSizePointFormat = 'bytes-letter',
    decimals = DEFAULT_DATA_SIZE_DECIMALS,
    nanText = DEFAULT_NAN_TEXT
  ): string {
    return formatDataSize(size, unitsType, decimals, nanText);
  }
}

/** Formats given size as a data size. */
export function formatDataSize(
  size: number,
  unitsType: DataSizePointFormat = 'bytes-letter',
  decimals: number = 2,
  nanText = DEFAULT_NAN_TEXT
): string {
  if (Number.isNaN(size)) {
    return nanText;
  }
  assertTruthy(decimals === 0 || decimals === 1 || decimals === 2);
  const sign = size < 0 ? '-' : '';
  const absSize = Math.abs(size);

  const units = UNITS_BY_TYPE[unitsType];
  const sizeOf1Kb = unitsType === 'binary-bytes-letter' || unitsType == 'binary-bytes-word' ? 1024 : 1000;

  if (unitsType === 'human-readable-number') {
    decimals = size < 1 && size > -1 ? 8 : 2;
  }

  if (size < 1 && size > -1) {
    return `${size.toFixed(Number.isInteger(size) ? 0 : decimals)}${units[0] === '' ? '' : ` ${units[0]}`}`;
  }
  const power = Math.min(Math.floor(Math.log(absSize) / Math.log(sizeOf1Kb)), units.length - 1);
  const sizeInUnits = absSize / Math.pow(sizeOf1Kb, power);
  const formattedSizeInUnits = !Number.isInteger(sizeInUnits)
    ? Math.round(sizeInUnits * Math.pow(10, decimals)) / Math.pow(10, decimals)
    : sizeInUnits;

  const unit = units[power];
  const spacer = UNIT_SPACER_BY_TYPE[unitsType];
  return unitsType === 'human-readable-number'
    ? `${sign}${formattedSizeInUnits.toLocaleString()}${spacer}${unit}`.trim()
    : `${sign}${formattedSizeInUnits}${spacer}${unit}`.trim();
}
