import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { truthy } from '@cp/common/utils/Assert';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import {
  AdminBillingState,
  AdminBillingStateService
} from '@cp/web/app/admin/admin-billing/admin-billing-state.service';
import { AdminBillingService } from '@cp/web/app/admin/admin-billing/admin-billing.service';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { environment } from '@cp/web/environments/environment';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElements
} from '@stripe/stripe-js';
import { Observable } from 'rxjs';

interface AddCreditCardUiState {
  buttonDisabled: boolean;
  errorMessage?: string;
  clientSecret?: string;
  stripeCardNumberErrorMessage?: string;
  stripeCardExpiryErrorMessage?: string;
  stripeCardCvcErrorMessage?: string;
  stripeCardNumberComplete: boolean;
  stripeCardExpiryComplete: boolean;
  stripeCardCvcComplete: boolean;
  stripeCardNumberReady: boolean;
  stripeCardExpiryReady: boolean;
  stripeCardCvcReady: boolean;
  stripeReady: boolean;
  stripeComplete: boolean;
}

@Component({
  selector: 'cp-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Form component containing credit card information (card number, expiry, CVC).
 * Since this component uses Stripe Elements, it saves input directly to Stripe.
 * The sensitive information is never exposed to our server or even the rest of the web application.
 * See designs: https://www.figma.com/file/i00FDyul4FXB8thLi0Dqfl/Beta?node-id=4239%3A28335
 */
export class AddCreditCardComponent implements AfterViewInit {
  GENERIC_ERROR = 'Error occurred when saving the payment information.';
  uiState: AddCreditCardUiState = {
    buttonDisabled: false,
    stripeCardNumberComplete: false,
    stripeCardExpiryComplete: false,
    stripeCardCvcComplete: false,
    stripeCardNumberReady: false,
    stripeCardExpiryReady: false,
    stripeCardCvcReady: false,
    stripeReady: false,
    stripeComplete: false
  };

  private stripe?: Stripe;
  private stripeElements?: StripeElements;
  private cardNumberElement?: StripeCardNumberElement;
  private cardExpiryElement?: StripeCardExpiryElement;
  private cardCvcElement?: StripeCardCvcElement;
  private clientSecret?: string;
  private setupIntentId?: string;
  adminBillingStateObs: Observable<AdminBillingState>;

  @Output()
  backButtonClick = new EventEmitter();

  @Output()
  creditCardSaved = new EventEmitter();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly adminBillingService: AdminBillingService,
    private readonly adminBillingStateService: AdminBillingStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.adminBillingStateObs = adminBillingStateService.observeState();
  }

  async ngAfterViewInit() {
    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    const clientSecretResponse = await this.adminBillingService.getClientSecret(organizationId);
    this.clientSecret = clientSecretResponse.clientSecret;
    this.setupIntentId = clientSecretResponse.setupIntentId;

    this.stripe = truthy(await loadStripe(environment.stripePublishableKey), 'FAILED_TO_INIT_STRIPE_CLIENT');

    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
    this.stripeElements = this.stripe.elements();

    // Create and mount the Payment Element
    this.cardNumberElement = this.stripeElements.create('cardNumber', { showIcon: true });
    this.cardNumberElement.mount('#cardNumberElement');
    this.cardNumberElement.on('change', (event) => {
      // Set this complete flag and if other complete flags are on, switch on the big stripeComplete flag
      this.uiState.stripeCardNumberComplete = event.complete;
      this.uiState.stripeComplete =
        event.complete && this.uiState.stripeCardExpiryComplete && this.uiState.stripeCardCvcComplete;
      this.uiState.stripeCardNumberErrorMessage = event.error?.message;
      this.cdr.markForCheck();
    });
    this.cardNumberElement.on('ready', () => {
      truthy(this.cardNumberElement).focus();
      // Set this ready flag on and if other ready flags are on, switch on the big stripeReady flag
      this.uiState.stripeCardNumberReady = true;
      this.uiState.stripeReady = this.uiState.stripeCardExpiryReady && this.uiState.stripeCardCvcReady;
      this.cdr.markForCheck();
    });
    this.cardExpiryElement = this.stripeElements.create('cardExpiry', {});
    this.cardExpiryElement.mount('#cardExpiryElement');
    this.cardExpiryElement.on('change', (event) => {
      this.uiState.stripeCardExpiryComplete = event.complete;
      this.uiState.stripeComplete =
        event.complete && this.uiState.stripeCardNumberComplete && this.uiState.stripeCardCvcComplete;
      this.uiState.stripeCardExpiryErrorMessage = event.error?.message;
      this.cdr.markForCheck();
    });
    this.cardExpiryElement.on('ready', () => {
      this.uiState.stripeCardExpiryReady = true;
      this.uiState.stripeReady = this.uiState.stripeCardNumberReady && this.uiState.stripeCardCvcReady;
      this.cdr.markForCheck();
    });
    this.cardCvcElement = this.stripeElements.create('cardCvc', {});
    this.cardCvcElement.mount('#cardCvcElement');
    this.cardCvcElement.on('change', (event) => {
      this.uiState.stripeCardCvcComplete = event.complete;
      this.uiState.stripeComplete =
        event.complete && this.uiState.stripeCardNumberComplete && this.uiState.stripeCardExpiryComplete;
      this.uiState.stripeCardCvcErrorMessage = event.error?.message;
      this.cdr.markForCheck();
    });
    this.cardCvcElement.on('ready', () => {
      this.uiState.stripeCardCvcReady = true;
      this.uiState.stripeReady = this.uiState.stripeCardNumberReady && this.uiState.stripeCardExpiryReady;
      this.cdr.markForCheck();
    });
  }

  async onSubmit(): Promise<void> {
    this.uiState.buttonDisabled = true;
    this.uiState.errorMessage = undefined;

    const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
    try {
      if (!this.stripe) {
        this.uiState.errorMessage = 'Error occurred when saving the payment information. Please try again later.';
        return;
      }

      if (!this.adminBillingStateService.getCanUpdatePaymentMethod()) {
        this.uiState.errorMessage = 'Error occurred when saving the payment information. Please try again later.';
        return;
      }

      const billingAddress = truthy(this.adminBillingStateService.getAddress()?.billingAddress);

      // Reformat the object. Stripe will throw an error if we send 'postalCode' (they're really strict about inputs).
      const { postalCode, ...rest } = billingAddress;
      const address = { ...rest, postal_code: postalCode };

      const stripeResponse = await this.stripe.confirmCardSetup(truthy(this.clientSecret), {
        payment_method: {
          card: truthy(this.cardNumberElement),
          billing_details: {
            address
          },
          metadata: {
            setupIntentId: this.setupIntentId ?? null
          }
        },
        return_url: `${environment.webUrl}/organization/admin/billing`
      });

      if (stripeResponse.error) {
        // Display the error and don't close the modal.
        this.uiState.stripeComplete = false;
        this.uiState.errorMessage =
          stripeResponse.error?.type === 'card_error' ? stripeResponse.error.message : this.GENERIC_ERROR;
        return;
      }

      try {
        const organizationId = this.organizationStateService.getCurrentOrgIdOrFail();
        const confirmUpdatedPaymentMethodResponse = await this.adminBillingService.confirmUpdatedPaymentMethod(
          organizationId,
          stripeResponse.setupIntent.id
        );
        const { paymentMethod, canUpdatePaymentMethod } = confirmUpdatedPaymentMethodResponse;
        this.adminBillingStateService.setPartialState({ paymentMethod, canUpdatePaymentMethod });
      } catch (e) {
        this.uiState.stripeComplete = false;
        this.uiState.errorMessage = getServerErrorMessage(e) ?? this.GENERIC_ERROR;
        return;
      }

      this.creditCardSaved.emit();
    } catch (e) {
      //TODO(1786) Collect forensics log for billing
      console.error(e);
      this.uiState.errorMessage = this.GENERIC_ERROR;
    } finally {
      this.uiState.buttonDisabled = false;
      if (this.uiState.errorMessage) {
        // If there's an error, prepare for a new setup intent.
        const clientSecretResponse = await this.adminBillingService.getClientSecret(organizationId);
        this.clientSecret = clientSecretResponse.clientSecret;
        this.setupIntentId = clientSecretResponse.setupIntentId;
      }
      this.cdr.markForCheck();
    }
  }

  handleBackButton() {
    this.backButtonClick.emit();
  }
}
