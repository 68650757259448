import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Instance, IpAccessListEntry } from '@cp/common/protocol/Instance';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy } from '@cp/common/utils/Assert';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { BehaviorSubject, distinctUntilChanged, Observable, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './import-ip-access-list-from-instance-dialog.component.html',
  styleUrls: ['./import-ip-access-list-from-instance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportIpAccessListFromInstanceDialogComponent {
  orgInstancesObs!: Observable<Array<Instance>>;

  /** Currently selected instance in the list of instances. */
  selectedInstanceIdSubject = new BehaviorSubject<string | undefined>(undefined);

  /** IP access list of the currently selected instance in the list of instances. */
  selectedInstanceIpAccessListObs: Observable<Array<IpAccessListEntry>>;

  /** Currently selected IP access list entries in the table to import. */
  selectedIpAccessListEntries: Array<IpAccessListEntry> = [];

  isSubmitInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly instanceId: string,
    readonly dialogRef: MatDialogRef<unknown>,
    private readonly instanceService: InstanceService,
    private readonly instanceStateService: InstanceStateService,
    private readonly snackBar: MatSnackBar
  ) {
    this.selectedInstanceIpAccessListObs = this.selectedInstanceIdSubject.pipe(
      distinctUntilChanged(),
      // Once instance changes -> reset selection.
      tap(() => {
        this.selectedIpAccessListEntries = [];
      }),
      switchMap((id) => (id ? this.instanceStateService.observeInstance(id) : of(undefined))),
      map((instance) => instance?.ipAccessList || []),
      // Once ipAccessList of the currently selected instance changes -> preserve selection for valid entries.
      tap((availableEntries) => {
        this.selectedIpAccessListEntries = this.selectedIpAccessListEntries.filter((e1) =>
          availableEntries.some((e2) => e2.source === e1.source)
        );
      })
    );

    this.orgInstancesObs = this.instanceStateService.observeInstances().pipe(
      map((instancesMap) => Object.values(instancesMap).sort((a, b) => a.name.localeCompare(b.name))),
      map((instances) => instances.filter((instance) => instance.id !== this.instanceId)),
      tap((instances) => {
        // If currently selected instance was removed -> switch to another.
        if (!instances.some((instance) => instance.id === this.selectedInstanceIdSubject.value)) {
          this.selectedInstanceIdSubject.next(instances[0]?.id);
        }
      })
    );
  }

  toggleIpAccessListEntrySelection(entry: IpAccessListEntry): void {
    if (this.selectedIpAccessListEntries.includes(entry)) {
      this.selectedIpAccessListEntries = this.selectedIpAccessListEntries.filter((e) => e !== entry);
    } else {
      this.selectedIpAccessListEntries.push(entry);
    }
  }

  onSelectedInstanceChanged(instanceId: string): void {
    this.selectedInstanceIdSubject.next(instanceId);
  }

  get nEntriesText(): string {
    const count = this.selectedIpAccessListEntries.length;
    return count !== 1 ? `${count} entries` : '1 entry';
  }

  async submit(): Promise<void> {
    assertTruthy(this.selectedIpAccessListEntries.length > 0);
    try {
      this.isSubmitInProgress = true;
      const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
      const newIpAccessList = instance.ipAccessList.filter(
        (e1) => !this.selectedIpAccessListEntries.some((e2) => e2.source === e1.source)
      );
      newIpAccessList.push(...this.selectedIpAccessListEntries);
      await this.instanceService.updateIpAccessList(this.instanceId, newIpAccessList);
      this.snackBar.open('IP addresses have been added', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      this.snackBar.open('Failed to update access list', 'Dismiss', { duration: 5000 });
    } finally {
      this.isSubmitInProgress = false;
    }
  }

  get selectedIpAccessListEntrySources(): string[] {
    return this.selectedIpAccessListEntries.map((e) => e.source);
  }

  convertInstancesToSelectOptions(instances: Array<Instance>): Array<SeedSelectOption> {
    return instances.map((instance) => ({
      value: instance.id,
      label: instance.name,
      dataCy: 'other-instance-option',
      dataCyValue: instance.id
    }));
  }
}

export function getImportIpAccessListFromInstanceDialogConfig(): Partial<MatDialogConfig<string>> {
  return {
    width: '100%',
    maxWidth: '517px',
    autoFocus: true,
    restoreFocus: false,
    panelClass: 'modal'
  };
}
