export function getMinMaxValues(
  selectedMin: number,
  selectedMax: number,
  minValid: number,
  maxValid: number
): Array<number> {
  let minToReturn = selectedMin,
    maxToReturn = selectedMax;

  if (minToReturn < minValid) {
    minToReturn = minValid;
  }

  if (minToReturn > maxValid) {
    minToReturn = maxValid;
  }

  if (maxToReturn > maxValid) {
    maxToReturn = maxValid;
  }

  if (maxToReturn < minValid) {
    maxToReturn = minValid;
  }

  if (minToReturn > maxToReturn) {
    minToReturn = maxToReturn;
  }

  return [minToReturn, maxToReturn];
}
