import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address } from '@cp/common/protocol/Billing';
import { DeepReadonlyArray } from '@cp/common/protocol/Common';
import { GalaxyEventType } from '@cp/common/protocol/Galaxy';
import { COUNTRIES_SEED_SELECT, STATES_SEED_SELECT } from '@cp/common/protocol/Region';
import { SeedSelectOption } from '@cp/common/protocol/Seed';
import { assertTruthy } from '@cp/common/utils/Assert';
import {
  AddressChange,
  BillingConversionUiState
} from '@cp/web/app/admin/billing-conversion-dialog/billing-conversion-dialog.component';
import { FullyQualifiedEvent, FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';

@Component({
  selector: 'cp-change-billing-address',
  templateUrl: './change-billing-address.component.html',
  styleUrls: ['./change-billing-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Form component containing address information (billing address, shipping address).
 * This component doesn't save the input. Instead, it passes it back to its caller to handle that "external" logic.
 * See designs: https://www.figma.com/file/i00FDyul4FXB8thLi0Dqfl/Beta?node-id=4921%3A28834
 */
export class ChangeBillingAddressComponent implements OnInit {
  shippingAddressSameAsBilling!: boolean;
  readonly countries = COUNTRIES_SEED_SELECT;
  readonly states = STATES_SEED_SELECT;

  addressForm: FormGroup;

  // No ngOnChanges() in this component because we don't expect the inputs to change after init.
  // We only use formInput in ngOnInit().
  @Input()
  formInput?: BillingConversionUiState;

  @Input()
  isCancelable = true;

  @Input()
  customSubmitText?: string;

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  @Output()
  addressChange = new EventEmitter<AddressChange>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private focusMonitor: FocusMonitor,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef
  ) {
    // formInput is not available in the constructor. Creating this empty form in the meantime.
    this.addressForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    assertTruthy(this.eventPrefix);

    const inputAddress = this.formInput?.addressFormInput?.billingAddress;
    const initialBothAddressesSame = this.formInput?.addressFormInput?.bothAddressesSame ?? true;

    this.addressForm = this.formBuilder.group({
      billingLine1: [inputAddress?.line1 ?? '', [Validators.required]],
      billingLine2: [inputAddress?.line2 ?? ''],
      billingCity: [inputAddress?.city ?? '', [Validators.required]],
      billingPostalCode: [inputAddress?.postalCode ?? '', [Validators.required]],
      billingCountry: [inputAddress?.country ?? '', [Validators.required]],
      billingState: new FormControl(
        {
          value: inputAddress?.state ?? '',
          disabled: !inputAddress?.country
        },
        this.getStateValidatorsForCountry(inputAddress?.country)
      )
    });

    this.addressForm.controls['billingCountry'].valueChanges.subscribe((value) => {
      const validators = this.getStateValidatorsForCountry(value);
      this.addressForm.controls['billingState'].setValidators(validators);
      this.addressForm.controls['billingState'].setValue(null);
      this.addressForm.controls['billingState'].markAsUntouched();
      if (this.areThereStatesAvailableForThisCountry(value)) {
        this.addressForm.controls['billingState'].enable();
      } else {
        this.addressForm.controls['billingState'].disable();
      }
      this.cdr.markForCheck();
    });

    this.shippingAddressSameAsBilling = initialBothAddressesSame;
    this.enableOrDisableShippingAddress(!initialBothAddressesSame);
  }

  private getStateValidatorsForCountry(countryCode: string | undefined) {
    return this.areThereStatesAvailableForThisCountry(countryCode) ? [Validators.required] : [];
  }

  private areThereStatesAvailableForThisCountry(countryCode: string | undefined) {
    return this.getStatesForCountryCode(countryCode).length > 0;
  }

  private getStatesForCountryCode(countryCode: string | undefined) {
    return countryCode ? this.states[countryCode] ?? [] : [];
  }

  getStates(addressType: AddressType): DeepReadonlyArray<SeedSelectOption> {
    const countryCode = this.addressForm.controls[`${addressType}Country`].value;
    return this.getStatesForCountryCode(countryCode);
  }

  toggleShippingAddressSameAsBilling() {
    this.enableOrDisableShippingAddress(this.shippingAddressSameAsBilling);
  }

  private enableOrDisableShippingAddress(enable: boolean) {
    if (enable) {
      const inputAddress = this.formInput?.addressFormInput?.shippingAddress;

      this.addressForm.addControl(
        'shippingLine1',
        this.formBuilder.control(inputAddress?.line1 ?? '', [Validators.required])
      );
      this.addressForm.addControl('shippingLine2', this.formBuilder.control(inputAddress?.line2 ?? ''));
      this.addressForm.addControl(
        'shippingCity',
        this.formBuilder.control(inputAddress?.city ?? '', [Validators.required])
      );
      this.addressForm.addControl(
        'shippingPostalCode',
        this.formBuilder.control(inputAddress?.postalCode ?? '', [Validators.required])
      );
      this.addressForm.addControl(
        'shippingCountry',
        this.formBuilder.control(inputAddress?.country ?? '', [Validators.required])
      );
      //TODO: add validator that requires the state if it has available values.
      this.addressForm.addControl(
        'shippingState',
        new FormControl(
          {
            value: inputAddress?.state ?? '',
            disabled: !inputAddress?.country
          },
          this.getStateValidatorsForCountry(inputAddress?.country)
        )
      );

      // TODO: Do I need to pipe this to takeUntil since this control might be removed?
      this.addressForm.controls['shippingCountry'].valueChanges.subscribe((value) => {
        const validators = this.getStateValidatorsForCountry(value);
        this.addressForm.controls['shippingState'].setValidators(validators);
        this.addressForm.controls['shippingState'].setValue(null);
        this.addressForm.controls['shippingState'].markAsUntouched();
        if (this.areThereStatesAvailableForThisCountry(value)) {
          this.addressForm.controls['shippingState'].enable();
        } else {
          this.addressForm.controls['shippingState'].disable();
        }
        this.cdr.markForCheck();
      });
    } else {
      this.addressForm.removeControl('shippingLine1');
      this.addressForm.removeControl('shippingLine2');
      this.addressForm.removeControl('shippingCity');
      this.addressForm.removeControl('shippingPostalCode');
      this.addressForm.removeControl('shippingCountry');
      this.addressForm.removeControl('shippingState');
    }
    this.shippingAddressSameAsBilling = !enable;
  }

  async onSubmit(): Promise<void> {
    if (this.addressForm.invalid) {
      return;
    }

    const billingAddress: Address = {
      line1: this.addressForm.value.billingLine1,
      line2: this.addressForm.value.billingLine2,
      city: this.addressForm.value.billingCity,
      state: this.addressForm.value.billingState,
      country: this.addressForm.value.billingCountry,
      postalCode: this.addressForm.value.billingPostalCode
    };

    const shippingAddress: Address | undefined = this.shippingAddressSameAsBilling
      ? undefined
      : {
          line1: this.addressForm.value.shippingLine1,
          line2: this.addressForm.value.shippingLine2,
          city: this.addressForm.value.shippingCity,
          state: this.addressForm.value.shippingState,
          country: this.addressForm.value.shippingCountry,
          postalCode: this.addressForm.value.shippingPostalCode
        };

    this.addressChange.emit({
      billingAddress,
      bothAddressesSame: this.shippingAddressSameAsBilling,
      shippingAddress
    });
  }

  buildFullyQualifiedEvent(name: GalaxyEventType): FullyQualifiedEvent {
    return `${this.eventPrefix}.${name}`;
  }
}

type AddressType = 'billing' | 'shipping';
