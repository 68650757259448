import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { MAX_INSTANCE_NAME_LENGTH, MIN_INSTANCE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import {
  instanceNameValidator,
  maxLengthTrimmedValidator,
  minLengthTrimmedValidator
} from '@cp/web/app/common/utils/FormValidationUtils';
import { ChangeInstanceNameDialogUiService } from '@cp/web/app/instances/change-instance-name-dialog/change-instance-name-dialog-ui.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import { ChangeInstanceNameUiState } from '@cp/web/app/instances/protocol/InstanceStates';
import { Observable } from 'rxjs';

@Component({
  selector: 'cp-change-instance-name-dialog',
  templateUrl: './change-instance-name-dialog.component.html',
  styleUrls: ['./change-instance-name-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeInstanceNameDialogComponent {
  changeInstanceNameForm: FormGroup;
  uiStateObs: Observable<ChangeInstanceNameUiState>;

  constructor(
    public dialogRef: MatDialogRef<ChangeInstanceNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly instanceId: string,
    private readonly formBuilder: FormBuilder,
    private readonly uiStateService: ChangeInstanceNameDialogUiService,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceService: InstanceService,
    private readonly snackBar: MatSnackBar
  ) {
    this.uiStateObs = uiStateService.observeState();
    this.uiStateService.setPartialState({ buttonDisabled: false, errorMessage: undefined });
    const instance = instanceStateService.getInstanceOrFail(this.instanceId);
    this.changeInstanceNameForm = this.formBuilder.group({
      name: [
        instance.name,
        [
          Validators.required,
          minLengthTrimmedValidator(MIN_INSTANCE_NAME_LENGTH),
          maxLengthTrimmedValidator(MAX_INSTANCE_NAME_LENGTH),
          instanceNameValidator()
        ]
      ]
    });
  }

  async onSubmit(): Promise<void> {
    if (this.changeInstanceNameForm.invalid) {
      return;
    }
    this.uiStateService.setPartialState({ buttonDisabled: true, errorMessage: undefined });
    const name = this.changeInstanceNameForm.value.name;
    try {
      await this.instanceService.changeInstanceName(this.instanceId, name);
      this.snackBar.open('Service Name Changed', 'Dismiss', { duration: 5000 });
      this.dialogRef.close();
    } catch (e) {
      const serverErrorMessage = getServerErrorMessage(e);
      if (serverErrorMessage === 'INSTANCE_WITH_SAME_NAME_ALREADY_EXISTS') {
        this.uiStateService.setStateKey('errorMessage', 'Service with same name already exists, please choose another');
        return;
      }
      console.error(e);
      this.uiStateService.setStateKey('errorMessage', 'Unable to change service name');
    } finally {
      this.uiStateService.setStateKey('buttonDisabled', false);
    }
  }
}
