<div class="tos_container auth_page_container" data-cy="terms_of_service">
  <div class="container">
    <cw-auth-logo type="full" color="primary-dark"></cw-auth-logo>

    <div class="form_container terms_of_service_dialog_content_wrapper">
      <div class="terms_of_service_dialog_title_wrapper" data-test="disable-mfa-dialog-title">
        <h4 class="terms_of_service_dialog_title">Accept Terms of Service and Privacy Policy</h4>
      </div>
      <div>
        <p class="terms_of_service_dialog_description" data-test="terms-of-service-dialog-description">
          Please review and accept the following documents to finish creating your account.
        </p>
        <cp-sign-up-checkbox [defaultValue]="true" (toggle)="onToggle($event)"
        ></cp-sign-up-checkbox>

        <div class="terms_of_service_dialog_vertical_buttons">
          <button class="button clickui-primary-button"
                  galaxyClick="auth.register.googleAuthSelect"
                  data-cy="sign-up-button"
                  mat-flat-button
                  [disabled]="!hasAcceptedTos || processingAcceptTos"
                  (click)="onContinue()"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
