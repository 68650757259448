<div class="instance_side_details" data-cy="service-side-details">
  <div class="title">SUMMARY</div>

  <ng-container *ngIf="instance">
    <div class="attribute">
      <div class="attr_title">NAME</div>
      <div class="attr_value" data-cy="service-name">
        <div class='instance_name'>{{instance.name}}</div>
      </div>
    </div>
    <div class="attribute">
      <div class="attr_title">STATUS</div>
      <div class="attr_value" data-cy="service-status">
        <cp-instance-state [state]="instance.state" class="instance_state"></cp-instance-state>
      </div>
    </div>
  </ng-container>

  <!--  Once the user will be able to select different providers and plans, these need to be an input-->
  <div class="attribute">
    <div class="attr_title">PLAN</div>
    <div class="attr_value">Serverless</div>
  </div>

  <div *ngIf="instance" class="attribute">
    <div class="attr_title">PROVIDER</div>
    <div class="attr_value">
      <mat-icon [svgIcon]="instance.cloudProvider"
                class="icon"
                data-cy="cloud-provider-icon"></mat-icon>
    </div>
  </div>
</div>
