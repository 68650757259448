import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserFeatureId } from '@cp/common/protocol/features';
import {
  AllocatedMemoryMetricArgs,
  InsertQpsMetricArgs,
  ReadThroughputMetricArgs,
  ResidentMemoryUsageMetricArgs,
  RowCountMetricArgs,
  S3StorageUsageMetricArgs,
  SelectQpsMetricArgs,
  SqlStatementsPerTypeMetricArgs,
  SuccessfulQueriesMetricArgs,
  TimePeriod,
  WriteThroughputMetricArgs
} from '@cp/common/protocol/Metrics';
import { assertTruthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_MINUTE, MILLIS_PER_SECOND } from '@cp/common/utils/DateTimeUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { formatDataSize } from '@cp/web/app/common/pipes/data-size.pipe';
import { isTestRun } from '@cp/web/app/common/utils/AngularUtils';
import {
  ERROR_COLOR_FOR_METRICS,
  INFO_COLOR_FOR_METRICS,
  SUCCESS_COLOR_FOR_METRICS
} from '@cp/web/app/common/utils/ChartUtils';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceMetricsDashboardUiService } from '@cp/web/app/metrics/instance-metrics-dashboard-ui.service';
import { InstanceMetricsStateService } from '@cp/web/app/metrics/instance-metrics-state-service';
import { MetricChartInput } from '@cp/web/app/metrics/metric-chart/metric-chart.component';
import { MetricsFeedbackDialogComponent } from '@cp/web/app/metrics/metric-feedback-dialog/metrics-feedback-dialog.component';
import { ReplaySubject, switchMap, takeUntil, timer } from 'rxjs';

/** How often we auto-refresh metrics dashboard by default. */
const METRICS_REFRESH_INTERVAL_MILLIS = 2 * MILLIS_PER_MINUTE;

/** This value is only used when the code is run as a part of the Cypress tests: tests do not need to wait a long period. */
const METRICS_REFRESH_INTERVAL_IN_TESTS_MILLIS = 5 * MILLIS_PER_SECOND;

@Component({
  selector: 'cp-instance-metrics-dashboard',
  templateUrl: './instance-metrics-dashboard.component.html',
  styleUrls: ['./instance-metrics-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstanceMetricsDashboardComponent extends OnDestroyComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() instanceId!: string;

  readonly availableTimePeriods: Array<TimePeriod> = ['LAST_HOUR', 'LAST_DAY', 'LAST_WEEK', 'LAST_MONTH', 'LAST_YEAR'];

  timePeriod!: TimePeriod;

  /** Last time the metrics report was refreshed. Either explicitly by user or with auto-refresh. */
  lastMetricsRefreshTimeMillis = 0;

  /** Charts shown on the page. */
  metricsList!: Array<MetricChartInput>;

  readonly uiStateObs = new ReplaySubject<void>(1);

  dashboardUrl?: string;

  constructor(
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly instanceStateService: InstanceStateService,
    private readonly uiStateService: InstanceMetricsDashboardUiService,
    private readonly accountStateService: AccountStateService,
    private readonly metricsStateService: InstanceMetricsStateService
  ) {
    super();

    this.uiStateObs
      .pipe(
        switchMap(() => uiStateService.observeDashboardState(this.instanceId)),
        takeUntil(this.onDestroy)
      )
      .subscribe((uiState) => {
        this.timePeriod = uiState.timePeriod;
        const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
        const endpoint = instance.endpoints.https;
        this.dashboardUrl = `https://${endpoint.hostname}:${endpoint.port}/dashboard`;
        this.updateMetrics();
      });
  }

  ngOnInit(): void {
    assertTruthy(this.instanceId);
  }

  ngOnChanges(): void {
    assertTruthy(this.instanceId);
    this.uiStateObs.next();
  }

  ngAfterViewInit(): void {
    const autoRefreshPeriod = isTestRun() ? METRICS_REFRESH_INTERVAL_IN_TESTS_MILLIS : METRICS_REFRESH_INTERVAL_MILLIS;
    timer(autoRefreshPeriod, autoRefreshPeriod)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        // Using 'delta' here because timer() sometimes runs a little (10-30 millis) earlier of the expected time.
        const deltaMillis = 100;
        const nextAutoRefreshTime = this.lastMetricsRefreshTimeMillis + autoRefreshPeriod - deltaMillis;
        if (nextAutoRefreshTime > Date.now()) {
          // Too early. Refresh was recently triggered by user. Skip auto-refresh this time.
          return;
        }
        this.updateMetrics();
      });
  }

  /** Tracks by MetricsChartInput 'type' property helper for Angular *ngFor. */
  trackByMetricType(_: number, { query }: MetricChartInput): string {
    return query.type;
  }

  onTimePeriodChanged(newTimePeriod: TimePeriod): void {
    // Reset all cached metrics data for the instance for the new time period.
    this.metricsStateService.clearInstanceMetricsForTimePeriod(this.instanceId, newTimePeriod);
    this.uiStateService.setTimePeriod(this.instanceId, newTimePeriod);
  }

  openMetricsFeedbackDialog(): void {
    this.dialog.open(MetricsFeedbackDialogComponent);
  }

  private updateMetrics(): void {
    // Update of the 'metricsList' triggers a complete '<metric-chart-with-summary>' update
    // even if there are no changes in the 'metricsList' internal values.
    // and the chart will trigger 'fetch' request as the result of input update.
    this.metricsList = createMetricsChartInput(
      this.timePeriod,
      this.accountStateService.getUserDetailsOrFail().features
    );
    this.lastMetricsRefreshTimeMillis = Date.now();
    this.cdr.markForCheck();
  }
}

/** Returns list of metrics shown on the dashboard page. */
function createMetricsChartInput(timePeriod: TimePeriod, userFeatures: Array<UserFeatureId>): Array<MetricChartInput> {
  const binaryBytesFormatter = (value: number | string) => {
    if (typeof value === 'string') {
      return value;
    }
    return formatDataSize(value, 'binary-bytes-letter', 1);
  };
  const s3StorageUsageReportInput: MetricChartInput<S3StorageUsageMetricArgs> = {
    title: 'Data stored over time',
    helpTooltipText: 'Statistics about the storage used.',
    tooltipDataSizePointFormat: 'bytes-letter',
    query: { type: 'S3_STORAGE_USAGE', timePeriod }
  };
  const allocatedMemoryReportInput: MetricChartInput<AllocatedMemoryMetricArgs> = {
    title: 'Memory allocation over time',
    helpTooltipText: 'Total memory allocated for all nodes in the cluster.',
    tooltipDataSizePointFormat: 'binary-bytes-letter',
    yAxisValueFormatter: binaryBytesFormatter,
    query: { type: 'ALLOCATED_MEMORY', timePeriod }
  };
  const residentMemoryUsageReportInput: MetricChartInput<ResidentMemoryUsageMetricArgs> = {
    title: 'Memory usage over time',
    helpTooltipText: 'Total memory used by all nodes in the cluster.',
    tooltipDataSizePointFormat: 'binary-bytes-letter',
    yAxisValueFormatter: binaryBytesFormatter,
    query: { type: 'RESIDENT_MEMORY_USAGE', timePeriod }
  };
  const successfulQueriesReportInput: MetricChartInput<SuccessfulQueriesMetricArgs> = {
    title: 'SQL queries success over time',
    helpTooltipText: 'The distribution of successful vs failed queries.',
    query: { type: 'SUCCESSFUL_QUERIES', timePeriod },
    seriesOptions: [
      { name: 'Successful queries', color: SUCCESS_COLOR_FOR_METRICS },
      { name: 'Failed queries', color: ERROR_COLOR_FOR_METRICS }
    ]
  };
  const selectQpsReportInput: MetricChartInput<SelectQpsMetricArgs> = {
    title: 'Selects (queries per second)',
    helpTooltipText: 'The number of SELECT queries.\nThe graph shows query rate per second.',
    tooltipValueSuffix: '/s',
    query: { type: 'SELECT_QPS', timePeriod }
  };
  const readThroughputReportInput: MetricChartInput<ReadThroughputMetricArgs> = {
    title: 'Read throughput (per second)',
    helpTooltipText:
      'The amount of data read.\n' +
      'The graph shows read throughput per second.\n' +
      'Based on both user-generated and system-generated activity.\n' +
      'All data displayed is uncompressed.',
    tooltipDataSizePointFormat: 'bytes-letter',
    tooltipValueSuffix: '/s',
    query: { type: 'READ_THROUGHPUT', timePeriod }
  };
  const insertQpsReportInput: MetricChartInput<InsertQpsMetricArgs> = {
    title: 'Inserts (queries per second)',
    helpTooltipText: 'The number of INSERT queries.\nThe graph shows query rate per second.',
    tooltipValueSuffix: '/s',
    query: { type: 'INSERT_QPS', timePeriod }
  };
  const writeThroughputReportInput: MetricChartInput<WriteThroughputMetricArgs> = {
    title: 'Write throughput (per second)',
    helpTooltipText:
      'The amount of data written.\n' +
      'The graph shows write throughput per second.\n' +
      'Based on both user-generated and system-generated activity.\n' +
      'All data displayed is uncompressed.',
    tooltipDataSizePointFormat: 'bytes-letter',
    tooltipValueSuffix: '/s',
    query: { type: 'WRITE_THROUGHPUT', timePeriod }
  };
  const sqlStatementsPerTypeReportInput: MetricChartInput<SqlStatementsPerTypeMetricArgs> = {
    title: 'SQL statements over time',
    helpTooltipText: 'The total number of SELECT and INSERT queries over time.',
    query: { type: 'SQL_STATEMENTS_PER_TYPE', timePeriod },
    seriesOptions: [
      { name: 'Selects', color: SUCCESS_COLOR_FOR_METRICS, type: 'line' },
      { name: 'Inserts', color: INFO_COLOR_FOR_METRICS, type: 'line' }
    ]
  };
  const rowCountReportInput: MetricChartInput<RowCountMetricArgs> = {
    title: 'Number of rows inserted and selected',
    helpTooltipText: 'The total number of select and insert rows over time.',
    query: { type: 'ROW_COUNT', timePeriod },
    seriesOptions: [
      { name: 'Selected rows', color: SUCCESS_COLOR_FOR_METRICS, type: 'line' },
      { name: 'Inserted rows', color: INFO_COLOR_FOR_METRICS, type: 'line' }
    ]
  };

  // At HD (1920) resolution, we have 2 rows on the screen.
  // Different modes use different orders to keep related widgets close to each other.
  const showResidentMemory = userFeatures.includes('SHOW_RESIDENT_MEMORY_USAGE');
  if (showResidentMemory) {
    return [
      s3StorageUsageReportInput,
      successfulQueriesReportInput,
      allocatedMemoryReportInput,
      residentMemoryUsageReportInput,
      selectQpsReportInput,
      readThroughputReportInput,
      insertQpsReportInput,
      writeThroughputReportInput,
      sqlStatementsPerTypeReportInput,
      rowCountReportInput
      // + Metrics feedback widget.
    ];
  }
  return [
    s3StorageUsageReportInput,
    allocatedMemoryReportInput,
    selectQpsReportInput,
    readThroughputReportInput,
    insertQpsReportInput,
    writeThroughputReportInput,
    sqlStatementsPerTypeReportInput,
    successfulQueriesReportInput,
    rowCountReportInput // + Metrics feedback widget.
  ];
}
