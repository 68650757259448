<div class="database_access_mappings">
  <div *ngFor="let mapping of databaseAccessObs | async; trackBy: trackByRole"
       class="database_access_mapping">
    <mat-icon svgIcon="users"></mat-icon>
    <div class="database_access_mapping_role">
      <div class="database_access_mapping_name">
        {{mapping.displayName}}
      </div>
      <div class="database_access_mapping_user_count">
        {{formattedUserCount(mapping)}}
      </div>
    </div>
    <div class="database_access_mapping_access">
      <seed-select [options]="accessTypeOptions"
                   (selectionChange)="selectAccessType(mapping.cpRole, $event.value)"
                   [value]="mapping.databaseAccess">
      </seed-select>
    </div>
  </div>
</div>
