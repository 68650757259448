import { Injectable } from '@angular/core';
import { StrapiService } from '@cp/web/app/common/services/strapi.service';
import * as Sentry from '@sentry/angular';
import { WorkshopEventType } from './learn-page.protocol';

const STRAPI_EVENT_DATE_REGEX = /(\w+) ([\D\d]+), (\d+) at (\d+):(\d+)(\D+) (\D+)$/;

@Injectable({
  providedIn: 'root'
})
export class LearnPageService {
  constructor(private readonly strapiService: StrapiService) {}

  async loadUpcomingEvents(): Promise<Array<WorkshopEventType>> {
    try {
      const strapiObj = await this.strapiService.getStrapi().find('events', {
        filters: {
          localDatetime: {
            $gt: new Date().toISOString()
          },
          category: {
            $eqi: 'Free Training'
          }
        },
        sort: ['localDatetime:ASC'],
        populate: ['agenda', 'agenda.items', 'location'],
        pagination: { start: 0, limit: 3 }
      });

      const upcomingEvents: Array<WorkshopEventType> = this.strapiService.convertStrapiObject(strapiObj.data) as any[];
      return upcomingEvents.map((event) => {
        const matches: string[] | null = event.datetimeAndTimezoneString.match(STRAPI_EVENT_DATE_REGEX);
        if (matches) {
          const day = matches[2].includes(' and ') ? matches[2].split(' and ')[1] : matches[2];
          const parsedDate = Date.parse(
            `${day} ${matches[1]} ${matches[3]} ${matches[6] === 'am' ? matches[4] : parseInt(matches[4]) + 12}:${
              matches[5]
            } ${matches[7]}`
          );
          event.date = new Date(parsedDate);
        }
        return event;
      });
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }
}
