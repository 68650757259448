<mat-dialog-content data-cy="delete-ip-access-list-entry-dialog">
  <h4>Delete entry</h4>
  <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>

  <div class="question" data-cy="question">
    Are you sure you want to delete the following IP address?
  </div>

  <div class="filter_value" data-cy="filter-value">
    {{data.ipAccessListEntry.source}}
  </div>

  <div class="dialog_buttons_row">
    <button (click)="delete()"
            color="warn"
            mat-flat-button
            data-cy="delete-button"
            [disabled]="isSubmitInProgress"
    >
      <mat-icon *ngIf="isSubmitInProgress" class="button_loader_icon icon svg_stroke" svgIcon="loader"></mat-icon>
      Delete entry
    </button>

    <a (click)="dialogRef.close()" class="cancel_button" data-cy="cancel-button">Cancel</a>

  </div>
</mat-dialog-content>
