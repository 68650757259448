import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GalaxyEventType } from '@cp/common/protocol/Galaxy';
import { Instance, InstanceState } from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { FullyQualifiedEvent, FullyQualifiedEventPrefix } from '@cp/web/app/common/services/galaxy.service';
import { SegmentService } from '@cp/web/app/common/services/segment.service';
import { getConsoleDisabledTooltip, getConsoleUrl } from '@cp/web/app/common/utils/WebConsoleUtils';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';

@Component({
  selector: 'cp-connect-console-button',
  templateUrl: './connect-console-button.component.html',
  styleUrls: ['./connect-console-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectConsoleButtonComponent implements OnInit {
  @Input()
  instance!: Instance;

  @Input()
  eventPrefix!: FullyQualifiedEventPrefix;

  @Input()
  color!: 'white' | 'primary';

  constructor(
    private readonly segmentService: SegmentService,
    private readonly instanceUiService: InstanceUiService
  ) {}

  ngOnInit() {
    assertTruthy(this.eventPrefix);
  }

  getWebConsoleUri(instance: Instance): string {
    return getConsoleUrl(instance);
  }

  reportSqlConsoleClicked(): void {
    this.segmentService.trackGaEvent({
      event: 'click',
      label: 'sql console',
      category: 'cloud ui service',
      component: 'button',
      view: 'serviceList'
    });
  }

  getDisabledTooltip(state: InstanceState) {
    return getConsoleDisabledTooltip(state);
  }

  showConnectDialog(): void {
    this.instanceUiService.showConnectDialog(this.instance.id, true);
  }

  buildFullyQualifiedEvent(eventName: GalaxyEventType): FullyQualifiedEvent {
    return `${this.eventPrefix}.${eventName}`;
  }
}
