<ng-container *ngIf="instanceObs | async as instance">
  <ng-container *ngIf="endpointsObs | async as endpoints">
    <div *ngIf="password && username">
      <div class="paragraph_text">We created credentials to help you securely log into ClickHouse.
      </div>
      <div class="paragraph_text make_sure_to_copy">Make sure to copy your password - We'll only show it to you
        during this session!
      </div>
      <cp-username-and-password
        onUserNameCopyEvent="auth.onboarding3.usernameCopy"
        onPasswordCopyEvent="auth.onboarding3.passwordCopy"
        onDownLoadCredentialsEvent="auth.onboarding3.credentialDownload"
        [password]="password"
        [username]="username">
      </cp-username-and-password>
    </div>
    <div *ngIf="!password && username" class="paragraph_text keep_your_data_private">
      We created credentials to help you securely log into ClickHouse.
      If you do not know the <span class="monospace">{{username}}</span> user's credentials,
      <ng-container *ngIf="myOrgRoleObs | async as myRole">
        <ng-container *ngIf="myRole === 'ADMIN'">
          you can <a class="reset_password_link" (click)="showInstanceResetPasswordDialog(instance)"
                     data-cy="reset-password-link"
        >reset your password</a>.
        </ng-container>
        <ng-container *ngIf="myRole !== 'ADMIN'">
          please contact an administrator to reset it.
        </ng-container>
      </ng-container>
    </div>
    <div class="paragraph_text use_your_connection_string">
      You can use your connection string to connect to your new service. See our docs for more details around
      connecting <a href="https://clickhouse.com/docs/en/interfaces/cli" target="_blank">natively</a> or using <a
      href="https://clickhouse.com/docs/en/interfaces/http" target="_blank">HTTPS</a>.
    </div>

    <mat-tab-group (selectedTabChange)="protocolClicked($event.tab.textLabel)"
                   [disableRipple]="true"
                   class="endpoint_tabs"
                   data-cy="endpoint-tabs"
    >
      <mat-tab *ngFor="let endpointProtocol of getEndpointProtocolsInTabOrder(endpoints)"
               [label]="getEndpointProtocolName(endpointProtocol)"
               [ngSwitch]="endpointProtocol">
        <cp-native-endpoint-details *ngSwitchCase="'nativesecure'"
                                    [instanceEndpoint]="endpoints[endpointProtocol]"
                                    [password]="password"
                                    [username]="instance.dbUsername"></cp-native-endpoint-details>
        <cp-https-endpoint-details *ngSwitchCase="'https'"
                                   [instanceEndpoint]="endpoints[endpointProtocol]"
                                   [password]="password"
                                   [username]="instance.dbUsername"></cp-https-endpoint-details>
        <ng-container *ngSwitchCase="'mysql'">
          <ng-container *ngIf="(mysqlSettingsObs | async) as mysqlSettings">
            <cp-mysql-endpoint-details *ngIf="mysqlSettings !== undefined && mysqlSettings !== null"
                                      [instanceId]="instanceId"
                                      [mysqlSettings]="mysqlSettings"
                                      [instanceEndpoint]="endpoints.nativesecure"
                                      [password]="password"
                                      [username]="instance.dbUsername"></cp-mysql-endpoint-details>
          </ng-container>
          <div class="mysql-loading-container" *ngIf="(mysqlSettingsObs | async) === undefined || (mysqlSettingsObs | async) === null">
            <mat-spinner class="mysql-spinner" diameter="24"></mat-spinner> Loading MySQL Settings...
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>
