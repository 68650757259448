import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cp-region-block-message',
  templateUrl: './region-block-message.component.html',
  styleUrls: ['./region-block-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionBlockMessageComponent {
  @Input()
  showContinueButton = false;

  @Output()
  continueClick = new EventEmitter<void>();
}
