import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { isDefined } from '@cp/common/protocol/Common';
import {
  ActiveMaintenanceKind,
  Instance,
  INSTANCE_TIERS_THAT_ARE_DEDICATED,
  INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED,
  InstanceMaintenanceWindow,
  STATES_VALID_TO_START,
  STATES_VALID_TO_STOP,
  STATES_VALID_TO_TERMINATE
} from '@cp/common/protocol/Instance';
import { IntegrationCategory } from '@cp/common/protocol/Integration';
import { TimePeriod } from '@cp/common/protocol/Metrics';
import { OrganizationBillingStatus, OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { MILLIS_PER_MINUTE } from '@cp/common/utils/DateTimeUtils';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import {
  getInstancePageBackupTabPath,
  getInstancePageConnectionTabPath,
  getInstancePageNetworkSettingsPath,
  getInstancePageSettingsTabPath,
  getServiceDetailsPageSummaryTabPath,
  getTimePeriodFromRouteSnapshot
} from '@cp/web/app/app-routing-utils';
import { FeaturesService } from '@cp/web/app/common/services/features.service';
import { GalaxyService } from '@cp/web/app/common/services/galaxy.service';
import { PageService } from '@cp/web/app/common/services/page.service';
import { isMaintenanceWindowNotificationShown } from '@cp/web/app/common/utils/MaintenanceWindowUtils';
import { getConsoleUrl } from '@cp/web/app/common/utils/WebConsoleUtils';
import { InstancePageUiService } from '@cp/web/app/instances/instance-page/instance-page-ui.service';
import {
  InstancePageFragment,
  InstancePageTab,
  InstancePageUiState
} from '@cp/web/app/instances/instance-page/instance-state';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceUiService } from '@cp/web/app/instances/instance-ui.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import {
  MaintenanceWindowInfoDialogComponent,
  MaintenanceWindowInfoMetadata
} from '@cp/web/app/instances/maintenance-window-info-dialog/maintenance-window-info-dialog.component';
import { TrialServiceLimitDialogComponent } from '@cp/web/app/instances/trial-service-limit-dialog/trial-service-limit-dialog.component';
import { InstanceMetricsDashboardUiService } from '@cp/web/app/metrics/instance-metrics-dashboard-ui.service';
import { NonpaymentDialogComponent } from '@cp/web/app/organizations/nonpayment-dialog/nonpayment-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { distinctUntilChanged, Observable, switchMap, take, takeUntil, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

/** An instance (service) page component. Uses instance id provided by the router. */
@Component({
  selector: 'cp-instance-page',
  templateUrl: './instance-page.component.html',
  styleUrls: ['./instance-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstancePageComponent extends OnDestroyComponent implements OnInit, AfterViewInit, OnDestroy {
  instanceId!: string;
  instance!: Instance;

  instanceObs!: Observable<Instance | undefined>;
  readonly STATES_VALID_TO_START = STATES_VALID_TO_START;
  //For UI purposes, we don't want to display the `stop` action when the instance is already stopped.
  readonly STATES_VALID_TO_STOP = STATES_VALID_TO_STOP.filter((state) => state != 'stopped');
  readonly STATES_VALID_TO_TERMINATE = STATES_VALID_TO_TERMINATE;
  readonly instancePageUiStateObs: Observable<InstancePageUiState>;
  readonly myOrgRoleObs: Observable<OrganizationRole>;
  readonly myOrgBillingStatusObs: Observable<OrganizationBillingStatus>;
  private canStartService = false;
  canAutoScaleService = false;
  isDevService = false;
  isDedicated = false;
  /**
   * 'true' - instance has user data.
   * 'false' - instance has no user data.
   * 'undefined' - unknown. Need to refresh 'instance.hasUserDataFlag'.
   */
  instanceHasUserData?: boolean;

  /** Time period selected on the metrics dashboard. Affects URL. */
  private timePeriodOnMetricsDashboard!: TimePeriod;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly instanceStateService: InstanceStateService,
    private readonly instancePageUiService: InstancePageUiService,
    private readonly instanceMetricsDashboardUiService: InstanceMetricsDashboardUiService,
    private readonly instanceUiService: InstanceUiService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly instanceService: InstanceService,
    private readonly featuresService: FeaturesService,
    private readonly pageService: PageService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar,
    private readonly galaxyService: GalaxyService
  ) {
    super();
    this.instancePageUiStateObs = instancePageUiService.observeState();
    this.myOrgRoleObs = organizationStateService.observeCurrentOrganizationRole();

    this.myOrgBillingStatusObs = organizationStateService.observeCurrentOrganizationId().pipe(
      filter(isDefined),
      switchMap((id) => organizationStateService.observeBillingStatus(id))
    );

    organizationStateService
      .observeCurrentOrganizationId()
      .pipe(
        filter(isDefined),
        switchMap((id) => organizationStateService.observeRestrictions(id)),
        takeUntil(this.onDestroy),
        distinctUntilChanged((prev, curr) => prev.canStartInstances === curr.canStartInstances)
      )
      .subscribe((restrictions) => {
        this.canStartService = restrictions.canStartInstances;
      });

    organizationStateService
      .observeCurrentOrganizationId()
      .pipe(
        filter(isDefined),
        switchMap((id) => organizationStateService.observeBillingStatus(id)),
        takeUntil(this.onDestroy)
      )
      .subscribe((billingStatus) => {
        if (billingStatus === 'IN_NONPAYMENT_GRACE_PERIOD') {
          // Show nonpayment dialog.
          NonpaymentDialogComponent.show(this.dialog);
        }
      });
  }

  ngOnInit(): void {
    const instanceIdParam = this.route.snapshot.paramMap.get('instanceId');
    if (!instanceIdParam) {
      this.router.navigate(['services']).then();
      return;
    }
    this.instanceId = instanceIdParam;
    const isOnBackupTab = this.router.url === getInstancePageBackupTabPath(this.instanceId);
    let activeTab: InstancePageTab | undefined;
    this.instancePageUiService.setPartialState({ activeTab: undefined });
    let fragment: InstancePageFragment = '';
    if (isOnBackupTab) {
      activeTab = 'BACKUP_TAB';
    } else if (this.router.url === getInstancePageSettingsTabPath(this.instanceId)) {
      activeTab = 'SETTINGS_TAB';
    } else if (this.router.url === getInstancePageNetworkSettingsPath(this.instanceId)) {
      activeTab = 'SETTINGS_TAB';
      fragment = 'Networking';
    } else if (this.router.url === getInstancePageConnectionTabPath(this.instanceId)) {
      activeTab = 'CONNECTION_TAB';
    }
    if (activeTab) {
      this.instancePageUiService.setPartialState({ activeTab, fragment });
    }

    this.instanceObs = this.instanceStateService.observeInstance(this.instanceId);
    // Return to the 'services' page when service is not found anymore.
    this.instanceObs.pipe(takeUntil(this.onDestroy)).subscribe(async (instance) => {
      if (!instance || instance.state === 'failed') {
        this.router.navigate(['services']).then();
        return;
      }
      this.instance = instance;

      this.canAutoScaleService = INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(instance.instanceTier);
      this.isDevService = instance.instanceTier === 'Development';
      this.isDedicated = INSTANCE_TIERS_THAT_ARE_DEDICATED.has(this.instance.instanceTier);
      if (instance.hasUserData && !this.instanceHasUserData) {
        this.instanceHasUserData = true;
        this.instancePageUiService.setPartialState({ activeTab: activeTab || 'METRICS_TAB' });
        this.cdr.markForCheck();
        return;
      }
    });

    const timePeriod = getTimePeriodFromRouteSnapshot(this.route.snapshot);
    if (timePeriod && !isOnBackupTab) {
      this.instanceMetricsDashboardUiService.setTimePeriod(this.instanceId, timePeriod);
    }

    // Update title and URL after 'instance' is available on every time period change.
    this.instanceObs
      .pipe(
        takeUntil(this.onDestroy),
        filter(isDefined),
        switchMap(() => this.instanceMetricsDashboardUiService.observeTimePeriod(this.instanceId)),
        takeUntil(this.onDestroy)
      )
      .subscribe((timePeriod) => {
        this.timePeriodOnMetricsDashboard = timePeriod;
        this.updateTitleAndUrl();
      });
  }

  override ngOnDestroy() {
    this.instancePageUiService.setPartialState({ activeTab: undefined });
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (!this.instanceHasUserData) {
      // Check if the instance got any data while the page is opened.
      const refreshUserDataFlagTimer = timer(0, MILLIS_PER_MINUTE)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(async () => {
          this.instanceHasUserData =
            this.instanceHasUserData ||
            this.instance?.hasUserData ||
            (await this.instanceService.refreshUserDataFlag(this.instanceId));
          if (this.instanceHasUserData) {
            refreshUserDataFlagTimer.unsubscribe();
          }
          const isActiveTabSet = this.instancePageUiService.getStateForKey('activeTab');
          if (!isActiveTabSet) {
            // Set active tab based on hasUserData.
            const tab = this.instanceHasUserData ? 'METRICS_TAB' : 'CONNECTION_TAB';
            this.instancePageUiService.setPartialState({ activeTab: tab });
            this.updateTitleAndUrl();
          }
          this.cdr.markForCheck();
        });
    }
  }

  onTabChange({ index }: { index: number }): void {
    let activeTab: InstancePageTab = 'METRICS_TAB';
    let event: 'navSummarySelect' | 'navBackupsSelect' | 'navSecuritySelect' | 'navConnectionSelect' =
      'navSummarySelect';
    switch (index) {
      case 1:
        event = 'navBackupsSelect';
        activeTab = 'BACKUP_TAB';
        break;
      case 2:
        event = 'navConnectionSelect';
        activeTab = 'CONNECTION_TAB';
        break;
      case 3:
        event = 'navSecuritySelect';
        activeTab = 'SETTINGS_TAB';
        break;
    }
    this.galaxyService.track({
      event: `servicePage.header.${event}`,
      interaction: 'click',
      properties: { serviceId: this.instanceId }
    });
    this.instancePageUiService.setPartialState({ activeTab, fragment: '' });
    this.updateTitleAndUrl();
  }

  getSelectedTabIndex(uiState: InstancePageUiState): number {
    switch (uiState.activeTab) {
      case 'BACKUP_TAB':
        return 1;
      case 'CONNECTION_TAB':
        return 2;
      case 'SETTINGS_TAB':
        return 3;
    }
    return 0;
  }

  async startInstance(orgBillingStatus: OrganizationBillingStatus): Promise<void> {
    if (!this.canStartService) {
      TrialServiceLimitDialogComponent.showStartServiceRestricted(this.dialog, this.snackBar, orgBillingStatus);
    } else {
      await this.instanceService.startInstance(this.instanceId);
    }
  }

  showDeleteInstanceDialog(instance: Instance): void {
    this.instanceUiService.showDeleteInstanceDialog(instance);
  }

  confirmStopInstance(instance: Instance): void {
    this.instanceUiService
      .showConfirmStopInstance(instance)
      .afterClosed()
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(async (result) => {
        if (result) {
          await this.instanceService.stopInstance(instance.id);
        }
      });
  }

  get consoleImportsUrl(): string {
    return `${getConsoleUrl(this.instance)}/imports`;
  }

  goToDataVisualizations(): void {
    const categoryFilter: IntegrationCategory = 'DATA_VISUALIZATION';
    this.router.navigate(['integrations'], { state: { categoryFilter } }).then();
  }

  /** Updates current page title & location 'pathname' based on the current page state: the tab activated and the time period selected. */
  private updateTitleAndUrl(): void {
    const activeTab: InstancePageTab = this.instancePageUiService.getStateForKey('activeTab') || 'METRICS_TAB';
    const fragment: InstancePageFragment = this.instancePageUiService.getStateForKey('fragment') || '';
    const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
    let title: string;
    let path: string;
    switch (activeTab) {
      case 'METRICS_TAB':
        title = `Metrics for ${instance.name}`;
        path = getServiceDetailsPageSummaryTabPath(this.instanceId, this.timePeriodOnMetricsDashboard);
        break;
      case 'BACKUP_TAB':
        title = `Backups for ${instance.name}`;
        path = getInstancePageBackupTabPath(this.instanceId);
        break;
      case 'SETTINGS_TAB':
        title = `Settings for ${instance.name}`;
        path =
          fragment === 'Networking'
            ? getInstancePageNetworkSettingsPath(this.instanceId)
            : getInstancePageSettingsTabPath(this.instanceId);
        break;
      case 'CONNECTION_TAB':
        title = `Connection for ${instance.name}`;
        path = getInstancePageConnectionTabPath(this.instanceId);
        break;
    }
    window.history.replaceState(null, '', path);
    this.pageService.setTitle(title);
  }

  get maintenanceWindow(): InstanceMaintenanceWindow | undefined {
    for (const w of this.instance.maintenanceWindows) {
      if (isMaintenanceWindowNotificationShown(w, !!this.instance.activeMaintenanceKind)) {
        return w;
      }
    }
    return undefined;
  }

  get activeMaintenanceKind(): ActiveMaintenanceKind | undefined {
    const instance = this.instanceStateService.getInstanceOrFail(this.instanceId);
    return instance.activeMaintenanceKind;
  }

  get isFullMaintenance(): boolean {
    return this.activeMaintenanceKind === 'fullMaintenance';
  }

  openMaintenanceWindowDialog($event: MouseEvent): void {
    $event.preventDefault();
    assertTruthy(this.maintenanceWindow);
    const maintenanceWindow: MaintenanceWindowInfoMetadata = {
      ...this.maintenanceWindow,
      instanceName: this.instance.name
    };
    MaintenanceWindowInfoDialogComponent.show(this.dialog, [maintenanceWindow]);
  }
}
