import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsInteraction } from '@cp/common/protocol/Galaxy';
import {
  GalaxyService,
  GalaxyTrackerData,
  GalaxyTrackerEventObj,
  getTracker
} from '@cp/web/app/common/services/galaxy.service';

@Directive({
  selector: '[galaxyClick]'
})
export class GalaxyTrackClickDirective {
  @Input('galaxyClick') data!: GalaxyTrackerData;

  private track!: (interaction: AnalyticsInteraction, data: GalaxyTrackerEventObj) => Promise<void>;

  constructor(private readonly galaxyService: GalaxyService) {
    this.track = getTracker(this.galaxyService);
  }

  getEvent() {
    return typeof this.data === 'string' ? this.data : this.data[0];
  }

  getProperties() {
    return (typeof this.data === 'string' ? {} : this.data[1]) || {};
  }

  getDataObj() {
    return {
      event: this.getEvent(),
      properties: this.getProperties()
    };
  }

  @HostListener('click')
  onClick() {
    if (!this.isFocusEvent()) {
      this.track('click', this.getDataObj());
    }
  }

  isFocusEvent() {
    return this.fullyQualifiedEvent().endsWith('Focus');
  }

  fullyQualifiedEvent() {
    return typeof this.data === 'string' ? this.data : this.data[0];
  }

  @HostListener('focus')
  onFocus() {
    if (this.isFocusEvent()) {
      this.track('click', this.getDataObj());
    }
  }
}
