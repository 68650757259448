import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { disableAutoSigninOnAppInitCognito } from '@cp/common-services/web-auth/cognito-web-auth-provider';

import { AppModule } from '@cp/web/app/app.module';
import { disableAutoSigninOnAppInitAuth0 } from '@cp/web/app/auth/auth0-web-auth-provider';
import { isAutoSigninEnabledOnAppInit } from '@cp/web/app/auth/CpWebAuthUtils';
import { isAuth0Mode } from '@cp/web/app/auth0-utils';
import { environment } from '@cp/web/environments/environment';
import * as Sentry from '@sentry/angular';

Sentry.init(environment.sentryConfig);

if (environment.stage === 'production') {
  enableProdMode();
}

// Disable auto-signin before auth services are initialized and triggered auto-signin.
if (!isAutoSigninEnabledOnAppInit()) {
  if (isAuth0Mode) {
    disableAutoSigninOnAppInitAuth0();
  } else {
    disableAutoSigninOnAppInitCognito();
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
