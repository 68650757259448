<div class="marketplace-container side_padding">
  <div class="inner_page_nav">
    <div class="inner_page_title" data-cy="page-title">Integrations</div>
    <input type="text"
           spellcheck="false"
           class="seed_input search_field_input"
           placeholder="Search Integrations"
           (input)="searchInputChanged()"
           [(ngModel)]="searchText"
    />
    <ng-container *ngTemplateOutlet="requestIntegrationButton"></ng-container>
  </div>

  <p class="inner_page_subtitle">
    If there’s an integration that you would like to see added to this list,
    <a (click)="showRequestIntegrationDialog()">please let us know</a>.
  </p>

  <div class="inner_page_body">
    <div class="category_filters">
      <h4>Categories</h4>
      <button [class.selected]="currentCategories.length > 1"
              mat-button
              (click)="filterCategories('ALL')"
      >
        View all
      </button>
      <button *ngFor="let cat of categories"
              [class.selected]="currentCategories.length === 1 && currentCategories[0] === cat"
              mat-button
              [attr.data-cy]="'integration-category-filter-' + cat"
              (click)="filterCategories(cat)"
      >
        {{getCategoryName(cat)}}
      </button>
    </div>

    <div class="integrations_table" *ngVar="searchTextSubject | async as filterText">
      <ng-container *ngIf="filterText; else categorizedIntegrationsTemplate">
        <div class="integrations_list">
          <ng-container *ngFor="let int of searchedIntegrations">
            <ng-container *ngTemplateOutlet="integrationCardTemplate; context: {$implicit: int}"></ng-container>
          </ng-container>
        </div>
        <div *ngIf="searchedIntegrations.length === 0" class="no_integrations_found_message_container">
          <div class="title">"{{filterText}}" not found</div>
          <div class="text">
            We didn't find any integrations matching your search term. Please try
            another keyword or browse using the categories on the left.
          </div>
          <ng-container *ngTemplateOutlet="requestIntegrationButton; context: {$implicit: filterText}"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #categorizedIntegrationsTemplate>
  <ng-container *ngFor="let cat of currentCategories">
    <h4 [attr.data-cy]="'integration-category-title-' + cat">{{getCategoryName(cat)}}</h4>
    <div class="integrations_list">
      <ng-container *ngFor="let int of integrationsPerCategory[cat]">
        <ng-container *ngTemplateOutlet="integrationCardTemplate; context: {$implicit: int}"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #integrationCardTemplate let-int>
  <cp-integration-card class="integration_card"
                       [integration]="int"
                       [attr.data-cy]="'integration-card-' + int.name"
  ></cp-integration-card>
</ng-template>

<ng-template #requestIntegrationButton let-integrationName>
  <button (click)="showRequestIntegrationDialog(integrationName)"
          class="request_button"
          color="primary"
          data-cy="request-button"
          mat-flat-button
  >
    Request Integration
  </button>
</ng-template>
