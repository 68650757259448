<div class="authenticator_app_dialog_wrapper">
  <div class="row">
    <div class="first_col">
      <h4>1.</h4>
    </div>
    <div class="second_col">
      <h4 data-test="first-step-title">Scan the QR code</h4>
      <p class="description" data-test="first-step-description">Generate one time security codes from an app like Authy,
        1Password, or
        Google
        Authenticator.</p>
    </div>
  </div>
  <div class="pairing_section_wrapper">
    <div *ngIf="qrCode; else qrCodeLoading" class="qrcode_wrapper">
      <qrcode [qrdata]="qrCode" [width]="240" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
    </div>
    <ng-template #qrCodeLoading>
      <div class="qrcode_loading_indicator_wrapper">
        <mat-icon svgIcon="loader" class="qrcode_loading_indicator"></mat-icon>
      </div>
    </ng-template>
    <div class="manual_pairing_code_wrapper">
      <div *ngIf="isEnterCodeManually; else enterWithQrCode" class="manual_pairing_code_container">
        <button class="warning_text_button" (click)="onClickEnterCodeManually()" data-test="manual-pairing-option">
          Hide code
        </button>
        <div class="manual_paring_code_element" data-test="manual-pairing-option">
          <p data-test="manual-pairing-code">{{code}}</p>
        </div>
      </div>
      <ng-template #enterWithQrCode>
        <div>
          <button class="warning_text_button" (click)="onClickEnterCodeManually()" data-test="qr-code-pairing-option">
            Enter code manually?
          </button>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="row">
    <div class="first_col">
      <h4>2.</h4>
    </div>
    <div class="second_col">
      <h4 data-test="second-step-title">Enter the 6 digit code</h4>
      <p class="description" data-test="second-step-description">Once you have connected your app, enter your most
        recent 6 digit
        verification code for ClickHouse.</p>

      <div class="input_token_wrapper">
        <label class="input_token_label" data-test="input-token-label">6 digit code</label>
        <input #tokenValue maxlength="6"
               (keyup.enter)="onTokenConfirmation()"
               (input)="onTokenUpdate(tokenValue.value)" [value]="token"
               class="seed_input input_token_element"
               data-test="input-token"/>
      </div>
    </div>
  </div>
</div>
