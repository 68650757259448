import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trackById } from '@cp/common-services/trackById';
import { isDefined } from '@cp/common/protocol/Common';
import { checkInstanceIsUpgradingFromStatus, Instance } from '@cp/common/protocol/Instance';
import { OrganizationBillingStatus, OrganizationRole } from '@cp/common/protocol/Organization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { OnDestroyComponent } from '@cp/cp-common-web/on-destroy';
import { AccountStateService } from '@cp/web/app/account/account-state.service';
import { isMaintenanceWindowNotificationShown } from '@cp/web/app/common/utils/MaintenanceWindowUtils';
import { CreateInstanceDialogComponent } from '@cp/web/app/instances/create-instance-dialog/create-instance-dialog.component';
import { InstanceBackupStateService } from '@cp/web/app/instances/instance-backups/instance-backup-state.service';
import { InstanceBackupService } from '@cp/web/app/instances/instance-backups/instance-backup.service';
import { InstanceStateService } from '@cp/web/app/instances/instance-state.service';
import { InstanceService } from '@cp/web/app/instances/instance.service';
import {
  MaintenanceWindowInfoDialogComponent,
  MaintenanceWindowInfoMetadata
} from '@cp/web/app/instances/maintenance-window-info-dialog/maintenance-window-info-dialog.component';
import { TrialServiceLimitDialogComponent } from '@cp/web/app/instances/trial-service-limit-dialog/trial-service-limit-dialog.component';
import { NonpaymentDialogComponent } from '@cp/web/app/organizations/nonpayment-dialog/nonpayment-dialog.component';
import { OrganizationStateService } from '@cp/web/app/organizations/organization-state.service';
import { TrialExpiredDialogComponent } from '@cp/web/app/organizations/trial-expired-dialog/trial-expired-dialog.component';
import { distinctUntilChanged, filter, Observable, switchMap, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cp-instances',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstancesComponent extends OnDestroyComponent {
  readonly instancesObs: Observable<Array<Instance>>;
  readonly upcomingMaintenanceWindowsObs: Observable<Array<MaintenanceWindowInfoMetadata>>;
  private upcomingMaintenanceWindows: Array<MaintenanceWindowInfoMetadata> = [];
  readonly myOrgRoleObs: Observable<OrganizationRole>;
  private canCreateNewService = false;
  orgBillingStatus?: OrganizationBillingStatus;

  readonly trackById = trackById;
  readonly regionBlockedObs: Observable<boolean | undefined>;

  readonly isInstanceUpgradingFromStatus = checkInstanceIsUpgradingFromStatus;

  constructor(
    private readonly accountStateService: AccountStateService,
    private readonly instanceService: InstanceService,
    private readonly dialog: MatDialog,
    private readonly instanceStateService: InstanceStateService,
    private readonly instanceBackupService: InstanceBackupService,
    private readonly instanceBackupStateService: InstanceBackupStateService,
    private readonly organizationStateService: OrganizationStateService,
    private readonly snackBar: MatSnackBar
  ) {
    super();
    this.instancesObs = instanceStateService
      .observeInstances()
      .pipe(map((instancesMap) => Object.values(instancesMap).sort((a, b) => b.creationDate - a.creationDate)));

    this.upcomingMaintenanceWindowsObs = this.instancesObs.pipe(
      map((instances) => {
        const windows: Array<MaintenanceWindowInfoMetadata> = [];
        for (const instance of instances) {
          for (const maintenanceWindow of instance.maintenanceWindows) {
            // Assuming maintenanceWindows to be sorted by start time.
            if (isMaintenanceWindowNotificationShown(maintenanceWindow, !!instance.activeMaintenanceKind)) {
              // Found a maintenance window that haven't ended yet and begins in the next notificationMinutes.
              windows.push({ ...maintenanceWindow, instanceName: instance.name });
              break;
            }
          }
        }
        // We expect all instances maintenance windows to be the same.
        assertTruthy(
          new Set(windows.map((w) => w.maintenanceName)).size <= 1,
          'Found more than 1 maintenance window that is in progress or about to start.'
        );
        return windows;
      }),
      tap((windows) => {
        this.upcomingMaintenanceWindows = windows;
      })
    );

    this.myOrgRoleObs = organizationStateService.observeCurrentOrganizationRole();
    this.regionBlockedObs = this.accountStateService
      .observeUserDetails()
      .pipe(
        filter(Boolean),
        map((userDetails) => userDetails.regionBlocked)
      )
      .pipe(distinctUntilChanged());

    organizationStateService
      .observeCurrentOrganizationId()
      .pipe(
        filter(isDefined),
        switchMap((id) => organizationStateService.observeNewServiceRestriction(id)),
        takeUntil(this.onDestroy)
      )
      .subscribe((canCreateNewService) => {
        this.canCreateNewService = canCreateNewService;
      });
    organizationStateService
      .observeCurrentOrganizationId()
      .pipe(
        filter(isDefined),
        switchMap((id) => organizationStateService.observeBillingStatus(id)),
        takeUntil(this.onDestroy)
      )
      .subscribe((billingStatus) => {
        this.orgBillingStatus = billingStatus;
        if (billingStatus === 'IN_TRIAL_GRACE_PERIOD' || billingStatus === 'DECOMMISSIONED') {
          // Show expired trial dialog.
          TrialExpiredDialogComponent.show(this.dialog);
        }
        if (billingStatus === 'IN_NONPAYMENT_GRACE_PERIOD') {
          // Show nonpayment dialog.
          NonpaymentDialogComponent.show(this.dialog);
        }
      });
  }

  get isInTrial(): boolean {
    return (
      this.orgBillingStatus === 'IN_TRIAL_GRACE_PERIOD' ||
      this.orgBillingStatus === 'DECOMMISSIONED' ||
      this.orgBillingStatus === 'IN_TRIAL'
    );
  }

  showCreateInstanceDialog(): void {
    if (!this.canCreateNewService) {
      if (this.isInTrial) {
        // Show trial service limit dialog.
        TrialServiceLimitDialogComponent.show(this.dialog);
      } else {
        this.snackBar.open(
          `Can't create a new service at the moment, probably because you maxed-out the number of allowed services. Please contact support for help.`,
          'Dismiss',
          { duration: 10000 }
        );
      }
      return;
    }
    this.dialog.open(CreateInstanceDialogComponent, {
      width: '100%',
      maxWidth: '840px',
      restoreFocus: false,
      autoFocus: false,
      panelClass: 'modal_no_padding'
    });
  }

  openMaintenanceWindowDialog($event: MouseEvent): void {
    $event.preventDefault();
    MaintenanceWindowInfoDialogComponent.show(this.dialog, this.upcomingMaintenanceWindows);
  }
}
