export const SUPPORT_CASE_STATUS_ARRAY = ['NEW', 'WAITING_ON_CUSTOMER', 'WAITING_ON_CLICKHOUSE', 'CLOSED'] as const;

// Since SEV_1 < SEV_2, lexicographically speaking, we can use >= to determine if a specific priority is allowed.
export const SUPPORT_CASE_PRIORITY_ARRAY = ['SEV_1', 'SEV_2', 'SEV_3'] as const;

export const SUPPORT_CASE_EVENT_TYPE_ARRAY = ['reply', 'status', 'attachment'] as const;

export const SUPPORT_CASE_PRIORITY_TO_SHORT_DESCRIPTION_MAP: Readonly<Record<SupportCasePriority, string>> = {
  SEV_3: 'Severity 3',
  SEV_2: 'Severity 2',
  SEV_1: 'Severity 1'
};

export const SUPPORT_CASE_PRIORITY_TO_DESCRIPTION_MAP: Readonly<Record<SupportCasePriority, string>> = {
  SEV_3: 'Severity 3 (Minor Business Impact or General Questions)',
  SEV_2: 'Severity 2 (Major Business Impact)',
  SEV_1: 'Severity 1 (Critical Business Impact)'
};

export type SupportCaseStatus = (typeof SUPPORT_CASE_STATUS_ARRAY)[number];
export type SupportCasePriority = (typeof SUPPORT_CASE_PRIORITY_ARRAY)[number];
export type SupportCaseEventType = (typeof SUPPORT_CASE_EVENT_TYPE_ARRAY)[number];

export const MAX_SUBJECT_LENGTH = 255;
export const MAX_DESCRIPTION_LENGTH = 32_000;
export const MAX_REPLY_LENGTH = 32_000;

export interface SupportCase {
  id: string;
  /** Another type of ID Salesforce provides for cases, exposed to the user in the UI */
  caseNumber: string;
  status: SupportCaseStatus;
  subject: string;
  description: string;
  priority: SupportCasePriority;
  /** The ISO timestamp of the last time this case was updated */
  lastModifiedDate: string;
  primaryClickHouseContactName?: string;
  serviceId?: string;
}

export interface SupportCaseDetails extends SupportCase {
  orgId: string;
  createdOn: string;
  fullName: string;
  additionalEmails?: string[];
  events: SupportCaseEvent[];
}

export interface SupportCaseEvent {
  type: SupportCaseEventType;
  timestamp: string;
  fullName: string;
  isClickHouse: boolean | string;
  reply: string;
  status: SupportCaseStatus;
  attachment?: CaseAttachment;
  email: string;
}

export interface ListCasesRequest {
  organizationId: string;
}

export interface ListCasesResponse {
  cases: Array<SupportCase>;
}

export interface CaseDetailsRequest {
  organizationId: string;
  caseId: string;
}

export interface CaseDetailsResponse {
  details: SupportCaseDetails;
}

export interface PrepareAttachmentUploadRequest {
  organizationId: string;
  fileName: string;
  mimeType?: string;
}

export interface CaseAttachment {
  fileName: string;
  downloadUrl: string;
  mimeType?: string;
}

export interface ReportSupportCaseEventRequest {
  organizationId: string;
  caseId: string;
  type: SupportCaseEventType;
  reply?: string;
  status?: SupportCaseStatus;
  attachmentStorageIds?: Array<string>;
}

export interface CreateSupportCaseRequest {
  organizationId: string;
  priority: SupportCasePriority;
  subject: string;
  description: string;
  additionalEmails?: string[];
  serviceId?: string;
  serviceName?: string;
  attachmentStorageIds?: string[];
  recaptchaToken: string;
}

export interface CreateSupportCaseResponse {
  caseId: string;
}

// Websocket payloads
/** Payload of 'SUPPORT_CASE_DETAILS_UPDATE' WS message. */
export type CaseDetailsUpdatePayload = {
  details: SupportCaseDetails;
};
