import { Injectable } from '@angular/core';
import { truthy } from '@cp/common/utils/Assert';
import { apply, isStateObject, State, StateLeafNode } from '@cp/common-services/state/action.applier';

import {
  ActionType,
  BaseAction,
  BatchAction,
  DeletePathAction,
  extractAllPathActions,
  SetPathAction
} from '@cp/common-services/state/actions';
import { getPathTrieFromAction, PathTrie } from '@cp/common-services/state/path_trie';
import { Observable, Observer, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private readonly subscriptionsTree = new PathTrie<SubscriptionData>();

  private state: State = {};
  private batchCount = 0;
  private readonly batchedActions: BaseAction[] = [];

  dispatch(action: BaseAction): void {
    if (this.batchCount > 0) this.batchedActions.push(action);
    this.state = apply(this.state, action);
    if (this.batchCount === 0) {
      this.dispatchSubscriptions(action);
    }
  }

  runInBatch(fn: () => unknown): void {
    this.batchCount++;
    try {
      fn();
    } finally {
      this.batchCount--;
      if (this.batchCount === 0 && this.batchedActions.length) {
        const batchAction: BatchAction = {
          type: ActionType.BATCH_ACTION,
          payload: [...this.batchedActions]
        };
        this.batchedActions.splice(0);
        this.dispatch(batchAction);
      }
    }
  }

  observePath<T>(path: string[], pathsToExcludeFn?: () => Array<string[]>): Observable<T> {
    return new Observable<unknown>((observer: Observer<unknown>) => {
      const subject = this.getOrCreateSubjectForPath(path);
      observer.next(this.getStateInPath(path));
      const subscription = subject
        .pipe(
          // If there are path to exclude - remove them
          filter((dataAndAction) => {
            if (!pathsToExcludeFn) return true;
            const pathToExcludeTrie = new PathTrie<boolean>();
            const pathsToExclude = pathsToExcludeFn();
            for (const pathToExclude of pathsToExclude) {
              pathToExcludeTrie.getOrCreatePathTrieNode(pathToExclude, true);
            }
            const pathsInAction = extractAllPathActions(dataAndAction.action).map((a) => a.path);
            let affectsNonExcludedPaths = false;
            for (const pathInAction of pathsInAction) {
              if (pathToExcludeTrie.getNodeList(pathInAction).length === 0) {
                affectsNonExcludedPaths = true;
                break;
              }
            }
            return affectsNonExcludedPaths;
          }),
          // Extract the data
          map((dataAndAction) => dataAndAction.data)
        )
        .subscribe(observer);
      return () => {
        subscription.unsubscribe();
        // When there are no more subscriptions to the given path, complete
        // the subject and clear the node data from the Trie.
        if (!subject.observed) {
          subject.complete();
          this.subscriptionsTree.clearNodeData(path);
        }
      };
    }) as Observable<T>;
  }

  setKeyInPath<T extends State>(
    path: string[],
    key: keyof T & string,
    value: T[keyof T] & StateLeafNode,
    noopIfSame = false
  ): void {
    this.setInPath([...path, key], value, noopIfSame);
  }

  setPartial<T extends State>(path: string[], partialState: Partial<T>, noopIfSame = false): void {
    let currentState = this.getStateInPath<State>(path);
    if (!currentState) {
      currentState = {};
    }
    this.setInPath(path, { ...currentState, ...partialState }, noopIfSame);
  }

  setInPath(path: string[], payload: StateLeafNode, noopIfSame = false): void {
    if (noopIfSame) {
      const existingValue = this.getStateInPath(path);
      if (existingValue === payload) return;
    }
    const setPathAction: SetPathAction = {
      type: ActionType.SET_PATH,
      path: path,
      payload
    };
    this.dispatch(setPathAction);
  }

  deletePath(path: string[]): void {
    const deletePathAction: DeletePathAction = {
      type: ActionType.DELETE_PATH,
      path: path
    };
    this.dispatch(deletePathAction);
  }

  getState(): State {
    return this.state;
  }

  getStateInPath<T extends StateLeafNode>(path: string[]): T | undefined {
    return getStateInPath(this.state, path) as T | undefined;
  }

  destroy(): void {
    this.subscriptionsTree.iterateBfs((subData: SubscriptionData) => {
      if (subData && subData.subject) {
        subData.subject.complete();
      }
    });
  }

  private getOrCreateSubjectForPath(path: string[]): Subject<{ action: BaseAction; data: unknown }> {
    const subscriptionNode = this.subscriptionsTree.getOrCreatePathTrieNode(path);
    if (!subscriptionNode.nodeData) {
      subscriptionNode.nodeData = new SubscriptionData();
    }
    const subscriptionData = subscriptionNode.nodeData;
    if (!subscriptionData.subject) {
      subscriptionData.subject = new Subject();
    }
    return subscriptionData.subject;
  }

  private dispatchSubscriptions(action: BaseAction): void {
    const lookupTree = getPathTrieFromAction(action, this.subscriptionsTree);
    lookupTree.iterateBfs((data: boolean, path: string[]) => {
      const subscriptionsNode = this.subscriptionsTree.getPathTrieNode(path);
      if (!subscriptionsNode || !subscriptionsNode.nodeData) return;
      truthy(subscriptionsNode.nodeData.subject).next({ action, data: getStateInPath(this.getState(), path) });
    });
  }
}

function getStateInPath(obj: State, path: string[]): StateLeafNode | undefined {
  let result: StateLeafNode = obj;
  for (const p of path) {
    if (result === null || result === undefined) return undefined;
    if (!isStateObject(result)) {
      throw new Error('result must be a state object');
    }
    result = result[p];
  }
  return result;
}

class SubscriptionData {
  subject: Subject<{ action: BaseAction; data: unknown }> | undefined;
}
