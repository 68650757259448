<mat-dialog-content>
  <cp-dialog-title [dialogTitle]="'Upgrade to Production'"></cp-dialog-title>
  <div class="upgrade_instance_dialog_close_button_wrapper">
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
  </div>
  <div class="text text_vertical">
    <h6 class="upgrade_instance_dialog_section_title">
      YOUR CURRENT SELECTION
    </h6>
    <div class="upgrade_instance_dialog_card upgrade_instance_dialog_card_development">
      <div class="upgrade_instance_dialog_card_title">
        <p>Development</p>
      </div>
      <ul class="upgrade_instance_dialog_bullet_list">
        <li> Great for smaller workloads</li>
        <li> Up to 1 TB storage and 16 GB total memory</li>
        <li> Competitive monthly pricing</li>
      </ul>
    </div>
    <h6 class="upgrade_instance_dialog_section_title">
      AVAILABLE UPGRADE
    </h6>
    <div class="upgrade_instance_dialog_card upgrade_instance_dialog_card_production">
      <div class="upgrade_instance_dialog_card_title">
        <p>Production</p>
      </div>
      <ul class="upgrade_instance_dialog_check_list">
        <li class="upgrade_instance_dialog_check_list_item">
          <mat-icon class="success_icon" svgIcon="check_icon"></mat-icon>
          Recommended for production workloads
        </li>
        <li class="upgrade_instance_dialog_check_list_item">
          <mat-icon class="success_icon" svgIcon="check_icon"></mat-icon>
          Unlimited storage with 24 GB+ total memory
        </li>
        <li class="upgrade_instance_dialog_check_list_item">
          <mat-icon class="success_icon" svgIcon="check_icon"></mat-icon>
          Usage based pricing
        </li>
        <li class="upgrade_instance_dialog_check_list_item">
          <mat-icon class="success_icon" svgIcon="check_icon"></mat-icon>
          Dedicated CPU
        </li>
        <li class="upgrade_instance_dialog_check_list_item">
          <mat-icon class="success_icon" svgIcon="check_icon"></mat-icon>
          Replicated across 3AZs
        </li>
        <li class="upgrade_instance_dialog_check_list_item">
          <mat-icon class="success_icon" svgIcon="check_icon"></mat-icon>
          Control your spend by limiting compute resources
        </li>
      </ul>
    </div>
  </div>
</mat-dialog-content>

<div class="vertical_buttons">
  <button [mat-dialog-close]="true" class="button btn_normal" mat-flat-button
          data-cy="upgrade-instance-dialog-confirmation-button">Upgrade this service to Production
  </button>

  <button [mat-dialog-close]="false" class="button secondary_action" mat-flat-button
          data-cy="upgrade-instance-dialog-cancel-button">Close
  </button>
</div>
