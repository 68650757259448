import { generate } from 'generate-password-ts';

/** Generates a random password for ClickHouse instance. */
export function generateClickHouseDbPassword(): string {
  return generate({
    length: 13,
    numbers: true,
    lowercase: true,
    uppercase: true,
    exclude: '*+$&,><:\'"`@#%/=;|?[]<>{}()^!-\\',
    symbols: true,
    strict: true
  });
}
