import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '@cp/web/app/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  async isLoggedIn(): Promise<boolean> {
    try {
      const accessToken = await this.authService.getAccessToken();
      return !!accessToken;
    } catch (error) {
      return false;
    }
  }

  async canActivate(): Promise<boolean> {
    if (await this.isLoggedIn()) {
      return true;
    }
    await this.router.navigate(['/signIn'], { replaceUrl: true });
    return false;
  }

  canActivateChild(): Promise<boolean> {
    return this.canActivate();
  }
}
