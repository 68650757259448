import { Injectable } from '@angular/core';
import { BaseUiStateService } from '@cp/common-services/base-ui-state.service';
import { StateService } from '@cp/common-services/state/state.service';
import { ChangeOrganizationNameUiState } from '@cp/web/app/organizations/protocol/OrganizationStates';

@Injectable({
  providedIn: 'root'
})
export class ChangeOrganizationNameUiService extends BaseUiStateService<ChangeOrganizationNameUiState> {
  constructor(stateService: StateService) {
    super(['ui', 'changeOrganizationName'], stateService);
  }
}
