<mat-dialog-content class="create_api_state_dialog">

  <div *ngIf="!generatedKeyDetails" class="key_creation_page">
    <cp-dialog-close-button [dialogRef]="dialogRef"></cp-dialog-close-button>
    <cp-dialog-title dialogTitle="Create API Key"></cp-dialog-title>
    <cp-edit-api-key-form
      mode="create"
      [userRole]="data.role"
      [isSubmitInProgress]="isCreateKeyNetworkCallInFlight"
      (update)="createApiKey($event)"
      (cancel)="dialogRef.close()"
    ></cp-edit-api-key-form>
  </div>

  <div *ngIf="generatedKeyDetails" class="generated_keys_page">
    <div class="header_logo">
      <mat-icon class="icon" svgIcon="check_in_circle"></mat-icon>
    </div>

    <div class="content">
      <div class="title">API key generated!</div>
      <div class="hint">
        Make sure to copy your client ID user secret key,
        <br>
        we'll only show it to you once.
      </div>
      <cp-username-and-password
        [username]="generatedKeyDetails.keyId"
        [password]="generatedKeyDetails.keySecret"
        usernameLabel="Key ID"
        passwordLabel="Key secret"
        credentialsFileName="key.txt"
      ></cp-username-and-password>
      <div class="hint">
        <a href="https://clickhouse.com/docs/en/cloud/manage/openapi" target="_blank">Click here</a> to learn how to use your new API Key.
      </div>
      <div class="buttons">
        <button mat-flat-button
                (click)="dialogRef.close()"
                class="button"
                color="primary"
                data-cy="key-confirm-credentials-button"
        >I've saved my credentials
        </button>
      </div>

    </div>
  </div>
</mat-dialog-content>
