import { Inject, Injectable } from '@angular/core';
import { isStrapiImage, StrapiObject } from '@cp/common/protocol/Strapi';
import { assertTruthy } from '@cp/common/utils/Assert';
import { environment } from '@cp/web/environments/environment';
import Strapi from 'strapi-sdk-js';
import { THEME_SERVICE_TOKEN, ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class StrapiService {
  private readonly strapiClient = new Strapi({
    url: environment.strapiBaseUrl,
    prefix: '/api',
    store: {
      key: 'strapi_jwt',
      useLocalStorage: false,
      cookieOptions: { path: '/' }
    },
    /** More about axios options - https://github.com/axios/axios#request-config. */
    axiosOptions: {}
  });

  constructor(@Inject(THEME_SERVICE_TOKEN) private readonly themeService: ThemeService) {}

  convertStrapiObject(strapiObject: unknown): unknown {
    if (!strapiObject) {
      return strapiObject;
    }

    if (Array.isArray(strapiObject)) {
      return strapiObject.map((i) => this.convertStrapiObject(i));
    }

    const result: any = {};
    assertTruthy(typeof strapiObject === 'object');
    const objectContent = getStrapiObjectAttributes(strapiObject);
    for (const entry of Object.entries(objectContent)) {
      const field: string = entry[0];
      const fieldValue: any = entry[1];

      if (Array.isArray(fieldValue)) {
        result[field] = this.convertStrapiObject(fieldValue);
      } else if (typeof fieldValue === 'object' && fieldValue) {
        if ('data' in fieldValue && Array.isArray(fieldValue.data)) {
          result[field] = this.convertStrapiObject(fieldValue.data);
          continue;
        }
        let convertedObj: any = {};
        if (isStrapiImage(fieldValue?.data?.attributes)) {
          convertedObj = fieldValue.data.attributes;
          convertedObj.url = environment.strapiBaseUrl + convertedObj.url;
          if (convertedObj.ext === '.svg') {
            // Register svg fieldValue icon.
            this.themeService.registerIcon(convertedObj.hash, convertedObj.url);
          }
        } else {
          convertedObj = this.convertStrapiObject(fieldValue);
        }
        result[field] = convertedObj;
      } else if (field === 'summary' && typeof fieldValue === 'string') {
        result[field] = normalizeImageUrl(fieldValue, environment.strapiBaseUrl);
      } else {
        result[field] = fieldValue;
      }
    }
    return result;
  }

  getStrapi(): Strapi {
    return this.strapiClient;
  }
}

/**
 * Search and replace all relative URLs to images uploaded in Strapi with URLs in absolute form.
 * @param text - text to search for URLs
 * @param basePath - path to prepend to URLs
 * @returns text with images with absolute URLs
 */
function normalizeImageUrl(text: string, basePath: string): string {
  return text.replace(/(\/uploads\/.+(.png|.svg|.jpeg|.jpg))/g, basePath + '$1');
}

function getStrapiObjectAttributes(obj: {}): {} {
  return isStrapiObject(obj) ? { id: obj.id, ...obj.attributes } : obj;
}

function isStrapiObject<T extends object>(obj: unknown): obj is StrapiObject<T> {
  if (!obj) return false;
  const strapiObj = obj as StrapiObject<T>;
  return 'attributes' in strapiObj && typeof strapiObj.attributes === 'object' && 'id' in strapiObj;
}
